import React from "react";
import { useState,useEffect } from "react";
export const Error404 = ({ page, name }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {

    let timer1 = setTimeout(() => setShow(true), 1000);

   

    return () => {

      clearTimeout(timer1);

    };

  });

  return show ? (
    <div id="not-found" >
      <div className="error-box" style={{}}>
        <h1>404</h1>
        <h3>
          <i className="fa fa-warning"></i> Oops! Page not found!
        </h3>
        <p>The page you requested was not found.</p>
        <a href={page} className="btn btn-custom">
          {name != null && name != "" ? "Back to " + name : "Back"}
        </a>
      </div>
    </div>
  )
  : ""
  ;
};
