import { AiFillFolder, AiFillFile } from "react-icons/ai";
import { MdArrowRight, MdArrowDropDown, MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FcDepartment } from "react-icons/fc";
import { PiBuildingsLight } from "react-icons/pi";
import { id } from "date-fns/locale";
import { useState } from "react";

const Node = ({
  node,
  style,
  dragHandle,
  tree,
  Rename,
  RenameSave,
  DeleteNode,
  onSelect,
  CrudISNeed = false,
  IsMultiselect = false,
  onCheckedNodesChange,
}) => {
  const CustomIcon = node.data.icon;
  const iconColor = node.data.iconColor;
  const IsSelected = node.data.IsSelected;
  const { isOpen } = node;

  const [checkedNodes, setCheckedNodes] = useState([]);

  const isChecked = checkedNodes[node.data.id];


  const HandleCheckboxChange = (e) => {
    const { checked } = e.target;
    setCheckedNodes({
      ...checkedNodes,
      [node.data.id]: !checkedNodes[node.data.id]
    });
    console.log(checkedNodes);
    onCheckedNodesChange(node.data, checked);
  }


  const HandleClick = () => {
    if (!node.data.isEditing) {
      if (!IsMultiselect) {
        onSelect(node.data.id, node);
      }
      isOpen ? node.close() : node.open();
    }
  }

  return (
    <div
      className={`node-container ${node.state.isSelected ? "isSelected" : ""
        }  `}
      // style={{ ...style, backgroundColor: isSelected ? "#C8C2C1" : "inherit", color: isSelected ? "#000" : "inherit" }}
      style={style}
      ref={dragHandle}
      onClick={HandleClick}
    >
      {IsMultiselect &&
        <input
          type="checkbox"
          checked={IsSelected}
          onChange={HandleCheckboxChange}
        />}
      <div className={`node-content`}>
        {node.isLeaf ? (
          <>
            <span className="arrow"></span>
            <span className="file-folder-icon">
              {CustomIcon ? (
                <CustomIcon color={iconColor ? iconColor : "#6bc7f6"} />
              ) : (
                <PiBuildingsLight color="#6bc7f6" />
              )}
            </span>
          </>
        ) : (
          <>
            <span className="arrow">
              {isOpen ? <MdArrowDropDown /> : <MdArrowRight />}
            </span>
            <span className="file-folder-icon">
              {CustomIcon ? (
                <CustomIcon color={iconColor ? iconColor : "#f6cf60"} />
              ) : (
                <FcDepartment color="#f6cf60" />
              )}
            </span>
          </>
        )}
        <span className="">
          {node.data.isEditing ? (
            <input
              type="text"
              className="flow-control"
              defaultValue={node.data.name}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={() => node.reset()}
              onKeyDown={(e) => {
                if (e.key === "Escape") node.reset();
                else if (e.key === "Enter")
                  RenameSave(node.data.id, e.currentTarget.value);
              }}
            // autoFocus
            />
          ) : (
            <span>{node.data.name}</span>
          )}
        </span>
      </div>
      {CrudISNeed && (
        <div className="file-actions">
          <div className="folderFileActions">
            <button onClick={() => Rename(node)} title="Rename...">
              <MdEdit color="#00000" />
            </button>
            <button onClick={() => DeleteNode(node.data.id)} title="Delete">
              <RxCross2 color="#00000" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Node;
