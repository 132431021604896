import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import $, { data } from "jquery";
import Global from "./../../services/Global";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";


const _masterDetailService = new MasterDetailService();

// const ConfigurationServiceobj = new ConfigurationService();
export const Station = ({ TabID }) => {
  const columnNames = [
    { title: "Station ID", visible: false },
    { title: "Station Code" },
    { title: "Station Name" },
    {
      title: "Submit",
      visible: getPrivilege().includes("MASTEREDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<button class='btn icon-btn' title='Edit'>
        <i class='fa fa-pencil m-r-5'></i>
      </button>`).on("click", function () {
          EditStation(Number(rowData[0]));
        });
        $(td).html(b);
        var c = $(
          `
          <button class='btn icon-btn' title='Delete'>
          <i class='fa fa-trash m-r-5'></i>
        </button>`).on("click", function () {
            onDeleteClick(Number(rowData[0]));
          });
          $(td).append(c);
      },
    },
  ];

  const [stationList, setStationList] = useState([]);
  const [allStationList, setAllStationList] = useState([]);
  const { showLoading, hideLoading } = useContext(AppContext);

  const { currentMasterTab, getCookies } = useContext(AppContext);

  // const [stationCodes, setStationCode] = useState([]);
  const [Refresh, setRefresh] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const onDeleteClick = (id) => {
 


    swal({
      title: "Are you sure?",
      text: "Do you want to delete the station?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _masterDetailService.DeleteStation(id).then((response) => {
          if (response.data == 1) {
            showDeleteSuccess();
            setRefresh(Refresh+1);
          }
          else {
            showSaveError();
          }
    
        }).catch((err) =>{
          setRefresh(Refresh+1);
          console.log(err);
        });
  
      }
    });  
  };
  const onSubmit = (data) => {
    //;
    var isExists = [];
    data.SavedBy = getCookies(Global.COOKIES.EmployeeID)

    isExists = allStationList.find(
      (v) =>
        (v.stationCode.toLowerCase().trim() ==
          data.stationCode.toLowerCase().trim() ||
          v.stationName.toLowerCase().trim() ==
            data.stationName.toLowerCase().trim()) &&
        v.stationID != data.stationID
    );
    if (isExists == null || isExists.length == 0) {
      _masterDetailService
        .SaveStation(data)

        .then((response) => {
          reset();
          showSaveSuccess();
          var count = Refresh;
          setRefresh(++count);
        })
        .catch((error) => {
          showSaveError();
        });
    } else {
      showWarningMsg("Station code or Station name already exists");
    }
  };

  //functions
  useEffect(() => {
    console.log(TabID);
    if (TabID == currentMasterTab) {
      getData();
      reset();
    }
  }, [currentMasterTab]);

  const getData = () => {
    showLoading();
    _masterDetailService
      .GetStationList(GetLoginUserID())
      .then((response) => {
        
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          console.log(response.data);
          setAllStationList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.stationID);
            row.push(v.stationCode);
            row.push(v.stationName);
            row.push("");
            rowList.push(row);
          });
          console.log(rowList);
          setStationList(rowList);
          hideLoading();
        } else {
          hideLoading();
        }
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  useEffect(() => {
    getData();
  }, [Refresh]);

  function EditStation(Id) {
    const found = allStationList.find((obj) => {
      return obj.stationID === Id;
    });
    Object.keys(found).map((key, i) => setValue(key, found[key]));
  }
  const changeHandler = (name, value) => {
    setValue(name, value.toUpperCase());
  };

  return (

    <div className="mb-2">
      {getPrivilege().includes("MASTERCREATE") && (
      <form id="StationMaster" onSubmit={handleSubmit(onSubmit)}>
        <div className="row d-flex justify-content-end">
          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="stationCode">
                Station Code <span className="text-danger">*</span>
              </label>
              <input type="hidden" value={register.stationID} />

              <input
                type="text"
                name="stationCode"
                className="form-control"
                // placeholder="Station Code"
                id="stationCode"
                maxLength="10"
                {...register("stationCode", {
                  required: "Station code is required",
                  validate: (value) => {
                    return !!value.trim() || "Station code cannot be empty";
                  },
                })}
                onKeyUp={(e) => changeHandler("stationCode", e.target.value)}
                value={register.stationCode}
              />

              {errors.stationCode && (
                <span className="text-danger">
                  {errors.stationCode.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="stationName">
                Station Name <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="stationName"
                className="form-control"
                // placeholder="Station Name"
                maxLength="100"
                id="stationName"
                {...register("stationName", {
                  required: "Station name is required",
                  validate: (value) => {
                    return !!value.trim() || "Station name cannot be empty";
                  },
                })}
                value={register.stationName}
              />
              {errors.stationName && (
                <span className="text-danger">
                  {errors.stationName.message}
                </span>
              )}
            </div>
          </div>
          
          <div className="col-2 mt-4">
            <AddButton />
          </div>
        
        </div>
      </form>
      )}
      <HtmlTable
        columnList={columnNames}
        rowList={stationList}
        id={"StationTable"}
      />
    </div>
  );
};
