import React, { useCallback, useEffect, useRef, useState } from "react";
import { GetLoginUserID } from "../services/CommonService";
import NotificationService from "./NotificationService";
import { DefaultAppContextValues } from "../services/ContextProvider";

export default function NotificationList({
  Icon,
  Count = 0,
  HeaderName,
  Type,
  URL = "",
  CustomIcon = false,
  IconImage = false,
  imageProps,
  NotificationContent,
  TypeNotification,
}) {
  const [NotificationList, setNotificationList] = useState([]);
  const [offset, setOffset] = useState(0);

  const _notificationService = new NotificationService();
 const {getCookies,setNotificationModel } = DefaultAppContextValues();
  const limit = 10;

  useEffect(() => {
    setOffset(0);
  }, [Count]);

  const handleOffset = () => {
    getNoti();
  };

  const getNoti = () => {
    _notificationService
      .GetNotificationById(GetLoginUserID(), Type, offset, limit)
      .then((response) => {
        if (response?.data?.length) {
          const data = response.data;
          setOffset((pre) => pre + limit);
          if (offset == 0) {
            setNotificationList(data);
          } else {
            setNotificationList((prevItems) => [...prevItems, ...data]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ClearAll = (id, mode) => {
    _notificationService
      .ClearNotication(id, mode)
      .then((response) => {
        if (response == 1) {
          setNotificationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ClearNotificationCount = (type) => {
    _notificationService
      .ClearNoticationCount(GetLoginUserID(), type)
      .then((response) => {
        if (response == 1) {
         GetCount();
        }
      })
      .catch(() => {});
  };

  const GetCount = (id = GetLoginUserID()) => {
    _notificationService
      .GetCount(id)
      .then((res) => {
        if (res?.data?.length) {
          const NotificationModel = res.data[0];
          setNotificationModel(NotificationModel);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <li className="nav-item dropdown">
        <a
          href="#"
          className="dropdown-toggle nav-link show"
          data-bs-toggle="dropdown"
          onClick={() => {
            handleOffset();
            ClearNotificationCount(Type);
          }}
        >
          {IconImage ? (
            <>
              <img src={Icon} {...imageProps} title={HeaderName} />
              <span className="badge rounded-pill bg-danger">{Count}</span>
            </>
          ) : CustomIcon ? (
            <>
              <Icon></Icon>
            </>
          ) : (
            <>
              <i className={Icon} title={HeaderName}></i>
              <span className="badge rounded-pill bg-danger">{Count}</span>
            </>
          )}
        </a>
        <div className="dropdown-menu notifications">
          <div className="topnav-dropdown-header">
            <span className="notification-title">{HeaderName}</span>
            <a
              href="#"
              onClick={() => ClearAll(GetLoginUserID(), Type)}
              className="clear-noti"
            >
              {" "}
              Clear All{" "}
            </a>
          </div>
          <div className="noti-content">
            <ul className="notification-list">
              {NotificationList?.length ? (
                NotificationList.map((data, i) => {
                  return NotificationContent(data, URL, Type, "");
                })
              ) : (
                <></>
              )}
              {NotificationList?.length ? (
                <div
                  className="topnav-dropdown-footer text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOffset();
                  }}
                >
                  Show More
                </div>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </li>
    </>
  );
}
