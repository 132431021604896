import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import { AppContext } from "./../../services/ContextProvider";

import { HtmlTable } from "../../components/HtmlTable";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import MasterDetailService from "../master/MasterDetailService";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess
} from "../../components/ToastMsg";
const _masterDetailService = new MasterDetailService();

const Header = ({ title }) => {
  return (
    <div className="page-header mb-3">
      <div className="row">
        <div className="col-4">
          <h3 className="page-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export const StationConfig = () => {

  const SaveStationColor = (StationID, StationColor) => {
    
    var EmployeeID = GetLoginUserID();
    showLoading();
    _masterDetailService.SaveStationColor(Number(StationID),Number(EmployeeID), String(StationColor))
        .then((response) => {
          if (
            response != null &&
            response.data != null 
          ) {
            if(response.data == 1){
              setRefresh(Refresh+1);
              showSaveSuccess();
            }
            else if(response.data == -1){
              showWarningMsg("Color already mapped to someother station");
            }
            else{
              showSaveError();
            }
            hideLoading();
            
            
          } else {
            setRefresh(Refresh+1);
            hideLoading();
          }
        })
  }
  const { showLoading, hideLoading } = useContext(AppContext);

  const columnNames = [
    { title: "Station ID", visible: false },
    { title: "Station Code" },
    {
      title: "Station Color",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<input type="color" style="width:100px" id="favcolor_${rowData[0]}" value="${cellData}" name="favcolor">`).on(
          "click",
          function () {
          
            // EditStation(Number(rowData[0], rowData));
          }
        );
        $(td).html(b);
      },
    },
    // { title: "Station Name" },
    {
      title: "Submit",
      visible: getPrivilege().includes("STATIONCONFIGEDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<button class='btn icon-btn'  title='Edit'>
        <i class='fa fa-check m-r-5'></i>
      </button>`).on("click", function () {
        
        var colorcode = $(`#favcolor_${rowData[0]}`).val();
          SaveStationColor(Number(rowData[0]), colorcode);
        });
        $(td).html(b);
      },
    },
  ];
  const [Refresh, setRefresh] = useState(0);

  const [stationList, setStationList] = useState([]);
  const getData = () => {
    showLoading();
    var EmployeeID = GetLoginUserID();
   _masterDetailService
      .GetStationColorList(Number(EmployeeID))
      .then((response) => {
        console.log(response);
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          console.log(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.stationID);
            row.push(v.stationCode);
            row.push(v.stationColor);
            row.push("");
            rowList.push(row);
          });
          setStationList(rowList);
          hideLoading();
        } else {
          hideLoading();
        }
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  useEffect(() => {
    getData();
  }, [Refresh]);
  return (
    <>
      <Header title={"Station Color Configuration"} />
      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={stationList}
            id={"StationColorTable"}
          />
        </div>
      </div>
    </>
  );
};
