import React, { useRef, useState, useEffect } from "react";
import { BootrapModel, HtmlTable, ReactMultiSelect } from "../../components";
import { MultiValue, Option } from "../../components";
import makeAnimated from "react-select/animated";
import StackedChart from "../../components/charts/StackedChart_for_UG19";
import { getCSSVariables } from "../../services/CommonService";
import moment from "moment";
import { Donet } from "../../components/charts/Donet";
import {
  AllDepartmentData,
  AllStationList,
  AllStationWiseData,
  AllEquipmentAvaWiseData,
  MaintenanceChecksForChecks,
  MaintenanceChecksReportData,
  MachineAvaChecksReportData,
  StackedDataForBDEquipmentWise,
  StackedDataForBDStationWise,
  EquipmentAvailabilitydata,
  AllEquipmentWiseData,
} from "./TempData";
import { CommonBarChart } from "../../components/charts/CommonBarChart";
import $ from "jquery";
import { HmacSHA512 } from "crypto-js";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";

export default function UG_19_StationDashboard() {
  const DepartmentChartRef = useRef(null);

  // useEffect(() => {
  //   AvailabilityWiseClick("_", false);
  //   DepartmentWiseClick("_", false);
  // }, []);
  const [FilterModel, setFilterModel] = useState({
    Departments: [],
    FromDate: "",
    ToDate: "",
    Corridor: "",
    Frequency: "",
    Stations: [],
  });
  const [Line, setLine] = useState([]);
  const [PeriodicModel, setPeriodicModel] = useState({
    ShowBar: false,
    AvailableShowBar: false,
    StationWiseData: [],
    Availabilitydata: [],
    ListEquipmentAvailabilitydata: EquipmentAvailabilitydata,
    ListAllEquipmentWiseData: AllEquipmentWiseData,
    DepartmentData: AllDepartmentData,
    EquipmentWiseBm: StackedDataForBDEquipmentWise,
    StationWiseDataForBM: [],
    DepartmentName: "",
  });
  const animatedComponents = makeAnimated();

  const DashboardCard = ({
    OpenCount,
    OverdueCount,
    AssendCount,
    CompletedCount,
    ApprovedCount,
    RejectedCount,
    TotalCount,
    onClick,
    CompletedText = "Completed",
    ApprovedText = "Approved",
    AttendText,
    AttendCount,
  }) => {
    return (
      <div class="row">
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_open"
            onClick={() => {
              onClick("Open");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-openid"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 116 */}
                  {OpenCount}
                  <span className="font-18 text-black-50">
                    {" "}
                    / {TotalCount}
                  </span>{" "}
                </h3>
                <span>Open</span>
              </div>
            </div>
          </div>
        </div>

        {OverdueCount != null && (
          <div class="col xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget dashboard_background_Overdue"
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {OverdueCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>Overdue</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_attended"
            onClick={() => {
              onClick("Completed");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-eercast"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 287  */}
                  {CompletedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>{CompletedText}</span>
              </div>
            </div>
          </div>
        </div>
        {AttendText != null && (
          <div class="col xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget"
              style={{ background: "#5184ff" }}
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {AttendCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>{AttendText}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="col xs12 m6 s12class l6 xl3">
          <div
            class="card dash-widget dashboard_background_Approved"
            onClick={() => {
              onClick("Approved");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-up"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {ApprovedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>{" "}
                </h3>
                <span>{ApprovedText}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_Rejected"
            onClick={() => {
              onClick("Rejected");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-down"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {RejectedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>Rejected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardAvaCard = ({
    OpenCount,
    OverdueCount,
    CompletedCount,
    ApprovedCount,
    RejectedCount,
    TotalCount,
  }) => {
    return (
      <div class="row">
        <div class="col xs12 m6 s12 l6 xl3 page-title-box"></div>

        <div class="col xs12 m6 s12class l6 xl3">
          <div
            class="card dash-widget dashboard_background_Approved"
            onClick={() => {
              MacnineOverdueOnClick(ApprovedCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-check"></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {ApprovedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>{" "}
                </h2>
                <span>Running</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_Overdue"
            onClick={() => {
              MacnineOverdueOnClick(OverdueCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-exclamation"></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {/* 355 */}
                  {OverdueCount}
                  <span className="font-18 text-black-50">
                    {" "}
                    / {TotalCount}
                  </span>{" "}
                </h2>
                <span>Under Maintenance</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_Rejected"
            onClick={() => {
              MacnineOverdueOnClick(RejectedCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-chain-broken "></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {RejectedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h2>
                <span>Breakdown</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col xs12 m6 s12 l6 xl3 page-title-box"></div>
      </div>
    );
  };

  const DepartmentWiseClick = (event, chartElements) => {
    
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;
      setPeriodicModel((pre) => ({
        ...pre,
        DepartmentName: AllDepartmentData.labels[clickedElementIndex],
      }));
      let StationWiseData = JSON.parse(JSON.stringify(AllStationWiseData));
      let filterLabel = AllStationWiseData.datasets[clickedDataSetIndex].label;
      StationWiseData.datasets = StationWiseData.datasets.filter(
        (ele) => ele.label == filterLabel
      );
      setPeriodicModel((pre) => ({
        ...pre,
        ShowBar: true,
        StationWiseData: StationWiseData,
      }));
      return 1;
    }

    let StationWiseData = AllStationWiseData;

    setPeriodicModel((pre) => ({
      ...pre,
      ShowBar: true,
      StationWiseData: StationWiseData,
    }));
  };

  const AvailabilityWiseClick = (event, chartElements) => {
    
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;
      // setPeriodicModel((pre) => ({
      //   ...pre,
      //   DepartmentName: AllDepartmentData.labels[clickedElementIndex],
      // }));
      let Availabilitydata = JSON.parse(
        JSON.stringify(AllEquipmentAvaWiseData)
      );
      let filterLabel =
        AllEquipmentAvaWiseData.datasets[clickedElementIndex].label;
      Availabilitydata.datasets = Availabilitydata.datasets.filter(
        (ele) => ele.label == filterLabel
      );
      setPeriodicModel((pre) => ({
        ...pre,
        AvailableShowBar: true,
        Availabilitydata: Availabilitydata,
      }));
      return 1;
    }

    let Availabilitydata = AllEquipmentAvaWiseData;

    setPeriodicModel((pre) => ({
      ...pre,
      AvailableShowBar: true,
      Availabilitydata: Availabilitydata,
    }));
  };

  const OverdueOnClick = (label) => {
    
    let DepartmentData = JSON.parse(JSON.stringify(AllDepartmentData));

    DepartmentData.datasets = DepartmentData.datasets.filter(
      (ele) => ele.label == label
    );

    setPeriodicModel((pre) => ({
      ...pre,
      ShowBar: false,
      DepartmentData: DepartmentData,
    }));
  };

  const MacnineOverdueOnClick = (label) => {
    let DepartmentData = JSON.parse(JSON.stringify(EquipmentAvailabilitydata));
    let dataindex = DepartmentData.datasets[0].data.findIndex(
      (ele) => ele == label
    );
    DepartmentData.datasets[0].data = [
      DepartmentData.datasets[0].data[dataindex],
    ];
    DepartmentData.datasets[0].backgroundColor = [
      DepartmentData.datasets[0].backgroundColor[dataindex],
    ];

    setPeriodicModel((pre) => ({
      ...pre,
      AvailableShowBar: false,
      ListEquipmentAvailabilitydata: DepartmentData,
    }));
  };

  const DepartmentWiseClickForBM = (event, chartElements) => {
    
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;
      setPeriodicModel((pre) => ({
        ...pre,
        DepartmentName:
          StackedDataForBDEquipmentWise.labels[clickedElementIndex],
      }));
      let StationWiseData = JSON.parse(JSON.stringify(AllStationWiseData));
      let filterLabel =
        StackedDataForBDStationWise.datasets[clickedDataSetIndex].label;
      StationWiseData.datasets = StationWiseData.datasets.filter(
        (ele) => ele.label == filterLabel
      );
      setPeriodicModel((pre) => ({
        ...pre,
        StationWiseDataForBM: StationWiseData,
      }));
      return 1;
    }

    setPeriodicModel((pre) => ({
      ...pre,
      StationWiseDataForBM: StackedDataForBDStationWise,
    }));
  };

  const OverdueOnClickForBM = (label) => {
    
    let DepartmentData = JSON.parse(
      JSON.stringify(StackedDataForBDEquipmentWise)
    );

    DepartmentData.datasets = DepartmentData.datasets.filter(
      (ele) => ele.label == label
    );

    setPeriodicModel((pre) => ({
      ...pre,
      ShowBar: false,

      EquipmentWiseBm: DepartmentData,
    }));
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div>
      <div className="row mb-2">
        <div className="col-md-7">
          <div className="row">
            <div className="col-3">
              <h3>Dashboard</h3>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "E&M", value: 1 },
                  { label: "Civil Maintenance", value: 2 },
                  { label: "Lift & Escalator", value: 3 },
                  { label: "OHE", value: 4 },
                  { label: "Track", value: 5 },
                  { label: "Panel", value: 6 },
                  { label: "Lighting", value: 7 },
                ]}
                placeholder={"Departments"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Departments: e }))
                }
                value={FilterModel.Departments}
              ></ReactMultiSelect>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "Lift", value: 1 },
                  { label: "Escalator", value: 2 },
                ]}
                placeholder={"Equipment"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Equipment: e }))
                }
                value={FilterModel.Equipment}
              ></ReactMultiSelect>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "C1 - UG", value: 1 },
                  { label: "C1 - Elevated", value: 2 },
                  { label: "C2 - UG ", value: 3 },
                  { label: "C2 - Elevated", value: 4 },
                ]}
                placeholder={"Corridor"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Corridor: e }))
                }
                value={FilterModel.Corridor}
              ></ReactMultiSelect>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="row">
            <div className="col-4">
              <ReactMultiSelect
                isMulti
                options={AllStationList}
                placeholder={"All Stations"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Stations: e }))
                }
                value={FilterModel.Stations}
              ></ReactMultiSelect>
            </div>
            <div className="col-8">
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY" // Specify the date format
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row mt-1">
            <ol
              class="breadcrumb text-muted mb-0 col-12"
              style={{ padding: "0 13px" }}
            >
              <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Departments?.length > 0
                    ? FilterModel.Departments.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Departments"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Equipment?.length > 0
                    ? FilterModel.Equipment.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Equipment"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Corridor?.length > 0
                    ? FilterModel.Corridor.map((ele) => ele.label).toString(",")
                    : "All Corridors"}
                </a>
              </li>
              <li class="breadcrumb-item text-muted">
                {FilterModel.Stations?.length > 0
                  ? FilterModel.Stations.map((ele) => ele.label).toString(",")
                  : "All Stations"}
              </li>
            </ol>

            <div className="col-4"></div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-12 card p-b-10 " style={{ paddingBottom: 30 }}>
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            Equipment Availability Status{" "}
          </h3>

          <div className="row">
            <div className="col-12 ">
              <hr style={{ marginTop: 10 }} />

              {DashboardAvaCard({
                OverdueCount: 528,
                ApprovedCount: "30,770",
                RejectedCount: 102,
                TotalCount: "31,400",
                // OverdueCount: 133,
                // ApprovedCount: 107,
                // RejectedCount: 99,
                // TotalCount: 339,
              })}
            </div>
            <div className="row col-12 mt-2 mx-auto">
              <div className="col xs12 m6 s12 l6 xl3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12  text-center">
                        <h3 class="card-title mt-2">
                          Equipment Availability Status by E&M Wise
                        </h3>
                        <hr style={{ marginTop: 10 }} />
                      </div>
                      <div
                        class="col-md-12"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div
                          class=" m-0"
                          style={{
                            maxWidth: "680px !important",
                            width: "680px",
                          }}
                        >
                          <div class="row d-flex justify-content-center">
                            <div class="mt-0 col-md-10 col-sm-6 ">
                              <div>
                                {/* <Donet
                                  data={
                                    PeriodicModel.ListEquipmentAvailabilitydata ??
                                    []
                                  }
                                  clickable={AvailabilityWiseClick}
                                  onDoubleClick={() => {
                                    AvailabilityWiseClick("_", false);
                                  }}
                                /> */}

                                <CommonBarChart
                                  IsShow={true}
                                  reff={DepartmentChartRef}
                                  data={
                                    PeriodicModel.ListAllEquipmentWiseData ?? []
                                  }
                                  clickable={DepartmentWiseClick}
                                  onDoubleClick={() => {
                                    DepartmentWiseClick("_", false);
                                  }}
                                  xTitle={"Equipments"}
                                  LegendBoxWidth={10}
                                  dataLabelsShow
                                ></CommonBarChart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 card p-b-10 " style={{ paddingBottom: 30 }}>
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            E&M - Lift Availability Status
          </h3>

          <div className="row">
            <div className="col-12 ">
              <hr style={{ marginTop: 10 }} />

              {DashboardAvaCard({
                OverdueCount: 2,
                ApprovedCount: 41,
                RejectedCount: 1,
                TotalCount: 44,
                // OverdueCount: 133,
                // ApprovedCount: 107,
                // RejectedCount: 99,
                // TotalCount: 339,
              })}
            </div>
            <div className="row col-12 mt-2 mx-auto">
              <div className="col xs12 m6 s12 l6 xl3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12  text-center">
                        <h3 class="card-title mt-2">
                          E&M - Lift Availability Status
                        </h3>
                        <hr style={{ marginTop: 10 }} />
                      </div>
                      <div
                        class="col-md-12"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div
                          class=" m-0"
                          style={{
                            maxWidth: "540px !important",
                            width: "480px",
                          }}
                        >
                          <div class="row d-flex justify-content-center">
                            <div class="mt-0 col-md-10 col-sm-6 ">
                              <div>
                                <Donet
                                  data={
                                    PeriodicModel.ListEquipmentAvailabilitydata ??
                                    []
                                  }
                                  clickable={AvailabilityWiseClick}
                                  onDoubleClick={() => {
                                    AvailabilityWiseClick("_", false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {PeriodicModel.AvailableShowBar && (
                <div className="col xs12 m6 s12 l6 xl3 text-center">
                  <div className="card">
                    <div className="card-body">
                      <h3 class="card-title mt-2">
                        Station Wise Breakdown Status (E&M - Lift)
                      </h3>
                      <hr style={{ marginTop: 10 }} />
                      <CommonBarChart
                        xTitle={"Stations"}
                        data={PeriodicModel.Availabilitydata}
                        clickable={() => {
                          $("#modelBreakdownBtn").click();
                        }}
                        IsShow={true}
                        LegendBoxWidth={10}
                        dataLabelsShow
                      ></CommonBarChart>
                    </div>
                  </div>
                </div>
              )}

              <BootrapModel
                module={<AvaReportTable />}
                Id={"modelBreakdownReportTableModel"}
                modelSize={"modal-xl"}
                ModelTitle={"E&M - Lift Availability Status - Breakdown Report"}
              ></BootrapModel>

              <button
                id="modelBreakdownBtn"
                hidden
                data-bs-toggle="modal"
                data-bs-target="#modelBreakdownReportTableModel"
              ></button>
            </div>
          </div>
        </div>

        <div className="col-12 card">
          <div className=" p-2 row ">
            <h3
              className="card-title m-0 text-center"
              style={{ paddingTop: "10px" }}
            >
              E&M - Escalator Breakdown Maintenance Status{" "}
            </h3>
            <hr style={{ marginTop: 10 }} />
            {DashboardCard({
              OverdueCount: 1,
              OpenCount: 1,
              CompletedCount: 2,
              ApprovedCount: 5,
              RejectedCount: 1,
              TotalCount: 10,
              onClick: OverdueOnClickForBM,
              CompletedText: "Assigned",
              // ApprovedText: "Approved",
              AttendText: "Attended",
              AssendCount: 1,
              AttendCount: 6,
            })}

            <div className="col-6 mt-2 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 class="card-title mt-2">
                    E&M - Escalator Breakdown Maintenance Status
                  </h3>
                  <hr style={{ marginTop: 10 }} />
                  <StackedChart
                    // IsHorizontal
                    data={StackedDataForBDEquipmentWise}
                    IsShow={true}
                    LegendPosition={"bottom"}
                    Title=""
                    BarClick={DepartmentWiseClickForBM}
                    onDoubleClick={() => {
                      DepartmentWiseClickForBM("_", false);
                    }}
                    // yLabel={"Equipments"}
                  ></StackedChart>
                </div>
              </div>
            </div>
            <div className="col-6 mt-2 text-center">
              {PeriodicModel.StationWiseDataForBM?.datasets && (
                <>
                  <div className="card">
                    <div className="card-body">
                      <h3 class="card-title mt-2">
                        E&M - Escalator Breakdown Maintenance Status - Open
                      </h3>
                      <hr style={{ marginTop: 10 }} />
                      <StackedChart
                        // IsHorizontal
                        data={PeriodicModel.StationWiseDataForBM}
                        IsShow={true}
                        LegendPosition={"bottom"}
                        // yLabel={"Stations"}
                        BarClick={() => {
                          $("#modelTriggerBtnBM").click();
                        }}
                        Title=""
                      ></StackedChart>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 card p-b-10 " style={{ paddingBottom: 30 }}>
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            E&M - Lift Preventive Maintenance Status
          </h3>
          <div
            className="col-3 m-1"
            style={{
              position: "absolute",
              right: "6px",
              top: "4px",
            }}
          >
            <ReactMultiSelect
              isMulti
              options={[
                { label: "Monthly", value: 1 },
                { label: "Quarterly", value: 2 },
                { label: "Half Yearly", value: 3 },
                { label: "Yearly", value: 4 },
              ]}
              components={{ Option, MultiValue, animatedComponents }}
              allowSelectAll
              onChange={(e) => setLine(e)}
              value={Line}
            ></ReactMultiSelect>
          </div>
          <hr style={{ marginTop: 10 }} />
          {DashboardCard({
            OverdueCount: 2,
            OpenCount: 4,
            CompletedCount: 38,
            ApprovedCount: 36,
            RejectedCount: 2,
            TotalCount: 44,
            onClick: OverdueOnClick,
          })}
          <div className="row col-12 text-center mx-auto mt-1">
            <div className="col-6 mt-1 mb-1">
              <div className="card">
                <div className="card-body">
                  <h3 class="card-title mt-2">
                    E&M - Lift Preventive Maintenance Status
                  </h3>
                  <hr style={{ marginTop: 10 }} />
                  <CommonBarChart
                    IsShow={true}
                    reff={DepartmentChartRef}
                    data={PeriodicModel.DepartmentData ?? []}
                    clickable={DepartmentWiseClick}
                    onDoubleClick={() => {
                      DepartmentWiseClick("_", false);
                    }}
                    // xTitle={"Equipments"}
                    LegendBoxWidth={10}
                    dataLabelsShow
                  ></CommonBarChart>
                </div>
              </div>
            </div>
            {PeriodicModel.ShowBar && (
              <div className="col-6 mt-1 mb-1 ">
                <div className="card">
                  <div className="card-body">
                    <h3 class="card-title mt-2 m-l-15">
                      {" "}
                      E&M - Lift Preventive Maintenance Status - Open
                    </h3>
                    <hr style={{ marginTop: 10 }} />
                    <CommonBarChart
                      xTitle={"Stations"}
                      data={PeriodicModel.StationWiseData}
                      clickable={() => {
                        $("#modelTriggerBtn").click();
                      }}
                      IsShow={true}
                      LegendBoxWidth={10}
                      dataLabelsShow
                    ></CommonBarChart>
                  </div>
                </div>
              </div>
            )}

            <BootrapModel
              module={<ReportTable />}
              Id={"MaintenanceChecksReportTableModel"}
              modelSize={"modal-xl"}
              ModelTitle={
                " E&M - Lift Preventive Maintenance Status - Open Report"
              }
            ></BootrapModel>

            <BootrapModel
              module={<ReportTable_1 />}
              Id={"MaintenanceChecksReportTableModelForBM"}
              modelSize={"modal-xl"}
              ModelTitle={
                "E&M - Escalator Breakdown Maintenance Status - Open Report"
              }
            ></BootrapModel>

            <button
              id="modelTriggerBtn"
              hidden
              data-bs-toggle="modal"
              data-bs-target="#MaintenanceChecksReportTableModel"
            ></button>
            <button
              id="modelTriggerBtnBM"
              hidden
              data-bs-toggle="modal"
              data-bs-target="#MaintenanceChecksReportTableModelForBM"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

const ReportTable = () => {
  const ColumNames = [
    {
      title: "StationCode ",
      data: "StationCode",
    },
    {
      title: "Description Work",
      data: "description",
    },
    {
      title: "Action",
      data: "action",
    },
    {
      title: "Equipment Code",
      data: "LiftName",
    },
    // {
    //   title: "Actual",
    //   data: "actual",
    // },
    {
      title: "Schedule Data",
      data: "Schduledate",
    },
    {
      title: "Last Maintained Date",
      data: "LastMaintainedate",
    },
    {
      title: "Status",
      data: "Status",
    },
    {
      title: "Remarks",
      data: "remarks",
    },
    {
      title: "Option",
      data: "Option",
    },
  ];

  return (
    <HtmlTable
      columnList={ColumNames}
      rowList={MaintenanceChecksReportData}
      id={"MaintenanceChecksReportTable"}
    ></HtmlTable>
  );
};
const AvaReportTable = () => {
  const ColumNames = [
    {
      title: "Station Name ",
      data: "StationName",
    },
    {
      title: "Equipment Code",
      data: "LiftName",
    },
    {
      title: "Last Maintained Date",
      data: "LastMaintainedate",
    },
    {
      title: "Last Breakdown Date",
      data: "LastDateofBreakdown",
    },
    {
      title: "Problem Description",
      data: "Remarks",
    },
    {
      title: "Status",
      data: "Status",
    },
    {
      title: "Action",
      data: "Action",
    },
  ];

  return (
    <HtmlTable
      columnList={ColumNames}
      rowList={MachineAvaChecksReportData}
      id={"AvaReportTableList"}
    ></HtmlTable>
  );
};

const ReportTable_1 = () => {
  const ColumNames = [
    {
      title: "StationCode ",
      data: "StationCode",
    },
    {
      title: "Description",
      data: "description",
    },
    {
      title: "Equipment Code",
      data: "LiftName",
    },

    {
      title: "Last Maintained Date",
      data: "LastMaintenanceDate",
    },
    {
      title: "Breakdown Date",
      data: "LastDateofBreakdown",
    },
    {
      title: "Attended Date",
      data: "LastDateofBreakdown",
    },
    {
      title: "Status",
      data: "Status",
    },
    {
      title: "Remarks",
      data: "remarks",
    },
    {
      title: "Action",
      data: "Action",
    },
  ];

  return (
    <HtmlTable
      columnList={ColumNames}
      rowList={MaintenanceChecksForChecks}
      id={"MaintenanceChecksReportTableForBM"}
    ></HtmlTable>
  );
};
