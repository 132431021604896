import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class RevenueConfigService {




    NonRevenue_GetEquipmentList(StationID) {
    return axios.get(`NonRevenueConfig/NonRevenue_GetEquipmentList?StationID=${StationID}`)

      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  NonRevenue_GetDataByID(RevenueHourID) {
    return axios.get(`NonRevenueConfig/NonRevenue_GetDataByID?RevenueHourID=${RevenueHourID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
 
  NonRevenue_MasterData(StationID) {
    return axios.get(`NonRevenueConfig/NonRevenue_MasterData?StationID=${StationID}`)
    
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
  }
 
  Config_NonRevenue_GetParameterList(StationID,EquipmentID) {
    return axios.get(`NonRevenueConfig/Config_NonRevenue_GetParameterList?StationID=${StationID}&EquipmentID=${EquipmentID}`)
    
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
  }

  async NonRevenueAddorEdit(data){
    return await axios
      .post("NonRevenueConfig/NonRevenueAddorEdit", data)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
 
  DeleteNonRevenueDetails(RevenueDetailID) {
    return axios.get(`NonRevenueConfig/NonRevenue_DeleteRevenueDetails?RevenueHourDetailID=${RevenueDetailID}`)
    
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
  }
  NonRevenue_DeleteMasterRevenue(RevenueHourID) {
    return axios.get(`NonRevenueConfig/NonRevenue_DeleteMasterRevenueDetails?RevenueHourID=${RevenueHourID}`)
    
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
  }



}