import React, { useRef, useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DashboardService from "../../screens/dashboard/DashboardService";
import { DataTableReports } from "../DataTableReports";
import BootrapModel from "../BootrapModel";
import zoomPlugin from "chartjs-plugin-zoom";
import $ from "jquery";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import { SpareModelData } from "../../components/charts/LineChart";
import { getDDMMYYdate, convert24hrto12hr } from "../../services/CommonService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

var _dashboardService = new DashboardService();

const columnNames = [
  { title: "Serial No" },
  { title: "Station Code" },
  { title: "Ticket No" },
  { title: "Equipment Tag No" },
  { title: "Parameter" },
  { title: "UOM" },
  { title: "Acceptable Parameter" },
  { title: "Actual Value" },
  { title: "Breakdown Date" },
  { title: "Breakdown Time" },
  { title: "Assigned By" },
  { title: "Assigned To" },
  { title: "Status" },
  // { title: "Problem Description" },
  // { title: "Remarks" },
];
export const BarChartWithModel = (props) => {
  const { data, yTitle, showLegend, id, clickable, title } = props;
  const ref = useRef();
  const [parameterName, setParameterName] = useState(null);

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    return data.labels[index];
  };

  const onClick = (event) => {
    const { current } = ref;

    if (!current) {
      return;
    }

    const points = current.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    var labelName;
    var slabel;
    if (points.length) {
      const firstPoint = points[0];
      labelName = current.data.labels[firstPoint.index];
      slabel = current.data.datasets[firstPoint.datasetIndex].label;
      const value =
        current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      // console.log(labelName, slabel, value);
    }

    var parameter =
      printElementAtEvent(getElementsAtEvent(current, event)) + "@" + slabel;
    setParameterName(parameter);
  };

  const closepopup = () => {
    setParameterName(null);
  };

  return (
    <div>
      {parameterName != null && parameterName != "" ? (
        <>
          <button
            hidden
            id="popupbutton"
            data-bs-toggle="modal"
            data-bs-target="#ParameterDetails"
          ></button>

          <BootrapModel
            Id={"ParameterDetails"}
            modelSize={"modal-xl"}
            ModelTitle={"Parameter Details"}
            module={
              <ParameterModelData
                parameterName={parameterName}
                count={0}
                title={title}
              />
            }
            closepopup={closepopup}
          />
        </>
      ) : (
        ""
      )}
      <Bar
        ref={ref}
        id={id}
        height={180}
        data={data}
        options={{
          plugins: {
            datalabels: {
              display: false,
            },
            zoom: {
              pan: {
                enabled: true,
                // onPanStart({ chart, point }) {
                //   const area = chart.chartArea;

                // },
                mode: "x",
              },
            },
            legend: {
              display: showLegend,
              position: "top",
              labels: {
                font: {
                  weight: "800",
                  style: "normal",
                },
              },
            },
          },
          scales: {
            x: {
              min: 0,
              max: 9,
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                autoSkip: true,
                callback: function (label, index, labels) {
                  // console.log(label, index, labels);
                  let val = `${data.labels[label]}`;
                  return val.length > 12 ? `${val.substring(0, 12)}...` : val;
                },
              },
            },
            y: {
              drawBorder: false,
              grace: "5%",
              ticks: {
                precision: 0,
                // forces step size to be 50 units
                // stepSize: steps ? steps : "",
              },
              title: {
                display: true,
                text: yTitle,
                color: "#333",
                font: {
                  size: 15,
                  weight: "600",
                  lineHeight: 1.2,
                },
                padding: { top: 0, left: 10, right: 10, bottom: 5 },
              },
            },
          },
        }}
        onClick={clickable ? onClick : ""}
      />
    </div>
  );
};

export const ParameterModelData = ({ parameterName, count, title }) => {
  const [parameterList, setParameterList] = useState([]);

  // table.res
  const GetChartFaultParameterDetails = () => {
    var fromdate = $("#FromDateforFault").val();
    var todate = $("#ToDateforFault").val();

    _dashboardService
      .GetChartFaultParameterDetails(parameterName, fromdate, todate)
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          // setSpareList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(i + 1);
            row.push(v.stationCode);
            row.push(v.ticketNo);
            row.push(v.equipmentCode);
            row.push(v.parameterName.split("***")[0]);
            v.parameterName.split("***").length > 0
              ? row.push(v.parameterName.split("***")[1])
              : row.push("");
            // row.push(v.parameterName.split("***")[1]);
            row.push(
              v.acceptableParameterLow + " - " + v.acceptableParameterHigh
            );
            row.push(v.currentReading);
            row.push(getDDMMYYdate(v.breakdownDate));
            row.push(v.breakdownTime);
            row.push(v.assignedByName);
            row.push(v.assignedToName);
            row.push(v.statusName);
            // row.push(v.problemDescription);
            // row.push(v.remarks);
            rowList.push(row);
          });
          setParameterList([...rowList]);
          $("#popupbutton").trigger("click");
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    GetChartFaultParameterDetails();
  }, [parameterName]);

  return (
    <DataTableReports
      columnList={columnNames}
      rowList={parameterList}
      id={"ParameterTable"}
      title={title}
      isAsc={true}
    />
  );
};

export const BarChartWithModelForSpare = ({
  data,
  yTitle,
  showLegend,
  id,
  clickable,
  title,
  year,
  month,
  mode,
}) => {
  // const { data, yTitle, showLegend, id, clickable, title } = props;
  const ref = useRef();
  // const [parameterName, setParameterName] = useState(null);

  const [SpareName, SeSpareName] = useState("");

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    return data.labels[index];
  };

  const onClick = (event) => {
    const { current } = ref;

    if (!current) {
      return;
    }
    const points = current.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    var labelName;
    var slabel;
    if (points.length) {
      const firstPoint = points[0];
      labelName = current.data.labels[firstPoint.index];
      slabel = current.data.datasets[firstPoint.datasetIndex].label;
      const value =
        current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      // console.log(labelName, slabel, value);
    }

    // const points = current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    // if (points.length) {
    //   const firstPoint = points[0];
    //   const label = current.data.labels[firstPoint.index];
    //   const slabel = current.data.datasets[firstPoint.datasetIndex].label;
    //   const value = current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
    //   console.log(label, slabel, value);
    // }

    var parameter = labelName + "@" + slabel;
    SeSpareName(parameter);
  };

  const closepopup = () => {
    SeSpareName(null);
  };

  return (
    <div>
      {SpareName != null && SpareName != "" ? (
        <>
          <button
            hidden
            id="popupbutton"
            data-bs-toggle="modal"
            data-bs-target="#SpareModeldetiles"
          ></button>

          <BootrapModel
            Id={"SpareModeldetiles"}
            modelSize={"modal-xl"}
            ModelTitle={"Highly Used Spare"}
            module={
              <SpareModelData
                sparename={SpareName}
                year={year}
                month={month}
                mode={mode}
                count={0}
                title={title}
              />
            }
            closepopup={closepopup}
          />
        </>
      ) : (
        ""
      )}
      <Bar
        ref={ref}
        id={id}
        height={180}
        data={data}
        options={{
          responsive: true,
          plugins: {
            datalabels: {
              display: false,
              align: "end",
              anchor: "end",
              color: function (context) {
                return context.dataset.backgroundColor;
              },
            },
            zoom: {
              pan: {
                enabled: true,
                onPanStart({ chart, point }) {
                  const area = chart.chartArea;
                },
                mode: "x",
              },
            },
            legend: {
              display: showLegend,
              position: "top",
              labels: {
                font: {
                  weight: "800",
                  style: "normal",
                },
              },
            },
          },
          scales: {
            x: {
              min: 0,
              max: 9,
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                autoSkip: true,
                callback: function (label, index, labels) {
                  // console.log(label, index, labels);
                  let val = `${data.labels[label]}`;
                  return val.length > 20 ? `${val.substring(0, 20)}...` : val;
                },
              },
              title: {
                color: "blue",
                display: false,
                align: "center",
              },
            },
            y: {
              drawBorder: false,
              grace: "5%",
              ticks: {
                precision: 0,

                // forces step size to be 50 units
                // stepSize: steps ? steps : "",
              },
              title: {
                display: true,
                text: yTitle,
                color: "#333",
                font: {
                  size: 15,
                  weight: "600",
                  lineHeight: 1.2,
                },
                align: "center",
                padding: { top: 0, left: 10, right: 10, bottom: 5 },
              },
            },
          },
        }}
        onClick={clickable ? onClick : ""}
      />
    </div>
  );
};
