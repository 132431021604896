import React from "react";
import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import Global from "../../services/Global";
import BreakdownMaintenanceService from "./BreakdownMaintenanceService";
import { saveAs } from "file-saver";
import $ from "jquery";
import { TbCameraSelfie } from "react-icons/tb";
import swal from "sweetalert";
import { ContextValues } from "../PeriodicMaintenance/PeriodicContextProvider";
import { DeepCopy, isImage } from "../../services/CommonService";
const obj = new BreakdownMaintenanceService();
export const DownLoadImagesForReport = ({
  FileDatas,
  DownLoadRefresh,
  DeleteAttachment,
  Flag,
  setSelectedData,
  handleImagePopUp
}) => {
  const [UniqueRole, SetUniqueRole] = useState([]);
  const [FilesObj, setFilesObj] = useState([]);
  const { setPeriodicModel } = ContextValues();
  useEffect(() => {
    $("#ImgOutput").html("");
    const unique = [...new Set(FileDatas.map((item) => item.RoleName))];
    SetUniqueRole(unique);
    setFilesObj(FileDatas);
    return () => {
      $("#ImgOutput").html("");
    };
  }, [FileDatas, DownLoadRefresh]);
  function DownloadOriginalFile(
    AttachmentID,
    fileName,
    IsFileAttached,
    ForShow = false
  ) {
    debugger;
    obj
      .fileDownload(AttachmentID, IsFileAttached, ForShow)
      .then(async (response) => {
        const contentType = response.headers["content-type"];
        if (ForShow) {
          let blobUrl = URL.createObjectURL(response.data); // Create a URL for the Blob
          displayImage(blobUrl);
        } else if (IsFileAttached == 5) {
          // Determine the file type based on the response headers or file extension
          if (contentType) {
            setPeriodicModel((pre) => ({
              ...pre,
              FileBlob: response.data,
              FileName: fileName,
            }));
          }
        } else {
          saveAs(
            new Blob([response.data], { type: contentType }),
            `${fileName}`
          );
        } // Display the image
      })
      .catch((res) => {});
  }
  function displayImage(blobUrl) {
    var outputDiv = document.getElementById("ImgOutput");
    outputDiv.innerHTML = ""; // Clear previous content
    var img = document.createElement("img");
    img.src = blobUrl; // Set the image source to the Blob URL
    img.style.maxWidth = "100%"; // Set maximum width to fit the container
    outputDiv.appendChild(img); // Append the image to the output div
  }
  function DeleteAttachmentDocument(TicketId, AttachID, Flag) {
    swal({
      title: "Are You Sure?",
      text: "Do You Want to Delete this Document?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteAttachment(TicketId, AttachID, Flag);
      }
    });
  }
  return (
    <>
      <div className="row">
        <div className="col-6 row">
          {UniqueRole != null && UniqueRole.length > 0 ? (
            UniqueRole.map((y) => {
              const RoleWiseFile = FilesObj.filter((x) => x.RoleName == y);
              return (
                <>
                  <ul className="list-group list-group-flush col-12">
                    <li className="list-group-item active rounded">{y}</li>
                    {RoleWiseFile?.map((x, index) => {
                      var data = CryptoJS.AES.encrypt(
                        x.Path + "\\" + x.GuidName,
                        Global.COOKIES.EnCryptKey
                      );
                      // JSON.stringify(data);
                      const { Type } = x;
                      let selfieIcon = () => "";
                      if (Type == "SELFIE") {
                        selfieIcon = () => <TbCameraSelfie />;
                      }
                      let CheckBox = false;
                      if (isImage(x.OrginalFile)) {
                        CheckBox = true;
                      }

                      return (
                        <li className="list-group-item link-secondary p-0">
                          <input
                            type="checkbox"
                            id={`Select_Image_${index}`}
                            key={index}
                            checked={x.Checked}
                            className="form-check-input"
                            onChange={(e) => {                                
                                let TempObj = DeepCopy(FilesObj);
                                let FinedObj = TempObj.find((ele) => ele.AttachmentID ==  x.AttachmentID);
                                FinedObj.Checked = e.target.checked;
                                setFilesObj(TempObj)
                            } }
                          />
                          <span
                            onClick={() => {
                              DownloadOriginalFile(
                                x.AttachmentID,
                                x.OrginalFile,
                                x.IsFileAttached,
                                true
                              );
                            }}
                          >
                            <span
                              className="EclispedotForDownload user-select"
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {x.OrginalFile}{" "}
                            </span>{" "}
                            {"   (" + x.EmployeeName + ")"} {selfieIcon()}
                          </span>
                          <span className="m-l-15 float-end">
                            <button
                              className="btn icon-btn hide"
                              title="Delete"
                              onClick={() =>
                                DeleteAttachmentDocument(
                                  x.TicketId,
                                  x.AttachmentID,
                                  Flag
                                )
                              }
                            >
                              <i className="fa fa-trash m-r-5"></i>
                            </button>
                          </span>
                          <span className="m-l-15 float-end">
                            <button
                              className="btn icon-btn "
                              title="Download"
                              onClick={() => {
                                DownloadOriginalFile(
                                  x.AttachmentID,
                                  x.OrginalFile,
                                  x.IsFileAttached
                                );
                              }}
                            >
                              <i className="fa fa-download m-r-5"></i>
                            </button>
                          </span>
                          <span className="m-l-15 float-end"></span>
                        </li>
                      );
                    })}
                  </ul>
             
                </>
              );
            })
          ) : (
            <span className="text-center">Sorry No Data Available </span>
          )}
        </div>
        <div className="col-6" id="ImgOutput">
          {" "}
        </div>
        <div className="row d-flex justify-content-end mt-3 mb-1 primary ">
                    <div className="col-md-2 m-6">
                      <button
                        type="button"
                        id=""
                        className={`add-btn btn secondary-btn`}
                        onClick={() => setSelectedData(FilesObj)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
      </div>
    </>
  );
};
