import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginStacked100
);

const totalizer = {
  id: "totalizer",
  beforeUpdate: (chart) => {
    let totals = {};
    let utmost = 0;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + value;
        });
      }
    });

    chart.$totalizer = {
      totals: totals,
      utmost: utmost,
    };
  },
};

const options = {
  plugins: {
    /* old */
    // stacked100: {
    //   enable: true,
    // },

    title: {
      display: true,
    },

    datalabels: {
      formatter: (_value, context) => {
        const data = context.chart.data;
        const { datasetIndex, dataIndex } = context;

        return `${data.originalData[datasetIndex][dataIndex]}`;
      },
      display: function (context) {
        var dataset = context.dataset;
        var count = dataset.data.length;
        var value = dataset.data[context.dataIndex];

        return value > 0;
      },
      align: "start",
      anchor: "end",
      color: "white",

      font: {
        size: 12,
      },
    },
  },

  responsive: true,

  scales: {
    x: {
      // stacked: true,
    },
    y: {
      beginAtZero: true,
      ticks: {
        display: false, // hide the ticks
      },
      title: {
        display: true,
        // text: "No of Jobs",
        color: "#333",
        font: {
          size: 14,
          weight: "600",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 10, right: 10, bottom: 5 },
      },
      // stacked: true,
    },
  },
};

export default function StackedChart({
  data,
  IsShow,
  yLabel,
  id,
  xLabel,
  BarClick,
  reff,
  IsHorizontal,
}) {
  return (
    <>
      {IsShow ? (
        <Bar
          ref={reff}
          // ref={ref ?? null}
          data={data}
          id={id}
          options={{
            indexAxis: IsHorizontal ? "y" : "x",

            plugins: {
              /* old */
              // stacked100: {
              //   enable: true,
              // },

              title: {
                display: true,
              },
              tooltip: {
                mode: "nearest",
                axis: "x",
                callbacks: {
                  label: (context) => {
                    let temp = data?.datasets;
                    let total = temp
                      .map((x) => x.data[context.dataIndex])
                      .reduce((acc, cur) => acc + cur, 0);
                    const dataset = context.dataset;
                    const value = dataset.data[context.dataIndex];
                    const percentage = ((value / total) * 100).toFixed(2);
                    return dataset.label + ": " + percentage + "%";
                  },
                },
              },

              datalabels: {
                // formatter: (_value, context) => {
                //   const data = context.chart.data;
                //   const { datasetIndex, dataIndex } = context;

                //   return `${data.originalData[datasetIndex][dataIndex]}`;
                // },
                display: function (context) {
                  var dataset = context.dataset;
                  var count = dataset.data.length;
                  var value = dataset.data[context.dataIndex];

                  return value > 0;
                },
                align: "center",
                anchor: "center",
                color: "#ffffff",
                textAlign: "start",
                offset: 10,
                padding: {
                  top: 30,
                },

                font: {
                  size: 9,
                },
              },
            },

            responsive: true,

            scales: {
              x: {
                stacked: true,

                //  ticks: { maxRotation: 90, minRotation: 90 },
                title: {
                  display: true,
                  text: xLabel ?? "",
                  color: "#333",
                  font: {
                    size: 14,
                    weight: "600",
                    lineHeight: 1.2,
                  },
                  padding: { top: 0, left: 10, right: 10, bottom: 5 },
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  display: true, // hide the ticks
                },
                title: {
                  display: true,
                  text: yLabel,
                  color: "#333",
                  font: {
                    size: 14,
                    weight: "600",
                    lineHeight: 1.2,
                  },
                  padding: { top: 0, left: 10, right: 10, bottom: 5 },
                },
                stacked: true,
              },
            },

            onClick:
              typeof BarClick == "function"
                ? BarClick
                : (function () {
                    return false;
                  })(),
          }}
          plugins={{
            id: `totalizer_${id}`,
            beforeUpdate: (chart) => {
              let totals = {};
              let utmost = 0;

              chart.data.datasets.forEach((dataset, datasetIndex) => {
                if (chart.isDatasetVisible(datasetIndex)) {
                  utmost = datasetIndex;
                  dataset.data.forEach((value, index) => {
                    totals[index] = (totals[index] || 0) + value;
                  });
                }
              });

              chart.$totalizer = {
                totals: totals,
                utmost: utmost,
              };
            },
          }}

          // onClick={  typeof BarClick == "function" ?
          // BarClick : (function() { return false; }())
          // }
        >
          {" "}
        </Bar>
      ) : (
        <div className="d-flex justify-content-center text-center">
          Sorry, No Schedule found
        </div>
      )}
    </>
  );
}
