import React from "react";

function BootrapModel({
  Id,
  modelSize = null,
  ModelTitle,
  module,
  closepopup,
  CloseID,
  CloseHide = false,
}) {
  return (
    <div>
      <div
        id={Id}
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static"
      >
        <div className={`modal-dialog modal-dialog-centered ${modelSize ?? "modal-xl"}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ModelTitle}</h5>
              {CloseHide ? (
                ""
              ) : (
                <button
                  type="button"
                  id={CloseID}
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closepopup}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            <div className="modal-body">{module}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BootrapModel;
