import React from "react";
import {
  getDDMMYYdate,
  GetLoginUserID,
  GetPeriodicStatus,
  getPrivilege,
  DownloadIcon,
  CSHARP_encrypt,
  remarksAndDescriptionDownload
} from "../../services/CommonService";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import { HtmlTable } from "../../components/HtmlTable";
import { useState, useEffect, useRef, useContext } from "react";
import $, { data } from "jquery";
import MasterDetailService from "../master/MasterDetailService";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import makeAnimated from "react-select/animated";
import { PeriodicService } from "./PeriodicService";
import { set, toDate } from "date-fns";
import { DataTableReports } from "../../components/DataTableReports";
import { AppContext } from "../../services/ContextProvider";
import { Dropdown } from "../../components/Dropdown";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { COOKIES } from "../../services/Global";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { saveAs } from 'file-saver';
import { BASE_URL } from "../../env";
import moment from  "moment"
import ExcelJS from 'exceljs';
// import * as from "xlsx-style"
// import   ExcelJS from 'excel4node';
const XLSX = require("xlsx")
const _periodicServices = new PeriodicService();

const MDS = new MasterDetailService();
const animatedComponents = makeAnimated();

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ReportPeriodic = () => {
  const { showLoading, hideLoading, getCookies,handleGlobalStationName } = useContext(AppContext);

  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [rowList, setData] = useState([]);
  const [FromDate, SetfromDate] = useState("");
  const [ToDate, SetToDate] = useState("");
  const [periodicTypeList, setPeriodicTypeList] = useState([]);
  const [EqipmentList, setEquipmentList] = useState([]);
  const [EqipmentID, setEquipmentID] = useState(0);
  const [stationIds, setStationIds] = useState("");

  const [periodicType, setPeriodicType] = useState(1);

  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const tableName = "periodc_report";

   const columns = [
    { data: "RowId", title: "Serial No" },
    { data: "StationCode", title: "Station Code" },
    { data: "FromDate", title: "Scheduled Date" },
    { data: "EquipmentShortName", title: "Equipment" },
    { data: "EquipmentCode", title: "Equipment Tag No" },
    { data: "MaintenanceTypeName", title: "Maintenance Type" },
    {
      data: "ApprovedBy",
      title: "Approved / Rejected By",
    },
    {
      data: "JobDoneBy",
      title: "Job Done By",
    },
   
    {
      data: "PWTNumber",
      title: "PTW Number",
    },
    {
      data: "PWTDate",
      title: "PTW Date",
    },
    {
      data: "ClosedDate",
      title: "Closed Date",
    },   
    {
      data: "StatusName",
      title: "Status",
    },
    {
      title: "Download Remarks",
      data: "JobScheduleID",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<button class='btn icon-btn text-center' title='Download Remarks'>
        <i class='fa fa-download m-r-5'></i>
      </button>`).on("click", function () {
        ReportGetJobDescriptionUsingJobID(rowData.JobScheduleID,rowData.EquipmentDetailID,rowData);
      });;
        $(td).html(b);
      },
    } ,
    {
      title: "Download",
      data: "download_url",
      visible: false,
      className : "hide"
    }
  ];






  const ReportGetJobDescriptionUsingJobID = (jobScheduleID = 0, EquipmentDetailID = 0,rowdata) => {
    

    _periodicServices.ReportGetJobDescriptionUsingJobID(EquipmentDetailID,jobScheduleID).then((response) => {
      const {data} = response.data
      var jobDescriptionList = []
      if (data?.length > 0) {


        rowdata["Station Code"] = rowdata.StationCode
        rowdata["Equipment Short Name"] = rowdata.EquipmentShortName
        rowdata["Maintenance Type Name"] = rowdata.MaintenanceTypeName
        rowdata["Approved By"] = rowdata.ApprovedBy
        rowdata["Job Done By"] = rowdata.JobDoneBy
        rowdata["Status Name"] = rowdata.StatusName
        rowdata["Closed Date"] = rowdata.ClosedDate
        rowdata["Equipment Code"] = rowdata.EquipmentCode
        
        const res = data.map((x) => {
          var obj = { ...rowdata };
          obj["Job Description"] = x.Description
          obj["Remarks By Engineer"] = x.RemarksByEngineer
          obj["Remarks By Technician"] = x.RemarksByTechncian
          obj["Downloads"] = x.AttachementFlag == 1 ? obj.download_url : "No Attachment Found"
          obj["Scheduled Date"] = obj.FromDate
          jobDescriptionList.push(obj)
          return obj
        })
        
        remarksAndDescriptionDownload(res)  
      }
     

      // saveAs(new Blob([response.data], { type: contentType }), `${file_name}`)
    }).catch((err) => 
    {
      console.log(err)
    })
  };







  const [isTable, setIsTable] = useState(false);

  

  useEffect(() => {
    getStationList();
    Report_GetJobEquipmentList();
    getType();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    SetfromDate(firstDay.toISOString().split("T")[0]);
    SetToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];
    let ignoredStation = [1,2,3]


    stationList.map((data) => {
      if(ignoredStation.includes(data.StationID))
         dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    if(dataLst?.length){
      HandleChange([dataLst[0]]);
    }

    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };

  const HandleChange = (e) => {
    let StationLabel = e.map(x => x.label).join()
    handleGlobalStationName(StationLabel)
    SetStationID(e);
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });

    if (FromDate != null) {
      $("#ToDate").attr("min", FromDate);
    }

    if (ToDate != null) {
      $("#FromDate").attr("max", ToDate);
    }
    setStationIds(stationIds);
    // getData(stationIds, periodicType, FromDate, ToDate, EqipmentID);

    return () => {
      setData([]);
    };
  }, [StationID, FromDate, ToDate, periodicType, EqipmentID]);

  const getDataSample = () => {
    var empID = GetLoginUserID();

    showLoading();
    // let startTime = performance.now();

    _periodicServices
      .GetSampleData()
      .then((data) => {
        var datas = [];
        if (data != null && data.data != null && data.data.length > 0) {
          data.data.map((c, i) => {
            var dataLst = [];

            dataLst.push(i + 1);
            dataLst.push(c.StationCode);
            dataLst.push(c.FromDate);
            dataLst.push(c.EquipmentShortName);
            dataLst.push(c.EquipmentCode);
            dataLst.push(
              c.MaintenanceTypeName.charAt(0) +
                c.MaintenanceTypeName.toLowerCase().slice(1)
            );
            // dataLst.push(c.Description);
            // dataLst.push(c.MaintenanceTypeID);
            dataLst.push(c.ApprovedBy);
            dataLst.push(c.JobDoneBy);
            // dataLst.push(c.JobStatus);
            // dataLst.push(c.RemarksForTech);
            // dataLst.push(c.RemarksForEngg);
            // dataLst.push(`${c.JobStatus}|${c.StatusName}`);
            dataLst.push(c.StatusName);
            c.download_url =  DownloadIcon(c.JobScheduleID,c.EquipmentDetailID,c.EquipmentCode)
            dataLst.push(c.download_url);
            dataLst.push(c.JobScheduleID);
            dataLst.push(c.EquipmentDetailID);

            // dataLst.push("");
            datas.push(dataLst);
          });
          setData(datas);
          // let endTime = performance.now();
          // let timeTaken = endTime - startTime;
          // console.log(`Time taken for API call: ${timeTaken} milliseconds`);
          hideLoading();
        } else {
          setData([]);
          hideLoading();
        }
      })
      .catch((err) => hideLoading());
  };

  const getData = (stationIds, periodicType) => {
    // stationIds = stationIds = "" ? null : stationIds;
    var empID = GetLoginUserID();

    ;
    if (
      stationIds != null &&
      stationIds != "" &&
      periodicType != null &&
      FromDate != "" &&
      ToDate != "" &&
      EqipmentID != null &&
      EqipmentID != 0
    ) {
      showLoading();
      // let startTime = performance.now();

      _periodicServices
        .Report_GetJobsForPeriodic(
          FromDate,
          ToDate,
          periodicType,
          stationIds,
          EqipmentID
        )
        .then((data) => {
          var datas = [];
          if (data != null && data.data != null && data.data.length > 0) {
            data.data.map((c, i) => {
              var dataLst = [];

              dataLst.push(i + 1);
              dataLst.push(c.StationCode);
              dataLst.push(c.FromDate);
              dataLst.push(c.EquipmentShortName);
              dataLst.push(c.EquipmentCode);
              dataLst.push(
                c.MaintenanceTypeName.charAt(0) +
                  c.MaintenanceTypeName.toLowerCase().slice(1)
              );
              // dataLst.push(c.Description);
              // dataLst.push(c.MaintenanceTypeID);
              dataLst.push(c.ApprovedBy);
              dataLst.push(c.JobDoneBy);
              // dataLst.push(c.JobStatus);
              // dataLst.push(c.RemarksForTech);
              // dataLst.push(c.RemarksForEngg);
              // dataLst.push(`${c.JobStatus}|${c.StatusName}`);
              dataLst.push(c.StatusName);

              // dataLst.push("");
              datas.push(dataLst);
            });
            setData(datas);
            // let endTime = performance.now();
            // let timeTaken = endTime - startTime;
            // console.log(`Time taken for API call: ${timeTaken} milliseconds`);
            hideLoading();
          } else {
            setData([]);
            hideLoading();
          }
        })
        .catch((err) => hideLoading());
      // _periodicServices
      //   .Report_GetJobsForPeriodic(FromDate, ToDate, periodicType, stationIds, EqipmentID)
      //   .then((data) => {

      //     var datas = [];
      //     if (data != null && data.data != null && data.data.length > 0) {
      //       data.data.map((c, i) => {
      //         var dataLst = [];

      //         dataLst.push(i + 1);
      //         dataLst.push(c.StationCode);
      //         dataLst.push(c.FromDate);
      //         dataLst.push(c.EquipmentShortName);
      //         dataLst.push(c.EquipmentCode);
      //         dataLst.push(
      //           c.MaintenanceTypeName.charAt(0) +
      //           c.MaintenanceTypeName.toLowerCase().slice(1)
      //         );
      //         // dataLst.push(c.Description);
      //         // dataLst.push(c.MaintenanceTypeID);
      //         dataLst.push(c.ApprovedBy);
      //         dataLst.push(c.JobDoneBy);
      //         // dataLst.push(c.JobStatus);
      //         // dataLst.push(c.RemarksForTech);
      //         // dataLst.push(c.RemarksForEngg);
      //         // dataLst.push(`${c.JobStatus}|${c.StatusName}`);
      //         dataLst.push(c.StatusName);

      //         // dataLst.push("");
      //         datas.push(dataLst);
      //       });
      //       setData(datas);
      //       // let endTime = performance.now();
      //       // let timeTaken = endTime - startTime;
      //       // console.log(`Time taken for API call: ${timeTaken} milliseconds`);
      //       hideLoading();

      //     }
      //     else {
      //       setData([]);
      //       hideLoading();
      //     }
      //   }).catch((err) => hideLoading());
    } else {
      setData([]);
      hideLoading();
    }
  };

  const Report_GetJobEquipmentList = () => {
    _periodicServices
      .Report_GetJobEquipmentList()
      .then((data) => {
        var datas = [];
        if (data != null && data.data != null && data.data[0].length > 0) {
          data.data[0].map((c, i) => {
            // var dataLst = [];
            datas.push({ key: c.EquipmentShortName, value: c.EquipmentID });
          });
          if (datas.length > 0) {
            datas.push({ key: "All Equipments", value: -1 });
          }
          setEquipmentList(datas);
          setIsTable(true);
          // hideLoading();
        } else {
          setEquipmentList([]);
          // hideLoading();
        }
        if (datas.length > 0) {
          setEquipmentID(datas[0].value);
        }
      })
      .catch(
        (err) => console.log(err)
        // hideLoading()
      );
  };
  const getType = () => {
    var dataLst = [];
    dataLst.push({ key: "Daily", value: 1 });
    dataLst.push({ key: "Monthly", value: 2 });
    dataLst.push({ key: "Quarterly", value: 3 });
    dataLst.push({ key: "Half-Yearly", value: 4 });
    dataLst.push({ key: "Yearly", value: 5 });
    dataLst.push({ key: "All Type", value: -1 });

    setPeriodicTypeList(dataLst);
  };
  const HandleChangeEquipment = (e) => {
    setEquipmentID(e.target.value);
  };
  const HandleChangetype = (e) => {
    setPeriodicType(e.target.value);
  };

  






  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-md-2 mt-2">
            <h4 className="page-title">Periodic Maintenance Report</h4>
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Equipment</label>
            <Dropdown
              placeholder="--Select Equipment--"
              name="equipment"
              data={EqipmentList}
              // initialVal={0}

              HandleChange={HandleChangeEquipment}
              value={EqipmentID}
            />
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Type</label>
            <Dropdown
              placeholder="--Select Type--"
              name="type"
              data={periodicTypeList}
              // initialVal={0}
              HandleChange={HandleChangetype}
              value={periodicType}
            />
          </div>

          <div className="col-md-2">
            <label className="col-form-label">From Date</label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              defaultValue={FromDate}
              onChange={(e) => {
                SetfromDate(e.target.value);
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="col-form-label">To Date</label>

            <input
              type="date"
              className="form-control"
              id="ToDate"
              defaultValue={ToDate}
              onChange={(e) => {
                SetToDate(e.target.value);
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Stations</label>

            <ReactMultiSelect
              options={StationList}
              isMulti
              value={StationID}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => HandleChange(e)}
              allowSelectAll={true}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <div className="">
          {isTable ? (
            <DataTableReportAjax
              columnList={columns}
              id={"PeriodicReport"}
              title={"Periodic Maintenance Report"}
              isAsc={true}
              url={`${BASE_URL}PeriodicMaintenance/Report_GetJobsForPeriodic`}
              EquipmentID={EqipmentID}
              StationID={stationIds ? stationIds.join(",") : "0"}
              FromDate={FromDate}
              ToDate={ToDate}
              Type={periodicType}
              exportOptions={   {
                columns: [0, 1, 2,3,4,5,6,7,8,9,10,11],
               
            }}
            />
          ) : (
            <>
              <div className="text-center" style={{ margin: "10rem" }}>
                <div className="loader-Wrapper">
                  <div className="Customloader-div">
                    <span className="loader"></span>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <DataTableReports columnList={columnNames} rowList={data} id={'PeriodicReport'} title={"Periodic Maintenance Report"} isAsc={true} /> */}
        </div>
      </div>
    </>
  );
};
