import React, { useState, useEffect, useContext } from "react";
import {
  LinechartWithCard,
  BarchartWithCardForSpare,
} from "./widgets/ChartWithCard";
import {
  randomArray,
  COLORS,
  MyCOLORS,
  StationColors,
} from "./../../services/CommonService";
import { FilterDropdown } from "../../components/FilterDropdown";
import MasterDetailService from "../master/MasterDetailService";
import {
  ReactMultiSelect,
  Option,
  MultiValue,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import DashboardService from "./DashboardService";
import { Link } from "react-router-dom";

import {
  GetLoginUserID,
  getPrivilege,
  Decrypt,
} from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
const datasOne = randomArray(11, 1000);
const datasTwo = randomArray(11, 200);

const _masterDetailService = new MasterDetailService();
const _dashboardService = new DashboardService();

// var sparedata = [{
//   stationCode: "STC",
//   value: [{ label: "Bearing", data: 20 }, { label: "Cooling Wheel", data: 22 }, { label: "Filter", data: 8 }, { label: "Compressor", data: 12 }, { label: "Blade", data: 25 }, { label: "Sample", data: 30 }, { label: "Wheel", data: 10 }, { label: "Fan", data: 50 }]
// }, {
//   stationCode: "STR",
//   value: [{ label: "Bearing", data: 15 }, { label: "Cooling Wheel", data: 32 }, { label: "Compressor", data: 22 }, { label: "Blade", data: 20 }, { label: "Wheel", data: 20 }, { label: "Fan", data: 40 }, { label: "wall", data: 20 }]
// }
// ]
// var sparedata = [
//   {
//     "stationCode": "STC",
//     "dashboardData": [
//       {
//         "label": "Cooling Wheel",
//         "data": 55
//       },
//       {
//         "label": "Bolt",
//         "data": 27
//       },
//       {
//         "label": "gASKET",
//         "data": 12
//       },
//       {
//         "label": "Solenoid",
//         "data": 10
//       },
//       {
//         "label": "Strainer",
//         "data": 10
//       }
//     ]
//   },
//   {
//     "stationCode": "STR",
//     "dashboardData": [
//       {
//         "label": "Cooling Wheel",
//         "data": 11
//       }
//     ]
//   }
// ]

// var datasets = sparedata.map((v, i) => {
//   return (
//     {
//       label: v.stationCode,
//       data: v.dashboardData,
//       backgroundColor: COLORS.blue,
//       borderColor: COLORS.blue,
//       fill: false,
//       tension: 0.1,
//       pointStyle: "circle",
//       pointRadius: 5,
//       pointHoverRadius: 10,
//     }
//   )
// })

// const dataOne = {
//   labels: label,
//   datasets: datasets,
// };

// const dataTwo = {
//   labels: label,
//   datasets: datasets
// };

const Filter = ({ code, onChange, value }) => {
  return (
    <div className="col">
      <FilterDropdown code={code} onChange={onChange} value={value} />
    </div>
  );
};
const SpareGraph = ({ stationID }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [monthName, setMonthName] = useState("");
  const [spareYearwise, setSpareYearwise] = useState({});
  const [spareMonthwise, setSpareMonthwise] = useState({});

  const onYearChange = (e) => {
    setYear(e.target.value);
  };
  const onMonthChange = (e) => {
    const seletedDate = new Date(e.target.value);
    const currentMonthName = seletedDate.toLocaleString("default", {
      month: "long",
    });

    setMonthName(currentMonthName);
    setMonth(e.target.value);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString("default", {
      month: "long",
    });
    setMonthName(currentMonthName);
  }, []);
  useEffect(() => {
    if (stationID != null && stationID != "") {
      _dashboardService
        .GetSpareDashboardData(year, 0, stationID, 1)
        .then((response) => {
          var rowData = [];
          var labelData = [];
          if (response.data != null && response.data.length > 0) {
            if (
              response.data[0].labels != null &&
              response.data[0].labels.length > 0
            ) {
              response.data[0].labels.map((data, i) => {
                return labelData.push(data);
              });
            } else {
              labelData.push("");
            }
            if (
              response.data[0]?.spareCharts != undefined &&
              response.data[0].spareCharts != null &&
              response.data[0].spareCharts.length > 0
            ) {
              response.data[0].spareCharts.map((v, i) => {
                const res = labelData.map((x, i) => {
                  const data = v.dashboardData.find((y) => y.label == x);
                  if (data != undefined) {
                    return data.data;
                  }
                });

                return rowData.push({
                  label: v.stationCode,
                  data: res,
                  backgroundColor: v.stationColor,
                  //backgroundColor: v.stationCode == 'SWA' ? StationColors[2] : v.stationCode == 'STR' ? StationColors[1] : StationColors[0],
                  hoverBorderWidth: 3,
                  hoverBorderColor: "#764ba2",
                });
              });
            }

            var rowList = {
              labels: labelData,
              datasets: rowData,
            };

            setSpareYearwise({ ...rowList });
          } else {
            setSpareYearwise({});
          }
        });
      _dashboardService
        .GetSpareDashboardData(year, month, stationID, 2)
        .then((response) => {
          var rowData = [];
          var labelData = [];
          if (response.data != null && response.data.length > 0) {
            if (
              response.data[0].labels != null &&
              response.data[0].labels.length > 0
            ) {
              response.data[0].labels.map((data, i) => {
                return labelData.push(data);
              });
            } else {
              labelData.push("");
            }
            if (
              response.data[0]?.spareCharts != undefined &&
              response.data[0].spareCharts != null &&
              response.data[0].spareCharts.length > 0
            ) {
              response.data[0].spareCharts.map((v, i) => {
                const res = labelData.map((x, i) => {
                  const data = v.dashboardData.find((y) => y.label == x);
                  if (data != undefined) {
                    return data.data;
                  }
                });

                return rowData.push({
                  label: v.stationCode,
                  data: res,
                  backgroundColor: v.stationColor,
                  //backgroundColor: v.stationCode == 'SWA' ? StationColors[2] : v.stationCode == 'STR' ? StationColors[1] : StationColors[0],
                  hoverBorderWidth: 3,
                  hoverBorderColor: "#764ba2",
                });
              });
            }

            var rowList = {
              labels: labelData,
              datasets: rowData,
            };
            console.log(rowList);
            setSpareMonthwise({ ...rowList });
          } else {
            setSpareMonthwise({});
          }
        });
    } else {
      var rowData = [];
      var labelData = [];
      labelData.push("");
      var rowList = {
        labels: labelData,
        datasets: rowData,
      };
      setSpareYearwise({ ...rowList });
      setSpareMonthwise({ ...rowList });
    }
  }, [year, month, stationID]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          {spareYearwise != null ? (
            <BarchartWithCardForSpare
              title={"Highly Used Spare (Yearly)"}
              data={spareYearwise}
              yTitle={"No of Spares"}
              min={-1}
              max={-1}
              id={"spare"}
              year={year}
              month={month}
              YearmonthName={year}
              download={true}
              clickable={true}
              mode={1}
              isModel
              charttitle={"Station"}
              child={
                <Filter code="Year" onChange={onYearChange} value={year} />
              }
            />
          ) : (
            <>Loading...</>
          )}
          {spareMonthwise != null ? (
            <BarchartWithCardForSpare
              title={"Highly Used Spare (Monthly)"}
              data={spareMonthwise}
              yTitle={"No of Spares"}
              min={-1}
              max={-1}
              id={"spareMonth"}
              year={year}
              month={month}
              YearmonthName={monthName}
              download={true}
              mode={2}
              clickable={true}
              isModel
              charttitle={"Station"}
              child={
                <Filter code="Month" onChange={onMonthChange} value={month} />
              }
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </div>
  );
};

export const Spare = ({ TabID, TabValue }) => {
  const animatedComponents = makeAnimated();

  const [stationList, setStationList] = useState([]);
  const [stationIds, setStationDropdown] = useState([]);
  const [stationallIds, setStationIds] = useState(null);

  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    setStationDropdown(dataLst);
    HandleChange(dataLst);
  };

  useEffect(() => {
    if (TabID == TabValue) {
      getStationList();
    }
  }, [TabValue]);

  function HandleChange(e) {
    var val = [];
    e.map((v, i) => val.push(v.value));
    setStationIds(val.join(","));
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);

    setStationDropdown(e);
  }

  return (
    <>
      {/* Equipment Filter */}
      <div className="row justify-content-space-between mt-0 mb-3">
        {getPrivilege().includes("REPORTVIEW") && (
          <div className="col-4">
            <Link
              to="/sparereport"
              className="btn btn-primary"
              state={{ isBack: "True" }}
              title="Spare"
            >
              <i className="la la-file"></i> <span> Report</span>{" "}
            </Link>
          </div>
        )}
        {windowWidth > 800 && <div className="col-4"></div>}
        {/* <div className="col-6"></div> */}
        <div className={windowWidth > 800 ? "col-4" : "col-8"}>
          <ReactMultiSelect
            options={stationList}
            isMulti
            value={stationIds}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder={"Select Station"}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => HandleChange(e)}
            allowSelectAll={true}
          />
        </div>
      </div>
      <SpareGraph stationID={stationallIds} />
    </>
  );
};
