import React from "react";
import {
  Dropdown,
  HtmlTable,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  ReactAutoComplete,
} from "../../components";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  addDays,
  checkColVisible,
} from "../../services/CommonService";
import { useState, useEffect, useContext, useReducer } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  ChillerMonitoring_GetChillerMonitoringData,
  ChillerMonitoring_GetEquipmentCode,
  ChillerMonitoring_SaveChillerMonitoring,
  ChillerMonitoring_ApprovelRejectRequest,
} from "./DailyElectricalPanelservice";
import $ from "jquery";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { ReactMultiSelect } from "../../components";

export default function ChillerMonitoring({ TabValue }) {
  const initialEquipmentState = {
    RoomID: [],
    StationID: "",
    IsDeleted: false,
    checkAllAttended: 0,
    CurrentDate: getYMDdate(new Date()),
    checkAllApproved: false,
    checkAllRejected: false,
    EquipmentLocation: "",
    OffStartTime: "",
    IsChillerMonitorAllCheck: 1,
    TVS_Consumption: "",
    VAC_Consumption: "",
    Chiller1: "",
    Chiller2: "",
    Chiller3: "",
    OnTime: '',
    OffTime: '',
  };
  const [StationList, setStationList] = useState([]);
  const [errors, setErrors] = useState({
    RoomID: "",
    StationID: "",
  });
  const [RoomList, setRoomList] = useState([]);
  const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
  const [EquipmentLocationList, setEquipmentLocationList] = useState([]);
  const [TimeandHoursList, setTimeandHoursList] = useState([]);
  const [ListPageRender, SetListPageRender] = useReducer((x) => x + 1, 0);
  const [ChillerMonitoringDetailList, setChillerMonitoringDetailList] =
    useState([]);

  const [ChillerModel, SetChillerModel] = useState(initialEquipmentState);
  const [onoffTiime, setonoffTiime] = useState({
    OnTime: 0,
    OffTime: 0,
  });

  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);
  let offStartTimedefault = 0;
  let offEndTimedefault = 4;

  /**
   * Column Names for the Daily Electrical Panel Detail   Table.
   */
  let min = ChillerModel?.OffStartTime?.value ?? 0;
  let max = ChillerModel?.OffEndTime?.value ?? 0;

  const columnNames = [
    {
      title: `<p style="text-align: center;">ChillerMonitoringDWNID</p>`,
      data: "ChillerMonitoringDWNID",
      visible: false,
    },
    {
      title: `<p style="text-align: center;">Description</p>`,
      data: "ChillerName",
    },

    {
      title: `<p style="text-align: center;">Min/ Max Level</p>`,
      data: "MaxMinLevel",
    },
    {
      title: `<p style="text-align: center;">Units</p>`,
      data: "Units",
    },

    {
      title: `<p style="text-align: center;">0.00</p>`,
      data: "Time_0_Start",

      className: checkColVisible(min, max, 1) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_0_Start");
      },
    },

    {
      title: `<p style="text-align: center;">1.00</p>`,
      data: "Time_0_End",
      className: "hide",
      // className: checkColVisible(min, max, 1) ? "hide" : "",
      // createdCell: function (td, cellData, rowData, row, col) {
      //   createdCommonCell(td, cellData, rowData, row, col, "Time_0_End");
      // },
    },
    {
      title: `<p style="text-align: center;">2.00</p>`,
      data: "Time_2_Start",
      className: checkColVisible(min, max, 2) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_2_Start");
      },
    },
    {
      title: `<p style="text-align: center;">3.00</p>`,
      data: "Time_2_End",
      className: "hide",
      // className: checkColVisible(min, max, 2) ? "hide" : "",
      // createdCell: function (td, cellData, rowData, row, col) {
      //   createdCommonCell(td, cellData, rowData, row, col, "Time_2_End");
      // },
    },
    {
      title: `<p style="text-align: center;">4.00</p>`,
      data: "Time_4_Start",
      className: checkColVisible(min, max, 3) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_4_Start");
      },
    },
    {
      title: `<p style="text-align: center;">5.00</p>`,
      data: "Time_4_End",
      className: "hide",
      // className: checkColVisible(min, max, 3) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_4_End");
      },
    },
    {
      title: `<p style="text-align: center;">6.00</p>`,
      data: "Time_6_Start",
      className: checkColVisible(min, max, 4) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_6_Start");
      },
    },
    {
      title: `<p style="text-align: center;">7.00</p>`,
      data: "Time_6_End",
      className: "hide",
      // className: checkColVisible(min, max, 4) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_6_End");
      },
    },
    {
      title: `<p style="text-align: center;">8.00</p>`,
      data: "Time_8_Start",
      className: checkColVisible(min, max, 5) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_8_Start");
      },
    },
    {
      title: `<p style="text-align: center;">9.00</p>`,
      className: "hide",
      data: "Time_8_End",
      className: "hide",
      // className: checkColVisible(min, max, 5) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_8_End");
      },
    },
    {
      title: `<p style="text-align: center;">10.00</span></p>`,
      data: "Time_10_Start",
      className: checkColVisible(min, max, 6) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_10_Start");
      },
    },
    {
      title: `<p style="text-align: center;">11.00</span></p>`,
      data: "Time_10_End",
      className: "hide",
      // className: checkColVisible(min, max, 6) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_10_End");
      },
    },
    {
      title: `<p style="text-align: center;">12.00</p>`,
      data: "Time_12_Start",
      className: checkColVisible(min, max, 7) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_12_Start");
      },
    },
    {
      title: `<p style="text-align: center;">13.00</p>`,
      data: "Time_12_End",
      className: "hide",
      // className: checkColVisible(min, max, 7) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_12_End");
      },
    },
    {
      title: `<p style="text-align: center;">14.00</p>`,
      data: "Time_14_Start",
      className: checkColVisible(min, max, 8) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_14_Start");
      },
    },
    {
      title: `<p style="text-align: center;">15.00</p>`,
      data: "Time_14_End",
      className: "hide",
      // className: checkColVisible(min, max, 8) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_14_End");
      },
    },
    {
      title: `<p style="text-align: center;">16.00</p>`,
      data: "Time_16_Start",
      className: checkColVisible(min, max, 9) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_16_Start");
      },
    },
    {
      title: `<p style="text-align: center;">17.00</p>`,
      data: "Time_16_End",
      className: "hide",
      // className: checkColVisible(min, max, 9) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_16_End");
      },
    },
    {
      title: `<p style="text-align: center;">18.00</p>`,
      data: "Time_18_Start",
      className: checkColVisible(min, max, 10) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_18_Start");
      },
    },
    {
      title: `<p style="text-align: center;">19.00</p>`,
      data: "Time_18_End",
      className: "hide",
      // className: checkColVisible(min, max, 10) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_18_End");
      },
    },
    {
      title: `<p style="text-align: center;">20.00</p>`,
      data: "Time_20_Start",
      className: checkColVisible(min, max, 11) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_20_Start");
      },
    },
    {
      title: `<p style="text-align: center;">21.00</p>`,
      data: "Time_20_End",
      className: "hide",
      // className: checkColVisible(min, max, 11) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_20_End");
      },
    },
    {
      title: `<p style="text-align: center;"> 22.00</p>`,
      data: "Time_22_Start",
      className: checkColVisible(min, max, 12) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_22_Start");
      },
    },
    {
      title: `<p style="text-align: center;"> 23.00</p>`,
      data: "Time_22_End",
      className: "hide",
      // className: checkColVisible(min, max, 12) ? "hide" : "",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_22_End");
      },
    },
    {
      title: `<p style="text-align: center;"> Status</p>`,
      data: "ChillerStatusSTR",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="StatusCls" > </span>`);
        span.append(GetPeriodicStatus(cellData));
        $(td).html(span);
      },
    },
  ];

  function CustomeHeader(thead) {
    const ColumnData = [
      {
        title: "",
        colspan: 3,
      },
      {
        title: "Time in (Hrs)",
        colspan: 24,
      },
    ];

    let headerRow1 = $('<tr class="header-row">');
    columnNames.forEach((column) => {
      headerRow1.append(
        `<th style="border-right: 2px solid silver; border-left: 1px solid silver;" >${column.title}</th>`
      );
    });

    let headerRow2 = $('<tr class="header-row">');
    ColumnData.forEach((data) => {
      if (!data?.title) {
        headerRow2.append(
          `<th colspan="${data.colspan}" style="text-align: center; border-right: 1px solid silver; border-left: 1px solid silver;"></th>`
        );
      } else {
        headerRow2.append(
          `<th colspan="${data.colspan}" style="text-align: center;  border-right: 1px solid silver; border-left: 1px solid silver;">${data.title}</th>`
        );
      }
    });
    $(thead).closest("thead").prepend(headerRow2);
  }
  const createdCommonCell = (td, cellData, rowData, row, col, ObjectName) => {
    let inputElement = "";

    if (
      ChillerModel.checkAllAttended == 67 ||
      ChillerModel.checkAllAttended == 68 ||
      ChillerModel.checkAllAttended == 69
    ) {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    } else if (
      ChillerModel.checkAllAttended == 0 &&
      getUserDetails().RoleID == COOKIES.TechRoleID
    ) {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      ).on("change", function (e) {
        e.preventDefault();

        let newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          rowData[ObjectName] = newValue;
          DailyElectricalPanelSaveChange(rowData);
          UpdateStatus(e);
          SetListPageRender();
        }
      });
    } else if (!IsAllowToEnterData()) {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    } else {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      ).on("change", function (e) {
        e.preventDefault();

        let newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          rowData[ObjectName] = newValue;
          DailyElectricalPanelSaveChange(rowData);
          UpdateStatus(e);
          SetListPageRender();
        }
      });
    }

    $(td).html(inputElement);
  };

  useEffect(() => {
    SetChillerModel({});
    initialDropDown();
  }, []);

  // useEffect(() => {
  //   if (TabValue == 4) {
  //     SetChillerModel({});
  //     initialDropDown();
  //   }

  //   return () => {
  //     setStationList([]);
  //     setRoomList([]);
  //     setEquipmentTagNumberList([]);
  //     setEquipmentLocationList([]);
  //   };
  // }, [TabValue]);

  useEffect(() => {
    initialDropDownEquipmentCode();
  }, [TabValue, ChillerModel.StationID]);
  useEffect(() => {
    getDailyElectricalDetails();
  }, [
    ChillerModel.StationID,
    ChillerModel.EquipmentDetailID,
    ChillerModel.EquipmentDetailIDs,
    ChillerModel.CurrentDate,
    // ListPageRender,
  ]);

  /**
   * Handle change event for common inputs.
   *
   * @param {Event} event - The input change event.
   */
  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      SetChillerModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name.includes('OnTime') || name.includes('OffTime')) {
        console.log(onoffTiime);
        setonoffTiime((pre) => ({
          ...pre,
          [name]: parseFloat(value),
        }));
        localStorage.setItem([name], parseFloat(value));
      }
      if (name == "StationID") {
        SetChillerModel((pre) => ({
          ...pre,
          EquipmentDetailID: "",
          EquipmentLocation: "",
          OffStartTime: "",
          OffEndTime: "",
        }));
        setChillerMonitoringDetailList([]);

        let Station = StationList.find((x) => x.value == value);
        handleGlobalStationName(Station?.key ?? "");
      }
    } else {
      if (name === "OffEndTime") {
        const hours = event.label.split(".")[0];
        offEndTimedefault = Number(hours);
        for (let i = offStartTimedefault; i <= offEndTimedefault; i++) {
          //  offoverallTimedefault.push(i);
        }
        //alert(offoverallTimedefault);
      }
      if (name === "OffStartTime") {
        const hours = event.label.split(":")[0];
        offStartTimedefault = hours;
      }

      SetChillerModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
      }));
    }
  };

  function ApprovedBtn() {
    let tableValues = $("#ChillerMonitoring")
      .DataTable()
      .table()
      .data()
      .toArray();

    let Chiller_1Fields = $(
      `<label class='m-l-15' style ="display:inline-flex;  width: 12%; float: right;" > <span style=" width: 40%; "> Chiller 1: </span><input type="text" style=" width: 60%; " disabled class="form-control" value="${ChillerModel.Chiller1}"/> </label>`
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });

    let Chiller_2Fields = $(
      `<label class='m-l-15' style ="display:inline-flex; width: 12%; float: right;" > <span style=" width: 40%; "> Chiller 2: </span><input type="text"  style=" width: 60%; "disabled class="form-control" value="${ChillerModel.Chiller2}"/></label>`
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });

    let Chiller_3Fields = $(
      `<label class='m-l-15' style ="display:inline-flex; width: 12%; float: right; margin-right: 1%;" > <span style=" width: 45%; "> Chiller 3: </span><input type="text" style=" width: 55%; " disabled class="form-control" value="${ChillerModel.Chiller3}"/></lsbel>`
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
    let Tvs_fields = $(
      `<label class='m-l-15' style ="display:inline-flex; width: 10%; float: right;" > <span style=" width: 45%; "> TVS: </span><input type="text"  style=" width: 55%; "disabled class="form-control" value="${ChillerModel.Chiller2}"/></label>`
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });

    let Vac_fields = $(
      `<label class='m-l-15' style ="display:inline-flex; width: 8%; float: right;" > <span style=" width: 45%; "> VAC: </span><input type="text" style=" width: 55%; " disabled class="form-control" value="${ChillerModel.Chiller3}"/></lsbel>`
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });

    let TLApproveBtn = $(
      `<button  class="btn btn-success "
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 68);
        await getDailyElectricalDetails();
      }
    });
    let ApproveBtn = $(
      `<button  style ="float: right;"  class="btn btn-success "
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 68);
        await getDailyElectricalDetails();
      }
    });
    let RejectBTn = $(
      `<button  style ="float: right;"  class="btn btn-danger m-l-15 m-r-10"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 69);
        await getDailyElectricalDetails();
      }
    });
    let ApproveBtndisabled = $(
      `<button  style ="float: right;" disabled class="btn btn-success "
          >  Approve </button>  `
    );
    let RejectBTndisabled = $(
      `<button  style ="float: right;"  disabled class="btn btn-danger m-l-15 m-r-10"
          >  Reject </button>  `
    );
    let Fragment = $(
      `<s  style="float: right; display: flex; left: 25%; top: 35px;"></s`
    );
    // Fragment.append(Tvs_fields);
    // Fragment.append(Vac_fields);
    // Fragment.append(Chiller_1Fields);
    // Fragment.append(Chiller_2Fields);
    // Fragment.append(Chiller_3Fields);
    if (
      ChillerModel.checkAllAttended == 55 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.append(TLApproveBtn);
      Fragment.append(RejectBTn);
    }

    if (
      ChillerModel.checkAllAttended == 68 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <=
      getYMDdate(addDays(ChillerModel.CurrentDate, 15))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTn);
    }

    if (
      ChillerModel.checkAllAttended == 68 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) > getYMDdate(addDays(ChillerModel.CurrentDate, 15))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    if (
      ChillerModel.checkAllAttended == 0 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    if (
      ChillerModel.checkAllAttended == 57 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <=
      getYMDdate(addDays(ChillerModel.CurrentDate, 15))
    ) {
      Fragment.append(TLApproveBtn);
      Fragment.append(RejectBTndisabled);
    }

    if (
      ChillerModel.checkAllAttended == 67 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) < getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    if (
      ChillerModel.checkAllAttended == 67 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }

    if (
      ChillerModel.checkAllAttended == 67 &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) < getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }
    if (
      ChillerModel.checkAllAttended == 67 &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    return Fragment;
  }
  function SubmitBtn() {

    let tableValues = $("#ChillerMonitoring")
      .DataTable()
      .table()
      .data()
      .toArray();

    let SendForApprovelBtn = $(
      `<button  class="btn btn-primary " style="float: right;" >  Submit </button> `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 67);
        await getDailyElectricalDetails();
      }
    });

    let Fragment = $(`<span ></span>`);

    if (
      getUserDetails().RoleID == COOKIES.TechRoleID &&
      ChillerModel.IsChillerMonitorAllCheck == 1 &&
      ChillerModel.checkAllAttended == 0 &&
      ChillerMonitoringDetailList.length > 0 &&
      IsAllowToEnterData()
      // ChillerModel.DailyElectricalPanelIsAdd == 1
      //   &&
      //   getYMDdate(new Date()) <=
      //     getYMDdate(addDays(ChillerModel.CurrentDate, 15)) &&
      //   getYMDdate(new Date()) > getYMDdate(addDays(ChillerModel.CurrentDate, 3))
    ) {
      Fragment.prepend(SendForApprovelBtn);
    }
    return Fragment;
  }

  function IsAllowToEnterData() {
    let currentDate = new Date().toISOString().slice(0, 10);
    let CheckDate = new Date(ChillerModel.CurrentDate)
      .toISOString()
      .slice(0, 10);
    return (
      getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate
    );
  }

  function UpdateStatus(e) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");

      if (StatusClsCell.length > 0) {
        StatusClsCell.html(GetPeriodicStatus("Attended")); // You can format the value as needed
      }
    }
  }

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });

    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setStationList([...FinalStationList]);

    SetChillerModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
      CurrentDate: getYMDdate(new Date()),
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  /**
   * Initializes dropdown values and equipment Code details.
   */
  const initialDropDownEquipmentCode = async () => {
    if (ChillerModel.StationID > 0) {
      await ChillerMonitoring_GetEquipmentCode(ChillerModel.StationID)
        .then((res) => {
          if (res?.data) {
            if (res.data.data.chillerTagList?.length) {
              setEquipmentTagNumberList([...res.data.data.chillerTagList]);

              if (res.data.data.chillerTagList?.length) {
                SetChillerModel((pre) => ({
                  ...pre,

                  EquipmentDetailID: res.data.data.chillerTagList[0],
                }));
              } else {
                SetChillerModel((pre) => ({
                  ...pre,
                  EquipmentDetailID: "",
                }));
              }
            } else {
              setEquipmentTagNumberList([]);
            }
            if (res.data.data.equipmentLocationList?.length) {
              setEquipmentLocationList([
                ...res.data.data.equipmentLocationList,
              ]);
            } else {
              setEquipmentLocationList([]);
            }
            if (res.data.data.timeandHoursList?.length) {
              setTimeandHoursList([...res.data.data.timeandHoursList]);
            } else {
              setTimeandHoursList([]);
            }
          } else {
            SetChillerModel((pre) => ({
              ...pre,
              EquipmentDetailID: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  /**
   * Fetch equipment details for a specific Energy Consumption date.
   */
  const getDailyElectricalDetails = async () => {
    if (ChillerModel.StationID > 0 && ChillerModel.EquipmentDetailID?.value) {
      // showLoading();
      await ChillerMonitoring_GetChillerMonitoringData(
        ChillerModel.StationID,
        ChillerModel.EquipmentDetailID.value,
        ChillerModel.CurrentDate
      )
        .then((res) => {
          // hideLoading();
          if (res?.data) {
            if (res.data.data.offStartTimeSelected?.length) {
              SetChillerModel((pre) => ({
                ...pre,
                OffStartTime: res.data.data.offStartTimeSelected[0],
              }));
            } else {
              SetChillerModel((pre) => ({
                ...pre,
                OffStartTime: "",
              }));
            }
            if (res.data.data.offEndTimeSelected?.length) {
              SetChillerModel((pre) => ({
                ...pre,
                OffEndTime: res.data.data.offEndTimeSelected[0],
              }));
            } else {
              SetChillerModel((pre) => ({
                ...pre,
                OffEndTime: "",
              }));
            }

            if (res.data.data.chillerMonitoringDet?.length) {
              setChillerMonitoringDetailList([
                ...res.data.data.chillerMonitoringDet,
              ]);
            } else {
              setChillerMonitoringDetailList([]);
            }
            if (res.data.data?.chillerMonitoringMST != null) {
              SetChillerModel((pre) => ({
                ...pre,
                checkAllAttended:
                  res.data.data.chillerMonitoringMST[0].ChillerMonitoringStatus,
                IsChillerMonitorAllCheck:
                  res.data.data.chillerMonitoringMST[0]
                    .IsChillerMonitorAllCheck,
                Chiller1: res.data.data.chillerMonitoringMST[0].Chiller_1,
                Chiller2: res.data.data.chillerMonitoringMST[0].Chiller_2,
                Chiller3: res.data.data.chillerMonitoringMST[0].Chiller_3,
                OnTime: res.data.data.chillerMonitoringMST[0].OnTime,
                OffTime: res.data.data.chillerMonitoringMST[0].OffTime,
                TVS_Consumption:
                  res.data.data.chillerMonitoringMST[0].TVS_Consumption,
                VAC_Consumption:
                  res.data.data.chillerMonitoringMST[0].VAC_Consumption,
              }));
              setonoffTiime((pre) => ({
                ...pre,
                OnTime: res.data.data.chillerMonitoringMST[0].OnTime,
                OffTime: res.data.data.chillerMonitoringMST[0].OffTime,
              }));
              localStorage.setItem('OnTime', res.data.data.chillerMonitoringMST[0].OnTime);
              localStorage.setItem('OffTime', res.data.data.chillerMonitoringMST[0].OffTime);
            } else {
              SetChillerModel((pre) => ({
                ...pre,
                checkAllAttended: 0,
                Chiller1: "",
                Chiller2: "",
                Chiller3: "",
                VAC_Consumption: "",
                TVS_Consumption: "",
                IsChillerMonitorAllCheck: 1,
              }));
            }
            if (res.data.data.equipmentLocationSelected?.length) {
              SetChillerModel((pre) => ({
                ...pre,
                EquipmentLocation:
                  res.data.data.equipmentLocationSelected[0].label,
              }));
            } else {
              SetChillerModel((pre) => ({
                ...pre,
                EquipmentLocation: "",
              }));
            }
            if (res.data.data.isoDocumentName?.length) {
              SetChillerModel((pre) => ({
                ...pre,
                ISODocumentNo: res.data.data.isoDocumentName[0].DocumentNo,
              }));
            } else {
              SetChillerModel((pre) => ({
                ...pre,
                ISODocumentNo: "",
              }));
            }
          } else {
            SetChillerModel((pre) => ({
              ...pre,
              OffStartTime: "",
              OffEndTime: "",
              ISODocumentNo: "",
              checkAllAttended: 0,
              IsChillerMonitorAllCheck: 1,
              EquipmentLocation: "",
              EquipmentDetailID: "",
            }));

            setChillerMonitoringDetailList([]);
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  /**
   * Handle changes in equipment energy Consumption and save the configuration.
   *
   * @param {Object} obj - The equipment energy Consumption  configuration object.
   */
  const DailyElectricalPanelSaveChange = async (obj, status = 48) => {
    obj.CreatedBy = Number(GetLoginUserID());
    obj.ChillerMonitoringDate = getYMDdate(ChillerModel.CurrentDate);
    obj.StationID = ChillerModel.StationID;
    obj.EquipmentDetailID = ChillerModel.EquipmentDetailID?.value;
    obj.EquipmentLocation = ChillerModel.EquipmentLocation;
    obj.HoursAndMinutesStartID = ChillerModel?.OffStartTime?.value;
    obj.HoursAndMinutesEndID = ChillerModel?.OffEndTime?.value;
    obj.CreatedDate = new Date().toLocaleTimeString();
    obj.OnTime = localStorage.getItem("OnTime") == null ? 0 : localStorage.getItem("OnTime");
    obj.OffTime = localStorage.getItem("OffTime") == null ? 0 : localStorage.getItem("OffTime");
    console.log(obj);
    await ChillerMonitoring_SaveChillerMonitoring(obj)
      .then((res) => {
        const { isSuccess } = res.data;
        obj.DailyElectricalPanelMonitorID = res.data.data;

        if (isSuccess && status != 48) {
          showSaveSuccess();
        } else if (!isSuccess) {
          showErrorMsg("Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ApprovelRejectRequirest = async (obj, status = 67) => {
    obj.CreatedBy = Number(GetLoginUserID());
    obj.ChillerMonitoringStatus = Number(status);
    obj.ChillerMonitoringDate = getYMDdate(ChillerModel.CurrentDate);
    obj.StationID = ChillerModel.StationID;
    obj.EquipmentDetailID = ChillerModel.EquipmentDetailID.value;

    await ChillerMonitoring_ApprovelRejectRequest(obj)
      .then((res) => {
        const { isSuccess } = res.data;

        obj.DailyElectricalPanelMonitorID = res.data.data;

        if (isSuccess) {
          showSaveSuccess();
        } else {
          showErrorMsg("Something went wrong. Please try again.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function DateFilter() {
    return $(
      `<label class='m-l-15' style ="display:inline-flex" > <span> Date: </span>
        <input type="date" id="CERTMDateID" max=${getYMDdate(
        new Date()
      )} style="height:35px" class="form-control m-l-15" value ="${ChillerModel.CurrentDate
      }"
        /></label>
        `
    ).on("change", function (e) {
      const { value } = e.target;
      SetChillerModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
  }

  return (
    <div>
      <div className="col-md-12">
        <label
          className="label"
          for="empCode"
          style={{ float: "right", position: "relative", top: "-15px" }}
        >
          {ChillerModel?.ISODocumentNo &&
            `Doc Number : ${ChillerModel.ISODocumentNo}`}
        </label>
      </div>
      <form id="EquipmentRunningStatusName">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={ChillerModel.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Chiller No <span className="text-danger"> *</span>{" "}
              </label>{" "}
              <ReactMultiSelect
                options={EquipmentTagNumberList}
                value={ChillerModel.EquipmentDetailID}
                onChange={(e) => CommonHandleChange(e, "EquipmentDetailID")}
                labelledBy="Select"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment Location <span className="text-danger"> *</span>{" "}
              </label>{" "}
              <ReactAutoComplete
                handleChange={(_, text) => {
                  CommonHandleChange(text, "EquipmentLocation");
                }}
                value={ChillerModel.EquipmentLocation}
                handleSelect={(e) => {
                  CommonHandleChange(e, "EquipmentLocation");
                }}
                Id={"EquipmentLocation"}
                name={"EquipmentLocation"}
                options={EquipmentLocationList ?? []}
              />
            </div>
          </div>
          <div className="col-1">
            <label className="label" for="name">
              On Time
            </label>
            <input
              type="number"
              className="form-control"
              name="OnTime"
              value={onoffTiime.OnTime}
              onChange={CommonHandleChange}
            />
          </div>
          <div className="col-1">
            <label className="label" for="name">
              Off Time
            </label>
            <input
              type="number"
              className="form-control"
              name="OffTime"
              value={ChillerModel.OffTime}
              onChange={CommonHandleChange}
            />
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Chiller Off Time <span className="text-danger"> *</span>{" "}
              </label>
              <br />
              <div className="btn-group">
                <ReactMultiSelect
                  options={TimeandHoursList}

                  value={ChillerModel.OffStartTime}
                  onChange={(e) => CommonHandleChange(e, "OffStartTime")}
                  labelledBy="Select"
                />
                <span style={{ margin: "10px" }}>-</span>
                <ReactMultiSelect
                  options={TimeandHoursList}
                  value={ChillerModel.OffEndTime}
                  onChange={(e) => CommonHandleChange(e, "OffEndTime")}
                  labelledBy="Select"
                />
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <label className="label" for="empCode" style={{ float: "right" }}>
              {ChillerModel?.TVS_Consumption &&
                ` TVS : ${ChillerModel.TVS_Consumption} `}
              &nbsp;
              {ChillerModel?.VAC_Consumption &&
                ` VAC : ${ChillerModel.VAC_Consumption}`}
              &nbsp;
              {ChillerModel?.Chiller1 &&
                ` Chiller 1 : ${ChillerModel.Chiller1}`}
              &nbsp;
              {ChillerModel?.Chiller2 &&
                ` Chiller 2 : ${ChillerModel.Chiller2}`}
              &nbsp;
              {ChillerModel?.Chiller3 &&
                ` Chiller 3 : ${ChillerModel.Chiller3} `}
            </label>
          </div>

          <div className="col-md-1"></div>
          {/* <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <label
                      className="label"
                      for="empCode"
                      style={{ float: "right" }}
                    >
                      {ChillerModel?.TVS_Consumption &&
                        `TVS : ${ChillerModel.TVS_Consumption}`}
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label
                      className="label"
                      for="empCode"
                      style={{ float: "right" }}
                    >
                      {ChillerModel?.VAC_Consumption &&
                        `VAC : ${ChillerModel.VAC_Consumption}`}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <label
                      className="label"
                      for="empCode"
                      style={{ float: "right" }}
                    >
                      {ChillerModel?.Chiller1 &&
                        `Chiller 1 : ${ChillerModel.Chiller1}`}
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label
                      className="label"
                      for="empCode"
                      style={{ float: "right" }}
                    >
                      {ChillerModel?.Chiller2 &&
                        `Chiller 2 : ${ChillerModel.Chiller2}`}
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label
                      className="label"
                      for="empCode"
                      style={{ float: "right" }}
                    >
                      {ChillerModel?.Chiller3 &&
                        `Chiller 3 : ${ChillerModel.Chiller3}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* 
          <div className="col-md-3">
            <label className="label" for="empCode" style={{ float: "right" }}>
              {ChillerModel?.ISODocumentNo &&
                `Doc Number : ${ChillerModel.ISODocumentNo}`}
            </label>
          </div> */}
        </div>
      </form>
      <div className="">
        {DateFilter}
        <HtmlTable
          columnList={columnNames}
          CustomeHeader={CustomeHeader}
          rowList={ChillerMonitoringDetailList ?? []}
          id={"ChillerMonitoring"}
          FixedColums={{
            left: 3,
            right: 1,
          }}
          IsASC
          wrapperPrependRightHtml={SubmitBtn}
          wrapperPrependMidRightHtml={ApprovedBtn}
          wrapperPrependHtml={DateFilter}
          paging={false}
          searching={false}
        />
      </div>
    </div>
  );
}
