import CryptoJS from "crypto-js";
import { useContext } from "react";
import { AppContext } from "./ContextProvider";
import $ from "jquery";
import { COOKIES, MaintenanceTypes, Roles } from "./Global";
import Global from "./Global";
import { type } from "@testing-library/user-event/dist/type";
import moment from "moment";
import { BASE_URL } from "../env";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
// import { PeriodicService } from "../screens/periodic/PeriodicService";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const KEY = "!5623a#de";
const CSHARP_Key = "1)@g*8$K4!~&96u#eDt5%3lT8@sN^6Ea"; // 32 Bytes for AES-256
const CSHARP_IV = "$8fP&@!9(2Go$Q9W"; // 16 Bytes for AES Block Size

export const randomArray = (length, max) =>
  [...new Array(length)].map(() => Math.round(Math.random() * max));

export const COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(102, 124, 232)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
  purple: "rgb(118, 75, 162)",
  pink: "rgb(245, 72, 127)",
  cryon: "rgb(68, 179, 187)",
  lightGrey: "#D3D3D3",
  grey: "#808080",
  tvf: "#93D254",
  ahu: "#DA80E2",
};

export const MyCOLORS = [
  "#82CD47",
  "#0bb4ff",
  "#E4C988",
  "#e6d800",
  "#9b19f5",
  "#C27664",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

export const StationColors = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(102, 124, 232)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
  "rgb(118, 75, 162)",
  "rgb(245, 72, 127)",
  "rgb(68, 179, 187)",
  "#D3D3D3",
  "#93D254",
  "#DA80E2",
];

export const PeriodicEquipmentColor = [
  "#2273b9",
  "rgb(226 73 91 / 80%)",
  "rgb(255 146 43 / 80%)",
  "rgb(100 208 220 / 80%)",
  "rgb(47 178 234 / 80%)",
  "rgb(255 202 102 / 80%)",
  "rgb(240 92 44 / 80%)",
  "rgb(45 199 138 / 80%)",
  "rgb(137 69 170 / 80%)",
  "rgb(83 106 239 / 80%)",
  "rgb(133 244 255 / 80%)",
  "rgb(243 170 170 / 80%)",
  "rgba(255, 213, 79, 1.0)",
  "rgba(255,138,101,1.0)",
  "rgba(239,235,233,1.0)",
  "rgba(207,216,220,1.0)",
  "rgba(179,229,252,1.0)",
  "#FF0000",
  "#0000FF",
  "#00FF00",
  "#FFFF00",
  "#FFA500",
  "#800080",
  "#FFC0CB",
  "#A52A2A",
  "#008080",
  "#00FFFF",
  "#800000",
  "#000080",
  "#808000",
  "#FF00FF",
  "#40E0D0",
  "#4B0082",
  "#E6E6FA",
  "#FF7F50",
  "#FFD700",
  "#C0C0C0",
  "#F5F5DC",
  "#FFDAB9",
  "#98FB98",
  "#36454F",
  "#FA8072",
  "#E0115F",
  "#708090",
  "#DA70D6",
  "#FFDB58",
  "#228B22",
  "#87CEEB",
  "#8B0000",
  "#DDA0DD",
  "#4682B4",
  "#F0E68C",
  "#D2B48C",
  "#FF6347",
  "#A0522D",
  "#FFF700",
  "#B0E0E6",
  "#C04000",
  "#6495ED",
  "#BC8F8F",
  "#708090",
  "#D8BFD8",
  "#008B8B",
  "#B8860B",
  "#556B2F",
  "#F08080",
  "#191970",
];

export const Encrypt = (data) => {
  var encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    KEY
  ).toString();
  return encryptedData;
};

export const Decrypt = (data) => {
  var decryptedData = "";
  if (data != null) {
    var bytes = CryptoJS.AES.decrypt(data, KEY);
    if (bytes != null) {
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  return decryptedData;
};

export const GetNumberOfDaysInSec = (noOfDays) => {
  return noOfDays * 24 * 60 * 60;
};

export const GetToggleButton = (row, col, value, id) => {
  if (value) {
    return `<div class="onoffswitch text-align-start">
    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" checked>
    <label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>`;
  } else {
    return `<div class="onoffswitch text-align-start">
    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" >
    <label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>`;
  }
};

export const SmallGetToggleButton = (value, id, className) => {
  if (value) {
    return `
    <div>
    <input class = "EquipmentRunningToggle ${className}" id="switch_annual_${id}" checked type="checkbox" />
    

  </div>`;
  } else {
    return `
    <div>
    <input class = "EquipmentRunningToggle ${className}" id="switch_annual_${id}" type="checkbox" />
    </div>

  
  `;
  }
};

// <div class="onoffswitch text-align-start">
// <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" checked>
// <label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
//   <span class="onoffswitch-inner"></span>
//   <span class="onoffswitch-switch"></span>
// </label>

{
  /* <div class="onoffswitch text-align-start">
<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="switch_annual_${id}${row}${col}" >
<label class="onoffswitch-label" for="switch_annual_${id}${row}${col}">
  <span class="onoffswitch-inner"></span>
  <span class="onoffswitch-switch"></span>
</label>
</div> */
}

export const GetSelect = (
  options,
  value,
  disabled,
  className = "",
  PlaceHolderName = "--Select--"
) => {

  if (disabled) {
    var select = `<select class="form-select form-select  editable-row ${className}" disabled  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">${PlaceHolderName}</option>`;
      options.map((v) => {

        if (v.value == value) {
          select += `<option selected value="${v.value}">${v.key}</option>`;
        } else {
          select += `<option value="${v.value}">${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  } else {
    var select = `<select class="form-select form-select ${className} editable-row "  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">${PlaceHolderName}</option>`;
      options.map((v) => {
        if (v.value == value) {
          select += `<option selected value="${v.value}">${v.key}</option>`;
        } else {
          select += `<option value="${v.value}">${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  }
};

export const SumoMultiSelect = (options, value) => {
  var values = value.split(",");
  var values1 = values.map(function (str) {
    return parseInt(str);
  });
  console.log(values1);
  var select = `<select  multiple="multiple" class="Selecttttt2  form-select form-control multiple editable-row " value = ${value}> `;

  if (options != null && options.length > 0) {
    select += `<option disabled value="0">----Select----</option>`;
    options.map((v) => {
      if (values1.includes(parseInt(v.value))) {
        select += `<option selected value=${v.value}>${v.key}</option>`;
      } else {
        select += `<option  value=${v.value}>${v.key}</option>`;
      }
    });
  }
  select += `</select>`;
  return select;
};

export const GetStatus = (value) => {
  if (value) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded" href="#">
      <i class="fa fa-dot-circle-o text-success"></i> Active
    </a>
  </div>`;
  } else {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded" href="#">
      <i class="fa fa-dot-circle-o text-danger"></i> In-Active
    </a>
  </div>`;
  }
};

export const getMonday = (d) => {
  var month = d.getMonth();
  var mondays = [];

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
    d.setDate(d.getDate() + 1);
  }
  return d;
};

export const GetPeriodicStatus = (value) => {
  var res = value.split("|");
  var id = res[0];
  var name = res[1];
  if (id == 1) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-primary"></i> ${name}
    </a>
  </div>`;
  } else if (id == 4) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-danger"></i> ${name}
    </a>
  </div>`;
  } else if (id == 3) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-success"></i> ${name}
    </a>
  </div>`;
  } else if (id == 2) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-muted"></i> ${name}
    </a>
  </div>`;
  } else if (id == 6) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-warning"></i> ${name}
    </a>
  </div>`;
  } else if (id == 8) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-danger"></i> ${name}
    </a>
  </div>`;
  } else if (id == 10) {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-secondary"></i> ${name}
    </a>
  </div>`;
  } else {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);" >
      <i class="fa fa-dot-circle-o text-danger"></i> Open
    </a>
  </div>`;
  }
};

export const getPeriodicIOTParams = (equipment) => {
  switch (equipment) {
    case "TVF":
      return {
        equipmets: ["STC-TVF-001", "STC-TVF-002", "STC-TVF-003", "STC-TVF-004"],
        params: [
          "Air Flow Status",
          "WTS-R",
          "WTS-Y",
          "WTS-B",
          "BTS-Driving End",
          "BTS-Non Driving End",
          "Vibration Sensor",
          "VFD FREQ",
          "VFD Current",
          "NOISE LEVEL AT 1.5M",
        ],
      };
  }
};

export const getNewIOTValue = () => {
  var d = new Date();
  var empCode = "EMP001";
  var date = `${d.toDateString()} ${d.getHours() <= 9 ? "0" + d.getHours() : d.getHours()
    }:${d.getMinutes() <= 9 ? "0" + d.getMinutes() : d.getMinutes()}`;
  return [
    [
      `${d.getTime()}`,
      date,
      empCode,
      "30",
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(2, 10),
      rn(2, 40),
      rn(10, 140),
      0,
      "",
    ],
    [
      `${d.getTime()}`,
      date,
      empCode,
      "50",
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(100, 200),
      rn(2, 10),
      rn(2, 40),
      rn(10, 140),
      0,
      "",
    ],
  ];
};

const rn = (maximum, minimum) =>
  Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

export const PeriodicColor = [
  "#44b3bb",
  "#9854cb",
  "rgb(255, 205, 86)",
  "rgb(255, 159, 64)",
  "rgb(102, 124, 232)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
  "rgb(68, 179, 187)",
  "#93D254",
  "#DA80E2",
];

export const GetDatesBetween = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    var d = new Date(dt);
    arr.push(getYMDdate(d));
  }
  return arr;
};

export const GetLoginUserID = () => {
  var id = localStorage.getItem(COOKIES.EmployeeID);
  return Decrypt(id);
};

export const GetLoginUserDepartmentID = () => {
  var id = localStorage.getItem(COOKIES.DepartmentID);
  return Decrypt(id);
};

export const getYMDdate = (date = new Date()) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var monthstr = month <= 9 ? "0" + month : month;
  var dateStr = date <= 9 ? "0" + date : date;
  var res = `${year}-${monthstr}-${dateStr}`;
  return res;
};

export const getDDMMYYdate = (date) => {
  // var stringLst = date.split("T")[0].split("-");
  var d = new Date(date);
  // var d = new Date(stringLst[0], stringLst[1], stringLst[2]);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var monthstr = month <= 9 ? "0" + month : month;
  var dateStr = date <= 9 ? "0" + date : date;
  var res = `${dateStr}-${monthstr}-${year}`;
  return res;
};

export function convert24hrto12hr(time24) {
  if (time24 != null && time24 != "") {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  } else {
    return "";
  }
}

export const getUserDetails = () => {
  var value = localStorage.getItem(COOKIES.UserDetails);
  if (value != null) {
    var res = Decrypt(value);
    return res;
  }
  return "";
};

export const getPrivilege = () => {
  var value = localStorage.getItem(COOKIES.Privilege);
  if (value != null) {
    var res = Decrypt(value);
    return res;
  } else {
    return "";
  }
};
export const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return [];

  return array.reduce((list, item) => {
    const hasItem = list.find((listItem) =>
      keys.every((key) => listItem[key] === item[key])
    );
    if (!hasItem) list.push(item);
    return list;
  }, []);
};
export const MakeSingleValueUnique = (array = [], key) => {
  const unique = [...new Set(array.map((item) => item[key]))];
  return unique;
};

export const FileDownLoad = (filename, data, type) => {
  const blob = new Blob([data], { type: type });
  // if(window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveBlob(blob, filename);
  // }
  // else{
  const elem = window.document.createElement("a");
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
  // }
};
export const dynamicsort = (property, order) => {
  var sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    // a should come before b in the sorted order
    if (a[property] < b[property]) {
      return -1 * sort_order;
      // a should come after b in the sorted order
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  };
};

export const selectalldatatable = (tableid, checked) => {
  if (checked) {
    var temp = $(`#${tableid} > tbody > tr:has(td)`).find(".checks");
    temp.prop("checked", true);

    var datas = [];
    var tbl = $(`#${tableid} > tbody > tr:has(td)`).map(function (i, v) {
      var td = $("td", this);

      if (td.eq(0).find(".checks").is(":checked")) {
        // console.log(td.eq(1).text());
        return datas.push({
          EquipmentID: td.eq(1).text(),
          JobScheduleID: td.eq(2).text() ? td.eq(2).text() : 0,
        });
      }
    });
  } else {
    var temp = $(`#${tableid} > tbody > tr:has(td)`).find(".checks");
    temp.prop("checked", false);
    var datas = [];
  }

  return datas;
};

export const PeriodicSetQuerterMOnthDate = (
  PeriodicDate = new Date(),
  Type = 0
) => {
  var date = PeriodicDate.getDate();
  var month = PeriodicDate.getMonth() + 1;
  var year = PeriodicDate.getFullYear();
  if (
    (PeriodicDate.getDate() > 28 && Type == 3, PeriodicDate.getMonth() + 1 == 1)
  ) {
    date = 28;
  }

  var Quar1st = [1, 2, 3];
  var Quar2st = [4, 5, 6];
  var Quar3st = [7, 8, 9];
  var Quar4st = [10, 11, 12];

  var Half1st = [12, 1, 2, 3, 4, 5];
  var Half2st = [6, 7, 8, 9, 10, 11];

  if (Type == 3) {
    if (Quar1st.includes(month)) {
      return new Date(`${year}-${2}-${date}`);
    } else if (Quar2st.includes(month)) {
      return new Date(`${year}-${5}-${date}`);
    } else if (Quar3st.includes(month)) {
      return new Date(`${year}-${8}-${date}`);
    } else if (Quar4st.includes(month)) {
      return new Date(`${year}-${11}-${date}`);
    }
  } else if (Type == 4) {
    if (Half1st.includes(month)) {
      if (month == 12) {
        year += 1;
      }
      return new Date(`${year}-${5}-${date}`);
    } else if (Half2st.includes(month)) {
      return new Date(`${year}-${11}-${date}`);
    }
  } else if (Type == 5) {
    return new Date(`${year}-${8}-${date}`);
  }
};

export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

const cryptkey = CryptoJS.enc.Utf8.parse(Global.COOKIES.FlutterEncryptKey);
const cryptiv = CryptoJS.enc.Utf8.parse(Global.COOKIES.FlutterIV);

export const FlutterEnc = (Value) => {
  const crypted = CryptoJS.enc.Base64.parse(Value);
  var decrypt = CryptoJS.AES.decrypt({ ciphertext: crypted }, cryptkey, {
    iv: cryptiv,
    mode: CryptoJS.mode.CTR,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
};

export const FlutterDec = (Value) => {
  var encrypt = CryptoJS.AES.encrypt(Value, cryptkey, {
    iv: cryptiv,
    mode: CryptoJS.mode.CTR,
  });

  return encrypt;
};

export const getCookieExpiry = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    let [name, value] = cookie.split("=");
    name = name.trim();
    if (name === cookieName) {
      console.log(name, value);
      const expiresIndex = value.indexOf("expires=");
      if (expiresIndex !== -1) {
        console.log(value);
        return new Date(value.slice(expiresIndex + 8)).toString();
      }
      return "Session cookie";
    }
  }
  return "Cookie not found";
};

export const Generateyears = (CountYears = 10) => {
  const currentYear = new Date().getFullYear();
  const Years = [];

  for (let i = 0; i < CountYears; i++) {
    const year = currentYear - i;
    Years.push({ value: year, key: year.toString() });
  }
  return Years;
};

export const ShortMonths = () => {
  return [
    { value: 0, key: "Jan" },
    { value: 1, key: "Feb" },
    { value: 2, key: "Mar" },
    { value: 3, key: "Apr" },
    { value: 4, key: "May" },
    { value: 5, key: "Jun" },
    { value: 6, key: "Jul" },
    { value: 7, key: "Aug" },
    { value: 8, key: "Sep" },
    { value: 9, key: "Oct" },
    { value: 10, key: "Nov" },
    { value: 11, key: "Dec" },
  ];
};

export const QuarterlyMonths = () => {
  return [
    { value: 2, key: "Feb - Apr" },
    { value: 5, key: "May - Jul" },
    { value: 8, key: "Aug - Oct" },
    { value: 11, key: "Nov - Jan" },
  ];
};

export const HalfMonths = () => {
  return [
    { value: 5, key: "May - Oct" },
    { value: 11, key: "Nov - Apr" },
  ];
};

export const DownloadIcon = (
  JobScheduleID,
  EquipmentDetailID,
  EquipmentCode
) => {
  if (JobScheduleID && EquipmentDetailID && EquipmentCode) {
    JobScheduleID = CSHARP_encrypt(JobScheduleID.toString());
    EquipmentDetailID = CSHARP_encrypt(EquipmentDetailID.toString());
    var Equipment_tag_no = EquipmentCode?.replaceAll("-", "_");
    var Equipment_tag_no = EquipmentCode?.replaceAll("/", "_");
    Equipment_tag_no = Equipment_tag_no;
    let currentDate = moment(); // This fetches the current date and time.
    let file_name =
      currentDate.format("DD_MM_YYYY_HH_mm_ss_") + Equipment_tag_no + ".zip";
    var url_path = `${BASE_URL}/PeriodicMaintenance/download_uploaded_files?JobScheduleID=${JobScheduleID}&EquipmentDetailID=${EquipmentDetailID}&Filename=${file_name}`;
    return url_path;
    $(`
  <a href=${url_path}>
      <i class='fa fa-download m-r-5' ></i> 
  </a>
`);
  }
};

const CKEY = CryptoJS.enc.Utf8.parse("1)@g*8$K4!~&96u#eDt5%3lT8@sN^6Ea"); // Must be 256 bits (32 bytes)
const CIV = CryptoJS.enc.Utf8.parse("$8fP&@!9(2Go$Q9W"); // Must be 128 bits (16 bytes)

export const CSHARP_encrypt = (text) => {
  const encryptedData = AES.encrypt(text, CKEY, {
    iv: CIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encodeURIComponent(encryptedData); //.toString();
};

export const CSHARP_decrypt = (cipherText) => {
  const bytes = AES.decrypt(cipherText, CKEY, {
    iv: CIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const Week5_render = (
  week5,
  isCurrentMonth,
  pendingcolor,
  checkdaterange,
  its_future_week5,
  isCurrentWeek,
  navigateTo,
  Week5IsNoJob,
  Week5Date = new Date()
) => {
  return (
    week5 != null && (
      <div className={"row calender"}>
        <div
          className={
            "col card CalenderCard_Week " +
            (isCurrentWeek && isCurrentMonth
              ? " currenweekcolor"
              : pendingcolor && !Week5IsNoJob && !its_future_week5
                ? " pendingweekcolor"
                : (its_future_week5 && pendingcolor) ||
                  (pendingcolor && Week5IsNoJob)
                  ? ""
                  : " CompletedWeekColor")
          }
        >{`Week 5`}</div>

        {week5.map((c) => {
          return (
            <div
              className={
                "col card CalenderCard_Week_Date " +
                (isCurrentWeek && isCurrentMonth
                  ? " currenweekcolor"
                  : pendingcolor && !Week5IsNoJob && !its_future_week5
                    ? " pendingweekcolor"
                    : (its_future_week5 && pendingcolor) ||
                      (pendingcolor && Week5IsNoJob)
                      ? ""
                      : " CompletedWeekColor")
              }
              onClick={() => navigateTo(4, "22", Week5Date)}
            >
              {c}
            </div>
          );
        })}
      </div>
    )
  );
};

export const remarksAndDescriptionDownload = (unorderedData) => {
  const orderedKeys = [
    "Station Code",
    "Scheduled Date",
    "Equipment Short Name",
    "Equipment Code",
    "Maintenance Type Name",
    "Approved By",
    "Job Done By",
    "Status Name",
    "Closed Date",
    "Job Description",
    "Remarks By Engineer",
    "Remarks By Technician",
    "Downloads",
  ];

  const data = unorderedData.map((item) => {
    const orderedItem = {};
    orderedKeys.forEach((key) => {
      orderedItem[key] = item[key];
    });
    return orderedItem;
  });

  let sheetName = data[0]["Equipment Code"];
  sheetName = sheetName?.replaceAll("/", "_");
  sheetName = sheetName?.replaceAll("\\", "_");

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Define a cell style for headers
  const headerCellStyle = {
    font: { bold: true },
    fill: {
      type: "pattern",
      pattern: "solid",

      fgColor: { argb: "C6CFEF" }, // Yellow fill color
    },
  };

  // Set column headers and apply the cell style
  worksheet.addRow(orderedKeys);
  worksheet.getRow(1).eachCell((cell) => {
    cell.style = {
      font: { bold: true },
      fill: {
        type: "pattern",
        pattern: "solid",

        fgColor: { argb: "C6CFEF" }, // Yellow fill color
      },
      alignment: { shrinkToFit: true, horizontal: "right" },
    };
  });

  orderedKeys.forEach((key, index) => {
    const column = worksheet.getColumn(index + 1);
    if (index + 1 == 13) {
      column.width = key.length + 10;
    } else {
      column.width = key.length + 5;
    } // Adjust the width as needed
  });

  // Set text wrap for all columns
  worksheet.columns.forEach((column) => {
    column.alignment = { wrapText: true };
  });

  // Add the data to the worksheet
  data.forEach((item) => {
    worksheet.addRow(orderedKeys.map((key) => item[key]));
  });

  const downloadsColumn = worksheet.getColumn(orderedKeys.length);
  downloadsColumn.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
    if (rowNumber > 1) {
      // Skip header row
      const downloadUrl = cell.value;
      if (isURL(downloadUrl)) {
        const displayText = "Download";
        cell.value = {
          formula: `HYPERLINK("${downloadUrl}", "${displayText}")`,
        };
      }
    }
  });

  // Create a buffer to store the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, sheetName);
  });
};

export const BreakdownDepartmentReportDownload = (unorderedData) => {

  const orderedKeys = [
    "Station Code",
    "Department Name",
    "Ticket No",
    "Breakdown Date",
    // "PTW Number",
    // "PTW Date",
    // "Approvel Status",
    // "Assigned To",
    // "Assigned By",

    "User Level",
    "Remarks",
    "Status",
    "Downloads",
  ];

  const data = unorderedData.map((item) => {
    const orderedItem = {};
    orderedKeys.forEach((key) => {
      orderedItem[key] = item[key];
    });
    return orderedItem;
  });

  let sheetName = data[0]["Ticket No"];
  sheetName = sheetName?.replaceAll("/", "_");
  sheetName = sheetName?.replaceAll("\\", "_");

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Define a cell style for headers
  const headerCellStyle = {
    font: { bold: true },
    fill: {
      type: "pattern",
      pattern: "solid",

      fgColor: { argb: "C6CFEF" }, // Yellow fill color
    },
  };

  // Set column headers and apply the cell style
  worksheet.addRow(orderedKeys);
  worksheet.getRow(1).eachCell((cell) => {
    cell.style = {
      font: { bold: true },
      fill: {
        type: "pattern",
        pattern: "solid",

        fgColor: { argb: "C6CFEF" }, // Yellow fill color
      },
      alignment: { shrinkToFit: true, horizontal: "right" },
    };
  });

  orderedKeys.forEach((key, index) => {
    const column = worksheet.getColumn(index + 1);
    if (index + 1 == 13) {
      column.width = key.length + 10;
    } else {
      column.width = key.length + 5;
    } // Adjust the width as needed
  });

  // Set text wrap for all columns
  worksheet.columns.forEach((column) => {
    column.alignment = { wrapText: true };
  });

  // Add the data to the worksheet
  data.forEach((item) => {
    worksheet.addRow(orderedKeys.map((key) => item[key]));
  });

  const downloadsColumn = worksheet.getColumn(orderedKeys.length);
  downloadsColumn.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
    if (rowNumber > 1) {
      // Skip header row
      const downloadUrl = cell.value;
      if (cell.value !== "No Attachment Found") {
        cell.value = {
          formula: `HYPERLINK("${cell.value}", "${cell.value}")`,
        };
      }
      // if (isURL(downloadUrl)) {

      //   alert();
      //   const displayText = "Download";
      //   cell.value = {
      //     formula: `HYPERLINK("${downloadUrl}", "${displayText}")`,
      //   };
      // }
    }
  });

  // Create a buffer to store the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, sheetName);
  });
};
function isURL(str) {
  // Regular expression for a basic URL pattern
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  return urlPattern.test(str);
}
export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1, 1);
}

export function dayDifference(startDate, endDate) {
  // Convert both dates to milliseconds
  var startMillis = startDate.getTime();
  var endMillis = endDate.getTime();

  // Calculate the difference in milliseconds
  var differenceMillis = startMillis - endMillis;

  // Convert the difference to days
  var differenceDays = differenceMillis / (1000 * 60 * 60 * 24);

  // Return the difference in days
  return differenceDays;
}
export function parseDDMMYYYYToDate(dateString) {
  var parts = dateString.split("-");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1; // Months are zero-based
  var year = parseInt(parts[2], 10);

  // Create a new Date object
  var parsedDate = new Date(year, month, day);

  return parsedDate;
}

export const GetSelectforTable = (options, value, disabled) => {
  if (disabled) {
    var select = `<select class="form-select" disabled  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">--Select--</option>`;
      options.map((v) => {
        if (v.value == value) {
          select += `<option selected value=${v.value}>${v.key}</option>`;
        } else {
          select += `<option value=${v.value}>${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  } else {
    var select = `<select class="form-select"  >`;
    if (options != null && options.length > 0) {
      select += `<option selected disabled value="0">--Select--</option>`;
      options.map((v) => {
        if (v.value == value) {
          select += `<option selected value=${v.value}>${v.key}</option>`;
        } else {
          select += `<option value=${v.value}>${v.key}</option>`;
        }
      });
    }
    select += `</select>`;
    return select;
  }
};

export const GetCurrentWeek = (date = new Date()) => {
  const first_week = Array.from({ length: 7 }, (_, i) => i + 1);
  const second_week = Array.from({ length: 7 }, (_, i) => i + 8);
  const third_week = Array.from({ length: 7 }, (_, i) => i + 15);
  const forth_week = Array.from({ length: 7 }, (_, i) => i + 22);
  const fifth_week = Array.from({ length: 7 }, (_, i) => i + 29);
  let listOfWeeks = [
    first_week,
    second_week,
    third_week,
    forth_week,
    fifth_week,
  ];
  let currentDate = date;
  let currentDay = currentDate.getDate();
  let currentMonth = currentDate.getMonth() + 1;

  let currentYear = currentDate.getFullYear();
  let finalValue = "";
  listOfWeeks.every((ele) => {
    if (ele.includes(currentDay)) {
      currentMonth = currentMonth > 9 ? currentMonth : "0" + currentMonth;
      currentDay = ele[0] > 9 ? ele[0] : "0" + ele[0];
      // if(currentDay > 23){
      //   currentDay = 1;
      // }
      finalValue = `${currentYear}-${currentMonth}-${currentDay}`;
      return false;
    }
    return true;
  });
  return finalValue;
};
export function GetCurrentWeekInDate() {
  let currentWeek = GetCurrentWeek();
  let CurrentWeekArray = currentWeek.split("-");

  let finalDate = new Date(
    CurrentWeekArray[0],
    CurrentWeekArray[1] - 1,
    CurrentWeekArray[2]
  );
  return finalDate;
}
export function YMDtoDate(date) {
  let dateArray = date.split("-");
  let finalDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  return finalDate;
}
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export function firstAndLastDateOfMonth(
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1
) {
  // JavaScript months are 0-based, so we subtract 1 from the provided month
  const firstDay = getYMDdate(new Date(year, month - 1, 1));

  // Set the date to the last day of the current month
  const lastDay = getYMDdate(new Date(year, month, 0));

  return { firstDay, lastDay };
}
export function convertToInitialCaps(inputString) {
  if (!inputString) return "";
  // Split the string into words
  var words = inputString.split(" ");

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together
  var resultString = capitalizedWords.join(" ");

  return resultString;
}

export function determineWeekColor(
  i,
  j,
  isCurrentMonth,
  pendingcolor,
  IsNoJob,
  its_future_week
) {
  if (i + 1 === j && isCurrentMonth && pendingcolor) {
    return "currenweekcolor";
  } else if (i + 1 === j && isCurrentMonth && !pendingcolor && !IsNoJob) {
    return "CompletedWeekColor";
  } else if (pendingcolor && !IsNoJob && !its_future_week) {
    return "pendingweekcolor";
  } else if ((its_future_week && pendingcolor) || (IsNoJob && pendingcolor)) {
    return "";
  } else {
    return "CompletedWeekColor";
  }
}

export function determineWeekColorForQuarter(
  i,
  j,
  isCurrentMonth,
  pendingcolor,
  checkdaterange,
  IsNoJob,
  its_future
) {
  if (i + 1 === j && isCurrentMonth && pendingcolor) {
    return "currenweekcolor";
  } else if (i + 1 === j && isCurrentMonth && !pendingcolor && !IsNoJob) {
    return "CompletedWeekColor";
  } else if (pendingcolor && checkdaterange && !IsNoJob) {
    return "pendingweekcolor";
  } else if ((its_future && pendingcolor) || (IsNoJob && pendingcolor)) {
    return "";
  } else {
    return "CompletedWeekColor";
  }
}

export const RoutineChecksReportOtherCellColor = (element, _index) => {
  const TextType = {
    58: "1,2,3",
    59: "ON,OFF",
    60: "OK,Not Ok",
    61: "W,NW",
    62: "Open,Close",
    63: "Local,Remote",
  };
  let options = TextType[parseInt(element[6].text)].split(",");
  let borderColor = element[6].style == "tableBodyOdd" ? "#e9e9e9" : "white";
  let selectedValues = element[_index].text.split(",");

  return options.map((value) => {
    let checkIsSelected = selectedValues.includes(String(value));
    return {
      text: String(value),
      fillColor: checkIsSelected ? "green" : "",
      border: [true, true, true, true],
      borderColor: Array.from({ length: 4 }, (_, i) => borderColor),
      margin: [5, 5, 5, 5],
      color: checkIsSelected ? "white" : "black",
      alignment: "center",
    };
  });
};

export const checkColVisible = (min = 0, max = 0, numCheck) => {
  let er = numCheck >= min && numCheck <= max;
  return er;
};

export const getCSSVariables = (variableNames) => {
  const cssVariables = {};
  const computedStyle = getComputedStyle(document.documentElement);
  variableNames.forEach((name) => {
    const propName = name.substring(2)?.replaceAll("-", "_");
    cssVariables[propName] = computedStyle.getPropertyValue(name).trim();
  });
  return cssVariables;
};

export const getCSSVariablesByList = (variableNames) => {
  const cssVariables = [];
  const computedStyle = getComputedStyle(document.documentElement);
  variableNames.forEach((name) => {
    cssVariables.push(computedStyle.getPropertyValue(name).trim());
  });
  return cssVariables;
};

export const getScheduleType = (date = new Date()) => {
  const month = date.getMonth() + 1;
  let ScheduleType = 0;

  if ([11, 12, 1].includes(month)) {
    ScheduleType = 75;
  } else if ([2, 3, 4].includes(month)) {
    ScheduleType = 76;
  } else if ([5, 6, 7].includes(month)) {
    ScheduleType = 77;
  } else if ([8, 9, 10].includes(month)) {
    ScheduleType = 78;
  }
  return ScheduleType;
};

export const getCurrentScheduleDate = (date = new Date()) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let ScheduleDate = "";

  if ([11, 12, 1].includes(month)) {
    ScheduleDate = new Date(year, 10, 1);
  } else if ([2, 3, 4].includes(month)) {
    ScheduleDate = new Date(year, 1, 1);
  } else if ([5, 6, 7].includes(month)) {
    ScheduleDate = new Date(year, 4, 1);
  } else if ([8, 9, 10].includes(month)) {
    ScheduleDate = new Date(year, 7, 1);
  }
  return ScheduleDate;
};

export function BuildTreeUsingParentID(data) {

  const tree = {};
  const map = {};

  // First pass: create a map of all nodes using their IDs as keys
  data.forEach((node) => {
    map[node.id] = { ...node, children: [] };
  });

  // Second pass: link each node to its parent
  data.forEach((node) => {
    const parentNode = map[node.parentId];
    if (parentNode) {
      parentNode.children.push(map[node.id]);
    } else {
      // delete map[node.id].children
      tree[node.id] = map[node.id];
    }
  });

  let Obj = Object.values(tree);
  CheckItsHasChild(Obj);
  Obj = Obj.sort((a, b) => b - a);

  // Convert tree object to array and return
  return Object.values(tree);
}

export function BuildTreeUsingParentIDAndRemoveLastChild(data) {
  const tree = {};
  const map = {};

  // First pass: create a map of all nodes using their IDs as keys
  data.forEach((node) => {
    map[node.id] = { ...node, children: [] };
  });

  // Second pass: link each node to its parent
  data.forEach((node) => {
    const parentNode = map[node.parentId];
    if (parentNode) {
      parentNode.children.push(map[node.id]);
    } else {
      // delete map[node.id].children
      tree[node.id] = map[node.id];
    }
  });

  let Obj = Object.values(tree);
  RemoveLastChild(Obj);
  Obj = Obj.sort((a, b) => b - a);

  // Convert tree object to array and return
  return Object.values(tree);
}

function CheckItsHasChild(obj = []) {
  obj.forEach((ele) => {
    if (ele?.children?.length) {
      CheckItsHasChild(ele.children);
    } else {
      ele.children = undefined;
    }
  });
}
function RemoveLastChild(obj = []) {
  for (let i = 0; i < obj.length; i++) {
    const ele = obj[i];
    if (ele.children && ele.children.length === 0) {
      obj.splice(i, 1);
      i--; // Adjust the index after removing the element
    } else if (ele.children && ele.children.length > 0) {
      RemoveLastChild(ele.children);
    }
  }
}

export const CovertParentIds = (list) => {
  let finalObj = [];
  if (list?.length) {
    function setParentIds(departmentList, parentId = -1) {
      for (let department of departmentList) {
        finalObj.push({
          id: department.id,
          name: department.name,
          parentId: parentId,
        });
        if (department?.children?.length > 0) {
          setParentIds(department.children, department.id);
        }
      }
    }
    setParentIds(list);
  }
  return finalObj;
};

export function findDepartmentById(departmentList, idToFind) {
  for (const department of departmentList) {
    if (department.id === idToFind) {
      return department;
    }
    if (department.children && department.children.length > 0) {
      const foundInChild = findDepartmentById(department.children, idToFind);
      if (foundInChild) {
        return foundInChild;
      }
    }
  }
  return null; // Return null if department with the specified ID is not found
}
export function propagateSelectionRecursively(nodes, IsChecked) {
  // nodes.forEach(node => {
  if (nodes.children) {
    propagateSelection(nodes, IsChecked);
    propagateSelectionRecursively(nodes.children, IsChecked); // Recursively call
  }
  // });
  return nodes;
}

// export function propagateSelectionRecursively(nodes) {
//   nodes.forEach(node => {
//     propagateSelection(node);
//     if (node.children) {
//       propagateSelectionRecursively(node.children); // Recursively call
//     }
//   });
// }

function propagateSelection(node, IsChecked) {
  if (node.children) {
    node.children.forEach((child) => {
      child.IsSelected = IsChecked;
      propagateSelection(child, IsChecked); // Recursively call
    });
  }
}

export const GenerateYearForReactSelect = () => {
  const currentYear = new Date().getFullYear();
  const Years = [];
  let CountYears = currentYear - 2022;

  for (let i = 0; i < CountYears; i++) {
    const year = currentYear - i;
    Years.push({ value: year, label: year.toString() });
  }
  return Years;
};

export const ShortMonthsForPeriodic = () => [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

export const DeepCopy = (List) => JSON.parse(JSON.stringify(List));

export const QuarterMOnthForPM = (
  PeriodicDate = new Date(),
  MonthConfig = []
) => {
  var date = PeriodicDate.getDate();
  var month = PeriodicDate.getMonth() + 1;
  var year = PeriodicDate.getFullYear();
  if (PeriodicDate.getDate() > 28 && PeriodicDate.getMonth() + 1 == 1) {
    date = 28;
  }

  let Q1StartMonth = MonthConfig.find((ele) => ele.ScheduleType == 86);
  let Q1EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 87)?.Month ?? 0;
  let Q2EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 88)?.Month ?? 0;
  let Q3EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 89)?.Month ?? 0;
  let Q4EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 90)?.Month ?? 0;

  if (Q1StartMonth) {
    let QuarterList = generateQuarters(Q1StartMonth.Month);
    if (QuarterList[0].includes(month)) {
      return new Date(`${year}-${Q1EndMonth}-${date}`);
    } else if (QuarterList[1].includes(month)) {
      return new Date(`${year}-${Q2EndMonth}-${date}`);
    } else if (QuarterList[2].includes(month)) {
      return new Date(`${year}-${Q3EndMonth}-${date}`);
    } else if (QuarterList[3].includes(month)) {
      return new Date(`${year}-${Q4EndMonth}-${date}`);
    }
  }
};


export const HalfYearlyMOnthForPM = (
  PeriodicDate = new Date(),
  MonthConfig = []
) => {
  var date = PeriodicDate.getDate();
  var month = PeriodicDate.getMonth() + 1;
  var year = PeriodicDate.getFullYear();
  if (PeriodicDate.getDate() > 28 && PeriodicDate.getMonth() + 1 == 1) {
    date = 28;
  }

  let Q1StartMonth = MonthConfig.find((ele) => ele.ScheduleType == 86);
  let H1EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 91)?.Month ?? 0;
  let H2EndMonth =
    MonthConfig.find((ele) => ele.ScheduleType == 92)?.Month ?? 0;


  if (Q1StartMonth) {
    let QuarterList = generateHalfYearly(Q1StartMonth.Month);
    if (QuarterList[0].includes(month)) {
      return new Date(`${year}-${H1EndMonth}-${date}`);
    } else if (QuarterList[1].includes(month)) {
      return new Date(`${year}-${H2EndMonth}-${date}`);
    }
  }
};

export const YearlyMOnthForPM = (
  PeriodicDate = new Date(),
  MonthConfig = []
) => {
  var date = PeriodicDate.getDate();
  var year = PeriodicDate.getFullYear();
  if (PeriodicDate.getDate() > 28 && PeriodicDate.getMonth() + 1 == 1) {
    date = 28;
  }


  let YearEnd =
    MonthConfig.find((ele) => ele.ScheduleType == 93)?.Month ?? 0;

  if (YearEnd) {
    return new Date(`${year}-${YearEnd}-${date}`);
  }
};

export function generateQuarters(startMonth) {
  const quarters = [];

  for (let i = 0; i < 4; i++) {
    const quarter = [];
    for (let j = 0; j < 3; j++) {
      let month = (startMonth + j + i * 3) % 12;
      if (month === 0) month = 12; // Convert 0 to 12 for December
      quarter.push(month);
    }
    quarters.push(quarter);
  }

  return quarters;
}

export function generateHalfYearly() {

  return [[1, 2, 3, 4, 5, 6], [7, 8, 9, 10, 11, 12]];
}




export function GetToDateAndWeek(CurrentView, CurrentWeek, FromDate) {
  let ScheduleType = MaintenanceTypes[CurrentView ?? "month"];
  let week = CurrentWeek;
  let ToDate = moment(FromDate).add(6, "d").format("YYYY-MM-DD").trim();

  if (CurrentView == "month") {
    ToDate = FromDate;
    week = -1;
  }
  return { ScheduleType, ToDate, week };
}

export const getL0RoleID = () => {
  var value = localStorage.getItem(Roles.LO_RoleId);
  if (value != null) {
    var res = Decrypt(value);
    return res;
  }
  return "";
};


export const tableLayout = () => {
  var objLayout = {};
  // Horizontal line thickness
  objLayout["hLineWidth"] = function (i) {
    return 0.5;
  };
  // Vertikal line thickness
  objLayout["vLineWidth"] = function (i) {
    return 0.5;
  };

  // Horizontal line color
  objLayout["hLineColor"] = function (i) {
    return "#aaa";
  };
  // Vertical line color
  objLayout["vLineColor"] = function (i) {
    return "#aaa";
  };
  // Left padding of the cell
  objLayout["paddingLeft"] = function (i) {
    return 4;
  };
  // Right padding of the cell
  objLayout["paddingRight"] = function (i) {
    return 4;
  };
  return objLayout;
};




function validateField(model, field, displayName) {
  const value = model[field];
  let isValid = true;

  if (typeof value === "number") {
    isValid = value > 0;
  } else if (typeof value === "string") {
    isValid = value.trim() !== "";
  } else if (Array.isArray(value)) {
    isValid = value.length > 0;
  }
  else if (typeof value === "object" && value !== null) {
    isValid = Object.keys(value).length > 0;
  } else {
    isValid = !!value;
  }

  if (!isValid) {
    return `${displayName} is required.`;
  }
  return null;
}

export function validateModel(model, fieldNames) {
  const errors = {};

  Object.entries(fieldNames).forEach(([field, displayName]) => {
    const errorMessage = validateField(model, field, displayName);
    if (errorMessage) {
      errors[field] = errorMessage;
    }
  });
  const hasErrors = Object.keys(errors).length > 0;
  return hasErrors ? errors : null;
}


export function isImage(filename) {
  const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|webp|svg)$/i;
  return pattern.test(filename);
}

export function FullFormatDate(dateString) {
  return moment(dateString).format('DD-MMM-YYYY HH:mm:ss');
}

export const findKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};