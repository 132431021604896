import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useReducer,
} from "react";
import { showSuccessMsg, showErrorMsg } from "../../components";
import BreakdownMaintenanceService, {
  getRCAReport,
} from "./BreakdownMaintenanceService";
import $ from "jquery";
import { AppContext } from "../../services/ContextProvider";
import { GetLoginUserID, getUserDetails } from "../../services/CommonService";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DynamicTable from "./DynamicTable";
import { DownloadModel } from "./DownloadModel";
import RCAImagePopup from "../../components/RCAImagePopup";
import { DownLoadImagesForReport } from "./DownLoadImagesForReport";
const obj = new BreakdownMaintenanceService();
export const SaveDepManualBreakdownMaindenceRCAReport = ({
  tableRefresh,
  popUp,
  TicketID,
}) => {
  const fileInputRef = useRef(null);
  const { hideLoading } = useContext(AppContext);
  const [FileDatas, setFileDatas] = useState([]);
  const [Flags, setFlag] = useState(0);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [DateTimeIncident, setDateTimeIncident] = useState(null);
  const [DateTimeClosure, setDateTimeClosure] = useState(null);
  const [ProbableCauseOfFailure, setProbableCauseOfFailure] = useState("");
  const [CorrectiveAction, setCorrectiveAction] = useState("");
  const [DetailsOfFaultyComponents, setDetailsOfFaultyComponents] =
    useState("");
  const [DetailsOfNewReplacedComponents, setDetailsOfNewReplacedComponents] =
    useState("");
  const [DescriptionOfIncident, setDescriptionOfIncident] = useState("");
  const [DetailIncident, setDetailIncident] = useState([
    { id: 1, cells: ["", ""] },
    { id: 2, cells: ["", ""] },
    { id: 3, cells: ["", ""] },
  ]);
  const [TimeSequenceOfIncidents, setTimeSequenceOfIncidents] = useState([
    { id: 1, cells: ["", ""] },
    { id: 2, cells: ["", ""] },
    { id: 3, cells: ["", ""] },
  ]);
  const [LastMaintenanceData, SetLastMaintenanceData] = useState([]);
  const [errors, seterrors] = useState({});
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [totalData, setTotaldata] = useState({});
  const [popUpImg, setPopUpImg] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      setInitialData();
      try {
        if (TicketID > 0) {
          const res = await getRCAReport(TicketID);
          const { lastMaintenanceDetails, reportDetails } = res.data;
          const totalData = reportDetails[0];
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the file input field
          }
          SetLastMaintenanceData(lastMaintenanceDetails ?? []);
          if (totalData.initialTicketID > 0) {
            setDateTimeIncident(
              totalData?.dateTimeIncident ? totalData?.dateTimeIncident : ""
            );
            setDateTimeClosure(
              totalData?.dateTimeClosure ? totalData?.dateTimeClosure : null
            );
            setProbableCauseOfFailure(
              totalData?.probableCauseOfFailure
                ? totalData?.probableCauseOfFailure
                : ""
            );
            setCorrectiveAction(
              totalData?.correctiveAction ? totalData?.correctiveAction : ""
            );
            setDetailsOfFaultyComponents(
              totalData?.detailsOfFaultyComponents
                ? totalData?.detailsOfFaultyComponents
                : ""
            );
            setDetailsOfNewReplacedComponents(
              totalData?.detailsOfNewReplacedComponents
                ? totalData?.detailsOfNewReplacedComponents
                : ""
            );
            setDescriptionOfIncident(
              totalData?.descriptionofincident
                ? totalData?.descriptionofincident
                : ""
            );
            setDetailIncident(
              totalData?.detailsofIncident
                ? JSON.parse(totalData?.detailsofIncident)
                : [
                    { id: 1, cells: ["", ""] },
                    { id: 2, cells: ["", ""] },
                    { id: 3, cells: ["", ""] },
                  ]
            );
            setTimeSequenceOfIncidents(
              totalData?.timeSequenceOfIncidents
                ? JSON.parse(totalData?.timeSequenceOfIncidents)
                : [
                    { id: 1, cells: ["", ""] },
                    { id: 2, cells: ["", ""] },
                    { id: 3, cells: ["", ""] },
                  ]
            );
            setPopUpImg(totalData?.images ? totalData?.images : null);
          }
          setTotaldata(totalData);
        }
      } catch (error) {
        throw error;
      }
    };
    fetchData(); // pre field data
  }, [popUp, TicketID]);
  const setInitialData = () => {
    seterrors((pre) => ({
      ...pre,
      DateTimeClosure: "",
      DateTimeIncident: "",
    }));
    SetLastMaintenanceData([]);
    setDateTimeIncident("");
    setDateTimeClosure("");
    setProbableCauseOfFailure("");
    setCorrectiveAction("");
    setDetailsOfFaultyComponents("");
    setDetailsOfNewReplacedComponents("");
    setDescriptionOfIncident("");
    setDetailIncident([
      { id: 1, cells: ["", ""] },
      { id: 2, cells: ["", ""] },
      { id: 3, cells: ["", ""] },
    ]);
    setTimeSequenceOfIncidents([
      { id: 1, cells: ["", ""] },
      { id: 2, cells: ["", ""] },
      { id: 3, cells: ["", ""] },
    ]);
    setPopUpImg("");
  };
  useEffect(() => {
    tableRefresh(Refresh);
  }, [Refresh]);
  const handleDateAndTimeOfIncident = (e) => {
    setDateTimeIncident(e.target.value);
  };
  const handleDateAndTimeOfClouse = (e) => {
    setDateTimeClosure(e.target.value);
  };
  const handleProbableCauseOfFailure = (e) => {
    setProbableCauseOfFailure(e.target.value);
  };
  const handleCorrectiveAction = (e) => {
    setCorrectiveAction(e.target.value);
  };
  const handleDetailsOfFaultyComponents = (e) => {
    setDetailsOfFaultyComponents(e.target.value);
  };
  const handleDetailsOfNewReplacedComponents = (e) => {
    setDetailsOfNewReplacedComponents(e.target.value);
  };
  const handleDescriptionOfIncident = (e) => {
    setDescriptionOfIncident(e.target.value);
  };
  const handleDetailIncident = (tableData) => {
    setDetailIncident(tableData);
  };
  const handleTimeSequenceOfIncidents = (tableData) => {
    setTimeSequenceOfIncidents(tableData);
  };
  const handleImagePopUp = () => {
    setImagePopUp((p) => !p);
  };
  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    obj
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
      
        // hideLoading()
        let DownloadData = res.data;
        if (res.data) {
          if (totalData?.images) {
            let ImageIds = JSON.parse(totalData?.images).map(
              (ele) => ele.AttachmentID
            );
            if (ImageIds?.length) {
              DownloadData = res.data?.map((ele) => {
                if (ImageIds.includes(ele.AttachmentID)) {
                  ele.Checked = true;
                }
                return ele;
              });
            }
          }
        }
        setFileDatas(DownloadData); // SetRefresh(Refresh + 1); // setDownLoadRefresh();
      })
      .catch((err) => {
        // hideLoading()
        console.log(err);
      });
  };
  function SaveDetailes(e) {
    e.preventDefault();
    const formData = new FormData();
    let flag = true;
    if (!DateTimeClosure || !DateTimeIncident) {
      seterrors({
        DateTimeClosure: "Please Provide Date and Time of Closure",
        DateTimeIncident: "Please Provide Date and Time of Incident",
      });
      flag = false;
    } // Iterate through each selected file and append to formData
    $.each($("#imageFile")[0].files, function (i, file) {
      formData.append("files", file);
    });
    if (flag) {
      obj
        .FileUpload(formData)
        .then((res) => {
        
          const formData = {
            ticketId: totalData.ticketId,
            corridorID: totalData.corridorID,
            departmentID: totalData.departmentID,
            stationID: totalData.stationID,
            dateTimeIncident: DateTimeIncident,
            dateTimeClosure: DateTimeClosure,
            descriptionofincident: DescriptionOfIncident,
            equipmentDetails: totalData.equipmentDetailID,
            lastMaintenanceDate: new Date(),
            timeSequenceOfIncidents: JSON.stringify(TimeSequenceOfIncidents),
            probableCauseOfFailure: ProbableCauseOfFailure,
            correctiveAction: CorrectiveAction,
            detailsOfFaultyComponents: DetailsOfFaultyComponents,
            detailsOfNewReplacedComponents: DetailsOfNewReplacedComponents,
            detailsofIncident: JSON.stringify(DetailIncident),
            images: res && res.length > 0 ? JSON.stringify(res) : popUpImg,
            SelectedImage: JSON.stringify(FileDatas?.filter((ele) => ele?.Checked)
              ?.map((ele) => {
                return { AttachmentID: ele.AttachmentID, Type: ele.Type };
              }))
              ?? "",
            SavedBy: GetLoginUserID(),
          };
        debugger
          obj
            .addRCAReport(formData)
            .then((res) => {
              if (res.errors) {
                showErrorMsg("Something went wrong, Please try after sometime");
              } else {
                setRefresh();
                hideLoading();
                $(".close").click();
                setTotaldata({});
                showSuccessMsg("Saved successfully.");
              }
            })
            .catch((err) => {
              setRefresh();
              console.log(err);
              showErrorMsg("Something went wrong, Please try after sometime 1");
              hideLoading();
            });
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
          showErrorMsg("Something went wrong, Please try after sometime");
        });
    }
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-3 col-sm-3">
          <div className="form-group">
            <label className="label">Department Name</label>
            <input
              style={{ marginTop: "10px" }}
              className="text-black form-control BreakdownManual"
              type="text"
              value={totalData.departmentName}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Corridor
            </label>
            <input
              style={{ marginTop: "10px" }}
              className="text-black form-control BreakdownManual"
              type="text"
              value={totalData.corridorName}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label">Station/Section Name</label>
            <input
              style={{ marginTop: "10px" }}
              className="text-black form-control BreakdownManual"
              type="text"
              value={totalData.stationCode}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label">Equipment Code</label>
            <input
              style={{ marginTop: "10px" }}
              className="text-black form-control BreakdownManual"
              type="text"
              value={totalData.equipmentCode}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Date and Time of Incident
              <span className="text-danger"> *</span>
            </label>
            <input
              type="datetime-local"
              name="Date_and_time_of_Incident"
              className="form-control PTWDate BreakdownManual"
              value={DateTimeIncident}
              onChange={handleDateAndTimeOfIncident}
            />
            <span className="text-danger">{errors.DateTimeIncident}</span>
          </div>
        </div>
        {/* Date and Time of Closure */}
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Date and Time of Closure
              <span className="text-danger"> *</span>
              {/* {getUserDetails().PrefixName == "L0" && ( 
             <span className="text-danger"> *</span> 
            )} */}
            </label>
            <input
              type="datetime-local"
              name="Date_and_time_of_Closure"
              className="form-control PTWDate BreakdownManual"
              value={DateTimeClosure}
              onChange={handleDateAndTimeOfClouse}
            />
            <span className="text-danger">{errors.DateTimeClosure}</span>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label className="label" htmlFor="imageInput">
              Images {/* <span className='text-danger'> *</span> */}
            </label>
            <div className="d-flex align-items-center gap-2">
              <input
                className="form-control"
                type="file"
                id="imageFile"
                name="imageFile"
                ref={fileInputRef}
                multiple
                disabled={
                  totalData.initialTicketID == undefined ||
                  totalData.initialTicketID == 0 ||
                  getUserDetails().PrefixName === "L1" ||
                  getUserDetails().PrefixName === "L3"
                    ? false
                    : true
                } // onChange={handleImageInput}
              />
              <button
                type="button" // data-bs-toggle="modal" // id="DownLoadImage" // data-bs-target="#DownLoadImage" title='Download' // onClick={handleImagePopUp}
                onClick={() => {
                  DownloadFiles(TicketID, 0, 8);
                  setFlag(2);
                  handleImagePopUp();
                }}
                className="btn btn-primary"
              >
                <i class="fa fa-picture-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        {imagePopUp && (
          <RCAImagePopup
            modelSize={"modal-fullscreen"}
            ModelTitle="Images"
            popUpImg={popUpImg}
            openClose={imagePopUp}
            handleChange={handleImagePopUp}
            model={
              <DownLoadImagesForReport
                setSelectedData={(ele) => {
                  setFileDatas(ele);
                  handleImagePopUp();
                }}
                Flag={Flags}
                DeleteAttachment={DownloadFiles}
                FileDatas={FileDatas}
              ></DownLoadImagesForReport>
            }
          />
        )}
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Probable Cause of Failure
            </label>
            <textarea
              className="form-control BreakdownManual" // placeholder="Please Enter Problem Description"
              style={{ resize: "none" }}
              type="text"
              name="Probable_Cause_of_Failure"
              value={ProbableCauseOfFailure}
              rows={3}
              onChange={handleProbableCauseOfFailure}
            />
            <span className="text-danger"> {errors.Remarks_L1} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Corrective action
            </label>
            <textarea
              className="form-control BreakdownManual" // placeholder="Please Enter Problem Description"
              style={{ resize: "none" }}
              type="text"
              name="Corrective_action"
              value={CorrectiveAction}
              rows={3}
              onChange={handleCorrectiveAction}
            />
            <span className="text-danger"> {errors.Remarks_L1} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Details of Faulty Components
            </label>
            <textarea
              className="form-control BreakdownManual" // placeholder="Please Enter Problem Description"
              style={{ resize: "none" }}
              type="text"
              name="DetailsOfFaultyComponents"
              value={DetailsOfFaultyComponents}
              rows={3}
              onChange={handleDetailsOfFaultyComponents}
            />
            <span className="text-danger"> {errors.Remarks_L1} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Details of New Replaced Components
            </label>
            <textarea
              className="form-control BreakdownManual" // placeholder="Add other details"
              style={{ resize: "none" }}
              type="text"
              name="DetailsOfNewReplacedComponents"
              value={DetailsOfNewReplacedComponents}
              rows={3}
              onChange={handleDetailsOfNewReplacedComponents}
            />
            <span className="text-danger"> {errors.Remarks_L1} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Description of Incident
            </label>
            <textarea
              className="form-control BreakdownManual" // placeholder="Please Enter Problem Description"
              style={{ resize: "none" }}
              type="text"
              name="DescriptionOfIncident"
              value={DescriptionOfIncident}
              rows={3}
              onChange={handleDescriptionOfIncident}
            />
            <span className="text-danger"> {errors.Remarks_L1} </span>
          </div>
        </div>
        {LastMaintenanceData?.length ? (
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">Last Maintenance Carried Out </label>
              <ol>
                {LastMaintenanceData?.map((ele) => {
                  return (
                    <li>
                      <span> {ele.MaintenanceTypeName}</span>
                      <small class="block text-ellipsis text-small-color">
                        <span>{ele.LastMaintenanceDate}</span>
                      </small>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex flex-column gap-4">
          <div>
            <DynamicTable
              DetailIncident={DetailIncident}
              handleChange={handleDetailIncident}
              title="Details of Incident"
            />
          </div>
          <div>
            <DynamicTable
              DetailIncident={TimeSequenceOfIncidents}
              handleChange={handleTimeSequenceOfIncidents}
              title="Time Sequence of Incidents"
            />
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-end mt-3 mb-1 primary ">
        <div className="col-md-2 m-6">
          <button
            type="button"
            id=""
            onClick={SaveDetailes}
            className={`btn add-btn secondary-btn submit-btn`}
            style={{ fontSize: "14px" }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
