import React, { useState, useContext } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { DataTableReports } from "../../components/DataTableReports";
import SpareService from "../spare/SpareService";

import makeAnimated from "react-select/animated";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import MasterDetailService from "../master/MasterDetailService";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";

const _spareService = new SpareService();
const animatedComponents = makeAnimated();
const MDS = new MasterDetailService();

const columnNames = [
  { title: "Serial No" },
  { title: "Station Code" },
  { title: "Equipment Tag No" },
  { title: "Spare Name" },
  { title: "Spare Part No" },
  { title: "Spare Location" },
  { title: "Available Quantity" },
  { title: "Quantity Used" },
  { title: "Spare Used Date" },
  { title: "Maintanance Type" },
  { title: "Used Employee" },
];
export const SpareReport = () => {
  const { showLoading, hideLoading, getCookies,handleGlobalStationName } = useContext(AppContext);

  const [fromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [StatusDropDown, setStatusDropDown] = useState([]);

  const [statusId, setStatusId] = useState(null);
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);

  const [faultList, setFaultList] = useState([]);
  const [orginalList, setOrginalList] = useState([]);
  const [data, setData] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const location = useLocation();

  const removeFaultList = (e) => {
    setFaultList(
      faultList.filter((faultItem) => statusId !== faultItem.statusId)
    );
  };

  useEffect(() => {
    getStationList();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    setFromDate(firstDay.toISOString().split("T")[0]);
    setToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;
      if (val.isBack == "True") {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);

    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map(x => x.label).join()
    handleGlobalStationName(StationLabel)
  };

  const onFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const onToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const onclear = () => {
    $("#FromDate").val(null);
    $("#ToDate").val(null);
    setFromDate("");
    setToDate("");
  };

  const getSpareData = (stationIds) => {
    showLoading();
    if (
      stationIds != null &&
      stationIds != "" &&
      fromDate != "" &&
      ToDate != ""
    ) {
      _spareService
        .SpareReport(fromDate, ToDate, stationIds)
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            console.log(response.data);
            var rowList = [];
            response.data.map((v, i) => {
              var row = [];
              row.push(i + 1);
              row.push(v.StationCode);
              row.push(v.EquipmentCode);
              row.push(v.SpareName);
              row.push(v.SparePartNo);
              row.push(v.SpareLocation);
              row.push(v.QuantityAvailable);
              row.push(v.Quantity);
              row.push(v.UsedDate);
              row.push(v.MaintananceType);
              row.push(v.Employee);

              rowList.push(row);
            });
            setOrginalList([...rowList]);
          } else {
            setOrginalList([]);
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } else {
      setOrginalList([]);
      hideLoading();
    }
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");
    console.log(ID);
    getSpareData(ID);
  }, [fromDate, ToDate, StationID]);
  return (
    <div>
      <Header
        title={"Equipment Spare Report"}
        removeFaultList={removeFaultList}
        stationchange={HandleChange}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        stationList={StationList}
        stationIDs={StationID}
        fromDate={fromDate}
        ToDate={ToDate}
        StatusDropDown={StatusDropDown}
        onclear={onclear}
        showButton={showButton}
      />
      <div className="card card-body">
        <DataTableReports
          columnList={columnNames}
          rowList={orginalList}
          id={"SpareTable"}
          title={"Equipment Spare Report"}
          isAsc={true}
        />
      </div>
    </div>
  );
};

const Header = ({
  title,
  onFromDateChange,
  stationList,
  stationIDs,
  stationchange,
  onToDateChange,
  fromDate,
  ToDate,
  StatusDropDown,
  HandleChange,
  onclear,
  showButton,
}) => {
  useEffect(() => {
    $(function () {
      if (fromDate != null) {
        $("#ToDate").attr("min", fromDate);
      }
      if (ToDate != null) {
        $("#FromDate").attr("max", ToDate);
      } else {
        $("#FromDate").val(null);
        $("#ToDate").val(null);
      }
    });
  }, [fromDate, ToDate]);

  return (
    <div className="page-header mb-3">
      <div className="row justify-content-end">
        <div className="col-5">
          <h3 className="page-title">{title}</h3>
          {showButton && (
            <>
              <Link
                to="/dashboard"
                className="btn btn-primary"
                state={{ Type: "2" }}
              >
                <i class="las la-chevron-left"></i>{" "}
                <span> Back to Dashboard</span>{" "}
              </Link>
            </>
          )}
        </div>
        {/* <div className='col-2'></div> */}
        <div className="col-2">
          <label className="label" for="">
            From Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            defaultValue={fromDate}
            id="FromDate"
            pattern="\d{4}-\d{2}-\d{2}"
            onChange={(e) => {
              onFromDateChange(e);
            }}
          />
        </div>
        <div className="col-2">
          <label className="label" for="">
            To Date{" "}
          </label>

          <input
            type="date"
            defaultValue={ToDate}
            className="form-control"
            id="ToDate"
            onChange={(e) => {
              onToDateChange(e);
            }}
          />
        </div>
        <div className="col-3">
          <label className="label">Stations</label>

          <ReactMultiSelect
            options={stationList}
            isMulti
            value={stationIDs}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => stationchange(e)}
            allowSelectAll={true}
          />
        </div>
      </div>
    </div>
  );
};
