import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export class UserService {
  GetUsers(userID, stationIDs = '', tvsVac = 0, depIDs = '') {
    
    return axios
      .get(`User/GetUsers?userID=${userID}&stationIDs=${stationIDs}&tvsVac=${tvsVac}&depIDs=${depIDs}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => null);
  }

  AddorUpdateUser(data) {
    console.log(data);
    return axios
      .post("User/AddOrUpdateUsers", data)
      .then((response) => {
        //;
        if (response != null && response.data != null) {
          if (response.data.hasOwnProperty("result")) {
            return response.data.result;
          }
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetRolesList() {
    return axios
      .get(`Role/GetRoleList`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => null);
  }
  GetUserRoleList(tvsVac) {
    return axios
      .get(`Role/GetUserRoleList?roleid=&isTvsVac=${tvsVac}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => null);
  }

  GetEmployeeCodes(type) {
    return axios
      .get(`User/GetEmployeCodes?type=${type}`)
      .then((response) => {
        //;
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        return null;
      })
      .catch((err) => null);
  }
  DeleteUser(empID) {

    return axios.get(`User/DeleteUser?empID=${empID}`)

  }
}
