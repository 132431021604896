import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Bar, getElementsAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const CommonBarChart = ({
  data,
  yTitle,
  xTitle,
  style,
  reff,
  showLegend,
  ShowDataLabel,
  id,
  setEquipmentCode,
  setStationColor,
  clickable,
  onDoubleClick,
  LegendBoxWidth,
  dataLabelsShow = false,
  ...props
}) => {
  const onClick = useCallback((event) => {
    const { current } = reff;

    if (!current) {
      return;
    }

    const points = current.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    var labelName;
    var slabel;
    var labelColor;
    if (points.length) {
      const firstPoint = points[0];
      labelName = current.data.labels[firstPoint.index];
      slabel = current.data.datasets[firstPoint.datasetIndex].label;

      labelColor =
        current.data.datasets[firstPoint.datasetIndex].backgroundColor;
      console.log(labelColor);
      const value =
        current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      // console.log(labelName, slabel, value);
    }
    var equipmentCode = labelName + "@" + slabel;
    // var equipmentCode = printElementAtEvent(getElementsAtEvent(current, event)) + '@' + slabel;
    setEquipmentCode(equipmentCode);
    setStationColor(labelColor);
  });

  return (
    <div>
      <Bar
        style={style}
        ref={reff}
        id={id}
        height={180}
        data={data}
        options={{
          onClick: clickable,
          responsive: true,
          intersect: true,
          plugins: {
            datalabels: {
              display: dataLabelsShow,
              align: "end",
              anchor: "end",
              display: function (context) {
                var dataset = context.dataset;
                var count = dataset.data.length;
                var value = dataset.data[context.dataIndex];

                return value > 0;
              },
              // color: function(context) {
              //   return context.dataset.backgroundColor;
              // },
            },

            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
            },

            legend: {
              display: showLegend,
              position: "top",
              labels: {
                font: {
                  weight: "800",
                  style: "normal",
                },
                boxWidth: LegendBoxWidth ?? 40,
              },
            },
          },
          scales: {
            x: {
              min: 0,
              max: 12,
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              title: {
                display: true,
                text: xTitle,
                color: "#333",
              },

              ticks: {
                display: true,
                autoSkip: true,
                callback: function (label, index, labels) {
                  // console.log(label, index, labels);
                  let val = `${data.labels[label]}`;
                  return val.length > 12 ? `${val.substring(0, 12)}...` : val;
                },
              },
            },
            y: {
              drawBorder: false,
              grace: "5%",
              ticks: {
                precision: 0,
              },
              title: {
                display: true,
                text: yTitle,
                color: "#333",
                font: {
                  size: 15,
                  weight: "600",
                  lineHeight: 1.2,
                },
                padding: { top: 0, left: 10, right: 10, bottom: 5 },
              },
            },
          },
        }}
        onDoubleClickCapture={onDoubleClick}
        // onDoubleClick={alert()}
      />
    </div>
  );
};
