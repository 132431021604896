import React from "react";
import { Dropdown } from "../../components/Dropdown";
import { useEffect, useState, useContext } from "react";
import $ from "jquery";
import swal from "sweetalert";
import {
  showWarningMsg,
  showDeleteSuccess,
  showErrorMsg,
} from "../../components/ToastMsg";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { PeriodicService } from "./PeriodicService";
import Global from "../../services/Global";


export const SpareList = ({
  IsAdd,
  PartDropDown,
  Equlist,
  saveSpare,
  SelectedSpareList,
  Ref,

}) => {
  const [errors, seterrors] = useState({});
  const [SpareLists, setSpareLists] = useState()
    // (SelectedSpareList != null && SelectedSpareList.length > 0) ? SelectedSpareList : []
  // );
  const [TicketModel, setTicketModel] = useState([]);
  const [PartDropDownModel, setPartDropDownModel] = useState([]);

  const [SpareID, setSpareID] = useState([]);
  const [PartNo, setPartNo] = useState([]);
  const [EqipmentNo, SetEqipmentNo] = useState([]);
  const [ListofSpares, SetListOfSpare] = useState([]);
  const [FilteredListofSpares, setFilteredListofSpares] = useState([]);
  const [priviledge, setpriviledge] = useState("");

  const { getCookies, setCookies, showLoading, hideLoading } =
    useContext(AppContext);

  const obj = new BreakdownMaintenanceService();
  const _PeriodicService = new PeriodicService();

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("");
    $("#SpareID").val("");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");

    seterrors({});
    setSpareID([]);

    saveSpare(SpareLists);
    // setSpareLists([]);
  }, [SpareLists, Ref]);

  // useEffect(() => {
  //   setSpareLists(SelectedSpareList);
  // }, [SelectedSpareList]);

  const GetSpareData = () => {
    let ID = localStorage.getItem("JobScheduleID");
    _PeriodicService
        .GetSpareUsingjobScheduleID(ID)
        .then((res) => {

            if (res.data != null && res.data.length > 0) {
              setSpareLists([...res.data]);
            }
            // else {
            //     setSpareList([]);
            // }

        })
        .catch((err) => {
            console.log(err);
        });
      }
  useEffect(() => {
    GetSpareData();
  
  }, [Ref])

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("");
    $("#SpareID").val("");
    $("#EqipmentName").val("");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");


    seterrors({});

    setSpareID([]);
    setPartNo([]);
    setPartDropDownModel([]);

    seterrors([]);
  }, [Ref]);

  useEffect(() => {
    var ModulePrivileges = getCookies(Global.COOKIES.modulePrivileage) != null ? getCookies(Global.COOKIES.modulePrivileage) : "";
    setpriviledge(ModulePrivileges);
  }, [])

  function AddHandleClick() {
    const spare = SpareID;
    const Part = PartNo;
    const Quantity = $("#Quantity").val();
    const Eqipment = EqipmentNo;

    // if (spare != null && Part != null) {
    var err = errors;
    var ErrCheck = true;

    if (Eqipment.EqipmentID == null || Eqipment.EqipmentID == "0") {
      err.EqipmentID = "Equiment name is required";
      ErrCheck = false;
    } else {
      err.EqipmentID = "";
    }

    if (spare == null || spare.spareID == null || spare.spareID == "0") {
      err.spareID = "Spare name is required";
      ErrCheck = false;
    } else {
      err.spareID = "";
    }
    if (Part == null || Part.spareDetailID == null || Part.spareDetailID == 0) {
      err.spareDetailID = "Part no is required";
      ErrCheck = false;
    } else {
      err.spareDetailID = "";
    }
    if (Quantity == null || Quantity == "") {
      err.Quantity = "Quantity is required";
      ErrCheck = false;
    } else {
      err.Quantity = "";
    }

    seterrors({ ...err });

    if (ErrCheck) {
      if (Number(Quantity) > 0 && Number(Quantity) <= Part.quantityAvailable) {
        var SpareLst = SpareLists != null ? SpareLists : [];

        var check = SpareLst.findIndex((x) => {
          return x.spareDetailID == Number(Part.spareDetailID);
        });

        if (check == -1) {
          swal({
            title: "Are you sure",
            text: "Do you want to add this spare?",
            icon: "info",
            buttons: true,
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              const NewSpare = {
                spareName: spare.spareName,
                spareID: spare.spareID,
                partNo: Part.PartNo,
                spareDetailID: Part.spareDetailID,
                quantity: Quantity,
                reorderLimit: Part.reorderLimit,
                quantityAvailable: Part.quantityAvailable - Quantity,
                eqipmentName: Eqipment.EqipmentName,
                equipmentDetailID: Eqipment.EqipmentID,
              };

              SpareLst.push(NewSpare);
              setSpareLists([...SpareLst]);
            }
          });
        } else {
          showWarningMsg(`Can't add duplicate Part No`);
        }
      } else {
        showWarningMsg(`Only ${Part.quantityAvailable} is available.`);
      }
    }
    // } else {
    //   const errr = {
    //     Quantity: "Quantity is required",
    //     spareDetailID: "Part No is required",
    //     spareID: "Spare name is required",
    //   };
    //   seterrors(errr);
    // }
  }

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("");
    $("#SpareID").val("");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");

    setSpareID([]);
    setPartNo([]);
    setPartDropDownModel([]);
    setSpareLists([]);
    seterrors([]);
    SetListOfSpare([]);
    setFilteredListofSpares([]);
  }, []);

  function PartNoHandleChange(e) {
    console.log(FilteredListofSpares);

    if (e.target.value > 0) {
      const Quantity = FilteredListofSpares.find((x) => {
        return Number(x.SpareDetailID) == Number(e.target.value);
      });
      const temp = {
        spareDetailID: e.target.value,
        PartNo: e.target.selectedOptions[0].label,
        quantityAvailable: Quantity.QuantityAvailable,
        reorderLimit: Quantity.ReorderLimit,
      };

      if (Quantity != null) {
        $("#AvailQuantity").html(
          `Available Quantity is : ${Quantity.QuantityAvailable}`
        );
        $("#ReorderLimit").html(`Reorder Limit Is : ${Quantity.ReorderLimit}`);
      } else {
        $("#AvailQuantity").html("");
        $("#ReorderLimit").html("");
      }
      setPartNo(temp);
    } else {
      setPartNo(null);
    }
  }
  function deleteSpare(spareId, SparedetailID, spareUsedID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this spare?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (spareUsedID > 0) {
          showLoading();
          obj
            .DeleteSparePart(spareId, SparedetailID, spareUsedID)
            .then((response) => {
              hideLoading();

              showDeleteSuccess();
              var remainSpare = SpareLists.filter((x) => {
                return x.spareDetailID != SparedetailID;
              });
              setSpareLists(remainSpare);
            })
            .catch((error) => {
              hideLoading();
              showErrorMsg("Something went wrong please try after sometime.");
            });
        } else {
          var remainSpare = SpareLists.filter((x) => {
            return x.spareDetailID != SparedetailID;
          });
          showDeleteSuccess();
          setSpareLists(remainSpare);
        }
      }
    });
  }

  function EquipChange(e) {
    const { value } = e.target;

    SetEqipmentNo({
      EqipmentID: value,
      EqipmentName: e.target.selectedOptions[0].label,
    });

    if (Number(value) > 0) {
      _PeriodicService
        .PeriodicGetDropList(Number(value))
        .then((res) => {
          var Spare = [];
          if (res.data[0].length > 0) {
            res.data[0].map((v) => {
              Spare.push({ key: v.SpareName, value: v.SpareID });
            });

            SetListOfSpare(Spare);
          } else {
            SetListOfSpare([]);
          }
          if (res.data[1].length > 0) {
            setFilteredListofSpares(res.data[1]);
          } else {
            setFilteredListofSpares([]);
          }
        })
        .catch((err) => {
          console.log(err);
          SetListOfSpare([]);
          setFilteredListofSpares([]);
        });
    } else {
      SetListOfSpare([]);
    }
  }

  function SpareHandleChange(e) {
    const { name, value } = e.target;

    setTicketModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    var PartDropDown;
    if (FilteredListofSpares != null) {
      console.log(FilteredListofSpares);
      const partDropDowns = FilteredListofSpares.filter((x) => {
        return Number(x.SpareID) == Number(e.target.value);
      });
      const temp = {
        spareID: e.target.value,
        spareName: e.target.selectedOptions[0].label,
      };
      setSpareID(temp);
      var tempList = [];
      partDropDowns.map((x) => {
        tempList.push({ key: x.SparePartNo, value: x.SpareDetailID, ...x });
      });

      setPartDropDownModel(tempList);
      $("#AvailQuantity").html("");
      $("#ReorderLimit").html("");
      $("#PartNoID").val("");
      setPartNo([]);

     
    }
  }

  return (
    <div>
      {IsAdd ? (
        <>
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Eqipment Tag No <span className="text-danger"> *</span>{" "}
                </label>
                <WithSelectDropDown
                  data={Equlist}
                  placeholder="Select"
                  name={"EqipmentName"}
                  HandleChange={EquipChange}
                  // initialVal={0}
                />

                <span className="text-danger mt-1"> {errors.EqipmentID} </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Spare Name <span className="text-danger"> *</span>{" "}
                </label>
                <WithSelectDropDown
                  data={ListofSpares}
                  placeholder="Select"
                  name={"SpareID"}
                  HandleChange={SpareHandleChange}
                  // initialVal={-1}
                />

                <span className="text-danger mt-1"> {errors.spareID} </span>
              </div>
            </div>

            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Part No <span className="text-danger"> *</span>{" "}
                </label>
                <WithSelectDropDown
                  data={PartDropDownModel}
                  placeholder="Select"
                  name={"PartNoID"}
                  HandleChange={PartNoHandleChange}
                //  initialVal={-1}
                />

                <span className="text-danger pt-1 mt-1">
                  {errors.spareDetailID}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-2 col-sm-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Quantity<span className="text-danger"> *</span>{" "}
                </label>
                <input
                  className="form-control"
                  type="number"
                  pattern="/^-?\d+\.?\d*$/"
                  onKeyPress="if(this.value.length==10) return false;"
                  placeholder="Quantity"
                  id="Quantity"
                />
                <span
                  id=""
                  className="text-danger pt-1 mt-1"
                  style={{ minHeight: "20px" }}
                >
                  {" "}
                  {errors.Quantity}{" "}
                </span>
                <br></br>
                <span
                  id="AvailQuantity"
                  className="text-info"
                  style={{ minHeight: "20px",display:"flex" }}
                >
                  {" "}
                </span>
                <span
                  id="ReorderLimit"
                  className="text-info"
                  style={{ minHeight: "20px" }}
                ></span>
              </div>
            </div>
            <div class="col-md-2 mt-4">
              <button
                // style={{ marginTop: "-50px" }}
                type="button"
                id="addRow"
                onClick={AddHandleClick}
                class="btn add-btn"
              >
                <i class="fa fa-plus"></i> Add
              </button>
            </div>


          </div>
          {/* <div className="row">
            <div class="col-7">
              <button
                style={{ marginTop: "-50px" }}
                type="button"
                id="addRow"
                onClick={AddHandleClick}
                class="btn add-btn"
              >
                <i class="fa fa-plus"></i> Add
              </button>
            </div>
          </div> */}
        </>
      ) : (
        ""
      )}

      <table
        id="SpareDetailes"
        style={{ marginBottom: "3px" }}
        class="table table-striped custom-table mt-3"
      >
        <thead>
          <tr>
            <th>Equipment Tag No</th>
            <th>Spare Name</th>
            <th>Part No</th>
            <th>Quantity</th>
            {priviledge.includes("BREAKDOWNSPAREADDDELETE") &&
              <th>Submit</th>
            }
          </tr>
        </thead>
        <tbody id="tbl1">
          <>
            {SpareLists != null && SpareLists.length > 0 ? (
              SpareLists.map((x) => {
                return (
                  <tr>
                    <td> {x.eqipmentName} </td>
                    <td> {x.spareName} </td>
                    <td>{x.partNo}</td>
                    <td>{x.quantity}</td>
                    {priviledge.includes("BREAKDOWNSPAREADDDELETE") &&
                      <td>
                        <button
                          type="button"
                          class="btn icon-btn text-center"
                          onClick={() =>
                            deleteSpare(x.spareID, x.spareDetailID, x.spareUsedID)
                          }
                        >
                          <i class="fa fa-trash m-r-5"></i>
                        </button>
                      </td>
                    }
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colspan="6">
                  No Data Found
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  );
};
