import React from "react";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { GetSelect, SumoMultiSelect } from "./../../services/CommonService";
import ParameterService from "./ParameterService";
import { MultiSelect } from "../../components/MultiSelect";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {
  showSaveError,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  showDeleteSuccess,
} from "./../../components/ToastMsg";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";

const _parameterService = new ParameterService();
function ParameterDetailsTable({
  columnList,
  rowList,
  tableName,
  parameters,
  status,
}) {
  const [isTable, setIsTable] = useState(true);
  var t;

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable]);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: false,
      autoWidth: false,
    });
    // $(".SumoSelect").select2({placeholder: "tyr"});
    $(".Selecttttt2").select2({
      multiple: true,
      closeOnSelect: false,
      placeholder: "--Select parameter type--",
      allowClear: true,
    });
    // $(".Selecttttt2").select2({multiple : true,closeOnSelect : false});

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);
      // $('tr').removeClass('shown');
      // $('tr').removeClass('dt-hasChild');
      // tr.addClass("shown");

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        var equipmentID = $(this).closest("tr").find("td:eq(1)").html();
        var childColumns = [
          {
            title: "Equipment ID",
            className: "hide",
          },
          {
            title: "ParameterConfigID",
            className: "hide",
          },
          {
            title: "Parameter ID",
            className: "hide",
          },
          {
            title: "Parameter Name",
            createdCell: (td, cellData, rowData, row, col) => {
              var b = $(GetSelect(parameters, cellData)).on(
                "change",
                function (e) {
                  //   var t = $(`#${tableName}`).DataTable();
                  //   console.log(t.cell(td));
                }
              );
              $(td).html(b);
            },
            width: "30%",
          },
          {
            title: "StatusIDs",
            className: "hide",
          },
          {
            title: "Parameter Type",
            createdCell: (td, cellData, rowData, row, col) => {
              // var b = $(SumoMultiSelect(parameters, cellData)).on("change", function (e) {
              //     //   var t = $(`#${tableName}`).DataTable();
              //     //   console.log(t.cell(td));
              // });

              var b = SumoMultiSelect(status, cellData);
              $(td).html(b);
            },
            width: "40%",
          },

          {
            title: "Units of Measurement",
            createdCell: (td, cellData, rowData, row, col) => {
              var b = $(
                `<input type="text" class="form-control" value="${cellData}" readonly style="background-color:#E0E0E0"/>`
              );
              $(td).html(b);
            },
            width: "20%",
          },
          {
            title: "row ID",
            className: "hide",
          },
          {
            title: "Submit",
            createdCell: (td, cellData, rowData, row, col) => {
              var b = "",
                c = "";
              if (getPrivilege().includes("EQUIPMENTPARAMETEREDIT")) {
                var b = $(`<button class='btn icon-btn' title='Save'>
                <i class='fa fa-check' style="margin-top:10px"></i>
              </button>`).on("click", function () {
                  saveParameterVal(rowData, row);
                });
              }
              if (getPrivilege().includes("EQUIPMENTPARAMETERDELETE")) {
                c = $(`<button class='btn icon-btn'  title='delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function () {
                  if (rowData[1] > 0) {
                    DeleteParameterConfiguration(
                      rowData[1],

                      equipmentID,
                      childColumns
                    );
                  }
                });
              }
              $(td).html(b);
              $(td).append(c);
            },
          },
        ];
        _parameterService
          .GetEquipmentParamterByID(equipmentID)
          .then((childRows) => {
            console.log(childRows.data);
            if (
              childRows != null &&
              childRows.data != null &&
              childRows.data.length > 0
            ) {
              var rowList = [];
              childRows.data.map((v, i) => {
                var rows = [];
                rows.push(equipmentID);
                rows.push(v.parameterConfigID);
                rows.push(v.parameterID);
                rows.push(v.parameterID);
                rows.push(v.statusID);
                rows.push(v.statusID);

                if (v.parameterID > 0) {
                  var findUnits = parameters.find(
                    (e) => e.value == v.parameterID
                  );
                  findUnits != null ? rows.push(findUnits.unit) : rows.push("");
                }
                rows.push(i + 1);
                rows.push("");
                rowList.push(rows);
              });
              row.child(ChildTable(equipmentID)).show();

              //set child table
              childTable(rowList, childColumns, equipmentID);
            } else {
              row.child(ChildTable(equipmentID)).show();

              //set child table
              childTable([], childColumns, equipmentID);
            }
          });

        tr.addClass("shown");
      }
    });

    return function () {
      table.destroy();
      $(".Selecttttt2").select2({
        multiple: true,
        closeOnSelect: false,
        placeholder: "--Select parameter type--",
        allowClear: true,
      });
    };
  };

  const childTable = (rowList, childColumns, equipmentID) => {
    // set child table as data table
    t = $(`#param_detail-${equipmentID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      bInfo: false,
      paging: false,
      scrollX: false,
      autoWidth: true,
      order: [[7, "desc"]],
    });
    // $(".SumoSelect").select2({placeholder: "tyr"});
    $(".Selecttttt2").select2({
      multiple: true,
      closeOnSelect: false,
      placeholder: "--Select parameter type--",
      allowClear: true,
    });

    //add new row functionality
    $(`#addRow-${equipmentID}`).on("click", function () {
      var rowID = t.column(0).data().length + 1;
      t.row.add([equipmentID, 0, 0, "", 0, "", "", rowID, ""]).draw(false);
      $(".Selecttttt2").select2({
        multiple: true,
        closeOnSelect: false,
        placeholder: "--Select parameter type--",
        allowClear: true,
      }); //update row values
      updateValues(t, equipmentID);
    });
    updateValues(t, equipmentID);
  };

  const updateValues = (t, equipmentID) => {
    $(`#param_detail-${equipmentID}  tbody  tr`).each(function (index, tr) {
      var row = t.row(this);
      var rowIndex = t.row(this).index();

      //on Paramter change
      var selectCell = $(this).closest("tr").find("td:eq(3)");
      selectCell.on("change", function (e) {
        //update parameter value
        t.cell({ row: rowIndex, column: 2 }).data(Number(e.target.value));

        //update paramter unit
        var parameterID = Number(e.target.value);
        if (parameterID > 0) {
          var paramName = parameters.find((v) => v.value == parameterID);
          if (paramName != null) {
            t.cell({ row: rowIndex, column: 6 }).data(paramName.unit);
          }
        }
      });

      var selectCell2 = $(this).closest("tr").find("td:eq(5) ");
      selectCell2.on("change", function (e) {
        var option_all = $(this)
          .closest("tr")
          .find(".Selecttttt2 option:selected")
          .map(function () {
            return $(this).val();
          })
          .get()
          .join(",");
        console.log(option_all);

        //update parameter value
        t.cell({ row: rowIndex, column: 4 }).data(option_all);

        // $('select').on('select2:close', function (evt) {
        //     var uldiv = $(this).siblings('span.select2').find('ul')
        //   var count = uldiv.find('li').length - 1;
        //      uldiv.html("<li>"+count+" items selected</li>")
        // });
      });
    });
  };
  const saveParameterVal = (data, row) => {
    var datas = t.column(2).data();

    if (
      data != null &&
      data.length > 0 &&
      data[0] != "" &&
      data[2] > 0 &&
      data[4] != ""

      // parseFloat(data[4]) != NaN &&
      // parseFloat(data[6]) != NaN
    ) {
      // var isExists = true;
      //

      // console.log(datas)

      // datas =  datas.reverse();

      // for (var i = 0; i < datas.length ; i++) {
      //     console.log(datas[i], Number(data[2]))
      //     if (Number(datas[i]) == Number(data[2])) {
      //         if (Number(data[7] != i+1)) {
      //             isExists = false;
      //             break;
      //         }

      //     }
      // }

      // if (isExists) {
      var paramDetails = {
        EquipmentID: Number(data[0]),
        ParameterConfigID: Number(data[1]),
        statusID: data[4],
        ParameterID: Number(data[2]),
        IsActive: 1,
      };

      _parameterService.AddParameterDetails(paramDetails).then((res) => {
        if (
          res != null &&
          res.isSuccess &&
          res.data != "Parameter already exists"
        ) {
          showSaveSuccess();
          t.cell({ row: row, column: 1 }).data(res.data);
        } else if (res.isSuccess && res.data == "Parameter already exists") {
          showWarningMsg(res.data);
        } else {
          showSaveError();
        }
      });
    } else {
      showWarningMsg("Parameter name already exists");
    }
  };

  const DeleteParameterConfiguration = (id, equipmentID, childColumns) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Equipment Parameter?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _parameterService
          .DeleteParameterConfiguration(id)
          .then((response) => {
            if (response == 1) {
              showDeleteSuccess();
              _parameterService
                .GetEquipmentParamterByID(equipmentID)
                .then((childRows) => {
                  if (
                    childRows != null &&
                    childRows.data != null &&
                    childRows.data.length > 0
                  ) {
                    var rowList = [];
                    childRows.data.map((v, i) => {
                      var rows = [];
                      rows.push(equipmentID);
                      rows.push(v.parameterConfigID);
                      rows.push(v.parameterID);
                      rows.push(v.parameterID);
                      rows.push(v.statusID);
                      rows.push(v.statusID);

                      if (v.parameterID > 0) {
                        var findUnits = parameters.find(
                          (e) => e.value == v.parameterID
                        );
                        findUnits != null
                          ? rows.push(findUnits.unit)
                          : rows.push("");
                      }
                      rows.push(i + 1);
                      rows.push("");
                      rowList.push(rows);
                    });
                    childTable(rowList, childColumns, equipmentID);
                  } else {
                    childTable([], childColumns, equipmentID);
                  }
                });
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div className="table-responsive">
      <div className="row">
        <div className="col-sm-12">
          {isTable ? (
            <table
              className="table table-striped no-footer display collapse-table"
              id={tableName}
              width="100%"
            ></table>
          ) : (
            /* No of coulms and no of rows must be equal*/
            <div className="row col-12 text-center mt-2 mb-2">
              <p className="text-muted">Table not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const ChildTable = (tableIndex) => {
  if (getPrivilege().includes("EQUIPMENTPARAMETERCREATE")) {
    return `<div class="row d-flex justify-content-end"><div class="col-md-2" style=" position: relative; top: -43px;"><button type="submit" id="addRow-${tableIndex}" class="btn add-btn btn-sm"><i class="fa fa-plus"></i> Row</button></div></div>
      <div class"row " style="margin-top: -43px;">
      <table id="param_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
      </table></div>`;
  } else {
    return `<div class="row d-flex justify-content-end"><div class="col-md-2" style=" position: relative; top: -43px;"><button type="submit" id="addRow-${tableIndex}" class="btn add-btn btn-sm"><i class="fa fa-plus"></i> Row</button></div></div>
        <div class"row "  style="margin-top: -43px;">
        <table id="param_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
        </table></div>`;
  }
};

export default ParameterDetailsTable;
