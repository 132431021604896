import React, { useContext, useEffect, useState } from "react";
import {
  ReactMultiSelect,
  Option,
  MultiValue,
  animatedComponents,
  ReactTreeView,
  showSaveSuccess,
  showSaveError,
} from "../../components";
import swal from "sweetalert";
import { PeriodicConfigurationContext } from "./ContextProvider";
import { findDepartmentById } from "../../services/CommonService";
import { PeriodicConfiguration_SavePeriodicConfiguration } from "../PeriodicMaintenance/PeriodicService";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import $ from "jquery";
import { FaRegSave } from "react-icons/fa";

export default function PeriodicConfigSave() {
  const {
    DropdownModel,
    setPeriodicConfigModel,
    PeriodicConfigModel,
    setDropdownModel,
  } = useContext(PeriodicConfigurationContext);
  const [initialWeekID, setinitialWeekID] = useState([]);
  const {
    FilterstationLists,
    StationList,
    EquipmentList,
    ScheduleList,
    WeekList,
    ErrorObj,
    FilterEquipmentList,
    DepartmentList,
    EquipmentTypeList,
    SaveStationList
  } = DropdownModel;

  const {
    StationID,
    DepartmentId,
    EquipmentID,
    ScheduleType,
    WeekID,
    DescriptionName,
    ComponentName,
    StatusOption1,
    StatusOption2,
    IsCritical,
    PeriodicID,
    MinValue,
    MaxValue,
    UOM,
    SystemTypeID,
    OrderNo,
  } = PeriodicConfigModel;

  useEffect(() => {
    FilterEquipment();
  }, [PeriodicID]);

  const AddHandleClick = () => {
    const Obj = {
      DepartmentId,
      PeriodicID,
      ScheduleType,
      EquipmentIDs: EquipmentID.value,
      WeekID: ScheduleType == 1 ? -1 : WeekID,
      DescriptionName,
      ComponentName,
      IsCritical,
      StationIDs: StationID.map((ele) => ele.value).join(),
      StatusOption2,
      StatusOption1,
      MinValue,
      MaxValue,
      UOM,
      SystemTypeID: SystemTypeID.value,
      OrderNo,
    };


    let Error = validateObj(Obj);
    console.log(Error);
    setDropdownModel((pre) => ({
      ...pre,
      ErrorObj: Error,
    }));
    
    if (!Error) {
      if (initialWeekID != WeekID && initialWeekID != '-1') {
        Swal(Obj);
      }
      else {
        PeriodicConfiguration_SavePeriodicConfiguration(Obj)
          .then((res) => {
            if (res?.data?.isSuccess) {
              showSaveSuccess();
              $("#ClosePeriodicConfigSave").click();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
            showSaveError();
          });
      }
    }

  };
  function Swal(Obj) {
    swal({
      title: "Are you sure?",
      text: "Would you like to update the week for the entire checklist?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        }
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        Obj.IsOverallWeek = 1;
        PeriodicConfiguration_SavePeriodicConfiguration(Obj)
          .then((res) => {
            if (res?.data?.isSuccess) {
              showSaveSuccess();
              $("#ClosePeriodicConfigSave").click();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
            showSaveError();
          });
      }
    });
  }

  const CommonHandleChange = (e, name) => {
    if (name) {
      setPeriodicConfigModel((pre) => ({
        ...pre,
        [name]: e,
      }));
    } else {
      const { name, value, checked } = e.target;
      setPeriodicConfigModel((pre) => ({
        ...pre,
        [name]: value,
      }));

      if ("IsCritical" === name) {
        setPeriodicConfigModel((pre) => ({
          ...pre,
          [name]: checked,
        }));
      }
    }



    if ("StationID" === name || name == "DepartmentId" || name == 'SystemTypeID') {
      debugger
      
      let selectedStations = name == "StationID" ? e : StationID;
      let selectedDepartmentID = name == 'DepartmentId' ? e : DepartmentId
      let selectedSystemTypeID = name == 'SystemTypeID' ? e.value : SystemTypeID.value
      

      if (selectedStations && selectedStations.length > 0 && selectedDepartmentID && selectedSystemTypeID) {
        const selectedStationIDs = selectedStations.map((station) =>
          parseInt(station.value, 10)
        );

        let filteredEquipment = FilterEquipmentList.filter((equipment) => {
          let equipmentStationIDs = equipment.StationID.split(",").map((id) =>
            parseInt(id, 10)
          );
          equipmentStationIDs = [...new Set(equipmentStationIDs)]

          let DepartmentIDs = equipment?.DepartmentID?.split(",").map((id) =>
            parseInt(id, 10)
          );
          DepartmentIDs = [... new Set(DepartmentIDs)]


          let isDepart = DepartmentIDs?.some((ele) => ele == selectedDepartmentID);
          let isType = equipment.SystemTypeID === selectedSystemTypeID;
          return (
            equipmentStationIDs.some((id) => selectedStationIDs.includes(id)) &&
            isDepart && isType
          );
        });

        setDropdownModel((pre) => ({
          ...pre,
          EquipmentList: filteredEquipment,
        }));
      } else {
        setDropdownModel((pre) => ({
          ...pre,
          EquipmentList: [],
        }));
      }

      setPeriodicConfigModel((pre) => ({
        ...pre,
        EquipmentID: {},
      }));
    }
    // if (name === 'EquipmentID') {
    //   setPeriodicConfigModel((pre) => ({
    //     ...pre,
    //     SystemTypeID: {},
    //   }));
    //   const { value } = e;
    //   let filterEquipmentTypeList = EquipmentTypeList.filter((e) => e.EquipmentID === value);
    //   setDropdownModel((pre) => ({
    //     ...pre,
    //     FilterEquipmentTypeList: filterEquipmentTypeList,
    //   }));
    // }
  };

  const onSelect = (id = 0, node) => {
    if (DepartmentList?.length) {
      let obj = findDepartmentById(DepartmentList, id);
      if (!Array.isArray(obj.children)) {
        let DepartList = JSON.parse(JSON.stringify(DepartmentList));
        DepartList[0].name = obj.name;
        DepartList[0].id = "Selected_" + obj.id;
        setDropdownModel((pre) => ({
          ...pre,
          DepartmentList: DepartList,
        }));
        node?.tree?.closeAll();

       let SaveStationList =FilterstationLists.filter((ele) => ele.Type == obj.Type)

       setDropdownModel((pre) => ({
        ...pre,
        SaveStationList
       }))



        CommonHandleChange(obj.id, "DepartmentId");
      }
    }
  };

  function FilterEquipment() {
    setinitialWeekID(WeekID);
    let selectedStations = StationID;
    if (selectedStations && selectedStations.length > 0 && DepartmentId > 0) {
      
      const selectedStationIDs = selectedStations.map((station) =>
        parseInt(station.value, 10)
      );

      let filteredEquipment = FilterEquipmentList.filter((equipment) => {
        const equipmentStationIDs = equipment.StationID.split(",").map((id) =>
          parseInt(id, 10)
        );

        const DepartmentIDs = equipment?.DepartmentID?.split(",").map((id) =>
          parseInt(id, 10)
        );
        let isDepart = DepartmentIDs?.some((ele) => ele == DepartmentId);

        return (
          equipmentStationIDs.some((id) => selectedStationIDs.includes(id)) &&
          isDepart
        );
      });

      setDropdownModel((pre) => ({
        ...pre,
        EquipmentList: filteredEquipment,
      }));
    } else {
      setDropdownModel((pre) => ({
        ...pre,
        EquipmentList: [],
      }));
    }
  }

  function validateObj(obj) {
    const errors = {};
    const fieldNames = {
      DepartmentId: "Department",
      ScheduleType: "Schedule Type",
      EquipmentIDs: "Equipment",
      WeekID: "Week",
      DescriptionName: "Description",
      ComponentName: "Component",
      IsCritical: "Critical",
      StationIDs: "Station",
      SystemTypeID: 'System Type',
    };

    const excludedKeys = [
      "PeriodicID",
      "IsCritical",
      "StatusOption2",
      "StatusOption1",
      'UOM',
      'MaxValue',
      'MinValue',
    ];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Skip validation for PeriodicID

        if (excludedKeys.includes(key)) {
          continue;
        }
        if (key === "WeekID" && obj["ScheduleType"] > 1 && obj[key] < 0) {
          errors[key] = `${fieldNames[key]} is required`;
          continue;
        }
        if (!obj[key]) {
          errors[key] = `${fieldNames[key]} is required`;
        }
      }
    }

    return Object.keys(errors).length > 0 ? errors : null;
  }

  return (
    <div>
      <form id="PeriodicConfig">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="label">
                Department Name<span className="text-danger"> *</span>{" "}
              </label>
              <ReactTreeView
                data={DepartmentList}
                onSelect={onSelect}
                openByDefault={false}
                isSearchNeed={false}
                width={300}
                height={150}
              />
              <span className="text-danger mt-1">
                {" "}
                {ErrorObj?.DepartmentId}{" "}
              </span>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Station Name<span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={SaveStationList}
                    isMulti
                    allowSelectAll
                    value={StationID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      CommonHandleChange(e, "StationID");
                    }}
                  />

                  <span className="text-danger mt-1"> {ErrorObj?.StationIDs} </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Equipment Type
                    <span className="text-danger"> *</span>
                  </label>
                  <ReactMultiSelect
                    options={EquipmentTypeList}
                    value={SystemTypeID}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "SystemTypeID");
                    }}
                  />
                  <span className="text-danger"> {ErrorObj?.SystemTypeID} </span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Equipment
                    <span className="text-danger"> *</span>
                  </label>

                  <ReactMultiSelect
                    options={EquipmentList}
                    value={EquipmentID}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "EquipmentID");
                    }}
                  />
                  <span className="text-danger"> {ErrorObj?.EquipmentIDs} </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Schedule Type
                    <span className="text-danger"> *</span>
                  </label>
                  <WithSelectDropDown
                    data={ScheduleList}
                    HandleChange={CommonHandleChange}
                    placeholder="Select"
                    value={ScheduleType}
                    name="ScheduleType"
                    initialVal={-1}
                    className={""}
                  />
                  <span className="text-danger"> {ErrorObj?.ScheduleType} </span>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    UOM
                    {/* <span className="text-danger"> *</span>{" "} */}
                  </label>
                  <input
                    className="form-control "
                    type="text"
                    name="UOM"
                    value={UOM}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                  {/* <span className="text-danger"> {ErrorObj?.DescriptionName} </span> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Order No{" "}
                  </label>
                  <input
                    className="form-control "
                    type="Number"
                    name="OrderNo"
                    value={OrderNo}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                  <span className="text-danger"> {ErrorObj?.StatusOption1} </span>
                </div>
              </div>
            </div></div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Week
                <span className="text-danger"> *</span>
              </label>
              <WithSelectDropDown
                data={WeekList}
                HandleChange={CommonHandleChange}
                placeholder="Select"
                value={WeekID}
                disabled={ScheduleType == 1}
                name="WeekID"
                initialVal={-1}
                className={" Weekclass"}
              />
              <span className="text-danger"> {ErrorObj?.WeekID} </span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Description
                <span className="text-danger"> *</span>
                {/* <span className="text-danger"> *</span>{" "} */}
              </label>
              <textarea
                className="form-control PeriodicClearClass"
                style={{ resize: "none" }}
                type="text"
                name="DescriptionName"
                value={DescriptionName}
                onChange={CommonHandleChange}
                rows={3}
              />
              <span className="text-danger"> {ErrorObj?.DescriptionName} </span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Component
                <span className="text-danger"> *</span>
                {/* <span className="text-danger"> *</span>{" "} */}
              </label>
              <textarea
                className="form-control PeriodicClearClass"
                style={{ resize: "none" }}
                type="text"
                name="ComponentName"
                value={ComponentName}
                onChange={CommonHandleChange}
                rows={3}
              />
              <span className="text-danger"> {ErrorObj?.ComponentName} </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Option 1{" "}
              </label>
              <input
                className="form-control "
                type="text"
                name="StatusOption1"
                value={StatusOption1}
                onChange={CommonHandleChange}
                rows={3}
              />
              <span className="text-danger"> {ErrorObj?.StatusOption1} </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Option 2
              </label>
              <input
                className="form-control "
                type="text"
                name="StatusOption2"
                value={StatusOption2}
                onChange={CommonHandleChange}
              />
              <span className="text-danger"> {ErrorObj?.StatusOption2} </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  for="#IsCritical"
                  name="IsCritical"
                  onChange={CommonHandleChange}
                  type="checkbox"
                  checked={IsCritical}
                />
                <label className="form-check-label" id="IsCritical">
                  IsCritical Job
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Max{" "}
              </label>
              <input
                className="form-control "
                type="Number"
                name="MinValue"
                value={MinValue}
                onChange={CommonHandleChange}
              // rows={3}
              />
              <span className="text-danger"> {ErrorObj?.StatusOption1} </span>
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Min{" "}
              </label>
              <input
                className="form-control "
                type="Number"
                name="MaxValue"
                value={MaxValue}
                onChange={CommonHandleChange}
                rows={3}
              />
              <span className="text-danger"> {ErrorObj?.StatusOption1} </span>
            </div>
          </div>
          <div className="col-md-2 mt-4">
            <button
              type="button"
              onClick={AddHandleClick}
              className="btn btn-primary float-end"
            >
              <FaRegSave /> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
