import React, { useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DashboardService from "../../screens/dashboard/DashboardService";
import { DataTableReports } from "../DataTableReports";
import BootrapModel from "../BootrapModel";
import { Line, getElementsAtEvent, getElementAtEvent } from "react-chartjs-2";
import { COLORS } from "../../services/CommonService";
import zoomPlugin from "chartjs-plugin-zoom";
import $ from "jquery";
import { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const DISPLAY = false;
const BORDER = false;
const CHART_AREA = true;
const TICKS = true;

const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function (tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return "Sum: " + sum;
};

const columnNames = [
  { title: "Serial No" },
  { title: "Station Code" },
  { title: "Spare" },
  { title: "Equipment Tag No" },
  { title: "Spare Part No" },
  { title: "Quantity Used" },
  { title: "Used Date" },
  { title: "Technician" },
  { title: "Maintanance Type" },
];

var _dashboardService = new DashboardService();

export const LineChart = (props) => {
  const {
    id,
    data,
    yTitle,
    min,
    max,
    title,
    year,
    month,
    reporttitle,
    download,
    mode,
  } = props;
  const ref = useRef();
  const [spareName, setSpareName] = useState(null);

  const printElementAtEvent = (element) => {
    console.log(element);
    if (!element.length) return;

    const temp = element.find((x) => {
      const { element } = x;

      return element.active == true;
    });

    const { datasetIndex, index } = temp;

    const temp2 = data.datasets.filter((item, i) => i === datasetIndex);

    return temp2[0].data[index].label;
  };

  const onClick = (event) => {
    const { current } = ref;

    if (!current) {
      return;
    }
    var sparename = printElementAtEvent(getElementsAtEvent(current, event));
    setSpareName(sparename);
  };

  const closepopup = () => {
    setSpareName(null);
  };

  return (
    <div>
      {spareName != null && spareName != "" ? (
        <>
          <button
            hidden
            id="popupbutton"
            data-bs-toggle="modal"
            data-bs-target="#SpareDetails"
          ></button>

          <BootrapModel
            Id={"SpareDetails"}
            modelSize={"modal-xl"}
            ModelTitle={"Spare Details"}
            module={
              <SpareModelData
                year={year}
                month={month}
                mode={mode}
                sparename={spareName}
                count={0}
                title={reporttitle}
              />
            }
            closepopup={closepopup}
          />
        </>
      ) : (
        ""
      )}

      <Line
        id={id}
        data={data}
        options={{
          parsing: {
            xAxisKey: "label",
            yAxisKey: "data",
          },
          responsive: true,

          interaction: {
            intersect: false,
            mode: "nearest",
            axis: "x",
          },

          plugins: {
            zoom: {
              pan: {
                enabled: true,
                onPanStart({ chart, point }) {
                  const area = chart.chartArea;
                },
                mode: "x",
              },
            },
            legend: {
              title: {
                display: true,
                text: title,
                font: {
                  size: 14,
                  weight: "bold",
                },
              },
              display: true,
              position: "top",
            },

            tooltip: {
              mode: "nearest",
              axis: "x",
              callbacks: {
                footer: footer,
              },
            },
            animations: {
              tension: {
                duration: 1000,
                easing: "linear",
                from: 1,
                to: 0,
                loop: true,
              },
            },
          },

          scales: {
            x: {
              min: 0,
              max: 4,

              grid: {
                display: DISPLAY,
                drawBorder: BORDER,
                drawOnChartArea: CHART_AREA,
                drawTicks: TICKS,
              },
              offset: true,
            },
            y: {
              // beginAtZero: true,
              ticks: {
                precision: 0,
              },
              grid: {
                drawBorder: false,
                color: function (context) {
                  if (context.tick.value == min || context.tick.value == max) {
                    return COLORS.red;
                  }
                  return "#e6e6e6";
                },
              },
              title: {
                display: true,
                text: yTitle,
                color: "#333",
                font: {
                  size: 15,
                  weight: "600",
                  lineHeight: 1.2,
                },
                padding: { top: 0, left: 10, right: 10, bottom: 5 },
              },
            },
          },
        }}
        ref={ref}
        onClick={download ? onClick : null}
      />
    </div>
  );
};

export const SpareModelData = ({
  year,
  sparename,
  count,
  title,
  month,
  mode,
}) => {
  const [spareList, setSpareList] = useState([]);

  // table.res
  const GetSpare = () => {
    _dashboardService
      .GetChartSpareList(year, month, mode, sparename)
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          // setSpareList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(i + 1);
            row.push(v.stationCode);
            row.push(v.spareName);
            row.push(v.equipmentCode);
            row.push(v.sparePartNo);
            row.push(v.quantity);
            row.push(v.usedDate.split(" ")[0]);
            row.push(v.employee.split(" ")[0]);
            row.push(v.maintananceType);

            rowList.push(row);
          });
          setSpareList([...rowList]);
          $("#popupbutton").trigger("click");
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    GetSpare();
  }, [year, sparename, month, mode]);

  return (
    <DataTableReports
      columnList={columnNames}
      rowList={spareList}
      id={"SparesTable"}
      title={title}
      isAsc={true}
    />
  );
};
