import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import RevenueConfigService from "./RevenueConfigService";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import { HtmlTable } from "../../components/HtmlTable";
import swal from "sweetalert";
import makeAnimated from "react-select/animated";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import { Dropdown } from "../../components/Dropdown";

import {
  showSaveSuccess,
  showSaveError,
  showWarningMsg,
  showDeleteSuccess,
} from "./../../components/ToastMsg";
import { NONE } from "react-big-calendar/lib/utils/Resources";

const _nonrevenueService = new RevenueConfigService();
const animatedComponents = makeAnimated();

const RevenueHourTable = ({
  revenueHourID,
  EquipmentTagNoTempList,
  HandleSubmit,
  ParameterList,
}) => {
  const [EquipmentTagNo, setEquipmentTagNo] = useState([]);
  const [EquipmentTempTag, setEquipmentTag] = useState([]);
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  // console.log(tableRef)
  const tableName = "table1";

  const [rows, setRows] = useState([]);
  const [time, setTime] = useState([]);

  const CommonHandleChange = (e) => {
    
    const { name, value } = e.target;

    if (name == "StartTime") {
      setTime((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name == "EndTime") {
      setTime((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setTime((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const CommonHandleCheckChange = (e) => {
    
    const { name, checked } = e.target;

    setTime((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const GetData = () => {
    if (revenueHourID != null) {
      _nonrevenueService
        .NonRevenue_GetDataByID(revenueHourID)
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            var dataLst = [];
            response.data.map((v, i) => {
              var row = [];
              row.push("");
              row.push(v.RevenueHourDetailID);
              row.push(v.EquipmentDetailID);
              row.push(v.EquipmentCode);
              row.push(v.FromTime);
              row.push(v.EndTime);
              row.push(v.IsShutdown);
              row.push(v.Remark);
              row.push(v.ParameterName);
              row.push("");

              dataLst.push(row);
            });
            setRows([...dataLst]);
          } else {
            // alert(revenueHourID);
            // setRows([]);
          }
        });
    }

    // else {
    //     setRows([]);
    // }
  };

  useEffect(() => {
    setTime([]);
    setEquipmentTagNo([]);
    setEquipmentTag([]);
    if (revenueHourID != 0) {
      GetData();
    } else {
      setRows([]);
    }
  }, [revenueHourID]);

  const HandleChange = (e) => {
    setEquipmentTagNo(e);
  };

  const isTimeRangeOverlap = (startTime1, endTime1, startTime2, endTime2) => {
    return startTime1 <= endTime2 && startTime2 <= endTime1;
  };

  const AddNonRevenuetime = () => {
    
    var ErrCheck = true;


    if (EquipmentTagNo != null && time != null) {
      if (EquipmentTagNo.length == 0) {
        ErrCheck = false;
      }
      if (time?.StartTime == null || time?.StartTime == "") {
        ErrCheck = false;
      }
      if (time?.EndTime == null || time?.EndTime == "") {
        ErrCheck = false;
      }
    }

    if (!ErrCheck) {
      
      if(!time.IsShutdown){
        showWarningMsg("Please fill all the fields");
      }else{
        ErrCheck = true;
      }
    }
    // if(time?.StartTime > time?.EndTime || time?.EndTime < time?.StartTime){
    //     showWarningMsg("Please choose valid time range");
    //     return false;
    //  }

    var val = 1;

    EquipmentTagNo.map((v, i) => {
      for (let i = 0; i < rows.length; i++) {
        const equipmentDetail = rows[i];

        let revenueHourDetailID = v.RevenueHourDetailID
          ? v.RevenueHourDetailID
          : 0;
        // console.log(v?.RevenueHourDetailID ? v.RevenueHourDetailID : 0)
        if (
          equipmentDetail[2] == v.EquipmentDetailID &&
          equipmentDetail[1] != revenueHourDetailID &&
          isTimeRangeOverlap(
            equipmentDetail[4],
            equipmentDetail[5],
            time.StartTime,
            time.EndTime,
            time?.IsShutdown,
            time?.Remark,
            time?.ParameterID
          )
        ) {
          showWarningMsg(`Time range overlaps for ${v.EquipmentCode}`);
          val = 0;
          break;
        }
      }
    });
    if (val != 0 && ErrCheck) {
      setTimeout(() => {
        GetData();
        setEquipmentTagNo([]);
        setEquipmentTag([]);
        setTime([]);
      }, 1000);
      HandleSubmit(EquipmentTagNo, time);
    }
  };

  const onDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _nonrevenueService
          .DeleteNonRevenueDetails(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              GetData();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const columnNames = [
    {
      title: "",
      width: "50px",
      //   title: "<input type='checkbox' id='selectall' class='form-check-input'/>",
      createdCell: (td, cellData, rowData, row, col) => {
        var a = rowData[2];

        if (a != 0) {
          var b = $(
            "<input type='checkbox'  class='checks form-check-input' />"
          ).on("change", function (e) {
            if (e.target.checked) {
              let temp = EquipmentTempTag;

              var index = temp.findIndex(
                (item) => item?.EquipmentDetailID == rowData[2]
              );
              if (index != -1) {
                e.target.checked = false;
                showWarningMsg("Same equipment tag no cannot be choosed");
              } else {
                temp.push({
                  value: rowData[2],
                  label: rowData[3],
                  RevenueHourDetailID: rowData[1],
                  EquipmentDetailID: rowData[2],
                  EquipmentCode: rowData[3],
                });
                setEquipmentTagNo([...temp]);
              }
            } else {
              let temp = EquipmentTempTag;

              var index = temp.findIndex(
                (item) => item.RevenueHourDetailID == rowData[1]
              );
              if (index != -1) {
                temp.splice(index, 1);
                setEquipmentTagNo([...temp]);
              }
              // removedRow.push(rowData[1]);
              // setRemoveRows([...removedRow]);
            }
          });
          $(td).html(b);
        } else {
          var b = "";

          $(td).html(b);
        }
      },
    },
    {
      title: "Non Revenue Hour ID",
      className: "hide",
    },
    { title: "Equipment Tag ID", className: "hide" },
    { title: "Equipment Tag No" },
    { title: "Start Time" },
    { title: "End Time" },
    { title: "Is Shutdown" },
    { title: "Remark" },
    { title: "ParameterName" },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
        getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var c = "";

        if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
            <i class='fa fa-trash m-l-15'></i>
          </button>`).on("click", function () {
            onDeleteClick(Number(rowData[1]));
          });
        }

        $(td).html(c);
      },
    },
  ];

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-4">
          <label className="label">
            Equipment Tag No <span className="text-danger"> *</span>{" "}
          </label>
          <ReactMultiSelect
            options={EquipmentTagNoTempList}
            isMulti
            value={EquipmentTagNo}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => HandleChange(e)}
            allowSelectAll={true}
            isStyle={true}
          />
        </div>

        <div className="col-md-3">
          <label className="label">
            From Time <span className="text-danger"> *</span>{" "}
          </label>
          <input
            className="form-control"
            name="StartTime"
            value={time?.StartTime ? time?.StartTime : ""}
            type="time"
            onChange={CommonHandleChange}
          />
        </div>
        <div className="col-md-3">
          <label className="label">
            To Time <span className="text-danger"> *</span>{" "}
          </label>
          <input
            className="form-control"
            name="EndTime"
            value={time?.EndTime ? time?.EndTime : ""}
            type="time"
            onChange={CommonHandleChange}
          />
        </div>

        <div className="col-md-2">
          <div class="input-block mb-3">
            <label class="d-block col-form-label">Is Shutdown</label>
            <div class="status-toggle">
              <input
                type="checkbox"
                id="contact_status"
                class="check"
                name="IsShutdown"
                checked={time?.IsShutdown}
                onChange={CommonHandleCheckChange}
              />
              <label for="contact_status" class="checktoggle">
                checkbox
              </label>
            </div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <div class="input-block mb-3">
            <label class="d-block col-form-label">Status</label>
            <div class="status-toggle">
              <input
                type="checkbox"
                id="contact_status"
                class="check"
                name="IsShutdown"
                value={time?.IsShutdown}
                onChange={CommonHandleChange}
              />
              <label for="contact_status" class="checktoggle">
                checkbox
              </label>
            </div>
          </div>
        </div> */}
        {ParameterList.length > 0 && (
          <div className="col-md-4">
            <div className="form-group">
              <label className="label" htmlFor="EquipmentID">
                Parameter Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={ParameterList}
                placeholder="--Select--"
                name={"ParameterID"}
                HandleChange={CommonHandleChange}
                value={time.ParameterID}
                className="BreakdownManualDrop"
                initialVal={-1}
                // disabled={revenueHourID != 0 ? true : false}
              />
            </div>
          </div>
        )}
        <div className="col-md-4">
          <label className="label">Remark</label>
          <div class="form-floating">
            <textarea
              className="form-control SystemBreakdown"
              placeholder="Please Enter Problem Description"
              style={{ resize: "none" }}
              type="text"
              name="Remark"
              value={time?.Remark ? time?.Remark : ""}
              rows={3}
              onChange={CommonHandleChange}
            />
          </div>
        </div>

        {getPrivilege().includes("NONREVENUECONFIGURATIONCREATE") && (
          <div className="col-md-2 mt-4">
            <button
              type="submit"
              className="btn add-btn mb-2"
              onClick={() => AddNonRevenuetime()}
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
        )}
      </div>
      <HtmlTable
        columnList={columnNames}
        rowList={rows}
        id={"nonrevenue"}
        orderby={0}
      />
      {/* <div className="table-responsive" style={{ overflow: "hidden" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <table
                            className="table table-striped no-footer display"
                            id={tableName}
                           
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th style={{ display: "none" }}>Non Revenue Hour ID</th>
                                    <th>Equipment Tag No</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    rows.map(row => (
                                        <tr key={row.RevenueHourDetailID}>
                                            <td style={{ display: "none" }}>
                                                {row.RevenueHourDetailID}
                                            </td>
                                            <td>
                                                {row.EquipmentCode}

                                            </td>
                                            <td>
                                                {row.editable ? (
                                                    <div className='form-group'>
                                                        <input className='form-control' type="time" value={row.FromTime} onChange={e => setRows(rows.map(r => {
                                                            if (r.id === row.id) {
                                                                return { ...r, FromTime: e.target.value };
                                                            } else {
                                                                return r;
                                                            }
                                                        }))} />
                                                    </div>

                                                ) : row.FromTime
                                                }
                                            </td>
                                            <td>
                                                {row.editable ? (
                                                    <div className='form-group'>
                                                        <input className='form-control' type="time" value={row.EndTime} onChange={e => setRows(rows.map(r => {
                                                            if (r.RevenueHourDetailID === row.RevenueHourDetailID) {
                                                                return { ...r, EndTime: e.target.value };
                                                            } else {
                                                                return r;
                                                            }
                                                        }))} />
                                                    </div>

                                                ) : row.EndTime}
                                            </td>
                                            <td>
                                                {row.editable ? (
                                                    <button className="btn icon-btn" onClick={() => handleSave()} ><i class='fa fa-check'></i></button>
                                                ) : (
                                                    <button className="btn icon-btn" onClick={() => handleEdit(row.RevenueHourDetailID)} ><i class='fa fa-pencil m-r-5'></i></button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>


                        </table>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default RevenueHourTable;
