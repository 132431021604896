import React, { useEffect, useState, useContext } from "react";
import {
  BootrapModel,
  HtmlTable,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
} from "../../components";
import { useLocation } from "react-router-dom";

import { PeriodicService } from "../periodic/PeriodicService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import $ from "jquery";
import {
  GetLoginUserID,
  GetSelect,
  addDays,
  getUserDetails,
  getYMDdate,
  makeUnique,
} from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { json, useNavigate } from "react-router";
import { SpareList } from "../periodic/SpareList";
import swal from "sweetalert";
import BreakDownDetailsView from "../AuditingProcess/BreakDownDetailsView";

const _breakdownService = new BreakdownMaintenanceService();
function UG19StationPMPage() {
  const _periodicService = new PeriodicService();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    showLoading,
    hideLoading,
    PeriodicModel,
    setPeriodicModel,
    getCookies,
  } = useContext(AppContext);

  const {
    equipmentID,
    jobScheduleID,
    maintenanceTypeID,
    fromdate,
    enddate,
    StationID,
  } = location.state;

  const {
    EquipmentID,
    FromDate,
    EndDate,
    MaintenanceTypeID,
    PWTDate,
    Location,
    PWTNumber,
    IsChecked,
    JobScheduleID,
  } = PeriodicModel;

  const [UGSColumnList, setUGSColumnList] = useState([]);
  const [PTWList, setPTWList] = useState({});
  const [UGSRowList, setUGSRowList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [RemarksDataModel, setRemarksDataModel] = useState({
    RemarksData: [],
    IsOverAllRemarks: false,
  });
  const [spareList, setSpareList] = useState([]);
  const [DocumentNo, setDocumentNo] = useState('');

  const [equipmentDetailsList, setEquipmentDetailsList] = useState([]);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setPeriodicModel({
      EquipmentID: equipmentID,
      JobScheduleID: jobScheduleID,
      FromDate: fromdate,
      EndDate: enddate,
      MaintenanceTypeID: maintenanceTypeID,
      IsChecked: false,
      StationID: StationID,
      PWTDate: "",
      Location: "",
      PWTNumber: "",
    });

    return () => {
      setUGSColumnList([]);
      setUGSRowList([]);
    };
  }, []);

  useEffect(() => {
    GetDescriptionByID();
  }, [EquipmentID]);

  const CommonHandleChange = async (event) => {
    const { name, value } = event.target;
    // setCommonRequiredObj((pre) => ({
    //   ...pre,
    //   [name]: value,
    // }));
    setPeriodicModel({ [name]: value });
    // setTableReRender(!tableReRender)
  };

  const getJobscheduleID = () => {
    if (EquipmentID > 0) {
      // showLoading();
      _periodicService
        .Periodic_GetEquipmentDetailsUsingID(
          parseInt(StationID) ?? 0,
          equipmentID,
          maintenanceTypeID,
          fromdate,
          enddate
        )
        .then((res) => {
          const { descriptionList, equipmentList, documentList } = res.data.data;
          debugger;
          // const jobScheduleID = equipmentList[0]?.JobScheduleID;
          descriptionList.forEach((element) => {
            let filList = equipmentList.filter(
              (x) => x.MaintenanceScheduleID == element.MaintenanceScheduleID
            );
            const CheckAnyClose = filList.find((x) => x.JobScheduleID > 0);
            const firstObj = filList[0];
            const {
              JobScheduleID,
            } = CheckAnyClose ?? firstObj;
            Object.assign(element, {
              JobScheduleID,
            });
          });
          let FinalRowObj = descriptionList.sort((a, b) => a.RowID - b.RowID);

          let CheckAnySave = FinalRowObj.find((ele) => ele.JobScheduleID > 0);
          if (
            CheckAnySave &&
            Object.hasOwn(CheckAnySave, "JobScheduleEquipmentID")
          ) {
            const { JobScheduleID } = CheckAnySave;
            setPeriodicModel({
              JobScheduleID,
            });
          } else {
            setPeriodicModel({
              JobScheduleID: 0,
            });
          }

        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };
  const GetDescriptionByID = () => {
    if (EquipmentID > 0) {
      showLoading();
      _periodicService
        .Periodic_GetEquipmentDetailsUsingID(
          parseInt(StationID) ?? 0,
          equipmentID,
          maintenanceTypeID,
          fromdate,
          enddate
        )
        .then((res) => {
          hideLoading();
          if (res?.data?.data) {
            let columnsList = [];
            const { descriptionList, equipmentList, documentList, ptwList } = res.data.data;
            setPTWList(ptwList);
            // const val = Object.fromEntries(ptwList);
            setDocumentNo(documentList[0]?.DocumentNo);
            let isInputEnable =
              Global.COOKIES.TechRoleID == getUserDetails().RoleID
                ? ""
                : "disabled";

            descriptionList.forEach((element) => {
              let filList = equipmentList.filter(
                (x) => x.MaintenanceScheduleID == element.MaintenanceScheduleID
              );
              //resistence
              const CheckAnyClose = filList.find((x) => x.JobScheduleID > 0);
              const firstObj = filList[0];
              const {
                UOM,
                Status,
                PWTDate,
                PWTNumber,
                JobScheduleID,
                JobScheduleEquipmentID,
                Location,
                JobStatus,
                PreRemarks,
              } = CheckAnyClose ?? firstObj;
              let status = "";

              let overAllStatusByID = [
                ...new Set(filList.map((x) => x.JobStatus)),
              ].toString();
              if (filList?.length) {
                let overAllStatus = [...new Set(filList.map((x) => x.Status))];

                status = overAllStatus.join(" / ");
              }

              filList.forEach((FilterEquipment) => {
                const {
                  EquipmentDetailID,
                  ActualValue,
                  EquipmentStatus,
                  RemarksByEngineer,
                  RemarksByTechncian,
                  EquipmentCode,
                  PreRemarks,
                  OverAllRemarksByEngineer,
                  OverAllRemarksByTechncian,
                  Resistance,
                  Power,
                  Wye,
                  VRY,
                  VYB,
                  VBR
                } = FilterEquipment;
                element[`ActualValue_${EquipmentDetailID}`] = ActualValue;
                element[`EquipmentStatus_${EquipmentDetailID}`] =
                  EquipmentStatus;
                element[`RemarksForTech_${EquipmentDetailID}`] =
                  RemarksByTechncian;
                element[`RemarksForEngg_${EquipmentDetailID}`] =
                  RemarksByEngineer;
                element[`OverAllRemarksForTech_${EquipmentDetailID}`] =
                  OverAllRemarksByTechncian;
                element[`OverAllRemarksForEngg_${EquipmentDetailID}`] =
                  OverAllRemarksByEngineer;
                element[`Resistance_${EquipmentDetailID}`] =
                  Resistance;
                element[`Power_${EquipmentDetailID}`] =
                  Power;
                element[`Wye_${EquipmentDetailID}`] =
                  Wye;
                element[`VRY_${EquipmentDetailID}`] =
                  VRY;
                element[`VYB_${EquipmentDetailID}`] =
                  VYB;
                element[`VBR_${EquipmentDetailID}`] =
                  VBR;

                element[`EquipmentCode_${EquipmentDetailID}`] = EquipmentCode;
                element[`PreRemarks_${EquipmentDetailID}`] = PreRemarks;
              });

              Object.assign(element, {
                UOM,
                Status: status,
                JobScheduleID,
                PWTDate,
                PWTNumber,
                JobScheduleEquipmentID,
                Location,
                JobStatus,
                PreRemarks,
                overAllStatusByID,
              });
            });

            let FinalRowObj = descriptionList.sort((a, b) => a.RowID - b.RowID);

            let CheckAnySave = FinalRowObj.find((ele) => ele.JobScheduleID > 0);
            if (
              CheckAnySave &&
              Object.hasOwn(CheckAnySave, "JobScheduleEquipmentID")
            ) {
              const { PWTDate, PWTNumber, JobScheduleID } = CheckAnySave;
              setPeriodicModel({
                PWTDate,
                PWTNumber,
                JobScheduleID,
              });
            } else {
              setPeriodicModel({
                PWTDate: "",
                PWTNumber: "",
                JobScheduleID: 0,
              });
            }

            columnsList.push({
              title: " <p style = 'width : 50px'>S.No<p/>",
              data: "RowID",
              width: "50px",
              // className: "S_no_Class hide",
              // visible: false,
            });
            columnsList.push({
              title: `<p style = 'width : 150px'> Component<p/>`,
              data: "Component",
              width: "150px ",
            });
            columnsList.push({
              title: `<p style = 'width : 150px'>Work Description<p/>`,
              data: "Description",
              width: "150px ",
            });

            columnsList.push({
              title: "Rated Value",
              data: "RatedValue",
              createdCell: function (td, cellData, rowData, row, col) {
                let isInputEnable = "";
                if (COOKIES.TechRoleID == getUserDetails().RoleID && cellData) {
                  isInputEnable = "disabled";
                }

                let inputElement = "";
                inputElement = $(
                  `<input type="number" class="form-control_copy" ${isInputEnable}  style="width:100px" value="${cellData}"/>`
                ).on("change", function (e) {
                  e.preventDefault();
                  const { value } = e.target;
                  rowData.RatedValue = value;
                });

                $(td).html(inputElement);
              },
            });

            columnsList.push({
              title: "<p style='width:100px'>UOM</p>",
              data: "UOM",
              width: "60px",
              createdCell: function (td, cellData, rowData, row, col) {
                var inputElement = "";
                inputElement = $(
                  `<input type="text" class="form-control_copy" ${isInputEnable}  style="width:100px" value="${cellData}"/>`
                ).on("change", function (e) {
                  e.preventDefault();
                  const { value } = e.target;
                  rowData.UOM = value;
                });

                $(td).html(inputElement);
              },
            });

            if (descriptionList?.length) {
              let uniqueList = makeUnique(equipmentList, [
                "EquipmentCode",
                "EquipmentDetailID",
              ]);

              let EquipForSpare = [];
              uniqueList?.forEach((element, _) => {
                const { EquipmentCode, EquipmentDetailID } = element;

                EquipForSpare.push({
                  key: EquipmentCode,
                  value: EquipmentDetailID,
                });

                let FilterEquipmentList = equipmentList.filter(
                  (ele) => ele.EquipmentDetailID == EquipmentDetailID
                );

                let CheckAnyOpen = FilterEquipmentList.some(
                  (ele) => ele.JobStatus == 0
                );
                let checkAnyRejected = FilterEquipmentList.some(
                  (ele) => ele.JobStatus == 4
                );
                let CheckAllClosed = FilterEquipmentList.every(
                  (ele) => ele.JobStatus == 2
                );
                let CheckAllApproved = FilterEquipmentList.every(
                  (ele) => ele.JobStatus == 3
                );
                let CheckAllRejected = FilterEquipmentList.every(
                  (ele) => ele.JobStatus == 4
                );

                let overAllStatusByID = [
                  ...new Set(FilterEquipmentList.map((x) => x.JobStatus)),
                ].toString();

                let isDisabled = true;
                let ShowStatus = "";

                let ForMaintenanceDays = addDays(new Date(EndDate), 3);
                let ForEngineerDays = addDays(new Date(EndDate), 15);
                ShowStatus = CheckAllClosed
                  ? "Closed"
                  : CheckAllApproved
                    ? "Approved"
                    : CheckAllRejected
                      ? "Rejected"
                      : "";

                if (
                  getUserDetails().RoleID == Global.COOKIES.TechRoleID &&
                  (getYMDdate() >= getYMDdate(EndDate) ||
                    checkAnyRejected ||
                    CheckAnyOpen) &&
                  !CheckAllClosed
                ) {
                  isDisabled = false;
                }

                if (
                  getUserDetails().RoleID == Global.COOKIES.MaintainerRolID &&
                  getYMDdate() <= getYMDdate(ForMaintenanceDays) &&
                  !overAllStatusByID.includes(0)
                ) {
                  isDisabled = false;
                }

                if (
                  (getUserDetails().RoleID == Global.COOKIES.MaintainerRolID &&
                    getYMDdate() <= getYMDdate(ForMaintenanceDays) &&
                    CheckAllApproved) ||
                  CheckAllRejected
                ) {
                  isDisabled = true;
                }

                if (
                  getUserDetails().RoleID == Global.COOKIES.EnggRoleID &&
                  // getYMDdate() <= getYMDdate(ForEngineerDays) &&
                  (CheckAllClosed || CheckAllApproved || CheckAllRejected)
                ) {
                  isDisabled = false;
                }
                if (ShowStatus) {
                  ShowStatus = `( ${ShowStatus} ) `;
                }

                let div = $(`<div style ="width : 200px">`);
                let span = $(`<span style ="width : 150px ;display: flex;position: static" >`);
                let labelStatus = $(
                  `<span class='PeriodicStatusLabel'> ${ShowStatus}</span>`
                );
                let ActualRatingHeading = $(
                  '<p class= "row" ><span class ="col"> Status</span><span  class ="col">Actual Value</span></p>'
                );

                let para = $(`<p>${element.EquipmentCode}</p>`);
                let toggleIcon = `<input  class =" m-r-10 checks form-check-input PeriodicUG19Toggle" ${isDisabled ? "disabled" : ""
                  }  id="switch_annual_${EquipmentDetailID}" type="checkbox" style='position:static'  />`;

                span.append(toggleIcon);
                span.append(para);
                div.append(span);
                div.append(labelStatus);
                div.append(ActualRatingHeading);

                // div.append(Status);

                columnsList.push({
                  title: div.html(),
                  data: `EquipmentStatus_${EquipmentDetailID}`,
                  // width: "100px",

                  createdCell: function (td, cellData, rowData, row, col) {
                    let WrapDiv = $(
                      "<div class='btn-group' style='width:150px;position:static'>"
                    );
                    let { RatedValue } = rowData;
                    let ActualValueInp = "";
                    let StatusValueInp = "";
                    const ActualValue =
                      rowData[`ActualValue_${EquipmentDetailID}`];
                    const EquipmentStatus =
                      rowData[`EquipmentStatus_${EquipmentDetailID}`];
                    const Option1 =
                      rowData[`Option1`];
                    const Option2 =
                      rowData[`Option2`];
                    let ActualValBColor =
                      RatedValue < ActualValue ? "badge-danger" : "";
                    StatusValueInp = $(
                      GetSelect(
                        [
                          { key: Option1, value: 70 },
                          { key: Option2, value: 71 },
                          { key: "NA", value: 130 },
                        ],
                        EquipmentStatus,
                        isInputEnable,
                        "m-r-5",
                        "-"
                      )
                    );
                    StatusValueInp.on("change", function (e) {
                      e.preventDefault();
                      const { value } = e.target;
                      rowData[`EquipmentStatus_${EquipmentDetailID}`] = value;
                    });

                    ActualValueInp = $(
                      `<input type="number" ${isInputEnable}  class="form-control ${ActualValBColor}" value="${ActualValue}"/>`
                    );

                    ActualValueInp.on("change", function (e) {
                      e.preventDefault();
                      // let { RatedValue } = rowData;
                      const { value } = e.target;
                      if (RatedValue < value) {
                        $(e.target).addClass("badge-danger");
                      } else {
                        $(e.target).removeClass("badge-danger");
                      }
                      rowData[`ActualValue_${EquipmentDetailID}`] = value;
                    });

                    WrapDiv.append(StatusValueInp);
                    WrapDiv.append(ActualValueInp);

                    $(td).html(WrapDiv);
                  },
                });
              });

              setEquipmentDetailsList(EquipForSpare);

              columnsList.push({
                title: "Approval",
                data: "JobStatus",
                width: "60px",
                className:
                  Global.COOKIES.TechRoleID == getUserDetails().RoleID
                    ? "hide"
                    : "",
                visible:
                  Global.COOKIES.TechRoleID == getUserDetails().RoleID
                    ? false
                    : true,
                // searchable : true,

                createdCell: function (td, cellData, rowData, row, col) {
                  if (cellData == 2) {
                    cellData = 3;
                    rowData.TempJobStatus = 3
                  }
                  let DropdownOptions = [
                    { key: "Approved", value: 3 },

                    { key: "Rejected", value: 4 },
                  ];
                  let ForEngineerDays = addDays(new Date(EndDate), 15);

                  if (getYMDdate() >= getYMDdate(ForEngineerDays)) {
                    DropdownOptions = DropdownOptions.slice(1);
                    cellData = 4;
                    rowData.TempJobStatus = 4
                  }

                  let StatusValueInp = $(
                    GetSelect(DropdownOptions, cellData, "", "m-r-5")
                  );
                  StatusValueInp.on("change", function (e) {
                    e.preventDefault();
                    const { value } = e.target;
                    rowData[`TempJobStatus`] = value;
                  });

                  $(td).html(StatusValueInp);
                },
              });

              columnsList.push({
                title: "Status",
                data: "Status",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).html(GetPeriodicStatus(cellData));
                  $(td).addClass('Status')
                },
              });

              columnsList.push({
                title: "Submit",
                data: "MaintenanceScheduleID",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                  let WarpDiv = $("<div>");
                  let isShow = NeedToShowApproveBtn(rowData);

                  let disabled = isShow ? "" : "disabled";
                  let SubmitBtn =
                    $(`<button type="button" class='btn icon-btn' ${disabled} title='Save'>
                <i class='fa fa-check' style="margin-top:10px"  ></i>
                </button>`);

                  let AddRemarks =
                    $(`<button class='btn icon-btn' title='Add Remarks'  ${disabled} data-bs-toggle="modal" data-bs-target="#PeriodicRemarksModel" >
              <i class='fa fa-edit' style="margin-top:10px"></i>
            </button>`);

                  if (isShow) {
                    $(SubmitBtn).on("click", function (e) {
                      setTableKey(prevKey => prevKey + 1);
                      e.preventDefault();
                      let EquipmentIDs = Object.keys(rowData)
                        .filter((key) => key.startsWith("EquipmentStatus_"))
                        .map((key) => {
                          let FinalObj = {
                            JobScheduleForPeriodic: {
                              ...rowData,
                              EquipmentDetailID: key.slice(16),
                              EquipmentStatus: rowData[key],
                              ActualValue:
                                rowData[`ActualValue_${key.slice(16)}`],
                              RemarksForTech:
                                rowData[`RemarksForTech_${key.slice(16)}`],
                              RemarksForEngg:
                                rowData[`RemarksForEngg_${key.slice(16)}`],
                              OverAllRemarksForTech:
                                rowData[
                                `OverAllRemarksForTech_${key.slice(16)}`
                                ],
                              Resistance:
                                rowData[`Resistance_${key.slice(16)}`],
                              Power:
                                rowData[`Power_${key.slice(16)}`],
                              VBR:
                                rowData[`VBR_${key.slice(16)}`],
                              VYB:
                                rowData[`VYB_${key.slice(16)}`],
                              VRY:
                                rowData[`VRY_${key.slice(16)}`],
                              Wye:
                                rowData[`Wye_${key.slice(16)}`],
                              OverAllRemarksForEngg:
                                rowData[
                                `OverAllRemarksForEngg_${key.slice(16)}`
                                ],
                              FromDate: FromDate,
                              ToDate: EndDate,
                              AssignedBy:
                                getUserDetails().RoleID != COOKIES.TechRoleID
                                  ? GetLoginUserID()
                                  : 0,
                              AssignedTo:
                                getUserDetails().RoleID == COOKIES.TechRoleID
                                  ? GetLoginUserID()
                                  : 0,
                              JobStatus:
                                getUserDetails().RoleID == COOKIES.TechRoleID
                                  ? 2
                                  : rowData.TempJobStatus,
                              PWTNumber: $(".PWTNumber").val(),
                              PWTDate: $(".PWTDate").val(),
                              Location: $(".Location").val(),
                              IsSpare: 0,
                              SavedBy: GetLoginUserID(),

                            },
                            ListOfOrginalFileName:
                              rowData[`Upload_${key.slice(16)}`],
                          };
                          return FinalObj;
                        });

                      SaveJobDetailsForUG(EquipmentIDs);
                      UpdateStatus(e, rowData);
                    });
                    $(AddRemarks).on("click", () => {
                      let EquipmentIDs = Object.keys(rowData)
                        .map((key) =>
                          key.startsWith("EquipmentStatus_")
                            ? key.slice(16)
                            : null
                        )
                        .filter(Boolean);
                      let FinalObj = [];

                      EquipmentIDs.forEach((Id) => {
                        let Details = { ...rowData };
                        Details.EquipmentDetailID = Id;
                        Details.RemarksForTech =
                          Details[`RemarksForTech_${Id}`];

                        Details.RemarksForEngg =
                          Details[`RemarksForEngg_${Id}`];
                        Details.Resistance =
                          Details[`Resistance_${Id}`];
                        Details.Power =
                          Details[`Power_${Id}`];
                        Details.VBR =
                          Details[`VBR_${Id}`];
                        Details.VYB =
                          Details[`VYB_${Id}`];
                        Details.VRY =
                          Details[`VRY_${Id}`];
                        Details.Wye =
                          Details[`Wye_${Id}`];
                        Details.EquipmentCode = Details[`EquipmentCode_${Id}`];
                        Details.PreRemarks = Details[`PreRemarks_${Id}`];
                        FinalObj.push(Details);
                      });

                      FinalObj = FinalObj.reverse();
                      setRemarksDataModel((pre) => ({
                        ...pre,
                        IsOverAllRemarks: false,
                        RemarksData: FinalObj,
                      }));
                      // setRemarksData([...FinalObj]);
                    });
                  }

                  WarpDiv.append(AddRemarks);
                  WarpDiv.append(SubmitBtn);

                  $(td).html(WarpDiv);
                },
              });

              setUGSColumnList([...columnsList]);
              setUGSRowList([...FinalRowObj]);
            } else {
              setUGSColumnList([]);
              setUGSRowList([]);
            }
          } else {
            setUGSColumnList([]);
            setUGSRowList([]);

          }
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };
  function UpdateStatus(e, Data) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".Status");

      if (StatusClsCell.length > 0) {
        Data.StatusName = "Closed";

        StatusClsCell.html(GetPeriodicStatus("Closed"));
      }
    }
  }
  const SaveJobDetailsForUG = async (data) => {
    showLoading();
    await _periodicService
      .AddorUpdateJobMaintenanceByIDForUG(data)
      .then((data) => {
        hideLoading();
        if (data != null && data.data != 0) {
          showSaveSuccess();
          if (getUserDetails().RoleID != COOKIES.TechRoleID) {
            GetDescriptionByID();
          }
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
      });
  };

  function addedPlugin() {
    $(".PeriodicUG19Toggle").on("change", function (e) {
      let IsChecked = $(".PeriodicUG19Toggle:checked").length > 0;
      setPeriodicModel({ IsChecked });
    });
  }

  const NeedToShowApproveBtn = (rowData, Type = 1) => {
    let ForMaintenanceDays = addDays(new Date(EndDate), 3);
    let ForEngineerDays = addDays(new Date(EndDate), 15);
    let ForTechnician = getYMDdate(new Date(EndDate));
    let IsShow = false;

    const { overAllStatusByID } = rowData;
    if (Type == 1) {
      if (
        Global.COOKIES.TechRoleID == getUserDetails().RoleID &&
        (overAllStatusByID.includes(0) || overAllStatusByID.includes(4) || overAllStatusByID.includes(2)) &&
        ((getYMDdate() <= getYMDdate(ForTechnician) &&
          overAllStatusByID.includes(4)) ||
          overAllStatusByID.includes(0) || overAllStatusByID.includes(2))
      ) {
        IsShow = true;
      }
      if (
        Global.COOKIES.MaintainerRolID == getUserDetails().RoleID &&
        getYMDdate() <= getYMDdate(ForMaintenanceDays) &&
        overAllStatusByID.includes(2)
      ) {
        IsShow = true;
      }
      if (
        Global.COOKIES.EnggRoleID == getUserDetails().RoleID &&
        overAllStatusByID.includes(2)
      ) {
        IsShow = true;
      }
      return IsShow;
    } else if (Type == 2) {
      //Close
      if (
        Global.COOKIES.TechRoleID == getUserDetails().RoleID
        &&
        // getYMDdate() <= getYMDdate(ForTechnician) &&
        IsChecked
      ) {
        IsShow = true;
      }
    } else if (Type == 3) {
      // Approve BTn
      if (
        Global.COOKIES.MaintainerRolID == getUserDetails().RoleID &&
        getYMDdate() <= getYMDdate(ForMaintenanceDays) &&
        IsChecked
      ) {
        IsShow = true;
      }
      if (
        Global.COOKIES.EnggRoleID == getUserDetails().RoleID &&
        IsChecked
        //  &&
        // getYMDdate() > getYMDdate(ForEngineerDays)
      ) {
        IsShow = true;
      }
    } else if (Type == 4) {
      if (
        Global.COOKIES.MaintainerRolID == getUserDetails().RoleID &&
        getYMDdate() <= getYMDdate(ForMaintenanceDays) &&
        IsChecked
      ) {
        IsShow = true;
      }
      if (
        Global.COOKIES.EnggRoleID == getUserDetails().RoleID &&
        getYMDdate() <= getYMDdate(ForEngineerDays) &&
        IsChecked
      ) {
        IsShow = true;
      }
    }
    return IsShow;
  };

  const saveSpare = (list) => {
    setSpareList(list);
  };
  const savespareData = () => {
    var job = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      MaintenanceTypeID: MaintenanceTypeID,
      JobScheduleID: JobScheduleID,
      FromDate: FromDate,
      ToDate: EndDate,
      EquipmentID: EquipmentID,
      IsSpare: 1,
      SavedBy: GetLoginUserID(),
    };
    const data = {
      JobScheduleForPeriodic: job,
      sparesUsedMasterModel: spareList,
    };
    showLoading();
    _periodicService
      .AddorUpdateJobMaintenanceByID(data)
      .then((data) => {
        hideLoading();
        if (data != null && data.data != 0) {
          showSaveSuccess();
          navigate(-2);
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
      });
  };
  const confirmSelection = (status) => {
    if (status) {
      let statusName = "";

      if (status == 3) statusName = "approve";
      else if (status == 4) statusName = "reject";
      else statusName = "save";
      swal({
        title: "Are you sure?",
        text: `Do you want to ${statusName} this job`,
        icon: `warning`,
        buttons: true,
        dangerMode: status == 0,
      }).then((willDelete) => {
        if (willDelete) {
          updateJobStatus(status);
        }
      });
    }
  }
  const updateJobStatus = (status) => {
    let selectedRows = [];
    // let selectedRow = [];
    let overAllRemarks = UpdateOverAllRemarks();

    $(".PeriodicUG19Toggle:checked").each((_, ele) => {
      let EquipmentDetailID = ele.id.replace("switch_annual_", "");
      let Remarks = overAllRemarks.find(
        (ele) => ele.EquipmentDetailID == EquipmentDetailID
      );
      // let EquipmentIDs = Object.keys(rowData);
      let EquipmentIDs = $("#PeriodicUGStation")
        .DataTable()
        .rows()
        .data()
        .toArray();
      // const equipment = EquipmentIDs.find(e => e.EquipmentDetailID == `EquipmentStatus_${EquipmentDetailID}`);
      // const result = {};
      EquipmentIDs.forEach(item => {
        const statusKey = `EquipmentStatus_${EquipmentDetailID}`;
        const uomKey = `UOM`;
        const actualKey = `ActualValue_${EquipmentDetailID}`;
        // const ratedKey = `RatedValue_${EquipmentDetailID}`;

        // result[statusKey] = item[statusKey];
        selectedRows.push({
          EquipmentStatus: item[statusKey],
          UOM: item[uomKey],
          JobStatus: status,
          ActualValue: item[actualKey],
          // RatedValue: item[ratedKey],
          EquipmentDetailID,
          MaintenanceScheduleID: item.MaintenanceScheduleID,
          RemarksForTech: Remarks?.RemarksForTech ?? "",
          Resistance: Remarks?.Resistance ?? "",
          Power: Remarks?.Power ?? '',
          VBR: Remarks?.VBR ?? '',
          VYB: Remarks?.VYB ?? '',
          VRY: Remarks?.VRY ?? '',
          Wye: Remarks?.Wye ?? '',
          RemarksForEngg: Remarks?.RemarksForEngg ?? "",
          // PWTNumber: $(".PWTNumber").val().length ? $(".PWTNumber").val() : null,
          // PWTDate: $(".PWTDate").val().length ? $(".PWTDate").val() : null,
        });
      });
      // selectedRows.push({
      //   EquipmentDetailID,
      //   RemarksForTech: Remarks?.RemarksForTech ?? "",
      //   Resistance: Remarks?.Resistance ?? "",
      //   Power: Remarks?.Power ?? '',
      //   VBR: Remarks?.VBR ?? '',
      //   VYB: Remarks?.VYB ?? '',
      //   VRY: Remarks?.VRY ?? '',
      //   Wye: Remarks?.Wye ?? '',
      //   ActualValue: equipment,
      //   EquipmentStatus: equipment,
      //   UOM: equipment,

      //   RemarksForEngg: Remarks?.RemarksForEngg ?? "",
      //   PWTNumber: $(".PWTNumber").val().length ? $(".PWTNumber").val() : null,
      //   PWTDate: $(".PWTDate").val().length ? $(".PWTDate").val() : null,
      // });
    });
    debugger;
    const list = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      EquipmentID: EquipmentID,
      MaintenanceTypeID: MaintenanceTypeID,
      JobScheduleID: JobScheduleID,
      FromDate: FromDate,
      ToDate: EndDate,
      AssignedBy:
        getUserDetails().RoleID != COOKIES.TechRoleID ? GetLoginUserID() : 0,
      AssignedTo:
        getUserDetails().RoleID == COOKIES.TechRoleID ? GetLoginUserID() : 0,
      JobStatus: status,
      // equipmentWithPWT: selectedRows,
      ActualValueList: JSON.stringify(selectedRows),
      OverallClose: true,
      Savedby: GetLoginUserID(),
      PWTNumber: $(".PWTNumber").val().length ? $(".PWTNumber").val() : null,
      PWTDate: $(".PWTDate").val().length ? $(".PWTDate").val() : null,
    };
    console.log(list);
    showLoading();
    _periodicService
      .BulkUpdateJobScheduleByEquipmentDetails(list)
      .then((res) => {
        hideLoading();
        if (res != null && res.data != 0) {
          showSaveSuccess();
          setPeriodicModel({ IsChecked: false });
          GetDescriptionByID();
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };

  const UpdateOverAllRemarks = () => {
    let PeriodicUGStationData = $("#PeriodicUGStation")
      .DataTable()
      .rows()
      .data()
      .toArray();
    if (PeriodicUGStationData?.length) {
      let rowData = PeriodicUGStationData[0];
      let EquipmentIDs = Object.keys(rowData)
        .map((key) =>
          key.startsWith("EquipmentStatus_") ? key.slice(16) : null
        )
        .filter(Boolean);
      let FinalObj = [];
      EquipmentIDs.forEach((Id) => {
        let Details = { ...rowData };
        Details.EquipmentDetailID = Id;
        Details.RemarksForTech = Details[`OverAllRemarksForTech_${Id}`];
        Details.RemarksForEngg = Details[`OverAllRemarksForEngg_${Id}`];
        Details.EquipmentCode = Details[`EquipmentCode_${Id}`];
        Details.Resistance =
          Details[`Resistance_${Id}`];
        Details.Power =
          Details[`Power_${Id}`];
        Details.Wye =
          Details[`Wye_${Id}`];
        Details.VRY =
          Details[`VRY_${Id}`];
        Details.VYB =
          Details[`VYB_${Id}`];
        Details.VBR =
          Details[`VBR_${Id}`];
        FinalObj.push(Details);
      });

      setRemarksDataModel((pre) => ({
        ...pre,
        IsOverAllRemarks: true,
        RemarksData: FinalObj,
      }));
      return FinalObj;
    }
  };


  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-sm-6 mb-2 text-start">
              <button
                className="btn btn-success"
                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
                onClick={() => navigate(-2)}
              >
                Go Back
              </button>

            </div>

            <div className="col-sm-6 mb-2 text-end">
              {getUserDetails().RoleID == COOKIES.TechRoleID ? (
                <button
                  class="btn btn-success"
                  title="Close selected jobs"
                  disabled={!NeedToShowApproveBtn("_", 2)}
                  onClick={(e) => confirmSelection(2)}
                >
                  <span>Close</span>
                </button>
              ) : <> </>}
              {getUserDetails().RoleID != COOKIES.TechRoleID ? (
                <button
                  class="btn btn-success"
                  title="Approve seleted jobs"
                  disabled={!NeedToShowApproveBtn("_", 3)}
                  onClick={(e) => confirmSelection(3)}
                  style={{ marginRight: "10px" }}
                >
                  <span>Approve</span>
                </button>
              ) : <></>}
              {getUserDetails().RoleID != COOKIES.TechRoleID && (
                <button
                  class="btn btn-danger"
                  title="Reject seleted jobs"
                  disabled={!NeedToShowApproveBtn("_", 4)}
                  onClick={(e) => confirmSelection(4)}
                >
                  <span>Reject</span>
                </button>
              )}

              <button
                className="btn btn-info m-r-1 text-white m-l-5"
                title="Update OverAll Remarks"
                onClick={UpdateOverAllRemarks}
                data-bs-toggle="modal"
                data-bs-target="#PeriodicRemarksModel"
              >
                <span>Add Remarks</span>
              </button>
            </div>
          </div>
          <div style={{ position: "absolute", top: "65px", right: "33px" }}>
            {DocumentNo && (
              <span style={{ paddingLeft: "10px" }}>Doc Number: {DocumentNo}</span>
            )}
          </div>
          <div className="row">
            <div className="col-md-3 hide">
              <div className="form-group">
                <label className="label" for="empCode">
                  Location <span className="text-danger"> *</span>{" "}
                </label>{" "}
                <input
                  className="form-control Location"
                  type="text"
                  name="Location"
                  value={Location}
                  disabled
                // onChange={(e) => CommonHandleChange(e)}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  PTW Number
                </label>{" "}
                <input
                  className="form-control PWTNumber"
                  type="text"
                  name="PWTNumber"
                  value={PWTNumber}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  PTW Date
                </label>{" "}
                <input
                  className="form-control PWTDate"
                  type="date"
                  name="PWTDate"
                  onChange={CommonHandleChange}
                  value={PWTDate}
                />
              </div>
            </div>
            {Array.isArray(PTWList) && PTWList.length > 0 ? (
              <div className="col-md-3">
                <div className="form-group">
                  <div>
                    <button type="button" class="btn colorful-btn m-r-10" data-bs-toggle="modal" data-bs-target="#PTWValues" title="Previous Day PTW">
                      <span>View PTW History</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <p>No remarks available.</p>
              </div>
            )}
          </div>

          <div style={{ marginTop: "-25px" }}>
            <HtmlTable
              IsASC
              FixedColums={{
                left: 3,
                right: 2,
              }}
              OrderTargetList={Array.from(
                { length: UGSColumnList.length - 6 },
                (_, i) => i + 4
              )}
              rowList={UGSRowList}
              columnList={UGSColumnList}
              id={`PeriodicUGStation`}
              addedPlugin={addedPlugin}
              tableReRender={PeriodicModel}
              orderby={0}
            />
          </div>
          <div className="card card-body">
            {/* <h4 className='text-center'> Used Spare</h4> */}
            <SpareList
              IsAdd={getUserDetails().RoleID == COOKIES.TechRoleID}
              Equlist={equipmentDetailsList}
              saveSpare={saveSpare}
              SelectedSpareList={spareList}
            ></SpareList>
            <br></br>
            {getUserDetails().RoleID == COOKIES.TechRoleID && (
              <div className="row d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary col-md-2 col-sm-2"
                  onClick={savespareData}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <BootrapModel
            module={<RemarksModel rowList={RemarksDataModel ?? {}} />}
            ModelTitle={"Remarks"}
            modelSize={"modal-lg"}
            CloseID={"ClosePeriodicRemarksModel"}
            Id={"PeriodicRemarksModel"}
          ></BootrapModel>
          <BootrapModel
            module={
              <RemarksComponent
                RemarksModel={PTWList}
              // UsedSpareModel={UsedSpare}
              // RemarksModel={RemarksModel}
              />
            }
            ModelTitle={"Preious Day PTW values"}
            modelSize={"modal-lg"}
            CloseID={"ClosePTWValues"}
            Id={"PTWValues"}
          ></BootrapModel>
        </div>
      </div>
    </>
  );
}
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
const formatDateDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const RemarksComponent = ({ RemarksModel = [] }) => (
  <div>
    {Array.isArray(RemarksModel) && RemarksModel.length > 0 ? (

      <div className="row">
        {RemarksModel.map((remark, index) => (
          <div className="col-md-5" key={index}>
            <div className="card mb-5">
              <div className="card-body">
                {/* Display ClosedDate in "dd-MM-yyyy" format */}
                <h5 className="card-title" style={{ color: 'blue' }}>
                  {formatDate(remark.ClosedDate)}
                </h5>
                <strong>PTWDate : </strong> {formatDateDDMMYYYY(remark.PTWDate)}

                {/* Display other key-value pairs */}
                {Object.entries(remark).map(([key, value]) => (
                  key !== 'ClosedDate' && key !== 'PTWDate' && (
                    <div key={key}>
                      <strong>{key} : </strong> {value || "N/A"}
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="col-md-12">
        <p>No remarks available.</p>
      </div>
    )}
  </div>

);


const RemarksModel = ({ rowList = {} }) => {
  const { showLoading, hideLoading } = useContext(AppContext);
  const { RemarksData, IsOverAllRemarks } = rowList;

  const Columns = [
    {
      title: `Equipment Detail ID`,
      data: "EquipmentDetailID",
      className: "hide",
    },
    {
      title: `Equipment Tag Number`,
      data: "EquipmentCode",
    },
    {
      title: `Resistance`,
      data: "Resistance",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="number" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`Resistance`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `Power`,
      data: "Power",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`Power`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `Wye`,
      data: "Wye",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`Wye`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `R-Y`,
      data: "VRY",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`VRY`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `Y-B`,
      data: "VYB",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`VYB`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `B-R`,
      data: "VBR",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:80px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`VBR`] = value;
        });
        $(td).html(inputElement);
      },
    },

    {
      title: `Engineer Remarks`,
      data: "RemarksForEngg",
      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;
        let inputElement = "";
        let IsEnable =
          getUserDetails().RoleID !== COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:150px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`RemarksForEngg`] = value;
        });
        $(td).html(inputElement);
      },
    },
    {
      title: `Technician Remarks`,
      data: "RemarksForTech",

      createdCell: function (td, cellData, rowData, row, col) {
        const { EquipmentDetailID } = rowData;

        let div = $("<div>");
        let prependText = $(
          `<span style="align-items: center;display: flex" >${rowData.PreRemarks}</span>`
        );
        let inputElement = "";
        if (cellData?.length == 0) {
          cellData += rowData.PreRemarks;
        }
        let IsEnable =
          getUserDetails().RoleID == COOKIES.TechRoleID ? "" : "disabled";

        inputElement = $(
          `<textarea type="text" class="form-control_copy" ${IsEnable} style="width:150px;resize:none" >${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          const { value } = e.target;
          rowData[`RemarksForTech`] = value;
        });
        div.append(prependText);
        div.append(inputElement);
        $(td).html(div);
      },
    },
    {
      title: "Upload",
      data: "MaintenanceScheduleID",
      width: "40px",
      className: IsOverAllRemarks ? "hide" : "",
      visible: !IsOverAllRemarks,

      createdCell: function (td, cellData, rowData, row, col) {
        let normalDiv = $('<div class="file-upload-div "> </div>');
        let UploadIcon = $(
          '<i class="fa fa-upload col-6" aria-hidden="true"></i>'
        ).on("click", function (e) {
          var parentElement = $(e.target).closest(".file-upload-div");
          parentElement.find(".file-input").click();
        });
        var inputElement = $(`
      <input type="file" class="form-control hide file-input" multiple value="${cellData}" />`).on(
          "change",
          async function (e) {
            e.preventDefault();

            const formData = new FormData();
            $.each(e.target.files, function (i, file) {
              formData.append("files", file);
            });
            if (e.target?.files.length > 0) {
              let returnValue = await fileUpload(formData);
              if (returnValue) {
                rowData.Upload = returnValue;
              }
            } else {
              rowData.Upload = "";
            }
          }
        );
        normalDiv.append(UploadIcon);
        normalDiv.append(inputElement);
        $(td).html(normalDiv);
      },
    },
  ];

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  function SubmitButton() {
    return $(
      `<button class="btn btn-primary m-l-15"
      >  Close</button>  `
    ).on("click", function (e) {
      e.preventDefault();
      SaveRemarks();
    });
  }

  const SaveRemarks = () => {
    let tableData = $("#PeriodicRemarksTable")
      .DataTable()
      .rows()
      .data()
      .toArray();
    let PeriodicUGStationData = $("#PeriodicUGStation")
      .DataTable()
      .rows()
      .data()
      .toArray();

    if (IsOverAllRemarks) {
      PeriodicUGStationData.map((element) => {
        tableData.map((remarksData) => {
          const { EquipmentDetailID } = remarksData;

          element[`OverAllRemarksForEngg_${EquipmentDetailID}`] =
            remarksData[`RemarksForEngg`];
          element[`OverAllRemarksForTech_${EquipmentDetailID}`] =
            remarksData[`RemarksForTech`];
          element[`Resistance_${EquipmentDetailID}`] =
            remarksData[`Resistance`];
          element[`Power_${EquipmentDetailID}`] =
            remarksData[`Power`];
          element[`Wye_${EquipmentDetailID}`] =
            remarksData[`Wye`];
          element[`VRY_${EquipmentDetailID}`] =
            remarksData[`VRY`];
          element[`VYB_${EquipmentDetailID}`] =
            remarksData[`VYB`];
          element[`VBR_${EquipmentDetailID}`] =
            remarksData[`VBR`];
        });
      });
    } else {
      let MaintenanceScheduleID = tableData?.[0].MaintenanceScheduleID ?? 0;
      let jobDetails = PeriodicUGStationData?.find(
        (ele) => ele.MaintenanceScheduleID == MaintenanceScheduleID
      );

      tableData.map((element) => {
        const { EquipmentDetailID } = element;
        jobDetails[`RemarksForEngg_${EquipmentDetailID}`] =
          element[`RemarksForEngg`];
        jobDetails[`RemarksForTech_${EquipmentDetailID}`] =
          element[`RemarksForTech`];
        jobDetails[`Resistance_${EquipmentDetailID}`] =
          element[`Resistance`];
        jobDetails[`Power_${EquipmentDetailID}`] =
          element[`Power`];
        jobDetails[`VBR_${EquipmentDetailID}`] =
          element[`VBR`];
        jobDetails[`VYB_${EquipmentDetailID}`] =
          element[`VYB`];
        jobDetails[`VRY_${EquipmentDetailID}`] =
          element[`VRY`];
        jobDetails[`Wye_${EquipmentDetailID}`] =
          element[`Wye`];
        jobDetails[`Upload_${EquipmentDetailID}`] = element[`Upload`];
      });
    }
    $("#ClosePeriodicRemarksModel").click();
  };

  return (
    <>
      <HtmlTable
        id="PeriodicRemarksTable"
        columnList={Columns}
        rowList={RemarksData}
        SearchPostponedHtml={SubmitButton}
        IsASC
      ></HtmlTable>
    </>
  );
};


export default UG19StationPMPage;
