import Axios from "../../../services/AxiosService";

const axios = new Axios();
const ApproveConfig_GetDropDown = async () => {
    return await axios.get(
        `ApplicationConfig/ApproveConfig_GetDropDown`
    );
};
const ApproveConfig_GetDescription = async () => {
    return await axios.get(
        `ApplicationConfig/ApproveConfig_GetDescription`
    );
};
const ApproveConfig_AddOrUpdate = async (data) => {
    return await axios.post(
        "ApplicationConfig/ApproveConfig_AddOrUpdate",
        data
    );
};
const ApproveConfig_DeleteDescription = async (ConfigurationWorkFlowID) => {
    return await axios.post(
        `ApplicationConfig/ApproveConfig_DeleteDescription?ConfigurationWorkFlowID=${ConfigurationWorkFlowID}`
    );
};
export {
    ApproveConfig_GetDropDown,
    ApproveConfig_GetDescription,
    ApproveConfig_AddOrUpdate,
    ApproveConfig_DeleteDescription,
}