import React from "react";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { Decrypt } from "../../services/CommonService";
import Global from "../../services/Global";

export const DataTableWithAjax = ({
  columnList,
  rowList,
  id,
  isAsc,
  url,
  pageLength = 10,
  SearchPostponedHtml = null,
  SearchPrependHtml = null,
  ShowLengthPostponedHtml = null,
  fixedColumns = [],
  tableRefresh = "",
  dataManipulation = null,
  stateSave = true,
  addedPlugin = function () {
    return false;
  },
}) => {
  $.DataTable = require("datatables.net");

  const tableRef = useRef();
  const tableName = id;
  const [isTable, setIsTable] = useState(true);
  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable, url, tableRefresh,JSON.stringify(columnList)]);

  const setDataTable = () => {

    var tableElement = $(`#${tableName}`);

    if ($.fn.DataTable.isDataTable(`#${tableName}`)) {
      tableElement.DataTable().off("draw.dt");
      tableElement.DataTable().destroy();
    }
    $(`#${tableName} thead`).empty();
    $(`#${tableName} tbody`).empty();


    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      stateSave: stateSave,
      columns: columnList,
      destroy: true,
      autoWidth: true,
      order: [isAsc ? [0, "asc"] : [0, "desc"]],
      // columnDefs: [
      //   {
      //     targets: [0], // your case first column
      //     width: "30px",
      //   },
      // ],
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"],
      ],

      dom: 'B<"clear">lfrtip',
      fixedColumns: fixedColumns,
      scrollY: rowList != null && rowList.length > 5 ? "400" : true,
      deferRender: true,
      searching: true,
      paging: true,
      scrollX: true,
      autoWidth: true,
      pageLength: pageLength,
      
      processing: true,
      serverSide: true,
      buttons: [],
      ajax: {
        url: url,

        type: "get",
        headers: {
          Authorization:
            "Bearer " + Decrypt(localStorage.getItem(Global.COOKIES.Jwt)),
        },
        datatype: "json",
        data: function(data) {
          delete data.columns;
      },
        dataSrc: function (json) {
          if (dataManipulation != null) {
            json = dataManipulation(json);
          }
          return json.data; // return the modified data array
        },

        error: function (xhr, status, error) {
          console.error(xhr, status, error);
        },
      },
    });

    if (SearchPostponedHtml) {
      $(`#${tableName}_filter`).append(SearchPostponedHtml());
    }
    if (SearchPrependHtml) {
      $(`#${tableName}_filter`).append(SearchPrependHtml());
    }
    if (ShowLengthPostponedHtml) {
      $(`#${tableName}_length`).append(ShowLengthPostponedHtml());
    }
    addedPlugin(table);

    return function () {
      table.destroy();
    };
  };

  return (
    <>
      <div className="table-responsive" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-md-12">
            {isTable ? (
              <table
                className="table table-striped no-footer display m-b-1 Reportdatatable "
                id={tableName}
                ref={tableRef}
                width="100%"
              ></table>
            ) : (
              /* No of coulms and no of rows must be equal*/
              <div className="row col-12 text-center mt-2 mb-2">
                <p className="text-muted">Table not found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
