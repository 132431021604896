import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "../../components/ToastMsg";
import { DepTypeConfig_Getlist } from "./MasterDetailService";
import { Controller, useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import "./master.css";
import $, { data } from "jquery";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Global from "./../../services/Global";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";

import { Dropdown } from "../../components";

const _masterDetailService = new MasterDetailService();

export const DepartmentTypeConfig = ({ TabID, TabValue }) => {
  const [DeparttypeModel, setDeparttypeModel] = useState([]);

  const [systemList, setsystemList] = useState([]);
  const [isAuto, setisAuto] = useState(false);
  const { currentMasterTab, getCookies } = useContext(AppContext);
  const [options, setOptions] = useState([]);

  const [systemType, setSystemType] = useState([]);
  const [error, setErrors] = useState(false);
  const [formErrors, setformErrors] = useState([]);

  const columnNames = [
    { title: "Department Name", data: "DepartmentID", visible: false },
    { title: "DepartmentName", data: "ConcatenatedDepartments" },
    { title: "Type", data: "StatusName" },

    // {
    //   title: "Submit",
    //   visible:
    //     getPrivilege().includes("MASTEREDIT") ||
    //     getPrivilege().includes("MASTERDELETE"),

    //   createdCell: (td, cellData, rowData, row, col) => {
    //     var b = "";
    //     var c = "";
    //     if (getPrivilege().includes("MASTEREDIT")) {
    //       b = $(`<button class='btn icon-btn' title='Edit'>
    //         <i class='fa fa-pencil m-r-5'></i>
    //       </button>`).on("click", function () {
    //         EditEquipment(Number(rowData[0]));

    //         window.scroll(0, 0);
    //       });
    //     }

    //     if (getPrivilege().includes("MASTERDELETE")) {
    //       c = $(`<button class='btn icon-btn'  title='delete'  >
    //         <i class='fa fa-trash m-l-15'></i>
    //       </button>`).on("click", function () {
    //         onDeleteClick(Number(rowData[0]));
    //       });
    //     }

    //     $(td).html(b);
    //     $(td).append(c);
    //   },
    // },
  ];

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;

    setDeparttypeModel((PreviousState) => ({
      ...PreviousState,
      [name]: value,
    }));
  };

  const [Refresh, setRefresh] = useState(0);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the equipment?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _masterDetailService
          .DeleteEquipment(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              setRefresh(Refresh + 1);
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            setRefresh(Refresh + 1);
            console.log(err);
          });
      }
    });
  };

  const onSubmit = (data) => {
    data.preventDefault();


    let ModifiedBy = GetLoginUserID();
    var myerror = formErrors;
    var flag = true;
    if (
      DeparttypeModel.DepartmentID == "" ||
      DeparttypeModel.DepartmentID == null ||
      DeparttypeModel.DepartmentID == 0 ||
      DeparttypeModel.DepartmentID == undefined
    ) {
      myerror.DepartmentID = "Department is Required";
      flag = false;
    } else {
      myerror.DepartmentID = "";
    }
    if (
      DeparttypeModel.StatusTypeID == "" ||
      DeparttypeModel.StatusTypeID == null ||
      DeparttypeModel.StatusTypeID == 0 ||
      DeparttypeModel.StatusTypeID == undefined
    ) {
      myerror.StatusTypeID = "Department Type is Required";
      flag = false;
    } else {
      myerror.StatusTypeID = "";
    }
    setformErrors({ ...myerror });

    if (flag) {
      _masterDetailService
        .DepartmentType_Update(
          DeparttypeModel.DepartmentID,
          DeparttypeModel.StatusTypeID,
          ModifiedBy
        )

        .then((response) => {
          reset();
          showSaveSuccess();
          var count = Refresh;
          setRefresh(++count);
          getData();
        })
        .catch((error) => {
          showSaveError();
        });
    }
  };

  //functions
  useEffect(() => {
    // get parameter list

    if (TabID == TabValue) {
      console.log(TabID + " " + TabValue + " Equipment");
      getData();
      reset();
      setisAuto(false);
      setErrors(false);
    }
  }, [currentMasterTab]);

  const getData = () => {
    DepTypeConfig_Getlist()
      .then((res) => {


        const dataMappings = [
          {
            dataKey: "departmentConfigurationList",
            stateKey: "DepartmentConfigurationList",
          },
          { dataKey: "departmentList", stateKey: "DepartmentList" },
          { dataKey: "statusTypeList", stateKey: "StatusTypeList" },
        ];

        if (res != null) {
          dataMappings.forEach((mapping) => {
            const { dataKey, stateKey } = mapping;
            const data = res.data.data[dataKey];
            if (data != null && data.length > 0) {
              setDeparttypeModel((prevState) => ({
                ...prevState,
                [stateKey]: data,
              }));
            }
          });
        } else {
          // Handle the case when res is null
        }
      })
      .catch((error) => { });

    _masterDetailService
      .GetSystemType()
      .then((response) => {
        // console.log(response.data);

        var systemType = [];
        response.data.map((v, i) => {
          systemType.push(v.systemTypeName);
        });
        setOptions(systemType);

        setsystemList(response.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (TabID == TabValue && Refresh != 0) {
      getData();
    }
  }, [Refresh]);

  const Validate = (data) => {
    //;
    var validate = systemType[0];
    if (validate == "" || validate == null) {
      setErrors(true);
      return false;
    } else {
      setErrors(false);

      onSubmit(data);
    }
  };

  return (
    <div>
      {/* {getPrivilege().includes("MASTERCREATE") && ( */}
      <form id="EquipmentMaster" onSubmit={handleSubmit(Validate)}>
        <div className="row">
          <div className="col-3">
            <div className="form-group mb-3">
              <Controller
                control={control}
                as={Typeahead}
                name="systemTypeName"
                render={({ field, fieldState }) => (
                  <div className="mb-3">
                    <label className="label" htmlFor="systemTypeName">
                      Department <span className="text-danger">*</span>
                    </label>
                    <Dropdown
                      data={DeparttypeModel.DepartmentConfigurationList}
                      value={DeparttypeModel.DepartmentID}
                      hideSelectedOptions={false}
                      HandleChange={CommonHandleChange}
                      name={"DepartmentID"}
                    />
                    <span className="text-danger mt-1">
                      {" "}
                      {formErrors.DepartmentID}{" "}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipmentName">
                Department Type <span className="text-danger">*</span>
              </label>

              <Dropdown
                data={DeparttypeModel.StatusTypeList}
                value={DeparttypeModel.StatusTypeID}
                hideSelectedOptions={false}
                HandleChange={CommonHandleChange}
                name={"StatusTypeID"}
              />
              <span className="text-danger">{formErrors.StatusTypeID}</span>
            </div>
          </div>

          {/* {getPrivilege().includes("MASTERCREATE") && ( */}
          <div className="col-2 ">
            <button
              className="btn btn-primary mt-4 pull-left"
              onClick={onSubmit}
            >
              Save
            </button>
          </div>
          {/* )} */}
        </div>
      </form>
      {/* )}  */}

      <HtmlTable
        columnList={columnNames}
        rowList={DeparttypeModel.DepartmentList}
        id={"EquipmentTable"}
      />
    </div>
  );
};
