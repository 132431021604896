import React from "react";
import { createContext, useState, useContext } from "react";

export const CyclicCheckContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => {},
  DropDownInitialState: {},
});

function CyclicCheckProvider({ children }) {
  const DropDownInitialState = {
    DepartmentList: [],
    YearList: [],
  };

  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
  };

  return (
    <CyclicCheckContext.Provider value={contextValue}>
      {children}
    </CyclicCheckContext.Provider>
  );
}

const GetCyclicContextValues = () => useContext(CyclicCheckContext);

export { CyclicCheckProvider,GetCyclicContextValues };
