import React from "react";
import { ContextValues } from "./PeriodicContextProvider";
import { useNavigate } from "react-router";
import { addDays, getYMDdate } from "../../services/CommonService";

function CommonWeekCalender({ weekLst = [] }) {
  const { DropdownModel, setPeriodicModel } = ContextValues();
  const navigate = useNavigate();
  const week1 = weekLst?.[0] ?? [];
  const navigateTo = (i, date, WeekDate) => {
    const Week = {
      1: 1,
      2: 8,
      3: 15,
      4: 22,
      5: 22,
    };

    const day = Week[i];
    let desiredDate = "";
    if (WeekDate) desiredDate = new Date(WeekDate);
    else desiredDate = new Date(date);

    desiredDate.setDate(day);

    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(desiredDate),
      ToDate: getYMDdate(addDays(new Date(desiredDate), 7)),
      CurrentWeek: i,
    }));
    // this flag for no need for refresh
    localStorage.setItem("NNR", 1);
    navigate("/Department-DailyCalender", {
      state: { week: i, date: getYMDdate(date) },
    });
  };
  return (
    <>
      {weekLst.map((v, i) => {
        let Color = "";
        let JobIsThere = "";
        let WeekID = i + 1;
        const { PendingDateList } = DropdownModel;
        if (PendingDateList?.length) {
          let FinedVal = PendingDateList.find((ele) => ele.WeekID == i + 37);
          if (FinedVal) {
            Color = FinedVal.Color;
            // JobIsThere = (
            //  // <i class="fa fa-dot-circle-o text-primary" title="Job found."></i>
            // );
          }
        }


        return (
          <>
            {v?.length ? (
              <div key={i} className={"row calender"}>
                <div
                  style={{ backgroundColor: Color }}
                  className={"col card CalenderCard_Week"}
                >
                  {" "}
                  <p>
                    {" "}
                    {`Week - ${WeekID}  `} {JobIsThere}{" "}
                  </p>{" "}
                </div>
                {v?.map((c) => {
                  let date = "";
                  if (WeekID == 5) {
                    date = c;
                    let defaultDate = week1[0];
                    defaultDate.setDate(c);
                    c = defaultDate;
                  } else {
                    date = c.getDate();
                  }
                  return (
                    <div
                      key={c}
                      style={{ backgroundColor: Color }}
                      className={"col card CalenderCard_Week_Date "}
                      onClick={() => navigateTo(i + 1, c.toDateString())}
                    >
                      {WeekID == 5 ? date : date < 10 ? "0" + date : date}
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </>
        );
      })}
    </>
  );
}

export default CommonWeekCalender;
