import React, { useEffect, useState } from 'react'
import '../assets/css/rca-image-report.css'

const RCAImagePopup = ({ ModelTitle,model, popUpImg, handleChange, openClose }) => {


  return (
    <div className="main_container_popup">
      <div className='container_popup'>
        <div className="">
          <div className="popup_title">
            <h5 className="title">{ModelTitle}</h5>

            {/* <button
              type="button"
              className=""
              onClick={handleChange}
            >
              <span >&times;</span>
            </button> */}

            <button
                  type="button"
                  className="btn btn-rounded btn-sm btn-light"
                  onClick={handleChange}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
          </div>
          <div className="modal-body h-auto">
            {model}

          </div>
        </div>
      </div>
    </div>
  )
}

export default RCAImagePopup