import { HtmlTable } from "../../components/HtmlTable";
import {
  showDeleteError,
  showDeleteSuccess,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
} from "../../components/ToastMsg";
import { useForm, Controller } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton, IconButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import $ from "jquery";
import { showWarningMsg } from "./../../components/ToastMsg";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";

const _masterDetailService = new MasterDetailService();

//Add form
const AddForm = ({ onSubmit, editData, currentMasterTab }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editData != null) {
      setValue("spareID", editData.SpareID, { shouldValidate: true });
      setValue("spareName", editData.SpareName, { shouldValidate: true });
    }
  }, [editData]);

  useEffect(() => {
    reset();
  }, [currentMasterTab]);

  const onsubmit = (data) => onSubmit(data, reset);

  return (
    <div className="mb-2">
      <form onSubmit={handleSubmit(onsubmit)}>
        <div className="row d-flex justify-content-end">
          {/* Spare ID*/}

          <input
            type="text"
            className="form-control"
            name="spareID"
            hidden
            defaultValue={0}
            {...register("spareID", { required: false })}
          />
          {/* Spare  Name*/}
          <div className="col-3">
            <label className="label" htmlFor="spareName">
              Spare Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Spare Name"
              name="spareName"
              // defaultValue={data.SpareName}
              {...register("spareName", {
                required: "Spare name is required",
                validate: (value) =>
                  !!value.trim() || "Spare name cannot be empty",
              })}
            />
            {errors.spareName && (
              <p className="text-danger mt-1">{errors.spareName.message}</p>
            )}
          </div>

          <div className="col-2 mt-4">
            <AddButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export const Spare = ({ TabID, TabValue }) => {
  //variables
  const { showLoading, hideLoading } = useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const { currentMasterTab } = useContext(AppContext);

  //Spare table columns
  const columnNames = [
    { title: "Spare ID", className: "hide" },
    { title: "Spare Name" },

    { title: "Status", visible: false },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        if (getPrivilege().includes("MASTEREDIT")) {
          b = $(`<button class='btn icon-btn' title='Edit'>
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
            onEditClick(rowData[0]);
            window.scroll(0,0);

          });
        }

        if (getPrivilege().includes("MASTERDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
          <i class='fa fa-trash m-l-15'></i>
        </button>`).on("click", function () {
            onDeleteClick(rowData[0]);
          });
        }

        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  useEffect(() => {
    if (TabID == TabValue) getData(0, false);
  }, [currentMasterTab]);

  //functions
  const onSubmit = (data, reset) => {
    var checkAlreadyExists = [];

    if (Number(data.spareID) == 0) {
      checkAlreadyExists = tableRows.filter(
        (v) => v[1].toLowerCase().trim() == data.spareName.toLowerCase().trim()
      );
    } else {
      checkAlreadyExists = tableRows.filter(
        (v) =>
          v[1].toLowerCase().trim() == data.spareName.toLowerCase().trim() &&
          Number(v[0]) != Number(data.spareID)
      );
    }

    if (checkAlreadyExists == [] || checkAlreadyExists.length == 0) {
      showLoading();
      var data = {
        SpareID: Number(data.spareID),
        SpareName: data.spareName,

        IsActive: true,
        SavedBy: GetLoginUserID(),
      };

      _masterDetailService.AddSpare(data).then((response) => {
        hideLoading();
        if (response == null || response == 0) {
          showSaveError();
        } else if (!response.isSuccess) {
          showSaveError();
        } else {
          reset();
          getData(0, false);
          showSaveSuccess();
        }
      });
    } else {
      showWarningMsg("Spare name already exists");
    }
  };

  const onEditClick = (id) => {
    _masterDetailService.GetSpare(id, false).then((response) => {
      if (
        response != null &&
        response.isSuccess &&
        response.data != null &&
        response.data.length > 0
      ) {
        setEditData(response.data[0]);
      } else {
        showErrorMsg("Failed to fetch record");
      }
    });
  };

  const getData = (spareID, isDelete) => {
    _masterDetailService.GetSpare(spareID, isDelete).then((response) => {
      if (
        response != null &&
        response.isSuccess &&
        response.data != null &&
        response.data.length > 0
      ) {
        var rowList = [];
        response.data.map((v, i) => {
          var row = [];
          row.push(v.SpareID);
          row.push(v.SpareName);

          row.push(v.IsActive ? "Active" : "In-Active");
          //Edit button
          row.push(IconButton(0, onEditClick));
          rowList.push(row);
        });
        setTableRows(rowList);
      } else {
        setTableRows([]);
      }
    });
  };

  const onDeleteClick = (id) => {
    _masterDetailService.GetSpare(id, true).then((res) => {
      if (res != null && res.isSuccess) {
        showDeleteSuccess();
        getData(0, false);
      } else {
        showDeleteError();
      }
    });
  };

  return (
    <>
      {/* {getPrivilege().includes("MASTERCREATE") && ( */}
        <AddForm
          editData={editData}
          onSubmit={onSubmit}
          currentMasterTab={currentMasterTab}
        />
      {/* )} */}

      <HtmlTable
        columnList={columnNames}
        rowList={tableRows}
        id={"SpareTable"}
      />
    </>
  );
};
