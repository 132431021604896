import { Decrypt, Encrypt } from "./CommonService";

const JWT = "JWT";

export default class Session {
  //save Jwt token in session
  saveJwtToken(token) {
    var encryptedToken = Encrypt(token);
    localStorage.setItem(JWT, encryptedToken);
  }

  //get Jwt token from session
  getJwtToken() {
    var token = localStorage.getItem(JWT);
    var decryptedToken = "";

    if (token != null) {
      decryptedToken = Decrypt(token);
    }
    return decryptedToken;
  }
}
