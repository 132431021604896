
import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-big-calendar'
import { YearlyMOnthForPM } from '../../services/CommonService';
import { PeriodicWeekData } from './PeriodicWeekData';
import { ContextValues } from './PeriodicContextProvider';
import CommonWeekCalender from './CommonWeekCalender';


export default function CalenderYearlyView({
    date,
    localizer,
    max = localizer.endOf(new Date(), 'day'),
    min = localizer.startOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day')}) {


    const { MonthConfigObj, PeriodicModel } = ContextValues();

    const HalfYearlyMonths = MonthConfigObj.filter((ele) => "93" == ele.ScheduleType);


    const MonthList = HalfYearlyMonths?.map((ele) => ele.Month);
  


    // useEffect(() => {
    //     var curMonth = date.getMonth() + 1;
    //     var curYear = date.getFullYear();
    //     var finalDate;
    //     if (MonthList.includes(curMonth)) {
    //         finalDate = date;
    //     } else {
    //         while (true) {
    //             if (curMonth < 12) {
    //                 curMonth = curMonth + 1;
    //                 if (MonthList.includes(curMonth)) {
    //                     finalDate = new Date(curYear, curMonth - 1, 1);
    //                     break;
    //                 }
    //             } else if (curMonth == 12) {
    //                 curMonth = 1;
    //                 curYear = curYear + 1;
    //             }
    //         }
    //     }

    // }, [date,JSON.stringify(MonthConfigObj),PeriodicModel?.CurrentView])





    const currRange = useMemo(
        () => {
            var Mydate = YearlyMOnthForPM(date, MonthConfigObj);

            return CalenderYearlyView.range(Mydate, { localizer })
        },
        [date, localizer]
    );


    let week1 = currRange.slice(0, 7);
    let week2 = currRange.slice(7, 14);
    let week3 = currRange.slice(14, 21);
    let week4 = currRange.slice(21, 28);   
    let week5 = PeriodicWeekData(currRange[0]);
    let weekLst = [week1, week2, week3, week4,week5];

    return (
        <CommonWeekCalender weekLst={weekLst} ></CommonWeekCalender>
       );
}

CalenderYearlyView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
}

CalenderYearlyView.range = (date, { localizer }) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = localizer.add(start, new Date(date.getFullYear()+1, date.getMonth(), 0).getDate(), 'day')

    let current = start
    const range = []

    while (localizer.lte(current, end, 'day')) {
        range.push(current)
        current = localizer.add(current, 1, 'day')
    }

    return range
}

CalenderYearlyView.navigate = (date, action, { localizer }) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "year");

        case Navigate.NEXT:
            return localizer.add(date, 1, "year");

        default:
            return date;
    }
}

CalenderYearlyView.title = (date) => {

    return "Jan - Dec "+ new Date(date).getFullYear();

}

