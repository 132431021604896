import React, { useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DashboardService from "../../screens/dashboard/DashboardService";
import { DataTableReports } from "../DataTableReports";
import BootrapModel from "../BootrapModel";
import { Line, getElementsAtEvent, getElementAtEvent } from "react-chartjs-2";
import { COLORS } from "../../services/CommonService";
import zoomPlugin from "chartjs-plugin-zoom";
import $ from "jquery";
import { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const DISPLAY = false;
const BORDER = false;
const CHART_AREA = true;
const TICKS = true;

const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function (tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return "Sum: " + sum;
};




export const LineChartForCommon = ({
  id,
  data,
  yTitle,
  min,
  max,
  title,
  onClick,
}) => {
  const ref = useRef();

  return (
    <div>


      <Line
        id={id}
        data={data}
        options={{
          parsing: {
            xAxisKey: "label",
            yAxisKey: "data",
          },
          responsive: true,

          interaction: {
            intersect: false,
            mode: "nearest",
            axis: "x",
          },

          plugins: {
            zoom: {
              pan: {
                enabled: true,
                onPanStart({ chart, point }) {
                  const area = chart.chartArea;
                },
                mode: "x",
              },
            },
            legend: {
              title: {
                display: true,
                text: title,
                font: {
                  size: 14,
                  weight: "bold",
                },
              },
              display: true,
              position: "top",
            },

            tooltip: {
              mode: "nearest",
              axis: "x",
              callbacks: {
                footer: footer,
              },
            },
            animations: {
              tension: {
                duration: 1000,
                easing: "linear",
                from: 1,
                to: 0,
                loop: true,
              },
            },
          },

          scales: {
            x: {
              min: 0,
              max: 4,

              grid: {
                display: DISPLAY,
                drawBorder: BORDER,
                drawOnChartArea: CHART_AREA,
                drawTicks: TICKS,
              },
              offset: true,
            },
            y: {
              // beginAtZero: true,
              ticks: {
                precision: 0,
              },
              grid: {
                drawBorder: false,
                color: function (context) {
                  if (context.tick.value == min || context.tick.value == max) {
                    return COLORS.red;
                  }
                  return "#e6e6e6";
                },
              },
              title: {
                display: true,
                text: yTitle,
                color: "#333",
                font: {
                  size: 15,
                  weight: "600",
                  lineHeight: 1.2,
                },
                padding: { top: 0, left: 10, right: 10, bottom: 5 },
              },
            },
          },
        }}
        ref={ref}
        onClick={onClick ? onClick : null}
      />
    </div>
  );
};

