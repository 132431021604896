import { HtmlTable } from "../../../components/HtmlTable";
import { BootrapModel, PageHeaderTitle } from "../../../components";
import $ from "jquery";
import { useEffect, useState, useContext, useReducer } from "react";
import { AuditContext } from "./AuditContextProvider";
import AuditingLevelConfigCreate from "./AuditingLevelConfigCreate";
import {
  AuditingLevelConfig_GetList,
  AuditingLevelConfig_DeleteByID,
} from "./AuditingLevelServices";
import { MasterConfig_GetDepartmentList } from "../../master/MasterDetailService";
import {
  BuildTreeUsingParentID,
  BuildTreeUsingParentIDAndRemoveLastChild,
  GenerateYearForReactSelect,
} from "../../../services/CommonService";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";

import {
  showSaveSuccess,
  showSaveError,
  showErrorMsg,
} from "../../../components";
import {
  findDepartmentById,
  GetLoginUserID,
} from "../../../services/CommonService";

export default function AuditingConfigList() {
  const { setDropdownModel, DropDownInitialState } = useContext(AuditContext);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [Refresh, setRefresh] = useState(0);
  const [AuditingLevelConfigID, setAuditingLevelConfigID] = useState(0);
  const [GridModel, setGridModel] = useState([]);
  useEffect(() => {
    initGrid();
    GetDepartmentList();
    return () => {
      setDropdownModel(DropDownInitialState);
    };
  }, [Refresh, GridRender]);

  const initGrid = async () => {
    await AuditingLevelConfig_GetList().then((res) => {
      if (res != null && res.data.data.auditingLevelConfigList != null) {
        setGridModel([...res.data.data.auditingLevelConfigList]);
      } else {
        setGridModel([]);
      }
    });
  };
  const DeleteAuditiingByID = async (id) => {
    var USserID = GetLoginUserID();
    await AuditingLevelConfig_DeleteByID(id, USserID).then((res) => {
      
      if (res.data.isSuccess) {
        showSaveSuccess();
        initGrid();
      } else {
        showErrorMsg();
      }
    });
  };
  const GetDepartmentList = async () => {
    await MasterConfig_GetDepartmentList()
      .then((res) => {
        if (res?.data?.data?.length) {
          let treeJSON = BuildTreeUsingParentID(res.data.data);
          let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
          Data.forEach((ele) => (ele.isOpen = true));
          let YearList = GenerateYearForReactSelect();
          setDropdownModel((pre) => ({
            ...pre,
            DepartmentList: Data,
            YearList: YearList,
          }));
        }
      })
      .catch((err) => console.log(err));
  };
  // const GetDepartmentList = async () => {
  //   await MasterConfig_GetDepartmentList()
  //     .then((res) => {
  //       if (res?.data?.data?.length) {
  //         let treeJSON = BuildTreeUsingParentIDAndRemoveLastChild(
  //           res.data.data
  //         );

  //         let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
  //         

  //         // let DepartmentList = [
  //         //   {
  //         //     id: uuidv4(),
  //         //     // name: "Select",
  //         //     children: Data,
  //         //   },
  //         // ];

  //         Data.forEach((ele) => (ele.isOpen = true));

  //         let YearList = GenerateYearForReactSelect();
  //         setDropdownModel((pre) => ({
  //           ...pre,
  //           DepartmentList: Data,
  //           DepartmentList: Data,
  //           YearList: YearList,
  //         }));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const columnNames = [
    {
      title: "AuditingLevelConfigID",
      visible: false,
      data: "AuditingLevelConfigID",
    },

    { title: "Department Name", data: "DepartmentName" },
    // { title: "Sub Department Name", data: "SubDepartmentName" },
    { title: "Task Type", data: "TaskType" },
    { title: "Frequency in (Days)", data: "JobSendDuration" },
    { title: "Percentage of Jobs", data: "JobPercentageVisable" },
    { title: "Auditing Level", data: "RoleName" },
    {
      title: "Submit",
      data: "AuditingLevelConfigID",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        b =
          $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#AuditingLevelConfig" >
                  <i class='fa fa-pencil m-r-5'></i>
                </button>`).on("click", function () {
            HandleClickCreateBtn(rowData);
          });
        c = $(`<button class='btn icon-btn'  title='delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function () {
          onDeleteAuditiing(rowData);
        });
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  const onDeleteAuditiing = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Auditing Level-Configuration?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteAuditiingByID(id.AuditingLevelConfigID);
      }
    });
  };
  const data = [];
  const Header = ({ title }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-md-6 ">
            <h3 className="page-title">{title}</h3>
          </div>
          <div className="col-md-2 ">
            <button
              className="btn btn-primary pull-right"
              data-bs-toggle="modal"
              data-bs-target="#AuditingLevelConfig"
              onClick={HandleClickCreateBtn}
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
        </div>
      </div>
    );
  };

  const HandleClickCreateBtn = (id) => {
    setAuditingLevelConfigID(id.AuditingLevelConfigID);

    PageRender();
  };

  return (
    <div>
      <Header title={"Auditing Level-Configuration"} />

      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={GridModel}
            id={"ConfigureList"}
          />
        </div>
      </div>

      <BootrapModel
        Id={"AuditingLevelConfig"}
        CloseID="AuditingLevelConfigurationClose"
        ModelTitle={`Auditing Level-Configuration`}
        modelSize={"modal-xl"}
        module={
          <AuditingLevelConfigCreate
            AuditingLevelConfigID={AuditingLevelConfigID}
            Render={Render}
            setGridRender={setGridRender}
          />
        }
      />
    </div>
  );
}
