import React from "react";
import {
  showSuccessMsg,
  showErrorMsg,
  showSaveError,
  showWarningMsg,
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import RoleService from "./RoleService";
import $ from "jquery";

const _roleService = new RoleService();

export const AddRole = ({
  roleID,
  roleName,
  PrefixRoleName,
  handleRefresh,
  roleList,
  closepopup,
}) => {
  const [chkbox, setCheckbox] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const Refresherror = () => {
    closepopup();
    clearErrors();
  };

  const [Id, setID] = useState(roleID);
  const [RoleName, setRoleName] = useState(roleName != null ? roleName : "");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const [Refresh, setRefresh] = useState(0);
  const selectAll = watch("selectall");

  const handleRow = (index, status, value) => {
    let array = chkbox;

    const label = status;
    const newValue = value;

    if (newValue == false) {
      setValue("selectall", false);
    }

    if (
      (label == "approval" ||
        label == "create" ||
        label == "edit" ||
        label == "delete") &&
      newValue == true
    ) {
      const newarrays = [
        ...array.slice(0, index),
        {
          ...array[index],
          [label]: newValue,
          view: true,
        },
        ...array.slice(index + 1),
      ];
      return setCheckbox(newarrays);
    } else if (label == "view" && newValue == false) {
      const newaraysview = [
        ...array.slice(0, index),
        {
          ...array[index],
          [label]: newValue,
          approval: false,
          create: false,
          edit: false,
          delete: false,
        },
        ...array.slice(index + 1),
      ];
      return setCheckbox(newaraysview);
    }
    const updatedArray = [
      ...array.slice(0, index),
      {
        ...array[index],
        [label]: newValue,
      },
      ...array.slice(index + 1),
    ];
    setCheckbox(updatedArray);
  };

  const selectAllbox = () => {
    if (!selectAll) {
      {
        chkbox.map((value, index) => {
          value.approval = true;
          value.create = true;
          value.edit = true;
          value.delete = true;
          value.view = true;
        });
      }
    } else {
      {
        chkbox.map((value, index) => {
          value.approval = false;
          value.create = false;
          value.edit = false;
          value.delete = false;
          value.view = false;
        });
      }
    }
  };

  // const handleColumn = (name) => {

  //       if(name == 'approval' || name == 'create' || name == 'edit' || name == 'delete' || name == 'view'){
  //         const newPrivilege = chkbox.map((data) => ({
  //             ...data,
  //             [name]: true,
  //             view : true

  //           }));
  //           setCheckbox(newPrivilege)
  //       }

  // }

  const onSubmit = (data) => {
    var isExists = [];
    
    isExists = roleList.find(
      (v) =>
        v.roleName.toLowerCase().trim() == data.roleName.toLowerCase().trim() &&
        v.roleID != data.roleId
    );

    if (isExists == null || isExists.length == 0) {
      let PreNameExist = roleList.find(
        (v) =>
          v.prefixName.toLowerCase().trim() ==
            data.PrefixRoleName.toLowerCase().trim() && v.roleID != data.roleId
      );

      if (!PreNameExist) {
        data.modPrivilegeLst = chkbox;

        const formData = new FormData();
        Object.keys(chkbox).forEach((key) =>
          formData.append("ModPrivilegeLst", chkbox[key])
        );

        formData.append("RoleID", data.roleID);
        formData.append("RoleName", data.roleName);
        formData.append("ModPrivilegeLst", JSON.stringify(chkbox));

        const status = data.modPrivilegeLst.some((element) => {
          if (
            element.approval == true ||
            element.create == true ||
            element.edit == true ||
            element.delete == true ||
            element.view == true
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (status) {
          _roleService
            .SaveRole(data)

            .then((response) => {
              reset();
              setCheckbox([]);
              showSuccessMsg("Role saved successfully");
              handleRefresh();
              $("#popupclose").trigger("click");
            })
            .catch((error) => {
              if (error.response.data == "Role already exists") {
                showWarningMsg(error.response.data);
              } else {
                showSaveError();
              }
            });
        } else {
          showWarningMsg("Privilege cannot be empty");
        }
      } else {
        showWarningMsg("Prefix name already exists.");
      }
    } else {
      showWarningMsg("Role already exists");
    }
  };

  useEffect(() => {
    setValue("roleId", roleID);
    setValue("roleName", roleName);
    setValue("PrefixRoleName", PrefixRoleName);
    setID(roleID);
    setCheckbox([]);
    setValue("selectall", true);
    _roleService
      .GetRoleData(roleID)
      .then((response) => {
        {
          response.data.modPrivilegeLst.some((element) => {
            if (
              element.approval == false ||
              element.create == false ||
              element.edit == false ||
              element.delete == false ||
              element.view == false
            ) {
              setValue("selectall", false);
            }
          });
        }
        var modules = response.data.modPrivilegeLst;
        if (modules != null) {
          setCheckbox(modules);
        } else {
          setCheckbox([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [roleID]);

  return (
    <>
      <div className="mb-2">
        <div
          id="addrole"
          className="modal custom-modal fade"
          data-bs-backdrop="static"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Role</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => Refresherror()}
                  data-bs-dismiss="modal"
                  id="popupclose"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form id="RoleAdd" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row justify-content-end">
                    <div className={"col-md-3 col-lg-4"}>
                      <div className="form-group mb-3">
                        <label className="label" htmlFor="roleName">
                        User Level <span className="text-danger">*</span>
                        </label>
                        <input type="hidden" value={register.roleID} />

                        <input
                          type="text"
                          name="PrefixRoleName"
                          className="form-control"
                          // placeholder="Role Name"
                          maxLength="50"
                          id="PrefixRoleName"
                          {...register("PrefixRoleName", {
                            required: "Pre Role Name is required Ex:L0,L1",
                            validate: (value) => {
                              return (
                                !!value.trim() ||
                                "Pre Role Name cannot be empty"
                              );
                            },
                          })}
                          value={register.PrefixRoleName}
                        />

                        {errors.roleName && (
                          <span className="text-danger">
                            {errors?.PrefixRoleName?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={"col-md-3 col-lg-4"}>
                      <div className="form-group mb-3">
                        <label className="label" htmlFor="roleName">
                          Role Name <span className="text-danger">*</span>
                        </label>
                        <input type="hidden" value={register.roleID} />

                        <input
                          type="text"
                          name="roleName"
                          className="form-control"
                          // placeholder="Role Name"
                          maxLength="50"
                          id="roleName"
                          {...register("roleName", {
                            required: "Role Name is required",
                            validate: (value) => {
                              return (
                                !!value.trim() || "Role name cannot be empty"
                              );
                            },
                          })}
                          value={register.roleName}
                        />

                        {errors.roleName && (
                          <span className="text-danger">
                            {errors.roleName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className={"col-md-3 mt-3 col-lg-4"}>
                      <button type="submit" className="btn add-btn">
                        <i className="fa fa-save"></i> Save
                      </button>
                      {/* <AddButton /> */}
                    </div>

                    <div className="row">
                      <div style={{ textAlign: "end" }}>
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          onClick={() => selectAllbox()}
                          {...register("selectall")}
                        />
                        <label htmlFor="selectall">
                          <span className="p-1">Select All</span>
                        </label>
                      </div>
                    </div>

                    <div className="table-responsive m-t-15">
                      <table className="table table-striped custom-table">
                        <thead>
                          <tr>
                            <th>Module Permission</th>
                            <th
                              className="text-center" /*onClick={()=>handleColumn('approval')}*/
                            >
                              Approval
                            </th>
                            <th
                              className="text-center" /*onClick={()=>handleColumn('create')}*/
                            >
                              Create
                            </th>
                            <th
                              className="text-center" /*onClick={()=>handleColumn('edit')}*/
                            >
                              Edit
                            </th>
                            <th
                              className="text-center" /*onClick={()=>handleColumn('delete')}*/
                            >
                              Delete
                            </th>
                            <th
                              className="text-center" /*onClick={()=>handleColumn('view')}*/
                            >
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {chkbox.map((item, index) => (
                            <tr key={index}>
                              <td>{item.moduleName}</td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRow(
                                      index,
                                      "approval",
                                      e.target.checked
                                    )
                                  }
                                  checked={item.approval}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRow(index, "create", e.target.checked)
                                  }
                                  checked={item.create}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRow(index, "edit", e.target.checked)
                                  }
                                  checked={item.edit}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRow(index, "delete", e.target.checked)
                                  }
                                  checked={item.delete}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRow(index, "view", e.target.checked)
                                  }
                                  checked={item.view}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
