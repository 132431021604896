import React from "react";
export const AddButton = ({ handleClick }) => {
  return (
    <button type="submit" className="btn add-btn" onClick={handleClick}>
      <i className="fa fa-plus"></i> Add
    </button>
  );
};

export const IconButton = (type, callback) => {
  switch (type) {
    //Edit
    case 0:
      return `        <button class=" btn icon-btn" title="Edit">
        <i class="fa fa-pencil m-r-5"></i>
      </button>`;

    //Delete
    case 1:
      return `<button class="icon-btn" title="Delete">
      <i class="fa fa-pencil m-r-5"></i>
    </button>`;

    //both Edit and Delete
    case 2:
      return "<p></p>";

    default:
      return "<p></p>";
  }
};
