import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import PageHeaderTitle from "./PageHeaderTitle";
import moment from "moment";
import { logo } from "../screens/Image";
import { AppContext } from "../services/ContextProvider";
import { COOKIES } from "../services/Global";
import { getYMDdate } from "../services/CommonService";


export const CheckListHtmlTable = ({
  columnList,
  rowList,
  id,
  HeadingTitle,
  count,
  FixedColums,
  orderby,
  SearchPrependHtml = null,
  SearchPostponedHtml = null,
  ShowLengthPostponedHtml = null,
  IsExcelNeed = false,
  exportOptions,
  IsASC = false,
  IsShowPDF = false,
  title = "",
  landscape = false,
  StationName = "",
  fromDate = "",
  EquipmentName = "",
  pdfHeading = "",
  PDfCustomization = () => { },
  DocumentNo,
  CurrentDate = getYMDdate(),
  CustomeHeader,
  pageSize = "LEGAL",
  AdditionalPlugin = () => { },
}) => {
  $.DataTable = require("datatables.net");
  const { removeCookies, getCookies, cookies } = useContext(AppContext);

  const tableRef = useRef();
  const tableName = id;

  const [isTable, setIsTable] = useState(true);

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
    // $(".sorting").trigger("click");
  }, [rowList, isTable, JSON.stringify(columnList)]);

  const setDataTable = () => {
    var tableElement = $(`#${tableName}`);

    if ($.fn.DataTable.isDataTable(`#${tableName}`)) {
      tableElement.DataTable().destroy();
    }
    $(`#${tableName} thead`).empty();
    $(`#${tableName} tbody`).empty();

    // let startTime = performance.now();
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      // stateSave:true,
      deferRender: true,
      keys: true,
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      // scrollX: true,
      autoWidth: true,
      scrollY: rowList != null && rowList.length > 6 ? "400" : true,
      scrollX: true,
      scrollCollapse: false,
      responsive: true,
      // paging:         false,
      fixedHeader: true,

      // fixedColumns: true,
      action: exportaction,

      fixedColumns: FixedColums,
      columnDefs: [{ orderable: false, targets: [0] }],
      headerCallback: CustomeHeader ?? function () { },

      order: [[orderby ? orderby : 0, IsASC ? "asc" : "desc"]],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      dom: 'B<"clear">lfrtip',
      buttons: [
        {
          extend: "excel",
          title: "Spare Details",
          className: `btn btn-secondary datatablebtn rounded-3 datatablepdf-button  mt-0 ${IsExcelNeed ? "" : "hide"
            } `,
          text: '<i style ="margin-top:4px;font-size:25px" class="las la-file-excel" title="Excel download"></i>',
          filename: "Spare Details",

          style: "position : absolute; margin-left : 20px",
          // action: exportaction,
          exportOptions: exportOptions ?? [],

          customize: function (xlsx) {
            var sheet = xlsx.xl.worksheets["sheet1.xml"];

            // Set style for second row
            var veryFirstRow = $("row:eq(0)", sheet);

            var firstRow = $("row:eq(1)", sheet);
            // var secondRow = $("row:eq(2)", sheet);

            $("c", firstRow).attr("s", "22");
            // $("c", secondRow).attr("s", "22");
          },
        },
        {
          extend: "pdf",
          className: `btn btn-primary datatablebtn rounded-3 datatablepdf-button ${IsShowPDF ? "" : "hide"
            }`,
          header: true,
          text: '<i  style ="margin-top:4px;font-size:25px" title="Pdf download" class="lar la-file-pdf"></i>',
          filename: title,
          title: title,
          orientation: landscape ? "landscape" : "portrait",
          // pageSize: pageSize,

          // pageSize: "A2",

          // action: exportaction,
          exportOptions: exportOptions ?? [],
          customize: function (doc) {
            doc.pageMargins = [20, 120, 20, 40];
            doc.defaultStyle.fontSize = 10;
            doc.defaultStyle.bold = false;
            doc.defaultStyle.fontWeight = "normal";
            doc.defaultStyle.color = "#272a2e";

            doc.styles.tableHeader.fontSize = 7;
            doc.styles.title.fontSize = 9;
            doc.content[0] = "";
            //Done By Vineeth
            if (doc.content[1].table.body[0].length < 10) {
              doc.pageSize = pageSize;
            } else {
              doc.pageSize = "A2";
            }

            //Increase to full width

            for (var row = 0; row < doc.content[1].table.headerRows; row++) {
              var header = doc.content[1].table.body[row];
              for (var col = 0; col < header.length; col++) {
                header[col].fillColor = "#fff";
                header[col].fontSize = 11;
                header[col].color = "black";
                header[col].alignment = "center";
                // header[col].margin = [0,10,0,0];
              }
            }
            doc.content[1].table.heights = 35;

            var curDate = moment().format("DD-MM-YYYY hh:mm A");
            var username = getCookies(COOKIES.UserName);

            // Remove spaces around page title
            //doc.content[0].text = doc.content[0].text.trim();
            //Create a footer
            doc["footer"] = function (page, pages) {
              return {
                columns: [
                  {
                    // This is the right column
                    alignment: "left",
                    fontSize: 8,
                    text: [
                      `CMMS Chennai \n Downloaded By ${username} on ${curDate}`,
                    ],
                    margin: [0, 10],
                  },

                  {
                    // This is the right column
                    alignment: "right",
                    text: [
                      "Page ",
                      { text: page.toString() },
                      " of ",
                      { text: pages.toString() },
                    ],
                    margin: [0, 10],
                  },
                ],
                margin: [10, 10],
              };
            };
            // Create a header
            doc["header"] = function (page, pages) {
              return {
                stack: [
                  {
                    columns: [
                      {
                        table: {
                          widths: [200, 200, 150],
                          layout: {
                            vLineWidth: 0.5,
                            hLineWidth: 0.5,
                          },

                          body: [
                            [

                              {
                                text: `Doc no: ${DocumentNo} \n Rev: 01`,
                                alignment: "left",
                                margin: [0, 20, 0, 0],

                              },
                              {
                                text: pdfHeading ?? "",
                                // rowSpan: 2,
                                alignment: "center",
                                margin: [0, 23, 0, 0],
                              },
                              {
                                image: logo,
                                // rowSpan: 2,
                                alignment: "center",
                                width: 50,
                                margin: [0, 5, 0, 5],
                              },
                            ],
                          ],
                        },
                        margin: [18, 10, 0, 0],
                      },
                    ],
                  },

                  {
                    columns: [
                      {
                        text: `Station Name: ${StationName}`,
                        alignment: "left",
                        margin: [18, 10, 0, 0],
                      },
                      {
                        text:
                          "Date :" + moment(CurrentDate).format("DD-MM-YYYY"),
                        alignment: "right",
                        margin: [0, 10, 25, 0],
                      },
                    ],
                  },
                ],
                columnGap: 10,
              };
            };

            // Styling the table: create style object
            //Done By Vineeth
            var objLayout = {};
            // Horizontal line thickness
            objLayout["hLineWidth"] = function (i) {
              return 0.5;
            };
            // Vertikal line thickness
            objLayout["vLineWidth"] = function (i) {
              return 0.5;
            };

            // Horizontal line color
            objLayout["hLineColor"] = function (i) {
              return "#aaa";
            };
            // Vertical line color
            objLayout["vLineColor"] = function (i) {
              return "#aaa";
            };
            // Left padding of the cell
            objLayout["paddingLeft"] = function (i) {
              return 4;
            };
            // Right padding of the cell
            objLayout["paddingRight"] = function (i) {
              return 4;
            };
            // Inject the object in the document
            doc.content[1].layout = objLayout;
            PDfCustomization(doc);
          },
        },
      ],
    });
    if (SearchPrependHtml) {
      $(`#${tableName}_filter`).prepend(SearchPrependHtml());
    }

    if (SearchPostponedHtml) {
      $(`#${tableName}_filter`).append(SearchPostponedHtml());
    }

    if (ShowLengthPostponedHtml) {
      $(`#${tableName}_length`).append(ShowLengthPostponedHtml());
    }
    if (AdditionalPlugin) {
      AdditionalPlugin(table);
    }

    return function () {
      table.destroy();
    };
  };

  function exportaction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    dt.one("preXhr", function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 2147483647;
      dt.one("preDraw", function (e, settings) {
        // Call the original action function
        if (button[0].className.indexOf("buttons-copy") >= 0) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(
            self,
            e,
            dt,
            button,
            config
          );
        } else if (button[0].className.indexOf("buttons-excel") >= 0) {
          // exportTableToExcel()

          $.fn.dataTable.ext.buttons.excelHtml5.available(dt, config)
            ? $.fn.dataTable.ext.buttons.excelHtml5.action.call(
              self,
              e,
              dt,
              button,
              config
            )
            : $.fn.dataTable.ext.buttons.excelFlash.action.call(
              self,
              e,
              dt,
              button,
              config
            );
        } else if (button[0].className.indexOf("buttons-csv") >= 0) {
          $.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)
            ? $.fn.dataTable.ext.buttons.csvHtml5.action.call(
              self,
              e,
              dt,
              button,
              config
            )
            : $.fn.dataTable.ext.buttons.csvFlash.action.call(
              self,
              e,
              dt,
              button,
              config
            );
        } else if (button[0].className.indexOf("buttons-pdf") >= 0) {
          $.fn.dataTable.ext.buttons.pdfHtml5.available(dt, config)
            ? $.fn.dataTable.ext.buttons.pdfHtml5.action.call(
              self,
              e,
              dt,
              button,
              config
            )
            : $.fn.dataTable.ext.buttons.pdfFlash.action.call(
              self,
              e,
              dt,
              button,
              config
            );
        } else if (button[0].className.indexOf("buttons-print") >= 0) {
          $.fn.dataTable.ext.buttons.print.action(e, dt, button, config);
        }
        dt.one("preXhr", function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });
        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);
        // Prevent rendering of the full data to the DOM
        return false;
      });
    });
    // Requery the server with the new one-time export settings
    dt.ajax.reload();
  }

  return (
    <>
      <PageHeaderTitle title={HeadingTitle}> </PageHeaderTitle>
      <div className="table-responsive" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-sm-12">
            {isTable ? (
              <table
                className="table table-striped no-footer display"
                id={tableName}
                ref={tableRef}
                data-page-length={count ? count : "10"}
                width="100%"
              ></table>
            ) : (
              /* No of coulms and no of rows must be equal*/
              <div className="row col-12 text-center mt-2 mb-2">
                <p className="text-muted">Table not found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
