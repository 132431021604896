import React, { useEffect, useState } from "react";
import {
  PageHeaderTitle,
  ReactTreeView,
  showErrorMsg,
  showSaveSuccess,
  showWarningMsg,
} from "../../components";
import {
  MasterConfig_GetDepartmentList,
  MasterConfig_SaveDepartments,
} from "./MasterDetailService";
import {
  BuildTreeUsingParentID,
  CovertParentIds,
  GetLoginUserID,
  findDepartmentById,
} from "../../services/CommonService";
import { useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import { v4 as uuidv4 } from "uuid";

export const DepartmentConfiguration = ({ TabValue = 0 }) => {
  const { DepartmentConfigList, setDepartmentConfigList } =
    useContext(AppContext);

  const [OnSelect, setOnSelect] = useState(0);

  useEffect(() => {
    if (TabValue == 0) {
      GetDepartmentList();
    }
  }, [TabValue]);

  const GetDepartmentList = async () => {
    MasterConfig_GetDepartmentList()
      .then((res) => {
        if (res?.data?.data?.length) {
          res.data.data.forEach((ele) => (ele.isOpen = true));
          let treeJSON = BuildTreeUsingParentID(res.data.data);
          let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
          Data.forEach((ele) => (ele.isOpen = true));

          setDepartmentConfigList(Data);
        }
      })
      .catch((err) => console.log(err));
  };

  const CreateDepartment = (IsDepartment = false, node) => {
    let DepartList = [];
    let uniqueID = uuidv4();
    if (DepartmentConfigList?.length) {
      DepartList = [...DepartmentConfigList];

      let obj = findDepartmentById(DepartList, OnSelect);
      if (obj) {
        let { children } = obj;
        if (Array.isArray(children)) {
          children.push({
            id: uniqueID,
            name: "",
            isEditing: true,
          });
        } else {
          obj.children = [
            {
              id: uniqueID,
              name: "",
              isEditing: true,
            },
          ];
        }
      } else {
        DepartList.push({
          id: uniqueID,
          name: "",
          isEditing: true,
          //children: [],
        });
      }
    } else {
      DepartList.push({
        id: uniqueID,
        name: "",
        isEditing: true,
        // children: [],
      });
    }
    setDepartmentConfigList([...DepartList]);
    setSelectedDepartment();
  };

  const EnableEdit = (node) => {
    const { id } = node;
    let data = [...DepartmentConfigList];
    let finedObj = findDepartmentById(data, id);
    if (finedObj) {
      finedObj.isEditing = true;
    }

    setDepartmentConfigList([...data]);
  };

  const RenameSave = (id, name) => {
    if (DepartmentConfigList?.length) {
      let data = [...DepartmentConfigList];

      let obj = findDepartmentById(data, id);
      let CheckName = findDepartmentByName(data, name, id);
      if (obj && !CheckName) {
        obj.name = name;
        obj.isEditing = false;
        setDepartmentConfigList([...data]);
      } else {
        showWarningMsg("Department names should be unique.");
      }
    }
  };

  const SaveDepartment = () => {
    if (DepartmentConfigList?.length) {
      let List = CovertParentIds([...DepartmentConfigList]);
      List.forEach((ele) => {
        let DepartmentID = 0;
        if (isNaN(ele.id)) {
          DepartmentID = 0;
        } else {
          DepartmentID = ele.id;
        }
        ele.DepartmentID = DepartmentID;
        ele.SavedBy = GetLoginUserID();
      });
      if (List?.length) {
        MasterConfig_SaveDepartments({ DepartmentList: JSON.stringify(List) })
          .then((res) => {
            const { isSuccess, data } = res.data;
            if (data > 0 && isSuccess) {
              showSaveSuccess();
            } else {
              showWarningMsg("Something went wrong please try again.");
            }
            GetDepartmentList();
          })
          .catch((err) => {
            console.log(err);
            showErrorMsg();
          });
      }
    }
  };

  const DeleteDepartment = (id) => {
    if (DepartmentConfigList?.length) {
      let data = [...DepartmentConfigList];
      deleteDepartmentById(data, id);
      setDepartmentConfigList([...data]);
      setSelectedDepartment();
    }
  };

  const setSelectedDepartment = (id = 0) => {
    setOnSelect(id);
    let obj = findDepartmentById(DepartmentConfigList, id);
    if (Array.isArray(obj.children)) {
      obj.children.forEach((x) => (x.isOpen = !x.isOpen));
    }
  };

  function findDepartmentByName(departmentList, nameToFind, id) {
    for (const department of departmentList) {
      if (department.name.trim() === nameToFind.trim() && department.id != id) {
        return department;
      }
      if (department.children && department.children.length > 0) {
        const foundInChild = findDepartmentById(
          department.children,
          nameToFind
        );
        if (foundInChild) {
          return foundInChild;
        }
      }
    }
    return null; // Return null if department with the specified ID is not found
  }

  function deleteDepartmentById(departmentList, idToDelete) {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === idToDelete) {
        departmentList.splice(i, 1); // Remove the department at index i
        return departmentList;
      }
      if (departmentList[i].children && departmentList[i].children.length > 0) {
        const updatedChildren = deleteDepartmentById(
          departmentList[i].children,
          idToDelete
        );
        if (updatedChildren !== departmentList[i].children) {
          departmentList[i].children = updatedChildren; // Update children if modified
          return departmentList;
        }
      }
    }
    return departmentList; // Return the original list if department with the specified ID is not found
  }

  return (
    <div>
      <PageHeaderTitle title={"Department Configuration"}></PageHeaderTitle>
      <div>
        <ReactTreeView
          data={DepartmentConfigList}
          CreateDepartment={CreateDepartment}
          onRename={EnableEdit}
          RenameSave={RenameSave}
          onSelect={setSelectedDepartment}
          DeleteNode={DeleteDepartment}
          openByDefault={false}
          CrudISNeed
        ></ReactTreeView>
      </div>
      <button className="btn btn-primary" onClick={SaveDepartment}>
        Save
      </button>
    </div>
  );
};
