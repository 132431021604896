import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class DesignAndCommService {
  GetEquipmentCodes(StationIDs, employeeID) {
    return axios
      .get(`Commissioned/GetEquipmentCodeList?stationIDs=${StationIDs}&&employeeID=${employeeID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  GetParameters() {
    return axios
      .get(`EquipmentManager/GetParameters?parameterID=0&isDelete=false`)
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  AddOrUpdateDesign(data) {
    console.log(data);
    return axios
      .post("Commissioned/AddCommissionedDesign", data)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetDesignParameters(designID) {
    return axios
      .get(
        `Commissioned/GetCommissionedDesignParameterByID?EquipmentDetailsID=${designID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  GetCommissionedDetailsParameterByID(EquipmentDetailsID) {
    return axios
      .get(
        `Commissioned/GetCommissionedDetailsParameterByID?EquipmentDetailsID=${EquipmentDetailsID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          console.log(response.data);
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  AddOrUpdateCommissioned(data) {
    console.log(data);
    return axios
      .post("Commissioned/AddCommissionedDetails", data)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
}
