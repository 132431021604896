import React, { useState, useEffect } from "react";
import { Dropdown } from "../../components/Dropdown";
import RevenueConfigService from "./RevenueConfigService";
import RevenueHourTable from "./RevenueHourTable";

import { showSaveSuccess, showWarningMsg } from "./../../components/ToastMsg";
import { GetLoginUserID } from "../../services/CommonService";

const _nonrevenueService = new RevenueConfigService();

const AddRevenueConfig = ({
  revenueHourID,
  equipmentID,
  station,
  setRevenueHourID,
  handleRefresh,
  closepopup,
  StationList,
  systemTypeID,
}) => {
  const [equipmentModel, setequipmentModel] = useState({});
  const [stationId, SetStationID] = useState(null);
  const [equipmentList, setEquipmentList] = useState([]);
  const [ParameterList, setParameterList] = useState([]);
  const [equipmentCategoryList, setEquipmentCategoryList] = useState([]);
  const [tempEquipList, setTempEquipList] = useState([]);
  const [EquipmentTagNo, setEquipmentTagNo] = useState([]);
  const [EquipmentTagNoList, setEquipmentTagList] = useState([]);
  const [EquipmentTagNoTempList, setEquipmentTagTempList] = useState([]);

  useEffect(() => {
    if (
      station != null &&
      station != 0 &&
      equipmentID != null &&
      equipmentID != 0
    ) {
      SetStationID(station);
      setequipmentModel((prevState) => ({
        ...prevState,
        EquipmentID: equipmentID,
        EquipmentCategory: systemTypeID,
      }));

      // const Equip = EquipmentTagNoList.filter((x) => x.EquipmentID == equipmentID);
      // setEquipmentTagTempList([...Equip]);
    }
  }, [station, equipmentID, systemTypeID]);

  useEffect(() => {
    if (
      stationId != undefined &&
      stationId != null &&
      stationId != -1 &&
      stationId != 0
    ) {
      _nonrevenueService
        .NonRevenue_MasterData(stationId)
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            var dataLst = [];
            var dataLsttag = [];
            var dataLstCategory = [];
            const EquipmentList = response.data[0];
            const EquipmentTagList = response.data[1];
            const EquipmentCategoryList = response.data[2];

            EquipmentList.map((v) => {
              dataLst.push({
                value: v.EquipmentID,
                key: v.EquipmentShortName,
                ...v,
              });

              // dataLstCategory.push({value : v.SystemTypeID , key: v.SystemTypeName});
            });

            EquipmentTagList.map((v) => {
              dataLsttag.push({
                value: v.EquipmentDetailID,
                label: v.EquipmentCode,
                ...v,
              });
            });

            EquipmentCategoryList.map((v) => {
              dataLstCategory.push({
                value: v.SystemTypeID,
                key: v.SystemTypeName,
              });
            });

            setEquipmentCategoryList([...dataLstCategory]);
            setEquipmentList([...dataLst]);
            setTempEquipList([...dataLst]);
            setEquipmentTagList([...dataLsttag]);

            if (equipmentID != 0) {
              const Equip = dataLsttag.filter(
                (x) => x.EquipmentID == equipmentID
              );

              setEquipmentTagTempList([...Equip]);

              const EquipName = tempEquipList.filter(
                (x) => x.value == equipmentID
              );

              if (EquipName[0].key == "ROOM TEMPERATURE") {
                _nonrevenueService
                  .Config_NonRevenue_GetParameterList(stationId, equipmentID)
                  .then((response) => {
                    if (
                      response != null &&
                      response.data != null &&
                      response.data.length > 0
                    ) {
                      const ParaList = response.data[0];
                      setParameterList([...ParaList]);
                    } else {
                      setParameterList([]);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          } else {
            setEquipmentList([]);
            setEquipmentTagList([]);
            setEquipmentCategoryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setEquipmentList([]);
      setEquipmentTagList([]);
      setEquipmentCategoryList([]);
    }
  }, [stationId]);

  const onstationchange = (e) => {
    const { name, value } = e.target;

    SetStationID(value);
    setEquipmentList([]);
    setParameterList([]);
    setEquipmentTagList([]);
    setEquipmentTagNo([]);
    setEquipmentTagTempList([]);
    setequipmentModel(null);
    setEquipmentCategoryList([]);
    // setRevenueHourID(0);
  };

  const CommonHandleChange = (e) => {
    
    const { name, value } = e.target;

    if (name == "EquipmentCategory") {
      setequipmentModel((prevState) => ({
        ...prevState,
        [name]: value,
        EquipmentID: -1,
      }));
      setEquipmentTagTempList([]);
      const EquipName = tempEquipList.filter((x) => x.SystemTypeID == value);

      setEquipmentList([...EquipName]);
      setParameterList([]);
    }
    if (name == "EquipmentID") {
      setequipmentModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      const Equip = EquipmentTagNoList.filter((x) => x.EquipmentID == value);
      setEquipmentTagTempList([...Equip]);

      const EquipName = tempEquipList.filter((x) => x.value == value);

      if (EquipName[0].key == "ROOM TEMPERATURE") {
        _nonrevenueService
          .Config_NonRevenue_GetParameterList(stationId, value)
          .then((response) => {
            if (
              response != null &&
              response.data != null &&
              response.data.length > 0
            ) {
              const ParaList = response.data[0];
              setParameterList([...ParaList]);
            } else {
              setParameterList([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  // const HandleChange = (e) => {

  //     setEquipmentTagNo(e);

  // }

  const HandleSubmit = (equipmenttagno, time) => {
    var equipmentList = [];
    let IsShutdownNum = 0;
    if (time?.IsShutdown == "true" || time?.IsShutdown == true) {
      IsShutdownNum = 1;
    }

    equipmenttagno.map((v, i) => {
      const list = {
        revenueHourDetailID: v.RevenueHourDetailID ? v.RevenueHourDetailID : 0,
        revenueHourID: revenueHourID,
        equipmentDetailID: v.EquipmentDetailID,
        fromTime: time?.StartTime,
        toTime: time?.EndTime,
        isShutdown: Number(IsShutdownNum),
        remark: time?.Remark,
        parameterIDS: time?.ParameterID,
      };
      equipmentList.push(list);
    });
    const finalData = {
      revenueHourID: revenueHourID,
      stationID: stationId,
      equipmentID: equipmentModel?.EquipmentID,
      status: 1,
      nonRevenueList: equipmentList,
      savedBy: GetLoginUserID(),
    };
    _nonrevenueService
      .NonRevenueAddorEdit(finalData)
      .then((res) => {
        if (res.data != 0) {
          setRevenueHourID(res.data);
          showSaveSuccess();
        } else {
          showWarningMsg("Equipment is already mapped for this station");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Refresherror = () => {
    SetStationID(-1);
    setEquipmentList([]);
    setParameterList([]);
    setEquipmentTagList([]);
    setEquipmentTagNo([]);
    setequipmentModel([]);
    setEquipmentTagTempList([]);
    setRevenueHourID(0);
    closepopup();
  };
  return (
    <div className="mb-2">
      <div
        id="timebutton"
        className="modal custom-modal fade"
        data-bs-backdrop="static"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Non-Revenue / Maintenance Hour Configuration
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => Refresherror()}
                data-bs-dismiss="modal"
                id="popupclose"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="timeconfigAdd">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="StationID">
                        Station Name<span className="text-danger"> *</span>{" "}
                      </label>
                      <Dropdown
                        data={StationList}
                        placeholder="--Select--"
                        name={"StationID"}
                        HandleChange={onstationchange}
                        value={stationId}
                        className="BreakdownManualDrop"
                        initialVal={-1}
                        disabled={revenueHourID != 0 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="EquipmentCategory">
                        Equipment Category
                        <span className="text-danger"> *</span>{" "}
                      </label>
                      <Dropdown
                        data={equipmentCategoryList}
                        placeholder="--Select--"
                        name={"EquipmentCategory"}
                        HandleChange={CommonHandleChange}
                        value={
                          equipmentModel?.EquipmentCategory
                            ? equipmentModel.EquipmentCategory
                            : -1
                        }
                        className="BreakdownManualDrop"
                        initialVal={-1}
                        disabled={revenueHourID != 0 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="EquipmentID">
                        Equipment Name<span className="text-danger"> *</span>{" "}
                      </label>
                      <Dropdown
                        data={equipmentList}
                        placeholder="--Select--"
                        name={"EquipmentID"}
                        HandleChange={CommonHandleChange}
                        value={
                          equipmentModel?.EquipmentID
                            ? equipmentModel.EquipmentID
                            : -1
                        }
                        className="BreakdownManualDrop"
                        initialVal={-1}
                        disabled={revenueHourID != 0 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </form>

              <RevenueHourTable
                revenueHourID={revenueHourID}
                EquipmentTagNoTempList={EquipmentTagNoTempList}
                HandleSubmit={HandleSubmit}
                ParameterList={ParameterList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRevenueConfig;
