import React from "react";

function PageHeaderTitle({ title }) {
  return (
    <div className="page-header mb-3" style={{ display: title ? "" : "none" }}>
      <div className="row justify-content-between">
        <div className="col-12">
          <h3 className="page-title">{title}</h3>
        </div>
      </div>
    </div>
  );
}

export default PageHeaderTitle;
