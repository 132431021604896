import React from "react";
import { useState, useEffect, useReducer, useContext } from "react";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import { Dropdown } from "../../components/Dropdown";
import ManualParameterEntryService from "./ManualParameterEntryService";
import { GetLoginUserID } from "../../services/CommonService";
import { HtmlTable } from "../../components/HtmlTable";
import $ from "jquery";
import { showSaveSuccess, showSaveError } from "../../components/ToastMsg";
import { AppContext } from "../../services/ContextProvider";
import { getPrivilege } from "../../services/CommonService";

const obj = new ManualParameterEntryService();
export const SaveParameterEntry = ({
  ParameterModel,
  CleartabRefresh,
  SavePointEntry,
  Refresh,
  ParameterList,
  EquipmentTagList,
  StationList,
  ManualType,
  TableName,
  columnNames,
  ManualPointList,
  TableRef,
}) => {
  const [errors, seterrors] = useState([]);
  const [ParameterPointModel, SetParameterPointModel] = useState({});
  const [EquipmentTagNo, setEquipmentTagNo] = useState(null);
  const [ParameterName, setParameterName] = useState(null);
  const [Equipmentlist, setEquipmentlist] = useState([]);
  const [parameterlist, setparameterlist] = useState([]);
  const [TableRefresh, setTableRefresh] = useReducer((x) => x + 1, 0);
  // const { getCookies, setCookies, showLoading, hideLoading } = useContext(AppContext);

  const AddHandleClick = () => {
    const CheckValue = ParameterPointModel;
    CheckValue.ManuallyType = ManualType;

    Object.keys(CheckValue).forEach(
      (k) =>
        (CheckValue[k] =
          CheckValue[k] !== undefined && typeof CheckValue[k] == "string"
            ? CheckValue[k].trim()
            : CheckValue[k])
    );

    var EquipTagNo = [],
      paramName = [];
    if (EquipmentTagNo != null) {
      EquipTagNo = EquipmentTagNo;
    }
    if (ParameterName != null) {
      paramName = ParameterName;
    }
    const err = errors;
    var flag = true;

    if (CheckValue != null) {
      if (CheckValue.StationID) {
        err.StationID = "";
      } else {
        err.StationID = "Please enter station name";
        flag = false;
      }
      if (
        (CheckValue.Remarks != null && CheckValue.Remarks != "") ||
        (CheckValue.PointValue != null && CheckValue.PointValue != "")
      ) {
        err.PointValue = "";
      } else {
        err.PointValue = "Please enter actual value";
        flag = false;
      }
      if (TableName !== "DailyTable") {
        if (CheckValue.PointDate) {
          err.PointDate = "";
        } else {
          err.PointDate = "Please enter date";
          err.PointTime = "";
          flag = false;
        }
      }
      if (TableName === "DailyTable") {
        if (CheckValue.PointTime) {
          err.PointTime = "";
        } else {
          err.PointTime = "Please choose time";
          err.PointDate = "";
          flag = false;
        }
      }

      if (
        (CheckValue.Remarks != null && CheckValue.Remarks != "") ||
        (CheckValue.PointValue != null && CheckValue.PointValue != "")
      ) {
        err.Remarks = " ";
      } else {
        flag = false;
        err.Remarks = "Please enter remarks";
      }

      if (EquipTagNo != null && EquipTagNo.value != undefined) {
        err.EquipmentTagNo = "";
      } else {
        flag = false;
        err.EquipmentTagNo = "Please enter equipment tag number";
      }
      if (paramName != null && paramName.value != undefined) {
        err.ParameterName = "";
      } else {
        flag = false;
        err.ParameterName = "Please enter parameter name";
      }

      seterrors({ ...err });
    }

    if (flag) {
      // CheckValue.ManuallyType = ManualType;
      CheckValue.SavedBy = GetLoginUserID();
      CheckValue.EquipmemntTagNo = EquipTagNo.value;
      CheckValue.ParameterID = paramName.value;

      console.log(CheckValue);
      obj
        .ManualPointEntry_SaveManualPoint(CheckValue)
        .then((res) => {
          if (res.data != null && res.data.data > 0) showSaveSuccess();
          else showSaveError();

          SetParameterPointModel({});
          setParameterName([]);
          setEquipmentTagNo([]);
          $(".ManualClearClass").val("");
          setTableRefresh();
        })
        .catch((res) => {
          showSaveError();
          console.log(res);
          setTableRefresh();
        });
    }
  };
  const EquipmentHandleChange = (e) => {
    setEquipmentTagNo({ ...e });

    const equiptagno = e.value;

    var seletedParameter = ParameterList.filter(
      (x) => x.EquipmentDetailID == equiptagno
    );

    setparameterlist([...seletedParameter]);
    setParameterName([]);
  };

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    SetParameterPointModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name == "StationID") {
      let Equipment = EquipmentTagList.filter((x) => {
        return x.StationID == value;
      });

      setEquipmentlist([...Equipment]);
      setEquipmentTagNo([]);
    }
  };

  useEffect(() => {
    TableRef(TableRefresh);
  }, [TableRefresh]);

  useEffect(() => {
    // if (TableName == 'DailyTable' && ParameterModel.PointDate != undefined) {

    //     ParameterModel.PointDate1 = ParameterModel.PointDate;

    //
    //     var time = new Date(ParameterModel.PointDate);
    //     ParameterModel.PointDate = moment(time).format("HH:mm")
    //     SetParameterPointModel(ParameterModel);

    SetParameterPointModel(ParameterModel);
    // }
    var seletedEquipment = EquipmentTagList.find(
      (x) => x.value == ParameterModel.EquipmemntTagNo
    );
    var seletedParameter = ParameterList.find(
      (x) => x.value == ParameterModel.ParameterID
    );
    setParameterName(seletedParameter);
    setEquipmentTagNo(seletedEquipment);
  }, [Refresh, ParameterModel]);

  useEffect(() => {
    SetParameterPointModel({});
    setParameterName([]);
    setEquipmentTagNo([]);
    seterrors([]);
    $(".ManualClearClass").val("");
  }, [CleartabRefresh]);

  return (
    <div>
      <form id="ManualPointEntryForm">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={ParameterPointModel.StationID}
                className="ManualClearClass"
              />

              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment Tag Number
                <span className="text-danger"> *</span>
              </label>
              <ReactMultiSelect
                options={Equipmentlist}
                // onChange={EquipmentChange}
                placeholder="Select"
                value={EquipmentTagNo}
                onChange={(e) => {
                  //  setEquipmentTagNo({ ...e }),

                  EquipmentHandleChange(e);

                  //  CommonHandleChange
                }}
              />
              <span className="text-danger"> {errors.EquipmentTagNo} </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Parameter Name
                <span className="text-danger"> *</span>
              </label>
              <ReactMultiSelect
                options={parameterlist}
                onChange={(e) => {
                  setParameterName({ ...e });
                }}
                placeholder="Select"
                value={ParameterName}
              />
              <span className="text-danger"> {errors.ParameterName} </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Actual Value<span className="text-danger"> *</span>{" "}
              </label>
              <input
                className="form-control ManualClearClass"
                type="number"
                name="PointValue"
                maxLength={10}
                onChange={CommonHandleChange}
                value={ParameterPointModel.PointValue}
              />
              <span className="text-danger"> {errors.PointValue} </span>
            </div>
          </div>
          {TableName == "DailyTable" ? (
            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Time<span className="text-danger"> *</span>{" "}
                </label>
                <input
                  className="form-control ManualClearClass"
                  type="time"
                  name="PointTime"
                  onChange={CommonHandleChange}
                  value={ParameterPointModel.PointTime}
                />
                <span className="text-danger"> {errors.PointTime} </span>
              </div>
            </div>
          ) : (
            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Date and Time<span className="text-danger"> *</span>{" "}
                </label>
                <input
                  className="form-control ManualClearClass"
                  type="datetime-local"
                  name="PointDate"
                  onChange={CommonHandleChange}
                  value={ParameterPointModel.PointDate}
                />
                <span className="text-danger"> {errors.PointDate} </span>
              </div>
            </div>
          )}
          <div className="col-md-4">
            <div className="form-group">
              <label className="label" for="empCode">
                Remarks
                <span className="text-danger"> *</span>{" "}
              </label>
              <textarea
                className="form-control ManualClearClass"
                placeholder="Please enter remarks"
                style={{ resize: "none" }}
                type="text"
                name="Remarks"
                value={ParameterPointModel.Remarks}
                onChange={CommonHandleChange}
                rows={3}
              />
              <span className="text-danger"> {errors.Remarks} </span>
            </div>
          </div>
          {getPrivilege().includes("MANUALPARAMETERENTRYCREATE") && (
            <div className="col-md-2 mt-4">
              <button
                type="button"
                id="addRow"
                onClick={AddHandleClick}
                className="btn add-btn"
              >
                <i className="fa fa-plus"></i> Add
              </button>
            </div>
          )}
        </div>
      </form>
      <div className="" style={{marginTop :"-30px"}}>
        <HtmlTable
          columnList={columnNames}
          rowList={ManualPointList}
          FixedColums={{
            left: 1,
            right: 1,
          }}
          id={TableName}
        />
      </div>
    </div>
  );
};
