import React, { useEffect, useState, useContext, useReducer } from "react";
import {
    BootrapModel,
    Dropdown,
    HtmlTable,
    WebCam,
    showErrorMsg,
    showSaveError,
    showSaveSuccess,
    showSuccessMsg,
    showWarningMsg,
} from "../../components";
import { AuditingProcess_GetPeriodicDetailsByID } from "./AuditingProcessService.js";

import { TbCameraSelfie } from "react-icons/tb";

// import { FaUpload } from "react-icons/fa";

import { useLocation } from "react-router-dom";

import {
    PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19,
    PeriodicMaintenance_GetOverAllRemarks,
    PeriodicMaintenance_SaveSpareData,
    PeriodicService,
} from "../PeriodicMaintenance/PeriodicService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";
import {
    DeepCopy,
    GetLoginUserID,
    GetSelect,
    getL0RoleID,
    getUserDetails,
    makeUnique,
} from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { useNavigate } from "react-router";
import { SpareList } from "../PeriodicMaintenance/SpareList";
import { PeriodicMaintenance_GetEquipmentDetailsUsingID } from "../PeriodicMaintenance/PeriodicService";
import { v4 } from "uuid";
import { IoMdCheckmark } from "react-icons/io";
import { RiArrowGoBackLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";

const _breakdownService = new BreakdownMaintenanceService();
function PeriodicDetailsView() {
    const _periodicService = new PeriodicService();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        showLoading,
        hideLoading,
        PeriodicModel,
        setPeriodicModel,
        getCookies,
    } = useContext(AppContext);

    const {
        PeriodicCheckListMaintenanceID,
        fromdate,
        enddate,
        EquipmentDetails,
        DepartmentID,
    } = location.state;

    const {
        EquipmentID,
        FromDate,
        EndDate,
        MaintenanceTypeID,
        PWTDate,
        Location,
        PWTNumber,
        ApprovalRemarks,
        IsChecked,
        JobScheduleID,
        EquipmentDetailId,
        OverAllRemarksList,
    } = PeriodicModel;

    const [UGSColumnList, setUGSColumnList] = useState([]);
    const [UGSRowList, setUGSRowList] = useState([]);

    const [spareList, setSpareList] = useState([]);

    const [equipmentDetailsList, setEquipmentDetailsList] = useState([]);
    const [FileObj, setFileObj] = useState({
        FilePaths: [],
        isAlreadyUploaded: false,
    });
    const [TestNo, setTestNo] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        setPeriodicModel({
            PeriodicCheckListMaintenanceID,
            DepartmentID,
            FromDate: fromdate,
            EndDate: enddate,
            PWTDate: "",
            EquipmentDetailId: EquipmentDetails[0]?.value ?? 0,
            Location: "",
            PWTNumber: "",
        });
        localStorage.setItem("FilePaths", "");
        localStorage.setItem("isAlreadyUploaded", "");
        return () => {
            setUGSColumnList([]);
            setUGSRowList([]);
            localStorage.setItem("FilePaths", "");
        };
    }, []);

    useEffect(() => {
        GetDescriptionByID();
    }, [PeriodicCheckListMaintenanceID, EquipmentDetailId]);

    const CommonHandleChange = async (event) => {
        const { name, value } = event.target;
        if (name == "FileObj") {
            event.preventDefault();
            const formData = new FormData();
            $.each(event.target.files, function (i, file) {
                formData.append("files", file);
            });
            if (event.target?.files.length > 0) {
                let returnValue = await fileUpload(formData);
                if (returnValue) {
                    let FilePaths = localStorage.getItem("FilePaths");
                    if (FilePaths) {
                        FilePaths = JSON.parse(FilePaths);
                    } else FilePaths = [];
                    // let FilePaths = CopyFileObj.FilePaths;

                    if (!Array.isArray(FilePaths)) {
                        FilePaths = [];
                    }
                    FilePaths = FilePaths.concat(returnValue);
                    FilePaths.forEach((ele) => (ele.Type = "UPLOAD"));
                    localStorage.setItem("FilePaths", JSON.stringify(FilePaths));
                    setFileObj((pre) => ({
                        ...pre,
                        FilePaths,
                    }));
                }
            }
        } else {
            setPeriodicModel({ [name]: value });
        }
    };

    const fileUpload = async (formData) => {
        showLoading();
        return await _breakdownService
            .FileUpload(formData)
            .then((res) => {
                hideLoading();
                showSuccessMsg("Files Uploaded SuccessFully.");
                return res;
            })
            .catch((err) => {
                hideLoading();
                showErrorMsg("Something Went Wrong! Please Try Again.");

                console.log(err);
                return 0;
            });
    };

    const GetDescriptionByID = () => {
        showLoading();
        AuditingProcess_GetPeriodicDetailsByID(
            PeriodicCheckListMaintenanceID,
            EquipmentDetailId,
        )
            .then((res) => {
                
                hideLoading();
                convertColum(res);
            })
            .catch((err) => {
                console.log(err);
                hideLoading();
            });
    };

    const SaveJobDetailsForUG = async (data) => {
        showLoading();

        await PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19(data)
            .then((res) => {
                hideLoading();
                if (res?.data?.isSuccess) {
                    showSaveSuccess();
                    GetDescriptionByID();
                } else {
                    showSaveError();
                }
            })
            .catch((err) => {
                hideLoading();
                showSaveError();
                console.log(err);
            });
    };

    function convertColum(res) {
        if (res?.data?.data) {
            let columnsList = [];
            const data = res.data.data;
            let InpEnable = getL0RoleID() == getUserDetails().RoleID;

            columnsList.push({
                title: `<p style ='width:150px;'> Component<p/>`,
                data: "ComponentName",
                width: "150px",
                createdCell: function (td, cellData, rowData, row, col) {
                    let Div = $(`<p>`);
                    let tag = $(`<p>${cellData}</p>`);
                    let Critical = $(`<small class ='criticalTag'>Critical</small>`);

                    let { IsCritical } = rowData;
                    console.log(IsCritical);
                    if (IsCritical) {
                        $(Div).append(Critical);
                    }
                    $(Div).append(tag);
                    $(td).html(Div);
                },
            });
            columnsList.push({
                title: `<p style = 'width : 150px'>Work Description<p/>`,
                data: "DescriptionName",
                width: "150px ",
            });

            columnsList.push({
                title: "Rated Value",
                data: "RatedValue",
                width: "50px ",
            });

            columnsList.push({
                title: "<p style='width:100px'>UOM</p>",
                data: "UOM",
                width: "60px",
            });

            let uniqueList = makeUnique(data, ["EquipmentCode", "EquipmentDetailID"]);

            let EquipForSpare = [];
            uniqueList?.forEach((element, _) => {
                const { EquipmentCode, EquipmentDetailID } = element;

                EquipForSpare.push({
                    key: EquipmentCode,
                    value: EquipmentDetailID,
                });

                let ShowStatus = "";

                let div = $(`<div style ="width : 300px">`);
                let span = $(`<span style ="width : 300px ;display: flex;" >`);
                let labelStatus = $(
                    `<span class='PeriodicStatusLabel'> ${ShowStatus}</span>`
                );
                let ActualRatingHeading = $(
                    '<p class= "row" ><span class ="col"> Status</span><span  class ="col">Actual Value</span></p>'
                );

                let toggleIcon = `<input  class =" m-r-10 checks form-check-input PeriodicUG19Toggle hide"  id="switch_annual_${EquipmentDetailID}" type="checkbox" />`;

                span.append(toggleIcon);
                div.append(span);
                div.append(labelStatus);
                div.append(ActualRatingHeading);

                // div.append(Status);
                columnsList.push({
                    title: div.html(),
                    data: `EquipmentStatus`,
                    // width: "100px",
                    createdCell: function (td, cellData, rowData, row, col) {
                        let WrapDiv = $("<div class='btn-group' style='width:200px'>");
                        let { RatedValue } = rowData;

                        let ActualValueInp = "";
                        let StatusValueInp = "";
                        const ActualValue = rowData[`Actual`];
                        const EquipmentStatus = rowData[`EquipmentStatus`] ?? "";
                        const { MaxValue, MinValue } = rowData;

                        let ActualValBColor =
                            MaxValue >= ActualValue && MinValue <= ActualValue
                                ? ""
                                : "badge-danger";
                        if (rowData.IsHasOption) {
                            StatusValueInp = $(
                                GetSelect(
                                    [
                                        {
                                            key: rowData.StatusOption1,
                                            value: rowData.StatusOption1,
                                        },
                                        {
                                            key: rowData.StatusOption2,
                                            value: rowData.StatusOption2,
                                        },
                                    ],
                                    EquipmentStatus,
                                    !InpEnable,
                                    "m-r-5",
                                    "-"
                                )
                            );
                        } else {
                            StatusValueInp = $(
                                `<input type="text"  class="form-control " ${InpEnable ? "" : "disabled"
                                } value="${EquipmentStatus}"/>`
                            );
                        }
                        StatusValueInp.on("change", function (e) {
                            e.preventDefault();
                            const { value } = e.target;
                            rowData[`EquipmentStatus`] = value;
                        });

                        ActualValueInp = $(
                            `<input type="number" style="margin-left: 5px;"  class="form-control ${ActualValBColor}" ${InpEnable ? "" : "disabled"
                            } value="${ActualValue}"/>`
                        );

                        ActualValueInp.on("change", function (e) {
                            e.preventDefault();

                            const { value } = e.target;
                            if (MaxValue >= value && MinValue <= value) {
                                $(e.target).removeClass("badge-danger");
                            } else {
                                $(e.target).addClass("badge-danger");
                            }
                            rowData[`Actual`] = value;
                        });

                        WrapDiv.append(StatusValueInp);
                        WrapDiv.append(ActualValueInp);

                        $(td).html(WrapDiv);
                    },
                });
            });

            setEquipmentDetailsList(EquipForSpare);
            columnsList.push({
                title: "<p  style=`width:150px`>Lo Remarks </p>",
                data: "Remarks",
                createdCell: function (td, cellData, rowData, row, col) {
                    let Remarks = $(
                        `<textarea  class="form-control" style="width:150px"  ${InpEnable ? "" : "disabled"
                        }>${cellData}</textarea>`
                    );

                    Remarks.on("change", function (e) {
                        e.preventDefault();
                        const { value } = e.target;
                        rowData[`Remarks`] = value;
                    });
                    $(td).html(Remarks);
                },
            });
            columnsList.push({
                title: "Status",
                data: "StatusName",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                    let Status = cellData;
                    if (getL0RoleID() !== getUserDetails().RoleID) {
                        if (rowData.InitialApproved == null) {
                            Status = "Closed";
                        } else {
                            Status = rowData.InitialApproved ? "Approved" : "Rejected";
                        }
                    }

                    $(td).html(GetPeriodicStatus(Status));
                },
            });

            columnsList.push({
                title: "Submit",
                data: "PeriodicCheckListMaintenanceID",
                width: "60px",
                visible: getL0RoleID() == getUserDetails().RoleID,
                className: `${getL0RoleID() == getUserDetails().RoleID ? "" : "hide"}`,

                createdCell: function (td, cellData, rowData, row, col) {
                    let WarpDiv = $("<div>");
                    let SubmitEnable = false;

                    if (
                        getL0RoleID() == getUserDetails().RoleID &&
                        (rowData.Status < 2 || rowData.IsShow)
                    ) {
                        SubmitEnable = true;
                    }
                    //SubmitEnable = true;
                    let SubmitBtn = $(`<button type="button" class='btn icon-btn' ${SubmitEnable ? "" : "disabled"
                        } title='Save'>
                <i class='fa fa-check' style="margin-top:10px"  ></i>
                </button>`);

                    if (SubmitEnable) {
                        SubmitBtn.on("click", () => {
                            handleSubmit(rowData);
                        });
                    }

                    WarpDiv.append(SubmitBtn);

                    $(td).html(WarpDiv);
                },
            });

            setUGSColumnList([...columnsList]);
            setUGSRowList([...data]);

            setPeriodicModel({
                PWTDate: data[0]?.PWTDate,
                PWTNumber: data[0]?.PWTNumber,
                ApprovalRemarks: data[0]?.ApprovalRemarks,
            });

            $(".PWTDate").val(data[0]?.PWTDate);
            $(".PWTNumber").val(data[0]?.PWTNumber);
        } else {
            setUGSColumnList([]);
            setUGSRowList([]);
        }
    }

    const handleSubmit = (rowData, status) => {
        const {
            PeriodicDetailID,
            PeriodicCheckListMaintenanceID,
            EquipmentStatus,
            Actual,
            CurrentStatgeID,
            Remarks,
        } = rowData;
        const obj = {
            PeriodicDetailID,
            PeriodicCheckListMaintenanceID,
            EquipmentStatus,
            Actual,
            SavedBy: GetLoginUserID(),
            Status: 2,
            PWTDate: $(".PWTDate").val(),
            PWTNumber: $(".PWTNumber").val(),
            CurrentStatgeID: CurrentStatgeID ?? 0,
            IsApproved: status,
            Remarks,
            ApprovalRemarks,
            Type: getL0RoleID() == getUserDetails().RoleID ? 1 : 2,
        };
        let FilePaths = localStorage.getItem("FilePaths");
        let isAlreadyUploaded = localStorage.getItem("isAlreadyUploaded");

        if (FilePaths) {
            FilePaths = JSON.parse(FilePaths);
        } else FilePaths = [];

        const isL0Role = getL0RoleID() === getUserDetails().RoleID;
        const hasSelfie = FilePaths.some((ele) => ele.Type === "SELFIE");

        if (isL0Role) {
            if (hasSelfie) {
                if (isAlreadyUploaded) {
                    obj.FileObj = JSON.stringify([]);
                    SaveJobDetailsForUG(obj);
                } else {
                    obj.FileObj = JSON.stringify(FilePaths);
                    SaveJobDetailsForUG(obj);
                    localStorage.setItem("isAlreadyUploaded", 1);
                    setPeriodicModel((pre) => ({
                        ...pre,
                        isAlreadyUploaded: true,
                    }));
                }
            } else {
                showWarningMsg(
                    "Please take a selfie. Then you can continue your work!"
                );
                $(".TakeSelfie").addClass("btn-danger");

                setTimeout(function () {
                    $(".TakeSelfie").removeClass("btn-danger");
                }, 1000);
            }
        } else {
            if (!isAlreadyUploaded) {
                obj.FileObj = JSON.stringify(FilePaths);
                SaveJobDetailsForUG(obj);
            }
        }
    };

    const getOverAllRemarks = () => {
        PeriodicMaintenance_GetOverAllRemarks(
            PeriodicCheckListMaintenanceID,
            EquipmentDetailId
        )
            .then((res) => {
                if (res?.data?.data) {
                    setPeriodicModel({ OverAllRemarksList: res.data.data });
                } else {
                    setPeriodicModel({ OverAllRemarksList: [] });
                }
            })
            .catch((err) => console.log(err));
    };

    const saveSpare = (list) => {
        setSpareList(list);
    };
    const SaveSpareData = () => {
        var obj = {
            PeriodicCheckListMaintenanceID:
                PeriodicModel.PeriodicCheckListMaintenanceID,
            SavedBy: GetLoginUserID(),
            SpareList: JSON.stringify(spareList),
        };

        PeriodicMaintenance_SaveSpareData(obj)
            .then((res) => {
                hideLoading();
                if (res?.data?.isSuccess) {
                    showSaveSuccess();
                    navigate(-1);
                } else {
                    console.log(res.data);
                    showSaveError();
                }
            })
            .catch((err) => {
                console.log(err);
                hideLoading();
            });
    };

    const updateJobStatus = (status) => {
        let tableData = $("#PeriodicUGStation").DataTable().rows().data().toArray();
        if (tableData?.length) {
            handleSubmit(tableData[0], status);
        }
    };

    const getCameraImagePath = (objString = "") => {
        
        if (objString) {
            let imageObj = JSON.parse(objString);
            let FilePaths = localStorage.getItem("FilePaths");
            if (FilePaths) {
                FilePaths = JSON.parse(FilePaths);
            } else FilePaths = [];
            let CopyFileObj = DeepCopy(FilePaths);
            if (!Array.isArray(CopyFileObj)) {
                CopyFileObj = [];
            }
            imageObj[0].Type = "SELFIE";
            CopyFileObj = CopyFileObj.concat(imageObj);

            localStorage.setItem("FilePaths", JSON.stringify(CopyFileObj));
            setFileObj((pre) => ({
                ...pre,
                FilePaths: [...CopyFileObj],
            }));
        }
    };

    const CameraModel = () => {
        return (
            <>
                <WebCam HandleChange={getCameraImagePath} />
            </>
        );
    };

    const RemarksBtn = () => {
        let btn = $(
            `<button class='btn btn-primary m-l-5'  data-bs-toggle="modal" data-bs-target="#OverAllRemarksComponent" title='Show OverAll Remarks' >`
        );
        let RemarksIcon = $(`<i class="fa fa-comments-o" aria-hidden="true"></i>`);

        btn.on("click", (e) => {
            e.preventDefault();
            getOverAllRemarks();
        });

        btn.append(RemarksIcon);
        return btn;
    };

    const OverAllRemarksComponent = () => {
        return (
            <>
                <div class="chats">
                    {OverAllRemarksList?.length
                        ? OverAllRemarksList.map((ele) => {
                            console.log(ele)
                            return (
                                <>
                                    <div class="chat-line">
                                        <span class="chat-date">
                                            {ele.EmployeeName} - {ele.StatusName}
                                        </span>
                                    </div>
                                    <div class="chat chat-left">
                                        <div class="chat-body">
                                            <div class="chat-bubble">
                                                <div class="chat-content">

                                                    <p className="text-black text-sm">{ele.Remarks?.length > 0 ? ele.Remarks : "-"} </p>

                                                    <span class="chat-time pull-right text-small" style={{ fontSize: "8px" }}>
                                                        {ele.FormattedDate}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                        : ""}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row d-flex justify-content-between">
                        <div className="col-sm-3 mb-2 text-start">
                            <button
                                className="btn btn-success"
                                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
                                onClick={() => navigate(-1)}
                            >
                                <RiArrowGoBackLine /> Go Back
                            </button>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="label" for="empCode">
                                    PWT Number
                                </label>{" "}
                                <input
                                    className="form-control PWTNumber"
                                    type="text"
                                    name="PWTNumber"
                                    disabled={getUserDetails().RoleID !== getL0RoleID()}
                                    value={PWTNumber}
                                    onChange={CommonHandleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="label" for="empCode">
                                    PWT Date
                                </label>{" "}
                                <input
                                    className="form-control PWTDate"
                                    type="date"
                                    name="PWTDate"
                                    disabled={getUserDetails().RoleID !== getL0RoleID()}
                                    onChange={CommonHandleChange}
                                    value={PWTDate}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="label" for="empCode">
                                    EquipmentCode{" "}
                                </label>{" "}
                                <Dropdown
                                    className="form-control"
                                    data={EquipmentDetails ?? []}
                                    name="EquipmentDetailId"
                                    value={EquipmentDetailId}
                                    HandleChange={CommonHandleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="label" for="empCode">
                                    Remarks
                                </label>{" "}
                                <textarea
                                    className="form-control OverAllRemarks"
                                    name="ApprovalRemarks"
                                    style={{ resize: "none" }}
                                    rows={2}
                                    disabled={getUserDetails().RoleID == getL0RoleID()}
                                    onChange={CommonHandleChange}
                                // value={}
                                >
                                    {" "}
                                </textarea>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="label" for="empCode">
                                    Upload{" "}
                                </label>{" "}
                                <input
                                    type="file"
                                    className="form-control"
                                    name="FileObj"
                                    multiple
                                    HandleChange={CommonHandleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "-25px" }}>
                        <HtmlTable
                            IsASC
                            FixedColums={{
                                left: 1,
                                right: 2,
                            }}
                            OrderTargetList={Array.from(
                                { length: UGSColumnList.length - 6 },
                                (_, i) => i + 4
                            )}
                            rowList={UGSRowList}
                            columnList={UGSColumnList}
                            id={`PeriodicUGStation`}
                            SearchPostponedHtml={RemarksBtn}
                            // addedPlugin={addedPlugin}
                            tableReRender={TestNo}
                            orderby={0}
                        />
                    </div>
                    <div className="card card-body">
                        {/* <h4 className='text-center'> Used Spare</h4> */}
                        <SpareList
                            IsAdd={getUserDetails().RoleID == getL0RoleID()}
                            Equlist={equipmentDetailsList}
                            saveSpare={saveSpare}
                            SelectedSpareList={spareList}
                        ></SpareList>
                        <br></br>
                        {getUserDetails().RoleID == getL0RoleID() && (
                            <div className="row d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary col-md-2 col-sm-2"
                                    onClick={SaveSpareData}
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                    <BootrapModel
                        module={<CameraModel />}
                        ModelTitle={"Selfie"}
                        modelSize={"modal-lg"}
                        CloseID={"CloseSelfiePeriodicModel"}
                        Id={"SelfiePeriodicModel"}
                    ></BootrapModel>

                    <BootrapModel
                        module={<OverAllRemarksComponent />}
                        ModelTitle={"Over All Remarks"}
                        modelSize={"modal-lg"}
                        CloseID={"CloseOverAllRemarksComponent"}
                        Id={"OverAllRemarksComponent"}
                    ></BootrapModel>
                </div>
            </div>
        </>
    );
}

export default PeriodicDetailsView;
