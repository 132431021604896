// 1: Uncontrolled Tree
import { useRef, useState } from "react";
import { Tree } from "react-arborist";
import Node from "./NodeCreater";
import { RiMenuAddFill } from "react-icons/ri";

const ReactTreeView = ({
  data,
  CreateDepartment,
  onRename,
  RenameSave,
  DeleteNode,
  onSelect,
  openByDefault,
  CrudISNeed,
  isSearchNeed,
  width = 400,
  height = 200,
  IsMultiselect,
  onCheckedNodesChange,
}) => {
  const [term, setTerm] = useState("");
  const treeRef = useRef(null);
  const [checkedNodes, setCheckedNodes] = useState({});

  return (
    <div>
      <div style={{ display: "flex" }}>
        {CrudISNeed && (
          <div className="">
            <button
              onClick={() => CreateDepartment()}
              className="btn btn-primary"
              title="Add new department"
            >
              <RiMenuAddFill />
            </button>
          </div>
        )}
        {isSearchNeed && (
          <div className="form-group m-l-15">
            <input
              className="form-control"
              type="search"
              placeholder="Search..."
              onChange={(e) => setTerm(e.target.value)}
              value={term}
            />
          </div>
        )}
      </div>
      <div className="col-6">
        {data?.length ? (
          <Tree
            ref={treeRef}
            data={data}
            width={width}
            openByDefault={openByDefault}
            height={height}
            className={`TreeContainer ${treeRef?.current?.visibleNodes.length > 0 ? "treeOpen": ""  }`}
            indent={24}
            rowHeight={32}
            disableDrag
            searchTerm={term}
            searchMatch={(node, term) =>
              node.data.name.toLowerCase().includes(term.toLowerCase())
            }
          >
            {({ ...restProps }) => (
              <Node
                {...restProps}
                Rename={onRename}
                RenameSave={RenameSave}
                onSelect={onSelect}
                DeleteNode={DeleteNode}
                CrudISNeed={CrudISNeed}
                IsMultiselect={IsMultiselect}
                // checkedNodes={checkedNodes}
                // setCheckedNodes={setCheckedNodes}
                onCheckedNodesChange={onCheckedNodesChange}
              />
            )}
          </Tree>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export { ReactTreeView };
