import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class SpareService {
  GetEquipmentStationList() {
    return axios
      .get("Spare/GetEquipmentStationList")

      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  GetSpareDetailsByID(spareDetailID = 0, IsDeleted, StationID, subsystemID) {
    return axios
      .get(
        `Spare/GetSpareDetailsByID?spareDetailID=${spareDetailID}&IsDeleted=${IsDeleted}&StationID=${StationID}&subsystemID=${subsystemID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  AddSpareDetails(data) {
    return axios.post("Spare/AddSpareDetails", data);
  }
  GetAutoCompleteData() {
    return axios
      .get("Spare/GetAutoCompleteData")

      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  SpareReport(StartDate, EndDate, StationID) {
    return axios
      .get(
        `Spare/SpareReport?StartDate=${StartDate}&&EndDate=${EndDate}&&StationID=${StationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  SpareReportHistoryDetails() {
    return axios.get(`Spare/SpareReportHistoryDetails`).then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    });
  }

  getDropDownList() {
    return axios
      .get("Spare/Spare_GetDropDownList")

      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  getSubsystemList() {
    return axios
      .get("Spare/Spare_GetSubsystemList")

      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
}
