import React, { useState, useContext } from "react";
import { useEffect } from "react";
import $ from "jquery";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { Dropdown } from "../../components/Dropdown";
import makeAnimated from "react-select/animated";
import {
  ReactMultiSelect,
  MultiValue,Option
} from "../../components/MultiSelect/ReactMultiSelect";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router-dom";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { PeriodicService } from "../periodic/PeriodicService";
const _breakdownService = new BreakdownMaintenanceService();
const animatedComponents = makeAnimated();
const obj = new BreakdownMaintenanceService();




 const columnNames = [
  { title: "Serial No", data: "RowId" },
  { title: "Station Code", data: "StationCode" },
  { title: "Equipment", data: "EquipmentShortName" },
  { title: "Equipment Code", data: "EquipmentCode" },
  { title: "Ticket No", data: "TicketNo" },
  { title: "Breakdown Date", data: "BreakdownDate" },
  { title: "Breakdown Time", data: "BreakdownTime" },
  { title: "Rectification Date", data: "ClosedDate" },
  { title: "Rectification Time", data: "RECTIFICATION_TIME" },
  { title: "Down Time ", data: "DownTime" },
  { title: "Maintenance Type ", data: "ModuleType" },
];


export const HistoryReport = () => {
  const { getCookies,handleGlobalStationName } = useContext(AppContext);

  const [fromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [StatusDropDown, setStatusDropDown] = useState([]);

  const [statusId, setStatusId] = useState(null);
  const [StationList, setStationList] = useState([]);
  const [EquipmentTagList, setEquipmentTagList] = useState([]);
  const [FilterEquipmentTagList, setFilterEquipmentTagList] = useState([]);
  const [StationID, SetStationID] = useState({});
  const [EquipmentIds, SetEquipmentIds] = useState(0);

  const [showButton, setShowButton] = useState(false);
  const [stationIdss, setStataionIds] = useState("");
  const [EqipmentList, setEquipmentList] = useState([]);
  const [FilterEquipmentList, setFilterEquipmentList] = useState([]);
  const [EqipmentID, setEquipmentID] = useState(0);
  const [PeriodicType, setPeriodicType] = useState(1);




  useEffect(() => {
    




    getStationList();
    // currently no need if need un command
     //GetStatus() 
    Report_GetJobEquipmentList()
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    setFromDate(firstDay.toISOString().split("T")[0]);
    setToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const  getStationList = async  () => {
    
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });

    HandleChange([dataLst[0]]);
    setStationList(dataLst);
  };

  // no need now
  const get_equipment_list = async (data) => {
    await obj.GetManualBreakdownData().then((res) =>{
        
      if (res?.data?.length > 0){
        const all_equipment =  res.data[0]
        all_equipment?.map((x) => {
          x.key = x.label  
          x.value = x.Value} )

        const  EquipmentTagList =  all_equipment.filter((x) => x.stationID == data.Value )
        setEquipmentTagList(EquipmentTagList)
        setFilterEquipmentTagList(all_equipment)
      }
    }).catch((err) => console.log(err))
  }


  const Report_GetJobEquipmentList = () => {
    _breakdownService
      .Report_HistoryDropDown()
      .then((data) => {
        
        var datas = [];
        if (data != null && data.data != null && data.data.length) {
          
          data.data.map((c, i) => {
            datas.push({ key: c.EquipmentShortName, value: c.EquipmentID,StationID :  c.StationID});
          });
          if (datas.length > 0) {
            datas.push({ key: "All Equipments", value: -1 });
          }

          var Station = Decrypt(getCookies(Global.COOKIES.Stations))[0];
          let EquipmentList = datas.filter((x) => {
             return x.StationID == Station.StationID
          })

          setEquipmentList(EquipmentList);
          setFilterEquipmentList(datas);
          // hideLoading();
        } else {
          setEquipmentList([]);
          // hideLoading();
        }
        if (datas.length > 0) {
          setEquipmentID(datas[0].value);
        }
      })
      .catch(
        (err) => console.log(err)
        // hideLoading()
      );
  };



  const HandleChange = (e,type = "STATION") => {
    
    if (type == "STATION"){
    SetStationID(e);
    
    if(e.hasOwnProperty("value")){
    const Equipment_list = FilterEquipmentList?.filter(tag => tag.StationID === e.value)
    setEquipmentList([...Equipment_list])
    var selectedOption = e.label
    handleGlobalStationName(selectedOption ?? "");
    }
else{
  handleGlobalStationName("");
  setEquipmentList([])
}

    }
    else if(type == "EQUIPMENT") {
      setEquipmentID(e.target.value)
    }
    else if(type == "EQUIPMENTTAGNO") {
      SetEquipmentIds(e.target.value)
    }
    else if(type == "PERIODICTYPE") {
      setPeriodicType(e.target.value)
    }

  };

  const onFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const onToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const onclear = () => {
    $("#FromDate").val(null);
    $("#ToDate").val(null);
    setFromDate("");
    setToDate("");
  };


  // no need now
  const GetStatus = () => {
    _breakdownService.GetStatusList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        var status = [];

        res.data.map((v) => {
          status.push({ key: v.statusName, value: v.statusID });
        });
        setStatusDropDown(status);
      }
    });
  }

  useEffect(() => {
    var ID = StationID.value

    setStataionIds(ID);

  }, [fromDate, ToDate, StationID,EquipmentIds,statusId]);

  return (
    <div>
      <Header
        title={"Mean Time Between Failure Report"}
        stationchange={HandleChange}
        EquipmentTagChange={HandleChange}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        stationList={StationList}
        EquipmentTagList={EquipmentTagList}
        stationIDs={StationID}
        statusId={statusId}
        EquipmentIds={EquipmentIds}
        fromDate={fromDate}
        ToDate={ToDate}
        StatusDropDown={StatusDropDown}
        onclear={onclear}
        showButton={showButton}
        EqipmentList={EqipmentList}
        EqipmentID={EqipmentID}
        periodicType={PeriodicType}
      />
      <div className="card card-body">
        <DataTableReportAjax
          columnList={columnNames}
          id={"HistoryTable"}
          title={"History Card Report"}
          isAsc={true}
          IsShowPDF={false}
         
          FromDate={fromDate}
          ToDate={ToDate}
          url={`${BASE_URL}BreakdownMaintenance/GetHistoryReport`}
          StationID={StationID?.value ?? "0"}
          EquipmentCode = {EquipmentIds ? EquipmentIds : "0"}
          // BreakdownStatus={statusId ? statusId : "0" }
          EquipmentID={EqipmentID}
          Type={PeriodicType}
          // rowList={[]}
          ExcelClass="CSVButton"
          pageLength={30}
          
        />
      
      </div>
    </div>
  );
};

const Header = ({
  title,
  onFromDateChange,
  stationList,
  EqipmentList,
  EqipmentID,
  EquipmentTagList,
  stationIDs,
  stationchange,
  EquipmentTagChange,
  onToDateChange,
  fromDate,
  ToDate,
  StatusDropDown,
  showButton,
  periodicType
}) => {

  const periodicTypeList = [
      { key: "Daily", value: 1 },
      { key: "Monthly", value: 2 },
      { key: "Quarterly", value: 3 },
      { key: "Half-Yearly", value: 4 },
      { key: "Yearly", value: 5 },
      { key: "All Type", value: -1 }
  ]


  useEffect(() => {
    $(function () {

      if (fromDate != null) {
        $("#ToDate").attr("min", fromDate);
      }
      if (ToDate != null) {
        $("#FromDate").attr("max", ToDate);
      }
    });
  }, [fromDate, ToDate]);





  return (
    <div className="page-header mb-3">
      <div className="row justify-content-end">
        <div className="col-12">
          <h3 className="page-title">{title}</h3>

        </div>
        <div className="col-12 row">
        <div className="col-2">
          <label className="label" for="">
            From Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            id="FromDate"
            pattern="\d{4}-\d{2}-\d{2}"
            defaultValue={fromDate}
            onChange={(e) => {
              onFromDateChange(e);
            }}
          />
        </div>
        <div className="col-2">
          <label className="label" for="">
            To Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            defaultValue={ToDate}
            id="ToDate"
            onChange={(e) => {
              onToDateChange(e);
            }}
          />
        </div>
       
        <div className="col-2">
          <label className="label">Stations</label>
          <ReactMultiSelect
            options={stationList}
            // isMulti
            value={stationIDs}
            hideSelectedOptions={false}
            onChange={(e) => stationchange(e,'STATION')}
          
          />
        </div>
        
        <div className="col-2">
            <label className="label">Equipment</label>
            <Dropdown
              placeholder="--Select Equipment--"
              name="equipment"
              data={EqipmentList}
              // initialVal={0}

              HandleChange={(e) => {EquipmentTagChange(e,"EQUIPMENT")}}
              value={EqipmentID}
            />
          </div>

          { /* no need now /*}
    {/* <div className="col-2">
                <label className="-label">Type</label>
                <Dropdown
                  placeholder="--Select Type--"
                  name="type"
                  data={periodicTypeList}
                  // initialVal={0}
                  HandleChange={(e) => {EquipmentTagChange(e,"PERIODICTYPE")}}
                  value={periodicType}
                />
              </div> 

    <div className="col-2">
            <label className="label">Equipment Tag No</label>

              <WithSelectDropDown
                placeholder={"Select Equipment Tag No"}
                name={"equipment_code"}
                data={EquipmentTagList}
                initialVal={0}
                HandleChange={(e) => {EquipmentTagChange(e,"EQUIPMENTTAGNO")}}
              />
            </div> */}

        </div>
      </div>
    </div>
  );
};
