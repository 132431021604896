import React, { useEffect, useContext, useState, Suspense, lazy } from "react";
import { EquipmentLiveTrends } from "./EquipmentLiveTredens";
import { FaultInfo } from "./FaultInfo";
import { Spare } from "./Spare";
import { DashboardHeader } from "./widgets/DashboardHeader";
import { useLocation } from "react-router";
import { COOKIES } from "../../services/Global";
import { AppContext } from "./../../services/ContextProvider";
import $ from "jquery";
import { TabBar } from "../../components/TabBar";
import Periodic from "./Periodic";
import DashboardContextProvider from "./DashboardContext/DashboardContext";
import { PerformanceEvaluation } from "./PerformenceEvaluation/PerformanceEvaluation";

import DashboardService from "./DashboardService";

import StationStatus from "./StationStatus";

import BootrapModel from "../../components/BootrapModel";
import { Decrypt } from "../../services/CommonService";

export const Dashboard = () => {
  const { getCookies} = useContext(AppContext);
  const [TabInitialVal, setTabInitialVal] = useState(0);
  const [StationList, setStationList] = useState("");
  const [IsPh1, setIsPh1] = useState(false);

  const location = useLocation();

  const TabHeader = [
    "Equipment Trends",
    "Fault Info",
    "Spare",
    "Periodic Schedule Info",
  ];
  if (!IsPh1) {
    TabHeader.push("Performance Evaluation");
  }

  const TabBody = [
    <EquipmentLiveTrends key={0} TabID={0} TabValue={TabInitialVal} />,
    <FaultInfo key={1} TabID={1} TabValue={TabInitialVal} />,
    <Spare key={2} TabID={2} TabValue={TabInitialVal} />,
    <Periodic key={3} TabID={3} TabValue={TabInitialVal} />,
   
  ];
  if (!IsPh1) {
    TabBody.push(
      <PerformanceEvaluation/>
    );
  }

 

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;
      if (val.Type != undefined && val.Type != null) {
        setTabInitialVal(parseInt(val.Type));
      } else {
        setTabInitialVal(0);
      }
    }
    const stations = Decrypt(getCookies(COOKIES.Stations)) ?? "";
    setStationList(stations);
    if (stations?.length) {
      const isValidStationList = stations?.every((element) =>
        [1, 2, 3].includes(element.StationID)
      );
      setIsPh1(isValidStationList);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("ID") == 0) {
      localStorage.setItem("ID", 1);
      
      window.location.reload();
    }
  }, []);

  const onTabchange = (e) => {
    setTabInitialVal(e - 1);
  };

  return (
    <>
      {/* Title and Filters */}
      {/* <DashboardHeader /> */}
      <DashboardContextProvider>
        <div className="card">
          <TabBar
            header={TabHeader}
            body={TabBody}
            InitialValue={TabInitialVal}
            onTabChange={onTabchange}
          />
        </div>
      </DashboardContextProvider>
      {/* Tab bar header*/}
      {/* <TabHeader /> */}

      {/* Tab bar content */}
      {/* <TabContent/> */}
    </>
  );
};
export const IconButton = ({ TabValue }) => {
  const [stationStatusList, setStationStatusList] = useState([]);

  useEffect(() => {
    getStationColorDetailsList();
  }, []);

  const DS = new DashboardService();

  const getStationColorDetailsList = async () => {
    await DS.DashBoard_getStationColorDetailsList().then((result) => {

      setStationStatusList(result.data);
    });
  };

  return (
    <>
      <button
        type="button"
        title="StationStatus"
        class="btn"
        data-bs-toggle="modal"
        data-bs-target={`#stationStatusModal_${TabValue}`}
      >
        {" "}
        <i class="fa fa-info-circle"></i>{" "}
      </button>

      <BootrapModel
        Id={`stationStatusModal_${TabValue}`}
        CloseID="closestationStatusModal"
        //closepopup={() => setStationStatusList([])}

        // ModelTitle={`Breakdown Maintenance Edit- ${TicketDetailes.ticketNo}`}

        modelSize={"modal-xl"}
        module={<StationStatus StationStatusList={stationStatusList ?? []} />}
      >
        {" "}
      </BootrapModel>
    </>
  );
};
