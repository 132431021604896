import React, { useEffect, useState, useContext, useRef } from "react";
import {
  getYMDdate,
} from "../../services/CommonService";
import { PeriodicService } from "./PeriodicService";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";

import { getUserDetails } from "../../services/CommonService";
import { Decrypt } from "../../services/CommonService";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import { HoverModal } from "./HoverModal";
import { PeriodicColor } from "../../services/CommonService";

import { CalenderDailyView } from "./CalenderDailyView";
import CalendarMonthView from "./CalendarMonthView";
import CalenderQuaterView from "./CalenderQuaterView";
import CalendarHalfyearlyView from "./CalendarHalfyearlyView";
import CalenderYearlyView from "./CalenderYearlyView";
import { useLocation } from "react-router-dom";

const localizer = globalizeLocalizer(globalize);

const _periodicServices = new PeriodicService();

//Quater months
const quaterMonthLst = [0, 3, 6, 9];

//Half yearly months
const halfYearlyMonthLst = [5];

//Yearly months
const yearlyMonthLst = [0];

export const PeriodicMaintance = () => {
  const [events, setEvents] = useState([]);
  const [periodicSchedule, setPeriodicSchedule] = useState([]);
  const [stationList, setStationList] = useState([]);
  const navigate = useNavigate();
  const {
    getCookies,
    setCookies,
    showLoading,
    hideLoading,
    refreshCal,
    handleGlobalStationName,
  } = useContext(AppContext);
  const getPeriodicStationID = getCookies(COOKIES.PeriodicStationID);
  const periodicStationID =
    getPeriodicStationID != null ? getPeriodicStationID : 0;
  const hoverModalOpenRef = useRef();
  const [hoverModalData, setHoverModalData] = useState(null);
  const [rescheduledData, setRescheduledData] = useState(null);
  const [jobListForMonth, setjobListForMonth] = useState(null);
  const eqpt = [];
  const location = useLocation();
  const { view } = location.state != null ? location.state : "";
  const [View, setView] = useState("month");

  const [currentDate, setCurrentDate] = useState(getYMDdate(new Date()));
  const [JobPendingDate, setJobPendingDate] = useState([]);
  const [curDate, setCurDate] = useState("");

  useEffect(() => {
    if (view != undefined && view != null && view != "") {
      setView(view);
    } else {
      setView("month");
    }
    return () => {
      setView("")
    }
  }, [view]);

  useEffect(() => {
    var curDate = localStorage.getItem(COOKIES.CalendarMonth);
    var d;
    if (curDate != null) {
      d = new Date(curDate);
    } else {
      d = new Date();
      localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(new Date()));
    }
    getStationList();

    return () => {
      setStationList([])
    }


  }, [refreshCal, getCookies(COOKIES.PeriodicStationID)]);

  useEffect(() => {
    hoverModalData != null && hoverModalOpenRef.current.click();
  }, [hoverModalData]);

  useEffect(() => {
    const buttons = document.querySelectorAll("button"); // Select all buttons

    buttons.forEach((button) => {
      if (button.textContent === "Back") {
        button.style.float = "left"; // Apply your custom styles
        // You can apply any other styles as needed
      }
    });
    var currentMonth, currentYear, todayDate;
    var curDate = localStorage.getItem(COOKIES.CalendarMonth);
    // setCurDate(curDate)
    var d;
    if (curDate != null) {
      d = new Date(curDate);
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    } else {
      d = new Date();
      localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(new Date()));
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    }
    // var curDate = localStorage.getItem(COOKIES.CalendarMonth) ? localStorage.getItem(COOKIES.CalendarMonth) : localStorage.setItem(COOKIES.CalendarMonth, new Date());
    _periodicServices
      .JobSchedulePendingDate(
        currentMonth,
        currentYear,
        todayDate,
        1,
        parseInt(getPeriodicStationID)
      )
      .then((res) => {
        if (res != null && res.data != null && res.data != undefined) {
          // console.log(res.data),
          setJobPendingDate([...res.data]);
        }
      });
  }, [localStorage.getItem(COOKIES.CalendarMonth), getPeriodicStationID]);

  function jobSchedulePendingdata(currentMonth, currentYear, todayDate) {
    showLoading();
    _periodicServices
      .JobSchedulePendingDate(
        currentMonth,
        currentYear,
        todayDate,
        1,
        parseInt(getPeriodicStationID)
      )
      .then((res) => {
        hideLoading();
        if (res != null && res.data != null && res.data != undefined) {
          // console.log(res.data),
          setJobPendingDate([...res.data]);
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  }

  const getStationList = () => {
    var stations = Decrypt(getCookies(Global.COOKIES.Stations));
    var stationsdropdown = [];

    stations.map((data) => {
      stationsdropdown.push({ value: data.StationID, key: data.StationCode });
    });
    setStationList(stationsdropdown);
    // _periodicServices.GetStationList().then((data) => {
    //   if (data != null && data.length > 0) {
    //     var stations = [];
    //     data.map((v) => {
    //       stations.push({ value: v.stationID, key: v.stationCode });
    //     });

    //     setStationList(stations);
    //   }
    // });
  };

  const getJobDataByDate = (d, scheduleData) => {
    var stationID = getCookies(COOKIES.PeriodicStationID);
    var month = new Date().getMonth() + 1;
    _periodicServices.GetJobSchedules(d, stationID, 1, month).then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        setHoverModalData({
          jobList: res.data,
          periodicMaintenance: scheduleData,
        });
      } else {
        setHoverModalData({
          jobList: [],
          periodicMaintenance: scheduleData,
        });
      }
    });
  };

  const calendarStyle = (date) => {
    // let currentDate = `${new Date().getDate()} ${new Date().getMonth() + 1} ${new Date().getFullYear()}`
    let allDate = `${date.getDate()} ${
      date.getMonth() + 1
    } ${date.getFullYear()}`;

    let currentDate = getYMDdate(date);
    let todayDate = getYMDdate(new Date());
    let start_check_date, end_check_date;
    // console.log(currentDate)
    if (JobPendingDate?.length > 0) {
      let check_currentDate = new Date(JobPendingDate[0].JobDate);
      start_check_date = getYMDdate(
        new Date(
          check_currentDate.getFullYear(),
          check_currentDate.getMonth(),
          1
        )
      );
      end_check_date = getYMDdate(
        new Date(
          check_currentDate.getFullYear(),
          check_currentDate.getMonth() + 1,
          0
        )
      );
    }

    if (
      JobPendingDate != null &&
      JobPendingDate.length > 0 &&
      JobPendingDate.some((x) => x.JobDate == currentDate)
    ) {
      let CurrentObj = JobPendingDate.find((x) => x.JobDate == currentDate);

      if (currentDate < todayDate && CurrentObj.CheckAnyJob) {
        return {
          style: {
            backgroundColor: "orange",
            // border: '1px solid gray',
            margin: 0,
            padding: 0,
          },
        };
      }
    } else if (
      start_check_date <= currentDate &&
      currentDate < end_check_date
    ) {
      return {
        style: {
          backgroundColor: "green",
          // border: '1px solid gray',
          margin: 0,
          padding: 0,
        },
      };
    }
  };

  const getDefaultDate = () => {
    let date = localStorage.getItem(COOKIES.CalendarMonth);

    if (date !== null) {
      return new Date(date);
    }

    const currentDate = new Date();
    localStorage.setItem(
      COOKIES.CalendarMonth,
      getYMDdate(currentDate.toString())
    );
    return currentDate;
  };

  // const CustomNavigateDaily = (date, view, action, ...arg) => {
  //   console.log(arg);

  //   if (view == "month") {
  //     if (localStorage.getItem(COOKIES.CalendarMonth) == null) {
  //       localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(date));
  //     }
  //     var d = localStorage.getItem(COOKIES.CalendarMonth);

  //     var dLst = d.split("-");
  //     var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
  //     switch (action) {
  //       case Navigate.PREVIOUS:
  //         newD.setMonth(newD.getDate() - 1);

  //         localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newD));
  //         break;
  //       // return newD;

  //       case Navigate.NEXT:
  //         newD.setMonth(newD.getDate() + 1);
  //         localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newD));
  //         break;
  //       // return newD;

  //       default:
  //         newD = new Date();
  //         localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newD));
  //         break;
  //     }
  //     console.log(newD.getMonth() + 1);
  //     jobSchedulePendingdata(
  //       newD.getMonth() + 1,
  //       newD.getFullYear(),
  //       getYMDdate(new Date())
  //     );

  //     return newD;
  //   }
  // };

  const CustomNavigateDaily = (date, view, action) => {
    var newDate = date;
    if (view == "month") {
      // Insert your custom behavior here...
      if (localStorage.getItem(COOKIES.CalendarMonth) == null) {
        localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newDate));
      }
      // newDate = newDatelocalStorage.getItem(COOKIES.CalendarMonth)
      // If you still want the default behavior after your custom behavior:
      switch (action) {
        case "NEXT":
          newDate.setDate(newDate.getDate() + 1);

          break;
        case "PREV":
          newDate.setDate(newDate.getDate() - 1);
          break;
        // Handle other actions like 'TODAY' or view changes if needed
        default:
          newDate = new Date();
      }

      localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newDate));
      jobSchedulePendingdata(
        newDate.getMonth() + 1,
        newDate.getFullYear(),
        getYMDdate(new Date())
      );
      return newDate; // Return the new date
    }
  };

  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-9">
            <h3 className="page-title">Periodic Maintenance</h3>
          </div>
          <div className="col-3">
            <select
              className="form-select"
              onChange={(e) => {
                setCookies(COOKIES.PeriodicStationID, e.target.value);
                handleGlobalStationName(
                  e.target.selectedOptions[e.target.selectedIndex] ?? ""
                );
              }}
            >
              {stationList != null && stationList.length > 0 ? (
                stationList.map((v, i) => {
                  if (periodicStationID == 0) {
                    setCookies(COOKIES.PeriodicStationID, v.value);
                    handleGlobalStationName(v.key);

                    return (
                      <option key={v.key} value={v.value} selected>
                        {v.key}
                      </option>
                    );
                  } else {
                    if (periodicStationID == v.value) {
                      handleGlobalStationName(v.key);
                      return (
                        <option key={v.key} value={v.value} selected>
                          {v.key}
                        </option>
                      );
                    }
                    return (
                      <option key={v.key} value={v.value}>
                        {v.key}
                      </option>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="card card-body  mt-4">
        <Calendar
          localizer={localizer}
          views={{
            month:
              getUserDetails().RoleID == COOKIES.TechRoleID
                ? CalenderDailyView
                : true,
            monthly: CalendarMonthView,
            quaterly: CalenderQuaterView,
            halfYearly: CalendarHalfyearlyView,
            yearly: CalenderYearlyView,
          }}
          view={View ? View : "month"}
          onView={(view) => {
            setView(view);
          }}
          messages={{
            month: "Daily",
            monthly: "Monthly",
            quaterly: "Quarterly",
            halfYearly: "Half-Yearly",
            yearly: "Yearly",
          }}
          defaultDate={getDefaultDate()}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{
            height: getUserDetails().RoleID == COOKIES.TechRoleID ? 700 : 600,
          }}
          selectable={true}
          onSelectSlot={(e) => {
            var d = e.start;
            // var d = new Date();

            var month = d.getMonth() + 1;
            var day = d.getDate();

            var output =
              d.getFullYear() +
              "-" +
              (month < 10 ? "0" : "") +
              month +
              "-" +
              (day < 10 ? "0" : "") +
              day;

            var newDate = new Date(
              d.getFullYear(),
              d.getMonth(),
              d.getDate()
            ).toLocaleDateString();

            var date = output.replaceAll("/", "-");
            localStorage.setItem("dailyDate", date);
            navigate("/DailyCalender");
          }}
          onSelectEvent={(event) => {
            if (
              event != null &&
              event.scheduleData != null &&
              event.scheduleData.length > 0
            ) {
              var dateLst = event.date.split("/");
              var newDate = `${dateLst[2]}-${
                dateLst[0] <= 9 ? "0" + dateLst[0] : dateLst[0]
              }-${dateLst[1] <= 9 ? "0" + dateLst[1] : dateLst[1]}`;
              getJobDataByDate(newDate, event.scheduleData);
            }
          }}
          onNavigate={CustomNavigateDaily}
          dayPropGetter={calendarStyle}
          eventPropGetter={(event, start, end, isSelected) => {
            var index = -1;
            if (event != null && event.title != null && event.title != "") {
              var equipmentName = event.title.split(":")[0];

              if (eqpt.includes(equipmentName)) {
                index = eqpt.indexOf(equipmentName);
              } else {
                eqpt.push(equipmentName);
                index = eqpt.indexOf(equipmentName);
              }
            }

            var bgStyle = { backgroundColor: PeriodicColor[index] };
            return {
              event,
              start,
              end,
              isSelected,
              style: bgStyle,
            };
          }}
        />
        {/* Open Hover modal */}
        <button
          type="button"
          hidden
          data-bs-toggle="modal"
          data-bs-target="#hover-periodic"
          ref={hoverModalOpenRef}
        ></button>
        <HoverModal hoverModalData={hoverModalData} />
      </div>
    </>
  );
};
