import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showErrorMsg,
  showWarningMsg,
  showDeleteError,
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import $, { data } from "jquery";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import { showDeleteSuccess } from "./../../components/ToastMsg";

const _masterDetailService = new MasterDetailService();

// Add form
const AddForm = ({ onSubmit, editData, currentMasterTab }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editData != null) {
      setValue("parameterID", editData.ParameterID, { shouldValidate: true });
      setValue("parameterName", editData.ParameterName, {
        shouldValidate: true,
      });
      setValue("parameterUnit", editData.ParameterUnit, {
        shouldValidate: true,
      });
    }
  }, [editData]);

  useEffect(() => {
    reset();
  }, [currentMasterTab]);

  const onsubmit = (data) => onSubmit(data, reset);

  return (
    <div className="mb-3">
      <form onSubmit={handleSubmit(onsubmit)}>
        <div className="row d-flex justify-content-end">
          {/* Parameter ID*/}
          <input
            type="text"
            className="form-control"
            placeholder="Parameter ID"
            name="parameterID"
            hidden
            defaultValue={0}
            {...register("parameterID", { required: false })}
          />
          {/* Parameter Name*/}
          <div className="col-3">
            <label className="col-form-label">
              Parameter Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Parameter Name"
              name="parameterName"
              {...register("parameterName", {
                required: "Parameter name is required",
                validate: (value) =>
                  !!value.trim() || "Parameter name cannot be empty",
              })}
            />
            {errors.parameterName && (
              <p className="text-danger mt-1">{errors.parameterName.message}</p>
            )}
          </div>
          {/* Parameter Unit*/}
          <div className="col-3">
            <label className="col-form-label">
              Parameter Unit <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Parameter Unit"
              name="parameterUnit"
              {...register("parameterUnit", {
                required: "Parameter unit is required",
                validate: (value) =>
                  !!value.trim() || "Parameter unit cannot be empty",
              })}
            />
            {errors.parameterUnit && (
              <p className="text-danger mt-1">{errors.parameterUnit.message}</p>
            )}
          </div>
          <div className="col-2" style={{ marginTop: "2.5rem" }}>
            <AddButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export const Parameter = ({ TabID, TabValue }) => {
  //variables
  const { showLoading, hideLoading } = useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const { currentMasterTab } = useContext(AppContext);

  //Parameter table columns
  const columnNames = [
    { title: "Parameter ID", className: "hide" },
    { title: "Parameter Name" },
    { title: "Parameter Unit" },
    { title: "Status", visible: false },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        if (getPrivilege().includes("MASTEREDIT")) {
          b = $(`<button class='btn icon-btn' title='Edit'>
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
            onEditClick(rowData[0]);
            window.scroll(0,0);

          });
        }

        if (getPrivilege().includes("MASTERDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
          <i class='fa fa-trash m-l-15'></i>
        </button>`).on("click", function () {
            onDeleteClick(rowData[0]);
          });
        }

        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  //functions
  useEffect(() => {
    // get parameter list
    if (TabID == TabValue) {
      getData(0, false);
    }
  }, [currentMasterTab]);

  const getData = (parameterID, isDelete) => {
    _masterDetailService
      .GetParameter(parameterID, isDelete)
      .then((response) => {
        if (
          response != null &&
          response.isSuccess &&
          response.data != null &&
          response.data.length > 0
        ) {
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.ParameterID);
            row.push(v.ParameterName);
            row.push(v.ParameterUnit);
            row.push(v.IsActive ? "Active" : "In-Active");
            row.push("");
            rowList.push(row);
          });
          setTableRows(rowList);
        } else {
          setTableRows([]);
        }
      });
  };

  const onSubmit = (e, reset) => {
    var checkAlreadyExists = [];

    if (Number(e.parameterID) == 0) {
      checkAlreadyExists = tableRows.filter(
        (v) => v[1].toLowerCase().trim() == e.parameterName.toLowerCase().trim()
      );
    } else {
      checkAlreadyExists =  [] 
      // tableRows.filter(
      //   (v) =>
      //     v[1].toLowerCase().trim() == e.parameterName.toLowerCase().trim() &&
      //     Number(v[0]) != Number(e.parameterID)
      // );
    }
    if (checkAlreadyExists == [] || checkAlreadyExists.length == 0) {
      showLoading();
      var data = {
        ParameterID: Number(e.parameterID),
        ParameterName: e.parameterName,
        ParameterUnit: e.parameterUnit,
        IsActive: true,
        SavedBy: GetLoginUserID(),
      };

      _masterDetailService.AddParameter(data).then((response) => {
        hideLoading();
        if (response == null || response == 0) {
          showSaveError();
        } else if (!response.isSuccess) {
          showSaveError();
        } else {
          reset();
          getData(0, false);
          showSaveSuccess();
        }
      });
    } else {
      showWarningMsg("Parameter name already exists");
    }
  };

  const onEditClick = (id) => {
    _masterDetailService.GetParameter(id, false).then((response) => {
      if (
        response != null &&
        response.isSuccess &&
        response.data != null &&
        response.data.length > 0
      ) {
        setEditData(response.data[0]);
      } else {
        showErrorMsg("Failed to fetch record");
      }
    });
  };

  const onDeleteClick = (id) => {
    _masterDetailService.GetParameter(id, true).then((res) => {
      if (res != null && res.isSuccess) {
        showDeleteSuccess();
        getData(0, false);
      } else {
        showDeleteError();
      }
    });
  };
  return (
    <>
      {getPrivilege().includes("MASTERCREATE") && (
        <AddForm
          editData={editData}
          onSubmit={onSubmit}
          currentMasterTab={currentMasterTab}
        />
      )}

      <HtmlTable
        columnList={columnNames}
        rowList={tableRows}
        id={"ParameterTable"}
      />
    </>
  );
};
