import React from "react";
import { useState, useEffect, useContext } from "react";
import { ParentTable } from "./ParentTable";
import SCADAConfigService from "./SCADAConfigService";
import { AppContext } from "../../services/ContextProvider";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import MasterDetailService from "../master/MasterDetailService";
import BootrapModel from "../../components/BootrapModel";
import AddScada from "./AddScada"

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { PageHeader } from "../../components/PageHeader";
import Global ,{COOKIES}from "../../services/Global";
import { Decrypt, getPrivilege, makeUnique ,getUserDetails} from "../../services/CommonService";
import { TabBar } from "../../components";

export const SCADAConfig = () => {
  const SCADAConfigServiceobj = new SCADAConfigService();
  const MDS = new MasterDetailService();

  const [rowList, setRowList] = useState({
    trend: [],
    digital: [],
  });
  const [StationList, setStationList] = useState([]);
  const [StatusList, setStatusList] = useState([]);
  const [AlaramStatus, setAlaramStatus] = useState([]);
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [StationID, SetStationID] = useState(0);
  const [FrequencyNameList, SetFrequencyNameList] = useState([]);
  const [SystemTypeList, setSystemTypeList] = useState([]);
  const [equipmentShortNameList,SetEquipmentShortNameList]=useState([])
  const [parameterList,SetParameterList]=useState([])
 const[equipmentCodeList,SetEquipmentCodeList] =useState([])
 const[scadaFlag,SetScadaFlag]=useState(false)

  const TableColumns = [
    {
      className: "dt-control",
      orderable: false,
      data: null,
      defaultContent: "",
      width: "2.06rem",
    },
    { title: "Equipment Detail ID", className: "hide" },
    { title: "Equipment Tag Number" },
    { title: "Equipment ID", className: "hide" },
  ];

  useEffect(() => {
    getStationList();
    GetEquipmentShortName()
    GetEquipmentCode()
    GetParameterList()
  }, []);
  const GetParameterList = () => {
    SCADAConfigServiceobj.GetParameterList()
    .then((result) => {
      console.log(result.data,'Parameter')
      SetParameterList(result.data)
     
    })
  }
  const GetEquipmentShortName = () => {
    SCADAConfigServiceobj.GetEquipmentShortName()
    .then((result) => {
      
      SetEquipmentShortNameList(result.data)
    })
  }
  const GetEquipmentCode = () => {
    SCADAConfigServiceobj.GetEquipmentCode()
    .then((result) => {
      console.log(result.data,'COde')
      SetEquipmentCodeList(result.data)
    })
  }

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, key: data.StationCode });
    });
    setStationList(dataLst);
    SetStationID(dataLst[0].value);
    handleGlobalStationName(dataLst[0].key ?? "");
  };

  useEffect(() => {
    showLoading();

    if (StationID > 0) {
      SCADAConfigServiceobj.GetEquipmentDetailesUsingStaionID(StationID)
        .then((result) => {
          
          hideLoading();
          var res = result.data;
          var eqptCodes = [];
          var eqptCodesforDigital = [];

          let filteredData = res.equipmentLists.filter(
            (item) => item.alaramORTrand != 36
          );
          let nonFilteredData = res.equipmentLists.filter(
            (item) => item.alaramORTrand == 36
          );

          var { equipmentLists } = res;
          const unique = equipmentLists
            .map((item) => item.systemTypeName)
            .filter((value, index, self) => self.indexOf(value) === index);
          setSystemTypeList(unique);
          filteredData = makeUnique(filteredData, [
            "equipmentDetailID",
            "equipmentCode",
            "equipmentID",
            "systemTypeName",
          ]);
          nonFilteredData = makeUnique(nonFilteredData, [
            "equipmentDetailID",
            "equipmentCode",
            "equipmentID",
            "systemTypeName",
          ]);

          filteredData.map((v) => {
            let eqptCode = [];
            eqptCode.push("");
            eqptCode.push(v.equipmentDetailID);
            eqptCode.push(v.equipmentCode);
            eqptCode.push(v.equipmentID);
            eqptCode.push(v.systemTypeName);
            eqptCode.push(v.alaramORTrand);

            eqptCodes.push({ ...eqptCode });
          });
          // setRowList([...eqptCodes]);
          nonFilteredData.map((v) => {
            let eqptCode = [];
            eqptCode.push("");
            eqptCode.push(v.equipmentDetailID);
            eqptCode.push(v.equipmentCode);
            eqptCode.push(v.equipmentID);
            eqptCode.push(v.systemTypeName);
            eqptCode.push(v.alaramORTrand);

            eqptCodesforDigital.push({ ...eqptCode });
          });
          setRowList((pre) => ({
            ...pre,
            trend: eqptCodes,
            digital: eqptCodesforDigital,
          }));
          console.log(eqptCodes);
          console.log(eqptCodesforDigital);

          var stationDropDowns = [];
          var statusMaster = [];
          var AlaramStatus = [];
          // if (res.statusMaster != null && res.statusMaster.length > 0) {
          //   res.stationDropDown.map((v) => {
          //     const drop = {
          //       key: v.stationName,
          //       value: v.stationID
          //     }
          //     stationDropDowns.push(drop);
          //   });
          //   setStationList(stationDropDowns);
          // }
          if (res.statusMaster != null && res.statusMaster.length > 0) {
            res.statusMaster.map((v) => {
              const drop = {
                key: v.statusName,
                value: v.statusID,
              };
              statusMaster.push(drop);
            });
            setStatusList([...statusMaster]);
           
          }
          if (res.alaramStatus != null && res.alaramStatus.length > 0) {
            res.alaramStatus.map((v) => {
              const drop = {
                key: v.statusName,
                value: v.statusID,
              };
              AlaramStatus.push(drop);
            });
            setAlaramStatus([...AlaramStatus]);
          }

          if (res.frequencyList != null && res.frequencyList.length > 0) {
            SetFrequencyNameList([...res.frequencyList]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      hideLoading();
      setSystemTypeList([]);
      setRowList({});
    }
  }, [StationID]);

  const HandleChange = (e) => {
    var selectedOption = e.target.options[e.target.selectedIndex];

    var selectedLabel = selectedOption.text;
    handleGlobalStationName(selectedLabel ?? "");
    SetStationID(e.target.value);
  };

  const TypeOfScada = ({ data = [], id = "", Type }) => {
    
    return (
      <Accordion allowZeroExpanded={true}>
        {SystemTypeList.map((item, key) => {
         
          let TableRow = [];
          TableRow = data.filter((x) => {
            return x[4] == item;
          });

          return (
            <AccordionItem key={key} uuid={key}>
              <AccordionItemHeading>
                <AccordionItemButton>{item}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="parentchildtable">
                  {StatusList != null && StatusList.length > 0 ? (
                    <ParentTable
                      columnList={TableColumns}
                      rowList={TableRow}
                      StatusList={StatusList}
                      AlaramStatus={AlaramStatus}
                      FrequencyNameList={FrequencyNameList}
                      Type={Type}
                      tableName={`${id}${key}`}
                    ></ParentTable>
                  ) : (
                    "Loading"
                  )}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  const TabHeader = ["Analog", "Digital"];
  const TabBody = [
    <TypeOfScada data={rowList.trend ?? []} id="trend" Type="22,23" />,
    <TypeOfScada data={rowList.digital ?? []} id="digital" Type="36" />,
  ];

  const HandleShowPopup=()=>{
    SetScadaFlag(true)
  }

  return (
    <>
    <div>
      <div className="m-b-30">
       {getPrivilege().includes("SCADACREATE") && (<button  type="button"   class="btn add-btn m-l-15" data-bs-toggle="modal" data-bs-target="#addSacdaDetails" onClick={HandleShowPopup}> <i class="fa fa-plus"></i> Add </button> 
)}
        <div className="col-2" style={{ float: "right", paddingBottom: "5px" }}>
          <WithoutSelectDropDown
            data={StationList}
            name={"StationIDDropDown"}
            HandleChange={HandleChange}
          ></WithoutSelectDropDown>
        
        </div>
        <PageHeader title={"SCADA "}></PageHeader>
      </div>
      <div></div>
      <div className="card">
        <TabBar header={TabHeader} body={TabBody} />
      </div>
    </div>
    
    <BootrapModel
    
        Id={"addSacdaDetails"}
        CloseID="addSacdaDetailsModelClose"
       // ModelTitle={`Breakdown Maintenance Edit- ${TicketDetailes.ticketNo}`}
        modelSize={"modal-xl"}
        module={

         <AddScada EquipmentShortNameList={equipmentShortNameList} 
         EquipmentCodeList={equipmentCodeList} ParameterList ={parameterList} AlaramStatus={AlaramStatus} StatusList={StatusList}
         scadaFlag={scadaFlag} SetScadaFlag={SetScadaFlag}
         />
      
        }
      >
        {" "}
      </BootrapModel>
    </>
  );
};
