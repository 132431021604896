import React, { useState, useEffect, useRef, useContext } from "react";
import { Tile } from "./widgets/Tile";
import { BarchartWithCard } from "./widgets/ChartWithCard";
import {
  randomArray,
  COLORS,
  MyCOLORS,
  StationColors,
} from "../../services/CommonService";
import MasterDetailService from "../master/MasterDetailService";
import {
  ReactMultiSelect,
  Option,
  MultiValue,
} from "../../components/MultiSelect/ReactMultiSelect";
import DashboardService from "./DashboardService";
import makeAnimated from "react-select/animated";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  GetLoginUserID,
  getPrivilege,
  Decrypt,
} from "../../services/CommonService";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { PieChart } from "../../components/charts/PieChart";
import StackedChart from "../../components/charts/StackedChart";

const _dashboardService = new DashboardService();
const obj = new DashboardService();

const FaultInfoGraph = ({ stationID, FromDate, Todate }) => {
  // const [year, setYear] = useState(new Date().getFullYear());
  // const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [faultList, setFaultList] = useState({});
  const [parameterList, setParameterList] = useState({});
  const [faultparameterList, setfaultParameterList] = useState({});
  const [equipmentCode, setEquipmentCode] = useState(null);
  const [stationColor, setStationColor] = useState(null);
  const [spareMonthwise, setSpareMonthwise] = useState({});
  const bottomRef = useRef(null);
  const { showLoading, hideLoading, getCookies } = useContext(AppContext);

  useEffect(() => {
    const offsetTop = $("#FaultEquipmentbyparameter")?.offset()?.top;

    // scroll to the element with animation
    $("html, body").animate(
      {
        scrollTop: offsetTop,
      },
      1
    );
  }, [faultparameterList]);

  function getFaultList() {
    if (stationID != null && stationID != "") {
      _dashboardService
        .GetChartFaultList(stationID, FromDate, Todate)
        .then((response) => {
          if (response.data != null && response.data[0].length > 0) {
            var rowData = [];
            var labelData = [];
            // var parameterIds =[];

            response.data[0].map((data, i) => {
              labelData = data.labels;

              const res = labelData.map((x) => {
                const datas = data.fault.find((y) => y.label == x);
                if (datas != undefined) {
                  return datas.data;
                }
                return "";
              });

              rowData.push({
                label: data.label,
                data: res,
                backgroundColor: data.color,
                // backgroundColor: data.label == 'SWA' ? StationColors[2] : data.label == 'STR' ? StationColors[1] : StationColors[0],
                hoverBorderWidth: 3,
                hoverBorderColor: "#764ba2",
              });
            });

            var rowList = {
              labels: labelData,
              datasets: rowData,
            };

            setfaultParameterList(null);
            setFaultList({ ...rowList });
          } else {
            var rowData = [];
            var labelData = [];
            labelData.push("");
            var rowList = {
              labels: labelData,
              datasets: rowData,
            };

            setfaultParameterList(null);
            setFaultList({ ...rowList });
          }

          if (response.data != null && response.data[1].length > 0) {
            var rowData = [];
            var labelData = [];

            response.data[1].map((data, i) => {
              labelData = data.labels;

              const res = labelData.map((x) => {
                const datas = data.parameter.find((y) => y.label == x);
                if (datas != undefined) {
                  return datas.data;
                }
                return "";
              });

              rowData.push({
                label: data.label,
                data: res,
                hoverBorderWidth: 3,
                hoverBorderColor: "#764ba2",
                backgroundColor: data.color,
                // backgroundColor: data.label == 'SWA' ? StationColors[2] : data.label == 'STR' ? StationColors[1] : StationColors[0],
              });
            });

            var rowList = {
              labels: labelData,
              datasets: rowData,
            };
            setParameterList({ ...rowList });
          } else {
            var rowData = [];
            var labelData = [];
            labelData.push("");
            var rowList = {
              labels: labelData,
              datasets: rowData,
            };
            setParameterList({ ...rowList });
          }
        });
    } else {
      var rowData = [];
      var labelData = [];
      labelData.push("");
      var rowList = {
        labels: labelData,
        datasets: rowData,
      };

      setfaultParameterList(null);
      setFaultList({ ...rowList });
      setParameterList({ ...rowList });
    }
  }
  function getFaultParameterList(equipmentCode) {
    _dashboardService
      .GetChartFaultParameter(equipmentCode, FromDate, Todate)
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          var rowData = [];
          var labelData = [];
          // var parameterIds =[];
          // parameterIds = labelData.map((v, i) => {
          //   return v.split('-')[0];
          // })
          response.data.map((data, i) => {
            return labelData.push(data.label);
          });

          var station = equipmentCode.split("@")[1];

          rowData.push({
            label: equipmentCode.split("@")[0] + " (" + station + ")",
            data: response.data.map((v, i) => {
              return v.data;
            }),
            backgroundColor: stationColor,
            // backgroundColor: station == 'SWA' ? StationColors[2] : station == 'STR' ? StationColors[1] : StationColors[0],
            hoverBorderWidth: 4,
            hoverBorderColor: "#764ba2",
          });

          var rowList = {
            labels: labelData,
            datasets: rowData,
          };

          setfaultParameterList({ ...rowList });
        }
      });
  }
  useEffect(() => {
    getFaultList();
  }, [stationID, FromDate, Todate]);
  useEffect(() => {
    if (
      equipmentCode != null &&
      equipmentCode != undefined &&
      equipmentCode != ""
    ) {
      getFaultParameterList(equipmentCode);
    }
  }, [equipmentCode]);

  return (
    // <div className="row">
    //   <div className="col-md-12">
    <div className="row">
      <div className="col-md-6">
        <FaultInfoForStatus
          stationID={stationID}
          Todate={Todate}
          FromDate={FromDate}
        />
      </div>

      {faultList != null ? (
        <BarchartWithCard
          id={"FaultEquipment"}
          title={"Equipment Status"}
          data={faultList}
          yTitle={"No of Faults"}
          showLegend={true}
          setEquipmentCode={setEquipmentCode}
          setStationColor={setStationColor}
          clickable={true}
          isModel={false}
        />
      ) : (
        ""
      )}
      {parameterList != null ? (
        <BarchartWithCard
          id={"Fault"}
          title={"Parameter Status"}
          data={parameterList}
          yTitle={"No of Faults"}
          showLegend={true}
          setEquipmentCode={setEquipmentCode}
          clickable={true}
          isModel={true}
        />
      ) : (
        ""
      )}
      {faultparameterList?.labels != undefined ? (
        <>
          <BarchartWithCard //ref={bottomRef}
            id={"FaultEquipmentbyparameter"}
            title={"Equipment Parameter"}
            data={faultparameterList}
            yTitle={"No of Faults"}
            showLegend={true}
            clickable={false}
            isModel={false}
          />
        </>
      ) : (
        <div className="col-md-6"> </div>
      )}
      <div className="col">
        <FaultInfoForStackedChart
          stationID={stationID}
          Todate={Todate}
          FromDate={FromDate}
        >
          {" "}
        </FaultInfoForStackedChart>
      </div>
    </div>
  );
};

export const FaultInfo = ({ TabID, TabValue }) => {
  const animatedComponents = makeAnimated();
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);

  const [stationList, setStationList] = useState([]);
  const [Templist, setStationDropdown] = useState([]);
  const [stationallIds, setStationIds] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
  var lastDay = new Date();

  const [FilterAttr, setFilterAttr] = useState({
    StationID: 0,
    FromDate: firstDay.toISOString().split("T")[0],
    ToDate: lastDay.toISOString().split("T")[0],
  });

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    setStationDropdown(dataLst);
    HandleChange(dataLst);
  };

  useEffect(() => {
    if (TabID == TabValue) {
      getStationList();
    }
  }, [TabValue]);

  function HandleChange(e) {
    if (Array.isArray(e)) {
      var val = [];
      e.map((v, i) => val.push(v.value));
      // setStationIds(val.join(","));
      setStationDropdown(e);

      let StationLabel = e.map((x) => x.label).join();
      handleGlobalStationName(StationLabel);

      setFilterAttr((prevState) => ({
        ...prevState,
        ["StationID"]: val.join(","),
      }));
    } else {
      const { name, value } = e.target;

      setFilterAttr((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }
  return (
    <>
      {/* Dropdown */}
      <div className="row justify-content-space-between mt-0 mb-3">
        <div className="row align-items-center">
          {getPrivilege().includes("REPORTVIEW") && (
            <div className={windowWidth > 800 ? "col-md-2" : "col-md-6"}>
              <Link
                to="/breakdownreport"
                className="btn btn-primary"
                state={{ isBack: "True" }}
                title="Breakdown Report"
              >
                <i className="la la-file"></i>{" "}
                <span className="ReportButton"> Report</span>{" "}
              </Link>
            </div>
          )}

          {windowWidth > 800 && <div className="col-md-2"></div>}
          <div className={windowWidth > 800 ? "col-md-4" : "col-md-8"}>
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                Station
              </label>
              <ReactMultiSelect
                options={stationList}
                isMulti
                value={Templist}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder={"Select Station"}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(e) => HandleChange(e)}
                allowSelectAll={true}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                From Date
              </label>
              <input
                className="form-control ManualClearClass"
                type="date"
                name="FromDate"
                id="FromDateforFault"
                max={FilterAttr.ToDate}
                value={FilterAttr.FromDate}
                onChange={HandleChange}
              />
            </div>
          </div>
          <div className="col-md-2 ">
            <div className="form-group">
              <label className="label" htmlFor="empCode">
                To Date
              </label>
              <input
                className="form-control ManualClearClass"
                type="date"
                name="ToDate"
                min={FilterAttr.FromDate}
                id="ToDateforFault"
                value={FilterAttr.ToDate}
                onChange={HandleChange}
              />
            </div>
          </div>
             {/* <div className="col-md-1 ">

            <div className="form-group">

              <IconButton/>

            </div>

          </div> */}
        </div>
      </div>

      {/* Bar chart */}
      <FaultInfoGraph
        stationID={FilterAttr.StationID}
        FromDate={FilterAttr.FromDate}
        Todate={FilterAttr.ToDate}
      />
    </>
  );
};

export const FaultInfoForStatus = React.memo(
  ({ stationID, id, FromDate, Todate }) => {
    const [IsSysBreakdown, SetIsSysBreakdown] = useState(true);
    const [Piedata, setPiedata] = useState([]);
    const [sum, setSum] = useState(0);

    useEffect(() => {
      GetDataForFilter();
    }, [FromDate, Todate, stationID, IsSysBreakdown]);

    const pidata = {
      labels: ["Open", "Assigned", "Attended", "Approved", "Rejected"],

      datasets: [
        {
          data: Piedata,
          backgroundColor: ["#667eea", "navy", "#6c757d", "#55ce63", "#dc3545"],
          hoverOffset: 4,
        },
      ],
    };

    const GetDataForFilter = () => {
      if (stationID != null && stationID != "") {
        var BreakDownType = IsSysBreakdown ? 41 : 42;

        obj
          .Dashboard_FaultInfo_Piechart(
            stationID,
            FromDate,
            Todate,
            BreakDownType
          )
          .then((res) => {
            const result = [0, 0, 0, 0, 0];
            if (res?.data?.length > 0) {
              res.data.forEach((x) => {
                const index = [
                  "OPEN",
                  "ASSIGNED",
                  "ATTENDED",
                  "APPROVED",
                  "REJECTED",
                ].indexOf(x.StatusName);
                if (index !== -1) {
                  result[index] += x.Counts;
                }
              });
            }
            let sum = result.reduce((acc, curr) => acc + curr, 0);
            setSum(sum);
            setPiedata([...result]);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSum(0);
        setPiedata([]);
      }
    };

    const HandleChange = (e) => {
      SetIsSysBreakdown(!IsSysBreakdown);
    };

    return (
      <div className="card m-0">
        <div className="card-body">
          {/* <div className="row d-flex FaultInfoForStatus">
          <div className="col-md-3 mb-2">
            <label className="label" >
              Breakdown Type

            </label>
            <WithoutSelectDropDown
              name={"BreakDownType"}
              placeholder="Select Breakdown Type"
              value={FilterAttr.BreakDownType}
              HandleChange={HandleChange}
              data={BreakdownList}
            />
          </div>

       
        </div> */}

          <div className="row d-flex justify-content-center">
            <div className="mt-0 col-md-9 col-sm-6">
              <div
                style={{
                  position: "absolute",
                  left: "2px",
                  marginLeft: "15px",
                }}
              >
                {" "}
                <h3 className="card-title">
                  {" "}
                  {IsSysBreakdown ? "System Ticket" : "Manual Ticket"}{" "}
                </h3>{" "}
              </div>
              <div
                className="onoffswitch text-align-start"
                title="Breakdown Type"
                style={{ position: "absolute", right: "5px" }}
              >
                <input
                  type="checkbox"
                  name="BreakDownType"
                  checked={IsSysBreakdown}
                  className="onoffswitch-checkbox"
                  onChange={HandleChange}
                  id="BreakDown"
                />
                <label className="onoffswitch-label" htmlFor="BreakDown">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
              <PieChart
                id={id}
                data={pidata}
                title={"Total - " + sum}
              ></PieChart>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export const FaultInfoForStackedChart = React.memo(
  ({ stationID, id, FromDate, Todate }) => {
    const [IsSysBreakdown, SetIsSysBreakdown] = useState(true);
    const [StackedChartData, setStackedChartData] = useState({
      labels: [],
      datasets: [],
    });
    const [sum, setSum] = useState(0);
    useEffect(() => {
      GetDataForFilter();
    }, [FromDate, Todate, stationID, IsSysBreakdown]);

    const GetDataForFilter = () => {
      let result = {
        labels: [],
        datasets: [],
      };
      if (stationID != null && stationID != "") {
        var BreakDownType = IsSysBreakdown ? 41 : 42;
        obj
          .Dashboard_FaultInfo_Piechart(
            stationID,
            FromDate,
            Todate,
            BreakDownType
          )
          .then((res) => {
            if (res?.data?.length > 0) {
              let labels = [
                { Status: "Open", backgroundColor: "#667eea" },
                { Status: "Assigned", backgroundColor: "navy" },
                { Status: "Attended", backgroundColor: "#6c757d" },
                { Status: "Approved", backgroundColor: "#55ce63" },
                { Status: "Rejected", backgroundColor: "#dc3545" },
              ];
              let Station_labels = [
                ...new Set(res.data.map((x) => x.StationCode)),
              ];

              let StatusDataList = [];
              for (let i = 0; i < 5; i++) {
                let StatusList = [];
                Station_labels.forEach((x) => {
                  let statusCount = res.data
                    .filter((y) => {
                      return (
                        y.StationCode == x &&
                        y.StatusName == labels[i].Status.toLocaleUpperCase()
                      );
                    })
                    .reduce((acc, curr) => acc + curr.Counts, 0);
                  StatusList.push(statusCount);
                });
                StatusDataList.push(StatusList);
              }
              let dataSet = labels.map((x, index) => {
                return {
                  label: x.Status,
                  data: StatusDataList[index],
                  backgroundColor: x.backgroundColor,
                };
              });

              result = {
                labels: Station_labels,
                datasets: dataSet,
              };
            }
            setStackedChartData({ ...result });
           
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSum(0);
        // setStackedChartData(result);
      }
    };

    const HandleChange = (e) => {
      SetIsSysBreakdown(!IsSysBreakdown);
    };

    return (
      <div className="card m-0">
        <div className="card-body">
          <div className="row d-flex justify-content-center">
            <div className="mt-0 col-md-9 col-sm-6">
              <div
                style={{
                  position: "absolute",
                  left: "2px",
                  marginLeft: "15px",
                }}
              >
                {" "}
                <h3 className="card-title">
                  {"Station Wise - "}

                  {IsSysBreakdown ? "System " : "Manual "}
                  {"Ticket"}
                </h3>{" "}
              </div>
              <div
                className="onoffswitch text-align-start"
                title="Breakdown Type"
                style={{ position: "absolute", right: "5px" }}
              >
                <input
                  type="checkbox"
                  name="BreakDownType"
                  checked={IsSysBreakdown}
                  className="onoffswitch-checkbox"
                  onChange={HandleChange}
                  id="BreakDown1"
                />
                <label className="onoffswitch-label" htmlFor="BreakDown1">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </div>
          <StackedChart
            id={"sdhfsdjfnsduindio"}
            data={StackedChartData}
            yLabel={"No of Breakdowns"}
            xLabel={"Stations"}
            IsShow
          ></StackedChart>
        </div>
      </div>
    );
  }
);

// <div className="col-md-6 mt-4" >
// <div className="card mt-0">
//   <div className="card-body">
//     <div className="row">
//       <div className="col">
//         <h3 className="card-title">{title}</h3>
//       </div>
//       <div className="col-1">
//         <i
//           className="fa fa-download"
//           style={{ color: "#667ce7", fontSize: "18px", cursor: "pointer" }}
//           onClick={() => downloadPDF(id, title)}
//         ></i>
//       </div>
//     </div>
//     {isModel == true ?
//     <div id={"bar-charts-" + id} className="m-4">
//     {child}
//     {data != null && data.datasets != undefined ?
//       <BarChartWithModel id={id} data={data} yTitle={yTitle} showLegend={showLegend} setEquipmentCode={setEquipmentCode} clickable={clickable} title={title} />
//       :
//       <></>
//     }
//   </div>
//   :
//   <div id={"bar-charts-" + id} className="m-4">
//       {child}
//       {data != null && data.datasets != undefined ?
//         <BarChart id={id} data={data} yTitle={yTitle} showLegend={showLegend} setEquipmentCode={setEquipmentCode} setStationColor={setStationColor} clickable={clickable}/>
//         :
//         <></>
//       }
//     </div>
//     }

//   </div>
// </div>
// </div>
