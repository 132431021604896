import React from "react";
import AuditingConfigList from "./AuditingConfigList";
import { AduitProvider } from "./AuditContextProvider";

function AuditingLevelConfigIndex() {
  return (
    <AduitProvider>
      <AuditingConfigList></AuditingConfigList>
    </AduitProvider>
  );
}

export default AuditingLevelConfigIndex;
