import { useEffect, useState } from "react";
import { Dropdown, ReactTreeView } from "../../../components";
import {
  ApproveConfig_GetDropDown,
  ApproveConfig_AddOrUpdate,
} from "./ApprovalConfigService";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../../components/MultiSelect/ReactMultiSelect";
import { showWarningMsg, showSaveSuccess } from "../../../components/ToastMsg";
import makeAnimated from "react-select/animated";
import { getYMDdate, GetLoginUserID } from "../../../services/CommonService";
import {
  BuildTreeUsingParentID,
  findDepartmentById,
} from "../../../services/CommonService";
import $ from "jquery";

const AddApproveConfig = ({ editModel, handleRefresh }) => {
  const animatedComponents = makeAnimated();

  const [DropdownModel, setDropdownModel] = useState({
    DepartmentList: [],
    TaskTypeList: [],
    RolePrefixList: [],
    OverallList: [],
  });
  const [ApproverModel, setApproverModel] = useState({
    ConfigurationWorkFlowID: "0",
    ConfigApproveID: "0",
    departmentID: "",
    TaskTypeID: "",
    verifierID: "",
    approverID: "",
    TimeDurationL1: "",
    TimeDurationL2: "",
    TimeDurationL3: "",
    TimeDurationL4: "",
    TimeDurationL5: "",
    IsJobAssign: 1,
  });
  const [verifierList, setverifierList] = useState([]);
  const [approverList, setapproverList] = useState([]);
  const [selctedList, setselctedList] = useState([]);
  const [treeData, settreeData] = useState([]);
  const [OnSelect, setOnSelect] = useState("");
  const [errors, setErrors] = useState({
    departmentID: "",
    taskType: "",
    verifier: "",
    approver: "",
  });

  useEffect(() => {
    initDropDown();
  }, []);

  useEffect(() => {

    setApproverModel((pre) => ({
      ...pre,
      ConfigurationWorkFlowID: editModel.ConfigurationWorkFlowID,
      ConfigApproveID: editModel.ConfigApproveID,
      departmentID: editModel.departmentID,
      TaskTypeID: editModel.TaskTypeID,
      verifierID: editModel.verifierID,
      approverID: editModel.approverID,
      TimeDurationL1: editModel.TimeDurationL1,
      TimeDurationL2: editModel.TimeDurationL2,
      TimeDurationL3: editModel.TimeDurationL3,
      TimeDurationL4: editModel.TimeDurationL4,
      TimeDurationL5: editModel.TimeDurationL5,
      IsJobAssign: editModel.IsJobAssign,
    }));
    if (treeData.length > 0 && editModel.departmentID != "") {
      let obj = findDepartmentById(treeData, editModel.departmentID);
      setOnSelect(obj.name);
    } else {
      setOnSelect('');
    }
  }, [editModel]);
  useEffect(() => {
    const filteredList = DropdownModel.RolePrefixList.filter(
      (item) => item.label !== "L0"
    );
    setverifierList(filteredList);
    if (DropdownModel.OverallList.length != 0) {
      let treeJsonData = BuildTreeUsingParentID(DropdownModel.OverallList);
      let Data = JSON.parse(JSON.stringify(treeJsonData, null, 2));
      settreeData(Data);
    }
  }, [DropdownModel.RolePrefixList]);

  useEffect(() => {
    if (ApproverModel.verifierID && Array.isArray(ApproverModel.verifierID)) {

      const highestVerifierIndex = Math.max(
        ...ApproverModel.verifierID.map(verifier => DropdownModel.RolePrefixList.findIndex(role => role.label === verifier.label))
      );
      // const filterApproverList = DropdownModel.RolePrefixList.filter(
      //   (item) =>
      //     item.label !== "L0" &&
      //     !ApproverModel.verifierID.some(
      //       (verifier) => verifier.label === item.label
      //     )
      // );
      if (highestVerifierIndex !== -1) {
        const filterApproverList = DropdownModel.RolePrefixList.slice(highestVerifierIndex + 1);
        setapproverList(filterApproverList);
      } else {
        setapproverList([]);
      }
    }
  }, [ApproverModel.verifierID]);

  const initDropDown = async () => {
    await ApproveConfig_GetDropDown().then((res) => {
      setDropdownModel((prevv) => ({
        ...prevv,
        DepartmentList: res.data.data.departmentList,
        TaskTypeList: res.data.data.taskTypeList,
        RolePrefixList: res.data.data.rolePrefixList,
        OverallList: res.data.data.overallList,
      }));
    });
  };
  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    setApproverModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const CommonChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setApproverModel((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const setSelectedDepartment = (id = 0) => {
    let obj = findDepartmentById(treeData, id);

    if (!Array.isArray(obj.children)) {
      setOnSelect(obj.name);
      setApproverModel((pre) => ({
        ...pre,
        departmentID: id,
      }));
    }
  };
  const AddOrUpdatePredefinedFauilers = async (e) => {
    try {
      e.preventDefault();
      let model = ApproverModel;
      let modelErrors = errors;
      let flag = true;

      if (model.departmentID != null && model.departmentID != "") {
        modelErrors.departmentID = "";
      } else {
        modelErrors.departmentID = "Please select Department";
        flag = false;
      }
      if (model.TaskTypeID != null && model.TaskTypeID != "") {
        modelErrors.taskType = "";
      } else {
        modelErrors.taskType = "Please select Task Type";
        flag = false;
      }
      if (model.verifierID != null && model.verifierID != "") {
        modelErrors.verifier = "";
      } else {
        modelErrors.verifier = "Please select Verifier";
        flag = false;
      }
      if (model.approverID != null && model.approverID != "") {
        modelErrors.approver = "";
      } else {
        modelErrors.approver = "Please select Approver";
        flag = false;
      }

      setErrors({ ...modelErrors });
      if (flag) {

        const verifierIDList = model.verifierID;
        const combinedData = {};
        verifierIDList.forEach((item) => {
          const timeDurationKey = `TimeDuration${item.label}`;

          combinedData[timeDurationKey] = model[timeDurationKey];
        });
        const VerifierJsonArray = [];
        verifierIDList.forEach((item) => {
          const combinedItem = {
            ...item,
            TimeDuration: combinedData[`TimeDuration${item.label}`],
            LevelType: 84,
          };
          VerifierJsonArray.push(combinedItem);
        });
        const approverIDList = model.approverID;
        const combinedApproveData = {};
        approverIDList.forEach((item) => {
          const timeDurationKey = `TimeDuration${item.label}`;
          combinedApproveData[timeDurationKey] = model[timeDurationKey];
        });
        const approverJsonArray = [];
        approverIDList.forEach((item) => {
          const combinedItem = {
            ...item,
            TimeDuration: combinedApproveData[`TimeDuration${item.label}`],
            LevelType: 85,
          };
          approverJsonArray.push(combinedItem);
        });

        const combinationData = {
          verifierData: VerifierJsonArray,
          approverData: approverJsonArray,
        };
        const combinedArray = [
          ...Object.values(combinationData.verifierData),
          ...Object.values(combinationData.approverData),
        ];
        const combinedJsonString = JSON.stringify(combinedArray);
        model.TaskTypeID = model.TaskTypeID;
        model.VerifierList = combinedJsonString;
        model.SavedBy = GetLoginUserID();
        model.SavedDate = getYMDdate(new Date());
        if (model.VerifierList != "") {
          const verifierList = JSON.parse(model.VerifierList);
          const filteredVerifierList = verifierList.filter(verifier => verifier.TimeDuration === '');
          if (filteredVerifierList.length == 0) {
            console.log(filteredVerifierList);
            ApproveConfig_AddOrUpdate(model)
              .then((res) => {
                if (res.data.data > 0) {
                  handleRefresh();
                  showSaveSuccess();
                  initDropDown();
                  setOnSelect('');
                  $(".close").click();
                }
              })
              .catch((err) => {
                console.log(err);
                // setValueList((pre) => ({
                //     ...pre,
                //     systemID: '',
                //     DepartmentID: '',
                //     CriticalType: '',
                //     EquipmentID: '',
                //     DescriptionName: '',
                // }));
              });
          } else {
            showWarningMsg("Please fill Time Duration");
          }
        }
      }
    } catch (e) {
      showWarningMsg("Please fill all fields");
    }
  };

  return (
    <div>
      <form id="addApprovalConfig">
        <div className="row">
          <div className="col-md-3">
            <ReactTreeView
              openByDefault={false}
              data={treeData}
              width={250}
              onSelect={setSelectedDepartment}
              IsMultiselect={false}
            ></ReactTreeView>
            {OnSelect !== "" && (
              <p>
                Selected Department:{" "}
                <span
                  style={{
                    color: "blue",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                  }}
                >
                  {OnSelect}
                </span>
              </p>
            )}
            <span className="text-danger"> {errors.departmentID} </span>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Task Type<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={DropdownModel.TaskTypeList}
                    placeholder="Select"
                    name={"TaskTypeID"}
                    HandleChange={CommonHandleChange}
                    value={ApproverModel.TaskTypeID}
                    className=""
                  />
                  <span className="text-danger"> {errors.taskType} </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Verifier<span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={verifierList}
                    isMulti
                    allowSelectAll
                    value={ApproverModel.verifierID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      setApproverModel((prevState) => ({
                        ...prevState,
                        verifierID: e,
                      }));
                    }}
                  />

                  <span className="text-danger"> {errors.verifier} </span>

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Approver<span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={approverList}
                    isMulti
                    allowSelectAll
                    value={ApproverModel.approverID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      setApproverModel((prevState) => ({
                        ...prevState,
                        approverID: e,
                      }));

                      const combinedLabels = [];
                      combinedLabels.push(e);
                      setselctedList(combinedLabels);
                      if (selctedList.length > 0) {
                        selctedList.some(
                          (item) => item.label && item.label.includes("L1")
                        );
                      }
                    }}
                  />

                  <span className="text-danger"> {errors.approver} </span>

                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label" htmlFor="stationCode">
                    Time Duration <span className="text-danger"> *</span>{" "}
                  </label>
                  <div className="input-group">
                    {/* {selctedList.some(item => item.label.includes('L1')) && ( */}
                    <div className="col-md-2">
                      <div className="input-temp">
                        <span className="label-text">L1</span>
                        <input
                          className="form-control"
                          type="number"
                          name="TimeDurationL1"
                          value={ApproverModel.TimeDurationL1}
                          onChange={CommonHandleChange}
                        />
                      </div>
                    </div>
                    {/* )} */}
                    <div className="col-md-2">
                      <div className="input-temp">
                        <span className="label-text">L2</span>
                        <input
                          className="form-control"
                          type="number"
                          name="TimeDurationL2"
                          value={ApproverModel.TimeDurationL2}
                          onChange={CommonHandleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-temp">
                        <span className="label-text">L3</span>
                        <input
                          className="form-control"
                          type="number"
                          name="TimeDurationL3"
                          value={ApproverModel.TimeDurationL3}
                          onChange={CommonHandleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-temp">
                        <span className="label-text">L4</span>
                        <input
                          className="form-control"
                          type="number"
                          name="TimeDurationL4"
                          value={ApproverModel.TimeDurationL4}
                          onChange={CommonHandleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-temp">
                        <span className="label-text">L5</span>
                        <input
                          className="form-control"
                          type="number"
                          name="TimeDurationL5"
                          value={ApproverModel.TimeDurationL5}
                          onChange={CommonHandleChange}
                        />
                      </div>
                    </div>

                    {/* Add more input-wrapper divs for additional inputs */}
                  </div>
                  {/* <span className="text-danger"> {errors.BufferPeriod} </span> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="checkbox-temp">
                  <label>
                    <input
                      type="checkbox"
                      name="IsJobAssign"
                      checked={ApproverModel.IsJobAssign}
                      onChange={CommonChange}
                    />
                    <span style={{ padding: "5px" }}>
                      Job Assignment Required
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-end justify-content-end">
                <button
                  type="submit"
                  className="btn add-btn mb-2"
                  id="timeconfigbutton"
                  onClick={AddOrUpdatePredefinedFauilers}
                //   data-bs-toggle="modal"
                //   data-bs-target="#addbutton"
                >
                  <i className="fa fa-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddApproveConfig;
