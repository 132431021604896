import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class SCADAConfigService {
  GetEquipmentDetailesUsingStaionID(stationID) {
    return axios
      .get(
        `Commissioned/GetEquipmentDetailesUsingStaionID?StaionID=${stationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }


  GetSCADADetailesUsingEquipmentID(EquipmentID, EquimentDetailID, Type) {
    return axios
      .get(
        `Commissioned/GetSCADADetailesUsingEquipmentID?EquipmentID=${EquipmentID}&EquDetailID=${EquimentDetailID}&Type=${Type}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  DeleteScadabyID(SCADAID, createdBy) {
    return axios
      .get(
        `Commissioned/DeleteScadabyID?ScadaID=${SCADAID}&createdBy=${createdBy}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  ScadaDetailesSave(ScadaModel) {
    
    return axios
      .post(`Commissioned/ScadaDetailesSave`, ScadaModel)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  GetEquipmentShortName(){
    
    return axios
    .get(
      `Commissioned/SCADA_GetEquipmentShortName`
    ).then(response => {
    
      return response.data;

    })
  }
  GetEquipmentCode(){
    
    return axios
    .get(
      `Commissioned/SCADA_GetEquipmentCode`
    ).then(response => {
     
      return response.data;

    })
  }
  GetParameterList(){
    
    return axios
    .get(
      `Commissioned/SCADA_GetParameterList`
    ).then(response => {
     
      return response.data;

    })
  }
}
