import React, { useState, useContext, useReducer, useEffect } from "react";

import {
  PeriodicMaintenance_GetDescriptionByEquipment,
  PeriodicService,
} from "./PeriodicService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES, MaintenanceTypes } from "../../services/Global";
import $ from "jquery";
import { ContextValues } from "./PeriodicContextProvider";

export default function ({ equipmentID }) {
  const { PeriodicModel } = ContextValues();
  const [data, setRows] = useState([]);

  const getDescriptions = () => {
    let DepartmentID = PeriodicModel.DepartmentID;
    let { CurrentWeek } = PeriodicModel;
    let ScheduleType = MaintenanceTypes[PeriodicModel.CurrentView ?? "month"];

    if (
      PeriodicModel?.EquipmentID &&
      ScheduleType &&
      PeriodicModel?.StationID?.value &&
      DepartmentID &&
      CurrentWeek
    ) {
      PeriodicMaintenance_GetDescriptionByEquipment(
        PeriodicModel?.EquipmentID,
        ScheduleType,
        PeriodicModel?.StationID?.value,
        DepartmentID,
        CurrentWeek
      ).then((res) => {
        if (res?.data?.data?.length) {
          const { data } = res?.data;
          var dataLst = [];
          data.map((v) => {
            dataLst.push(v.Description);
          });
          setRows(dataLst);
        } else {
          setRows([]);
        }
      });
    }
  };
  useEffect(() => {
    getDescriptions();
  }, [PeriodicModel.DescriptionRefreshID, PeriodicModel.CurrentView]);

  return (
    <div>
      <div className="card">
        <ol>
          {data?.map((v,index) => (
            <div key={`Description-Key-${index}`} className="card-body">
              <li className="">{v}</li>
            </div>
          ))}
        </ol>
      </div>
    </div>
  );
}
