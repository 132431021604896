import React, { useState, useEffect, useContext, getCookies } from "react";
import { CommisionedDetailsTable } from "./CommisionedDetailsTable";
import DesignAndCommService from "./DesingAndCommService";
import { AppContext } from "../../services/ContextProvider";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Global from "../../services/Global";
import { GetLoginUserID } from "../../services/CommonService";

const _designAndCommService = new DesignAndCommService();

export const CommissionedDetails = ({ StationIDs }) => {
  //Equipment Design details table columns
  const TableColumns = [
    {
      className: "dt-control",
      orderable: false,
      data: null,
      defaultContent: "",
      width: "2.06rem",
    },
    { title: "Equipment Detail ID", className: "hide" },
    { title: "Equipment Tag Number" },
  ];

  //variables
  const { showLoading, hideLoading } = useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [parameterList, setParameters] = useState(null);
  const [SystemTypeList, setSystemTypeList] = useState([]);


  //functions
  useEffect(() => {
    getTableData();
    getParameter();
  }, [StationIDs]);

  const getTableData = () => {
    
    if (StationIDs != null && StationIDs != "") {
      showLoading();
      try {
        const id = GetLoginUserID()

        _designAndCommService.GetEquipmentCodes(StationIDs, id).then((res) => {
          hideLoading();
          if (res != null && res.isSuccess) {


            var Data = res.data.equipCodeForCommision;
            const unique = Data
              .map(item => item.systemTypeName)
              .filter((value, index, self) => self.indexOf(value) === index);
            setSystemTypeList(unique);

            var eqptCodes = [];
            Data.map((v) => {
              var eqptCode = [];
              eqptCode.push("");
              eqptCode.push(v.equipmentDetailID);
              eqptCode.push(v.equipmentCode);
              eqptCode.push(v.systemTypeName);
              eqptCodes.push(eqptCode);
            });
            setTableRows(eqptCodes);
          }
          else {
            setSystemTypeList([]);

            setTableRows([]);
          }
        });
      }
      catch (ex) {
        hideLoading();
        console.log(ex);
      }

    }
    else {
      setSystemTypeList([]);

      setTableRows([]);
    }
  };
  const getParameter = () => {
    _designAndCommService.GetParameters().then((res) => {
      if (res != null && res.isSuccess && res.data.length > 0) {
        var parameters = [];
        res.data.map((v) => {
          parameters.push({
            key: v.ParameterName,
            value: v.ParameterID,
            unit: v.ParameterUnit,
          });
        });

        setParameters(parameters);
      }
    });
  };

  return (
    <div>


      <Accordion allowZeroExpanded={true} >
        {SystemTypeList.map((item, key) => {

          let TableRow = [];
          TableRow = tableRows.filter((x) => {
            return x[3] == item;

          })

          return (

            <AccordionItem key={key} uuid={key}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>


                {parameterList != null && parameterList.length > 0 ? (
                  <CommisionedDetailsTable
                    columnList={TableColumns}
                    rowList={TableRow}
                    tableName={`Equipemt_Commissioned_Detail${key}`}
                    parameters={parameterList}
                  />
                ) : parameterList == null ? (
                  <p className="text-center m-5">Loading...</p>
                ) : (
                  <p className="text-center text-danger m-5">
                    Equipment Design parameter is not found
                  </p>
                )}




              </AccordionItemPanel>
            </AccordionItem>

          )

        }

        )}
      </Accordion>

    </div>
  );
};



{/* <div>
{parameterList != null && parameterList.length > 0 ? (
  <CommisionedDetailsTable
    columnList={TableColumns}
    rowList={tableRows}
    tableName={"Equipemt_Commissioned_Detail"}
    parameters={parameterList}
  />
) : parameterList == null ? (
  <p className="text-center m-5">Loading...</p>
) : (
  <p className="text-center text-danger m-5">
    Equipment Design parameter is not found
  </p>
)}
</div>
); */}
