import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { randomArray } from "../../services/CommonService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const PieChart = ({ data, title }) => {
  return (
    <div>
      <Pie
        height={100}
        width={100}
        data={data}
        options={{
          plugins: {
            title: {
              display: title ? true : false,
              text: title,
            },
            legend: {
              labels: {
                boxWidth: 20,
              },
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              // borderColor: 'white',
              borderRadius: 25,
              borderWidth: 2,
               color: "white",
              anchor: "end",
              align: "start",
              display: function (context) {
                var dataset = context.dataset;
                var count = dataset.data.length;
                var value = dataset.data[context.dataIndex];
                return value; //> count * 1.5;
              },
              font: {
                weight: "bold",
              },
              padding: 4,
              formatter: Math.round,
            },
          },
        }}
      ></Pie>
    </div>
  );
};
