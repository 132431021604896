import { HtmlTable } from "../../../components/HtmlTable";
import {
  BuildTreeUsingParentID,
} from "../../../services/CommonService";
import $ from "jquery";
import { useEffect, useState } from "react";
import AddPrefiledFilures from "./AddPrefiledFailures";
import {
  PrefinedFails_GetDropDown,
  PrefinedFails_GetDescriptionList,
  PredefinedFails_DeleteDescription,
} from "./PredefinedFailuresService";
import { BootrapModel } from "../../../components";
import swal from "sweetalert";
import { showSaveError, showDeleteSuccess } from "../../../components/ToastMsg";
import { useReducer } from "react";

export default function PredifnedFailures() {
  const [DropdownModel, setDropdownModel] = useState({
    DepartmentList: [],
    SubDepartmentList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
    CritLevel: [],
    OverallList: [],
  });
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [GridModel, setGridModel] = useState([]);
  const [treeData, settreeData] = useState([]);
  const [model, setmdodel] = useState({
    systemID: "",
    DepartmentID: "",
    CriticalType: "",
    EquipmentID: "",
    DescriptionID: "0",
    DescriptionName: "",
    BMPredefineFailuresID: "0",
  });

  useEffect(() => {
    initDropdown();
    initGrid();
  }, []);
  useEffect(() => {
    if (DropdownModel.OverallList.length != 0) {
      let treeJsonData = BuildTreeUsingParentID(DropdownModel.OverallList);
      let Data = JSON.parse(JSON.stringify(treeJsonData, null, 2));
      console.log(Data);
      // Data.forEach((ele) => ele.isOpen = true)
      settreeData(Data);
    }
  }, [DropdownModel]);

  const HandleClickCreateBtn = (id) => {
    // setCyclicCheckId(id.CyclicCheckId);

    PageRender();
  };

  const handleRefresh = () => {
    initGrid();
    setmdodel((pre) => ({
      ...pre,
      systemID: "",
      DepartmentID: "",
      CriticalType: "",
      EquipmentID: "",
      DescriptionID: "0",
      DescriptionName: "",
      BMPredefineFailuresID: "0",
    }));
  };

  const initDropdown = async () => {
    await PrefinedFails_GetDropDown().then((res) => {
      setDropdownModel((pre) => ({
        ...pre,
        DepartmentList: res.data.data.departmentList,
        OverallList: res.data.data.overallList,
        SystemList: res.data.data.subDepartmentList,
        EquipmentList: [],
        FilterEquipmentList: res.data.data.equipmentList,
        CritLevel: res.data.data.critLevel,
      }));
    });
  };
  const initGrid = async () => {
    await PrefinedFails_GetDescriptionList().then((res) => {
      if (
        res != null &&
        res.data.data.descriptionList != null &&
        res.data.data.descriptionList.length > 0
      ) {
        setGridModel([...res.data.data.descriptionList]);
      } else {
        setGridModel([]);
      }
    });
  };
  const EditmodelList = (rowData) => {
    PageRender();
    setmdodel((pre) => ({
      ...pre,
      systemID: rowData.DepartmentID,
      DepartmentID: rowData.DepartmentID,
      CriticalType: rowData.CriticalTypeId,
      EquipmentID: rowData.EquipmentID,
      DescriptionID: rowData.DescriptionID,
      DescriptionName: rowData.DescriptionName,
      BMPredefineFailuresID: rowData.BMPredefineFailuresID,
    }));
  };
  const columnNames = [
    { title: "CriticalTypeId", visible: false, data: "CriticalTypeId" },
    {
      title: "BMPredefineFailuresID",
      visible: false,
      data: "BMPredefineFailuresID",
    },
    { title: "Department", data: "DepartmentName" },
    { title: "Criticality Level", data: "CriticalType" },
    { title: "Equipment Type", data: "EquipmentShortName" },
    { title: "Description", data: "DescriptionName" },
    {
      title: "Submit",
      data: "BMPredefineFailuresID",
      // visible:
      //     getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
      //     getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONEDIT")) {
        b =
          $(`<button class='btn icon-btn' title='Edit'  data-bs-toggle="modal" data-bs-target="#PrefinedFailures" >
                  <i class='fa fa-pencil m-r-5'></i>
                </button>`).on("click", function () {
            EditmodelList(rowData);
          });
        // }
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
        c = $(`<button class='btn icon-btn'  title='delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function () {
          onDeleteClick(rowData);
        });
        // }
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];
  const onDeleteClick = (rowData) => {
    let id = rowData.BMPredefineFailuresID;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PredefinedFails_DeleteDescription(id)
          .then((response) => {
            if (response.data.data == 1) {
              showDeleteSuccess();
              initGrid();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const Header = ({ title }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-md-6 ">
            <h3 className="page-title">{title}</h3>
          </div>
          {/* {getPrivilege().includes("NONREVENUECONFIGURATIONCREATE") && ( */}
          <div className="col-md-2 ">
            <button
              type="submit"
              className="btn add-btn mb-2"
              id="timeconfigbutton"
              onClick={HandleClickCreateBtn}
              data-bs-toggle="modal"
              data-bs-target="#PrefinedFailures"
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header title={"Breakdown Maintenance - Predefined Failures"} />
      <BootrapModel
        Id={"PrefinedFailures"}
        CloseID={'PrefinedFailuresClose'}
        modelSize={"modal-xl"}
        ModelTitle={"Add / Edit Breakdown Maintenance - Predefined Failure"}
        module={
          <AddPrefiledFilures
            model={model}
            handleRefresh={handleRefresh}
            DropdownModel={DropdownModel}
            setDropdownModel={setDropdownModel}
            Render={Render}
            setGridRender={setGridRender}
          />
        }
        closepopup={handleRefresh}
      />
      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={GridModel}
            id={"ConfigureList"}
          />
        </div>
      </div>
    </div>
  );
}
