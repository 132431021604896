import { Encrypt } from "../../services/CommonService";
import Axios from "../../services/AxiosService";
import { parse } from "date-fns";

const axios = new Axios();
let cancelToken;
let cancelTokenTime;

export default class DashboardService {
  async GetSpareDashboardData(year, month, stationID, mode) {
    return await axios
      .get(
        `Dashboard/SpareChartDataYear?year=${year}&&month=${month}&&stationId=${stationID}&&mode=${mode}`
      )
      .then((res) => res.data);
  }
  async GetSpareDashboardDataMonth(year, month, stationID) {
    return await axios
      .get(
        `Dashboard/SpareChartDataMonth?year=${year}&&month=${month}&&stationId=${stationID}`
      )
      .then((res) => res.data);
  }
  async GetChartSpareList(year, month, mode, sparename) {
    return await axios
      .get(
        `Dashboard/GetChartSpareList?year=${year}&&month=${month}&&mode=${mode}&&sparename=${sparename}`
      )
      .then((res) => res.data);
  }
  async GetChartFaultList(stationID, fromDate, todate) {
    return await axios
      .get(
        `Dashboard/GetChartFaultList?stationId=${stationID}&&fromDate=${fromDate}&&todate=${todate}`
      )
      .then((res) => res.data);
  }
  async GetChartFaultParameter(equipmentCode, fromDate, todate) {
    return await axios
      .get(
        `Dashboard/GetChartFaultParameter?equipmentCode=${equipmentCode}&&fromDate=${fromDate}&&todate=${todate}`
      )
      .then((res) => res.data);
  }
  async GetChartFaultParameterDetails(parameterName, fromDate, todate) {
    return await axios
      .get(
        `Dashboard/GetChartFaultParameterDetails?parameterName=${parameterName}&&fromDate=${fromDate}&&todate=${todate}`
      )
      .then((res) => res.data);
  }
  async Dashboard_GetEquipmentTagNo(EquipmentID, StationID) {
    return await axios
      .get(
        `Dashboard/Dashboard_GetEquipmentTagNo?EquipmentID=${EquipmentID}&&StationID=${StationID}`
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
      });
  }

  async Dashboard_GetparametersUsingEquipmentTagNo(
    TagNumbers,
    FromDate,
    ToDate,
    Flag = 1
  ) {
    if (cancelToken) {
      cancelToken.abort();
    }

    // Create a new cancellation token for this request
    cancelToken = new AbortController();

    return await axios
      .get(
        `Dashboard/Dashboard_GetparametersUsingEquipmentTagNo?TagNumbers=${TagNumbers}&FromDate=${FromDate}&ToDate=${ToDate}&Flag=${Flag}`,
        {
          signal: cancelToken.signal,
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        if (err.name === "CanceledError") {
          return null;
        } else {
          console.log(err);
        }
      });
  }
  async GetScdadataUsingMaxTime(
    ChartMaxTime,
    ParameterID,
    EquipmentDetailID,
    Flag,
    IsTime = true
  ) {
    if (cancelTokenTime) {
      cancelTokenTime.abort();
    }

    // Create a new cancellation token for this request
    cancelTokenTime = new AbortController();

    ChartMaxTime = new Date(ChartMaxTime).toISOString();

    let hoursToAdd = 3; // Example number of hours to add
    let newDate = new Date(ChartMaxTime);
    newDate.setHours(newDate.getHours() + hoursToAdd);

    let newDateTimeString = newDate.toISOString();

    // console.log(ChartMaxTime, newDateTimeString);

    return await axios
      .get(
        `Dashboard/Dashboard_GetScdadataUsingMaxTime?ChartMaxTime=${newDateTimeString}&ParameterID=${ParameterID}&EquipmentDetailID=${EquipmentDetailID}&Flag=${Flag}&IsTime=${IsTime}`,
        {
          signal: cancelTokenTime.signal,
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        if (err.name === "CanceledError") {
          return null;
        } else {
          console.log(err);
        }
      });
  }

  async Dashboard_GetEquipmentList() {
    return await axios
      .get(`Dashboard/Dashboard_GetEquipmentList`)
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
      });
  }

  async Dashboard_GetdataForVariousdays(
    EquipmentDetailID,
    ParameterID,
    Flag,
    FromDate = "",
    ToDate = ""
  ) {
    return await axios
      .get(
        `Dashboard/Dashboard_GetdataForVariousdays?ParameterID=${ParameterID}&EquipmentDetailID=${EquipmentDetailID}&Flag=${Flag}&FromDate=${FromDate}&ToDate=${ToDate}`
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
      });
  }

  async Dashboard_FaultInfo_Piechart(
    stationID,
    FromDate = "",
    ToDate = "",
    BreakdownType = ""
  ) {
    return await axios
      .get(
        `Dashboard/Dashboard_FaultInfo_Piechart?stationID=${stationID}&FromDate=${FromDate}&ToDate=${ToDate}&BreakdownType=${BreakdownType}`
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  async Dashboard_FaultInfo_DropdownData() {
    return await axios
      .get(`Dashboard/Dashboard_FaultInfo_DropdownData`)
      .then((res) => res.data)
      .catch((err) => err);
  }

  async Dashboard_PeriodicStackChart(
    stationID,
    Frequency,
    FromDate,
    ToDate,
    equipmentID = 0
  ) {
    return await axios
      .get(
        `Dashboard/Dashboard_PeriodicStackChart?Station=${stationID}&Frequency=${Frequency}&FromDate=${FromDate}&ToDate=${ToDate}&equipmentID=${equipmentID}`
      )
      .then((res) => res.data)
      .catch((err) => err);
  }
  // Created By Arunbala
  async DashBoard_getStationColorDetailsList() {
    return await axios
      .get(`Dashboard/DashBoard_getStationColorDetailsList`)
      .then((res) => res.data);
  }
}

export const DashBoard_GetPerformanceEvaluation = async (
  StationID,
  EquipmentID,
  YearID
) => {
  return await axios.get(
    `Dashboard/DashBoard_GetPerformanceEvaluation?Station=${StationID}&&EquipmentID=${EquipmentID}&YearID=${YearID}`
  );
};

export const Dashboard_GetBreakdownDepartmentList = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  FromDate = "",
  ToDate = ""
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetBreakdownDepartmentList?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&FromDate=${FromDate}&&ToDate=${ToDate}`
  );
};

export const Dashboard_GetEquAvaList = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  FromDate = "",
  ToDate = ""
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetEquAvaList?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&FromDate=${FromDate}&&ToDate=${ToDate}`
  );
};
export const Dashboard_GetCyclicChekList = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  FromDate = "",
  ToDate = ""
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetCyclicChekList?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&FromDate=${FromDate}&&ToDate=${ToDate}`
  );
};

export const Dashboard_GetStattionWiseBreakdownData = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  StatusName = "",
  StationName = "",
  EqCode
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetStattionWiseBreakdownData?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&StatusName=${StatusName}&&StationName=${StationName}&&EqCode=${EqCode}`
  );
};

export const Dashboard_GetStattionWiseEquipAvaData = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  StatusName = "",
  StationName = "",
  EqCode
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetStattionWiseEquipAvaData?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&StatusName=${StatusName}&&StationName=${StationName}&&EqCode=${EqCode}`
  );
};

export const Dashboard_GetBreakdownDepStationList = async (
  StationID = "",
  DepartmentID = "",
  EquipmentDetailID = "",
  StatusName = "",
  StationName = ""
) => {
  return await axios.get(
    `Dashboard/Dashboard_GetBreakdownDepStationList?StationID=${StationID}&&DepartmentID=${DepartmentID}&&EquipmentDetailID=${EquipmentDetailID}&&StatusName=${StatusName}&&StationName=${StationName}`
  );
};

export const DashboardBreakdown_Filter = async (employeeID = 0) => {
  return await axios.get(
    `Dashboard/DashboardBreakdown_Filter?EmployeeID=${employeeID}`
  );
};
export const Dashboard_PM_StationWiseAndDepartmentWise = async (
  scheduleTypeID,
  departmentID,
  stationID,
  fromDate,
  toDate
) => {
  return await axios.get(
    `Dashboard/Dashboard_PM_StationWiseAndDepartmentWise?scheduleTypeID=${scheduleTypeID}&departmentID=${departmentID}&stationID=${stationID}&fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const Dashboard_PM_StationWiseGetDataForReport = async (
  scheduleTypeID,
  departmentID,
  stationID,
  fromDate,
  toDate,
  EquipmentID,
  Status
) => {
  return await axios.get(
    `Dashboard/Dashboard_PM_StationWiseGetDataForReport?scheduleTypeID=${scheduleTypeID}&departmentID=${departmentID}&stationID=${stationID}&fromDate=${fromDate}&toDate=${toDate}&EquipmentID=${EquipmentID}&Status=${Status}`
  );
};
