import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class RoleService {
  GetRoleList(roleid, tvsvac) {
    return axios.get(`Role/GetRoleList?roleid=${roleid}&tvsvac=${tvsvac}`);
  }
  GetRoleData(RoleID) {
    return axios.get(`Role/GetRoleData?RoleID=${RoleID}`);
  }
  SaveRole(data) {
    console.log(data);
    return axios.post("Role/AddRole", data);
  }
  DeleteRole(RoleID) {
    return axios.get(`Role/DeleteRole?RoleID=${RoleID}`);
  }
}

export const Role_SaveOrderList = (RoleList) => {
  return axios.post(`Role/Role_SaveOrderList`, RoleList);
};
