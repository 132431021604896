import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";

import { useGetEquipmentForJobAllocationMutation } from "../PeriodicReduxApiService";
import {
  BootrapModel,
  Dropdown,
  HtmlTable,
  Option,
  MultiValue,
  ReactMultiSelect,
} from "../../../components";
import BreakdownMaintenanceService from "../../BreakDownMaintenance/BreakdownMaintenanceService";
import { GetPeriodicStatus } from "../../BreakDownMaintenance/MainGrid";
import {
  Decrypt,
  GetLoginUserID,
  GetToDateAndWeek,
  ShortMonths,
  getL0RoleID,
  getPrivilege,
  getUserDetails,
  getYMDdate,
} from "../../../services/CommonService";
import { PeriodicMaintenance_GetEquipmentDetailForJobSave } from "../PeriodicService";
import { DownloadModel } from "../../BreakDownMaintenance/DownloadModel";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router";
import { PeriodicJobAssign } from "../PeriodicJobAssign";
import {
  PeriodicStateSelector,
  setInitialState,
  setModelState,
} from "../../../ReduxSlice/PeriodicMaintenanceSlice";
import { DefaultAppContextValues } from "../../../services/ContextProvider";
import { COOKIES } from "../../../services/Global";
import { WithoutSelectDropDown } from "../../../components/WithoutSelectDropDown";
import { FaFilter } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { ContextValues } from "../PeriodicContextProvider";
import { DataTableWithAjax } from "../../../components/DataTableWithAjax/DataTableWithAjax";
import { BASE_URL } from "../../../env";

export default function PeriodicJobAllocation() {
  const [getEquipmentForJobAllocation, { isError, error, data: AllJobList }] =
    useGetEquipmentForJobAllocationMutation();

  const {
    DepartmentList = [],
    StationList = [],
    StationTypes = [],
    FilterStationList = [],
    Model = {},
  } = useSelector(PeriodicStateSelector);
  const animatedComponents = makeAnimated();

  const disPatch = useDispatch();

  const {
    GetLoginStationBaseOnDep,
    GetLoginStationTypes,
    getCookies,
    handleGlobalStationName,
    showLoading,
    hideLoading,
  } = DefaultAppContextValues();

  const { setPeriodicModel } = ContextValues();

  const _breakdown = new BreakdownMaintenanceService();
  const navigate = useNavigate();
  const [FileData, setFileData] = useState([]);
  const [DownLoadRefresh, setDownLoadRefresh] = useReducer((x) => x + 1, 0);

  const columnNames = [
    {
      title: "",
      data: "JobAssigned",
      className: "hide",
    },

    {
      title: "Station Code",
      data: "StationCode",
    },
    {
      title: "Equipment Name",
      data: "EquipmentShortName",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(
          `<span title='${rowData.SystemTypeName}'>${cellData}</span>`
        );
      },
    },
    {
      title: "Maintenance Type Name",
      data: "MaintenanceTypeName",
    },
    {
      title: "Week",
      data: "WeeKName",
    },
    {
      title: "Count",
      data: "OpenCount",
      createdCell: (td, cellData, rowData, row, col) => {
        const {
          OpenCount = 0,
          ClosedCount = 0,
          ApprovedCount = 0,
          RejectedCount = 0,
        } = rowData;

        let OpenSpan = OpenCount > 0 ? $(`<p>Open : ${OpenCount}</p>`) : "";
        let CloseSpan =
          ClosedCount > 0 ? $(`<p>Close : ${ClosedCount}</p>`) : "";
        let ApproveSpan =
          ApprovedCount > 0 ? $(`<p>Approve : ${ApprovedCount}</p>`) : "";
        let RejectSpan =
          RejectedCount > 0 ? $(`<p>Reject : ${RejectedCount}</p>`) : "";

        let WrapDiv = $(`<div>`);
        WrapDiv.append(OpenSpan);
        WrapDiv.append(CloseSpan);
        WrapDiv.append(ApproveSpan);
        WrapDiv.append(RejectSpan);

        $(td).html(WrapDiv);
      },
    },

    {
      title: "Assigned To",
      data: "JobDoneBy",
    },
    {
      title: "Approved By",
      data: "ApprovedBy",
    },
    {
      title: "Rejected By",
      data: "RejectedBy",
    },
    {
      title: "Status",
      data: "StatusName",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = GetPeriodicStatus(`${cellData}`);

        if (rowData.RejectedBy || rowData.Status == 4) {
          $(td).parent().addClass("RejectedTableBack");
        } else if (rowData.ApprovedBy) {
          $(td).parent().css("background", "#87f093");
        } else if (rowData.JobDoneBy) {
          $(td).parent().css("background", "#ffff72");
        } else {
          $(td).parent().css("background", "#93a7fd");
        }

        $(td).html(b);
      },
    },

    {
      title: "Submit",
      data: "EquipmentID",
      visible: getPrivilege().includes("PERIODICMAINTENANCEEDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        let {
          JobAssigner,
          DownloadFlag,
          StationID,
          StationCode,
          MaintenanceTypeName,
          WeeKName,
          DepartmentID,
        } = rowData;

        let EnableEdit =
          rowData?.PeriodicCheckListMaintenanceID > 0 || JobAssigner;

        let editBtn = $(`<button class='btn icon-btn' ${
          EnableEdit ? "" : "disabled"
        } title='Edit'>
            <i class='fa fa-pencil m-r-5'></i>
          </button>`);

        if (EnableEdit) {
          editBtn.on("click", function () {
            EditPeriodicSchedule(rowData);
          });
        }

        var JobDescription =
          $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#Description" title='Job Description'  >
                      <i class=" fa fa-solid fa-calendar-check-o m-r-5"></i>
                      </button>`).on("click", function () {
            setPeriodicModel((pre) => ({
              ...pre,
              EquipmentID: rowData.EquipmentID,
              DescriptionRefreshID: uuid(),
            }));
          });

        let JobAsinEnable =
          (JobAssigner || getL0RoleID() == getUserDetails().RoleID) &&
          !["Approved", "Closed"].includes(rowData.StatusName);

        let jobAssign = $(`<button class='btn icon-btn text-center' ${
          JobAsinEnable ? "" : "disabled"
        } data-bs-toggle="modal" data-bs-target="#PeriodicJobAssign" title='Job Assign'  >
            <i class=" fa fa-solid fa-opencart m-r-5"></i>
            </button>`);

        if (JobAsinEnable) {
          jobAssign.on("click", function () {
            let CurrentView = MaintenanceTypeName.toLowerCase();
            let CurrentWeek = WeeKName?.replace(/\D/g, "") ?? -1;
            if (MaintenanceTypeName?.toUpperCase() == "DAILY") {
              CurrentView = "month";
            } else if (MaintenanceTypeName?.toUpperCase() == "QUARTERLY") {
              CurrentView = "quaterly";
            }
            const { Year, MonthID } = Model;
            const WeekStartDate = {
              1: 1,
              2: 8,
              3: 15,
              4: 21,
            };
            debugger;
            let WeekDate = WeekStartDate[CurrentWeek] ?? -1;

            const FromDate = getYMDdate(new Date(Year, MonthID, WeekDate));

            setPeriodicModel((pre) => ({
              ...pre,
              EquipmentID: rowData.EquipmentID,
              StationID: {
                label: StationCode,
                value: StationID,
              },
              DepartmentID,
              CurrentView,
              CurrentWeek,
              FromDate,
              RefreshID: uuid(),
            }));
          });
        }

        var download = "";
        if (DownloadFlag) {
          download =
            $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
                <i class='fa fa-download m-r-5'></i>
                      </button>`).on("click", function () {
              // SetIsView(true)
              DownloadFiles(rowData.PeriodicCheckListMaintenanceID, 0, 7);
            });
        } else {
          download =
            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
                <i class='fa fa-download m-r-5'></i>
                      </button>`);
        }

        $(td).html(JobDescription);
        $(td).append(jobAssign);
        $(td).append(editBtn);
        $(td).append(download);
        // $(td).append(e);
      },
    },
  ];

  useEffect(() => {
    getStationList();
  }, []);

  const getStationList = () => {
    let stationList = GetLoginStationBaseOnDep();
    let stationTypes = GetLoginStationTypes();
    let DepartmentList = Decrypt(getCookies(COOKIES.Departments));

    let Stations = [];
    let Types = [];
    let Department = [];

    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({
          key: data.label,
          ...data,
        });
      });

      stationTypes.map((data) => {
        Types.push({
          key: data.label,
          ...data,
        });
      });
    }

    if (DepartmentList?.length) {
      const itemsWithoutChildren = DepartmentList.filter(
        (item) => !DepartmentList.some((child) => child.parentId === item.id)
      );

      itemsWithoutChildren.map((data) => {
        Department.push({ value: data.id, key: data.name, Type: data.Type });
      });

      const { StationID, DepartmentID, StationTypeID } = Model;

      let FilStations = [];
      if (DepartmentID && StationID?.length && StationTypeID) {
        let TempCrdID = Types.find((ele) => ele.value == StationTypeID);
        // let TempDeptID = Department.find(
        //   (ele) => ele.value == DepartmentID
        // );

        FilStations = Stations.filter(
          (ele) =>
            ele.CorridorID == TempCrdID.value &&
            ele.DepartmentID == DepartmentID
        );
      } else {
        FilStations = Stations.filter((ele) => {
          return (
            ele.CorridorID == Types[0].value &&
            ele.DepartmentID == Department[0].value
          );
        });
      }

      disPatch(
        setInitialState({
          StationList: FilStations,
          FilterStationList: Stations,
          StationTypes: Types,
          DepartmentList: Department,
          FilterDepartmentList: Department,
        })
      );
      let iniDepID =
        DepartmentID != 0 ? DepartmentID : Department?.[0].value ?? 0;
      let iniStationID =
        StationID.length !== 0 ? StationID : [FilStations?.[0]] ?? [];
      disPatch(
        setModelState({
          DepartmentID: iniDepID,
          StationTypeID:
            StationTypeID != null ? StationTypeID : Types?.[0].value ?? 0,
          StationID: iniStationID,
        })
      );
      handleGlobalStationName(FilStations?.[0]?.label ?? "");
    }
  };

  const GetDates = () => {
    debugger;
    const { MonthID, Year, Status } = Model;
    let FromDate = new Date(Year, MonthID, 1, 6)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
    let ToDate = new Date(Year, MonthID + 1, 0, 8)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
    let StationID = Model.StationID?.map((ele) => ele.value).join(",") ?? "";
    return { FromDate, ToDate, StationID, Status };
  };

  const DownloadFiles = (ticketID, AttachmentID, Flag) => {
    _breakdown
      .GetFilesUsingTicketID(ticketID, AttachmentID, Flag)
      .then((res) => {
        if (res?.data) {
          setFileData(res.data);
        } else {
          setFileData([]);
        }
        setDownLoadRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditPeriodicSchedule = async (data) => {
    const { PeriodicCheckListMaintenanceID, DepartmentID, EquipmentID } = data;
    let EmployeeID = GetLoginUserID();

    await PeriodicMaintenance_GetEquipmentDetailForJobSave(
      PeriodicCheckListMaintenanceID,
      EmployeeID
    )
      .then((res) => {
        if (res?.data?.data?.length) {
          navigate("/periodic/Depart-periodics", {
            state: {
              PeriodicCheckListMaintenanceID,
              DepartmentID,
              FromDate: "",
              EquipmentDetails: res.data.data,
              EquipmentID,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleChange = (e, cusName) => {
    debugger;
    if (!cusName) {
      const { value, name } = e.target;
      disPatch(
        setModelState({
          [name]: value,
        })
      );
      cusName = name;
    } else {
      disPatch(
        setModelState({
          [cusName]: e,
        })
      );
    }

    if (cusName == "StationID") {
      handleGlobalStationName(e.label ?? "");
    }

    if (["StationTypeID", "DepartmentID"].includes(cusName)) {
      const { value } = e.target;

      const StationTypeID =
        cusName == "StationTypeID" ? value : Model.StationTypeID;
      const DepartmentID =
        cusName == "DepartmentID" ? value : Model.DepartmentID;

      // const fineDept = DropdownModel?.DepartmentList?.find(
      //   (ele) => ele.value == DepartmentID
      // );
      const finedType = StationTypes?.find((ele) => ele.value == StationTypeID);

      if (DepartmentID && finedType) {
        const FilStations = FilterStationList.filter(
          (ele) =>
            ele.CorridorID == finedType.value &&
            ele.DepartmentID == DepartmentID
        );

        disPatch(setInitialState({ StationList: FilStations }));

        if (FilStations?.length) {
          disPatch(
            setModelState({
              StationID: [FilStations?.[0]] ?? [],
            })
          );
        }

        handleGlobalStationName(FilStations?.[0]?.label ?? "");
      } else {
        disPatch(
          setInitialState({
            StationList: [],
          })
        );

        disPatch(
          setModelState({
            StationID: [],
          })
        );
        handleGlobalStationName("");
      }
    }
  };

  const dataManipulation = (res) => {
    if (res?.data?.length) {
      let Obj = {};
      res.data.forEach((element) => {
        if (Obj[element.StatusName]) {
          Obj[element.StatusName] = Obj[element.StatusName] + 1;
        } else {
          Obj[element.StatusName] = 1;
        }
      });

      let WrapDiv = $(`<span id='StatusID' class='m-l-15'>`);

      $("#StatusID").remove();
      Object.keys(Obj).forEach((ele, index) => {
        let separator = Object.keys(Obj).length == index + 1 ? "." : ",";

        let Span = $(`<span>${ele} - ${Obj[ele]}${separator}</span>`);
        WrapDiv.append(Span);
      });
      $(`#periodic-daily-OverAll_length`).append(WrapDiv);
    }

    return res;
  };

  const { FromDate, ToDate, StationID, Status } = GetDates();

  return (
    <div>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-12">
            <h3 className="page-title">Periodic Maintenance Overall Jobs</h3>
          </div>

          <div className="col-3">
            <Dropdown
              data={DepartmentList ?? []}
              value={Model.DepartmentID}
              placeholder={"Department"}
              name={"DepartmentID"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col-2">
            <Dropdown
              data={StationTypes ?? []}
              value={Model.StationTypeID}
              name={"StationTypeID"}
              placeholder={"Station Types"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col-2">
            <ReactMultiSelect
              options={StationList ?? []}
              isMulti
              value={Model.StationID}
              name={"StationID"}
              placeholder={"Stations"}
              onChange={(e) => HandleChange(e, "StationID")}
              allowSelectAll={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
            >
              {" "}
            </ReactMultiSelect>
          </div>
          <div className="col-2 ">
            <FilterDropdown
              code={"Year"}
              name={"Year"}
              onChange={(e) => HandleChange(e)}
              value={Model.Year}
            />
          </div>
          <div className="col-2">
            <WithoutSelectDropDown
              name={"MonthID"}
              value={Model.MonthID}
              placeholder="Select Date Range"
              HandleChange={HandleChange}
              data={ShortMonths()}
            />
          </div>
          <div className="col-2 mt-2">
            <WithoutSelectDropDown
              name={"Status"}
              value={Model.Status}
              placeholder="Select Date Range"
              HandleChange={HandleChange}
              data={[
                {
                  value: "",
                  key: "--Select--",
                },
                {
                  value: "OPEN",
                  key: "Open",
                },
                {
                  value: "CLOSED",
                  key: "Closed",
                },
                {
                  value: "APPROVED",
                  key: "Approved",
                },
                {
                  value: "REJECTED",
                  key: "Rejected",
                },
              ]}
            />
          </div>

          <DataTableWithAjax
            pageLength={10}
            stateSave={false}
            url={`${BASE_URL}PeriodicMaintenance/PeriodicMaintenanceGetEquipmentForJobAllocation?StationID=${StationID}&EmployeeID=${GetLoginUserID()}&FromDate=${FromDate}&ToDate=${ToDate}
        &DepartmentId=${Model.DepartmentID}&Status=${Status}`}
            columnList={columnNames}
            dataManipulation={dataManipulation}
            id={"periodic-daily-OverAll"}
          />
        </div>
      </div>

      <BootrapModel
        Id={"DownLoadFiles"}
        CloseID="DownLoadFilesClose"
        ModelTitle={`Periodic Maintenance - Download Files`}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            DeleteAttachment={DownloadFiles}
            FileDatas={FileData}
            DownLoadRefresh={DownLoadRefresh}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>

      <BootrapModel
        Id={"PeriodicJobAssign"}
        CloseID="PeriodicJobAssignClose"
        ModelTitle={`Periodic Job Assign`}
        modelSize={"modal-xl"}
        closepopup={() => {}}
        module={<PeriodicJobAssign></PeriodicJobAssign>}
      >
        {" "}
      </BootrapModel>
    </div>
  );
}
