import React, {useEffect} from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";

function Navbar() {
 
  return (
    <div>
        <Header />
        <Sidebar />
    </div>
  )
}

export default Navbar