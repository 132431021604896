import React from "react";
import $ from "jquery";
import {
  showSuccessMsg,
  showErrorMsg,
  showSaveError,
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState, useRef, useReducer } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import EmployeeService from "./EmployeeService";
import { Dropdown, Select } from "../../components/Dropdown";

import MasterDetailService from "../master/MasterDetailService";
import PictureUploader from "./PictureUploader";
import { render } from "@testing-library/react";
import { upload } from "@testing-library/user-event/dist/upload";
import { BASE_URL } from "../../env";
import SumoMultiSelect from "../../components/SumoMultiSelect";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";

import { showWarningMsg } from "./../../components/ToastMsg";

import { components } from "react-select";
import DepartmentFilter from "../../components/DepartmentFilter/DepartmentFilter";
import { Accordion } from "react-accessible-accordion";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
const _empServc = new EmployeeService();
const _masterDetailService = new MasterDetailService();

;

export const AddEmployee = ({
  empID,
  setempID,
  setProfilePicPath,
  picPath,
  Refresh,
  setRefresh,
  ModelREf,
  isDetails,
  setisDetails,
}) => {
  const { showLoading, hideLoading } = useContext(AppContext);
  if (!picPath) {
    picPath = "default.jpg";
  }
  const { register, handleSubmit } = useForm();
  const _breakdownService = new BreakdownMaintenanceService();
  const isAddMode = Number(empID) == 0;
  // const multiSelectRef = useRef();
  const [empdetails, setempdetails] = useState({});
  const [stationList, setStationList] = useState([]);

  const [bloodgrpList, setbloodgrpList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [systemListMatch, setsystemListMatch] = useState([]);
  const [VendorListMatch, setVendorListMatch] = useState([]);
  const [systemList, setsystemList] = useState([]);
  const [VendorList, setVendorList] = useState([]);
  const [depListMatch, setdepsystemListMatch] = useState([]);
  const [depList, setdepList] = useState([]);
  const [isAuto, setisAuto] = useState(false);
  const [isAutoVendor, setisAutoVendor] = useState(false);
  const [isAutoDep, setisAutoDep] = useState(false);
  const [selectedStation, setSelectedStation] = useState([]);
  const [stationallIds, setStationIds] = useState(null);
  const [CorridotList, setCorridotList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [CompetencyModel, setCompetencyModel] = useState({
    DepartmentIDs: "",
    CorridorID: "",
    DepartmentID: "",
    StationTypeID: "",
    TempStationTypeID: "",
    CompetencyNumber: "",
    LocationTypeID: 0,
    LocationNo: "",
    upload: "",
    ExpiryDate: "",
    TypeIDs: "",
    flag: 1,
  });
  const [SubCompetencyModel, setSubCompetencyModel] = useState({
    DepartmentIDs: "",
    CorridorID: "",
    DepartmentID: "",
    StationTypeID: "",
    TempStationTypeID: "",
    CompetencyNumber: "",
    LocationTypeID: 0,
    LocationNo: "",
    upload: "",
    ExpiryDate: "",
    TypeIDs: "",
    flag: 1,
  });
  const [errors, setErrors] = useState({
    empCodeErr: false,
    firstnameErr: false,
    lastnameErr: false,
    emailErr: false,
    contactErr: false,
    emergencyContactErr: false,
    dobErr: false,
    dojErr: false,
    designationErr: false,
    departmentErr: false,
    stationErr: false,
  });

  const animatedComponents = makeAnimated();
  const _empServc = new EmployeeService();

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1; //jan=0; feb=1 .......
    var day = dtToday.getDate();
    var year = dtToday.getFullYear() - 18;
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var minDate = year + "-" + month + "-" + day;
    var maxDate = year + "-" + month + "-" + day;
    $("#dob").attr("max", maxDate);
    $("#doj").attr("min", maxDate);
  });

  const [reset, setreset] = useReducer((x) => x + 1, 0);

  const handleFileChange = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    $.each(e.target.files, function (i, file) {
      formData.append("files", file);
    });
    if (e.target?.files.length > 0) {
      let returnValue = await fileUpload(formData);
      if (returnValue) {
        setCompetencyModel((prevState) => ({
          ...prevState,
          upload: JSON.stringify(returnValue),
        }));
      }
    } else {
      setCompetencyModel((prevState) => ({
        ...prevState,
        upload: "",
      }));
    }
  };

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  const validate = (e) => {
    e.preventDefault();

    var firstName = e.target.firstName.value;
    var lastName = e.target.lastName.value;
    var empcode = e.target.empCode.value;
    var email = e.target.email.value;
    var contactNo = e.target.ContactNum.value;
    var emergencyContact = e.target.emergencyContactNumber.value;
    var dob = e.target.dob.value;
    var doj = e.target.doj.value;
    //var department = e.target.department.value;
    var designation = e.target.designation.value;
    var stationdata = stationallIds;

    var error = errors;
    var isSuccess = true;

    const typeidss = CompetencyModel.DepartmentIDs?.split(",");

    const sectionList = DepartmentList.filter((item) =>
      typeidss.includes(item.id.toString())
    );
    

    let stationIdss = "";
    if (SubCompetencyModel?.StationID?.length > 0) {
      stationIdss = SubCompetencyModel?.StationID;
    }
    else {
      stationIdss = SubCompetencyModel?.StationTypeID;
    }
    let typeflags = false;
    if (stationIdss != "") {
      typeflags = stationIdss?.some((item) => item.Type === 95);
    }

    if (typeflags == true) {
      if (
        CompetencyModel.ExpiryDate == "" ||
        CompetencyModel.ExpiryDate == null ||
        CompetencyModel.ExpiryDate == undefined
      ) {
        error.ExpiryDate = true;
        isSuccess = false;
      } else {
        error.CompetencyModel = false;
      }

      if (
        CompetencyModel.CompetencyNumber == "" ||
        CompetencyModel.CompetencyNumber == null ||
        CompetencyModel.CompetencyNumber == undefined
      ) {
        error.CompetencyNumber = true;
        isSuccess = false;
      } else {
        error.CompetencyNumber = false;
      }
    }
    if (firstName.trim() == "") {
      error.firstnameErr = true;
      isSuccess = false;
    } else {
      error.firstnameErr = false;
    }
    if (lastName.trim() == "") {
      error.lastnameErr = true;
      isSuccess = false;
    } else {
      error.lastnameErr = false;
    }

    if (empcode.trim() == "") {
      error.empcodeErr = true;
      isSuccess = false;
    } else {
      error.empcodeErr = false;
    }
    if (email.trim() == "") {
      error.emailErr = true;
      isSuccess = false;
    } else {
      error.emailErr = false;
    }
    if (contactNo.trim() == "") {
      error.contactErr = true;
      isSuccess = false;
    } else {
      error.contactErr = false;
    }
    if (SubCompetencyModel?.DepartmentIDs == "") {
      error.departmentErr = true;
      isSuccess = false;
    } else {
      error.departmentErr = false;
    }
    if (designation.trim() == "") {
      error.designationErr = true;
      isSuccess = false;
    } else {
      error.designationErr = false;
    }

    if (emergencyContact.trim() == "") {
      error.emergencyContactErr = true;
      isSuccess = false;
    } else {
      error.emergencyContactErr = false;
    }
    if (dob.trim() == "") {
      error.dobErr = true;
      isSuccess = false;
    } else {
      error.dobErr = false;
    }
    if (doj.trim() == "") {
      error.dojErr = true;
      isSuccess = false;
    } else {
      error.dojErr = false;
    }

    if (Templist == null || Templist.length == 0) {
      if (SubCompetencyModel.DepartmentIDs == "" || SubCompetencyModel.CorridorID.length < 0 || SubCompetencyModel.StationTypeID.length < 0) {
        error.stationErr = true;
        isSuccess = false;
      }

    } else {
      error.stationErr = false;
    }

    setErrors({ ...error });
    if (isSuccess) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    $("#hidFlag").val(0);

    $("#openDiv").click(function () {
      $("#fileUpload").trigger("click");
    });

    getStationList();
    getbloodgrpList();
  }, []);
  // get Station list
  const getStationList = async () => {
    _masterDetailService.GetStationList().then((response) => {
      if (response != null && response.data.length > 0) {
        var dataLst = [];
        response.data.map((v) => {
          dataLst.push({
            value: v.stationID,
            label: v.stationCode,
            CorridorId: v.corridorId,
          });
        });
        setStationList(dataLst);
      }
    });
    await _empServc.Employee_GetCommonDropdown().then((res) => {
      setCorridotList(res.data.data.corridorList);
      setDepartmentList(res.data.data.departmentList);
    });
  };

  const getbloodgrpList = () => {
    var dataLst = [];
    dataLst.push({ key: "A +", value: 1 });
    dataLst.push({ key: "A -", value: 2 });
    dataLst.push({ key: "B +", value: 3 });
    dataLst.push({ key: "B -", value: 4 });
    dataLst.push({ key: "O +", value: 5 });
    dataLst.push({ key: "O -", value: 6 });
    dataLst.push({ key: "AB +", value: 7 });
    dataLst.push({ key: "AB -", value: 8 });

    setbloodgrpList(dataLst);
  };

  useEffect(() => {
    setempdetails({});
    document.getElementById("empAdd").reset();
    $("#bloodgroup").val(0);
    //empdetails.department=response.department;
    //$("[name=stationCode]").select2("val", `${response.stationID}`);

    $("#addr1").text("");
    $("#addr2").text("");

    if (!isAddMode) {
      _empServc
        .GetEmployeeByID(Number(empID))
        .then((response) => {
          if (empID == 0) {
            setProfilePicPath("");
          }
          $("#hidFlag").val(0);

          var stationIDs =
            response.stationID != null ? response.stationID?.split(",") : [];

          var stationArray = [];
          stationList.map((x) => {
            for (var i = 0; i < stationIDs.length; i++) {
              if (Number(stationIDs[i]) == x.value) {
                const values = {
                  label: x.label,
                  value: x.value,
                  corridorId: x.CorridorId,
                };

                stationArray.push(values);
              }
            }
          });
          const uniqueCorridorIds = [
            ...new Set(stationList.map((station) => station.CorridorId)),
          ];
          const corridorIdsString = uniqueCorridorIds.join(",");
          setStationDropdown([...stationArray]);
          const filteredCorridotList = CorridotList.filter((item) =>
            uniqueCorridorIds.includes(item.value)
          );
          setCompetencyModel((prevState) => ({
            ...prevState,
            StationTypeID: stationArray,
            CorridorId: filteredCorridotList,
          }));

          setempdetails(response);
          if (response.expiryDate != null) {
            const datePart = response.expiryDate?.split(" ")[0];
            const [day, month, year] = datePart?.split("-");
            const formattedExpiryDate = `${year}-${month}-${day}`;
            console.log(formattedExpiryDate);

            setCompetencyModel((prevState) => ({
              ...prevState,
              CompetencyNumber: response.competencyNo,
              LocationNo: response.LocationNumber,
              ExpiryDate: formattedExpiryDate,
            }));
          }
          setCompetencyModel((prevState) => ({
            ...prevState,
            CompetencyNumber: response.competencyNo,
          }));
          const { departmentID, departmentIDs, stationID, corridorIdS } = response;
          let deptID = depList.find((ele) => ele.Value == departmentID) ?? {};
          setempdetails((pre) => ({
            ...pre,
            DepartmentID: deptID,
          }));

          setCompetencyModel((prevState) => ({
            ...prevState,
            DepartmentIDs: departmentIDs,
            TempStationTypeID: stationID,
            CorridorIdS: corridorIdS

          }));

          setSubCompetencyModel((prevState) => ({
            ...prevState,
            DepartmentIDs: departmentIDs,
            TempStationTypeID: stationID,
            CorridorIdS: corridorIdS

          }));

          setStationIds(response.stationID);

          $("#addr2").text(response.address2);

          $("#bloodgroup").val(response.bloodGroup);


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [ModelREf]);

  const onSubmit = (data) => {

    if (data.target.empID.value == 0) {
      data.target.empID.value = 0;
    }
    data.preventDefault();

    if (validate(data)) {

      if ($("#hidFlag").val() == "1") {
        $("#btnUpload").trigger("click");
      }
      createEmployee(data);
    }
  };

  const saveAll = (data) => {
    onSubmit(data);
  };

  function createEmployee(data) {
    
    const CorridorIDs = SubCompetencyModel?.CorridorID?.map(item => item.value).join(',');
    let stationIdss = "";
    if (SubCompetencyModel?.StationID?.length > 0) {
      stationIdss = SubCompetencyModel.StationID.map(item => item.value).join(',');
    }
    else {
      stationIdss = SubCompetencyModel.StationTypeID.map(item => item.value).join(',');
    }
    // const stationIdss = SubCompetencyModel.StationID.map(item => item.value).join(',');
    // var stationIdss = selectedStation.join(",");
    var emp = {
      EmployeeID: Number(data.target.empID.value),
      FirstName: data.target.firstName.value,
      LastName: data.target.lastName.value,
      ProfilePicPath: $("#hidPath").val(),
      DateOfBirth: data.target.dob.value,
      DateOFJoining: data.target.doj.value,
      EmailID: data.target.email.value,
      ContactNumber: Number(data.target.ContactNum.value),
      EmergencyContactNumber: Number(data.target.emergencyContactNumber.value),
      BloodGroup: data.target.bloodgroup.value,
      Address1: data.target.addr1.value,
      Address2: data.target.addr2.value,
      Pincode: Number(641001),
      DepartmentID: Number(1),
      DesignationID: Number(1),
      Designation: data.target.designation.value,
      VendorName: data.target.vendor.value,
      StationID: stationIdss,
      IsActive: 1,
      IsDeleted: 1,
      SavedBy: GetLoginUserID(),
      EmployeeCode: data.target.empCode.value,
      Department: SubCompetencyModel.DepartmentID,
      ExpiryDate: data.target.ExpiryDate.value,
      CompetencyNo: CompetencyModel.CompetencyNumber,
      UploadPath: SubCompetencyModel.upload,
      DepartmentIDs: SubCompetencyModel.DepartmentIDs,
      LocationNumber: SubCompetencyModel.LocationNo,
      LocationTypeID: SubCompetencyModel.LocationTypeID,
      CorridorIdS: CorridorIDs,
    };

    console.log(emp);
    _empServc
      .SaveEmployeeMaster(emp)
      .then((response) => {
        // var count = Refresh;
        // setRefresh(++count);

        // setCompetencyModel((prevState) => ({
        //   ...prevState,
        //   StationTypeID: "",
        //   DepartmentID: "",
        //   CorridorID: "",
        //   StationTypeID: "",
        //   LocationTypeID: "",
        //   LocationNo: "",
        //   CompetencyNumber: "",
        //   upload: "",
        //   ExpiryDate: "",
        //   flag: 1,
        // }));
        setCompetencyModel([]);

        $("#closepopupmodel").trigger("click");
        showSuccessMsg("Employee saved successfully");
      })
      .catch((error) => {
        if (error.response.data == "Empcode") {
          showWarningMsg("Employee code already exists");
          return false;
        }
        if (error.response.data == "Email") {
          showWarningMsg("Email id already exists");
          return false;
        } else {
          console.log(error);
          showSaveError();
        }
      });
  }



  $(function () {
    $("#File").change(function (e) {
      var img = URL.createObjectURL(e.target.files[0]);
      $(".image").attr("src", img);
    });
  });

  const closepopup = () => {
    $("p.text-danger").hide();
    var count1 = Refresh;
    setRefresh(++count1);
    $("#hidFlag").val(0);
    setreset();
    // setProfilePicPath("default.jpg");
    setisAutoDep(false);
    setisAuto(false);
    setisDetails(null);
    setSelectedStation([]);
    getStationList();
    $("#bloodgroup").val(0);
    setStationDropdown([]);
    setempdetails((pre) => ({
      ...pre,
      DepartmentID: [],
    }));
    // setCompetencyModel((prevState) => ({
    //   ...prevState,
    //   CompetencyNumber: "",
    //   upload: "",
    //   ExpiryDate: "",
    //   EmployeeID: empdetails.employeeID

    // }));
    setCompetencyModel((prevState) => ({
      ...prevState,
      DepartmentIDs: "",
      CorridorID: "",
      DepartmentID: "",
      StationTypeID: "",
      TempStationTypeID: "",
      CompetencyNumber: "",
      LocationTypeID: 0,
      LocationNo: "",
      upload: "",
      ExpiryDate: "",
      TypeIDs: "",
      EmployeeID: empdetails.employeeID,
      flag: 1,

    }));

  };

  const searchSystem = (text) => {

    setisAuto(true);
    errors.designationErr = false;
    let matches = systemList.filter((system) => {
      const regex = new RegExp(`${text}`, "gi");
      return system.designation.match(regex);
    });
    setsystemListMatch(matches);
  };

  const searchVendorSystem = (text) => {
    setisAutoVendor(true);

    // errors.designationErr = false;
    let matches = VendorList.filter((system) => {

      const regex = new RegExp(`${text}`, "");
      return system.VendorName.match(regex);
    });
    setVendorListMatch(matches);
  };
  const searchDeptSystem = (text) => {
    setisAutoDep(true);
    errors.departmentErr = false;

    let matches = depList.filter((system) => {
      const regex = new RegExp(`${text}`, "gi");
      return system.department.match(regex);
    });
    setdepsystemListMatch(matches);
  };
  useEffect(() => {
    _empServc
      .GetSystemType()
      .then((response) => {
        setsystemList(response.data);
      })
      .catch((error) => { });
    _empServc
      .Employee_GetVendorList()
      .then((response) => {

        setVendorList(response.data.data);
      })
      .catch((error) => { });
  }, [Refresh]);

  useEffect(() => {
    _empServc
      .GetDeptList()
      .then((response) => {
        if (response?.data?.data?.length) {
          setdepList(response.data.data);
        } else {
          setdepList([]);
        }
      })
      .catch((error) => { });
  }, [Refresh]);

  function setAutoComplete(val) {
    $("#designation").val(val);

    setisAuto(false);
  }

  function setAutoCompleteVendor(val) {
    $("#vendor").val(val);

    setisAutoVendor(false);
  }
  function setAutoCompleteDept(val) {
    $("#department").val(val);

    setisAutoDep(false);
  }
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const [Templist, setStationDropdown] = useState([]);

  $("#ContactNum").keypress(function (event) {
    var keycode = event.which;
    if (
      !(
        event.shiftKey == false &&
        (keycode == 46 ||
          keycode == 8 ||
          keycode == 37 ||
          keycode == 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    ) {
      event.preventDefault();
    }
  });
  $("#emergencyContactNumber").keypress(function (event) {
    var keycode = event.which;
    if (
      !(
        event.shiftKey == false &&
        (keycode == 46 ||
          keycode == 8 ||
          keycode == 37 ||
          keycode == 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    ) {
      event.preventDefault();
    }
  });
  const CommonHandleChange = (e) => {

    const { name, value } = e.target;
    setCompetencyModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const FilterChange = (name, value) => {
    if (name == "StationID") {
      var val = [];
      value.map((v, i) => val.push(v.value));
      // console.log(val.join(','));
      setStationIds(val.join(","));
      setStationDropdown(value);
    }

    setSubCompetencyModel((prevState) => ({
      ...prevState,
      [name]: value,

    }));
  };

  return (
    <div
      id="add-emp"
      className="modal custom-modal fade"
      role="dialog"
      aria-modal="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {isDetails == 1
                ? "Employee Detail"
                : empdetails.employeeID > 0
                  ? "Edit Employee"
                  : "Add Employee"}
            </h5>
            <button
              type="button"
              className="close"
              id="closepopupmodel"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => closepopup()}
            >
              {" "}
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form id="empAdd" onSubmit={saveAll}>
              <input
                type="hidden"
                id="hidPath"
                defaultValue={empdetails.profilePicPath}
              />
              <div className="row">

                <div className="col-md-8">
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="empCode">
                          Employee Code <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={50}
                          disabled={isDetails == 1}
                          id="empCode"
                          defaultValue={empdetails.employeeCode}
                        />
                        {errors.empcodeErr && (
                          <p className="text-danger">Employee code is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="hidden" id="hidFlag" value="0" />
                        <input
                          type="hidden"
                          id="empID"
                          defaultValue={
                            empdetails.employeeID ? empdetails.employeeID : 0
                          }
                        />
                        <label className="col-form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          maxLength={100}
                          disabled={isDetails == 1}
                          defaultValue={empdetails.firstName}
                        />
                        {errors.firstnameErr && (
                          <p className="text-danger">First name is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          maxLength={100}
                          disabled={isDetails == 1}
                          defaultValue={empdetails.lastName}
                        />
                        {errors.lastnameErr && (
                          <p className="text-danger">Last name is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Designation <span className="text-danger">*</span>
                        </label>
                        <input type="hidden" id="hidDesignation" />

                        <input
                          className="form-control"
                          type="text"
                          id="designation"
                          maxLength={100}
                          disabled={isDetails == 1}
                          defaultValue={empdetails.designation}
                          onChange={(e) => searchSystem(e.target.value)}
                        />

                        <div className="col-3" style={{ position: "absolute" }}>
                          {isAuto &&
                            systemListMatch &&
                            systemListMatch.map((item, index) => (
                              <li
                                className="autocomplete"
                                onClick={() => setAutoComplete(item.designation)}
                              >
                                {item.designation}
                              </li>
                            ))}
                        </div>
                        {errors.designationErr && (
                          <p className="text-danger">Designation is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Vendor
                        </label>
                        <input type="hidden" id="hidDesignation" />

                        <input
                          className="form-control"
                          type="text"
                          id="vendor"
                          maxLength={100}
                          disabled={isDetails == 1}
                          defaultValue={empdetails.vendor}
                          onChange={(e) => searchVendorSystem(e.target.value)}
                        />

                        <div className="col-3" style={{ position: "absolute" }}>
                          {isAutoVendor &&
                            VendorListMatch &&
                            VendorListMatch.map((item, index) => (
                              <li
                                className="autocomplete"
                                onClick={() => setAutoCompleteVendor(item.VendorName)}
                              >
                                {item.VendorName}
                              </li>
                            ))}
                        </div>
                        {/* {errors.designationErr && (
                          <p className="text-danger">Designation is required</p>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Contact <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={10}
                          id="ContactNum"
                          disabled={isDetails == 1}
                          defaultValue={empdetails.contactNumber}
                        />
                        {errors.contactErr && (
                          <p className="text-danger">Contact number is required</p>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                  <PictureUploader
                    images={picPath}
                    Refresh={Refresh}
                    reset={reset}
                  ></PictureUploader>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Emergency Contact Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      maxLength={10}
                      disabled={isDetails == 1}
                      id="emergencyContactNumber"
                      defaultValue={empdetails.emergencyContactNumber}
                    />
                    {errors.emergencyContactErr && (
                      <p className="text-danger">
                        Emergency contact number is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Email ID <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      maxLength={300}
                      disabled={isDetails == 1}
                      defaultValue={empdetails.emailID}
                    />

                    {errors.emailErr && (
                      <p className="text-danger">Email ID is required</p>
                    )}
                  </div>
                </div>


                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Blood Group</label>
                    <Dropdown
                      placeholder="--Select Blood group--"
                      name="bloodgroup"
                      data={bloodgrpList}
                      initialVal={0}
                      disabled={isDetails == 1}
                    // value={empdetails.bloodGroup}
                    />
                  </div>
                </div>



                <DepartmentFilter
                  EditDropDown={CompetencyModel}
                  isSmall={false}
                  HandleChange={FilterChange}
                ></DepartmentFilter>
                {errors.departmentErr && (
                  <p className="text-danger">Department is required</p>
                )}
                {errors.stationErr && (
                  <p className="text-danger">Station is required</p>
                )}


                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      DOB <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control"
                        type="date"
                        id="dob"
                        disabled={isDetails == 1}
                        defaultValue={empdetails.dateOfBirth}
                      />
                      {errors.dobErr && (
                        <p className="text-danger">Date of birth is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      DOJ <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="doj"
                      disabled={isDetails == 1}
                      defaultValue={empdetails.dateOFJoining}
                    />
                    {errors.dojErr && (
                      <p className="text-danger">Date of joining is required</p>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Competency Document
                    </label>
                    <input type="file" onChange={handleFileChange} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Competency Number</label>
                    <input
                      className="form-control"
                      type="text"
                      value={CompetencyModel.CompetencyNumber}
                      name="CompetencyNumber"
                      maxLength={100}
                      id="CompetencyNumber"
                      onChange={CommonHandleChange}
                    />
                    {errors.CompetencyNumber && (
                      <p className="text-danger">
                        Competency number is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Competency ExpiryDate
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      value={CompetencyModel.ExpiryDate}
                      name="ExpiryDate"
                      id="ExpiryDate"
                      onChange={CommonHandleChange}
                    />
                    {errors.ExpiryDate && (
                      <p className="text-danger">
                        Competency expiryDate is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Address 1</label>
                        <textarea
                          className="form-control"
                          row={5}
                          maxLength={250}
                          disabled={isDetails == 1}
                          defaultValue={empdetails.address1}
                          id="addr1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Address 2</label>
                    <textarea
                      className="form-control"
                      disabled={isDetails == 1}
                      row={5}
                      maxLength={250}
                      defaultValue={empdetails.address2}
                      id="addr2"
                    />
                  </div>
                </div>



              </div>

              {isDetails != 1 && isDetails == null ? (
                <div className="submit-section">
                  <button type="submit" className="btn btn-primary submit-btn">
                    Submit
                  </button>
                </div>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
