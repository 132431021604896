import React, { useState, useEffect, useContext } from "react";
import { TabBar } from "./../../components/TabBar";
import { PageHeader } from "./../../components/PageHeader";
import { DesignDetails } from "./DesingDetails";
import { CommissionedDetails } from "./CommissionedDetails";
import MasterDetailService from "../master/MasterDetailService";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";
//Tab heaer list
const TabHeader = ["Equipment Design Details", "Commissioned Details"];

const MDS = new MasterDetailService();
//Tab content

const animatedComponents = makeAnimated();

export const DesingAndCommission = () => {
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState(0);
  const [StationIDs, SetStationIDs] = useState("");

  useEffect(() => {
    getStationList();
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);
    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };

  const TabContent = [
    <DesignDetails StationIDs={StationIDs} />,
    <CommissionedDetails StationIDs={StationIDs} />,
  ];

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map((x) => x.label).join();
    var stationIds = e.map((x) => {
      return x.value;
    });
    handleGlobalStationName(StationLabel);
    SetStationIDs(stationIds.toString());
  };
  return (
    <>
      <div className="col-4" style={{ float: "right" }}>
        <ReactMultiSelect
          options={StationList}
          isMulti
          value={StationID}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={(e) => HandleChange(e)}
          allowSelectAll={true}
        />
      </div>

      <PageHeader title=" Equipment Design" />
      <div className="card mt-3">
        <TabBar header={TabHeader} body={TabContent} />
      </div>
    </>
  );
};
