import Axios from "../../services/AxiosService";

const axios = new Axios();

/**
 * AlertConfig_SaveAlertConfiguration
 * 
 * This function sends a POST request to save an alert configuration.
 *
 * @param {Object} data - The configuration data to be saved.
 * @returns {Promise<AxiosResponse>} - The Axios response after saving the configuration.
 */
const AlertConfig_SaveAlertConfiguration = async(data) => {

    return await axios.post("MasterConfig/AlertConfig_SaveAlertConfiguration", data)
}


/**
 * AlertConfig_GetById
 * 
 * This function sends a GET request to fetch an alert configuration by its ID.
 *
 * @param {Number} delayId - The ID of the configuration to be fetched.
 * @param {Number} isDeleted - Filter flag to determine if the configuration is deleted or not.
 * @returns {Promise<AxiosResponse>} - The Axios response containing the requested configuration.
 */
const AlertConfig_GetById = async(delayId,isDeleted) => {

    return await axios.get(`MasterConfig/AlertConfig_GetById?delayId=${delayId}&isDeleted=${isDeleted}`)
}
export {AlertConfig_SaveAlertConfiguration,AlertConfig_GetById}