import React, { useContext } from "react";
import $ from "jquery";
import { HtmlTable } from "../../components/HtmlTable";
import {
  PeriodicConfiguration_GetDescription,
  PeriodicConfiguration_GetDropDown,
} from "../PeriodicMaintenance/PeriodicService";
import { useEffect } from "react";
import swal from "sweetalert";
import { findDepartmentById, getPrivilege } from "../../services/CommonService";
import Global from "../../services/Global";
import {
  AppContext,
  DefaultAppContextValues,
} from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BootrapModel, Dropdown, MultiValue, Option, ReactMultiSelect, animatedComponents } from "../../components";
import PeriodicConfigSave from "./PeriodicConfigSave";
import { PeriodicConfigurationContext } from "./ContextProvider";
import { BuildTreeUsingParentID } from "../../services/CommonService";
import { v4 as uuidv4 } from "uuid";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";

export default function MainTable() {
  const { getCookies } = DefaultAppContextValues();
  const {
    handleGlobalStationName,
    GetLoginStationTypes,
    GetLoginStationList,
  } = useContext(AppContext);

  const {
    setDropdownModel,
    DropdownModel,
    setPeriodicConfigModel,
    InitialState,
    FilterObj,
    setFilterObj,
  } = useContext(PeriodicConfigurationContext);

  const { StationList, FilterDepartmentList, FilterEquipmentList, EquipmentTypeList,FilterstationLists } =
    DropdownModel;

  const columnNamesForDaily = [
    { title: "S.No", data: "RowId" },
    { title: "Order No", data: "OrderNo" },
    // { title: "Department", data: "DepartmentName" },
    // { title: "Station Code", data: "StationCode" },
    { title: "Equipment", data: "EquipmentShortName" },
    { title: "Equipment Type", data: "SystemTypeName" },
    { title: "Schedule Type", data: "ScheduleTypeName" },
    { title: "Week", data: "WeekName" },
    { title: "Description", data: "DescriptionName" },
    { title: "Component", data: "ComponentName" },
    { title: "IsCritical", data: "IsCriticalName" },
    { title: "Uom", data: "UOM" },
    { title: "Status Option 1", data: "StatusOption1" },
    { title: "Status Option 2", data: "StatusOption2" },
    {
      title: "Submit  ",
      data: "PeriodicID",
      createdCell: (td, cellData, rowData) => {
        var EditBtn = "",
          deleteBtn = "";

        if (getPrivilege().includes("PERIODICCONFIGURATIONEDIT")) {
          EditBtn = $(`<button class='btn icon-btn text-center' title='Edit'
          data-bs-toggle="modal"
          data-bs-target="#PeriodicConfigSave"
          >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
            GetDescriptionUsingId(rowData.PeriodicID);
          });
        }
        if (getPrivilege().includes("PERIODICCONFIGURATIONDELETE")) {
          deleteBtn = $(`<button class='btn icon-btn text-center' 
          title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
              </button>`).on("click", function () {
            Swal(rowData.PeriodicID);
          });
        }

        $(td).html(EditBtn);
        $(td).append(deleteBtn);
      },
    },
  ];

  function Swal(PeriodicID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this configuration?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await GetDescriptionUsingId(PeriodicID, 1);
        setFilterObj((pre) => ({
          ...pre,
          RefreshID: uuidv4()
        }))
      }
    });
  }

  useEffect(() => {
    GetDropdown();
  }, []);

  const GetDropdown = async () => {
    await PeriodicConfiguration_GetDropDown()
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList, weekList, scheduleList, departmentList, equipmentTypeList } =
            res?.data.data;
          if (
            equipmentList?.length &&
            weekList?.length &&
            scheduleList?.length
          ) {
            var FilterstationList = Decrypt(getCookies(Global.COOKIES.Stations));
            var dataLst = [];
            let stationList = GetLoginStationList();
            let stationTypes = GetLoginStationTypes();
            let DepartmentListforDrop = Decrypt(getCookies(Global.COOKIES.Departments));
            FilterstationList.map((data) => {
              dataLst.push({ value: data.StationID, label: data.StationCode, key: data.StationCode,Type: data.Type });
            });
            let Stations = [];
            let Types = [];
            let Department = [];
            if (stationList?.length) {
              stationList.map((data) => {
                Stations.push({
                  key: data.label,
                  ...data,
                });
              });

              stationTypes.map((data) => {
                Types.push({
                  key: data.label,
                  ...data,
                });
              });


            }
            let treeJSON = BuildTreeUsingParentID(departmentList);
            let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
            let FilterConcatDepartList = [];
            let FilDepart = Decrypt(getCookies(Global.COOKIES.Departments));
            if (FilDepart?.length) {
              const itemsWithoutChildren = FilDepart.filter(
                (item) => !FilDepart.some((child) => child.parentId === item.id)
              );

              itemsWithoutChildren.map((data) => {
                FilterConcatDepartList.push({ value: data.id, key: data.name, Type: data.Type });
              });
              setFilterObj((pre) => ({
                ...pre,
                DepartmentID: FilterConcatDepartList[0].value,
                StationID: dataLst[0]
              }))


            }
            if (DepartmentListforDrop?.length) {
              const itemsWithoutChildren = DepartmentListforDrop.filter(
                (item) => !DepartmentListforDrop.some((child) => child.parentId === item.id)
              );

              itemsWithoutChildren.map((data) => {
                Department.push({ value: data.id, key: data.name, Type: data.Type });
              });
              let FilStations = Stations.filter(
                (ele) =>
                  ele.CorridorID == Types[0].value && ele.Type == Department[0].Type
              );


              setDropdownModel((pre) => ({
                ...pre,
                StationList: FilStations,
                FilterstationLists: dataLst,
                SaveStationList : [],
                FilterStationList: Stations,
                StationTypes: Types,
                FilterConcatDepartList: Department,
                // FilterDepartmentList: Department,
              }));
              // const { StationID, DepartmentID, StationTypeID } = FilterObj;

              setFilterObj((pre) => ({
                ...pre,
                DepartmentID: Department?.[0].value ?? 0,
                StationTypeID: Types?.[0].value ?? 0,
                StationID: FilStations?.[0] ?? {},

              }));
              handleGlobalStationName(FilStations[0].label);
            }

            let DepartmentList = [
              {
                id: uuidv4(),
                name: "Select",
                children: Data,
              },
            ];

            Data.forEach((ele) => (ele.isOpen = true));

            setDropdownModel((pre) => ({
              ...pre,
              // StationList: dataLst,
              FilterEquipmentList: equipmentList,
              EquipmentList: [],
              WeekList: weekList,
              ScheduleList: scheduleList,
              FilterDepartmentList: DepartmentList,
              // FilterConcatDepartList,
              EquipmentTypeList: equipmentTypeList,
              DepartmentList: [],
              StationTypes: stationTypes
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const FixedColum = {
    left: 1,
    right: 1,
  };

  const GetDescriptionUsingId = async (id = 0, IsDeleted = 0) => {
    await PeriodicConfiguration_GetDescription(id, IsDeleted)
      .then((res) => {
        if (res?.data?.data) {
          const { data } = res.data;
          if (id > 0 && IsDeleted == 0) {
            manipulateModel(data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function manipulateModel(data) {
    if (data?.length) {
      const singleObj = data[0];
      
      const { DepartmentId, EquipmentID, SystemTypeID } = singleObj;
      let StationIDs = singleObj.StationIds.split(",").map((ele) =>
        parseInt(ele)
      );
      if (StationList?.length) {
        let SelectedStationList = StationList.filter((ele) => {
          return StationIDs.includes(ele.value);
        });
        singleObj.StationID = SelectedStationList;
      }
      if (EquipmentTypeList?.length) {
        let SelectedEquipmentList = EquipmentTypeList.find(
          (ele) => ele.value == SystemTypeID
        );
        singleObj.SystemTypeID = SelectedEquipmentList;
      }
      if (FilterDepartmentList?.length) {
        let SelectedDepartment = findDepartmentById(
          FilterDepartmentList,
          DepartmentId
        );
        let copyDepartmentList = JSON.parse(
          JSON.stringify(FilterDepartmentList)
        );

        let SaveStationList =FilterstationLists.filter((ele) => ele.Type == SelectedDepartment.Type)

        copyDepartmentList[0].id = SelectedDepartment.id;
        copyDepartmentList[0].name = SelectedDepartment.name;
        setDropdownModel((pre) => ({
          ...pre,
          DepartmentList: [...copyDepartmentList],
          SaveStationList
        }));
      }

      if (FilterEquipmentList?.length) {
        let SelectedEquipment = FilterEquipmentList.find(
          (ele) => ele.value == EquipmentID
        );
        singleObj.EquipmentID = SelectedEquipment;
      }
      
      setPeriodicConfigModel((pre) => ({
        ...pre,
        ...singleObj,
      }));
    }
  }
  const HandleChange = (e, name) => {

    // if (cusName == "StationID") {
    //   handleGlobalStationName(e.target.selectedOptions?.[0]?.label ?? "");
    // }
    if (["StationTypeID", "Department"].includes(name)) {
      const { value } = e.target;

      const StationTypeID =
        name == "StationTypeID" ? value : FilterObj.StationTypeID;
      const DepartmentID =
        name == "Department" ? value : FilterObj.DepartmentID;

      const fineDept = DropdownModel?.FilterConcatDepartList?.find(
        (ele) => ele.value == DepartmentID
      );
      const finedType = DropdownModel?.StationTypes?.find(
        (ele) => ele.value == StationTypeID
      );

      if (fineDept && finedType) {
        const FilStations = DropdownModel?.FilterStationList.filter(
          (ele) =>
            ele.CorridorID == finedType.value && ele.Type == fineDept.Type
        );

        setDropdownModel((pre) => ({
          ...pre,
          StationList: FilStations,
        }));

        setFilterObj((pre) => ({
          ...pre,
          StationID: FilStations?.[0] ?? {},
          StationTypeID: name == "StationTypeID" ? value : StationTypeID,
          DepartmentID: name == "Department" ? value : DepartmentID,
        }));

        handleGlobalStationName(FilStations?.[0]?.label ?? "");
      } else {
        setDropdownModel((pre) => ({
          ...pre,
          StationList: [],
        }));

        setFilterObj((pre) => ({
          ...pre,
          StationID: {},
        }));
        handleGlobalStationName("");
      }
    }
  };
  function createButton() {
    const { DepartmentID, StationID } = FilterObj;
    if (FilterObj.DepartmentID != 0) {
      let DepartmentListforDrop = Decrypt(getCookies(Global.COOKIES.Departments));
      let department = DepartmentListforDrop.find((e) => e.id == DepartmentID);
      let DepartmentName = department ? department.name : '';
      let stationName = StationID?.label;
      let label1 = $(
        `<button class="shattered-glass-button m-l-15">${DepartmentName} - ${stationName}</button>`
      );

      // let label2 = $(
      //   `<button class="shattered-glass-button m-l-15">${stationName}</button>`
      // );

      // Append both buttons to the existing container
      $('#PeriodicConfigTable1_length').append(label1);
    }
  }


  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <div class="page-header mb-3">
                <div class="row justify-content-between">
                  <h3 class="page-title">
                    Periodic Maintenance Schedule Configuration
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-2">
              <Dropdown
                data={DropdownModel?.FilterConcatDepartList ?? []}
                value={FilterObj.DepartmentID}
                placeholder={"Department"}
                HandleChange={(e) => {
                  HandleChange(e, 'Department')
                }}
              >
                {" "}
              </Dropdown>
            </div>
            <div className="col-2">
              <Dropdown
                data={DropdownModel?.StationTypes ?? []}
                value={FilterObj.StationTypeID}
                name={"StationTypeID"}
                placeholder={"Station Types"}
                HandleChange={(e) => {
                  HandleChange(e, 'StationTypeID')
                }}
              >
                {" "}
              </Dropdown>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <ReactMultiSelect
                  options={StationList}
                  placeholder={"Stations"}
                  value={FilterObj.StationID}
                  onChange={(e) => {
                    setFilterObj((pre) => ({
                      ...pre,
                      StationID: e,
                    }));
                  }}
                />

              </div>
            </div>

            <div className="col-2">
              <button
                className="btn btn-primary float-end"
                data-bs-toggle="modal"
                data-bs-target="#PeriodicConfigSave"
                onClick={() => {
                  
                  setPeriodicConfigModel(InitialState);

                  setDropdownModel((pre) => ({
                    ...pre,
                    DepartmentList: FilterDepartmentList,
                  }));
                }}
              >
                <IoIosAddCircleOutline size={20} /> Add{" "}
              </button>
            </div>
          </div>

          <DataTableReportAjax
            columnList={columnNamesForDaily}
            IsExcel={false}
            IsShowPDF={false}
            pageLength={10}
            ShowLengthPostponedHtml={createButton}
            StationID={FilterObj?.StationID?.value ?? 0}
            DepartmentID={FilterObj?.DepartmentID ?? 0}
            FromDate={FilterObj.RefreshID}
            url={`${BASE_URL}PeriodicMaintenance/PeriodicConfiguration_GetDescriptionForGrid`}
            FixedColums={FixedColum}
            id={"PeriodicConfigTable1"}
          />
        </div>
      </div>

      <BootrapModel
        modelSize={"modal-xl"}
        Id={"PeriodicConfigSave"}
        ModelTitle={"Periodic Maintenance Schedule Configuration"}
        CloseID={"ClosePeriodicConfigSave"}
        closepopup={() => {
          setDropdownModel((pre) => ({
            ...pre,
            ErrorObj: null,
          }));
          setFilterObj((pre) => ({
            ...pre,
            RefreshID: uuidv4()
          }))
        }}
        module={<PeriodicConfigSave />}
      ></BootrapModel>
    </>
  );
}
