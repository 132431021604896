import React, { useState } from "react";
import underGround from "../../../assets/img/underGround.png";
import makeAnimated from "react-select/animated";

import { ReactMultiSelect } from "../../../components";
import { MultiValue, Option } from "../../../components";
import { AllStationList } from "../TempData";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const EquipmentAvailability = (clickable, onDoubleClick) => {
  const animatedComponents = makeAnimated();
  const [FilterModel, setFilterModel] = useState({
    Departments: [],
    FromDate: "",
    ToDate: "",
    Corridor: "",
    Frequency: "",
    Stations: [],
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-7">
          <div className="row">
            <div className="col-3">
              <h3 style={{ whiteSpace: "nowrap" }}>Preventive Maintenance</h3>
            </div>
            <div
              className="col-3"
              style={{ position: "relative", left: "56px" }}
            >
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "E&M", value: 1 },
                  { label: "Civil Maintenance", value: 2 },
                  { label: "Lift & esculator", value: 3 },
                  { label: "OHE", value: 4 },
                  { label: "Track", value: 5 },
                  { label: "Panel", value: 6 },
                  { label: "Lighting", value: 7 },
                ]}
                placeholder={"Departments"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Departments: e }))
                }
                value={FilterModel.Departments}
              ></ReactMultiSelect>
            </div>
            <div
              className="col-3"
              style={{ position: "relative", left: "47px" }}
            >
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "Lift", value: 1 },
                  { label: "Esculator", value: 2 },
                ]}
                placeholder={"Equipment"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Equipment: e }))
                }
                value={FilterModel.Equipment}
              ></ReactMultiSelect>
            </div>
            <div
              className="col-3"
              style={{ position: "relative", left: "37px" }}
            >
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "C1 - UG", value: 1 },
                  { label: "C1 - Elevated", value: 2 },
                  { label: "C2 - UG ", value: 3 },
                  { label: "C2 - Elevated", value: 4 },
                ]}
                placeholder={"Corridor"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Corridor: e }))
                }
                value={FilterModel.Corridor}
              ></ReactMultiSelect>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="row" style={{ position: "relative", left: "27px" }}>
            <div className="col-4">
              <ReactMultiSelect
                isMulti
                options={AllStationList}
                placeholder={"All Stations"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Stations: e }))
                }
                value={FilterModel.Stations}
              ></ReactMultiSelect>
            </div>
            <div
              className="col-8"
              style={{ position: "relative", right: "7px" }}
            >
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY" // Specify the date format
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row mt-1">
            <ol
              class="breadcrumb text-muted mb-0 col-12"
              style={{ padding: "0 13px" }}
            >
              <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Departments?.length > 0
                    ? FilterModel.Departments.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Departments"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Equipment?.length > 0
                    ? FilterModel.Equipment.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Equipment"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Corridor?.length > 0
                    ? FilterModel.Corridor.map((ele) => ele.label).toString(",")
                    : "All Corridors"}
                </a>
              </li>
              <li class="breadcrumb-item text-muted">
                {FilterModel.Stations?.length > 0
                  ? FilterModel.Stations.map((ele) => ele.label).toString(",")
                  : "All Stations"}
              </li>
            </ol>

            <div className="col-4"></div>
          </div>
        </div>
      </div>

      {/* <div className="row mb-2">
        <div className="col-3">
          <h3>Preventive Maintenance</h3>
        </div>
        <div className="col-2">
          <ReactMultiSelect
            isMulti
            options={[
              { label: "E&M", value: 1 },
              { label: "Civil Maintenance", value: 2 },
              { label: "Lift & esculator", value: 3 },
              { label: "OHE", value: 4 },
              { label: "Track", value: 5 },
              { label: "Panel", value: 6 },
              { label: "Lighting", value: 7 },
            ]}
            placeholder={"Departments"}
            components={{ Option, MultiValue, animatedComponents }}
            allowSelectAll
            onChange={(e) =>
              setFilterModel((pre) => ({ ...pre, Departments: e }))
            }
            value={FilterModel.Departments}
          ></ReactMultiSelect>
        </div>

        <div className="col-2">
          <ReactMultiSelect
            isMulti
            options={[
              { label: "C1 - UG", value: 1 },
              { label: "C1 - Elevated", value: 2 },
              { label: "C2 - UG ", value: 3 },
              { label: "C2 - Elevated", value: 4 },
            ]}
            placeholder={"Corridor"}
            components={{ Option, MultiValue, animatedComponents }}
            allowSelectAll
            onChange={(e) => setFilterModel((pre) => ({ ...pre, Corridor: e }))}
            value={FilterModel.Corridor}
          ></ReactMultiSelect>
        </div>

        <div className="col-2">
          <ReactMultiSelect
            isMulti
            options={AllStationList}
            placeholder={"All Stations"}
            components={{ Option, MultiValue, animatedComponents }}
            allowSelectAll
            //allOption
            onChange={(e) => setFilterModel((pre) => ({ ...pre, Stations: e }))}
            value={FilterModel.Stations}
          ></ReactMultiSelect>
        </div>
        <div className="col-md-3">
          <DateRangePicker
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat="DD/MM/YYYY" // Specify the date format
          />
        </div>

        <div className="col-12">
          <div className="row mt-1">
            <ol
              class="breadcrumb text-muted mb-0 col-12"
              style={{ padding: "0 13px" }}
            >
              <li class="breadcrumb-item">
                <a href="#">Preventive Maintenance</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Departments?.length > 0
                    ? FilterModel.Departments.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Departments"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Corridor?.length > 0
                    ? FilterModel.Corridor.map((ele) => ele.label).toString(",")
                    : "All Corridors"}
                </a>
              </li>
              <li class="breadcrumb-item text-muted">
                {FilterModel.Stations?.length > 0
                  ? FilterModel.Stations.map((ele) => ele.label).toString(",")
                  : "All Stations"}
              </li>
            </ol>

            <div className="col-4"></div>
          </div>
        </div>
      </div> */}

      <div class="kanban-board card mb-0">
        <div class="card-body">
          <div class="kanban-cont">
            <div class="kanban-list kanban-warning">
              {" "}
              <div class="kanban-header" style={{ background: "#ff9f40" }}>
                <span class="status-title">Overdue</span>
              </div>
              <div className="kanban-wrap" style={{ background: "#fbf0e5" }}>
                <KanbanCard
                  count={"01"}
                  code="SCR"
                  bgsColor={"#fbe3d3"}
                  DepartmentsStr={"lift..."}
                />
                <KanbanCard
                  count={"02"}
                  code="SSA"
                  bgsColor={"#fbe3d3"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"01"}
                  code="SSA"
                  bgsColor={"#fbe3d3"}
                  DepartmentsStr={" lift..."}
                />
                {/* <KanbanCard
                  count={"04"}
                  code="SGE"
                  bgsColor={"#fbe3d3"}
                  DepartmentsStr={" lift..."}
                /> */}
              </div>
            </div>

            <div
              class="kanban-list kanban-info"
              style={{ background: "#e7ecff" }}
            >
              <div class="kanban-header" style={{ background: "#93a7fd" }}>
                <span class="status-title">Open</span>
                <div class="dropdown kanban-action"></div>
              </div>
              <div class="kanban-wrap">
                <KanbanCard
                  count={"01"}
                  code="SCC"
                  bgsColor={"#c0ccff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"01"}
                  code="SEG"
                  bgsColor={"#c0ccff"}
                  DepartmentsStr={" lift..."}
                />
                {/* <KanbanCard
                  count={"15"}
                  code="STC"
                  bgsColor={"#c0ccff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"14"}
                  code="SCR"
                  bgsColor={"#c0ccff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"25"}
                  code="SGM"
                  bgsColor={"#c0ccff"}
                  DepartmentsStr={" lift..."}
                /> */}
              </div>
            </div>

            <div
              class="kanban-list kanban-success"
              style={{ background: "#ecfbfe" }}
            >
              <div class="kanban-header" style={{ background: "#83d0e1" }}>
                <span class="status-title">Completed</span>
              </div>
              <div class="kanban-wrap ks-empty">
                <KanbanCard
                  count={"04"}
                  code="SCR"
                  bgsColor={"#ccf6ff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"03"}
                  code="STE"
                  bgsColor={"#ccf6ff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"03"}
                  code="SGM"
                  bgsColor={"#ccf6ff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"02"}
                  code="SCR"
                  bgsColor={"#ccf6ff"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"04"}
                  code="SLI"
                  bgsColor={"#ccf6ff"}
                  DepartmentsStr={" lift..."}
                />
              </div>
            </div>

            <div
              class="kanban-list kanban-purple"
              style={{ background: "#e8ffeb" }}
            >
              <div class="kanban-header" style={{ background: "#87f093" }}>
                <span class="status-title">Approved</span>
              </div>
              <div class="kanban-wrap">
                <KanbanCard
                  count={"03"}
                  code="SCR"
                  bgsColor={"#cdfbd3"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"02"}
                  code="STE"
                  bgsColor={"#cdfbd3"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"03"}
                  code="SGM"
                  bgsColor={"#cdfbd3"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"02"}
                  code="SCR"
                  bgsColor={"#cdfbd3"}
                  DepartmentsStr={" lift..."}
                />
                <KanbanCard
                  count={"04"}
                  code="SLI"
                  bgsColor={"#cdfbd3"}
                  DepartmentsStr={" lift..."}
                />
              </div>
            </div>
            <div class="kanban-list kanban-danger">
              <div class="kanban-header" style={{ background: "#f27d89" }}>
                <span class="status-title">Rejected</span>
              </div>
              <div class="kanban-wrap">
                <KanbanCard
                  count={"01"}
                  code="SCR"
                  bgsColor={"#ffe4e0"}
                  DepartmentsStr={"lift..."}
                />
                <KanbanCard
                  count={"01"}
                  code="STE"
                  bgsColor={"#ffe4e0"}
                  DepartmentsStr={"lift..."}
                />
                {/* <KanbanCard
                  count={"26"}
                  code="SGE"
                  bgsColor={"#ffe4e0"}
                  DepartmentsStr={"Escalator, lift..."}
                />
                <KanbanCard
                  count={"34"}
                  code="SEG"
                  bgsColor={"#ffe4e0"}
                  DepartmentsStr={"Escalator, lift..."}
                />
                <KanbanCard
                  count={"23"}
                  code="STR"
                  bgsColor={"#ffe4e0"}
                  DepartmentsStr={"Escalator, lift..."}
                /> */}
              </div>
              {/* <div class="add-new-task">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add_task_modal"
                >
                  Add New Task
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquipmentAvailability;

function KanbanCard({ count, code, bgsColor, DepartmentsStr }) {
  return (
    <>
      <div className="card panel">
        <div className="kanban-box" style={{ background: bgsColor }}>
          <div className="">
            <div class="time-list mb-0">
              <div class="" style={{ display: "inline", padding: "8px" }}>
                <h2>{count}</h2>
              </div>
              <div
                class="dash-stats-list"
                style={{ display: "inline", padding: "8px" }}
              >
                <div className="row">
                  <div className="Col-md-6">
                    <p className="pull-right text-muted pt-1">
                      {" "}
                      <img
                        style={{
                          width: "30px",
                          position: "relative",
                          top: "-2px",
                        }}
                        src={underGround}
                        alt="Underground"
                      />{" "}
                      {code}
                    </p>
                  </div>
                  <div className="Col-md-6">
                    <p style={{ fontSize: "12px", float: "right" }}>
                      {DepartmentsStr}
                      <span style={{ color: "#818181" }}>+4 more</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="request-btn">
              <p style={{ fontSize: "12px" }}>
                {DepartmentsStr}
                <span style={{ color: "#818181" }}>+4 more</span>
              </p>
            </div> */}
            {/* <div class="time-list">
              <div className="dash-stats-list">
                <h4>{count}</h4>
                <p>
                  <img
                    style={{
                      width: "30px",
                      position: "relative",
                      top: "-2px",
                    }}
                    src={underGround}
                    alt="Underground"
                  />{" "}
                  {code}
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
