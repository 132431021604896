import React from "react";
import { createContext, useState } from "react";

export const DashBoardContext = createContext({
  PerformanceModel: {},
  DropdownModel: {},

  setDropdownModel: () => {},
  DropDownInitialState : {},
  LineChartModel : {},
  setLineChartModel : () => {},
  setPerformanceModel : () => {}

});

function DashboardContextProvider({children}) {
  
  const DropDownInitialState = {
    StationList : [],
    EquipmentList : [],
    YearList : []
  }
  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [PerformanceModel, setPerformanceModel] = useState({
    StationID : 0,
    EquipmentID : 0,
    FromDate : "",
    ToDate : "",
    YearID : 0,
    DashboardData : []

  });
  const  [LineChartModel ,setLineChartModel] = useState();



  const PerformanceHandleChange = (obj) => {
    setPerformanceModel((pre) => ({
        ...pre,
        ...obj,
    }))
  }


  const contextValue = {
    PerformanceModel,
    DropdownModel,
    setDropdownModel,    
    DropDownInitialState,
    LineChartModel ,
    setLineChartModel,
    setPerformanceModel : PerformanceHandleChange
  };

  return (
    <DashBoardContext.Provider value={contextValue}>
      {children}
    </DashBoardContext.Provider>
  );
}

export default DashboardContextProvider;
