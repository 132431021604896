import React from "react";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import DesignAndCommService from "./DesingAndCommService";
import {
  GetSelect,
  getPrivilege,
  GetLoginUserID,
} from "./../../services/CommonService";
import {
  showSaveError,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  showDeleteSuccess,
  showDeleteError,
} from "./../../components/ToastMsg";
import swal from "sweetalert";

$.DataTable = require("datatables.net");

const _designAndCommService = new DesignAndCommService();

export const DesignDetailsTable = ({
  columnList,
  rowList,
  tableName,
  parameters,
}) => {
  const [isTable, setIsTable] = useState(true);
  var t;
  var childColumns = [
    {
      title: "Eqpt ID",
      className: "hide",
    },
    {
      title: "Design Detail ID",
      className: "hide",
    },
    {
      title: "Equipment Design Parameter ID",
      className: "hide",
    },
    {
      title: "Equipment Design Parameter",

      createdCell: (td, cellData, rowData, row, col) => {
        var disabled = true;

        var b;
        // = $(GetSelect(parameters, cellData)).on("change", function (e) {
        //   //   var t = $(`#${tableName}`).DataTable();
        //   //   console.log(t.cell(td));
        // });
        if (
          getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
          rowData[0] == 0
        ) {
          b = $(GetSelect(parameters, cellData)).on("change", function (e) {
            //   var t = $(`#${tableName}`).DataTable();
            //   console.log(t.cell(td));
          });
        } else {
          b = $(GetSelect(parameters, cellData, disabled)).on(
            "change",
            function (e) {
              //   var t = $(`#${tableName}`).DataTable();
              //   console.log(t.cell(td));
            }
          );
        }
        $(td).html(b);
      },
    },
    {
      title: "Actual Equipment Values-Hidden",
      className: "hide",
    },
    {
      title: "Values",
      createdCell: (td, cellData, rowData, row, col) => {
        var b;
        //  = $(
        //   `<input  pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        // );
        if (
          getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
          rowData[0] == 0
        ) {
          b = $(
            `<input  pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;" class="form-control" value="${cellData}"/>`
          ).on("change", function () {});
        } else {
          b = $(
            `<input readonly pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;" class="form-control" value="${cellData}"/>`
          ).on("change", function () {});
        }
        $(td).html(b);
      },
    },
    {
      title: "Units of Measurement",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="text" class="form-control" value="${cellData}" readonly style="background-color:#E0E0E0	"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "Row ID",
      className: "hide",
    },
    {
      title: "Submit",
      // className: "hide",
      visible:
        getPrivilege().includes("EQUIPMENTDESIGNEDIT") ||
        getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
        getPrivilege().includes("EQUIPMENTDESIGNDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";

        if (
          getPrivilege().includes("EQUIPMENTDESIGNEDIT") ||
          getPrivilege().includes("EQUIPMENTDESIGNCREATE")
        ) {
          b = $(`<button class='btn icon-btn' title='Save'>
        <i class='fa fa-check' style="margin-top:10px"></i>
      </button>`).on("click", function () {
            saveEqptDesingVal(rowData, row, 1);
          });
        }
        if (getPrivilege().includes("EQUIPMENTDESIGNDELETE")) {
          c = $(`<button class='btn'  title='delete'  >
                <i class='fa fa-trash m-l-15'></i>
              </button>`).on("click", function () {
            DeleteParameter(rowData, row, 0);
          });
        }
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable]);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: false,
      autoWidth: false,
      //   order: [[1, "asc"]],
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        var eqptCodeID = $(this).closest("tr").find("td:eq(1)").html();

        _designAndCommService
          .GetDesignParameters(eqptCodeID)
          .then((childRows) => {
            if (
              childRows != null &&
              childRows.data != null &&
              childRows.data.length > 0
            ) {
              var rowList = [];
              childRows.data.map((v, i) => {
                var rows = [];
                rows.push(eqptCodeID);
                rows.push(v.equipDesignID);
                rows.push(v.parameterID);
                rows.push(v.parameterID);
                rows.push(v.values);
                rows.push(v.values);
                if (v.parameterID > 0) {
                  var findUnits = parameters.find(
                    (e) => e.value == v.parameterID
                  );
                  findUnits != null ? rows.push(findUnits.unit) : rows.push("");
                }
                rows.push(i + 1);
                rows.push("");

                rowList.push(rows);
              });
              row.child(ChildTable(eqptCodeID)).show();

              //set child table
              childTable(rowList, childColumns, eqptCodeID);
            } else {
              row.child(ChildTable(eqptCodeID)).show();

              //set child table
              childTable([], childColumns, eqptCodeID);
            }
          });
        // row.child(ChildTable(eqptCodeID)).show();
        // //set child table
        // childTable([], childColumns, eqptCodeID);
        tr.addClass("shown");
      }
    });

    return function () {
      table.destroy();
    };
  };

  const childTable = (rowList, childColumns, eqptCodeID) => {
    // set child table as data table
    t = $(`#design_detail-${eqptCodeID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      paging: false,
      bInfo: false,
      scrollX: false,
      autoWidth: true,
      order: [[7, "desc"]],
    });

    //add new row functionality
    $(`#addRow-${eqptCodeID}`).on("click", function () {
      var rowID = t.column(1).data().length + 1;
      t.row.add([eqptCodeID, 0, 0, "", "", "", "", rowID, ""]).draw(false);

      //update row values
      updateValues(t, eqptCodeID);
    });
    updateValues(t, eqptCodeID);
  };

  const updateValues = (t, eqptCodeID) => {
    $(`#design_detail-${eqptCodeID}  tbody  tr`).each(function (index, tr) {
      var row = t.row(this);
      var rowIndex = t.row(this).index();

      //on Paramter change
      var selectCell = $(this).closest("tr").find("td:eq(3)");
      selectCell.on("change", function (e) {
        //update parameter value
        t.cell({ row: rowIndex, column: 2 }).data(Number(e.target.value));

        //update paramter unit
        var parameterID = Number(e.target.value);
        if (parameterID > 0) {
          var paramName = parameters.find((v) => v.value == parameterID);
          if (paramName != null) {
            t.cell({ row: rowIndex, column: 6 }).data(paramName.unit);
          }
        }
      });

      //on Actual value change
      var inputCell = $(this).closest("tr").find("td:eq(5)");
      inputCell.on("change", function (e) {
        t.cell({ row: rowIndex, column: 4 }).data(e.target.value);
      });
    });
  };

  const saveEqptDesingVal = (data, rowIndex, status) => {
    //;
    if (
      data != null &&
      data.length > 0 &&
      data[0] != "" &&
      data[2] > 0 &&
      data[4] != ""
    ) {
      var design = {
        EquipDesignID: data[1],
        EquipmentDetailsID: Number(data[0]),
        ParameterID: data[2],
        Values: data[4],
        SavedBy: GetLoginUserID(),
        IsActive: status,
      };

      _designAndCommService.AddOrUpdateDesign(design).then((res) => {
        if (res != null && res.isSuccess && res.data != 0) {
          showSaveSuccess();
          // console.log(res.data);
          //;
          t.cell({ row: rowIndex, column: 1 }).data(res.data);
        } else if (res.isSuccess && res.data === 0) {
          showWarningMsg(res.message);
        } else {
          showSaveError();
        }
      });
    } else {
      showWarningMsg("Please fill all fields");
    }
  };

  const DeleteParameter = (data, row, status) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this parameter?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (
          data != null &&
          data.length > 0 &&
          data[0] != "" &&
          data[2] > 0 &&
          data[4] != ""
        ) {
          var design = {
            EquipDesignID: data[1],
            EquipmentDetailsID: Number(data[0]),
            ParameterID: data[2],
            Values: data[4],
            SavedBy: GetLoginUserID(),
            IsActive: status,
          };

          _designAndCommService.AddOrUpdateDesign(design).then((res) => {
            if (res != null && res.isSuccess && res.data != 0) {
              showDeleteSuccess();
              // console.log(res.data);
              //;
              t.cell({ row: row, column: 1 }).data(res.data);

              var eqptCodeID = Number(data[0]);

              const getData = async () => {
                _designAndCommService
                  .GetDesignParameters(eqptCodeID)
                  .then((childRows) => {
                    if (
                      childRows != null &&
                      childRows.data != null &&
                      childRows.data.length > 0
                    ) {
                      var rowList = [];
                      childRows.data.map((v, i) => {
                        var rows = [];
                        rows.push(eqptCodeID);
                        rows.push(v.equipDesignID);
                        rows.push(v.parameterID);
                        rows.push(v.parameterID);
                        rows.push(v.values);
                        rows.push(v.values);
                        if (v.parameterID > 0) {
                          var findUnits = parameters.find(
                            (e) => e.value == v.parameterID
                          );
                          findUnits != null
                            ? rows.push(findUnits.unit)
                            : rows.push("");
                        }
                        rows.push(i + 1);
                        rows.push("");

                        rowList.push(rows);
                      });
                      // row.child(ChildTable(eqptCodeID)).show();

                      //set child table
                      childTable(rowList, childColumns, eqptCodeID);
                    } else {
                      // row.child(ChildTable(eqptCodeID)).show();

                      //set child table
                      childTable([], childColumns, eqptCodeID);
                    }
                  });
              };
              getData();
            } else if (res.isSuccess && res.data === 0) {
              showWarningMsg(res.message);
            } else {
              showSaveError();
            }
          });
        } else {
          showWarningMsg("Please fill all fields");
        }
      }
    });
  };

  return (
    <div className="table-responsive ">
      <div className="row">
        <div className="col-sm-12">
          {isTable ? (
            <table
              className="table table-striped no-footer display collapse-table"
              id={tableName}
              width="100%"
            ></table>
          ) : (
            /* No of coulms and no of rows must be equal*/
            <div className="row col-12 text-center mt-2 mb-2">
              <p className="text-muted">Table not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ChildTable = (tableIndex) => {
  if (getPrivilege().includes("EQUIPMENTDESIGNCREATE")) {
    return `
  <div class="row d-flex justify-content-end"><div class="col-md-2" style=" position: relative; top: -43px;"><button type="submit" id="addRow-${tableIndex}" class="btn add-btn btn-sm"><i class="fa fa-plus"></i> Add</button></div></div>
  <div class"row" style="margin-top: -43px;">
    <table id="design_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
    </table></div>`;
  } else {
    return `
    <div class"row ">
    <table id="design_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
    </table></div>`;
  }
};
