import React from "react";
import { PageHeader } from "./../../components/PageHeader";
import { useForm } from "react-hook-form";
import { HtmlTable } from "../../components/HtmlTable";
import manualUpDownloadService from "./ManualUpDownloadService";
import { AddButton, IconButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import $, { data } from "jquery";
import { BASE_URL } from "../../env";
import {
  showSaveSuccess,
  showDeleteError,
  showDeleteSuccess,
  showSaveError,
} from "../../components/ToastMsg";
import { AppContext } from "./../../services/ContextProvider";
import Global from "./../../services/Global";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import { saveAs } from "file-saver";

// import DownloadProgress from "./DownloadProgress";

const _manualupdownloadServc = new manualUpDownloadService();

// Add form
const AddForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  // const onsubmit = (data) => {
  //
  //   if (data.documentName.trim() == '') {
  //     setError("documentName", { message: "Document Name cannot be empty" })
  //   }
  //   if (data.description.trim() == '') {
  //     setError("description", { message: "Description cannot be empty" })
  //   }
  //   else {
  //     onSubmit(data, reset, setError);
  //   }

  // }

  return (
    <div className="mb-2">
      {getPrivilege().includes("REFERENCEDOCUMENTCREATE") && (
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="row d-flex justify-content-end">
            <input
              type="text"
              className="form-control"
              placeholder="manaulID"
              name="manaulID"
              hidden
              defaultValue={0}
              {...register("manaulID", { required: false })}
            />
            {/* Document Name*/}
            <div className="col-3">
              <label className="label" for="name">
                Document Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="documentName"
                className="form-control"
                placeholder="Document Name"
                name="documentName"
                {...register("documentName", {
                  required: "Document Name is required",
                })}
              />
              {errors.documentName && (
                <span className="text-danger">
                  {errors.documentName.message}
                </span>
              )}
            </div>
            {/* Description*/}
            <div className="col-3">
              <label className="label" for="name">
                Description <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Description"
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              {errors.description && (
                <span className="text-danger">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="col-3">
              <input id="file" hidden />
              <label className="label" for="name">
                Upload File <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                id="originalFileName"
                type="file"
                {...register("originalFileName", {
                  required: {
                    value: true,
                    message: "Please Upload the File",
                  },
                })}
                // onChange={() => setError("FormFile", "")}
                accept="*"
                // onChange={(e) => FileSetFiles(e)}
              />
              {errors.originalFileName && (
                <span className="text-danger">
                  {errors.originalFileName.message}
                </span>
              )}
            </div>
            <div className="col-2 mt-4">
              <AddButton />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const ManualSave = () => {
  //variables
  const { showLoading, hideLoading } = useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [allDocumentList, setallDocumentList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [Refresh, setRefresh] = useState(0);
  const [OrginalName, setOrginalFileName] = useState(null);
  const { removeCookies, getCookies, cookies } = useContext(AppContext);

  const [progress, setProgress] = useState(0);

  const DownloadFiles = (DownloadID, fileName) => {
    showLoading();
    _manualupdownloadServc
      .DownloadMaintenanceManual(DownloadID, setProgress)
      .then((res) => {
        const contentType = res.headers["content-type"];
        saveAs(new Blob([res.data], { type: contentType }), `${fileName}`);
        hideLoading();
        setProgress(0);
      })
      .catch((err) => {
        hideLoading();
        console.error(err);
      });
  };

  //Spare table columns
  const columnNames = [
    { title: "Manual ID", visible: false },
    { title: "Document Name" },
    { title: "Description" },
    { title: "Guid Name", visible: false },
    { title: "Orginal Name", visible: false },
    { title: "File Type", visible: false },
    { title: "File Path", visible: false },
    // { title: "Status", visible: true },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("REFERENCEDOCUMENTEDIT") ||
        getPrivilege().includes("REFERENCEDOCUMENTDELETE") ||
        getPrivilege().includes("REFERENCEDOCUMENTVIEW"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        var d = "";
        b =
          $(`<button class='btn icon-btn text-center'  data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
          <i class='fa fa-download m-r-5'></i>
                </button>`).on("click", function () {
            // SetIsView(true)
            DownloadFiles(rowData[0], rowData[4]);
          });
        if (getPrivilege().includes("REFERENCEDOCUMENTEDIT")) {
          c = $(`<button class='btn icon-btn' title='Edit'>
            <i class='fa fa-pencil m-l-5'></i>
          </button>`).on("click", function () {
            onEditClick(Number(rowData[0]));
          });
        }
        if (getPrivilege().includes("REFERENCEDOCUMENTDELETE")) {
          d = $(`<button class='btn icon-btn' title='Delete'  >
            <i class='fa fa-trash m-l-5'></i>
          </button>`).on("click", function () {
            onDeleteClick(Number(rowData[0]));
          });
        }

        $(td).html(b);
        $(td).append(c);
        $(td).append(d);
      },
    },
  ];

  const onDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this document?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _manualupdownloadServc
          .DeleteRefrenceDocument(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              setRefresh(Refresh + 1);
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            setRefresh(Refresh + 1);
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    getData();
  }, [Refresh]);

  const getData = () => {
    _manualupdownloadServc.GetManualList().then((response) => {
      console.log(response.data);
      setallDocumentList(response.data);
      if (response != null) {
        var rowList = [];
        response.data.map((v, i) => {
          var row = [];
          row.push(v.manaulID);
          row.push(v.documentName);
          row.push(v.description);
          row.push(v.guidFileName);
          row.push(v.originalFileName);
          row.push(v.fileType);
          row.push(v.filePath);

          //Edit button
          row.push("");
          rowList.push(row);
        });
        setTableRows(rowList);
      }
    });
  };

  const onEditClick = (id) => {
    const found = allDocumentList.find((obj) => {
      return obj.manaulID === id;
    });

    setOrginalFileName(found["originalFileName"]);

    Object.keys(found).map((key, i) => setValue(key, found[key]));
  };

  const onSubmits = (e, reset, setError) => {
    showLoading();
    console.log(e);

    const formData = new FormData();
    formData.append("ManaulID", e.manaulID ? e.manaulID : 0);
    formData.append("File", e.originalFileName[0]);
    formData.append("DocumentName", e.documentName);
    formData.append("Description", e.description);
    formData.append("SavedBy", getCookies(Global.COOKIES.EmployeeID));
    formData.append("GuidFileName", e.guidFileName ? e.guidFileName : null);
    formData.append(
      "OriginalFileName",
      e.originalFileName ? e.originalFileName : null
    );
    formData.append("FilePath", e.filePath ? e.filePath : null);
    formData.append("FileType", e.fileType ? e.fileType : null);

    try {
      _manualupdownloadServc
        .UploadMaintenanceManual(formData)
        .then((response) => {
          hideLoading();
          if (response.data <= 0) {
            hideLoading();
            //showSaveError();
          } else {
            reset();
            $("#documentName").val("");
            $("#description").val("");
            $("#originalFileName").val("");
            setOrginalFileName(null);
            var count = Refresh;
            setRefresh(++count);
            showSaveSuccess();
          }
        })
        .catch((err) => {
          hideLoading();
          showSaveError();
          console.log(err);
        });
    } catch {
      hideLoading();
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onsubmit = (data) => {
    if (data.documentName.trim() == "") {
      setError("documentName", { message: "Document Name cannot be empty" });
    }
    if (data.description.trim() == "") {
      setError("description", { message: "Description cannot be empty" });
    }
    if (Number(data.manaulID) == 0 && data.originalFileName.length == 0) {
      setError("originalFileName", { message: "File is required" });
    }
    if (
      Number(data.manaulID) != 0 &&
      (OrginalName == "" || OrginalName == null)
    ) {
      setError("originalFileName", { message: "File is required" });
    } else {
      onSubmits(data, reset, setError);
    }
  };

  return (
    <>
      <div className="row">
        <PageHeader title="Reference Document" subtitle=""></PageHeader>
        {/* {progress > 0 && */}
        {/* <DownloadProgress progress={progress}/> */}
        {/* } */}
      </div>

      {/* <AddForm onSubmit={onSubmit} /> */}
      {getPrivilege().includes("REFERENCEDOCUMENTCREATE") && (
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="row d-flex justify-content-start">
            <input
              type="hidden"
              defaultValue={0}
              {...register("manaulID", { required: false })}
            />
            {/* <input
            type="text"
            class="form-control"
            placeholder="manaulID"
            name="manaulID"
            hidden
            // defaultValue={0}
            {...register("manaulID", { required: false })}
          /> */}
            {/* Document Name*/}
            <div className="col-md-3">
              <label className="label" for="name">
                Document Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="documentName"
                className="form-control"
                // placeholder="Document Name"
                name="documentName"
                {...register("documentName", {
                  required: "Document name is required",
                })}
              />
              {errors.documentName && (
                <span className="text-danger">
                  {errors.documentName.message}
                </span>
              )}
            </div>
            {/* Description*/}
            <div className="col-md-3">
              <label className="label" for="name">
                Description <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                // placeholder="Description"
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              {errors.description && (
                <span className="text-danger">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="col-md-3">
              <input id="file" hidden />
              <label className="label" for="name">
                Upload File <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                id="originalFileName"
                type="file"
                {...register("originalFileName", {
                  required: {
                    value:
                      OrginalName != null && OrginalName != "" ? false : true,
                    message: "Please upload the file",
                    validate: (value) => {
                      console.log(value);
                      // return !!value.length != 0 || "Please Upload the File";
                    },
                  },
                })}
                // onChange={() => setOrginalFileName(null)}
                accept="*"
                // onChange={(e) => FileSetFiles(e)}
              />
              {errors.originalFileName && (
                <span className="text-danger">
                  {errors.originalFileName.message}
                </span>
              )}
              <span>{OrginalName != "" ? OrginalName : ""}</span>
            </div>
            <div className="col-3 mt-4">
              <button type="submit" className="btn add-btn">
                <i className="fa fa-plus"></i> Add
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="card card-body mt-4" style={{ position: "relative" }}>
        <HtmlTable
          columnList={columnNames}
          rowList={tableRows}
          id={"ManualsTable"}
        />
      </div>
    </>
  );
};
