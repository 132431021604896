// // // // /////Dev

// export const BASE_URL = "https://localhost:5001/api/";
// export const UPLOAD_URL = "https://localhost:5001/Images/";

// export const BASE_URL = "http://192.168.1.20:6781/api/";
// export const UPLOAD_URL = "http://192.168.1.20:6781//Images/";


// // //////Qa  HTTPS
export const BASE_URL = "https://cmmsqaapi.cybertronics.us/api/";
export const UPLOAD_URL = "https://cmmsqaapi.cybertronics.us/Images/";

// // // // // // // ///UAT
// export const BASE_URL = "https://cmrluatapi.azurewebsites.net/api/";
// export const UPLOAD_URL = "https://cmrluatapi.azurewebsites.net/Images/";

// // // //PROD
// export const BASE_URL = "https://cmrlapi.azurewebsites.net/api/";
// export const UPLOAD_URL = "https://cmrlapi.azurewebsites.net/Images/";
