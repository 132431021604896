import React from 'react'
import { getYMDdate } from '../../services/CommonService';

export const PeriodicWeekData = (date) => {
    
  var days31 = [1, 3, 5, 7, 8, 10, 12];
  var days30 = [4, 6, 9, 11];

  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  if (days31.includes(month)) {
    var week5 = [29, 30, 31, '', '', '', ''];
  }
  else if (days30.includes(month)) {
    var week5 = [29, 30, '', '', '', '', ''];
  }
  else {
    if ((year % 4 == 0 || year % 400 == 0) && year % 100 != 0) {
      var week5 = [29, '', '', '', '', '', ''];
    }
    else {
      week5 = null;
    }
  }

  return week5
  
}
export function checkDate(date) {
  return date < getYMDdate(new Date());
}
export function checkMonth(date) {
  var newdate = new Date(date);
  return newdate.getMonth() == new Date().getMonth();
}
