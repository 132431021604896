import React, { useEffect } from "react";
import { createContext, useState, useContext } from "react";
import {
  GetToDateAndWeek,
  addDays,
  getYMDdate,
} from "../../services/CommonService";
import {
  PeriodicMaintenance_GetDynamicMonths,
  PeriodicMaintenance_GetPendingColor,
} from "./PeriodicService";
import { MaintenanceTypes } from "../../services/Global";
import moment from "moment";
import { showWarningMsg } from "../../components";

export const PeriodicContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => { },
  DropDownInitialState: {},
  PeriodicModel: {},
  setPeriodicModel: () => { },
  setMonthConfigObj: () => [],
  MonthConfigObj: [],
});

function PeriodicContextProvider({ children }) {
  const DropDownInitialState = {
    StationList: [],
    DepartmentList: [],
    FilterDepartmentList: [],
    FilterEquipmentList: [],
    EquipmentList: [],
    ErrorObj: {},
    PendingDateList: [],
    FilterStatusList: [],
  };

  const InitialState = {
    StationID: {},
    CurrentView: "month",
    FromDate: getYMDdate(),
    TodDate: getYMDdate(),
    ScheduleType: 1,
    CurrentWeek: -1,
    DepartmentID: 0,
    JobAssigned: false,
    AssignedEmployee: 0,
    IsJobAssigner: false,
    FileBlob: null,
    FileName: "",
    CalSrtDate: "",
    DescriptionRefreshID : ""
  };

  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [PeriodicModel, setPeriodicModel] = useState(InitialState);
  const [MonthConfigObj, setMonthConfigObj] = useState([]);

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
    PeriodicModel,
    setPeriodicModel,
    setMonthConfigObj,
    MonthConfigObj,
  };

  useEffect(() => {
    GetDynamicMonths();
  }, [PeriodicModel.DepartmentID, PeriodicModel.StationID?.value]);

  const GetDynamicMonths = async () => {
    const { DepartmentID, FromDate, StationID } = PeriodicModel;
    if (DepartmentID && FromDate && StationID?.value) {
      await PeriodicMaintenance_GetDynamicMonths(
        DepartmentID,
        new Date(FromDate ?? "").getFullYear(),
        StationID?.value
      )
        .then((res) => {
          
          if (res?.data?.data?.length) {
            
            setMonthConfigObj([...res.data.data]);
            // setDropdownModel((pre) => ({
            //   ...pre,
            //   PendingDateList: res.data.data.statuslist,
            // }));
          } else {
            showWarningMsg("The periodic start month has not been configured properly.")
            setMonthConfigObj([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <PeriodicContext.Provider value={contextValue}>
      {children}
    </PeriodicContext.Provider>
  );
}

export function ContextValues() {
  const ContextValue = useContext(PeriodicContext);
  return ContextValue;
}

export default PeriodicContextProvider;
