import React from "react";
import {
  GetLoginUserID,
  Decrypt,
  getYMDdate,
  DownloadIcon,
} from "../../services/CommonService";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import { useState, useEffect, useRef, useContext } from "react";
import $, { data } from "jquery";
import MasterDetailService from "../master/MasterDetailService";
import makeAnimated from "react-select/animated";
import { PeriodicService } from "../periodic/PeriodicService";
import { AppContext } from "../../services/ContextProvider";
import { Dropdown } from "../../components/Dropdown";
import Global from "../../services/Global";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ChillerMonitoringHtmlTable } from "../../components/ChillerMonitoringHtmlTable.js";
import {
  ChillerMonitoring_GetChillerMonitoringReportData,
  ChillerMonitoring_GetEquipmentCode,
} from "../ManualLog/DailyElectricalPanelservice.js";
const XLSX = require("xlsx");
const _periodicServices = new PeriodicService();
const initialEquipmentState = {
  RoomID: [],
  StationName: "",
  StationID: "",
  IsDeleted: false,
  checkAllAttended: 0,
  CurrentDate: getYMDdate(new Date()),
  checkAllApproved: false,
  checkAllRejected: false,
  EquipmentLocation: "",
  OffStartTime: "",
  IsChillerMonitorAllCheck: 0,
  Chiller1: "",
  Chiller2: "",
  Chiller3: "",
  MDB_1: "",
  MDB_2: "",
  TVS_Consumption: "",
  VAC_Consumption: "",
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ChillerMonitoringReport = () => {
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);

  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [rowList, setData] = useState([]);
  const [FromDate, SetfromDate] = useState("");
  const [ToDate, SetToDate] = useState("");
  const [periodicTypeList, setPeriodicTypeList] = useState([]);
  const [EqipmentList, setEquipmentList] = useState([]);
  const [EqipmentID, setEquipmentID] = useState(0);
  const [stationIds, setStationIds] = useState("");
  const [EquipmentLocationList, setEquipmentLocationList] = useState([]);
  const [TimeandHoursList, setTimeandHoursList] = useState([]);

  const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
  const [RoomModel, setRoomModel] = useState(initialEquipmentState);

  const [periodicType, setPeriodicType] = useState(1);

  const [ChillerMonitoringDetailList, setChillerMonitoringDetailList] =
    useState([]);
  $.DataTable = require("datatables.net");

  const columnNames = [
    {
      title: `<p style="text-align: center;">S.NO</p>`,
      data: "ChillerMonitoringDWNID",
    },
    {
      title: `<p style="text-align: center;">Description</p>`,
      data: "ChillerName",
    },

    {
      title: `<p style="text-align: center;">Min/ Max Level</p>`,
      data: "MaxMinLevel",
    },
    {
      title: `<p style="text-align: center;">Units</p>`,
      data: "Units",
    },

    // {
    //   title: `<p style="text-align: center;">0.00</p>`,
    //   data: "Time_0_Start",
    //   // className: checkColVisible(min, max, 1) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">1.00</p>`,
    //   data: "Time_0_End",

    //   // className: checkColVisible(min, max, 1) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">2.00</p>`,
    //   data: "Time_2_Start",
    //   // className: checkColVisible(min, max, 2) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">3.00</p>`,
    //   data: "Time_2_End",
    //   // className: checkColVisible(min, max, 2) ? "hide" : "",
    // },
    {
      title: `<p style="text-align: center;">4.00</p>`,
      data: "Time_4_Start",
      width: "200px",
    },
    // {
    //   title: `<p style="text-align: center;">5.00</p>`,
    //   data: "Time_4_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">6.00</p>`,
      data: "Time_6_Start",
    },
    // {
    //   title: `<p style="text-align: center;">7.00</p>`,
    //   data: "Time_6_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">8.00</p>`,
      data: "Time_8_Start",
    },
    // {
    //   title: `<p style="text-align: center;">9.00</p>`,
    //   data: "Time_8_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">10.00</span></p>`,
      data: "Time_10_Start",
    },
    // {
    //   title: `<p style="text-align: center;">11.00</span></p>`,
    //   data: "Time_10_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">12.00</p>`,
      data: "Time_12_Start",
    },
    // {
    //   title: `<p style="text-align: center;">13.00</p>`,
    //   data: "Time_12_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">14.00</p>`,
      data: "Time_14_Start",
    },
    // {
    //   title: `<p style="text-align: center;">15.00</p>`,
    //   data: "Time_14_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">16.00</p>`,
      data: "Time_16_Start",
    },
    // {
    //   title: `<p style="text-align: center;">17.00</p>`,
    //   data: "Time_16_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">18.00</p>`,
      data: "Time_18_Start",
    },
    // {
    //   title: `<p style="text-align: center;">19.00</p>`,
    //   data: "Time_18_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">20.00</p>`,
      data: "Time_20_Start",
    },
    // {
    //   title: `<p style="text-align: center;">21.00</p>`,
    //   data: "Time_20_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;"> 22.00</p>`,
      data: "Time_22_Start",
    },
    // {
    //   title: `<p style="text-align: center;"> 23.00</p>`,
    //   data: "Time_22_End",
    //   className: "hide",
    // },
  ];

  const [isTable, setIsTable] = useState(false);
  let offStartTimedefault = 0;
  let offEndTimedefault = 4;
  useEffect(() => {
    Report_GetJobEquipmentList();
    getType();
  }, []);

  useEffect(() => {
    initialDropDown();
    SetfromDate(getYMDdate(new Date()));
  }, []);

  useEffect(() => {
    initialDropDownEquipmentCode();
  }, [RoomModel.StationID]);

  useEffect(() => {
    getDailyElectricalDetails();
  }, [
    RoomModel.StationID,
    RoomModel.EquipmentDetailID,
    RoomModel.EquipmentDetailIDs,
    FromDate,
    // ListPageRender,
  ]);

  const getDailyElectricalDetails = async () => {
    if (RoomModel.StationID > 0 && RoomModel.EquipmentDetailID?.value) {
      showLoading();

      await ChillerMonitoring_GetChillerMonitoringReportData(
        RoomModel.StationID,
        RoomModel.EquipmentDetailID.value,
        FromDate
      )
        .then((res) => {
          //
          if (res?.data) {
            if (res.data.data.offStartTimeSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                OffStartTime: res.data.data.offStartTimeSelected[0],
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                OffStartTime: "",
              }));
            }
            if (res.data.data.offEndTimeSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                OffEndTime: res.data.data.offEndTimeSelected[0],
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                OffEndTime: "",
              }));
            }

            if (res.data.data.chillerMonitoringDet?.length) {
              setChillerMonitoringDetailList([
                ...res.data.data.chillerMonitoringDet,
              ]);
            } else {
              setChillerMonitoringDetailList([]);
            }
            if (res.data.data?.chillerMonitoringMST != null) {
              setRoomModel((pre) => ({
              ...pre,
                checkAllAttended:
                  res.data.data.chillerMonitoringMST[0].ChillerMonitoringStatus,
                IsChillerMonitorAllCheck:
                  res.data.data.chillerMonitoringMST[0]
                    .IsChillerMonitorAllCheck,
                Chiller1: res.data.data.chillerMonitoringMST[0].Chiller_1,
                Chiller2: res.data.data.chillerMonitoringMST[0].Chiller_2,
                Chiller3: res.data.data.chillerMonitoringMST[0].Chiller_3,
                StationName: res.data.data.chillerMonitoringMST[0].StationName,
                MDB_1: res.data.data.chillerMonitoringMST[0].MDB_1,
                MDB_2: res.data.data.chillerMonitoringMST[0].MDB_2,
                TVS_Consumption:
                  res.data.data.chillerMonitoringMST[0].TVS_Consumption,
                VAC_Consumption:
                  res.data.data.chillerMonitoringMST[0].VAC_Consumption,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                checkAllAttended: 0,
                Chiller1: "",
                Chiller2: "",
                Chiller3: "",
                StationName: "",
                MDB_1: "",
                MDB_2: "",
                VAC_Consumption: "",
                TVS_Consumption: "",
                IsChillerMonitorAllCheck: 0,
              }));
            }

            if (res.data.data.equipmentLocationSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation:
                  res.data.data.equipmentLocationSelected[0].label,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation: "",
              }));
            }
            if (res.data.data.isoDocumentName?.length) {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: res.data.data.isoDocumentName[0].DocumentNo,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: "",
              }));
            }
          } else {
            setRoomModel((pre) => ({
              ...pre,
              OffStartTime: "",
              OffEndTime: "",
              ISODocumentNo: "",
              checkAllAttended: 0,
              IsChillerMonitorAllCheck: 0,
              EquipmentLocation: "",
              EquipmentDetailID: "",
            }));

            setChillerMonitoringDetailList([]);
          }

          hideLoading();
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  const initialDropDownEquipmentCode = async () => {
    if (RoomModel.StationID > 0) {
      await ChillerMonitoring_GetEquipmentCode(RoomModel.StationID)
        .then((res) => {
          if (res?.data) {
            if (res.data.data.chillerTagList?.length) {
              setEquipmentTagNumberList([...res.data.data.chillerTagList]);

              if (res.data.data.chillerTagList?.length) {
                setRoomModel((pre) => ({
                  ...pre,

                  EquipmentDetailID: res.data.data.chillerTagList[0],
                }));
              } else {
                setRoomModel((pre) => ({
                  ...pre,
                  EquipmentDetailID: "",
                }));
              }
            } else {
              setEquipmentTagNumberList([]);
            }
            if (res.data.data.equipmentLocationList?.length) {
              setEquipmentLocationList([
                ...res.data.data.equipmentLocationList,
              ]);
            } else {
              setEquipmentLocationList([]);
            }
            if (res.data.data.timeandHoursList?.length) {
              setTimeandHoursList([...res.data.data.timeandHoursList]);
            } else {
              setTimeandHoursList([]);
            }
          } else {
            setRoomModel((pre) => ({
              ...pre,
              EquipmentDetailID: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setStationList([...FinalStationList]);

    setRoomModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
      CurrentDate: getYMDdate(new Date()),
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  const HandleChange = (e) => {
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
    SetStationID(e);
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });

    if (FromDate != null) {
      $("#ToDate").attr("min", FromDate);
    }

    setStationIds(stationIds);
    return () => {
      setData([]);
    };
  }, [StationID, FromDate, periodicType, RoomModel.EquipmentDetailID]);

  const Report_GetJobEquipmentList = () => {
    _periodicServices
      .Report_GetJobEquipmentList()
      .then((data) => {
        var datas = [];
        if (data != null && data.data != null && data.data[0].length > 0) {
          data.data[0].map((c, i) => {
            // var dataLst = [];
            datas.push({ key: c.EquipmentShortName, value: c.EquipmentID });
          });
          if (datas.length > 0) {
            datas.push({ key: "All Equipments", value: -1 });
          }
          setEquipmentList(datas);
          setIsTable(true);
          // hideLoading();
        } else {
          setEquipmentList([]);
          // hideLoading();
        }
        if (datas.length > 0) {
          setEquipmentID(datas[0].value);
        }
      })
      .catch(
        (err) => console.log(err)
        // hideLoading()
      );
  };
  const getType = () => {
    var dataLst = [];
    dataLst.push({ key: "Daily", value: 1 });
    dataLst.push({ key: "Monthly", value: 2 });
    dataLst.push({ key: "Quarterly", value: 3 });
    dataLst.push({ key: "Half-Yearly", value: 4 });
    dataLst.push({ key: "Yearly", value: 5 });
    dataLst.push({ key: "All Type", value: -1 });

    setPeriodicTypeList(dataLst);
  };

  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        setRoomModel((pre) => ({
          ...pre,
          EquipmentDetailID: "",
          EquipmentLocation: "",
          OffStartTime: "",
          OffEndTime: "",
        }));
        setChillerMonitoringDetailList([]);

        let Station = StationList.find((x) => x.value == value);
        handleGlobalStationName(Station?.key ?? "");
      }
    } else {
      if (name === "OffEndTime") {
        const hours = event.label.split(".")[0];
        offEndTimedefault = Number(hours);
        for (let i = offStartTimedefault; i <= offEndTimedefault; i++) {
          //  offoverallTimedefault.push(i);
        }
        //alert(offoverallTimedefault);
      }
      if (name === "OffStartTime") {
        const hours = event.label.split(":")[0];
        offStartTimedefault = hours;
      }

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
      }));
    }
  };
  const PDfCustomization = (doc) => {
    let TechnicianRow = [];
    let EngineerRow = [];


    TechnicianRow.push({
      text: `Name of the Shift Technician : ${ChillerMonitoringDetailList?.[0]?.ShiftTechnician ?? ""}`,
      colSpan: 3,
    });
    EngineerRow.push({
      text: 'Name of the Shift Approver/Engineer',
      colSpan: 2,
    });

    var dynamicText = [
      ``,
      ``,
      `VAC Consumption : ${RoomModel.VAC_Consumption}`,
      "",
      `TVS Consumption: ${RoomModel.TVS_Consumption}`,
      "",
      `Chiller-1 ${RoomModel.Chiller1}`,
      `Chiller-2 ${RoomModel.Chiller2}`,
      `Chiller-3 ${RoomModel.Chiller3}`,
      `MDB-1 ${RoomModel.MDB_1}`,
      `MDB-2 ${RoomModel.MDB_2}`,
      ``,
      "",

      ``,
    ];
    var dynamicColumn = [
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      ,
      1,
      1,
      1,
      1,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      3,
      2,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
    ];

    // Initialize count
    let count = 0;
    debugger;
    let headerRow = [...doc.content[1].table.body[0]];
    headerRow = headerRow.slice(1);
    headerRow?.forEach(() => {
      TechnicianRow.push({
        colSpan: dynamicColumn[count],
        text: dynamicText[count] || "",
      });
      EngineerRow.push({
        colSpan: 12,
        alignment: "center",
        text: ChillerMonitoringDetailList?.[0]?.ShiftEngineer ?? "",
      });
      count++; // Increment count
    });


    doc.content[1].table.body.push(TechnicianRow);
    doc.content[1].table.body.push(EngineerRow);
    doc.content[1].table.widths = "*";
    doc.content[1].layout = {
      padding: 10, // Add padding for the entire table
    };
  };

  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-md-2 mt-2">
            <h4 className="page-title">Chiller Monitoring Report</h4>
          </div>

          <div className="col-md-2">
            <label className="col-form-label">Stations</label>

            <Dropdown
              data={StationList}
              placeholder="Select"
              name={"StationID"}
              HandleChange={CommonHandleChange}
              value={RoomModel.StationID}
              className=""
            />
          </div>

          <div className="col-md-2">
            <label className="col-form-label" for="empCode">
              Chiller No <span className="text-danger"> *</span>{" "}
            </label>{" "}
            <ReactMultiSelect
              options={EquipmentTagNumberList}
              value={RoomModel.EquipmentDetailID}
              onChange={(e) => CommonHandleChange(e, "EquipmentDetailID")}
              labelledBy="Select"
            />
          </div>

          <div className="col-md-2">
            <label className="col-form-label">Date</label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              defaultValue={FromDate}
              onChange={(e) => {
                SetfromDate(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <div className="">
          {isTable ? (
            <>
              <ChillerMonitoringHtmlTable
                IsASC
                IsShowPDF
                title="Chiller Monitoring Report"
                rowList={ChillerMonitoringDetailList}
                columnList={columnNames}
                id={`ChillerMonitoringReport`}
                FromDate={FromDate}
                StationName={RoomModel?.StationName ?? ""}
                EquipmentName={RoomModel?.EquipmentDetailID?.label ?? ""}
                pdfHeading={`Daily Chiller Monitoring`}
                PDfCustomization={PDfCustomization}
                DocumentNo={RoomModel?.ISODocumentNo ?? ""}
                landscape
              // paging={false}
              />
            </>
          ) : (
            <>
              <div className="text-center" style={{ margin: "10rem" }}>
                <div className="loader-Wrapper">
                  <div className="Customloader-div">
                    <span className="loader"></span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
