import { Encrypt } from "./../services/Global";
import Axios from "./../services/AxiosService";

const axios = new Axios();

export default class NotificationService {
  GetNotificationById(id, Type,offset,limit) {
    return axios
      .get(`MaintenanceManual/GetNotification?EmployeeId=${id}&Type=${Type}&offset=${offset}&limit=${limit}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  ClearNotication(id, mode) {
    return axios
      .get(`MaintenanceManual/ClearAll?EmployeeId=${id}&&mode=${mode}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  ClearNoticationCount(id, type) {
    return axios
      .get(
        `MaintenanceManual/ClearCnt?EmployeeId=${id}&&NotificationType=${type}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  async GetCount(EmployeeID) {
    return await axios
      .get(`MaintenanceManual/Getcount?EmployeeId=${EmployeeID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  GetPeriodicCount(EmployeeID) {
    return axios
      .get(`MaintenanceManual/GetPeriodicCount?EmployeeId=${EmployeeID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetPeriodicNotificationById(id) {
    return axios
      .get(`MaintenanceManual/GetPeriodicNotification?EmployeeId=${id}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
}
