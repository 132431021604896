import React from "react";
import $ from "jquery";
import BreakdownMaintenanceService from "./BreakdownMaintenanceService";
import { useState, useEffect, useReducer, useContext } from "react";
import BootrapModel from "../../components/BootrapModel";
import { SaveBreakdownMaintenance } from "./SaveBreakdownMaintenance";
import MasterDetailService from "../master/MasterDetailService";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import {
  GetLoginUserID,
  getUserDetails,
  getPrivilege,
  DeepCopy,
} from "../../services/CommonService";
import { SaveManualBreakdownMaindence } from "./SaveManualBreakdownMaindence";
import { TabBar } from "../../components/BreakdownTabBar";
import swal from "sweetalert";
import { DownloadModel } from "./DownloadModel";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";
import { useLocation } from "react-router-dom";
import { DataTableWithAjax } from "../../components/DataTableWithAjax/DataTableWithAjax";
import { BASE_URL } from "../../env";
import { InvokeCall } from "../../services/NotificationService";

const obj = new BreakdownMaintenanceService();
const { COOKIES } = Global;

export const MainGrid = () => {
  const [StationList, setStationList] = useState([]);
  const [EmployeeDropDown, setEmployeeDropDown] = useState([]);
  const [StatusDropDown, setStatusDropDown] = useState([]);
  const [StationID, SetStationID] = useState(0);

  const [TicketDetails, SetTicketDetails] = useState([]);
  const [ManualTicketDetailes, SetManualTicketDetails] = useState([]);
  const [AssignByDropDown, setAssignByDropDown] = useState([]);


  const [FilteredPartDropDown, setFilteredPartDropDown] = useState([]);


  const [FilteredSpareDropdown, setFilteredSpareDropdown] = useState([]);

  const [SelectedSpareList, setSelectedSpare] = useState([]);
  const [Refresh, SetRefresh] = useReducer((x) => x + 1, 0);
  const [ManualTableRefresh, SetManualTableRefresh] =  useReducer((x) => x + 1, 0);

  const [RefreshForm, setRefreshForm] = useReducer((x) => x + 1, 0);
  const [DownLoadRefresh, setDownLoadRefresh] = useReducer((x) => x + 1, 0);

  const [IsView, SetIsView] = useState(false);
  const [IsViewforManual, setIsViewforManual] = useState(false);
  const [Flags, setFlag] = useState(0);


  const [StatusList, SetStatusList] = useState([]);
  const [FileDatas, setFileDatas] = useState([]);
  const {
    getCookies,
    setCookies,
    showLoading,
    hideLoading,
    initialBreakdownTabID,
    handleBreakdownTab,
    handleGlobalStationName,
  } = useContext(AppContext);

  const [BreakdownDate, setBreakdownDate] = useState("");
  const onBreakdownDateChange = (e) => {
    setBreakdownDate(e.target.value);
  };
  const onBreakdownDateCleareChange = () => {
    setBreakdownDate("");
  };
  const location = useLocation();
  const columnNames = [
    { title: "Station Code", data: "stationCode" },

    {
      title: "Ticket No",
      data: "ticketNo",
      createdCell: (td, cellData, rowData, row, col) => {
        var b =
          $(`<a class='btn icon-btn text-center' title='View' data-bs-toggle="modal" data-bs-target="#BreakdownMaintenceModel" >
            ${cellData}  ${
            rowData.Type == "TREND" ? "" : '<i class="las la-bell"></i>'
          }
          </a>`).on("click", function () {
            EditBreakdown(rowData.ticketID);

            SetIsView(false);
          });
        $(td).html(b);
      },
    },
    {
      title: "Ticket Date",
      data: "savedDate",
    },
    {
      title: "Ticket Time",
      data: "breakdownTime",
    },

    {
      title: "<p style = 'width :200px'>  Equipment Tag Number </p>",
      data: "equipmentCode",
    },
    { title: "Parameter", data: "parameterName" },
    { title: "UOM", data: "parameterUnit" },

    { title: "Acceptable Param Value", data: "acceptableParameter" },
    { title: "Threshold High", data: "thresholdValue" },
    { title: "Actual Value", data: "currentReading" },

    {
      title: " Status",
      data: "statusName",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = GetPeriodicStatus(cellData);
        $(td).html(b);
      },
    },
    {
      title: "Submit  ",
      data: "ticketID",
      createdCell: (td, cellData, rowData, row, col) => {
        var editBtn = "";
        if (getPrivilege().includes("BREAKDOWNMAINTENANCEEDIT")) {
          editBtn =
            $(`<button class='btn icon-btn text-center' title='Edit' data-bs-toggle="modal" data-bs-target="#BreakdownMaintenceModel" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
              
              SetIsView(true);
              EditBreakdown(rowData.ticketID);
            });
        }

        var DwnBtn = "";

        if (rowData.DownloadFlag == 1) {
          DwnBtn =
            $(`<button class='btn icon-btn text-center'  data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              // SetIsView(true)
              DownloadFiles(rowData.ticketID, 0, 1);
              setFlag(1);
            });
        } else {
          DwnBtn =
            $(`<button class='btn icon-btn text-center' disabled data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`);
        }

        $(td).html(editBtn);
        $(td).append(DwnBtn);
      },
    },
  ];

  const columnNamesForManual = [
    { title: "Station Code", data: "StationCode" },

    {
      title: "Ticket No",
      data: "TicketNo",
      createdCell: (td, cellData, rowData, row, col) => {
        var b =
          $(`<a class='btn icon-btn text-center' title='View' data-bs-toggle="modal" data-bs-target="#ManualBreakdownMaintenceModel" >
            ${cellData}
          </a>`).on("click", function () {
            setIsViewforManual(true);
            EditManualBreakdown(rowData.TicketID, 0);
          });
        $(td).html(b);
      },
    },

    { title: "Equipment Tag No", data: "EquipmentCode" },
    { title: "Parameter", data: "ParameterName" },
    { title: "UOM", data: "ParameterUnit" },
    { title: "Assigned By", data: "AssignedByName" },
    { title: "Assigned To", data: "AssignedToName" },
    { title: "Problem Description", data: "ProblemDescription" },
    { title: "Work Description", data: "WorkDescription" },
    { title: "Location", data: "Location" },
    {
      title: " Status",
      data: "StatusName",
      createdCell: (td, cellData) => {
        var b = GetPeriodicStatus(cellData);
        $(td).html(b);
      },
    },
    {
      title: "Submit",
      data: "TicketID",
      createdCell: (td, cellData, rowData, row, col) => {
        let editBtn = "",
          deleteBtn = "",
          downloadBtn = "";

        if (getPrivilege().includes("BREAKDOWNMAINTENANCEEDIT")) {
          editBtn =
            $(`<button class='btn icon-btn text-center' title='Edit' data-bs-toggle="modal" data-bs-target="#ManualBreakdownMaintenceModel" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
              setIsViewforManual(false);
              EditManualBreakdown(rowData.TicketID, 0);
            });
        }
        if (getPrivilege().includes("BREAKDOWNMAINTENANCEDELETE")) {
          deleteBtn =
            $(`<button class='btn icon-btn text-center' title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
      </button>`).on("click", function () {
              // SetIsView(true)
              EditManualBreakdown(rowData.TicketID, 1);
            });
        }
        if (rowData.DownloadFlag == 1) {
          downloadBtn =
            $(`<button class='btn icon-btn text-center'  data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              DownloadFiles(rowData.TicketID, 0, 2);
              setFlag(2);
            });
        } else {
          downloadBtn =
            $(`<button class='btn icon-btn text-center' disabled data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'><i class='fa fa-download m-r-5'></i>
              </button>`);
        }

        $(td).html(editBtn);
        $(td).append(deleteBtn);
        $(td).append(downloadBtn);
      },
    },
  ];

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;
      if (val.TICKET_NO != "") {
        let type = val.TICKET_NO.startsWith("TKT") ? 1 : 2;
        BreakdownMaintenance_GetStationIdUsingTicketNo(type, val.TICKET_NO);
      }
    }
    NecessaryDropDown();
    getStationList();
  }, [location?.state]);

  const BreakdownMaintenance_GetStationIdUsingTicketNo = (type, ticketNo) => {
    showLoading();
    obj
      .BreakdownMaintenance_GetStationIdUsingTicketNo(type, ticketNo)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          const { StationID } = response.data.data[0];
          SetStationID(StationID);
        }
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };



  const NecessaryDropDown = async () => {
   
    await obj
      .BreakdownMaintenance_GetDropDownListForSystemTicket()
      .then((res) => {
        if (res?.data?.data) {
          
          
          const { statusMaster, statusListForManual : DropDown } = res.data.data;
          let result = [];

          if (getUserDetails().RoleID == COOKIES.TechRoleID) {
            result = statusMaster.filter(function (item) {
              return ["Open", "Completed"].indexOf(item.key) != -1;
            });
          } else {
            result = statusMaster.filter(function (item) {
              return (
                ["Assigned", "Approved", "Rejected"].indexOf(item.key) != -1
              );
            });
          }
          let result_1 = [];
          if (getUserDetails().RoleID == COOKIES.TechRoleID) {
            result_1 = DropDown.filter(function (item) {
              return ["Attended", "Open"].indexOf(item.key) != -1;
            });
          } else {
            result_1 = DropDown.filter(function (item) {
              return ["Approved", "Rejected"].indexOf(item.key) != -1;
            });
          }
          SetStatusList([...result_1]);

          setStatusDropDown([...result]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, key: data.StationCode });
    });
    setStationList(dataLst);
    SetStationID(dataLst[0].value);

    handleGlobalStationName(dataLst[0].key);
  };

  const GetTicketDetails = (TicketID) => {
    var employeeID = GetLoginUserID();
    showLoading();
    obj
      .GetBreakdownMaintenanceList(
        StationID,
        employeeID,
        BreakdownDate,
        TicketID
      )
      .then((response) => {
        if (response != null && response.data != null) {
          const {
            breakdownModel,
            usedSpareModel,
            employeeList: employeeModel,
            partDropDown,
            spareList,
          } = response.data;

          if (breakdownModel?.length) {
            SetTicketDetails(breakdownModel[0]);
          } else {
            SetTicketDetails({});
          }
          if (usedSpareModel?.length) {
            setSelectedSpare([...usedSpareModel]);
          } else {
            setSelectedSpare([]);
          }

          if (spareList?.length) {
            setFilteredSpareDropdown([...spareList]);
          } else {
            setFilteredSpareDropdown([]);
          }

          if (partDropDown?.length) {
            setFilteredPartDropDown([...partDropDown]);
          } else {
            setFilteredPartDropDown([]);
          }
          if (employeeModel?.length) {
            const EmpList = employeeModel.filter((x) => {
              return x.roleID == Global.COOKIES.TechRoleID;
            });
            const EmpList1 = employeeModel.filter((x) => {
              return x.roleID !== Global.COOKIES.TechRoleID;
            });
            setAssignByDropDown([...EmpList1]);
            setEmployeeDropDown([...EmpList]);
          } else {
            setAssignByDropDown([]);
            setEmployeeDropDown([]);
          }
        }
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };


  const HandleChange = (e) => {
    SetStationID(e.target.value);
    var selectedOption = e.target.options[e.target.selectedIndex];

    var selectedLabel = selectedOption.text;
    handleGlobalStationName(selectedLabel ?? "");
  };

  const EditBreakdown = (TicketID) => {
    GetTicketDetails(TicketID);
    setRefreshForm();
  };


  const EditManualBreakdown = (TicketID, IsDeleted) => {
    if (IsDeleted == 1) {
      swal({
        title: "Are you sure?",
        text: "Do you want to delete this ticket?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          obj
            .BreakdownMaintenance_GetManualTicketUsingTicketID(
              TicketID,
              IsDeleted
            )
            .then((res) => {
              SetManualTableRefresh();

              if (res.data != null && res.data.length > 0) {
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    } else {
      obj
        .BreakdownMaintenance_GetManualTicketUsingTicketID(TicketID, IsDeleted)
        .then((res) => {
          if(res?.data){
            const {ticketModel,spareUsedModel } =res.data;
            if(ticketModel?.length){
              SetManualTicketDetails(ticketModel[0]);
            }
            if(spareUsedModel?.length){
              setSelectedSpare(spareUsedModel);
            }
          }
          setRefreshForm();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    // showLoading()
    obj
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        // hideLoading()

        setFileDatas(res.data);

        setDownLoadRefresh();
      })
      .catch((err) => {
        // hideLoading()
        console.log(err);
      });
  };
  function SubmitBtn() {
    let SendForApprovelBtn = $(
      ` <button  type="button"  id="ManualBreakdownMaintenceTable" class="btn add-btn m-l-15" data-bs-toggle="modal" data-bs-target="#ManualBreakdownMaintenceModel"
          > <i class="fa fa-plus"></i> Add </button> `
    ).on("click", async function (e) {
      e.preventDefault();
      setSelectedSpare([])
      await setIsViewforManual(false);
      await EditManualBreakdown(0, 0);
      await SetManualTicketDetails({});
      await setRefreshForm();
    });

    let Fragment = $(`<span></span>`);

    if (getPrivilege().includes("BREAKDOWNMAINTENANCECREATE")) {
      Fragment.prepend(SendForApprovelBtn);
    }

    return Fragment;
  }

  const dataManipulation = (json) => {
    const { breakdownModel } = json.data;
    let copyObj = DeepCopy(json);
    copyObj.data = breakdownModel;
    return copyObj;
  };








  return (
    <>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <h3 className="page-title">Breakdown Maintenance</h3>
        </div>
        <div className="row col-md-8 col-sm-12 justify-content-end">
          <div className="col-md-3 col-sm-4">
            <WithoutSelectDropDown
              data={StationList}
              value={StationID}
              name={"StationIDDropDown"}
              HandleChange={HandleChange}
            ></WithoutSelectDropDown>
          </div>
          <div className="col-md-1 col-sm-1 text-center">
            <button
              type="button"
              id="addRow"
              onClick={onBreakdownDateCleareChange}
              className="btn btn-sm btn-danger"
            >
              <i className="fa fa-refresh " title="Reset" />
            </button>
          </div>

          <div className="col-md-3 col-sm-4">
            <input
              type="date"
              className="form-control"
              id="BreakdownDate"
              style={{ height: "40px" }}
              pattern="\d{4}-\d{2}-\d{2}"
              value={BreakdownDate}
              onChange={(e) => {
                onBreakdownDateChange(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <TabBar
          InitialValue={initialBreakdownTabID}
          header={["System Ticket", "Manual Ticket"]}
          body={[
            <div style={{ position: "relative", top: "-20px" }}>
              <DataTableWithAjax
                columnList={columnNames}
                url={`${BASE_URL}BreakdownMaintenance/GetBreakdownMaintenanceList?stationID=${StationID}&employeeID=${GetLoginUserID()}&ticketID=0&BreakdownDate=${BreakdownDate}`}
                id={"BreakdownMaintence"}
                dataManipulation={dataManipulation}
                fixedColumns={{
                  left: 1,
                  right: 1,
                }}
                tableRefresh={Refresh}
              />
            </div>,
            <div style={{ position: "relative", top: "-20px" }}>
              <DataTableWithAjax
                columnList={columnNamesForManual}
                url={`${BASE_URL}BreakdownMaintenance/BreakdownMaintenance_GetManualTicket?stationID=${StationID}&employeeID=${GetLoginUserID()}&BreakdownDate=${BreakdownDate}`}
                fixedColumns={{
                  left: 1,
                  right: 1,
                }}
                tableRefresh={ManualTableRefresh}
                id={"ManualBreakdownMaintence"}
                SearchPostponedHtml={SubmitBtn}
              />{" "}
            </div>,
          ]}
          onTabChange={handleBreakdownTab}
        >
          {" "}
        </TabBar>
      </div>

      <BootrapModel
        Id={"ManualBreakdownMaintenceModel"}
        CloseID="ManualBreakdownMaintenceModelClose"
        closepopup={() => SetManualTableRefresh()}
        ModelTitle={
          ManualTicketDetailes.TicketID == undefined
            ? "Manual Ticket Creation"
            : IsViewforManual
            ? `Manual Ticket View - (${ManualTicketDetailes.TicketNo})`
            : `Manual Ticket Edit - ${ManualTicketDetailes.TicketNo}`
        }
        modelSize={"modal-xl"}
        module={
          <SaveManualBreakdownMaindence
            IsShow={IsViewforManual}
            StationList={StationList}
            TicketDetailes={ManualTicketDetailes}
            StatusDropDown={StatusList}
            RefreshforForm={RefreshForm}
            IsView={IsView}
            SelectedSpareList={SelectedSpareList}
          ></SaveManualBreakdownMaindence>
        }
      >
        {" "}
      </BootrapModel>
      <BootrapModel
        Id={"BreakdownMaintenceModel"}
        CloseID="BreakdownMaintenceModelClose"
        ModelTitle={`Breakdown Maintenance Edit- ${TicketDetails.ticketNo}`}
        modelSize={"modal-xl"}
        closepopup={() => SetRefresh()}
        module={
          <SaveBreakdownMaintenance
            EmployeeDropDown={EmployeeDropDown}
            TicketDetailes={TicketDetails}
            StatusDropDown={StatusDropDown}
            Sparelist={FilteredSpareDropdown}
            PartDropDown={FilteredPartDropDown}
            SelectedSpareList={SelectedSpareList}
            RefreshforForm={RefreshForm}
            AssignByDropDown={AssignByDropDown}
            IsView={IsView}
            SubmitButton={true}
          ></SaveBreakdownMaintenance>
        }
      >
        {" "}
      </BootrapModel>

      <BootrapModel
        Id={"DownLoadFiles"}
        CloseID="DownLoadFilesClose"
        ModelTitle={
          Flags == 2
            ? "Manual Ticket - Download Attachments"
            : "System Ticket - Download Attachments"
        }
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={Flags}
            DeleteAttachment={DownloadFiles}
            FileDatas={FileDatas}
            DownLoadRefresh={DownLoadRefresh}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>
    </>
  );
};

export const GetPeriodicStatus = (value) => {
  var id = value;
  var name = value;

  if (id == "Assigned") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-info"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Open") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-primary"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Attended") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-secondary" ></i> ${name}
    </a>
  </div>`;
  } else if (id == "Completed") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-success"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Approved") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-success"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Pending") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-warning"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Waitting for Engineer") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-warning"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Closed") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-muted"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Rejected") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-danger"></i> ${name}
    </a>
  </div>`;
  }
};
