import React, { Children, useContext, useEffect } from "react";
import { BootrapModel, HtmlTable, PageHeaderTitle } from "../../../components";
import { IoIosAddCircleOutline } from "react-icons/io";
import { PeriodicStartMonthContext } from "./ContextProvider";
import { MasterConfig_GetDepartmentList } from "../../../screens/master/MasterDetailService";
import { PeriodicStartMonthSave } from "./PeriodicStartMonthSave";
import {
  BuildTreeUsingParentID,
  GenerateYearForReactSelect,
  ShortMonthsForPeriodic,
  findDepartmentById,
} from "../../../services/CommonService";
import { v4 as uuidv4 } from "uuid";
import {
  PeriodicStartMonth_GetMaintenanceType,
  PeriodicStartMonth_GetPeriodicStartMonthList,
} from "../ApplicationService";
import $ from "jquery";
import swal from "sweetalert";
import { DefaultAppContextValues } from "../../../services/ContextProvider";

export const MainTable = () => {
  const {
    setDropdownModel,
    DropDownInitialState,
    DropdownModel,
    InitialState,
    PeriodicStartModel,
    setPeriodicStartModel,
    ScheduleTypeOptions,
    ErrInitialState,
  } = useContext(PeriodicStartMonthContext);

  const { GetLoginStationList } = DefaultAppContextValues();

  const { FilterDepartmentList, StationList,FilterStation } = DropdownModel;

  useEffect(() => {
    GetDepartmentList();
    GetMaintenanceList();
    getDataForTable();
    return () => {
      setDropdownModel(DropDownInitialState);
    };
  }, []);

  const columnNames = [
    {
      title: "PeriodicScheduleConfigID",
      data: "PeriodicScheduleConfigID",
      visible: false,
    },
    {
      title: "Department Name",
      data: "DepartmentName",
    },
    {
      title: "Stations",
      data: "StationCode",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(`<p class='Eclispedot' title='${cellData}'>${cellData}</p>`);
      }
    },
    {
      title: "Year",
      data: "YearID",
    },
    {
      title: "Q1 Start Month",
      data: "DisplayQ1_StartMonth",
    },
    {
      title: "Q1 Job Month",
      data: "DisplayQ1_EndMonth",
    },
    {
      title: "Q2 Job Month",
      data: "DisplayQ2_EndMonth",
    },
    {
      title: "Q3 Job Month",
      data: "DisplayQ3_EndMonth",
    },
    {
      title: "Q4 Job Month",
      data: "DisplayQ4_EndMonth",
    },
    {
      title: "H1 Job Month",
      data: "DisplayH1_EndMonth",
    },
    {
      title: "H2 Job Month",
      data: "DisplayH2_EndMonth",
    },

    {
      title: "Yearly Job Month",
      data: "DisplayYearly_EndMonth",
    },
    {
      title: "Submit  ",
      data: "PeriodicScheduleConfigID",
      createdCell: (td, cellData, rowData, row, col) => {
        const { PeriodicScheduleConfigID } = rowData;
        let div = $("<div>");
        let EditBtn = $(`<button class='btn icon-btn text-center' title='Edit' 
          data-bs-toggle="modal"
          data-bs-target="#PeriodicStartMonthConfig" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
          getDataForTable(PeriodicScheduleConfigID);
        });
        let deleteBtn = $(`<button class='btn icon-btn'  title='Delete'  >
          <i class='fa fa-trash m-l-15'></i>
        </button>`).on("click", function () {
          swal({
            title: "Are You Sure?",
            text: "Do you want to delete this config?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              getDataForTable(PeriodicScheduleConfigID, 1);
            }
          });
        });
        div.append(EditBtn);
        div.append(deleteBtn);

        $(td).html(div);
      },
    },
  ];

  const GetDepartmentList = async () => {
    const StationList = GetLoginStationList();

    await MasterConfig_GetDepartmentList()
      .then((res) => {
        if (res?.data?.data?.length) {
          let treeJSON = BuildTreeUsingParentID(res.data.data);
          let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));

          let DepartmentList = [
            {
              id: uuidv4(),
              name: "Select",
              children: Data,
            },
          ];

          Data.forEach((ele) => (ele.isOpen = true));

          let YearList = GenerateYearForReactSelect();

          setDropdownModel((pre) => ({
            ...pre,
            FilterStation : StationList,
            StationList : [],
            FilterDepartmentList: DepartmentList,
            DepartmentList: [],
            YearList: YearList,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const getDataForTable = (id = 0, IsDeleted = 0) => {
    PeriodicStartMonth_GetPeriodicStartMonthList(id, IsDeleted)
      .then((res) => {
        if (res?.data?.data?.jobMonthList?.length) {
          if (id > 0 && IsDeleted == 0) {
            ManipulateInModel(res.data.data);
          } else {
            ManipulatedList(res.data.data);
          }
        } else {
          setDropdownModel((pre) => ({
            ...pre,
            PeriodicStartMonthList: [],
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  function ManipulatedList(ManipulatedList) {
    let DataList = [];
    const { periodicConfigList, jobMonthList } = ManipulatedList;

    const objList = [
      "Q1_StartMonth",
      "Q1_EndMonth",
      "Q2_EndMonth",
      "Q3_EndMonth",
      "Q4_EndMonth",
      "H1_EndMonth",
      "H2_EndMonth",
      "Yearly_EndMonth",
    ];

    periodicConfigList.forEach((ele) => {
      const {
        PeriodicScheduleConfigID,
        YearID,
        DepartmentName,
        DepartmentId,
        StationIDs,
        StationCode,
      } = ele;
      let obj = {
        PeriodicScheduleConfigID,
        YearID,
        DepartmentName,
        DepartmentId,
        StationIDs,
        StationCode,
      };
      let FilterList = jobMonthList.filter(
        (job) => job.PeriodicScheduleConfigID == PeriodicScheduleConfigID
      );
      if (FilterList?.length) {
        objList.forEach((ele) => {
          let FilterObj = FilterList.filter((job) => job.StatusName == ele);
          if (FilterObj?.length) {
            obj["Display" + ele] = FilterObj.map(
              (ele) => ele.ShortMonthName
            ).join(",");
          } else {
            obj["Display" + ele] = "";
          }
        });
        DataList.push(obj);
      }
    });

    setDropdownModel((pre) => ({
      ...pre,
      PeriodicStartMonthList: DataList,
    }));
  }

  const ManipulateInModel = (model) => {
    if (model) {
      
      const { periodicConfigList, jobMonthList } = model;
      const { DepartmentId, YearID, PeriodicScheduleConfigID, StationIDs } =
        periodicConfigList[0];
      let SelectedStation = [];
      
      if (StationIDs?.length > 0) {
        let ConvertedList = StationIDs.split(",").map((ele) => parseInt(ele));

        SelectedStation = FilterStation?.filter((ele) =>
          ConvertedList.includes(ele.value)
        );
      }

      jobMonthList.map((ele) => {
        ele.label = ele.ShortMonthName;
        ele.value = ele.MonthValue;
      });

      let Q1_StartMonth = jobMonthList.find(
        (ele) => ele.StatusName == "Q1_StartMonth"
      );
      let Q1_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "Q1_EndMonth"
      );
      let Q2_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "Q2_EndMonth"
      );
      let Q3_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "Q3_EndMonth"
      );
      let Q4_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "Q4_EndMonth"
      );
      let H1_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "H1_EndMonth"
      );
      let H2_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "H2_EndMonth"
      );
      let Yearly_EndMonth = jobMonthList.filter(
        (ele) => ele.StatusName == "Yearly_EndMonth"
      );

      if (FilterDepartmentList?.length) {
        let TempDepart = JSON.parse(JSON.stringify(FilterDepartmentList));

        let DepartmentObj = findDepartmentById(TempDepart, DepartmentId);
        if (DepartmentObj) {
          TempDepart[0].id = DepartmentId;
          TempDepart[0].name = DepartmentObj.name;
          debugger
          let StationList = FilterStation?.filter((ele) => ele.Type == DepartmentObj.Type);

          setDropdownModel((pre) => ({
            ...pre,
            StationList
          }));

        
        }
        setDropdownModel((pre) => ({
          ...pre,
          DepartmentList: TempDepart,
        }));
      }

      setPeriodicStartModel((pre) => ({
        ...pre,
        Q1_StartMonth,
        Q1_EndMonth,
        Q2_EndMonth,
        Q3_EndMonth,
        Q4_EndMonth,
        H1_EndMonth,
        H2_EndMonth,
        Yearly_EndMonth,
        DepartmentId,
        PeriodicScheduleConfigID,
        StationID : SelectedStation,
        YearID: { label: YearID, value: YearID },
      }));

      ScheduleTypeOptions(Q1_StartMonth.value, true);
    }
  };

  const GetMaintenanceList = async () => {
    await PeriodicStartMonth_GetMaintenanceType()
      .then((res) => {
        if (res?.data?.data?.length) {
          const { data } = res.data;
          setDropdownModel((pre) => ({
            ...pre,
            MaintenanceTypeList: data,
            Q1MonthList: [...ShortMonthsForPeriodic()],
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div class="page-header mb-3">
            <div class="row justify-content-between">
              <h3 class="page-title">
                Periodic Start Month Configuration
              </h3>
            </div>
          </div>
        </div>
        <div className="col-4">
          <button
            className="btn btn-primary float-end"
            data-bs-toggle="modal"
            data-bs-target="#PeriodicStartMonthConfig"
            onClick={() => {
              setPeriodicStartModel(InitialState);
              setDropdownModel((pre) => ({
                ...pre,
                DepartmentList: FilterDepartmentList,
                StationList : []
              }));
            }}
          >
            <i className="fa fa-plus"></i> Add{" "}
          </button>
        </div>
      </div>

      <HtmlTable
        columnList={columnNames}
        id={"PeriodicScheduleCOnfigList"}
        rowList={DropdownModel?.PeriodicStartMonthList ?? []}
        FixedColums={{
          left: 2,
          right: 1,
        }}
      >
        {" "}
      </HtmlTable>

      <BootrapModel
        Id={"PeriodicStartMonthConfig"}
        CloseID="PeriodicStartMonthCOnfigClose"
        ModelTitle={`Periodic Maintenance Start Month Configuration`}
        modelSize={"modal-xl"}
        module={<PeriodicStartMonthSave />}
        closepopup={() => {
          getDataForTable();
          setDropdownModel((pre) => ({
            ...pre,
            ErrorObj: ErrInitialState,
          }));
        }}
      />
    </div>
  );
};
