import React from 'react'
import { useState, useEffect, useContext } from 'react';
import MasterDetailService from '../master/MasterDetailService';
import { BASE_URL } from '../../env';

import { DataTableReportAjax } from '../../components/DataTableReportAjax';
import $ from "jquery";

import { ReactMultiSelect, MultiValue, Option } from '../../components/MultiSelect/ReactMultiSelect';
import makeAnimated from "react-select/animated";
import ManualParameterEntryService from './ManualParameterEntryService';
import { AppContext } from '../../services/ContextProvider';
import Global from '../../services/Global'
import { Decrypt } from '../../services/CommonService'
const obj = new ManualParameterEntryService();
const MDS = new MasterDetailService();
const animatedComponents = makeAnimated();



export const Report_ManulPointEntry = () => {

  const { showLoading, hideLoading, getCookies,handleGlobalStationName } = useContext(AppContext);

  const columnNames = [
    // { title: "Manual pointID", visible: false },
    { title: "Serial No" ,data : "RowId" },
    { title: "Station Code" , data : "StationCode" },
    { title: "Manual Type",data : "MaintenanceTypeName" },
    { title: "Date",data : "PointDate" },
    { title: "Equipment Tag Number",data : "EquipmentCode" },
    { title: "Parameter",data : "ParameterName" },
    { title: "Parameter Value",data : "PointValue"},
    { title: "Created Date",data : "CreateDate" },
    { title: "Remarks",data : "Remarks" },
  ];



  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [data, setData] = useState([]);
  const [FromDate, SetfromDate] = useState("");
  const [ToDate, SetToDate] = useState("");



  const HandleChange = (e) => {

    SetStationID(e);
    let StationLabel = e.map(x => x.label).join()
    handleGlobalStationName(StationLabel)
  }


  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations))
    var dataLst = [];

    stationList.map((data) => {

      dataLst.push({ value: data.StationID, label: data.StationCode });

    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);

    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };


  useEffect(() => {
    getStationList();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    SetfromDate(firstDay.toISOString().split('T')[0]);
    SetToDate(lastDay.toISOString().split('T')[0])
  }, [])

  useEffect(() => {

    var stationIds = StationID.map((x => {
      return x.value
    }))

    if (FromDate != null) {

      $("#ToDate").attr("min", FromDate);

    }

    if (ToDate != null) {

      $("#FromDate").attr("max", ToDate)

    }



    // getData(stationIds);

    return () => { setData([]) }

  }, [StationID, FromDate, ToDate])




  function getData(stationIds) {
    showLoading();
    if (stationIds != null && stationIds != ''  && FromDate != '' && ToDate != '')
    {
      obj.Report_ManualPointEntry(FromDate, ToDate, stationIds).then((response) => {

        var BreakList = [];
        if (response.data.length > 0) {
          var breakdownMaintenance = response.data;

          breakdownMaintenance.map((x, i) => {

            var row = [];

            // row.push(x.ManualParameterID);
            row.push(i + 1);
            row.push(x.StationCode);
            row.push(x.StatusName);
            row.push(x.PointDate);
            row.push(x.EquipmentCode);
            row.push(x.ParameterName);
            row.push(x.PointValue);
            row.push(x.CreateDate);
            row.push(x.Remarks);
            row.push("");
            BreakList.push(row);
          });
          setData([...BreakList]);
          hideLoading();
        }
        else {
          setData([]);
          hideLoading();
        }
      }).catch((Err) => {
        console.log(Err);
        hideLoading();
      })
    }
    else {
      setData([]);
      hideLoading();
    }
  }


  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col">
            <h3 className="page-title">Manual Entry Parameter Report</h3>
          </div>
          <div className="col-2">
            <label className="col-form-label">From Date</label>

            <input
              type="date"
              className="form-control"
              id='FromDate'

              defaultValue={FromDate}
              onChange={(e) => {

                SetfromDate(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <label className="col-form-label">To Date</label>

            <input
              type="date"
              className="form-control"
              id='ToDate'
              defaultValue={ToDate}

              onChange={(e) => {

                SetToDate(e.target.value);
              }}
            />
          </div>
          <div className='col-2' >
            <label className="col-form-label">Stations</label>

            <ReactMultiSelect
              options={StationList}
              isMulti
              value={StationID}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => HandleChange(e)}
              allowSelectAll={true}

            />
          </div>
        </div>
      </div>
      <div className="card card-body">

        <div className="">





          <DataTableReportAjax
          
          columnList={columnNames} 
           id={'ManulPointENtry'} title={"Manual Entry parameter report"}
           FromDate={FromDate}
           ToDate={ToDate}
           StationID={ StationID ? StationID.map((x) => {return x.value}).join(",") : 0}
          url={`${BASE_URL}ManualPointEntry/Report_ManualPointEntry`}

           isAsc={true} />

        </div>
      </div>
    </>
  )
}

                                                                                                 