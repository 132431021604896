import React, { useState } from "react";
import { HtmlTable, ReactMultiSelect } from "../../../components";
import { MultiValue, Option } from "../../../components";
import makeAnimated from "react-select/animated";
import { PieChart } from "../../../components/charts/PieChart";
import {
  AllDepartmentData,
  AllStationList,
  AllEquipmentAvaWiseData,
  BreakdownVerifyReportData,
  StackedDataForBDEquipmentWise,
  BreakdownVerifydata,
} from "../TempData";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

export default function Verifycation() {
  const [FilterModel, setFilterModel] = useState({
    Departments: [],
    FromDate: "",
    ToDate: "",
    Corridor: "",
    Frequency: "",
    Stations: [],
  });
  const [PeriodicModel, setPeriodicModel] = useState({
    ShowBar: false,
    AvailableShowBar: false,
    StationWiseData: [],
    Availabilitydata: [],
    ListEquipmentAvailabilitydata: BreakdownVerifydata,
    DepartmentData: AllDepartmentData,
    EquipmentWiseBm: StackedDataForBDEquipmentWise,
    StationWiseDataForBM: [],
    DepartmentName: "",
  });
  const animatedComponents = makeAnimated();

  const AvailabilityWiseClick = (event, chartElements) => {
    
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      let Availabilitydata = JSON.parse(
        JSON.stringify(AllEquipmentAvaWiseData)
      );
      let filterLabel =
        AllEquipmentAvaWiseData.datasets[clickedElementIndex].label;
      Availabilitydata.datasets = Availabilitydata.datasets.filter(
        (ele) => ele.label == filterLabel
      );
      setPeriodicModel((pre) => ({
        ...pre,
        AvailableShowBar: true,
        Availabilitydata: Availabilitydata,
      }));
      return 1;
    }

    let Availabilitydata = AllEquipmentAvaWiseData;

    setPeriodicModel((pre) => ({
      ...pre,
      AvailableShowBar: true,
      Availabilitydata: Availabilitydata,
    }));
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div>
      <div className="row mb-2">
        <div className="col-md-7">
          <div className="row">
            <div className="col-3">
              <h3>Dashboard</h3>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "E&M", value: 1 },
                  { label: "Civil Maintenance", value: 2 },
                  { label: "Lift & escalator", value: 3 },
                  { label: "OHE", value: 4 },
                  { label: "Track", value: 5 },
                  { label: "Panel", value: 6 },
                  { label: "Lighting", value: 7 },
                ]}
                placeholder={"Departments"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Departments: e }))
                }
                value={FilterModel.Departments}
              ></ReactMultiSelect>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "Lift", value: 1 },
                  { label: "Escalator", value: 2 },
                ]}
                placeholder={"Equipment"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Equipment: e }))
                }
                value={FilterModel.Equipment}
              ></ReactMultiSelect>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                isMulti
                options={[
                  { label: "C1 - UG", value: 1 },
                  { label: "C1 - Elevated", value: 2 },
                  { label: "C2 - UG ", value: 3 },
                  { label: "C2 - Elevated", value: 4 },
                ]}
                placeholder={"Corridor"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Corridor: e }))
                }
                value={FilterModel.Corridor}
              ></ReactMultiSelect>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="row">
            <div className="col-4">
              <ReactMultiSelect
                isMulti
                options={AllStationList}
                placeholder={"All Stations"}
                components={{ Option, MultiValue, animatedComponents }}
                allowSelectAll
                onChange={(e) =>
                  setFilterModel((pre) => ({ ...pre, Stations: e }))
                }
                value={FilterModel.Stations}
              ></ReactMultiSelect>
            </div>
            <div className="col-8">
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY" // Specify the date format
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row mt-1">
            <ol
              class="breadcrumb text-muted mb-0 col-12"
              style={{ padding: "0 13px" }}
            >
              <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Departments?.length > 0
                    ? FilterModel.Departments.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Departments"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Equipment?.length > 0
                    ? FilterModel.Equipment.map((ele) => ele.label).toString(
                        ","
                      )
                    : "All Equipment"}
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  {FilterModel.Corridor?.length > 0
                    ? FilterModel.Corridor.map((ele) => ele.label).toString(",")
                    : "All Corridors"}
                </a>
              </li>
              <li class="breadcrumb-item text-muted">
                {FilterModel.Stations?.length > 0
                  ? FilterModel.Stations.map((ele) => ele.label).toString(",")
                  : "All Stations"}
              </li>
            </ol>

            <div className="col-4"></div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-12 card p-b-10 " style={{ paddingBottom: 30 }}>
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            E&M - Escalator Cyclic Check Report Status
          </h3>

          <div className="row">
            <div className="row col-12 mt-2 mx-auto">
              <div className="col xs12 m6 s12 l6 xl3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12  text-center">
                        <h3 class="card-title mt-2">
                          E&M - Escalator Cyclic Check Report Status
                        </h3>
                        <hr style={{ marginTop: 10 }} />
                      </div>
                      <div
                        class="col-md-12"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div
                          class=" m-0"
                          style={{
                            maxWidth: "540px !important",
                            width: "480px",
                          }}
                        >
                          <div class="row d-flex justify-content-center">
                            <div class="mt-0 col-md-10 col-sm-6 ">
                              <div>
                                <PieChart
                                  data={
                                    PeriodicModel.ListEquipmentAvailabilitydata ??
                                    []
                                  }
                                  clickable={AvailabilityWiseClick}
                                  onDoubleClick={() => {
                                    AvailabilityWiseClick("_", false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col xs12 m6 s12 l6 xl3 text-center">
                <div className="card">
                  <div className="card-body">
                    <h3 class="card-title mt-2">
                      Breakdown Occured at SSA (SSA-E-01)
                    </h3>
                    <hr style={{ marginTop: 10 }} />

                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <AvaReportTable />
                    </div>
                  </div>
                </div>
              </div>
              {/* {PeriodicModel.AvailableShowBar && (
                <div className="col xs12 m6 s12 l6 xl3 text-center">
                  <div className="card">
                    <div className="card-body">
                      <h3 class="card-title mt-2">
                        Equipment Availability Status by Station Wise
                      </h3>
                      <hr style={{ marginTop: 10 }} />
                      <CommonBarChart
                        xTitle={"Stations"}
                        data={PeriodicModel.Availabilitydata}
                        clickable={() => {
                          $("#modelBreakdownBtn").click();
                        }}
                        IsShow={true}
                        LegendBoxWidth={10}
                        dataLabelsShow
                      ></CommonBarChart>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AvaReportTable = () => {
  const ColumNames = [
    {
      title: "Station",
      data: "StationName",
    },
    {
      title: "Equipment",
      data: "LiftName",
    },
    {
      title: "Last Maintained Date",
      data: "LastMaintainedate",
    },

    {
      title: "Status",
      data: "Status",
    },
  ];

  return (
    <HtmlTable
      columnList={ColumNames}
      rowList={BreakdownVerifyReportData}
      id={"AvaReportTableList"}
    ></HtmlTable>
  );
};
