import React, { useContext, useEffect, useState, } from "react";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { BASE_URL } from "../../env";
import "../../assets/css/dynamicTable.css";
import $ from "jquery";
import axios from "axios";
import makeAnimated from "react-select/animated";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";
import {
  BootrapModel,
  PageHeaderTitle,
  ReactMultiSelect,
} from "../../components";
import {
  useNavigate,
} from "react-router-dom";
import { Report_PeriodicMaintenance_DropDown } from "../PeriodicMaintenance/PeriodicService";

pdfMake.vfs = pdfFonts.pdfMake.vfs;





export const RCAReport = () => {
  const navigate = new useNavigate();
  const [FilterModel, setFilterModel] = useState({
    DepartmentID: {},
    Stations: {},
  });

  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    FilterStationList: [],
    DepartmentList: [],
  });
  const { getCookies, handleGlobalStationName } = useContext(AppContext);

  const columns = [
    { title: "Serial No", data: "RowID" },
    { title: "Ticket Number", data: "TicketNo" },
    { title: "Ticket Number", data: "TicketID" ,className : "hide" },
    { title: "Station Code", data: "StationCode" },
    { title: "Date Time Closure", data: "DateTimeClosure" },
    { title: "Date Time Incident", data: "DateTimeIncident" },
    { title: "Department Name", data: "DepartmentName" },
    { title: "Corrective Action", data: "CorrectiveAction" },
    { title: "Description of Incident", data: "Descriptionofincident" },
    {
      title: "Details of Faulty Components",
      data: "DetailsOfFaultyComponents",
    },
    {
      title: "Equipment Details",
      data: "EquipmentDetails",
    },
    {
      title: "Actions",
      data: "RCA_ID",
      createdCell: (td, cellData, rowData) => {
        const button =
          $(`<button class='btn icon-btn text-center' title='Download Remarks'
  
          
          >
          <i class='fa fa-download m-r-5'></i>
        </button>`).on("click", () => {
          debugger
          navigate("/RCAPdfReport",{
            state : {
              TicketID : rowData.TicketID
            }
          })
         
          });
        $(td).html(button);
      },
    },
  ];





  useEffect(() => {
    InitialDropDown();
    return () => {
      setDropdownModel((pre) => ({
        ...pre,
        StartMonthConfigList: [],
      }));
    };
  }, []);

  useEffect(() => {
    const { Stations, DepartmentID } = FilterModel;
    if (Stations?.value && DepartmentID?.value) {
      let EquipmentList = DropdownModel?.FilterEquipmentList.filter(
        (ele) =>
          ele.DepartmentID == DepartmentID.value &&
          ele.StationID == Stations.value
      );
      setDropdownModel((pre) => ({
        ...pre,
        EquipmentList: EquipmentList ?? [],
      }));

      setFilterModel((pre) => ({
        ...pre,
        EquipmentID: EquipmentList?.[0] ?? {},
      }));
    }
  }, [FilterModel?.DepartmentID?.value, FilterModel?.Stations?.value]);

  const InitialDropDown = async () => {
    let stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));
    let Department = [];
    DepartmentDropDownValues();

    let Stations = [];
    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({ value: data.StationID, label: data.StationCode });
      });
      handleGlobalStationName(Stations[0].key);
    }

    await Report_PeriodicMaintenance_DropDown()
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList, configMonth } = res.data.data;
          let EquipmentList = [];
          if (stationList?.length) {
            const StationID = Stations[0].value;

            const DepartmentID = Department[0].value;
            EquipmentList = equipmentList.filter(
              (ele) =>
                ele.StationID == StationID && ele.DepartmentID == DepartmentID
            );
          }

          setFilterModel((pre) => ({
            ...pre,
            EquipmentID: EquipmentList?.[0] ?? {},
            Stations: Stations[0],
            DepartmentID: Department[0],
          }));

          setDropdownModel((pre) => ({
            ...pre,
            StartMonthConfigList: configMonth,
            EquipmentList,
            FilterEquipmentList: equipmentList,
            StationList: Stations,
            DepartmentList: Department,
          }));
        }
      })
      .catch((err) => console.log(err));

    function DepartmentDropDownValues() {
      if (DepartmentList?.length) {
        const itemsWithoutChildren = DepartmentList.filter(
          (item) => !DepartmentList.some((child) => child.parentId === item.id)
        );
        itemsWithoutChildren.map((data) => {
          Department.push({ value: data.id, label: data.name });
        });

        Department = Department.filter((ele) => ele.label != "TVS & VAC");
      }
    }
  };

  const CommonHandleChange = (event, CustomName) => {
    if (CustomName) {
      setFilterModel((pre) => ({
        ...pre,
        [CustomName]: event,
      }));

      if (CustomName == "DepartmentID") {
        let StartMonth = DropdownModel?.StartMonthConfigList?.find(
          (ele) =>
            ele.DepartmentId == event.value && FilterModel.Year == ele.YearID
        );
        if (StartMonth) {
          setFilterModel((pre) => ({
            ...pre,
            PeriodicStartMonth: StartMonth.MonthValue,
          }));
        }
      }
    } else {
      const { name, value } = event.target;
      setFilterModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  return (
    <div>
      <PageHeaderTitle title={`Root Cause Analysis Report (RCAR) `}></PageHeaderTitle>

      <div className="row col-12 mb-1">
        {" "}
        <div className="col-2">
          <label className="form-check-label">Department</label>
          <ReactMultiSelect
            options={DropdownModel.DepartmentList}
            placeholder={"Department"}
            onChange={(e) => CommonHandleChange(e, "DepartmentID")}
            value={FilterModel.DepartmentID}
          ></ReactMultiSelect>
        </div>
        <div className="col-2">
          <label className="form-check-label">Stations</label>
          <ReactMultiSelect
            options={DropdownModel.StationList}
            placeholder={"Stations"}
            onChange={(e) => CommonHandleChange(e, "Stations")}
            value={FilterModel.Stations}
          ></ReactMultiSelect>
        </div>
      </div>

      <div className="card card-body pt-0">
        <DataTableReportAjax
          columnList={columns}
          id="rcaReportTable"
          title="RCA Report"
          url={`${BASE_URL}AuditingProcess/Report_BreakdownRcaReportDetailsAudit`}
          // FromDate={fromDate}
          // ToDate={ToDate}
          StationID={FilterModel.Stations.value}
          DepartmentID={FilterModel?.DepartmentID?.value}
          // PDFCustomization={PDFCustomization}
          // exportOptions={{
          //   columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          // }}
        />
      </div>
    </div>
  );
};
