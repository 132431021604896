import { useEffect, useState } from "react";
import { Dropdown, ReactTreeView } from "../../../components";
import { PrefinedFails_AddOrUpdate } from "./PredefinedFailuresService";
import { showWarningMsg, showSaveSuccess } from "../../../components/ToastMsg";
import { getYMDdate, GetLoginUserID } from "../../../services/CommonService";
import $ from "jquery";
import {
  BuildTreeUsingParentID,
  findDepartmentById,
} from "../../../services/CommonService";
const AddPrefiledFilures = ({
  model,
  handleRefresh,
  closepopup,
  DropdownModel,
  setDropdownModel,
  Render,
  setGridRender,
}) => {
  const [valuelist, setValueList] = useState({
    systemID: "",
    DepartmentID: "",
    CriticalType: "",
    EquipmentID: "",
    DescriptionName: "",
    DescriptionID: "0",
    BMPredefineFailuresID: "0",
  });
  const [treeData, settreeData] = useState([]);
  const [OnSelect, setOnSelect] = useState("");
  const [errors, setErrors] = useState({
    systemID: "",
    CriticalType: "",
    EquipmentID: "",
    DescriptionName: "",
  });
  const clearList = () => {
    
    setValueList((pre) => ({
      ...pre,
      systemID: "",
      DepartmentID: "",
      CriticalType: "",
      EquipmentID: "",
      DescriptionName: "",
    }));
    setOnSelect("");
  };

  useEffect(() => {

    // if (model != null) {
    //   setValueList((pre) => ({
    //     ...pre,
    //     // systemID: model.systemID,
    //     // DepartmentID: model.DepartmentID,
    //     CriticalType: model.CriticalType,
    //     EquipmentID: model.EquipmentID,
    //     DescriptionID: model.DescriptionID,
    //     DescriptionName: model.DescriptionName,
    //     BMPredefineFailuresID: model.BMPredefineFailuresID,
    //   }));

    //   if (treeData.length > 0 && model.systemID) {
    //     let obj = findDepartmentById(treeData, model.systemID);
    //     setOnSelect(obj.name);
    //   }
    // }
    if (DropdownModel.OverallList.length != 0) {
      let treeJsonData = BuildTreeUsingParentID(DropdownModel.OverallList);
      let Data = JSON.parse(JSON.stringify(treeJsonData, null, 2));
      console.log(Data);
      // Data.forEach((ele) => ele.isOpen = true)
      settreeData(Data);
    }
  }, [DropdownModel]);
  useEffect(() => {

    if (model != null) {
      setValueList((pre) => ({
        ...pre,
        systemID: model.systemID,
        DepartmentID: model.DepartmentID,
        CriticalType: model.CriticalType,
        EquipmentID: model.EquipmentID,
        DescriptionID: model.DescriptionID,
        DescriptionName: model.DescriptionName,
        BMPredefineFailuresID: model.BMPredefineFailuresID,
      }));

      if (treeData.length > 0 && model.systemID) {
        let obj = findDepartmentById(treeData, model.systemID);
        setOnSelect(obj.name);
      }
    }
  }, [Render]);
  closepopup = () => {
    setValueList((pre) => ({
      systemID: "",
      DepartmentID: "",
      CriticalType: "",
      EquipmentID: "",
      DescriptionName: "",
      DescriptionID: "0",
    }));
  };
  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    setValueList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const setSelectedDepartment = (id = 0) => {
    console.log(id);

    let obj = findDepartmentById(treeData, id);
    if (!Array.isArray(obj.children)) {
      if (obj) {
        setOnSelect(obj.name);
        setValueList((pre) => ({
          ...pre,
          systemID: id,
        }));

        const { FilterEquipmentList } = DropdownModel;
        if (FilterEquipmentList?.length) {

          let EquipmentList = FilterEquipmentList.filter(
            (ele) => ele.DepartmentID == id
          );
          setDropdownModel((pre) => ({
            ...pre,
            EquipmentList: EquipmentList,
          }));
        }
      }
    }
  };
  // function findDepartmentById(departmentList, idToFind) {
  //   for (const department of departmentList) {
  //     if (department.id === idToFind) {
  //       return department;
  //     }
  //     if (department.children && department.children.length > 0) {
  //       const foundInChild = findDepartmentById(department.children, idToFind);
  //       if (foundInChild) {
  //         return foundInChild;
  //       }
  //     }
  //   }
  //   return null; // Return null if department with the specified ID is not found
  // }
  const AddOrUpdatePredefinedFauilers = async (e) => {

    e.preventDefault();

    let model = valuelist;
    let modelErrors = errors;
    let flag = true;

    const { systemID, CriticalType, EquipmentID, DescriptionName } = model;

    if (systemID != null && systemID != "") {
      modelErrors.systemID = "";
    } else {
      modelErrors.systemID = "Please select Department";
      flag = false;
    }
    if (EquipmentID != null && EquipmentID != "") {
      modelErrors.EquipmentID = "";
    } else {
      modelErrors.EquipmentID = "Please select Equipment";
      flag = false;
    }
    if (CriticalType != null && CriticalType != "") {
      modelErrors.CriticalType = "";
    } else {
      modelErrors.CriticalType = "Please select Crictical Type";
      flag = false;
    }
    if (DescriptionName != null && DescriptionName != "") {
      modelErrors.Descname = "";
    } else {
      modelErrors.Descname = "Please enter Failure Description";
      flag = false;
    }
    setErrors({ ...modelErrors });
    if (flag) {
      model.SavedBy = GetLoginUserID();
      model.SavedDate = getYMDdate(new Date());

      PrefinedFails_AddOrUpdate(model)
        .then((res) => {
          showSaveSuccess();
          handleRefresh();
          clearList();
          $(".close").click();
        })
        .catch((err) => {
          console.log(err);
          setValueList((pre) => ({
            ...pre,
            systemID: "",
            DepartmentID: "",
            CriticalType: "",
            EquipmentID: "",
            DescriptionName: "",
          }));
        });
    }
  };
  $('#PrefinedFailuresClose').click(function () {
    setOnSelect("");
  });
  return (
    <div>
      <form id="addPrefilledFailures">
        <div className="row">
          <div className="col-md-3">
            <div className="row"></div>
            <div className="col-md-12">
              <ReactTreeView
                openByDefault={false}
                data={treeData}
                width={230}
                IsMultiselect={false}
                onSelect={setSelectedDepartment}
              ></ReactTreeView>
              {OnSelect !== "" && (
                <p>
                  Selected Department:{" "}
                  <span
                    style={{
                      color: "blue",
                      fontFamily: "Arial",
                      fontWeight: "bold",
                    }}
                  >
                    {OnSelect}
                  </span>
                </p>
              )}
              <span className="text-danger"> {errors.systemID} </span>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              {/*  */}
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Equipment<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={DropdownModel.EquipmentList}
                    placeholder="Select"
                    name={"EquipmentID"}
                    HandleChange={CommonHandleChange}
                    value={valuelist.EquipmentID}
                    className=""
                  />
                  <span className="text-danger"> {errors.EquipmentID} </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Criticality Level<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={DropdownModel.CritLevel}
                    placeholder="Select"
                    name={"CriticalType"}
                    HandleChange={CommonHandleChange}
                    value={valuelist.CriticalType}
                    className=""
                  />
                  <span className="text-danger"> {errors.CriticalType} </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Failure Description
                    <span className="text-danger"> *</span>
                    {/* <span className="text-danger"> *</span>{" "} */}
                  </label>
                  <textarea
                    className="form-control PeriodicClearClass"
                    style={{ resize: "none" }}
                    type="text"
                    name="DescriptionName"
                    value={valuelist.DescriptionName}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                  <span className="text-danger"> {errors.Descname} </span>
                </div>
              </div>
              <div className="col-md-12 d-flex align-items-end justify-content-end">
                <button
                  type="submit"
                  className="btn add-btn mb-2"
                  id="timeconfigbutton"
                  onClick={AddOrUpdatePredefinedFauilers}
                // data-bs-toggle="modal"
                // data-bs-target="#addbutton"
                >
                  <i className="fa fa-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddPrefiledFilures;

