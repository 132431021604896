import { showWarningMsg } from "../ToastMsg";

// Function to convert degrees to radians
function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

// Function to calculate distance between two lat/lon points using Haversine formula
function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // Radius of the Earth in meters
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
}

// Function to check if current location is within range
export function checkCurrentLocationWithinRange(
  currentLat,
  currentLon,
  originalLat,
  originalLon,
  offsetLat,
  offsetLon,
  IsStation = false,
  range
) {
  // Check if Geolocation is supported

  // Calculate distance between the original point and the current location
  if (IsStation) {
    const distance = haversineDistance(
      originalLat,
      originalLon,
      currentLat,
      currentLon
    );
    if (distance <= range) {
      return true;
    }
  } else {
    return checkCurrentLocationForSection(
      originalLat,
      offsetLat,
      originalLon,
      offsetLon,
      currentLat,
      currentLon
    );
  }
}

function checkCurrentLocationForSection(
  lat1,
  lat2,
  lon1,
  lon2,
  currentLat,
  currentLon
) {
  // Check if Geolocation is supported

  // Determine the bounds
  const minLat = Math.min(lat1, lat2);
  const maxLat = Math.max(lat1, lat2);
  const minLon = Math.min(lon1, lon2);
  const maxLon = Math.max(lon1, lon2);
  if (
    currentLat >= minLat &&
    currentLat <= maxLat &&
    currentLon >= minLon &&
    currentLon <= maxLon
  ) {
    return true;
  }
}
