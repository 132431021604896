import React from "react";
import { createContext, useState, useContext } from "react";

export const CyclicCheckApproverContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => {},
  DropDownInitialState: {},
});

function CyclicCheckApproverProvider({ children }) {
  const DropDownInitialState = {
    DepartmentList: [],
    YearList: [],
  };

  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
  };

  return (
    <CyclicCheckApproverContext.Provider value={contextValue}>
      {children}
    </CyclicCheckApproverContext.Provider>
  );
}

const GetCyclicContextApproverValues = () => useContext(CyclicCheckApproverContext);

export { CyclicCheckApproverProvider,GetCyclicContextApproverValues };
