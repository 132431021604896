import { configureStore } from "@reduxjs/toolkit";
import { PeriodicMaintenanceSlice } from "../ReduxSlice/PeriodicMaintenanceSlice";
import { PM_AllApiFunction } from "../screens/PeriodicMaintenance/PeriodicReduxApiService";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [PeriodicMaintenanceSlice.reducerPath]: PeriodicMaintenanceSlice.reducer,
    [PM_AllApiFunction.reducerPath]: PM_AllApiFunction.reducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(PM_AllApiFunction.middleware),
});



setupListeners(store.dispatch)
