import React, { useEffect } from "react";
import { StationLocationContextValues } from "./StationLocationContext";
import {
  BootrapModel,
  HtmlTable,
  ReactMultiSelect,
  showErrorMsg,
  showSaveSuccess,
  showWarningMsg,
} from "../../components/index";
import { DefaultAppContextValues } from "../../services/ContextProvider";
import { FaRegSave } from "react-icons/fa";
import {
  DeepCopy,
  GetLoginUserID,
  getPrivilege,
  validateModel,
} from "../../services/CommonService";
import {
  StationLocationGetList,
  StationLocationSaveConfig,
} from "./StationLocationService";
import $ from "jquery";
import swal from "sweetalert";

function StationLocationConfiguration() {
  const { GetLoginStationList, showLoading, hideLoading } =
    DefaultAppContextValues();
  const {
    setDropdownModel,
    ConfigList,
    setConfigList,
    setConfigModel,
    DropdownModel,
    InitialState,
  } = StationLocationContextValues();

  const { StationList } = DropdownModel;

  useEffect(() => {
    const StationList = GetLoginStationList();
    if (StationList?.length) {
      setDropdownModel((pre) => ({
        ...pre,
        StationList,
      }));
    }
  }, []);

  useEffect(() => {
    getConfigListByID();
    return () => {
      setConfigList([]);
    };
  }, []);

  const Columns = [
    {
      data: "StationLocationID",
      visible : false,
      className : "hide"
    },
    {
      data: "StationCode",
      title: "Station Code",
    },
    {
      data: "GeoLocationName",
      title: "Geo Location Name",
    },
    {
      data: "Latitude",
      title: "Latitude",
    },
    {
      data: "Longitude",
      title: "Longitude",
    },
    {
      data: "OffsetLatitude",
      title: "OffsetLatitude",
    },
    {
      data: "OffsetLongitude",
      title: "OffsetLongitude",
    },
    {
      data: "Range",
      title: "Range",
    },
    {
      data: "StationLocationID",
      title: "Submit",
      createdCell: (td, cellData, rowData, row, col) => {
        var EditBtn = "",
          deleteBtn = "";

        if (getPrivilege().includes("PERIODICCONFIGURATIONEDIT")) {
          EditBtn = $(`<button class='btn icon-btn text-center' title='Edit'
          data-bs-toggle="modal"
          data-bs-target="#StationLocationConfiguration"
          >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
            getConfigListByID(rowData.StationLocationID);
          });
        }
        if (getPrivilege().includes("PERIODICCONFIGURATIONDELETE")) {
          deleteBtn = $(`<button class='btn icon-btn text-center' 
          title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
              </button>`).on("click", function () {
            Swal(rowData.StationLocationID);
          });
        }

        $(td).html(EditBtn);
        $(td).append(deleteBtn);
      },
    },
  ];

  const getConfigListByID = async (StationLocationID = 0, IsDeleted = 0) => {
    showLoading();
    await StationLocationGetList(StationLocationID, IsDeleted, GetLoginUserID())
      .then((res) => {
        hideLoading();
        if (res?.data?.data?.length) {
          

          if (StationLocationID == 0 || IsDeleted == 1) setConfigList(res.data.data);
          else {
            if (StationList?.length) {
              const Obj = res.data.data[0];
              let Station = StationList.find(
                (ele) => ele.value == Obj.StationID
              );
              Obj.StationID = Station;
              setConfigModel(Obj);
            }
          }
        } else {
          setConfigList([]);
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };

  function Swal(StationLocationID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this configuration?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await getConfigListByID(StationLocationID, 1);
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <div class="page-header mb-3">
            <div class="row justify-content-between">
              <h3 class="page-title">Station Location Configuration</h3>
            </div>
          </div>
        </div>
        <div className="col-4">
          <button
            className="btn btn-primary float-end"
            data-bs-toggle="modal"
            data-bs-target="#StationLocationConfiguration"
            onClick={() => {
              setConfigModel(InitialState);
            }}
          >
            <i className="fa fa-plus"></i> Add{" "}
          </button>
        </div>
        <BootrapModel
          ModelTitle={"Station Location Configuration"}
          Id={"StationLocationConfiguration"}
          CloseID={"CloseStationLocationConfiguration"}
          closepopup={() => {
            getConfigListByID();
            setDropdownModel((pre) => ({
              ...pre,
              ErrorObj: {},
            }));
          }}
          module={<StationConfigSave />}
        ></BootrapModel>

        <HtmlTable
          columnList={Columns}
          IsASC
          rowList={ConfigList ?? []}
          id={"StationLocationConfigurationTable"}
        />
      </div>
    </>
  );
}

const StationConfigSave = () => {
  const {
    DropdownModel,
    ConfigModel,
    setConfigModel,
    setDropdownModel,
    ConfigList,
  } = StationLocationContextValues();
  const { ErrorObj } = DropdownModel;
  const CommonHandleChange = (event, name) => {
    if (name) {
      setConfigModel((pre) => ({
        ...pre,
        [name]: event,
      }));
    } else {
      const { name, value } = event.target;
      setConfigModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  const HandleSave = async () => {
    let fieldNames = {
      StationID: "Station",
      GeoLocationName: "Geo Location Name",
      Latitude: "Latitude",
      Longitude: "Longitude",
      OffsetLatitude: "Offset Latitude",
      OffsetLongitude: "Offset Longitude",
      Range: "Range",
    };
    if ((ConfigModel?.StationID?.IsSection == 0)) {
      const { StationID, GeoLocationName, Latitude,Longitude,Range} = fieldNames;
      fieldNames = { StationID, GeoLocationName, Latitude, Longitude ,Range};
    }else{
      const { StationID, GeoLocationName, Latitude,Longitude,OffsetLatitude,OffsetLongitude} = fieldNames;
      fieldNames = { StationID, GeoLocationName, Latitude, Longitude,OffsetLatitude,OffsetLongitude};
    }

    let Error = validateModel(ConfigModel, fieldNames);
    if (!Error) {
      setDropdownModel((pre) => ({
        ...pre,
        ErrorObj: Error,
      }));
      const Obj = DeepCopy(ConfigModel);
      Obj.StationID = Obj.StationID?.value;
      
      let CheckAlreadyExists = ConfigList.find((ele) => {
        return (
          ele.StationLocationID != Obj.StationLocationID &&
          ele.StationID == Obj.StationID
        );
      });
      if (CheckAlreadyExists) {
        showWarningMsg("This Station configured already.");
        return false;
      }

      await StationLocationSaveConfig(Obj)
        .then((res) => {
          $("#CloseStationLocationConfiguration").click();
          if (res?.data?.isSuccess) {
            showSaveSuccess();
          } else {
            showErrorMsg();
          }
        })
        .catch((err) => {
          showErrorMsg();
          $("#CloseStationLocationConfiguration").click();
          console.log(err);
        });
    } else {
      setDropdownModel((pre) => ({
        ...pre,
        ErrorObj: Error,
      }));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label">
              Station <span className="text-danger"> *</span>{" "}
            </label>
            <ReactMultiSelect
              options={DropdownModel?.StationList}
              placeholder={"Select Station"}
              onChange={(value) => CommonHandleChange(value, "StationID")}
              value={ConfigModel.StationID}
            />
            <span className="text-danger mt-1"> {ErrorObj?.StationID} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Geo Location Name <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="text"
              name="GeoLocationName"
              value={ConfigModel?.GeoLocationName}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.GeoLocationName} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Latitude <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="text"
              name="Latitude"
              value={ConfigModel?.Latitude}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.Latitude} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Longitude <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="text"
              name="Longitude"
              value={ConfigModel?.Longitude}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.Longitude} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Offset Latitude <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="text"
              name="OffsetLatitude"
              disabled={ConfigModel?.StationID?.IsSection == 0}
              value={ConfigModel?.OffsetLatitude}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.OffsetLatitude} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Offset Longitude <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="text"
              name="OffsetLongitude"
              disabled={ConfigModel?.StationID?.IsSection == 0}
              value={ConfigModel?.OffsetLongitude}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.OffsetLongitude} </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Range (M) <span className="text-danger"> *</span>{" "}
            </label>
            <input
              className="form-control "
              type="number"
              name="Range"
              value={ConfigModel?.Range}
              disabled={ConfigModel?.StationID?.IsSection == 1}
              onChange={CommonHandleChange}
            />
            <span className="text-danger"> {ErrorObj?.Range} </span>
          </div>
        </div>
      </div>
      <button className="btn btn-primary float-end" onClick={HandleSave}>
        <FaRegSave /> Save
      </button>
    </>
  );
};

export default StationLocationConfiguration;
