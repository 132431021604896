import { BASE_URL } from "../env";
const signalR = require("@microsoft/signalr");


const URL = BASE_URL.replace("/api/",'')
console.log(`${URL}/notificationHub`)


const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${URL}/notificationHub`)
  .build();

const startConnection = async () => {
  if (connection.state === signalR.HubConnectionState.Disconnected) {
    try {
      await connection.start();
      console.log("SignalR Connected");
    } catch (err) {
      console.error("SignalR Connection Error: ", err);
    }
  }
};

export const InvokeCall = async () => {
  try {
    await startConnection();
    await connection.invoke("SendNotification").
    then(() => console.log("Message sent successfully"))
    .catch((err) => console.log("Message Error :",err));
    
  } catch (err) {
    console.error("Error sending message: ", err);
  }
};

export const getNotification = async () => {
    await startConnection();
    return connection;
};
