import React from "react";

export const WithoutSelectDropDown = ({
  data,
  name,
  value,
  reg,
  disabled,
  HandleChange,
  placeholder
}) => {
  return (


    
    <select
      className="form-select"
      name={name}
      value={value}
      ref={reg}
      id={name}
      disabled={disabled}
      onChange={HandleChange}
      placeholder={placeholder}
    >
      {data != null && data.length > 0 ? (
        data.map((v, i) => {
          return (
            <option key={i} value={v.value}>
              {v.key}
            </option>
          );
        })
      ) : (
        <></>
      )}
    </select>

  );
};