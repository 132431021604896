import React from "react";
import { CustomOrderList, ReactTimeline } from "../../components";

function MaintenanceChecksCardView({Headings = [], StatusCount = []}) {
  const customContent = () => {
    let check = StatusCount.map((ele) => {
      return (
        <CustomOrderList > </CustomOrderList>
      );
    });
    return check;
  };

  return (
    <ReactTimeline
      Headings={Headings}
      customContent={customContent()}
    ></ReactTimeline>
  );
}

export default MaintenanceChecksCardView;
