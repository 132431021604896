import React from "react";
import { Chrono } from "react-chrono";
import StyledCard from "./StyledCard";

export const ReactTimeline = ({ Headings, customContent }) => {
  return (
    <div id="ReactTimeLine">
      {" "}
      <Chrono
        items={Headings}
        mode="VERTICAL_ALTERNATING"
        // scrollable = {false}
        cardHeight={100}
        disableClickOnCircle
        flipLayout
        hideControls
        classNames = {["overflow-hidden"]}
        //   enableOutline

        // cardLess
      >
        {customContent}
      </Chrono>
    </div>
  );
};
