import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useReducer,
} from "react";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import {
  ReactTreeView,
  ReactAutoComplete,
  Dropdown,
  showSuccessMsg,
  showErrorMsg,
} from "../../components";
import { default as ReactSelect } from "react-select";
import BreakdownMaintenanceService, { BreakdownDepartment_GetDropdownDataUsingID } from "./BreakdownMaintenanceService";
import $ from "jquery";
import { AppContext } from "../../services/ContextProvider";
import { ManualSpareList } from "./ManualSpareList";
import makeAnimated from "react-select/animated";
import {
  getUserDetails,
  findDepartmentById,
  GetLoginUserID,
  BuildTreeUsingParentID,
  GenerateYearForReactSelect,
  makeUnique,
  getL0RoleID,
} from "../../services/CommonService";
import Global, { COOKIES } from "../../services/Global";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { MasterConfig_GetDepartmentList } from "../master/MasterDetailService";
import CameraComponent from "../../components/OpenCamera/OpenCamera";
import { WebCam } from "../../components";
import { Breakdown_GetPredefineDWN } from "./BreakdownMaintenanceService";


const obj = new BreakdownMaintenanceService();
export const SaveDepManualBreakdownMaindence = ({
  IsShow,
  TicketDetailes,
  StatusDropDown,
  AssignByDropDown,
  EmployeeDropDown,
  EqiupmentTagList,
  ParameterList,
  RefreshforForm,
  tableRefresh,
  SelectedSpareList,
  PartDropDown,
  Sparelist,
  FilterSpareList,
  EquipmentCyclicList,
  EquipmentCyclicIDS,
}) => {
  // const initialModel = {
  //   C
  // }

  const animatedComponents = makeAnimated();

  const [TicketModel, setTicketModel] = useState({});
  const [FilterParameterList, setFilterParameterList] = useState([]);
  const { getCookies, setCookies, showLoading, hideLoading } =
    useContext(AppContext);
  const [EquipmentTagNo, setEquipmentTagNo] = useState({});
  const [EquipmentCyclicID, setEquipmentCyclicIDS] = useState([
    EquipmentCyclicIDS,
  ]);
  const [ParameterID, setParameterID] = useState("");
  const [errors, seterrors] = useState({});
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [ModelRefresh, setModelRefresh] = useReducer((x) => x + 1, 0);
  const [SelectedSpareListForManual, SetSelectedSpareListForManual] = useState(
    []
  );
  const [priviledge, setpriviledge] = useState("");
  const [Equiplist, setEquiplist] = useState([]);
  // const [AssignToDropDown, setAssignToDropDown] = useState([]);
  const [ReportDrop, setReportDrop] = useState([]);
  const [StationSpareList, setStationSpareList] = useState([]);
  const [StationPartList, setStationPartList] = useState([]);
  const [isChildFocused, setIsChildFocused] = useState(false);
  const [DropdownModel, setDropdownModel] = useState([]);
  const [CorridorList, setCorridorList] = useState([]);
  const [AllStationList, setAllStationList] = useState([]);
  const [BreakDownTypeList, setBreakDownTypeList] = useState([]);
  const [StationCatgoryList, setStationCatgoryList] = useState([]);
  const [AssignedToList, setAssignedToList] = useState([]);
  const [RequesterDetails, SetRequesterDetails] = useState([]);
  const [PrefinedFailuresList, SetPrefinedFailuresList] = useState([]);
  const [DepartmentID, SetDepartmentID] = useState([]);

  const [FilterObj,setFilterObj] = useState({
    SpareList : [],
    PartList : []
  })

  useEffect(() => {
    setTicketModel({});
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";
    setpriviledge(ModulePrivileges);
  }, []);

  useEffect(() => {

    setEquipmentCyclicIDS(EquipmentCyclicIDS);

    GetPredefineDWN();
  }, [TicketModel.BreakDownTypeID, TicketModel.DepartmentID, EquipmentTagNo]);

  useEffect(() => {
    showLoading();
    seterrors({});
    GetDepartmentList();

    $(".BreakdownManual").val(" ");
    $(".BreakdownManualDrop").val("0");
    document.getElementById("FormFile").value = null;

    setModelRefresh();

    // FilterSpareList.map((x) => {
    //   return (x.key = x.SpareName), (x.value = x.SpareID);
    // });

    // let filStationSpareList = FilterSpareList.filter(
    //   (x) => x.StationID == TicketDetailes.StationID
    // );

   // setStationSpareList(filStationSpareList ?? []);

   // setAssignToDropDown(EmployeeDropDown);
    // setReportDrop(ReportToDropdown);

    // PartDropDown.map((x) => {
    //   return (x.key = x.SparePartNo), (x.value = x.SpareDetailID);
    //   // temp1.push({ key: x.SparePartNo, value: x.SpareDetailID, ...x });
    // });
    // let filStationPartDropDown = PartDropDown.filter(
    //   (x) => x.StationID == TicketDetailes.StationID
    // );
    // setStationPartList(filStationPartDropDown ?? []);
    
    if (TicketDetailes.TicketID > 0) {
      // const EqiupmentTag = EqiupmentTagList.find((x) => {
      //   return x.Value == TicketDetailes.EquipmentDetailID;
      // });
    
      const AsyncFunction =async () => {
        await getEquipmentList(StationID,DepartmentID,EquipmentDetailID,false)
      }
      const { StationID ,DepartmentID,EquipmentDetailID,ISCyclicCheck }= TicketDetailes;
      
      if(ISCyclicCheck != 1)
        AsyncFunction() 

      
      

      // const Parameter = ParameterList.find((x) => {
      //   return x.id == TicketDetailes.ParameterID;
      // });

      // setParameterID(Parameter?.label ?? "");


      if (
        getUserDetails().PrefixName === "L0" &&
        TicketDetailes.initialStatusID == 28 &&
        TicketModel.ShowReportBtn == 0
      ) {
        TicketDetailes.TicketStatusID = 26;
      }

      if (
        TicketDetailes.TicketStatusID == 28 &&
        TicketDetailes.PrefixName == TicketDetailes.PrefixName
      ) {
        TicketDetailes.TicketStatusID = 26;
      }
      if (
        (TicketDetailes.TicketStatusID == 25 ||
          TicketDetailes.TicketStatusID == 101 ||
          TicketDetailes.TicketStatusID == 30) &&
        getUserDetails().PrefixName == "L0"
      ) {
        TicketDetailes.TicketStatusID = 26;
      }
      if (
        TicketDetailes.TicketStatusID == 98 &&
        getUserDetails().PrefixName == "L2"
      ) {
        TicketDetailes.TicketStatusID = 29;
      }
      if (
        (TicketDetailes.TicketStatusID == 105 ||
          TicketDetailes.TicketStatusID == 31 ||
          TicketDetailes.TicketStatusID == 101 ||
          TicketDetailes.TicketStatusID == 102 ||
          TicketDetailes.TicketStatusID == 103) &&
        getUserDetails().PrefixName !== "L0"
      ) {
        TicketDetailes.TicketStatusID = 30;
      }
      if (
        TicketDetailes.TicketStatusID == 26 &&
        getUserDetails().PrefixName == "L1"
      ) {
        TicketDetailes.TicketStatusID = 29;
      }
      if (
        TicketDetailes.TicketStatusID == 99 &&
        getUserDetails().PrefixName == "L3"
      ) {
        TicketDetailes.TicketStatusID = 29;
      }
      if (
        TicketDetailes.TicketStatusID == 100 &&
        getUserDetails().PrefixName == "L4"
      ) {
        TicketDetailes.TicketStatusID = 29;
      }
      setTicketModel({ ...TicketDetailes });
      const Parameter = ParameterList.find((x) => {
        return x.id == TicketDetailes.ParameterID;
      });
      debugger

      setParameterID(Parameter?.label ?? "");
      setTicketModel((prevState) => ({
        ...prevState,
        ParameterUnit: Parameter?.UOM ?? "",
      }));
    } else {
      setTicketModel({ ...TicketDetailes });
      setEquipmentTagNo({});
      setParameterID("");
      onchangeUOMClearName();
      $(".TicketStatus").val("25");
      setTicketModel((prevState) => ({
        ...prevState,
        ["TicketStatusID"]: 25,
        ["statusName"]: "Open",
        ["AssignedBy"]: GetLoginUserID(),
        ["ReportTo"]:
          getUserDetails().PrefixName !== COOKIES.TechRoleID
            ? GetLoginUserID()
            : "0",
      }));
    }
    GetCorridorList();
    return () => {
      setTicketModel(null);
    };

  }, [JSON.stringify(TicketDetailes), RefreshforForm]);

  useEffect(() => {
    tableRefresh(Refresh);
  }, [Refresh]);

  const GetAssignedToList = async (DepartmentID, stationID) => {
    var employeeID = GetLoginUserID();
    obj
      .Breakdown_GetAssignedToList(employeeID, DepartmentID, stationID)
      .then((res) => {
        if (
          res != null &&
          res?.data?.assignedToList != null &&
          res?.data?.assignedToList.length > 0
        ) {
          setAssignedToList([...res?.data?.assignedToList]);
        } else {
          setAssignedToList([]);
        }
        if (
          res != null &&
          res?.data?.reportToList != null &&
          res?.data?.reportToList.length > 0
        ) {
          //   setReportDrop(Report_drop);
          setReportDrop([...res?.data?.reportToList]);
        } else {
          setAssignedToList([]);
        }
      })
      .catch((err) => {
        setRefresh();
        console.log(err);
      });
  };
  const GetCorridorList = async () => {
    showLoading();
    var employeeID = GetLoginUserID();
    const ticketID = Number(TicketDetailes?.TicketID);

    // Check if ticketID is NaN
    const sanitizedTicketID = isNaN(ticketID) ? 0 : ticketID; // Change 0 to any default value you want

    obj
      .Breakdown_GetCorridorList(employeeID, sanitizedTicketID)
      .then((res) => {
        const {
          prefinderList,
          corridorList,
          stationList,
          breakDownList,
          reportToList,
          assignedList,
          requesterDetails,
        } = res?.data;

        if (res != null && corridorList != null && corridorList.length > 0) {
          setCorridorList([...corridorList]);
        } else {
          setCorridorList([]);
        }
        if (res != null && prefinderList != null && prefinderList.length > 0) {
          SetPrefinedFailuresList([...prefinderList]);
        } else {
          SetPrefinedFailuresList([]);
        }
        if (res != null && stationList != null && stationList.length > 0) {
          setAllStationList([...stationList]);
        } else {
          setAllStationList([]);
        }

        // setStationCatgoryList

        if (TicketDetailes?.CorridorID != 0) {
          var dataLsts = [];

          stationList.map((data) => {
            if (data.CorridorId == Number(TicketDetailes?.CorridorID))
              dataLsts.push({ value: data.StationID, key: data.StationCode });
          });
          dataLsts = makeUnique(dataLsts, ["value", "key"]);
          setStationCatgoryList([...dataLsts]);

          setTicketModel((prevState) => ({
            ...prevState,
            StationID: TicketDetailes.StationID,
          }));
        }

        if (res != null && breakDownList != null && breakDownList.length > 0) {
          setBreakDownTypeList([...breakDownList]);
        } else {
          setAllStationList([]);
        }
        if (res != null && reportToList != null && reportToList.length > 0) {
          setReportDrop([...reportToList]);
        } else {
          setReportDrop([]);
        }
        if (res != null && assignedList != null && assignedList.length > 0) {
          setAssignedToList([...assignedList]);
        } else {
          setAssignedToList([]);
        }

        if (
          res != null &&
          requesterDetails != null &&
          requesterDetails.length > 0
        ) {
          SetRequesterDetails([...requesterDetails]);
          if (!TicketDetailes?.TicketID) {
            setTicketModel((prevState) => ({
              ...prevState,
              // DepartmentName: requesterDetails[0].DepartmentName,
              // DepartmentID: requesterDetails[0].RequesterDepartmentID,
              DepartmentIDs: requesterDetails[0].DepartmentIDS,
            }));
          } else {
            setTicketModel((prevState) => ({
              ...prevState,
              DepartmentIDs: requesterDetails[0].DepartmentIDS,
            }));
          }
        } else {
          SetRequesterDetails([]);
        }

        // if (TicketModel.StationID !== 0 && TicketModel.StationID != "") {
        //   var staID = TicketModel.StationID;
        //   getStationList(TicketModel.CorridorID);

        //   setTicketModel((PreviousState) => ({
        //     ...PreviousState,
        //     StationID: staID,
        //   }));
        // }
        hideLoading();
      })
      .catch((err) => {
        setRefresh();
        console.log(err);
        hideLoading();
      });
  };

  const onSelect = (e = 0, name = "") => {
    if (TicketModel.TicketID == undefined ||
      TicketModel.TicketID == 0) {
      let obj = findDepartmentById(DropdownModel?.DepartmentList, e);
      if (!Array.isArray(obj.children)) {
        GetAssignedToList(obj.id, 0);
        SetDepartmentID(obj.id);
        setTicketModel((PreviousState) => ({
          ...PreviousState,
          DepartmentID: obj.id,
          DepartmentName: obj.name,
        }));
        if (TicketModel.StationID !== 0 && TicketModel.StationID != "") {
          getEquipmentList(TicketModel.StationID,obj.id);

        }

        FilterDepartment("DepartmentID",obj.id);
        // if (TicketModel.CorridorID != undefined) {
        //   getStationList(TicketModel.CorridorID, obj.name);
        // }
      }
    }
  };

  const getStationList = (id, val) => {
    let DDepartmentName = TicketModel.DepartmentName;
    var dataLst = [];
    if (val) {
      DDepartmentName = val;
    }
    AllStationList.map((data) => {
      if ((DDepartmentName == "FOCS" || DDepartmentName == "OHE") && id == 1) {
        if (data.CorridorId == Number(id) && data.StationCode == "SAP-SME") {
          dataLst.push({ value: data.StationID, key: data.StationCode });
        }
        // else if (data.CorridorId == Number(id)) {
        //   dataLst.push({ value: data.StationID, key: data.StationCode });
        // }
      } else {
        if (data.CorridorId == Number(id) && data.StationCode != "SAP-SME") {
          dataLst.push({ value: data.StationID, key: data.StationCode });
        }
      }
    });

    dataLst = makeUnique(dataLst, ["value", "key"]);
    setStationCatgoryList([...dataLst]);

    setTicketModel((prevState) => ({
      ...prevState,
      StationID: 0,
    }));

    // setStationList(dataLst);
    // SetStationID(dataLst[0].value);

    // handleGlobalStationName(dataLst[0].key);
  };

  const GetPredefineDWN = async () => {
    if (
      TicketModel.DepartmentID &&
      TicketModel.BreakDownTypeID &&
      EquipmentTagNo.EquipmentID 
    )
      await Breakdown_GetPredefineDWN(
        EquipmentTagNo.EquipmentID,
        TicketModel.DepartmentID,
        TicketModel.BreakDownTypeID
      )
        .then((res) => {
          console.log(res);
          SetPrefinedFailuresList(res.data.data.Table ?? []);
        })
        .catch((err) => console.log(err));
  };
  const GetDepartmentList = async () => {
    await MasterConfig_GetDepartmentList()
      .then((res) => {
        if (res?.data?.data?.length) {
          let treeJSON = BuildTreeUsingParentID(res.data.data);
          let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
          Data.forEach((ele) => (ele.isOpen = true));
          let YearList = GenerateYearForReactSelect();
          setDropdownModel((pre) => ({
            ...pre,
            DepartmentList: Data,
            YearList: YearList,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  // const handleCheckboxChange = (event) => {
  //   
  //   setTicketModel((prevState) => ({
  //     ...prevState,

  //     IsunderBreakdown: event.target.checked,
  //   }));

  //   // onChange && onChange(event.target.checked); // Optional callback for parent handling
  // };

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    if (name == "AssignedTo") {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
        TicketStatusID: 28,
        AssignedBy: GetLoginUserID(),
      }));
    } else if (name == "IsunderBreakdown") {
      setTicketModel((prevState) => ({
        ...prevState,

        IsunderBreakdown: e.target.checked,
      }));
    } else if (name == "StationID") {

      GetAssignedToList(DepartmentID, value);
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      getEquipmentList(value,TicketModel.DepartmentID);

      // const Equip = EqiupmentTagList.filter(
      //   (x) =>
      //     x.StationID == value && x.DepartmentID == TicketModel.DepartmentID
      // );
      // setEquiplist([...Equip]);
      // setEquipmentTagNo({});

      // let filStationSpareList = FilterSpareList.filter(
      //   (x) =>
      //     x.StationID == value && x.EquipmentID == EquipmentTagNo.EquipmentID
      // );

    //  setStationSpareList(filStationSpareList ?? []);



      // let filStationPartDropDown = PartDropDown.filter(
      //   (x) =>
      //     x.StationID == value && x.DepartmentID == TicketModel.DepartmentID
      // );
    //  setStationPartList(filStationPartDropDown ?? []);

      // const Assigned_By_DropDown = AssignByDropDown.filter((x) => {
      //   return (
      //     x.stationID === Number(value) && x.roleID == Global.COOKIES.TechRoleID
      //   );
      // });

     // setAssignToDropDown(Assigned_By_DropDown);

      // const Report_drop = AssignByDropDown.filter((x) => {
      //   return x.stationID === Number(value);
      // });

      // setReportDrop(Report_drop);



      //   setReportDrop(Report_drop);
    } else if (name == "CorridorID") {
      GetAssignedToList(DepartmentID, 0);
      //  getStationList(value);
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      FilterDepartment(name,value);
    } else {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onchangeParameterName = (e) => {
    setIsChildFocused(true);
    let paraName = e;
    //  paraName?.UOM
    setTicketModel((prevState) => ({
      ...prevState,
      ParameterUnit: paraName?.UOM,
    }));
  };
  const onchangeUOMClearName = () => {
    setIsChildFocused(false);
    setTicketModel((prevState) => ({
      ...prevState,
      ParameterUnit: "",
    }));
  };
  const EquipmentChange = (e,ParameterClear = true) => {
    if (e) {
      const { EquipmentID, SystemTypeID } = e;
      debugger
      setEquipmentTagNo(e);
      if(ParameterClear){
      setParameterID("");
      onchangeUOMClearName();
      }
    
      if (ParameterList != null) {
        const FilterList = ParameterList.filter((x) => {
          return x.EquipmentID == EquipmentID;
        });

        setFilterParameterList([...FilterList]);
      }

      if(FilterObj?.SpareList?.length){
        let obj = FilterObj?.SpareList.filter((ele) => ele.EquipmentTypeID == SystemTypeID)
        setStationSpareList(obj ?? [])
      }

      if(FilterObj?.PartList?.length){
        let obj = FilterObj?.PartList.filter((ele) => ele.EquipmentTypeID == SystemTypeID)
        setStationPartList(obj ?? [])
      }     
    }
  };
  const EquipmentCyclicIDChange = (e) => {
    if (e) {
      // const { EquipmentID, SystemTypeID } = e;

      let StationVal = e.map((x) => x.value).join();
      setEquipmentCyclicIDS(e);
      setTicketModel((prevState) => ({
        ...prevState,
        EquipmentIDS: StationVal,
      }));
    }
  };

  const getEquipmentList = async (StationID,DepartmentID,EquipmentDetailID,ParameterClear = true) => {
    if(StationID && DepartmentID){

    
    await BreakdownDepartment_GetDropdownDataUsingID(StationID,DepartmentID)
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList, spareList, partList,employeeList } = res.data.data;

          if (employeeList?.length) {
            
            setReportDrop(employeeList)

          } else {
            setReportDrop([]);
          }
          

          if(EquipmentDetailID){
            
           let EquipmentTag = equipmentList.find((ele) => ele.value == EquipmentDetailID)
           
            EquipmentChange(EquipmentTag,ParameterClear);
          }

          setFilterObj((pre) => ({
            ...pre,
            PartList : partList ?? [],
            SpareList : spareList ?? []
          }))

          


          // setStationSpareList(spareList);
          // setStationPartList(partList);

          setEquiplist([...equipmentList]);
          if(!EquipmentDetailID){
            setEquipmentTagNo({});
          }
        }
      })
      .catch((err) => {
        setReportDrop([]);
        setEquipmentTagNo({});
        setEquiplist([])
        console.log(err);
      });
    }
    else{
      setReportDrop([]);
      setEquipmentTagNo({});
      setEquiplist([])
    }
  };


  const FilterDepartment = (name,value) => {
    

      let CorridorID = 0;
      let DepartmentID = 0;
      if (name == "CorridorID") {
        CorridorID = value;
      } else {
        CorridorID = TicketModel.CorridorID;
      }
      if (name == "DepartmentID") {
        DepartmentID = value;
      } else {
        DepartmentID = TicketModel.DepartmentID;
      }
      
      let fineDept = findDepartmentById(DropdownModel?.DepartmentList,DepartmentID)
      
      let finedType = CorridorList?.find(
        (ele) => ele.value == CorridorID
      );
      if (fineDept && finedType) {
        let FilStations = AllStationList.filter(
          (ele) => ele.CorridorId == finedType.value && ele.Type == fineDept.Type
        );
        setStationCatgoryList([...FilStations] ?? []);
      }

  }

  function SaveDetailes(e) {
    e.preventDefault();
    

    const formData = new FormData();

    var TicketModels = TicketModel;

    const {
      Location,
      ProblemDescription,
      AssignedTo,
      AssignedBy,
      TicketStatusID,
      StationID,
      ReportToStrID,
      ShowReportBtn,
      DepartmentID,
      PTWNumber,
      PTWDate,
      BreakDownTypeID,
      GuidName,
      OrginalFile,
      CorridorID,
      IsunderBreakdown,
      FilePath,
      DepartmentIDs,
      ISCyclicCheck,
    } = TicketModels;

    var ErrorMgs = errors;

    let flag = true;
    if (Location != null && Location.trim() != "") {
      ErrorMgs.Location = "";
    } else {
      ErrorMgs.Location = "Please enter location";
      flag = false;
    }

    if (DepartmentID != null && DepartmentID != 0 && DepartmentID != "") {
      ErrorMgs.DepartmentID = "";

      if (
        DepartmentIDs != null &&
        DepartmentIDs != "" &&
        DepartmentIDs != undefined
      ) {
        const numbersArray = DepartmentIDs?.split(",").map(Number); // Split the string and convert each element to a number

        if (
          numbersArray.includes(DepartmentID) &&
          getUserDetails().PrefixName == "L0"
        ) {
          if (GuidName == null || GuidName == "" || GuidName == undefined) {
            ErrorMgs.GuidName = "Please take a photo";
            flag = false;
          } else {
            ErrorMgs.GuidName = "";
          }
        }
      }
    } else {
      ErrorMgs.DepartmentID = "Please select department";
      flag = false;
    }
    if (getUserDetails().PrefixName == "L0") {
      if (PTWNumber != null && PTWNumber.trim() != "") {
        ErrorMgs.PTWNumber = "";
      } else {
        ErrorMgs.PTWNumber = "Please enter PTW number";
        flag = false;
      }
      if (PTWDate != null && PTWDate.trim() != "") {
        ErrorMgs.PTWDate = "";
      } else {
        ErrorMgs.PTWDate = "Please enter PTW date";
        flag = false;
      }
    }

    if (ProblemDescription != null && ProblemDescription.trim() != "") {
      ErrorMgs.ProblemDescription = "";
    } else {
      ErrorMgs.ProblemDescription = "Please enter problem description";
      flag = false;
    }

    if (AssignedToList != null && AssignedToList.length < 0) {
      if (AssignedTo != null && AssignedTo != "") {
        ErrorMgs.AssignedTo = "";
      } else {
        ErrorMgs.AssignedTo = "Please enter assigned to";
        flag = false;
      }
    } else {
      ErrorMgs.AssignedTo = "";
      if (ShowReportBtn == 1) {
        if (
          ReportToStrID != null &&
          ReportToStrID != "" &&
          ReportToStrID != "0"
        ) {
          ErrorMgs.ReportToStrID = "";
        } else {
          ErrorMgs.ReportToStrID = "Please enter report to";
          flag = false;
        }
      }
      if (TicketStatusID == 25 && getUserDetails().PrefixName == "L1") {
        if (AssignedTo != null && AssignedTo != "") {
          ErrorMgs.AssignedTo = "";
        } else {
          ErrorMgs.AssignedTo = "Please enter assigned to";
          flag = false;
        }
      }
    }

    if (StationID != null && StationID != "") {
      ErrorMgs.StationID = "";
    } else {
      ErrorMgs.StationID = "Please enter station/section name";
      flag = false;
    }

    if (AssignedBy != null && AssignedBy != "") {
      ErrorMgs.AssignedBy = "";
    } else {
      ErrorMgs.AssignedBy = "Please enter assigned by";
      flag = false;
    }
    if (TicketStatusID != null && TicketStatusID != "") {
      ErrorMgs.TicketStatusID = "";
    } else {
      ErrorMgs.TicketStatusID = "Please select status";
      flag = false;
    }

    if (BreakDownTypeID != null && BreakDownTypeID > 0) {
      ErrorMgs.BreakDownTypeID = "";
    } else {
      ErrorMgs.BreakDownTypeID = "Please select break down type";
      flag = false;
    }

    if (CorridorID != null && CorridorID > 0) {
      ErrorMgs.CorridorID = "";
    } else {
      ErrorMgs.CorridorID = "Please select corridor";
      flag = false;
    }

    // if (ParameterID != null && ParameterID.length) {
    //   ErrorMgs.ParameterID = "";
    // } else {
    //   ErrorMgs.ParameterID = "Please select parameter";
    //   flag = false;
    // }
    if (ISCyclicCheck != 1) {
      if (EquipmentTagNo != null && EquipmentTagNo.value != undefined) {
        ErrorMgs.EquipmentTagNo = "";
      } else {
        ErrorMgs.EquipmentTagNo = "Please select equipment tag no";
        flag = false;
      }
    }

    seterrors({ ...ErrorMgs });

    // TicketModels.assignedBy =

    if (flag) {
      var fileInput = document.getElementById("FormFile");

      if (
        TicketModels.TicketStatusID == 28 &&
        TicketModels.AssignedBy == TicketModels.AssignedTo
      ) {
        TicketModels.TicketStatusID = 26;
      }
      TicketModels.savedBy = GetLoginUserID();
      TicketModels.BreakDownTypeID = Number(TicketModels.BreakDownTypeID);

      // TicketModels.ParameterID =  isNaN(ParameterID[0].id) ? 0 : ParameterID[0].id;
      TicketModels.ParameterName = ParameterID;
      if (ISCyclicCheck != 1) {
        TicketModels.EquipmentDetailID = EquipmentTagNo?.value;
      }
      TicketModels.Files = fileInput.files;

      const SelectedSpareList = SelectedSpareListForManual;

      $.each($("#FormFile")[0].files, function (i, file) {
        formData.append("files", file);
      });

      showLoading();

      obj
        .FileUpload(formData)
        .then((res) => {
          const WrapObject = {
            breakdownMaintenanceModel: TicketModels,
            sparesUsedMasterModels: SelectedSpareList,
            ListOfOrginalFileName: res,
          };

          obj
            .Breakdown_DepartmentManualSaveBreakdownDetailes(WrapObject)
            .then((res) => {
              setRefresh();

              hideLoading();

              $(".close").click();

              showSuccessMsg("Saved successfully.");
            })
            .catch((err) => {
              setRefresh();
              console.log(err);
              showErrorMsg("Something went wrong, Please try after sometime");
              hideLoading();
            });
        })
        .catch((err) => {
          hideLoading();

          console.log(err);
        });
    }
  }

  const NextDaySaveDetailes = (e) => {
    // e.preventDefault();

    const formData = new FormData();

    var TicketModels = TicketModel;

    const {
      Location,
      ProblemDescription,
      AssignedTo,
      AssignedBy,
      TicketStatusID,
      StationID,
      ReportToStrID,
      ShowReportBtn,
      DepartmentID,
      PTWNumber,
      PTWDate,
      BreakDownTypeID,
      DepartmentIDs,
      GuidName,
      CorridorID,
      OrginalFile,
      FilePath,
      ISCyclicCheck,
    } = TicketModels;

    var ErrorMgs = errors;

    let flag = true;

    if (Location != null && Location.trim() != "") {
      ErrorMgs.Location = "";
    } else {
      ErrorMgs.Location = "Please enter location";
      flag = false;
    }

    if (DepartmentID != null && DepartmentID != 0 && DepartmentID != "") {
      ErrorMgs.DepartmentID = "";
      const numbersArray = DepartmentIDs?.split(",").map(Number); // Split the string and convert each element to a number

      if (
        numbersArray.includes(DepartmentID) &&
        getUserDetails().PrefixName == "L0"
      ) {
        if (GuidName == null || GuidName == "" || GuidName == undefined) {
          ErrorMgs.GuidName = "Please take a photo";
          flag = false;
        } else {
          ErrorMgs.GuidName = "";
        }
      }
    } else {
      ErrorMgs.DepartmentID = "Please select department";
      flag = false;
    }

    if (getUserDetails().PrefixName == "L0") {
      if (PTWNumber != null && PTWNumber.trim() != "") {
        ErrorMgs.PTWNumber = "";
      } else {
        ErrorMgs.PTWNumber = "Please enter PTW number";
        flag = false;
      }
      if (PTWDate != null && PTWDate.trim() != "") {
        ErrorMgs.PTWDate = "";
      } else {
        ErrorMgs.PTWDate = "Please enter PTW date";
        flag = false;
      }
    }

    if (ProblemDescription != null && ProblemDescription.trim() != "") {
      ErrorMgs.ProblemDescription = "";
    } else {
      ErrorMgs.ProblemDescription = "Please enter problem description";
      flag = false;
    }

    if (AssignedToList != null && AssignedToList.length < 0) {
      if (AssignedTo != null && AssignedTo != "") {
        ErrorMgs.AssignedTo = "";
      } else {
        ErrorMgs.AssignedTo = "Please enter assigned to";
        flag = false;
      }
    } else {
      ErrorMgs.AssignedTo = "";
      if (ShowReportBtn == 1) {
        if (
          ReportToStrID != null &&
          ReportToStrID != "" &&
          ReportToStrID != "0"
        ) {
          ErrorMgs.ReportToStrID = "";
        } else {
          ErrorMgs.ReportToStrID = "Please enter report to";
          flag = false;
        }
      }
    }

    if (StationID != null && StationID != "") {
      ErrorMgs.StationID = "";
    } else {
      ErrorMgs.StationID = "Please enter station/section name";
      flag = false;
    }

    if (AssignedBy != null && AssignedBy != "") {
      ErrorMgs.AssignedBy = "";
    } else {
      ErrorMgs.AssignedBy = "Please enter assigned by";
      flag = false;
    }
    if (TicketStatusID != null && TicketStatusID != "") {
      ErrorMgs.TicketStatusID = "";
    } else {
      ErrorMgs.TicketStatusID = "Please select status";
      flag = false;
    }

    if (BreakDownTypeID != null && BreakDownTypeID > 0) {
      ErrorMgs.BreakDownTypeID = "";
    } else {
      ErrorMgs.BreakDownTypeID = "Please select break down type";
      flag = false;
    }

    if (CorridorID != null && CorridorID > 0) {
      ErrorMgs.CorridorID = "";
    } else {
      ErrorMgs.CorridorID = "Please select corridor";
      flag = false;
    }

    // if (ParameterID != null && ParameterID.length) {
    //   ErrorMgs.ParameterID = "";
    // } else {
    //   ErrorMgs.ParameterID = "Please select parameter";
    //   flag = false;
    // }
    if (ISCyclicCheck != 1) {
      if (EquipmentTagNo != null && EquipmentTagNo.Value != undefined) {
        ErrorMgs.EquipmentTagNo = "";
      } else {
        ErrorMgs.EquipmentTagNo = "Please select equipment tag no";
        flag = false;
      }
    }

    seterrors({ ...ErrorMgs });

    // TicketModels.assignedBy =

    if (flag) {
      var fileInput = document.getElementById("FormFile");

      if (
        TicketModels.TicketStatusID == 28 &&
        TicketModels.AssignedBy == TicketModels.AssignedTo
      ) {
        TicketModels.TicketStatusID = 26;
      }

      TicketModels.TicketStatusID = 1;
      TicketModels.savedBy = GetLoginUserID();
      TicketModels.BreakDownTypeID = Number(TicketModels.BreakDownTypeID);

      // TicketModels.ParameterID =  isNaN(ParameterID[0].id) ? 0 : ParameterID[0].id;
      TicketModels.ParameterName = ParameterID;
      if (ISCyclicCheck != 1) {
        TicketModels.EquipmentDetailID = EquipmentTagNo?.Value;
      }
      TicketModels.Files = fileInput.files;

      const SelectedSpareList = SelectedSpareListForManual;

      $.each($("#FormFile")[0].files, function (i, file) {
        formData.append("files", file);
      });

      showLoading();

      obj
        .FileUpload(formData)
        .then((res) => {
          const WrapObject = {
            breakdownMaintenanceModel: TicketModels,
            sparesUsedMasterModels: SelectedSpareList,
            ListOfOrginalFileName: res,
          };

          obj
            .Breakdown_DepartmentManualSaveBreakdownDetailes(WrapObject)
            .then((res) => {
              setRefresh();

              hideLoading();

              $(".close").click();

              showSuccessMsg("Saved successfully.");
            })
            .catch((err) => {
              setRefresh();
              console.log(err);
              showErrorMsg("Something went wrong, Please try after sometime");
              hideLoading();
            });
        })
        .catch((err) => {
          hideLoading();

          console.log(err);
        });
    }
  };

  const saveSpare = (value) => {
    SetSelectedSpareListForManual(value);
  };

  const CameraComponentChange = (e) => {
    const deserialData = JSON.parse(e);
    setTicketModel((pre) => ({
      ...pre,
      GuidName: deserialData[0]?.guidName ?? "",
      OrginalFile: deserialData[0]?.orginalFile ?? "",
      FilePath: deserialData[0]?.path ?? "",
    }));
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
      height: "100%",
      background: "white",
    }),
    control: (provided) => ({
      ...provided,

      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      // primary: "white",
      borderColor: "black",
    }),
  };
console.log(ParameterID)
  return (
    <div>
      {IsShow ? (
        <form
          id="ManualBreakdownDetailes"
          className="ManualBreakdownDetailes"
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={SaveDetailes}
        >
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="label">
                  Department Name<span className="text-danger"> *</span>{" "}
                </label>
                <br />
                <ReactTreeView
                  data={DropdownModel.DepartmentList}
                  onSelect={onSelect}
                  openByDefault={true}
                  isSearchNeed={false}
                  width={300}
                />
                {TicketModel.DepartmentName != "" && (
                  <span className="text-black mt-1 mr-1">
                    {" "}
                    {TicketModel.DepartmentName}{" "}
                  </span>
                )}

                {/* <span className="text-danger mt-1"> {error.DepartmentID} </span> */}
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Station/Section Name<span className="text-danger"> *</span>{" "}
                </label>
                <Dropdown
                  data={StationCatgoryList}
                  placeholder="--Select--"
                  name={"StationID"}
                  HandleChange={CommonHandleChange}
                  value={TicketModel.StationID}
                  className="BreakdownManualDrop"
                  disabled
                  initialVal={-1}
                />

                <span className="text-danger mt-1"> {errors.StationID} </span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Breakdown Type<span className="text-danger"> *</span>{" "}
                </label>
                <Dropdown
                  data={BreakDownTypeList}
                  placeholder="--Select--"
                  name={"BreakDownTypeID"}
                  HandleChange={CommonHandleChange}
                  value={TicketModel.BreakDownTypeID}
                  className="BreakdownManualDrop"
                  disabled
                  initialVal={-1}
                />

                <span className="text-danger mt-1">
                  {" "}
                  {errors.BreakDownTypeID}{" "}
                </span>
              </div>
            </div>

            {TicketDetailes.ISCyclicCheck != 1 && (
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Equipment Tag Number
                    <span className="text-danger"> *</span>
                  </label>
                  <ReactMultiSelect
                    isDisabled
                    options={Equiplist}
                    onChange={EquipmentChange}
                    placeholder="Select"
                    // closeMenuOnSelect={false}
                    // hideSelectedOptions={false}
                    theme={(theme) => ({
                      ...theme,
                      // borderRadius: 0,

                      fontColor: "Black",
                      colors: {
                        ...theme.colors,
                        primary25: "hotpink",
                        //  primary: 'white',
                        primary50: "red",
                      },
                    })}
                    styles={customStyles}
                    value={EquipmentTagNo}
                  />


                  <span className="text-danger"> {errors.EquipmentTagNo} </span>
                </div>
              </div>
            )}

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Parameter Name
                  <span className="text-danger"> </span>
                </label>
                <ReactSelect
                  options={FilterParameterList}
                  isDisabled
                  // closeMenuOnSelect={false}
                  // hideSelectedOptions={false}
                  onChange={(e) => setParameterID(e)}
                  placeholder="Select"
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: 0,

                    fontColor: "Black",
                    colors: {
                      ...theme.colors,
                      primary25: "hotpink",
                      //  primary: 'white',
                      primary50: "red",
                    },
                  })}
                  disabled
                  styles={customStyles}
                  value={ParameterID}
                />
                <span className="text-danger"> {errors.ParameterID} </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Status
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"

                  type="text"
                  name="ProblemDescription"
                  disabled
                  value={TicketModel.statusName}
                />
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned By
                  <span className="text-danger"> *</span>
                </label>
                <Dropdown
                  disabled
                  data={AssignByDropDown}
                  placeholder="Select"
                  value={TicketModel.AssignedBy}
                  className="BreakdownManualDrop"
                  initialVal={-1}
                  name={"AssignedBy"}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedBy} </span>
              </div>
            </div>
            {AssignedToList != "" && (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned To
                    <span className="text-danger"> * </span>
                  </label>
                  <Dropdown
                    data={AssignedToList}
                    placeholder="Select"
                    value={TicketModel.AssignedTo}
                    name={"AssignedTo"}
                    className="BreakdownManualDrop"
                    disabled
                    initialVal={-1}
                    HandleChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.AssignedTo} </span>
                </div>
              </div>
            )}
            {AssignedToList == null || AssignedToList == "" ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Report To
                    <span className="text-danger"> * </span>
                  </label>
                  {TicketModel.TicketID == undefined ||
                    TicketModel.TicketID == 0 ? (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportToStrID}
                      //   disabled
                      name={"ReportToStrID"}
                      className="BreakdownManualDrop"
                      initialVal={-1}
                      HandleChange={CommonHandleChange}
                    />
                  ) : (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportToStrID}
                      disabled
                      name={"ReportToStrID"}
                      className="BreakdownManualDrop"
                      initialVal={-1}
                      HandleChange={CommonHandleChange}
                    />
                  )}
                  <span className="text-danger"> {errors.ReportToStrID} </span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Problem Description
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="ProblemDescription"
                  disabled
                  value={TicketModel.ProblemDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
                <span className="text-danger">
                  {" "}
                  {errors.ProblemDescription}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Work Description
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="WorkDescription"
                  disabled
                  value={TicketModel.WorkDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Location
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  style={{ resize: "none" }}
                  type="text"
                  name="Location"
                  disabled
                  value={TicketModel.Location}
                  onChange={CommonHandleChange}
                  rows={3}
                />
                <span className="text-danger"> {errors.Location} </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Upload Document
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <input id="file" hidden />
                <input
                  className="form-control "
                  type="file"
                  id="FormFile"
                  name="FormFile"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>
          <ManualSpareList
            saveSpare={saveSpare}
            Ref={ModelRefresh}
            IsAdd={priviledge.includes("BREAKDOWNSPAREADDCREATE")}
            Sparelist={Sparelist}
            PartDropDown={PartDropDown}
            SelectedSpareList={SelectedSpareList}
          >
            {" "}
          </ManualSpareList>
        </form>
      ) : (
        <form
          id="ManualBreakdownDetailes"
          className="ManualBreakdownDetailes"
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={SaveDetailes}
        >
          {/* <h4
            className="modal-title text-primary"
            style={{
              position: "relative",
              top: "-31px",
              zIndex: "99",
              //   right: "12px",
              fontSize: "13px",
              textAlign: "center",
            }}
          >
            {RequesterDetails[0]?.RequesterDepartmentName}
          </h4> */}

          <div className="row" style={{ margin: "-20px" }}>
            <div className="col-md-3 col-sm-3">
              <div className="form-group">
                <label className="label">
                  Department Name<span className="text-danger"> *</span>{" "}
                </label>
                <br />
                <ReactTreeView
                  data={DropdownModel?.DepartmentList}
                  onSelect={onSelect}
                  openByDefault={true}
                  isSearchNeed={false}
                  width={300}
                  height={150}
                />
                {TicketModel.DepartmentName != "" && (
                  <>
                    <input
                      style={{ marginTop: "10px" }}
                      className="text-black form-control BreakdownManual"
                      type="text"
                      value={TicketModel.DepartmentName}
                      disabled
                    />
                  </>

                  // <span
                  //   className="text-black form-control"
                  //   style={{ display: "flex", marginTop: "10px" }}
                  // >
                  //   {" "}
                  //   {TicketModel.DepartmentName}{" "}
                  // </span>
                )}

                <span className="text-danger mt-1">
                  {" "}
                  {errors.DepartmentID}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="row">
                {/* <div
                  className="col-md-12 col-sm-6"
                  style={{ background: "#e9ecef" }}
                >
                  <label className="label mt-1" htmlFor="empCode">
                    Equipments
                  </label>
                  <p className="m-t-40">{TicketModel.EquipmentCode}</p>
                </div> */}
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      Corridor
                      <span className="text-danger"> *</span>{" "}
                    </label>
                    <Dropdown
                      data={CorridorList}
                      placeholder="--Select--"
                      name={"CorridorID"}
                      HandleChange={CommonHandleChange}
                      value={TicketModel.CorridorID}
                      className="CorridorDrp BreakdownManual"
                      disabled={
                        TicketModel.TicketID == undefined ||
                          TicketModel.TicketID == 0
                          ? false
                          : true
                      }
                      initialVal={0}
                    />

                    <span className="text-danger mt-1">
                      {" "}
                      {errors.CorridorID}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      Station/Section Name
                      <span className="text-danger"> *</span>{" "}
                    </label>
                    <Dropdown
                      data={StationCatgoryList}
                      placeholder="--Select--"
                      name={"StationID"}
                      HandleChange={CommonHandleChange}
                      value={TicketModel.StationID}
                      className="BreakdownManualDrop"
                      disabled={
                        TicketModel.TicketID == undefined ||
                          TicketModel.TicketID == 0
                          ? false
                          : true
                      }
                      initialVal={0}
                    />

                    <span className="text-danger mt-1">
                      {" "}
                      {errors.StationID}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      Breakdown Type<span className="text-danger"> *</span>{" "}
                    </label>
                    <Dropdown
                      data={BreakDownTypeList}
                      placeholder="--Select--"
                      name={"BreakDownTypeID"}
                      HandleChange={CommonHandleChange}
                      value={TicketModel.BreakDownTypeID}
                      className="BreakdownManualDrop"
                      disabled={
                        TicketModel.TicketID == undefined ||
                          TicketModel.TicketID == 0
                          ? false
                          : true
                      }
                      initialVal={-1}
                    />

                    <span className="text-danger mt-1">
                      {" "}
                      {errors.BreakDownTypeID}{" "}
                    </span>
                  </div>
                </div>
                {TicketModel.ISCyclicCheck != 1 ? (
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Equipment Tag Number
                        <span className="text-danger"> *</span>
                      </label>
                      <ReactMultiSelect
                        options={Equiplist}
                        onChange={EquipmentChange}
                        placeholder="Select"
                 
                        styles={customStyles}
                        value={EquipmentTagNo}
                        disabled={
                          TicketModel.TicketID == undefined ||
                            TicketModel.TicketID == 0
                            ? false
                            : true
                        }
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.EquipmentTagNo}{" "}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <label className="label" htmlFor="empCode">
                          Equipment Tag Number
                          <span className="text-danger"> *</span>
                        </label>

                        <ReactMultiSelect
                          options={EquipmentCyclicList}
                          isMulti
                          value={EquipmentCyclicID}
                          closeMenuOnSelect={false} 
                          hideSelectedOptions={false}
                          components={{
                            Option,
                            MultiValue,
                            animatedComponents,
                          }}
                          onChange={EquipmentCyclicIDChange}
                          allowSelectAll={true}
                        />

                        {/* <ReactMultiSelect
                          options={EquipmentCyclicList}
                          onChange={EquipmentCyclicIDChange}
                          placeholder="Select"
                          MultiValue={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          theme={(theme) => ({
                            ...theme,
                            // borderRadius: 0,

                            fontColor: "Black",
                            colors: {
                              ...theme.colors,
                              primary25: "hotpink",
                              //  primary: 'white',
                              primary50: "red",
                            },
                          })}
                          styles={customStyles}
                          value={EquipmentCyclicID}
                          allowSelectAll={true}
                          // disabled={
                          //   TicketModel.TicketID == undefined ||
                          //   TicketModel.TicketID == 0
                          //     ? false
                          //     : true
                          // }
                        /> */}
                        <span className="text-danger">
                          {" "}
                          {errors.EquipmentTagNo}{" "}
                        </span>
                      </div>
                    </div>
                  </>
                )}


                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      PTW Number{" "}
                      {getUserDetails().PrefixName == "L0" && (
                        <span className="text-danger"> *</span>
                      )}
                    </label>
                    <input
                      type="text"
                      name="PTWNumber"
                      className="form-control PTWNumber BreakdownManual"
                      value={TicketModel.PTWNumber}
                      // // disabled={
                      // //   // (TicketModel.TicketID == undefined ||
                      // //   //   TicketModel.TicketID == 0) &&
                      // //   getUserDetails().PrefixName == "L0" ? false : true
                      // // }
                      onChange={CommonHandleChange}
                    />
                    <span className="text-danger"> {errors.PTWNumber} </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      PTW Date
                      {getUserDetails().PrefixName == "L0" && (
                        <span className="text-danger"> *</span>
                      )}
                    </label>
                    <input
                      type="date"
                      name="PTWDate"
                      className="form-control PTWDate BreakdownManual"
                      value={TicketModel.PTWDate}
                      // disabled={
                      //   // TicketModel.TicketID == undefined ||
                      //   // TicketModel.TicketID == 0
                      //   getUserDetails().PrefixName == "L0" ? false : true
                      // }
                      onChange={CommonHandleChange}
                    />
                    <span className="text-danger"> {errors.PTWDate} </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" for="empCode">
                      Parameter Name<span className="text-danger"> </span>{" "}
                    </label>{" "}
                    <ReactAutoComplete
                      handleChange={(e, text) => {
                        setParameterID(text);
                        onchangeUOMClearName();
                      }}
                      value={ParameterID ?? ""}
                      handleSelect={(e, text) => {
                        setParameterID(e);
                        onchangeParameterName(text);
                      }}
                      name={"ParameterName"}
                      options={FilterParameterList}
                    />
                    <span className="text-danger mt-1">
                      {" "}
                      {errors.ParameterID}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="label" htmlFor="empCode">
                      UOM
                      <span className="text-danger"> </span>
                    </label>
                    <input
                      type="text"
                      name="ParameterUnit"
                      className="form-control ParameterUnit"
                      value={TicketModel.ParameterUnit}
                      onChange={CommonHandleChange}
                      disabled={isChildFocused}
                    />
                    <span className="text-danger"> {errors.ParameterID} </span>
                  </div>
                </div>

                {getUserDetails().PrefixName !== "L0" &&
                  TicketModel.initialStatusID == 26 ? (
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Status
                        <span className="text-danger"> *</span>
                      </label>

                      <Dropdown
                        data={StatusDropDown}
                        placeholder="--Select--"
                        value={TicketModel.TicketStatusID}
                        name={"TicketStatusID"}
                        className="BreakdownManualDrop TicketStatus"
                        HandleChange={CommonHandleChange}
                        initialVal={0}
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.TicketStatusID}{" "}
                      </span>
                    </div>
                  </div>
                ) : getUserDetails().PrefixName !==
                  COOKIES.AMCVendorTechnician &&
                  getUserDetails().PrefixName !== "L0" &&
                  getUserDetails().PrefixName !== "L1" &&
                  getUserDetails().PrefixName !== "L2" &&
                  getUserDetails().PrefixName !== "L4" &&
                  getUserDetails().PrefixName !== "L3" ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Status <span className="text-danger"> *</span>
                      </label>
                      <input
                        className="form-control BreakdownManual disabled"
                        // placeholder="Please Enter Problem Description"

                        type="text"
                        name="ProblemDescription"
                        disabled
                        value={TicketModel.statusName}
                      />
                    </div>
                  </div>
                ) : (getUserDetails().PrefixName ==
                  COOKIES.AMCVendorTechnician ||
                  getUserDetails().PrefixName !== "L0" ||
                  getUserDetails().PrefixName !== "L1" ||
                  getUserDetails().PrefixName !== "L2" ||
                  getUserDetails().PrefixName !== "L3" ||
                  getUserDetails().PrefixName !== "L4") &&
                  [
                    26, 25, 30, 98, 99, 100, 101, 105, 102, 103, 110, 111, 112,
                    28, 32,
                  ].includes(TicketModel.initialStatusID) ? (
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Status
                        <span className="text-danger"> *</span>
                      </label>

                      <Dropdown
                        data={StatusDropDown}
                        placeholder="--Select--"
                        value={TicketModel.TicketStatusID}
                        name={"TicketStatusID"}
                        className="BreakdownManualDrop TicketStatus"
                        HandleChange={CommonHandleChange}
                        initialVal={0}
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.TicketStatusID}{" "}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Status <span className="text-danger"> *</span>
                      </label>
                      <input
                        className="form-control BreakdownManual"
                        type="text"
                        disabled
                        name="ProblemDescription"
                        value={TicketModel.statusName}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Prefined Failures
                </label>
                <Dropdown
                  data={PrefinedFailuresList}
                  placeholder="--Select--"
                  value={TicketModel.PrefinedFailuresID}
                  className="PrefinedFailuresID"
                  initialVal={0}
                  name={"PrefinedFailuresID"}
                  HandleChange={CommonHandleChange}
                  disabled={
                    TicketModel.TicketID == undefined ||
                      TicketModel.TicketID == 0
                      ? false
                      : true
                  }
                />
                <span className="text-danger">
                  {" "}
                  {errors.PrefinedFailuresID}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned By
                  <span className="text-danger"> *</span>
                </label>
                <Dropdown
                  disabled
                  data={AssignByDropDown}
                  placeholder="--Select--"
                  value={TicketModel.AssignedBy}
                  className="BreakdownManualDrop BreakdownManual"
                  initialVal={0}
                  name={"AssignedBy"}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedBy} </span>
              </div>
            </div>
            {TicketModel.IsApprovel == 1 ||
              (getUserDetails().PrefixName == "L1" &&
                AssignedToList.length > 0) ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned To
                    <span className="text-danger"> * </span>
                  </label>
                  <Dropdown
                    data={AssignedToList}
                    placeholder="--Select--"
                    value={TicketModel.AssignedTo}
                    name={"AssignedTo"}
                    className="BreakdownManualDrop"
                    initialVal={0}
                    HandleChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.AssignedTo} </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {TicketModel.ShowReportBtn == 1 ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Report To
                    <span className="text-danger"> * </span>
                  </label>
                  {TicketModel.TicketID == undefined ||
                    TicketModel.TicketID == 0 ? (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportToStrID}
                      name={"ReportToStrID"}
                      className="BreakdownManualDrop"
                      initialVal={0}
                      HandleChange={CommonHandleChange}
                    />
                  ) : (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportToStrID}
                      //   disabled
                      name={"ReportToStrID"}
                      className="BreakdownManualDrop"
                      initialVal={0}
                      HandleChange={CommonHandleChange}
                    />
                  )}
                  <span className="text-danger"> {errors.ReportToStrID} </span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Problem Description
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="ProblemDescription"
                  value={TicketModel.ProblemDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                  disabled={
                    TicketModel.TicketID == undefined ||
                      TicketModel.TicketID == 0 ||
                      (TicketModel.ISCyclicCheck == 1 &&
                        getUserDetails().PrefixName == "L1")
                      ? false
                      : true
                  }
                />
                <span className="text-danger">
                  {" "}
                  {errors.ProblemDescription}{" "}
                </span>
              </div>
            </div>

            {getUserDetails().PrefixName == "L0" ||
              getUserDetails().PrefixName == "L1" ? ( //&&
              // TicketModel.TicketID != "0" &&
              // TicketModel.TicketID != undefined &&
              // TicketModel.TicketID != ""
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks {getUserDetails().PrefixName != "L0" ? "of L0" : ""}
                  </label>
                  <textarea
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks_L0"
                    value={TicketModel.Remarks_L0}
                    disabled={getUserDetails().PrefixName != "L0"}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.Remarks_L0} </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {(getUserDetails().PrefixName == "L1" ||
              getUserDetails().PrefixName == "L2") &&
              TicketModel.TicketID != "0" &&
              TicketModel.TicketID != undefined &&
              TicketModel.TicketID != "" ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks {getUserDetails().PrefixName != "L1" ? "of L1" : ""}
                  </label>
                  <textarea
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks_L1"
                    value={TicketModel.Remarks_L1}
                    disabled={getUserDetails().PrefixName != "L1"}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.Remarks_L1} </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(getUserDetails().PrefixName == "L2" ||
              getUserDetails().PrefixName == "L3") &&
              TicketModel.TicketID != "0" &&
              TicketModel.TicketID != undefined &&
              TicketModel.TicketID != "" ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks {getUserDetails().PrefixName != "L2" ? "of L2" : ""}
                  </label>
                  <textarea
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks_L2"
                    value={TicketModel.Remarks_L2}
                    disabled={getUserDetails().PrefixName != "L2"}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.Remarks_L2} </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(getUserDetails().PrefixName == "L3" ||
              getUserDetails().PrefixName == "L4") &&
              TicketModel.TicketID != "0" &&
              TicketModel.TicketID != undefined &&
              TicketModel.TicketID != "" ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks {getUserDetails().PrefixName != "L3" ? "of L3" : ""}
                  </label>
                  <textarea
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks_L3"
                    value={TicketModel.Remarks_L3}
                    disabled={getUserDetails().PrefixName != "L3"}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.Remarks_L3} </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {getUserDetails().PrefixName == "L4" ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks {getUserDetails().PrefixName != "L4" ? "of L4" : ""}
                  </label>
                  <textarea
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks_L4"
                    value={TicketModel.Remarks_L4}
                    disabled={getUserDetails().PrefixName != "L4"}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.Remarks_L4} </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Work Description
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="WorkDescription"
                  value={TicketModel.WorkDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                  disabled={
                    TicketModel.TicketID == undefined ||
                      TicketModel.TicketID == 0
                      ? false
                      : true
                  }
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Location
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  style={{ resize: "none" }}
                  type="text"
                  name="Location"
                  value={TicketModel.Location}
                  onChange={CommonHandleChange}
                  rows={3}
                  disabled={
                    TicketModel.TicketID == undefined ||
                      TicketModel.TicketID == 0 ||
                      (TicketModel.ISCyclicCheck == 1 &&
                        getUserDetails().PrefixName == "L1")
                      ? false
                      : true
                  }
                />
                <span className="text-danger"> {errors.Location} </span>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Upload Document
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <input id="file" hidden />
                <input
                  className="form-control "
                  type="file"
                  id="FormFile"
                  name="FormFile"
                  multiple
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <ManualSpareList
              saveSpare={saveSpare}
              Ref={ModelRefresh}
              // IsAdd={priviledge.includes("BREAKDOWNSPAREADDCREATE")}

              IsAdd={getUserDetails().PrefixName == "L0" ? true : false}
              // IsAdd={ true}

              Sparelist={StationSpareList ?? []}
              PartDropDown={StationPartList ?? []}
              SelectedSpareList={SelectedSpareList}
            >
              {" "}
            </ManualSpareList>
          </div>
          <div className="row">
            {/* {getUserDetails().PrefixName == "L0" ||
            getUserDetails().PrefixName == "L1" ? ( */}
            <div className="col-md-12 mb-2">
              <WebCam
                HandleChange={CameraComponentChange}
                ClearImage={RefreshforForm}
                AddOnName={
                  TicketModel.DepartmentName && TicketModel.DepartmentName + "_"
                }
              ></WebCam>
              <span className="text-danger"> {errors.GuidName} </span>
            </div>
            {/* ) : (
              <></>
            )} */}

            {getUserDetails().PrefixName == "L0" ||
              (getUserDetails().PrefixName == "L1" &&
                (TicketModel.TicketID == undefined ||
                  TicketModel.TicketID == 0)) ? (
              <div className="col-md-3 mb-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="IsunderBreakdown"
                    name="IsunderBreakdown"
                    value={TicketModel.IsunderBreakdown}
                    checked={TicketModel.IsunderBreakdown}
                    onChange={CommonHandleChange}
                  // disabled={
                  //   (TicketModel.TicketID == undefined ||
                  //   TicketModel.TicketID == 0) && getUserDetails().PrefixName == "L0"
                  //     ? false
                  //     : true
                  // }
                  />
                  <label className="form-check-label" for="IsunderBreakdown">
                    {" "}
                    Is Under Breakdown
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {(TicketModel.ShowReportBtn == 0 ||
            TicketModel.ShowReportBtn == undefined) &&
            (TicketModel.IsVisiable == undefined ||
              TicketModel.IsVisiable == 1) ? (
            <>
              <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
                <div className="col-md-2 ">
                  <button
                    type="submit"
                    id=""
                    onSubmit={SaveDetailes}
                    className="btn add-btn secondary-btn submit-btn"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    Save
                  </button>
                </div>
                {(TicketModel.DepartmentIDs &&
                  TicketModel.DepartmentIDs?.split(",")
                    .map(Number)
                    .includes(TicketModel.DepartmentID) &&
                  getUserDetails().PrefixName == "L0") ||
                  (TicketModel.ShowReportBtn == 0 &&
                    TicketModel.IsVisiable == 1 &&
                    getUserDetails().PrefixName == "L0") ? (
                  <div className="col-md-3 ">
                    <button
                      type="button"
                      id=""
                      onClick={NextDaySaveDetailes}
                      className="btn add-btn secondary-btn submit-btn"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      Continue for Next Day
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {TicketModel.ShowReportBtn == 1 ? (
            <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
              <div className="col-md-3 ">
                <button
                  type="submit"
                  id=""
                  onSubmit={SaveDetailes}
                  className="btn add-btn secondary-btn submit-btn"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  Report to Subordinate
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      )}
    </div>
  );
};
