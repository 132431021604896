import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import Global from "./../../services/Global";
import swal from "sweetalert";
import $, { data } from "jquery";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";


const _masterDetailService = new MasterDetailService();

export const Area = ({ TabID, TabValue }) => {
  const [allareaList, setallAreaList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [Refresh, setRefresh] = useState(0);
  const { currentMasterTab, getCookies } = useContext(AppContext);
  const { showLoading, hideLoading } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const columnNames = [
    { title: "Area ID", visible: false },
    { title: "Area Served" },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),

        createdCell: (td, cellData, rowData, row, col) => {
          var b = "";
          var c = "";
          if (getPrivilege().includes("MASTEREDIT")) {
            b = $(`<button class='btn icon-btn' title='Edit'>
            <i class='fa fa-pencil m-r-5'></i>
          </button>`).on("click", function () {
            EditArea(Number(rowData[0]));
            window.scroll(0,0);

            });
          }
  
          if (getPrivilege().includes("MASTERDELETE")) {
            c = $(`<button class='btn icon-btn'  title='delete'  >
            <i class='fa fa-trash m-l-15'></i>
          </button>`).on("click", function () {
            onDeleteClick(Number(rowData[0]));
            });
          }
  
          $(td).html(b);
          $(td).append(c);
        },
      
    },
  ];
  const onDeleteClick = (id) => {
 


    swal({
      title: "Are you sure?",
      text: "Do you want to delete the area served?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _masterDetailService.DeleteEquipmentArea(id).then((response) => {
          if (response.data == 1) {
            showDeleteSuccess();
            setRefresh(Refresh+1);
          }
          else {
            showSaveError();
          }
    
        }).catch((err) =>{
          setRefresh(Refresh+1);
          console.log(err);
        });
  
      }
    });  
  };
  const onSubmit = (data) => {
    var isExists = [];
    isExists = allareaList.find(
      (v) =>
      (v.areaName != null) &&
        v.areaName.toLowerCase().trim() == data.areaName.toLowerCase().trim() &&
        v.areaID != data.areaID
    );
    data.SavedBy = getCookies(Global.COOKIES.EmployeeID)

    if (isExists == null || isExists.length == 0) {
      _masterDetailService
        .SaveEquipmentArea(data)

        .then((response) => {
          reset();
          showSaveSuccess();
          var count = Refresh;
          setRefresh(++count);
        })
        .catch((error) => {
          showSaveError();
        });
    } else {
      showWarningMsg("Equipment area already exists");
    }
  };

  //functions
  useEffect(() => {
    // get parameter list
   
    if (TabID == TabValue) {
      console.log(TabID+ ' ' + TabValue + ' Area');
      getData();
      reset();
    }
  }, [currentMasterTab]);

  const getData = () => {
    
    _masterDetailService
      .GetEquipmentAreaList()
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          setallAreaList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.areaID);
            row.push(v.areaName);

            row.push("");
            rowList.push(row);
          });
          setAreaList(rowList);
        } else {
        }
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (TabID == TabValue && Refresh != 0) {
     getData();
    }
  }, [Refresh]);

  function EditArea(Id) {
    const found = allareaList.find((obj) => {
      return obj.areaID === Id;
    });
    // setstationListObj(found);
    Object.keys(found).map((key, i) => setValue(key, found[key]));
  }

  return (
    <div>
      {getPrivilege().includes("MASTERCREATE") && (
      <form id="AreaMaster" onSubmit={handleSubmit(onSubmit)}>
        <div className="row d-flex justify-content-end">
          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipLocationName">
                Area Served <span className="text-danger">*</span>
              </label>
              <input type="hidden" value={register.areaID} />

              <input
                type="text"
                name="areaName"
                maxLength="100"
                className="form-control"
                // placeholder="Area Served"
                id="areaName"
                {...register("areaName", {
                  required: "Equipment area is required",
                  validate: (value) => {
                    return !!value.trim() || "Equipment area cannot be empty";
                  },
                })}
                value={register.areaName}
              />
              {errors.areaName && (
                <span className="text-danger">{errors.areaName.message}</span>
              )}
            </div>
          </div>

          {getPrivilege().includes("MASTERCREATE") && (
          <div className="col-2 mt-4">
            <AddButton />
          </div>
          )}
        </div>
      </form>
      )}
      <HtmlTable columnList={columnNames} rowList={areaList} id={"areaTable"} />
    </div>
  );
};
