import React from "react";
import { makeUnique } from "../../services/CommonService";

const StationStatus = ({ StationStatusList }) => {
  const UniqueIDs = makeUnique(StationStatusList, ["CorridorType"]);
  return (
    <>
      <form id="StationStatus">
        <section className="">
          <div>
            <h3 className="card-title">File Transfer Status Across Stations</h3>
            <hr></hr>
          </div>
          {UniqueIDs?.map((ele) => {
            const list = StationStatusList?.filter(
              (element) => element.CorridorType == ele.CorridorType
            );
            return (
              <TimeLine
                StationStatusList={list}
                LineName={ele.CorridorType == 1 ? "C1" : "C2"}
              />
            );
          })}
        </section>
      </form>
    </>
  );
};

function TimeLine({ StationStatusList = [], LineName = "" }) {

  return (
    <>
     <h5 className="timeline-main-sider" >{LineName}</h5>
    <div className="timeline-container text-center mt-10">
   
      {StationStatusList.map((item, index) => (
        <div key={index} className={LineName == "C1" ? "timeline-item-blue" : "timeline-item-green"}>
          <div
            className="timestamp"
            title={
              item?.LastFileInsertedDate &&
              `Last Updated Time : ${item.LastFileInsertedDate}`
            }
          >
            <span className="date">{item.StationCode}</span>
          </div>
          <div
            className="Colorstatus"
            style={{
              backgroundColor:
                item.LastFileInsertedDate != null ? "green" : "red",
            }}
          ></div>
        </div>
      ))}
    </div>
    </>
  );
}
export default StationStatus;
