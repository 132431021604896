import React from "react";
import { createContext, useState, useContext } from "react";
import { getYMDdate } from "../../services/CommonService";

export const ManualLogContext = createContext({
  RoutineShiftCheckModel: {},
  setRoutineShiftCheckModel: () => {},
  DropdownModel: {},
  setDropdownModel: () => {},
  Error : {},
  setError : () => {},
  DropDownInitialState : {},
  RoutineChecksList : [],
  setRoutineChecksList : () => {}
});

function ContextProvider({children}) {
  
  const DropDownInitialState = {
    StationList : [],
    TypeList : [],
    EquipmentList : []
  }
  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [RoutineShiftCheckModel, setRoutineShiftCheckModel] = useState({
    CurrentDate : getYMDdate(new Date())
  });
  const [Error, setError] = useState({});
  const  [RoutineChecksList ,setRoutineChecksList] = useState()


  const contextValue = {
    RoutineShiftCheckModel: RoutineShiftCheckModel,
    setRoutineShiftCheckModel: setRoutineShiftCheckModel,
    DropdownModel: DropdownModel,
    setDropdownModel: setDropdownModel,
    Error : Error,
    setError : setError,
    DropDownInitialState: DropDownInitialState,
    RoutineChecksList : RoutineChecksList,
    setRoutineChecksList : setRoutineChecksList
  };

  return (
    <ManualLogContext.Provider value={contextValue}>
      {children}
    </ManualLogContext.Provider>
  );
}

export default ContextProvider;
