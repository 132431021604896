import React, { useEffect } from "react";
import { useContext } from "react";
import { WithoutSelectDropDown } from "../../../components/WithoutSelectDropDown";
import { PageHeaderTitle, ReactMultiSelect } from "../../../components";
import { DashBoardContext } from "../DashboardContext/DashboardContext";
import { PerformanceEvaluation_DropDownDetails } from "../../PerformanceEvaluation/PerformanceEvaluationService";
import { AppContext } from "../../../services/ContextProvider";
import { COOKIES } from "../../../services/Global";
import { Decrypt, MyCOLORS } from "../../../services/CommonService";
import { DashBoard_GetPerformanceEvaluation } from "../DashboardService";
import { LineChartForCommon } from "../../../components/charts/LineChartForCommon";
const PerformanceEvaluation = () => {
  const { setDropdownModel, setPerformanceModel,PerformanceModel } =
    useContext(DashBoardContext);
  const { getCookies, handleGlobalStationName } = useContext(AppContext);
  const {StationID,EquipmentID,YearID} = PerformanceModel;

  useEffect(() => {
    initialDropDown();
  }, []);
  useEffect(() => {
    GetDashboardData();
  }, [StationID,EquipmentID,YearID])
  


  const initialDropDown = async () => {
    initStationDropDown();
    YearDropdownList();
    await PerformanceEvaluation_DropDownDetails(0)
      .then((res) => {
        if (res?.data?.data?.length) {
          const { data } = res.data;
          setDropdownModel((pre) => ({
            ...pre,
            EquipmentList: data,
          }));
          setPerformanceModel({ EquipmentID: data[0]});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const YearDropdownList = () => {
    const currentYear = new Date().getFullYear();
    const YearDropDown = []
    for(var i =2022;i<=currentYear;i++){
      YearDropDown.push({label : i,Value : i});
    }
    setPerformanceModel({ YearID : YearDropDown[YearDropDown.length - 1]});

    setDropdownModel((pre) => ({
      ...pre,
      YearList : YearDropDown
    }))

  }

  const initStationDropDown = async () => {
    const userStationsList = Decrypt(getCookies(COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setDropdownModel((pre) => ({
      ...pre,
      StationList: FinalStationList,
    }));

    setPerformanceModel({ StationID: FinalStationList[0]?.value ?? 0 });
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  const GetDashboardData = () => {
    
    if(StationID > 0 && EquipmentID?.Value > 0 && YearID?.Value > 0){

    
    DashBoard_GetPerformanceEvaluation(StationID,EquipmentID.Value,YearID.Value).then((res) => {
        if(res.data){
          const {data} = res.data;
          
          if(data.hasOwnProperty("datasets") ){

            data.labels = data.labels.map((ele) => ele.Label);


            data.datasets?.forEach((ele,index) => {

              ele.borderWidth = 1;
              ele.backgroundColor =  MyCOLORS[index]
              ele.borderColor = MyCOLORS[index]
              ele.tension = 0.1
            })
            setPerformanceModel({DashboardData : data})
          }
          
        }
    }).catch((err) => console.log(err));
  }
  }

  return (
    <div>
      <PerformanceFilter></PerformanceFilter>
      <PerformanceGraph ></PerformanceGraph>
    </div>
  );
};

const PerformanceFilter = () => {
  const { DropdownModel, PerformanceModel, setPerformanceModel } =
    useContext(DashBoardContext);
  const { StationID, EquipmentID,YearID } = PerformanceModel;
  const { EquipmentList, StationList,YearList } = DropdownModel;

  const CommonHandleChange = (event,name = '') => {
    if(name){
      setPerformanceModel({ [name]: event });
    }else{
      const { name, value } = event.target;
      setPerformanceModel({ [name]: value });

    }

  };

  return (
    <div className="row d-flex justify-content-center mt-0 mb-2">
      <div className="col-md-2" style={{ float: "right" }}>
        <label className="form-check-label">Stations</label>
        <WithoutSelectDropDown
          data={StationList}
          value={StationID}
          name={"StationID"}
          HandleChange={CommonHandleChange}
        ></WithoutSelectDropDown>
      </div>

      <div className="col-md-2" style={{ float: "right" }}>
        <div className="form-group">
          <label className="label" for="empCode">
            Equipment
           
          </label>
          <ReactMultiSelect
            options={EquipmentList}
            placeholder="Select"
            value={EquipmentID}
            onChange={(e) => {
              CommonHandleChange(e, "EquipmentID");
            }}
          />
          
        </div>
      </div>
      <div className="col-md-2" style={{ float: "right" }}>
        <div className="form-group">
          <label className="label" for="empCode">
            Year
           
          </label>
          <ReactMultiSelect
            options={YearList}
            placeholder="Select"
            value={YearID}
            onChange={(e) => {
              CommonHandleChange(e, "YearID");
            }}
          />
          
        </div>
      </div>


    </div>
  );
};

const PerformanceGraph = () => {
  const { PerformanceModel } =
    useContext(DashBoardContext);

    const {DashboardData} = PerformanceModel;
  return (
    <>
    {DashboardData?.datasets?.length ? 

      <div className="card">
        <div className="card-body">
          <div className="row"> 
          <div className="col-8">
            <PageHeaderTitle  title={DashboardData?.descriptionName ?? ""}/> 
          <LineChartForCommon  data={DashboardData} id={"PerformanceID"}   ></LineChartForCommon>
          </div>
          </div>
        </div> 

      </div> : ""
    }
    </>
  )
}

export { PerformanceEvaluation };
