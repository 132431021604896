import React, { useEffect, useState } from "react";
import "../../assets/css/dynamicTable.css";
import { FcAddRow } from "react-icons/fc";
import { FcAddColumn } from "react-icons/fc";
import { GrPowerReset } from "react-icons/gr";



const DynamicTable = ({
  title,
  handleChange,
  DetailIncident,
  disabledCondition,
}) => {
  const [reset, setReset] = useState(false);

  useEffect(() => {
    handleChange([
      { id: 1, cells: ["", ""] },
      { id: 2, cells: ["", ""] },
      { id: 3, cells: ["", ""] },
    ]);
  }, [reset]);

  const addRow = () => {
    const newRow = {
      id: DetailIncident.length + 1,
      cells: Array(DetailIncident[0].cells.length).fill(""),
    };
    handleChange([...DetailIncident, newRow]);
  };

  const addColumn = () => {
    const updatedData = DetailIncident.map((row) => ({
      ...row,
      cells: [...row.cells, ""],
    }));
    handleChange(updatedData);
  };

  const deleteCell = (rowIndex, colIndex) => {
    const updatedData = DetailIncident.filter((row, rIndex) => {
      return rIndex != rowIndex;
    });
    handleChange(updatedData);
  };

  const deleteHeader = (colIndex) => {
    const updatedData = DetailIncident.map((row) => ({
      ...row,
      cells: row.cells.filter((cell, cIndex) => cIndex !== colIndex),
    }));
    handleChange(updatedData);
  };

  const handleReset = () => {
    setReset((p) => !p);
  };
  const handleSubmit = () => {
    console.log("Submitting data:", DetailIncident);
    // Replace with actual code to send data to backend
  };

  return (
    <div className="dynamic-table-container">
      <h3>{title}</h3>
      <div className="mb-2 bd-example">
        <button
          disabled={disabledCondition}
          type="button"
          title="Add Rows"
          className={`btn btn-primary m-1 ${
            disabledCondition ? "desableButton" : ""
          }`}
          onClick={addRow}
        >
         <FcAddRow 
         size={20}
         />
        </button>
        <button
          disabled={disabledCondition}
          type="button "
          className={`btn btn-secondary  m-1 ${
            disabledCondition ? "desableButton" : ""
          }`}
          title="Add Columns"
          onClick={addColumn}
        >
           <FcAddColumn
             size={20} />
        </button>

        <button
          disabled={disabledCondition}
          type="button"
          className={`btn m-1 ${
            disabledCondition ? "desableButton" : ""
          }`}
          title="Reset table"

          onClick={handleReset}
        >
          <GrPowerReset />
        </button>
      </div>
      <div className="scroll_div">
        <table className="dynamic-table table table-responsive table-striped">
          <thead>
            <tr>
              {DetailIncident.length > 0 &&
                DetailIncident[0].cells.map((cell, colIndex) => (
                  <th key={`header-${colIndex}`}>
                    <div className="headerCell d-flex">
                      <input
                        type="text"
                        value={cell}
                        placeholder="Header name"
                        onChange={(e) => {
                          const updatedData = [...DetailIncident];
                          updatedData[0].cells[colIndex] = e.target.value;
                          handleChange(updatedData);
                        }}
                        disabled={disabledCondition}
                        className={`header-input ${
                          disabledCondition ? "desableButton" : ""
                        }`}
                      />
                      <button
                        type="button"
                        className={`btn  ${
                          disabledCondition ? "desableButton" : ""
                        }`}
                        onClick={() => deleteHeader(colIndex)}
                        disabled={disabledCondition}
                      >
                       <i class="fa fa-times-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {DetailIncident.slice(1).map((row, rowIndex) => (
              <tr key={row.id}>
                {row.cells.map((cell, colIndex) => (
                  <td key={`${row.id}-${colIndex}`} className="">
                    <div className="bodyTable">
                      <input
                        type="text"
                        placeholder="Description"
                        value={cell}
                        onChange={(e) => {
                          const updatedData = [...DetailIncident];
                          updatedData[rowIndex + 1].cells[colIndex] =
                            e.target.value;
                          handleChange(updatedData);
                        }}
                        disabled={disabledCondition}
                        className={`cell-input ${
                          disabledCondition ? "desableButton" : ""
                        }`}
                      />
                      {row?.cells?.length == colIndex + 1 && (
                        // <button
                        //   type="button"
                        //   className={`action-button-body ${
                        //     disabledCondition ? "desableButton" : ""
                        //   }`}
                        //   onClick={() => deleteCell(rowIndex + 1, colIndex)}
                        //   disabled={disabledCondition}
                        // >
                        //   X
                        // </button>
                        <button
                          type="button"
                          className={` btn ${
                            disabledCondition ? "desableButton" : ""
                          }`}
                          onClick={() => deleteCell(rowIndex + 1, colIndex)}
                          disabled={disabledCondition}
                         
                        >
                          <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DynamicTable;
