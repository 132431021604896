import React from 'react';

const VerificationDays = ({ approvelDays, handleRefresh }) => {
    // Sort the array based on the labels (e.g., "L1", "L2", etc.)
    approvelDays.sort((a, b) => {
        const labelA = parseInt(a.name.replace("TimeDurationL", ""));
        const labelB = parseInt(b.name.replace("TimeDurationL", ""));
        return labelA - labelB;
    });

    // Organize data into rows
    const rows = {};
    approvelDays.forEach(({ name, value }) => {
        const label = name.replace("TimeDurationL", "L");
        if (!rows[label]) {
            rows[label] = [];
        }
        rows[label].push(value);
    });

    return (
        <div className="verification-days-container">
            <div className="row">
                {Object.entries(rows).map(([label, data]) => (
                    <div key={label} className="col-md-3">
                        <div className="list-group">
                            <div className="list-group-item">
                                <strong>{label}:</strong> {data.join(', ')}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <style jsx>{`
                .col-md-3 {
                    margin-bottom: 20px; /* Adjust the value as needed */
                }
            `}</style>
        </div>
    );
};

export default VerificationDays;
