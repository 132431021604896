import React from "react";
import { MainTable } from "./MainTable";
import PeriodicStartMonthProvider from "./ContextProvider";
function PeriodicStartMonthConfig() {
  return (
    <PeriodicStartMonthProvider>
      <MainTable></MainTable>
    </PeriodicStartMonthProvider>
  );
}

export default PeriodicStartMonthConfig;
