import React from "react";
import {
  Dropdown,
  HtmlTable,
  showSaveSuccess,
  showErrorMsg,
  ReactAutoComplete,
} from "../../components";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  addDays,
} from "../../services/CommonService";
import { useState, useEffect, useContext, useReducer } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  DailyElectricalPanel_GetDailyElectricalPanelDetailes,
  DailyElectricalPanelMonitor_GetEquipmentCode,
  DailyElectricalPanel_SaveDailyElectricalPanel,
  DailyElectricalPanel_ApprovelRejectRequest,
} from "./DailyElectricalPanelservice";
import $ from "jquery";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { ReactMultiSelect } from "../../components";
import { NULL } from "xlsx-populate/lib/FormulaError";

export default function DailyElectricalPanel({ TabValue }) {
  const initialEquipmentState = {
    RoomID: [],
    StationID: "",
    IsDeleted: false,
    checkAllAttended: false,
    CurrentDate: getYMDdate(new Date()),
    checkAllApproved: false,
    checkAllRejected: false,
    EquipmentLocation: "",
  };
  const [StationList, setStationList] = useState([]);
  const [errors, setErrors] = useState({
    RoomID: "",
    StationID: "",
  });
  const [RoomList, setRoomList] = useState([]);
  const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
  const [EquipmentLocationList, setEquipmentLocationList] = useState([]);
  const [ListPageRender, SetListPageRender] = useReducer((x) => x + 1, 0);
  const [DailyElectricalPanelDetailList, setDailyElectricalPanelDetailList] =
    useState([]);
  const [RoomModel, setRoomModel] = useState(initialEquipmentState);
  const [time, setTime] = useState(new Date());

  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);

  /**
   * Column Names for the Temperature Monitor Detail   Table.
   */
  const columnNames = [
    {
      title: `<p style="text-align: center;"> Time (hrs)</p>`,
      // title: "Time (hrs)",
      data: "HoursAndMinutes",
    },

    {
      title: `<p style="text-align: center;"> Entry Start Time</p>`,
      // title: "Entry Start Time",
      data: "EntryStartDate",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="TimeCls" > </span>`);
        span.append(cellData);
        $(td).html(span);
      },
    },

    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">RY</span></p>`,
      data: "LineVoltage_VRY",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineVoltage_VRY");
      },
    },
    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">YB</span></p>`,
      data: "LineVoltage_VYB",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineVoltage_VYB");
      },
    },
    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">BR</span></p>`,
      // title: "VBR",
      data: "LineVoltage_VBR",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineVoltage_VBR");
      },
    },
    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">RN</span></p>`,
      // title: "VRN",
      data: "PhaseVoltage_VRN",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "PhaseVoltage_VRN");
      },
    },
    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">YN</span></p>`,
      // title: "VYN",
      data: "PhaseVoltage_VYN",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "PhaseVoltage_VYN");
      },
    },
    {
      title: `<p style="text-align: center;">V<span style="font-size: 11px;">BN</span></p>`,
      // title: "VBN",
      data: "PhaseVoltage_VBN",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "PhaseVoltage_VBN");
      },
    },
    {
      title: `<p style="text-align: center;">I<span style="font-size: 11px;">R</span></p>`,
      // title: "IR",
      data: "LineCurrent_IR",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineCurrent_IR");
      },
    },
    {
      title: `<p style="text-align: center;">I<span style="font-size: 11px;">Y</span></p>`,
      // title: "IY",
      data: "LineCurrent_IY",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineCurrent_IY");
      },
    },
    {
      title: `<p style="text-align: center;">I<span style="font-size: 11px;">B</span></p>`,
      // title: `IB`,
      data: "LineCurrent_IB",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "LineCurrent_IB");
      },
    },
    {
      title: `<p style="text-align: center;">&empty;</p>`,
      // title: `&empty;`,

      data: "PowerFreq_HZ",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "PowerFreq_HZ");
      },
    },
    {
      title: `<p style="text-align: center;">H<span style="font-size: 11px;">Z</span></p>`,
      // title: `HZ`,
      data: "Freq_HZ",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Freq_HZ");
      },
    },
    {
      title: `<p style="text-align: center;"> KW</p>`,
      // title: `KW`,
      data: "Power_KW",

      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Power_KW");
      },
    },

    {
      title: `<p style="text-align: center;"> MVAH</p>`,
      // title: `MVAH`,
      data: "ApparentEnergy_MVAH",

      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(
          td,
          cellData,
          rowData,
          row,
          col,
          "ApparentEnergy_MVAH"
        );
      },
    },
    {
      title: `<p style="text-align: center;"> MWH</p>`,
      // title: `MWH`,
      data: "ActiveEnergy_MWH",

      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "ActiveEnergy_MWH");
      },
    },
    {
      title: `<p style="text-align: center;"> MVARH</p>`,
      // title: `MVARH`,
      data: "ReactiveEnergy_MVARH",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(
          td,
          cellData,
          rowData,
          row,
          col,
          "ReactiveEnergy_MVARH"
        );
      },
    },
    {
      title: `<p style="text-align: center;"> Status</p>`,
      // title: "Status",
      data: "DailyElectricalPanelStatusSTR",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="StatusCls" > </span>`);
        span.append(GetPeriodicStatus(cellData));
        $(td).html(span);
      },
    },

    // {
    //   title: "Remarks",
    //   data: "Remark",
    //   createdCell: (td, cellData, rowData, row, col) => {
    //     let b = "";
    //     if (
    //       !IsAllowToEnterData() ||
    //       rowData.Status == 49 ||
    //       rowData.Status == 50
    //     ) {
    //       b = $(
    //         `<textarea  class="form-control" disabled style = "
    //       resize: none;
    //       min-width: 150px !important;" value="${cellData}">${cellData} </textarea>`
    //       );
    //     } else if (
    //       getUserDetails().RoleID !== COOKIES.TechRoleID ||
    //       IsAllowToEnterData()
    //     ) {
    //       b = $(
    //         `<textarea  class="form-control " style = "
    //         resize: none;
    //         min-width: 150px !important;" value="${cellData}">${cellData} </textarea>`
    //       ).on("change", (e) => {
    //         e.preventDefault();
    //         rowData.Remark = e.target.value;
    //         DailyElectricalPanelSaveChange(rowData);

    //         UpdateStatus(e);
    //       });
    //     }

    //     $(td).html(b);
    //   },
    // },
  ];

  function CustomeHeader(table) {

    let thead = $(table?.table()?.header());

    if (thead?.length) {
      const ColumnData = [
        {
          title: "",
          colspan: 2,
        },
        {
          title: "Line Voltage ",
          colspan: 3,
        },
        {
          title: "Phase Voltage ",
          colspan: 3,
        },
        {
          title: "Line Current ",
          colspan: 3,
        },
        {
          title: "P. F",
        },
        {
          title: "Freq",
        },
        {
          title: "Power",
        },
        {
          title: "Apparent Energy",
        },
        {
          title: "Active Energy",
        },
        {
          title: "Reactive Energy",
        },
        {
          title: "",
        },
      ];

      let headerRow1 = $('<tr class="header-row">');
      columnNames.forEach((column) => {
        headerRow1.append(
          `<th style="border-right: 2px solid silver; border-left: 1px solid silver;" >${column.title}</th>`
        );
      });

      let headerRow2 = $('<tr class="header-row">');
      ColumnData.forEach((data) => {
        if (!data?.title) {
          headerRow2.append(
            `<th colspan="${data.colspan}" style="text-align: center; border-right: 1px solid silver; border-left: 1px solid silver;"></th>`
          );
        } else {
          headerRow2.append(
            `<th colspan="${data.colspan}" style="text-align: center;  border-right: 1px solid silver; border-left: 1px solid silver;">${data.title}</th>`
          );
        }
      });
      thead.prepend(headerRow2);
    }

  }

  const createdCommonCell = (td, cellData, rowData, row, col, ObjectName) => {
    let inputElement = "";
    if (
      rowData.DailyElectricalPanelStatusSTR != "Pending" &&
      rowData.DailyElectricalPanelStatusSTR != "Open" &&
      RoomModel.checkAllAttended == 54
    ) {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    } else if (
      rowData.DailyElectricalPanelStatusSTR == "Open" &&
      getUserDetails().RoleID == COOKIES.TechRoleID
    ) {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      ).on("change", function (e) {
        e.preventDefault();

        let newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          rowData[ObjectName] = newValue;
          DailyElectricalPanelSaveChange(rowData);
          UpdateStatus(e);
          // SetListPageRender();
        }
      });
    } else if (!IsAllowToEnterData()) {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    } else {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      ).on("change", function (e) {
        e.preventDefault();

        let newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          rowData[ObjectName] = newValue;
          DailyElectricalPanelSaveChange(rowData);
          UpdateStatus(e);
          // SetListPageRender();
        }
      });
    }

    $(td).html(inputElement);
  };

  useEffect(() => {
    // if (TabValue == 2) {
    //   initialDropDown();
    // }
    initialDropDown();
    return () => {
      setStationList([]);
      setRoomList([]);
      setEquipmentTagNumberList([]);
      setEquipmentLocationList([]);
    };
  }, []);

  useEffect(() => {
    initialDropDownEquipmentCode();
  }, [RoomModel.StationID]);
  useEffect(() => {
    getDailyElectricalDetails();
  }, [
    RoomModel.StationID,
    RoomModel.EquipmentDetailID,
    RoomModel.CurrentDate,
    ListPageRender,
  ]);

  // }, [TabValue]);

  /**
   * Handle change event for common inputs.
   *
   * @param {Event} event - The input change event.
   */
  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        let Station = StationList.find((x) => x.value == value);

        handleGlobalStationName(Station?.key ?? "");
      }
    } else {
      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
      }));
    }
  };

  function ApprovedBtn() {
    let tableValues = $("#DailyElectricalPanel")
      .DataTable()
      .table()
      .data()
      .toArray();

    let TLApproveBtn = $(
      `<button  class="btn btn-success "
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 56);
        await getDailyElectricalDetails();
      }
    });

    let ApproveBtn = $(
      `<button  class="btn btn-success "
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 56);
        await getDailyElectricalDetails();
      }
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 57);
        await getDailyElectricalDetails();
      }
    });
    let ApproveBtndisabled = $(
      `<button disabled class="btn btn-success "
          >  Approve </button>  `
    );
    let RejectBTndisabled = $(
      `<button  disabled class="btn btn-danger m-l-15 m-r-10"
          >  Reject </button>  `
    );
    let Fragment = $(`<s></s`);

    if (
      RoomModel.checkAllAttended == 55 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.append(TLApproveBtn);
      Fragment.append(RejectBTn);
    }

    if (
      RoomModel.checkAllAttended == 56 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(RoomModel.CurrentDate, 15))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTn);
    }

    if (
      RoomModel.checkAllAttended == 57 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(RoomModel.CurrentDate, 15))
    ) {
      Fragment.append(TLApproveBtn);
      Fragment.append(RejectBTndisabled);
    }

    if (
      RoomModel.checkAllAttended == 54 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getYMDdate(new Date()) >= getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.append(TLApproveBtn);
      Fragment.append(RejectBTn);
    }

    if (
      RoomModel.checkAllAttended == 0 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getPrivilege().includes("MANUALLOGAPPROVAL")
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    if (
      RoomModel.checkAllAttended == 54 &&
      getUserDetails().RoleID == COOKIES.EnggRoleID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) < getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    if (
      RoomModel.checkAllAttended == 54 &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) < getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }
    if (
      RoomModel.checkAllAttended == 54 &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.append(ApproveBtndisabled);
      Fragment.append(RejectBTndisabled);
    }

    return Fragment;
  }

  function SubmitBtn() {
    let tableValues = $("#DailyElectricalPanel")
      .DataTable()
      .table()
      .data()
      .toArray();

    let SendForApprovelBtn = $(
      `<button  class="btn btn-primary " style="margin-left: 3px;"
          >  Submit </button> `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await ApprovelRejectRequirest(rowData, 54);
        await getDailyElectricalDetails();
      }
    });

    let Fragment = $(`<span></span>`);

    if (
      getUserDetails().RoleID == COOKIES.TechRoleID &&
      (RoomModel.checkAllAttended == NULL || RoomModel.checkAllAttended == 0) &&
      RoomModel.DailyElectricalPanelIsAdd == 1 &&
      RoomModel.CurrentDate == getYMDdate(new Date())
      //   &&
      //   getYMDdate(new Date()) <=
      //     getYMDdate(addDays(RoomModel.CurrentDate, 15)) &&
      //   getYMDdate(new Date()) > getYMDdate(addDays(RoomModel.CurrentDate, 3))
    ) {
      Fragment.prepend(SendForApprovelBtn);
    }

    return Fragment;
  }

  function IsAllowToEnterData() {
    let currentDate = new Date().toISOString().slice(0, 10);
    let CheckDate = new Date(RoomModel.CurrentDate).toISOString().slice(0, 10);
    return (
      getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate
    );
  }

  function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return hours + ":" + minutes;
  }

  function UpdateStatus(e) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");
      let TimeClsCell = rowElement.find(".TimeCls");

      if (StatusClsCell.length > 0) {
        StatusClsCell.html(GetPeriodicStatus("Attended")); // You can format the value as needed
      }

      if (TimeClsCell.length > 0) {
        TimeClsCell.html(getCurrentTime()); // You can format the value as needed
      }
    }
  }

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    //let ignoredStation = [""];

    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setStationList([...FinalStationList]);

    setRoomModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
      CurrentDate: getYMDdate(new Date()),
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  /**
   * Initializes dropdown values and equipment Code details.
   */
  const initialDropDownEquipmentCode = async () => {
    if (RoomModel.StationID > 0) {
      await DailyElectricalPanelMonitor_GetEquipmentCode(RoomModel.StationID)
        .then((res) => {
          if (res?.data) {
            if (res.data.data.equipmentTagList?.length) {
              setEquipmentTagNumberList([...res.data.data.equipmentTagList]);

              if (res.data.data.equipmentTagList?.length) {
                setRoomModel((pre) => ({
                  ...pre,

                  EquipmentDetailID: res.data.data.equipmentTagList[0],
                }));
              } else {
                setRoomModel((pre) => ({
                  ...pre,
                  EquipmentDetailID: "",
                }));
              }
            } else {
              setEquipmentTagNumberList([]);
            }
            if (res.data.data.equipmentLocationList?.length) {
              setEquipmentLocationList([
                ...res.data.data.equipmentLocationList,
              ]);
            } else {
              setEquipmentLocationList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  /**
   * Fetch equipment details for a specific Energy Consumption date.
   */
  const getDailyElectricalDetails = async () => {
    if (RoomModel.StationID > 0 && RoomModel.EquipmentDetailID?.value) {
      showLoading();
      await DailyElectricalPanel_GetDailyElectricalPanelDetailes(
        RoomModel.StationID,
        RoomModel.EquipmentDetailID.value,
        RoomModel.CurrentDate
      )
        .then((res) => {
          hideLoading();
          if (res?.data) {
            // const { data } = res.data.data.dailyElectricalPanelDet;

            if (res.data.data.dailyElectricalPanelDet?.length) {
              if (res.data.data?.dailyElectricalPanelMST != null) {
                setRoomModel((pre) => ({
                  ...pre,
                  checkAllAttended:
                    res.data.data.dailyElectricalPanelMST[0]
                      .DailyElectricalPanelStatus,
                  DailyElectricalPanelIsAdd:
                    res.data.data.dailyElectricalPanelMST[0]
                      .DailyElectricalPanelIsAdd,
                  DailyConsumptionKWH:
                    res.data.data.dailyElectricalPanelMST[0]
                      .Daily_Consumption_KWH,
                }));
              } else {
                setRoomModel((pre) => ({
                  ...pre,
                  checkAllAttended: 0,
                  DailyElectricalPanelIsAdd: 0,
                  DailyConsumptionKWH: "",
                }));
              }
              setDailyElectricalPanelDetailList([
                ...res.data.data.dailyElectricalPanelDet,
              ]);
            } else {
              setDailyElectricalPanelDetailList([]);
            }
            if (res.data.data.equipmentLocationSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation:
                  res.data.data.equipmentLocationSelected[0].label,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation: "",
              }));
            }
            if (res.data.data.isoDocumentName?.length) {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: res.data.data.isoDocumentName[0].DocumentNo,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: "",
              }));
            }
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  /**
   * Handle changes in equipment energy Consumption and save the configuration.
   *
   * @param {Object} obj - The equipment energy Consumption  configuration object.
   */
  const DailyElectricalPanelSaveChange = async (obj, status = 48) => {
    obj.CreatedBy = Number(GetLoginUserID());
    obj.DailyElectricalPanelMonitoringDate = getYMDdate(RoomModel.CurrentDate);
    obj.StationID = RoomModel.StationID;
    obj.EquipmentDetailID = RoomModel.EquipmentDetailID.value;
    obj.EquipmentLocation = RoomModel.EquipmentLocation;

    obj.CreatedDate = new Date().toLocaleTimeString();

    await DailyElectricalPanel_SaveDailyElectricalPanel(obj)
      .then((res) => {
        const { isSuccess } = res.data;
        obj.DailyElectricalPanelMonitorID = res.data.data;
        if (
          obj.DailyElectricalPanelMonitorID == 6 &&
          RoomModel.DailyElectricalPanelIsAdd == 0
        ) {
          SetListPageRender();
        }

        if (isSuccess && status != 48) {
          showSaveSuccess();
        } else if (!isSuccess) {
          showErrorMsg("Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ApprovelRejectRequirest = async (obj, status = 48) => {
    obj.CreatedBy = Number(GetLoginUserID());
    obj.DailyElectricalPanelStatus = Number(status);
    obj.DailyElectricalPanelMonitoringDate = getYMDdate(RoomModel.CurrentDate);
    obj.StationID = RoomModel.StationID;
    obj.EquipmentDetailID = RoomModel.EquipmentDetailID.value;

    await DailyElectricalPanel_ApprovelRejectRequest(obj)
      .then((res) => {
        const { isSuccess } = res.data;

        obj.DailyElectricalPanelMonitorID = res.data.data;

        if (isSuccess) {
          showSaveSuccess();
        } else {
          showErrorMsg("Something went wrong. Please try again.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function DateFilter() {
    return $(
      `<label class='m-l-15' style ="display:ruby" > <span> Date : </span>
        <input type="date" id="CERTMDateID" max=${getYMDdate(
        new Date()
      )} style="height:35px;width:auto" class="form-control m-l-15" value ="${RoomModel.CurrentDate
      }"
        /></label>
        <label class='m-l-15' style ="display:inline-flex" > <span> ${RoomModel?.DailyConsumptionKWH !== null &&
        RoomModel?.DailyConsumptionKWH !== ""
        ? `KWH: ${RoomModel.DailyConsumptionKWH}`
        : ''}</span>
   </label>
        `
    ).on("change", function (e) {
      const { value } = e.target;
      setRoomModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
  }

  return (
    <div>
      {/* <div className="col-md-12">
        <label
          className="label"
          for="empCode"
          style={{ float: "right", position: "relative", top: "-15px" }}
        >
          {RoomModel?.DailyConsumptionKWH !== null &&
            RoomModel?.DailyConsumptionKWH !== ""
            ? `KWH: ${RoomModel.DailyConsumptionKWH}`
            : null}
        </label>
      </div> */}
      <form id="EquipmentRunningStatusName">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={RoomModel.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment Tag Number <span className="text-danger"> *</span>{" "}
              </label>{" "}
              <ReactMultiSelect
                options={EquipmentTagNumberList}
                value={RoomModel.EquipmentDetailID}
                onChange={(e) => CommonHandleChange(e, "EquipmentDetailID")}
                labelledBy="Select"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment Location <span className="text-danger"> *</span>{" "}
              </label>{" "}
              <ReactAutoComplete
                handleChange={(_, text) => {
                  CommonHandleChange(text, "EquipmentLocation");
                }}
                value={RoomModel.EquipmentLocation}
                handleSelect={(e) => {
                  CommonHandleChange(e, "EquipmentLocation");
                }}
                Id={"EquipmentLocation"}
                name={"EquipmentLocation"}
                options={EquipmentLocationList ?? []}
              />
            </div>
          </div>
          {/* <div className="col-md-9">
            <label
              className="label"
              for="empCode"
              style={{ float: "right", position: "relative" }}
            >
              {RoomModel?.DailyConsumptionKWH !== null &&
                RoomModel?.DailyConsumptionKWH !== ""
                ? `KWH: ${RoomModel.DailyConsumptionKWH}`
                : null}
            </label>
          </div> */}
          <div className="col-md-3">
            <label
              className="label"
              for="empCode"
              style={{ float: "right", position: "relative" }}
            >
              {RoomModel?.ISODocumentNo &&
                `Doc Number : ${RoomModel.ISODocumentNo}`}
            </label>
          </div>
        </div>
      </form>
      <div className="" style={{ position: "relative", top: "-20px" }}>
        <HtmlTable
          columnList={columnNames}
          addedPlugin={CustomeHeader}
          rowList={DailyElectricalPanelDetailList ?? []}
          id={"DailyElectricalPanel"}
          FixedColums={{
            left: 1,
            right: 1,
          }}
          ShowLengthPostponedHtml={DateFilter}
          SearchPrependHtml={ApprovedBtn}
          IsASC
          //   orderby={0}
          SearchPostponedHtml={SubmitBtn}
        />
      </div>
    </div>
  );
}
