import React, { useContext, useEffect, useState } from "react";
import {
  Report_OverAllHistoryDropDown,
  Report_OverAllHistoryReport,
} from "./OverAllHistortyService";
import ExcelJS from "exceljs";
import { merge } from "jquery";
import moment from "moment";
import { Decrypt, makeUnique } from "../../services/CommonService";
import {
  MultiValue,
  Option,
  PageHeaderTitle,
  ReactMultiSelect,
  animatedComponents,
  showErrorMsg,
  showWarningMsg,
} from "../../components";
import { AppContext } from "../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { FilterDropdown } from "../../components/FilterDropdown";
import { MdFileDownload } from "react-icons/md";

export default function OverAllHistoryReport() {
  const InitialDropDownType = {
    Stations: [],
    EquipmentID: {},
    StationList: [],
    FilterEquipmentList: [],
    EquipmentList: [],
    Year: new Date().getFullYear(),
  };

  const [DropdownModel, setDropdownModel] = useState(InitialDropDownType);

  const { getCookies, handleGlobalStationName } = useContext(AppContext);

  useEffect(() => {
    GetDropDownList();
    return () => {
      setDropdownModel(InitialDropDownType);
    };
  }, []);

  const ManipulateData = async (
    breakdownList,
    periodicList,
    manualBreakdownList
  ) => {
    //  const { breakdownList, periodicList, manualBreakdownList } = ReportList;

    const Workbook = new ExcelJS.Workbook();
    let StationList = makeUnique(
      periodicList.map((ele) => {
        return { StationCode: ele.StationCode, StationID: ele.StationID };
      }),
      ["StationCode", "StationID"]
    );

    if (StationList?.length) {
      const EquipmentShortName = DropdownModel?.EquipmentID?.label ?? "";

      StationList.map((stationObj, _) => {
        const { StationID, StationCode } = stationObj;

        const StationWiseProjectList = periodicList.filter(
          (ele) => ele.StationID == StationID
        );
        const StationWiseBreakdown = breakdownList.filter(
          (ele) => ele.StationID == StationID
        );

        const ManualStationWiseBreakdown = manualBreakdownList.filter(
          (ele) => ele.StationID == StationID
        );

        const worksheet = Workbook.addWorksheet(StationCode);
        const fileHeader = `A${1}:k${1}`;
        MergeColumWithValue(
          worksheet,
          fileHeader,
          `CMMS-${StationCode} ${EquipmentShortName} History Card`,
          false
        );
        worksheet.getRow(1).height = 30;
        worksheet.columns.forEach((ele) => (ele.width = 10));

        let MaintenanceTypeIDList = [
          ...new Set(
            StationWiseProjectList.map((ele) => ele.MaintenanceTypeID)
          ),
        ];

        const MaintenanceTypeObj = {
          2: "Monthly",
          3: "Quarterly",
          4: "Half - Yearly",
          5: "Yearly",
        };

        MaintenanceTypeIDList.map((MaintenanceTypeID) => {
          let cell =
            Number(worksheet?.lastRow?.getCell(1).address.replace("A", "")) ??
            1;

          HeaderComponent(
            worksheet,
            cell,
            MaintenanceTypeObj[MaintenanceTypeID]
          );

          let EquipmentCodes = [
            ...new Set(periodicList.map((ele) => ele.EquipmentDetailID)),
          ];
          EquipmentCodes.map((EquipmentDetailID, index) => {
            const startCell =
              worksheet.getCell(`A${worksheet.rowCount}`).row + 1;
            let EquipmentObj = periodicList.filter(
              (element) =>
                element.EquipmentDetailID == EquipmentDetailID &&
                element.MaintenanceTypeID == MaintenanceTypeID
            );

            let TicketNum = 0;
            let ManualTicketNum = 0;
            EquipmentObj.map((obj) => {
              const {
                EquipmentCode,
                FromDate,
                Resistance,
                Wye,  
                Power,
                VRY,
                VYB,
                VBR,
                RemarksByEngineer,
                RemarksByTechnician,
              } = obj;
              const shortMonth = moment(FromDate).format("MMM");
              const LongDate = moment(FromDate).format("DD.MM.yyyy");
              let monthBreakDownList = StationWiseBreakdown.filter(
                (element) =>
                  element.EquipmentDetailID == EquipmentDetailID &&
                  new Date(FromDate).getMonth() ==
                    new Date(element.BreakdownDate).getMonth()
              );
              let ManualMonthBreakDownList = ManualStationWiseBreakdown.filter(
                (element) =>
                  element.EquipmentDetailID == EquipmentDetailID &&
                  new Date(FromDate).getMonth() ==
                    new Date(element.BreakdownDate).getMonth()
              );

              worksheet.addRow([
                EquipmentCode,
                shortMonth,
                `PM Completed in ${LongDate}`,
                Resistance,
                Wye,
                Power,
                VRY,
                VYB,
                VBR,
                RemarksByTechnician,
                RemarksByEngineer,
              ]);

              TicketNum += monthBreakDownList.length;

              monthBreakDownList.map((ele) => {
                const { TicketNo, RemarksByTechnician, RemarksByEngineer } =
                  ele;
                worksheet.addRow([
                  EquipmentCode,
                  "SystemBreakdown",
                  TicketNo,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  RemarksByTechnician,
                  RemarksByEngineer,
                ]);
              });

              ManualTicketNum += monthBreakDownList.length;

              ManualMonthBreakDownList.map((ele) => {
                const { TicketNo, RemarksByTechnician, RemarksByEngineer } =
                  ele;
                worksheet.addRow([
                  EquipmentCode,
                  "ManualBreakdown",
                  TicketNo,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  RemarksByTechnician,
                  RemarksByEngineer,
                ]);
              });
            });

            console.log(startCell, EquipmentObj.length + TicketNum);
            if (EquipmentObj.length > 0) {
              MergeColum(
                worksheet,
                `A${startCell}:A${
                  startCell + (EquipmentObj.length + TicketNum) - 1
                }`
              );
            }
          });
          worksheet.addRow([]);
        });

        const getMaxWidth = (col) => {
          const column = worksheet.getColumn(col);
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
              // Skip the first row
              const cellValue = cell.value ? cell.value.toString() : "";
              maxLength = Math.max(maxLength, cellValue.length);
            }
          });
          return maxLength;
        };

        // Set the width for each column based on the maximum width of the headers
        worksheet.columns.forEach((column, index) => {
          if (index > 0) {
            const maxWidth = getMaxWidth(index + 1);
            column.width = maxWidth < 10 ? 10 : maxWidth;
          } // Set a minimum width of 10
        });

        MergeColum(worksheet, "A1:K1");
      });

      // var range = worksheet.  // Specify the range you want to autofit

      //range.format.autofitColumns();

      const buffer = await Workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Over All History Report - ${EquipmentShortName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      showWarningMsg("Sorry! No job was completed with your selected equipment.");
    }
  };

  const GetReport = async () => {
    const { Stations, EquipmentID, Year } = DropdownModel;
    if (Stations?.length && EquipmentID?.value && Year) {
      let StationsIds = Stations.map((ele) => ele.value).join(",");
      await Report_OverAllHistoryReport(StationsIds, EquipmentID?.value, Year)
        .then((res) => {
          const { breakdownList, periodicList, manualBreakdownList } =
            res.data.data;
          ManipulateData(breakdownList, periodicList, manualBreakdownList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function HeaderComponent(worksheet, StartCellNo = 1, TypeName = "") {
    let Header = [
      {
        textName: "     Equipment Name       ",
        mergeCells: `A${StartCellNo + 1}:A${StartCellNo + 3}`,
      },
      {
        textName: TypeName,
        mergeCells: `B${StartCellNo + 1}:B${StartCellNo + 3}`,
      },
      {
        textName: "Scope of works/Description of fault",
        mergeCells: `C${StartCellNo + 1}:C${StartCellNo + 3}`,
      },
      {
        textName: "Parameters",
        mergeCells: `D${StartCellNo + 1}:I${StartCellNo + 1}`,
      },
      {
        textName: "Amps",
        mergeCells: `D${StartCellNo + 2}:F${StartCellNo + 2}`,
      },
      {
        textName: "Voltage",
        mergeCells: `G${StartCellNo + 2}:I${StartCellNo + 2}`,
      },
      {
        textName: "R",
        mergeCells: `D${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "Y",
        mergeCells: `E${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "B",
        mergeCells: `F${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "R-Y",
        mergeCells: `G${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "Y-B",
        mergeCells: `H${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "B-R",
        mergeCells: `I${StartCellNo + 3}`,
        isMergeNeed: false,
      },
      {
        textName: "Technician Remarks",
        mergeCells: `J${StartCellNo + 1}:J${StartCellNo + 3}`,
      },
      {
        textName: "Engineer / Maintainer Remarks",
        mergeCells: `K${StartCellNo + 1}:K${StartCellNo + 3}`,
      },
    ];

    Header.forEach((ele) => {
      const { mergeCells, textName, isMergeNeed } = ele;
      MergeColumWithValue(worksheet, mergeCells, textName, isMergeNeed);
    });
  }

  const MergeColumWithValue = (Ws, cells, value = "", MergeNeed = true) => {
    let desiredCell = Ws.getCell(cells);

    if (MergeNeed) {
      Ws.mergeCells(cells);
    }
    desiredCell.alignment = { horizontal: "center", vertical: "middle" };
    desiredCell.font = { bold: true };
    desiredCell.value = value;
  };

  const MergeColum = (Ws, cells) => {
    let desiredCell = Ws.getCell(cells);
    Ws.mergeCells(cells);
    desiredCell.alignment = { horizontal: "center", vertical: "middle" };
  };

  const GetDropDownList = async () => {
    let stationList = Decrypt(getCookies(COOKIES.Stations));
    let Stations = [];

    if (stationList?.length) {
      await Report_OverAllHistoryDropDown()
        .then((res) => {
          if (res?.data?.data?.length) {
            stationList.map((data) => {
              Stations.push({ value: data.StationID, label: data.StationCode });
            });

            setDropdownModel((pre) => ({
              ...pre,
              StationList: Stations,
            }));

            handleGlobalStationName(Stations[0].key);

            setDropdownModel((pre) => ({
              ...pre,
              Stations: [Stations[0]],
            }));

            let StationID = Stations[0]?.value ?? 0;
            const { data } = res.data;
            let EquipmentList = data.filter((ele) => {
              return ele.StationID == StationID;
            });

            setDropdownModel((pre) => ({
              ...pre,
              FilterEquipmentList: data,
              EquipmentList,
              EquipmentID: EquipmentList?.[0] ?? {},
            }));
          }
        })
        .catch((err) => console);
    }
  };

  const CommonHandleChange = (event, cusName) => {
    if (cusName) {
      setDropdownModel((pre) => ({
        ...pre,
        [cusName]: event,
      }));

      if ("Stations" == cusName) {
        let values = event?.map((ele) => ele.value);
        if (values?.length) {
          let EquipmentList = DropdownModel.FilterEquipmentList?.filter((ele) =>
            values.includes(ele.StationID)
          );
          EquipmentList = makeUnique(EquipmentList, ["label", "value"]);
          setDropdownModel((pre) => ({
            ...pre,
            EquipmentList,
          }));
        } else {
          setDropdownModel((pre) => ({
            ...pre,
            EquipmentList: [],
            EquipmentID: {},
          }));
        }
      }
    } else {
      const { name, value } = event.target;

      setDropdownModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  return (
    <div>
      <PageHeaderTitle title={"History Card Report"}></PageHeaderTitle>
      <div className="row">
        <div className="col-2">
          <label className="form-check-label">Stations</label>
          <ReactMultiSelect
            options={DropdownModel.StationList}
            isMulti
            allowSelectAll
            placeholder={"Stations"}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => CommonHandleChange(e, "Stations")}
            value={DropdownModel.Stations}
          ></ReactMultiSelect>
        </div>
        <div className="col-2">
          <label className="form-check-label">Equipment </label>
          <ReactMultiSelect
            options={DropdownModel.EquipmentList}
            placeholder={"Equipment"}
            onChange={(e) => CommonHandleChange(e, "EquipmentID")}
            value={DropdownModel.EquipmentID}
          ></ReactMultiSelect>
        </div>
        <div className="col-2">
          <label className="form-check-label">Year </label>
          <FilterDropdown
            code={"Year"}
            name={"Year"}
            onChange={(e) => CommonHandleChange(e)}
            value={DropdownModel?.Year}
          ></FilterDropdown>
        </div>

        <div className="col-2 align-content-end">
          {" "}
          <button
            className="btn btn-secondary"
            title="Download"
            onClick={GetReport}
          >
            <MdFileDownload /> Download{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
