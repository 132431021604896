import React from "react";

export const WithSelectDropDown = ({
  data,
  placeholder,
  name,
  value,
  reg,
  initialVal,
  disabled,
  HandleChange,
  className,
  defaultValue
}) => {
  return (
    <select
    className={`form-select ${className}`}

      name={name}
      value={value}
    defaultValue={defaultValue}
      ref={reg}
      id={name}
      disabled={disabled}
      onChange={HandleChange}
    >
      <option  selected value={initialVal != null ? initialVal : ""} >
        {placeholder}
      </option>
      {data != null && data.length > 0 ? (
        data.map((v, i) => {
          return (
            <option key={i} value={v.value}>
              {v.key}
            </option>
          );
        })
      ) : (
        <></>
      )}
    </select>
  );
};
