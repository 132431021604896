import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { Link } from "react-router-dom";
import makeAnimated from "react-select/animated";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router-dom";
import Global from "../../services/Global";
import {
  Decrypt,
  GetLoginUserID,
  BreakdownDepartmentReportDownload,
} from "../../services/CommonService";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const _breakdownService = new BreakdownMaintenanceService();
const animatedComponents = makeAnimated();
const obj = new BreakdownMaintenanceService();

const DepartmentcolumnNames = [
  { title: "S.No", data: "RowID" },
  { title: "Department Name", data: "Department" },
  { title: "Station", data: "StationCode" },
  { title: "Raised By", data: "CyclicCheckRequestedBy" },
  { title: "Request Approved By", data: "CyclicCheckApprovedBy" },
  { title: "Problem Description", data: "Component" },
  { title: "Equipment Type", data: "SystemTypeName" },
  { title: "Status", data: "StatusName" },
  { title: "Remarks", data: "RejectedRemark" },

  { title: "No of Equipments Verified", data: "No_Of_EquipmentsVerified" },
  {
    title: "No of Equipments Not Verified",
    data: "No_Of_EquipmentsNotVerified",
  },

];

export const CyclicCheckReport = () => {
  const { getCookies, handleGlobalStationName } = useContext(AppContext);

  const [fromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [StatusDropDown, setStatusDropDown] = useState([]);

  const [statusId, setStatusId] = useState(null);
  const [StationList, setStationList] = useState([]);
  const [FilterStationList, setFilterStationList] = useState([]);
  const [EquipmentTagList, setEquipmentTagList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [FilterEquipmentTagList, setFilterEquipmentTagList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [EquipmentIds, SetEquipmentIds] = useState([]);
  const [EquipmentId, SetEquipmentId] = useState([]);
  const [DepartmentIds, SetDepartmentIds] = useState("");

  const [showButton, setShowButton] = useState(false);
  const [stationIdss, setStataionIds] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;
      if (val.isBack == "True") {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, []);

  useEffect(() => {
    getStationList();
    GetStatus();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    setFromDate(firstDay.toISOString().split("T")[0]);
    setToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const getStationList = async () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode, Type : data.Type});
    });
    HandleChange([dataLst[0]]);

    await get_equipment_list(dataLst[0]);
    setStationList(dataLst);
    setFilterStationList(dataLst)
  };

  const get_equipment_list = async (data) => {
    var employeeID = GetLoginUserID();
    await obj
      .BreakdownMaintenance_ReportDropDown(employeeID)
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentTagList, departmentList } = res.data.data;
          setDepartmentList(departmentList);
          const filterEquipmentTagList = equipmentTagList.filter(
            (x) => x.StationID == data.value
          );
          setEquipmentTagList(filterEquipmentTagList);
          setFilterEquipmentTagList(equipmentTagList);
        }
      })
      .catch((err) => console.log(err));
  };

  const HandleChange = (e, type = "STATION") => {
    if (type == "STATION") {
      SetStationID(e);
      let StationLabel = e.map((x) => x.label).join();
      handleGlobalStationName(StationLabel);

      const Equipment_list = e
        ?.map((item) =>
          FilterEquipmentTagList?.filter((tag) => tag.StationID === item.value)
        )
        .flat();
      setEquipmentTagList([...Equipment_list]);
    } else if (type == "STATUS") {
      setStatusId(e.target.value);
    } else if (type == "EQUIPMENT") {
      SetEquipmentIds(e.target.value);
    } else if (type == "Department") {
      const {value } = e.target;
      SetDepartmentIds(value);
      let obj = DepartmentList?.find(ele => ele.value == value)
      let StationList = FilterStationList?.filter((ele) => ele.Type == obj.Type);
      setStationList(StationList ?? [])
      if(StationList?.length){
        SetStationID([StationList[0]])
      }else{
        SetStationID([])
      }
    }
  };

  const onFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const onToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const onclear = () => {
    $("#FromDate").val(null);
    $("#ToDate").val(null);
    setFromDate("");
    setToDate("");
  };

  const GetStatus = () => {
    _breakdownService.GetStatusList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        var status = [];
        console.log(res.data);
        res.data.map((v) => {
          status.push({ key: v.statusName, value: v.statusID });
        });
        setStatusDropDown(status);
      }
    });
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");

    setStataionIds(ID);

    // GetFault(ID);
  }, [fromDate, ToDate, StationID, EquipmentIds, statusId]);

  // console.log(EquipmentIds,statusId)

  return (
    <div className="m-1">
      <Header
        title={"Cyclic Check Report"}
        stationchange={HandleChange}
        EquipmentTagChange={HandleChange}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        stationList={StationList}
        EquipmentTagList={EquipmentTagList}
        DepartmentList={DepartmentList}
        stationIDs={StationID}
        EquipmentId={EquipmentId}
        statusId={statusId}
        EquipmentIds={EquipmentIds}
        DepartmentID={DepartmentIds}
        fromDate={fromDate}
        ToDate={ToDate}
        StatusDropDown={StatusDropDown}
        onclear={onclear}
        showButton={showButton}
      />
      <div className="card card-body pt-0">
        <DataTableReportAjax
          columnList={DepartmentcolumnNames}
          id={"FaultTables"}
          title={"Cyclic Check Report"}
          isAsc={true}
          FromDate={fromDate}
          ToDate={ToDate}
          url={`${BASE_URL}CyclicCheck/Report_CyclicCheckReportDetails`}
          StationID={stationIdss ? stationIdss : "0"}
          EquipmentCode={EquipmentIds ? EquipmentIds : "0"}
          DepartmentID={DepartmentIds ? DepartmentIds : "0"}
          BreakdownStatus={statusId ? statusId : "0"}
          FileNameNoDate={true}
          // landscape
          exportOptions={{
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }}
        />
      </div>
    </div>
  );
};

const Header = ({
  title,
  onFromDateChange,
  stationList,
  EquipmentTagList,
  DepartmentList,
  stationIDs,
  stationchange,
  EquipmentTagChange,
  onToDateChange,
  DepartmentID,
  fromDate,
  ToDate,
  StatusDropDown,
  showButton,
  EquipmentId,
}) => {
  useEffect(() => {
    $(function () {
      var lastDay = new Date();

      if (fromDate != null) {
        $("#ToDate").attr("min", fromDate);
        $("#ToDate").attr("max", lastDay.toISOString().split("T")[0]);
      }
      if (ToDate != null) {
        $("#FromDate").attr("max", ToDate);
      }
    });
  }, [fromDate, ToDate]);
  return (
    <div className="page-header mb-3">
      <div className="row justify-content-end">
        <div className="col-12">
          <h3 className="page-title">{title}</h3>
          {showButton && (
            <>
              <Link
                to="/dashboard"
                className="btn btn-primary"
                state={{ Type: "1" }}
              >
                <i class="las la-chevron-left"></i>{" "}
                <span> Back to Dashboard</span>{" "}
              </Link>
            </>
          )}
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <label className="label">Department</label>
            <WithSelectDropDown
              placeholder={"Select Department"}
              name={"DepartmentID"}
              data={DepartmentList}
              value={DepartmentID}
              initialVal={0}
              HandleChange={(e) => {
                EquipmentTagChange(e, "Department");
              }}
            />
          </div>
          <div className="col-2 hide">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              defaultValue={fromDate}
              onChange={(e) => {
                onFromDateChange(e);
              }}
            />
          </div>
          <div className="col-2 hide">
            <label className="label" for="">
              To Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              defaultValue={ToDate}
              id="ToDate"
              onChange={(e) => {
                onToDateChange(e);
              }}
            />
          </div>

          <div className="col-2">
            <label className="label">Station/Section</label>
            <ReactMultiSelect
              options={stationList}
              isMulti
              value={stationIDs}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => stationchange(e, "STATION")}
              allowSelectAll={true}
            />
          </div>
          <div className="col-2 visually-hidden">
            <label className="label">Equipment Tag No</label>

            <WithSelectDropDown
              placeholder={"Select Equipment Tag No"}
              name={"equipment_code"}
              data={EquipmentTagList}
              initialVal={0}
              HandleChange={(e) => {
                EquipmentTagChange(e, "EQUIPMENT");
              }}
            />
          </div>

          <div className="col-2 visually-hidden">
            <label className="label">Status</label>

            <WithSelectDropDown
              placeholder={"Select Status"}
              name={"statuscode"}
              data={StatusDropDown}
              initialVal={0}
              HandleChange={(e) => {
                EquipmentTagChange(e, "STATUS");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export const AuditingReportDownload = (unorderedData) => {


  const orderedKeys = [
    "S.No",
    "Ticket No",
    "Station/Section Code",
    "Department Name",
    "Date of Action",
    "Category of Work",
    "User Level",
    "Remarks",
    "Status",
    "Downloads",
  ];

  const data = unorderedData.map((item) => {
    const orderedItem = {};
    orderedKeys.forEach((key) => {
      orderedItem[key] = item[key];
    });
    return orderedItem;
  });

  data.map((ele, index) => (ele["S.No"] = index + 1));

  let sheetName = data[0]["Ticket No"];
  sheetName = sheetName?.replaceAll("/", "_");
  sheetName = sheetName?.replaceAll("\\", "_");

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);


  // Set column headers and apply the cell style
  worksheet.addRow(orderedKeys);
  worksheet.getRow(1).eachCell((cell) => {
    cell.style = {
      font: { bold: true },
      fill: {
        type: "pattern",
        pattern: "solid",

        fgColor: { argb: "C6CFEF" }, // Yellow fill color
      },
      alignment: { shrinkToFit: true, horizontal: "right" },
    };
  });

  orderedKeys.forEach((key, index) => {
    const column = worksheet.getColumn(index + 1);
    if (index + 1 == 13) {
      column.width = key.length + 10;
    } else {
      column.width = key.length + 5;
    } // Adjust the width as needed
  });

  // Set text wrap for all columns
  worksheet.columns.forEach((column) => {
    column.alignment = { wrapText: true };
  });

  // Add the data to the worksheet
  data.forEach((item) => {
    worksheet.addRow(orderedKeys.map((key) => item[key]));
  });

  const downloadsColumn = worksheet.getColumn(orderedKeys.length);
  downloadsColumn.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
    if (rowNumber > 1) {
      // Skip header row
      const downloadUrl = cell.value;
      const displayText = "Download";
      if (cell.value !== "No Attachment Found") {
        cell.value = {
          formula: `HYPERLINK("${downloadUrl}", "${displayText}")`,
        };
      }

    }
  });

  // Create a buffer to store the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, sheetName);
  });
};
