import React, { useEffect, useState } from "react";
import { FilterDropdown } from "../../components/FilterDropdown";
import { MdFileDownload } from "react-icons/md";
import ExcelJS from "exceljs";
import BillingSheetServices from "./BillingSheetServices";
import { Link } from "react-router-dom";

function BillingSheetReportExl() {
  const [Year, setYear] = useState(new Date().getFullYear());
  const [Quarter, setQuarter] = useState("Nov-Jan(Q1)");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const _billServc = new BillingSheetServices();
  const borderStyles = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  useEffect(() => {}, [dateRange]);

  const handleQuarterChange = (event) => {
    const selectedQuarter = event.target.value;
    setQuarter(selectedQuarter);
    updateDateRange(selectedQuarter, Year);
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    updateDateRange(Quarter, selectedYear);
  };

  const updateDateRange = (quarter, year) => {
    let fromDate = "";
    let toDate = "";

    switch (quarter) {
      case "Nov-Jan(Q1)":
        fromDate = `${year}-11-01`;
        toDate = `${parseInt(year) + 1}-01-31`;
        break;
      case "Feb-Apr(Q2)":
        fromDate = `${year}-02-01`;
        toDate = `${year}-04-30`;
        break;
      case "May-Jul(Q3)":
        fromDate = `${year}-05-01`;
        toDate = `${year}-07-31`;
        break;
      case "Aug-Oct(Q4)":
        fromDate = `${year}-08-01`;
        toDate = `${year}-10-31`;
        break;
      default:
        break;
    }

    setDateRange({ fromDate, toDate });
  };

  const ExcelFormat = async (billingData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      `${Quarter} (${dateRange.fromDate} to ${dateRange.toDate})`
    );

    const fileHeader = `A1:R1`;
    MergeColumWithValue(
      worksheet,
      fileHeader,
      "COMPREHENSIVE MAINTENANCE PAYMENT WORKOUT SHEET",
      true
    );
    worksheet.getRow(1).height = 30;
    const startRow = 4;
    const fixedHeight = 30;
    for (
      let rowNumber = startRow;
      rowNumber <= worksheet.rowCount;
      rowNumber++
    ) {
      worksheet.getRow(rowNumber).height = fixedHeight;
    }
    HeaderComponent(worksheet);

    const totalAmount = billingData.reduce((sum, item) => {
      if (item.EquipmentShortName === "OTHERS") {
        return sum - (item.Amount || 0);
      }
      return sum + (item.Amount || 0);
    }, 0);

    billingData.splice(0, 0, {
      DisplayName: "Preventive Maintenance for VAC System",
      isCustomize: true,
      cellsName: `E4:M4`,
    });
    billingData.splice(7, 0, {
      DisplayName: "Preventive Maintenance for TVS Systems ",
      isCustomize: true,
      cellsName: `E11:M11`,
    });
    billingData.splice(13, 0, {
      DisplayName: "History Cards for VAC System",
      isCustomize: true,
      cellsName: `E17:M17`,
    });
    debugger;
    billingData.splice(19, 0, {
      SerialNo: "",
      CheckListNumber: "",
      CheckListDescription: "TOTAL",
      STC: "",
      STR: "",
      SWA: "",
      TotalNo: "",
      TotalCompletedChecklist: "",
      MaxMarksWeightage: "",
      MarksObtained: "",
      Amount: totalAmount,
      MaxAmount: "",
      RateAsPerContract: "",
      VendorRemarks: "",
      CMRLRemarks: "",
    });
    billingData.splice(20);

    billingData.forEach((item) => {
      const {
        SerialNo,
        CheckListNumber,
        CheckListDescription,
        STC,
        STR,
        SWA,
        TotalNo,
        TotalCompletedChecklist,
        MaxMarksWeightage,
        MarksObtained,
        Amount,
        MaxAmount,
        RateAsPerContract,
        VendorRemarks,
        CMRLRemarks,
        DisplayName,
        isCustomize,
        cellsName,
      } = item;

      // TotalNo's Count
      let stationCount = 0;
      if (STC) stationCount++;
      if (STR) stationCount++;
      if (SWA) stationCount++;

      //TotalCompletedChecklist Count
      let completedCount = 0;
      if (STC === "C") completedCount++;
      if (STR === "C") completedCount++;
      if (SWA === "C") completedCount++;

      if (isCustomize) {
        MergeColumWithValue(worksheet, cellsName, DisplayName);
      } else {
        let row = worksheet.addRow([
          SerialNo,
          CheckListNumber,
          "",
          "",
          CheckListDescription,
          CheckListDescription === "TOTAL" ? "" : "QUARTERLY",
          STC,
          STR,
          SWA,
          CheckListDescription === "TOTAL" ? "" : stationCount,
          CheckListDescription === "TOTAL" ? "" : completedCount,
          MaxMarksWeightage === ""
            ? ""
            : parseFloat(MaxMarksWeightage || 0).toFixed(2),
          MarksObtained === "" ? "" : parseFloat(MarksObtained || 0).toFixed(2),
          Amount,
          MaxAmount,
          RateAsPerContract,
          VendorRemarks,
          CMRLRemarks,
        ]);
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          if (colNumber === 5) {
            cell.alignment = { vertical: "middle", horizontal: "left" }; 
          } else {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          }

          cell.border = borderStyles;
        });
      }
    });

    let cell =
      Number(worksheet?.lastRow?.getCell(1).address.replace("A", "")) ?? 1;
    // don't change the text format
    let footerText = `THE ABOVE SAID SEGREGATION OF WORKS  WITH RESPECT TO PREVENTIVE MAINTENANCE AND CORRECTIVE MAINTENANCE CHECKLISTS FOR VARIOUS VAC & TVS EQUIPMENTS PERFORMED FOR WHICH WEIGHTAGE HAS BEEN PROVIDED, HAS BEEN DULY ACCEPTED & AGREED BY M/s VOLTAS FOR PAYMENT PURPOSES.

STERLING & WILSON OPN & CAMC - MAINTENANCE MANAGER                                                                                                                                  STERLING & WILSON MANAGER (CAMC)                                                              `;


    MergeColumWithValue(
      worksheet,
      `D4:D19`,
      "80 % of Total CAMC Contract Value",
      true
    );
    MergeColumWithValue(
      worksheet,
      `D20:D20`,
      "20 % of Total CAMC Contract Value",
      true
    );
    MergeColumWithValue(
      worksheet,
      `A${cell + 1}:P${cell + 4}`,
      footerText,
      true
    );

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Billing Sheet - ${Quarter} (${dateRange.fromDate} to ${dateRange.toDate})`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  function HeaderComponent(worksheet, StartCellNo = 1) {
    let Header = [
      {
        textName: "S.No",
        mergeCells: `A${StartCellNo + 1}:A${StartCellNo + 2}`,
        width: 5,
      },
      {
        textName: "CHECKLIST NUMBER",
        mergeCells: `B${StartCellNo + 1}:B${StartCellNo + 2}`,
        width: 20,
      },
      {
        textName: "PERIOD",
        mergeCells: `C${StartCellNo + 1}:C${StartCellNo + 2}`,
        width: 15,
      },
      {
        textName: "Weightage Percentage of Payment",
        mergeCells: `D${StartCellNo + 1}:D${StartCellNo + 2}`,
        width: 20,
      },
      {
        textName: "CHECKLIST DESCRIPTION",
        mergeCells: `E${StartCellNo + 1}:E${StartCellNo + 2}`,
        width: 50,
        alignment: { horizontal: "left" },
      },
      {
        textName: "MAINTENANCE PERIODICITY",
        mergeCells: `F${StartCellNo + 1}:F${StartCellNo + 2}`,
        width: 25,
      },
      {
        textName: "STATION NAMES",
        mergeCells: `G${StartCellNo + 1}:I${StartCellNo + 1}`,
        width: 21,
      },
      {
        textName: "STC",
        mergeCells: `G${StartCellNo + 2}`,
        isMergeNeed: false,
        width: 7,
      },
      {
        textName: "STR",
        mergeCells: `H${StartCellNo + 2}`,
        isMergeNeed: false,
        width: 7,
      },
      {
        textName: "SWA",
        mergeCells: `I${StartCellNo + 2}`,
        isMergeNeed: false,
        width: 7,
      },
      {
        textName: "Total No's",
        mergeCells: `J${StartCellNo + 1}:J${StartCellNo + 2}`,
        width: 15,
      },
      {
        textName: "Total Completed Checklist",
        mergeCells: `K${StartCellNo + 1}:K${StartCellNo + 2}`,
        width: 25,
      },
      {
        textName: "Max Marks Weightage (%)",
        mergeCells: `L${StartCellNo + 1}:L${StartCellNo + 2}`,
        width: 20,
      },
      {
        textName: "Marks Obtained",
        mergeCells: `M${StartCellNo + 1}:M${StartCellNo + 2}`,
        width: 15,
      },
      {
        textName: "Amount",
        mergeCells: `N${StartCellNo + 1}:N${StartCellNo + 2}`,
        width: 15,
      },
      {
        textName: "Max Amount (Rs)",
        mergeCells: `O${StartCellNo + 1}:O${StartCellNo + 2}`,
        width: 20,
      },
      {
        textName: "Rate as per Contract (Rs)",
        mergeCells: `P${StartCellNo + 1}:P${StartCellNo + 2}`,
        width: 25,
      },
      {
        textName: "Remarks of Vendor-L1",
        mergeCells: `Q${StartCellNo + 1}:Q${StartCellNo + 2}`,
        width: 25,
      },
      {
        textName: "Remarks of CMRL-L2",
        mergeCells: `R${StartCellNo + 1}:R${StartCellNo + 2}`,
        width: 25,
      },
    ];

    Header.forEach((ele, index) => {
      const { mergeCells, textName, isMergeNeed, width } = ele;
      MergeColumWithValue(worksheet, mergeCells, textName, isMergeNeed);
      worksheet.getColumn(index + 1).width = width || 15;
    });
  }

  const MergeColumWithValue = (
    worksheet,
    cells,
    value = "",
    mergeNeed = true
  ) => {
    const desiredCell = worksheet.getCell(cells);

    if (mergeNeed) {
      worksheet.mergeCells(cells);
    }
    desiredCell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    desiredCell.font = { bold: true };
    desiredCell.value = value;
    desiredCell.border = borderStyles;
  };

  const GetReport = async () => {
    await BillingSheetDetails();
  };
  const BillingSheetDetails = async () => {
    debugger;
    var FromDate = dateRange.fromDate || `${Year}-11-01`;
    var ToDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;

    await _billServc
      .BillingSheet_ReportDetails(FromDate, ToDate)
      .then((res) => {
        const billingData = res?.data?.data || [];
        ExcelFormat(billingData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="row">
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-6">
            <h3 className="page-title">Billing Sheet Report</h3>
          </div>
          <div className="col-6" style={{ width: "11%" }}>
            <Link to="/BillingSheet">
              {" "}
              <button className="btn btn-secondary">Go Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <label className="form-check-label">Select</label>
          <div>
            <select
              onChange={handleQuarterChange}
              value={Quarter}
              className="form-select"
            >
              <option>Nov-Jan(Q1)</option>
              <option>Feb-Apr(Q2)</option>
              <option>May-Jul(Q3)</option>
              <option>Aug-Oct(Q4)</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-check-label">Year</label>
          <FilterDropdown
            code={"Year"}
            name={"Year"}
            onChange={(e) => handleYearChange(e.target.value)}
            value={Year}
          />
        </div>
        <div className="col-2 align-content-end">
          <button
            className="btn btn-secondary"
            title="Download"
            onClick={GetReport}
          >
            <MdFileDownload /> Download
          </button>
        </div>
      </div>
    </div>
  );
}

export default BillingSheetReportExl;
