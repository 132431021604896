import React, { useContext, useEffect, useState } from "react";
import { PerformanceEvaluation_GetStatusUsingID } from "./PerformanceEvaluationService";
import { MakeSingleValueUnique } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import { useNavigate } from "react-router-dom";

const QuarterTiles = () => {
  const navigate = useNavigate();

  const { setPerformanceDropdownList, PerformanceDropdownList } =
    useContext(AppContext);
  const { StationID, CurrentYear } = PerformanceDropdownList;

  const DateObj = {
    0: {
      DateText: "(Nov - Jan)",
      StartDate: `${CurrentYear - 1}-11-01`,
    },
    1: {
      DateText: "(Feb - Apr)",
      StartDate: `${CurrentYear}-02-01`,
    },
    2: {
      DateText: "(May - July)",
      StartDate: `${CurrentYear}-05-01`,
    },
    3: {
      DateText: "(Aug - Oct)",
      StartDate: `${CurrentYear}-08-01`,
    },
  };

  const [ScheduleList, setScheduleList] = useState([]);

  useEffect(() => {
    getEquipmentStatus();
    return () => {
      setScheduleList([]);
    };
  }, [StationID, CurrentYear]);



  





  const getEquipmentStatus = async () => {
    if (StationID > 0 && CurrentYear > 0) {
      await PerformanceEvaluation_GetStatusUsingID(StationID, CurrentYear).then(
        (res) => {
          const Data = res?.data?.data;
          if (Data?.length) {
            const uniqueStatus = MakeSingleValueUnique(Data, "ScheduleName");
            let catagoriesSchedule = [];
            uniqueStatus.forEach((ele) => {
              catagoriesSchedule.push(
                Data.filter((el) => el.ScheduleName == ele)
              );
            });

            setScheduleList(catagoriesSchedule);
          }
        }
      );
    }
  };

  const Status = {
    Pending: "text-warning",
    Open: "text-primary",
    Attended: "text-secondary",
    Rejected: "text-danger",
    Approved: "text-success",
  };

  const Tiles = ({ Title = "", subTitle = "", data = [], date = "" }) => {
    
    function drag(ev) {
      ev.dataTransfer.setData("text", ev.target.id);
    }


    return (
      <div
        className="col-md-12 col-lg-6 col-xl-3 d-flex"
        draggable
        ondragstart={drag}
        onClick={() => {
          setPerformanceDropdownList((pre) => ({
            ...pre,
            StartDate: date,
          }));
          navigate("/PerformanceEvaluation");
        }}
      >
      
        <div className="card flex-fill"  >
          <div className="card-body ">
            <div className="statistics">
              <div className="row">
                <div className="col-md-12 col-12 text-center">
                  <div className="stats-box mb-4">
                    <h3>{Title}</h3>
                    <h5>{subTitle}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {data.map((ele) => {
                const { StatusName, EquipmentShortName } = ele;
                return (
                  <p>
                    <i
                      className={`fa fa-dot-circle-o btn-rounded ${Status[StatusName]} me-2`}
                    ></i>
                    {EquipmentShortName}{" "}
                    
                    <span className="float-end"> {StatusName}</span>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row">
        {ScheduleList?.map((ele, index) => {
          const { ScheduleName } = ele[0];
          const { DateText, StartDate } = DateObj[index];

          return (
            <Tiles
              Title={ScheduleName}
              data={ele}
              subTitle={DateText}
              date={StartDate}
            ></Tiles>
          );
        })}
      </div>
    </div>
  );
};

export default QuarterTiles;
