import React from "react";
import { ToastContainer, toast } from "react-toastify";

// for Save
const SAVE_SUCCESS = "Saved Successfully .";
const SAVE_ERROR = "Failed to save .";

export const showSaveSuccess = () => toast.success(SAVE_SUCCESS);
export const showSaveError = () => toast.error(SAVE_ERROR);

//for Delete
const DELETE_SUCCESS = "Deleted Successfully .";
const DELETE_ERROR = "Failed to delete .";

export const showDeleteSuccess = () => toast.success(DELETE_SUCCESS);
export const showDeleteError = () => toast.error(DELETE_ERROR);

//for others
export const showSuccessMsg = (msg) => toast.success(msg);
export const showErrorMsg = (msg = "Something went wrong! Please try again .") => toast.error(msg);

//warning
export const showWarningMsg = (msg) => toast.warning(msg);

export const ToastMsg = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
