module.exports = {
  COOKIES: {
    Jwt: "JWT",
    MyJwt: "MyJwt",
    PeriodicStationID: "PeriodicStationID",
    IOTParams: "IOT",
    UserName: "username",
    Password: "drowssap",
    modulePrivileage: "modulePrivileage",
    EmployeeID: "EmployeeID",
    CalendarMonth: "CalendarMonth",
    UserID: "UserID",
    UserDetails: "UserDetails",
    UserGoodID: "UserGoodID",
    Privilege: "Privilege",
    IsAuth: "IsAuth",
    EnggRoleID: "d8582569-e548-49b3-9e4a-10a7032aa6da",
    TechRoleID: "5f124936-521e-4815-b488-5015ebccaa78",
    L0RoleID: "5e1cd87d-6b3c-4fd8-847d-3f314538dc6f",
    L1RoleID: "b16baa31-9333-46ae-bd87-606422e28f18",
    L2RoleID: "4bdb1c0b-9b3f-4513-a8dd-34181701e300",
    L3RoleID: "d8582569-e548-49b3-9e4a-10a7032aa6da",
    L4RoleID: "afdcb4ad-44e8-44d2-ac05-0482b15538c3",
    AMCVendorTechnician: "5e1cd87d-6b3c-4fd8-847d-3f314538dc6f",

    AdminRoleID: "4bdb1c0b-9b3f-4513-a8dd-34181701e300",
    SuperAdminRoleID: "b16baa31-9333-46ae-bd87-606422e28f18",
    OperationRoleID: "5e1cd87d-6b3c-4fd8-847d-3f314538dc6f",
    MaintainerRolID: "afdcb4ad-44e8-44d2-ac05-0482b15538c3",
    EnCryptKey: "1)@g*8$K4!~&96u#eDt5%3lT8@sN^6Ea",
    IV: "$8fP&@!9(2Go$Q9W",
    FlutterEncryptKey: "rfejghortigfjr9eifjo8aurjewruei3",
    FlutterIV: "dsjft47ry3hfew98",
    Stations: "Station",
    CorridorChangePrivilege: "CorridorChangePrivilege",
    GLOBAL_STATION: "GLOBAL_STATION",
    DepartmentStations : "DepartmentStations",
    corridorID: "corridorID",
    ID: "ID",
    DepartmentID: "DepartmentID",
    Role: 'Role',
    UserRoleID: 'UserRoleID',
    l0RoleID: 'l0RoleID',
    Departments: 'Departments',
    IsTvsvac: 'IsTvsvac',
  },
  C1_BLUE_COLOR: {
    main_color: "#667eea",
    main_color_hover: "#482fe9",
    sidebar_back_color: "#f4f4f4",
    notification_color: "#7460ee",
    header_linear_color: "#764ba2",
    body_background_color: "#f7f7f7",
  },
  C2_GREEN_LINE: {
    main_color: "#4ba28e",
    main_color_hover: "#52a44f",
    sidebar_back_color: "#dee9de",
    notification_color: "#7460ee",
    header_linear_color: "#52a44f",
    body_background_color: "#f7f7f7",
  },
  STAND_BY: "Stand By",
  MaintenanceTypes: {
    month: 1,
    monthly: 2,
    quaterly: 3,
    halfYearly: 4,
    yearly: 5,
  },
  MaintenanceDuration: {
    month: {
      addCount: 1,
      DurationType: "d",
    },
    monthly: {
      addCount: 7,
      DurationType: "d",
    },
    quaterly: {
      addCount: 3,
      DurationType: "7",
    },
    halfYearly: {
      addCount: 6,
      DurationType: "M",
    },
    yearly: {
      addCount: 8,
      DurationType: "M",
    },
  },
  Roles: {
    LO_RoleId: "5sdiorjsiosjeriojahyudhasdui",
  },
};
