import React from "react";
import CyclicCheckList from "./CyclicCheckList";
import { CyclicCheckProvider } from "./CyclicCheckContext";

function CyclicCheckIndex() {
  return (
    <CyclicCheckProvider>
      <CyclicCheckList></CyclicCheckList>
    </CyclicCheckProvider>
  );
}

export default CyclicCheckIndex;
