import React from "react";
import { TabBar } from "../../components/TabBar";
import PageHeaderTitle from "../../components/PageHeaderTitle";
import { SaveParameterEntry } from "./SaveParameterEntry";
import ManualParameterEntryService from "./ManualParameterEntryService";
import $ from "jquery";
import { useState, useEffect, useReducer, useContext } from "react";
import { GetLoginUserID, getYMDdate } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import moment from "moment/moment";
import { getPrivilege } from "../../services/CommonService";
import swal from "sweetalert";
import { Decrypt } from "../../services/CommonService";
import Global from "../../services/Global";

const Obj = new ManualParameterEntryService();
export const ManualParameterEntry = () => {
  const [ParameterList, setParameterList] = useState({
    YearlyParam: [],
    HalfYearlyParam: [],
    QuartlyParam: [],
    MonthlyParam: [],
    DailyParam: [],
  });
  const [EquipmentTagList, setEquipmentTagList] = useState({
    DailyEquip: [],
    MonthlyEquip: [],
    QuartlyEquip: [],
    HalfYearlyEquip: [],
    YearlyEquip: [],
  });
  const [StationList, setStationList] = useState([]);
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [CleartabRefresh, setCleartabRefresh] = useReducer((x) => x + 1, 0);
  const [TableRowList, setTableRowList] = useState([]);
  const [Manualtype, SetManualtype] = useState(33);
  const [ManualPointEntryModel, setManualPointEntryModel] = useState([]);
  const [TableRefresh, setTableRefresh] = useState(0);

  const { getCookies, setCookies, showLoading, hideLoading } =
    useContext(AppContext);

  const TabHeader = [
    "Daily",
    "Monthly",
    "Quarterly",
    // "Half yearly",
    // "Yearly",
  ];
  const SavePointEntry = (data) => {
    Obj.ManualPointEntry_SaveManualPoint((res) => {}).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    Obj.ManualPointEntry_GetDropdownDate()
      .then((res) => {
        const { data } = res.data;

        if (data[1] != null && data[1].length > 0) {
          const temp = EquipmentTagList;
          temp.DailyEquip = data[1].filter((x) => x.StatusID == 33);
          temp.MonthlyEquip = data[1].filter((x) => x.StatusID == 34);
          temp.QuartlyEquip = data[1].filter((x) => x.StatusID == 35);
          temp.HalfYearlyEquip = data[1].filter((x) => x.StatusID == 36);
          temp.YearlyEquip = data[1].filter((x) => x.StatusID == 37);

          setEquipmentTagList({ ...temp });
        }

        if (data[2] != null && data[2].length > 0) {
          var DailyParam = data[2].filter((x) => x.StatusID == 33);
          var MonthlyParam = data[2].filter((x) => x.StatusID == 34);
          var QuartlyParam = data[2].filter((x) => x.StatusID == 35);
          var HalfYearlyParam = data[2].filter((x) => x.StatusID == 36);
          var YearlyParam = data[2].filter((x) => x.StatusID == 37);

          setParameterList({
            DailyParam: DailyParam,
            MonthlyParam: MonthlyParam,
            QuartlyParam: QuartlyParam,
            HalfYearlyParam: HalfYearlyParam,
            YearlyParam: YearlyParam,
          });
        }
        var stations = Decrypt(getCookies(Global.COOKIES.Stations));
        var stationsdropdown = [];

        stations.map((data) => {
          stationsdropdown.push({
            value: data.StationID,
            key: data.StationCode,
          });
        });
        setStationList(stationsdropdown);
        // setStationList(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const TableRef = (value) => {
    setTableRefresh(value);
  };

  useEffect(() => {
    getUsingID(0, 0, false);
  }, [TableRefresh, Manualtype]);

  const getUsingID = (ManualParameterId, IsDeleted, SINGLE) => {
    var employeeID = GetLoginUserID();
    showLoading();

    Obj.ManualPointEntry_GetManualPointDetailesUsingID(
      ManualParameterId,
      Manualtype,
      IsDeleted,
      employeeID
    )
      .then((res) => {
        const { data } = res.data;

        if (data != null && data.length > 0 && data[0].length > 0) {
          if (SINGLE) {
            hideLoading();
            const val = data[0];
            if (Manualtype == 33) {
              val[0].PointDate1 = val[0].PointDate;
              var time = new Date(val[0].PointDate);
              val[0].PointTime = moment(time).format("HH:mm");
              setManualPointEntryModel(val[0]);
            } else {
              setManualPointEntryModel(val[0]);
            }
          } else {
            const val = data[0];
            var rowList = [];
            val.map((x) => {
              var row = [];
              row.push(x.ManualParameterID);
              row.push(x.StationCode);
              row.push(x.PointDate);
              row.push(x.EquipmentCode);
              row.push(x.ParameterName);
              row.push(x.PointValue);
              row.push(x.Remarks);
              row.push("");
              rowList.push(row);
            });

            setTableRowList([...rowList]);

            hideLoading();
          }
        } else setTableRowList([]);

        hideLoading();
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };

  const columnNames = [
    { title: "Manual pointID", visible: false },
    { title: "Station Code" },
    { title: "Date and Time" },
    { title: "Equipment Tag Number" },
    { title: "Parameter" },
    { title: "Actual Value" },
    { title: "Remarks" },
    {
      title: "Submit  ",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "",
          d = "";

        if (getPrivilege().includes("MANUALPARAMETERENTRYEDIT")) {
          b = $(`<button class='btn icon-btn text-center' title='Edit'>
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
            getUsingID(rowData[0], 0, true);

            setRefresh();
            window.scrollTo(0, 0);
            // SetIsView(true)
            // EditBreakdown(rowData[0], rowData[8], rowData[9]);
          });
        }

        if (getPrivilege().includes("MANUALPARAMETERENTRYDELETE")) {
          d = $(`<button class='btn icon-btn text-center'  title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
              </button>`).on("click", function () {
            swal({
              title: "Are you sure?",
              text: "Do you want to delete this configuration?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                getUsingID(rowData[0], 1, false);
              }
            });
          });
        }

        $(td).html(b);
        $(td).append(d);
      },
    },
  ];

  const TabBody = [
    <SaveParameterEntry
      ManualPointList={TableRowList}
      CleartabRefresh={CleartabRefresh}
      TableRef={TableRef}
      Refresh={Refresh}
      columnNames={columnNames}
      TableName="DailyTable"
      ParameterModel={ManualPointEntryModel}
      ManualType="33"
      SavePointEntry={SavePointEntry}
      StationList={StationList}
      EquipmentTagList={EquipmentTagList.DailyEquip}
      ParameterList={ParameterList.DailyParam}
    />,
    <SaveParameterEntry
      ManualPointList={TableRowList}
      CleartabRefresh={CleartabRefresh}
      TableRef={TableRef}
      Refresh={Refresh}
      columnNames={columnNames}
      TableName="MonthlyTable"
      ParameterModel={ManualPointEntryModel}
      ManualType="34"
      SavePointEntry={SavePointEntry}
      StationList={StationList}
      EquipmentTagList={EquipmentTagList.MonthlyEquip}
      ParameterList={ParameterList.MonthlyParam}
    />,
    <SaveParameterEntry
      ManualPointList={TableRowList}
      CleartabRefresh={CleartabRefresh}
      TableRef={TableRef}
      Refresh={Refresh}
      columnNames={columnNames}
      TableName="QuaterlyTable"
      ParameterModel={ManualPointEntryModel}
      ManualType="35"
      SavePointEntry={SavePointEntry}
      StationList={StationList}
      EquipmentTagList={EquipmentTagList.QuartlyEquip}
      ParameterList={ParameterList.QuartlyParam}
    />,
    // <SaveParameterEntry ManualPointList={TableRowList} CleartabRefresh={CleartabRefresh} TableRef={TableRef} Refresh={Refresh} columnNames={columnNames} TableName="HalfyearlyTable" ParameterModel={ManualPointEntryModel} ManualType="36" SavePointEntry={SavePointEntry} StationList={StationList} EquipmentTagList={EquipmentTagList.HalfYearlyEquip} ParameterList={ParameterList.HalfYearlyParam} />,
    // <SaveParameterEntry ManualPointList={TableRowList} CleartabRefresh={CleartabRefresh} TableRef={TableRef} Refresh={Refresh} columnNames={columnNames} TableName="YearlyTable" ParameterModel={ManualPointEntryModel} ManualType="37" SavePointEntry={SavePointEntry} StationList={StationList} EquipmentTagList={EquipmentTagList.YearlyEquip} ParameterList={ParameterList.YearlyParam} />
  ];

  const onTabChange = (e) => {
    setTableRefresh(0);
    switch (e) {
      case 1:
        SetManualtype(33);

        break;
      case 2:
        SetManualtype(34);

        break;
      case 3:
        SetManualtype(35);

        break;
      case 4:
        SetManualtype(36);

        break;
      case 5:
        SetManualtype(37);

        break;

      default:
    }
    setCleartabRefresh();
  };

  return (
    <div>
      <>
        <PageHeaderTitle title={"Manual Parameter Entry"} />
        <div className="card">
          <TabBar header={TabHeader} body={TabBody} onTabChange={onTabChange} />
        </div>
      </>
    </div>
  );
};
