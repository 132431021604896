import React, { useEffect, useContext } from "react";
import { Calendar, Navigate, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Decrypt,
  getDDMMYYdate,

} from "../../services/CommonService";
import PropTypes from "prop-types";
import { useMemo } from "react";
import QuarterTiles from "./QuarterTiles";
import { Dropdown } from "../../components";
import { COOKIES } from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";

export default function PerformanceEvaluationLandingPage() {


  const {getCookies,PerformanceDropdownList,handleGlobalStationName,setPerformanceDropdownList} = useContext(AppContext);

  const { StationID, StationList } = PerformanceDropdownList


  function PerformanceEvaluationCalenderView({

    ...props
  }) {
    return <QuarterTiles ></QuarterTiles>;
  }

  PerformanceEvaluationCalenderView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  PerformanceEvaluationCalenderView.title = (date) => {
    return getDDMMYYdate(date);
  };

  PerformanceEvaluationCalenderView.navigate = (date, action, { localizer }) => {
    let returnDate = date;
    let IncrementNumber = 1;
    switch (action) {
      case Navigate.PREVIOUS:
        returnDate = localizer.add(date, -IncrementNumber, "year");
        break;
      case Navigate.NEXT:
        returnDate = localizer.add(date, IncrementNumber, "year");
        break;
      default:
        returnDate = new Date();
        break;
    }
    
    setPerformanceDropdownList((pre) => ({
      ...pre,
      CurrentYear : new Date(returnDate).getFullYear()
    }))
    return returnDate;
  };

  useEffect(() => {
    initDropDown();
  }, []);




  const initDropDown = async () => {
    const userStationsList = Decrypt(getCookies(COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
        return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
        return { value: data.StationID, key: data.StationCode };
    });
    let initialStationID = FinalStationList[0]?.value ?? 0;
    if(StationID > 0){
      initialStationID = StationID;
    }
    
    setPerformanceDropdownList((pre) => ({
      ...pre,
      StationList : FinalStationList,
      StationID : initialStationID,
      CurrentYear : new Date().getFullYear()
      
    }))

    handleGlobalStationName(FinalStationList[0]?.key ?? "");
}






  const localizer = momentLocalizer(moment);

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: {
        day: PerformanceEvaluationCalenderView,
      },
    }),
    []
  );

  const MyToolbar = (toolbar) => {
    const goToDate = (Type) => {
      toolbar.onNavigate(Type);
    };

    return (
      <>
        <div class="rbc-toolbar">
          <span class="rbc-btn-group">
            
            <button type="button" onClick={() => goToDate("PREV")}>
              Back
            </button>
            <button type="button" onClick={() => goToDate("CUSTOM_TODAY")}>
              Today
            </button>
            <button type="button" onClick={() => goToDate("NEXT")}>
              Next
            </button>
          </span>
          <span className="rbc-toolbar-label">
            { moment(toolbar.date).format("YYYY") }
          </span>

          <div className="col-md-2">
                        <div className="form-group">
                            
                            <Dropdown
                                data={StationList}
                                placeholder="Station"
                                name={"StationID"}
                                HandleChange={(e) => setPerformanceDropdownList((pre) => ({
                                  ...pre,
                                  StationID : e.target.value
                                }))}
                                value={StationID}
                                className=""
                            />
                        </div>
                    </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="card height600"
      style={{ position: "relative", top: "-10px" }}
    >
      <div className="card-body">
        <Calendar
          defaultDate={defaultDate}
          defaultView={"day"}
          events={[]}
          components={{
            toolbar: MyToolbar,
          }}
          selectable
          localizer={localizer}
          views={views}
        />
      </div>
    </div>
  );
}
