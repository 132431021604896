import Axios from "../../services/AxiosService";

const axios = new Axios();

const openstreetmapApiCall = (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    return fetch(apiUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.display_name) {
                
                return data;
            } else {
                throw new Error('No address found');
            }
        })
        .catch(error => {
            console.error('Error converting coordinates to address:', error);
            throw error; // re-throw the error for the caller to handle
        });
};


export {
    openstreetmapApiCall
}