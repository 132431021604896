import React from "react";
import { useState, useEffect, useContext } from "react";
import { showSaveError, showDeleteSuccess } from "../../components/ToastMsg";
import { getPrivilege } from "../../services/CommonService";
import { HtmlTable } from "../../components/HtmlTable";
import makeAnimated from "react-select/animated";

import $ from "jquery";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";

import AddRevenueConfig from "./AddRevenueConfig";
import RevenueConfigService from "./RevenueConfigService";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import swal from "sweetalert";

import { Decrypt } from "../../services/CommonService";

const nonrevenueService = new RevenueConfigService();
const animatedComponents = makeAnimated();

export default function RevenueConfiguration() {
  const [revenueHoursID, setRevenueHourID] = useState(0);
  const [equipmentId, setEquipmentID] = useState(0);
  const [station, setStation] = useState(0);

  const [Refresh, setRefresh] = useState(0);
  const [StationList, setStationList] = useState([]);
  const [StationListModel, setStationListModel] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [equipmetList, setEquipmentList] = useState([]);
  const [systemTypeID, setSystemTypeID] = useState(0);

  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);

  useEffect(() => {
    getStationList();
  }, []);

  const GetData = (stationIds) => {
    showLoading();
    if (stationIds != null && stationIds != "") {
      nonrevenueService
        .NonRevenue_GetEquipmentList(stationIds)
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            var rowList = [];

            setEquipmentList([...response.data]);
          } else {
            setEquipmentList([]);
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } else {
      setEquipmentList([]);
      hideLoading();
    }
  };
  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];
    var dataLst2 = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
      dataLst2.push({ value: data.StationID, key: data.StationCode });
    });
    setStationList(dataLst);
    setStationListModel(dataLst2);
    HandleChange([dataLst[0]]);
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");
    GetData(ID);
  }, [StationID]);

  const HandleChange = (e) => {
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
    SetStationID(e);
  };

  const AddNewConfig = () => {
    setEquipmentID(0);
    setStation(0);
    setRevenueHourID(0);
    setSystemTypeID(0);
  };

  const EditNonRevenue = (rowData) => {
    // console.log(rowData);

    setRevenueHourID(rowData.RevenueHourID);
    setSystemTypeID(rowData.SystemTypeID);
    setStation(rowData.StationID);
    setEquipmentID(rowData.EquipmentID);
  };

  const closepopup = () => {
    setEquipmentID(0);
    setStation(0);
    setSystemTypeID(0);
    setRevenueHourID(0);

    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");
    GetData(ID);
  };
  const handleRefresh = () => {
    var count = Refresh;
    setRefresh(++count);
  };

  const onDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        nonrevenueService
          .NonRevenue_DeleteMasterRevenue(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              var stationIds = StationID.map((x) => {
                return x.value;
              });
              var ID = stationIds.join(",");
              GetData(ID);
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const columnNames = [
    { title: "RevenueHourID", visible: false, data: "RevenueHourID" },
    { title: "SystemTypeID", visible: false, data: "SystemTypeID" },
    { title: "StationID", visible: false, data: "StationID" },
    { title: "EquipmentID", visible: false, data: "EquipmentID" },
    { title: "Station Code", data: "StationCode" },
    { title: "Equipment Name", data: "EquipmentShortName" },
    { title: "Equipment Tag No", data: "EquipmentCode" },
    { title: "Start Time", data: "FromTime" },
    { title: "End Time", data: "EndTime" },
    { title: "Is Shutdown", data: "IsShutdown" },
    { title: "Remark", data: "Remark" },
    {
      title: "Submit",
      data: "RevenueHourID",
      visible:
        getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
        getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        if (getPrivilege().includes("NONREVENUECONFIGURATIONEDIT")) {
          b =
            $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#timebutton" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
              EditNonRevenue(rowData);
            });
        }
        if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
                    <i class='fa fa-trash m-l-15'></i>
                  </button>`).on("click", function () {
            onDeleteClick(Number(rowData.RevenueHourID));
          });
        }
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  const Header = ({ title, stationList, stationIDs, stationchange }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-md-6 ">
            <h3 className="page-title">{title}</h3>
          </div>
          <div className="col-md-4 ">
            {/* <label className="label">Stations</label> */}

            <ReactMultiSelect
              options={stationList}
              isMulti
              value={stationIDs}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => stationchange(e)}
              allowSelectAll={true}
            />
          </div>

          {getPrivilege().includes("NONREVENUECONFIGURATIONCREATE") && (
            <div className="col-md-2 ">
              <button
                type="submit"
                className="btn add-btn mb-2"
                id="timeconfigbutton"
                onClick={() => AddNewConfig()}
                data-bs-toggle="modal"
                data-bs-target="#timebutton"
              >
                <i className="fa fa-plus"></i> Add
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <Header
        title={"Non-Revenue / Maintenance Hour Configuration"}
        stationList={StationList}
        stationIDs={StationID}
        stationchange={HandleChange}
      />

      <AddRevenueConfig
        setRevenueHourID={setRevenueHourID}
        systemTypeID={systemTypeID}
        revenueHourID={revenueHoursID}
        equipmentID={equipmentId}
        station={station}
        handleRefresh={() => handleRefresh()}
        closepopup={() => closepopup()}
        StationList={StationListModel}
      />
      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={equipmetList}
            id={"TimeTable"}
          />
        </div>
      </div>
    </>
  );
}
