import { HtmlTable } from "../../components/HtmlTable";
import { BootrapModel } from "../../components";
import $ from "jquery";
import { useEffect, useState, useContext, useReducer } from "react";
import {
  CyclicCheckApprover_GetList,
  CyclicCheck_DeleteByID,
} from "./CyclicCheckApproverServices";
import swal from "sweetalert";
import CyclicCheckApproverCreate from "./CyclicCheckApproverCreate";
// import CyclicCheckCreate from "./CyclicCheckCreate";
import { showSaveSuccess, showErrorMsg } from "../../components";
import { GetLoginUserID } from "../../services/CommonService";
import { CyclicCheckApproverContext } from "./CyclicCheckApproverContext";
import { MasterConfig_GetDepartmentList } from "../master/MasterDetailService";
import { BuildTreeUsingParentID } from "../../services/CommonService";
import { CyclicCheck_DeleteCyclicCheckId } from "./CyclicCheckApproverServices";
import { GenerateYearForReactSelect } from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";

export default function CyclicCheckApproverList() {
  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);
  const { setDropdownModel, DropDownInitialState } = useContext(
    CyclicCheckApproverContext
  );
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [Refresh, setRefresh] = useState(0);
  const [CyclicCheckId, setCyclicCheckId] = useState(0);
  const [GridModel, setGridModel] = useState([]);
  useEffect(() => {
    initGrid();
    GetDepartmentList();
    return () => {
      setDropdownModel(DropDownInitialState);
    };
  }, [Refresh, GridRender]);

  const initGrid = async () => {
    showLoading();
    var USserID = GetLoginUserID();
    await CyclicCheckApprover_GetList(USserID).then((res) => {
      if (res != null && res.data.data.Table != null) {
        setGridModel([...res.data.data.Table]);
      } else {
        setGridModel([]);
      }
      hideLoading();
    });
  };

  const GetDepartmentList = async () => {
    await MasterConfig_GetDepartmentList()
      .then((res) => {
        if (res?.data?.data?.length) {
          let treeJSON = BuildTreeUsingParentID(res.data.data);
          let Data = JSON.parse(JSON.stringify(treeJSON, null, 2));
          Data.forEach((ele) => (ele.isOpen = true));
          let YearList = GenerateYearForReactSelect();
          setDropdownModel((pre) => ({
            ...pre,
            DepartmentList: Data,
            YearList: YearList,
          }));
        }
      })
      .catch((err) => console.log(err));
  };


  const columnNames = [
    {
      title: "CyclicCheckId",
      visible: false,
      data: "CyclicCheckId",
    },

    { title: "Department Name", data: "DepartmentName" },
    {
      title: "Station/Section", data: "StationCode", createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(`<p class='Eclispedot' title=${cellData}>${cellData}</p>`);
      },
    },
    { title: "Location Name", data: "LocationName" },
    { title: "Equipment Type", data: "SystemTypeName" },
    { title: "Equipment", data: "EquipmentShortName" },
    { title: "Approval From", data: "PrefixName" },
    { title: "Cycle Check", data: "SubModuleDescription" },
    { title: "WorkDescription", data: "WorkDescription" },
    { title: "Remarks", data: "Remarks" },
    {
      title: " Status",
      data: "StatusName",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = GetCycleCheckStatus(cellData);
        $(td).html(b);
      },
    },

    {
      title: "Action",
      data: "CyclicCheckId",
      createdCell: (td, cellData, rowData, row, col) => {

        var b = "";
        var c = "";
        if (rowData.Status == 118) {
          b =
            $(`<button class='btn icon-btn' title='Approve/ Reject' data-bs-toggle="modal" data-bs-target="#AuditingLevelConfig" >
                  <i class='fa fa-check m-r-5'></i>
                </button>`).on("click", function () {
              HandleClickCreateBtn(rowData);
            });
        }

        $(td).html(b);

      },
    },
  ];

  const Header = ({ title }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-md-6 ">
            <h3 className="page-title">{title}</h3>
          </div>

        </div>
      </div>
    );
  };

  const HandleClickCreateBtn = (id) => {
    setCyclicCheckId(id.CyclicCheckId);

    PageRender();
  };

  return (
    <div>
      <Header title={"Cyclic Check Approver"} />

      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={GridModel}
            id={"ConfigureList"}
          />
        </div>
      </div>

      <BootrapModel
        Id={"AuditingLevelConfig"}
        CloseID="AuditingLevelConfigurationClose"
        ModelTitle={`Cyclic Check Approver`}
        modelSize={"modal-xl"}
        module={
          <CyclicCheckApproverCreate
            CyclicCheckId={CyclicCheckId}
            Render={Render}
            setGridRender={setGridRender}
          />
        }
      />
    </div>
  );
}

export const GetCycleCheckStatus = (value) => {
  var id = value;
  var name = value;

  if (id == "Assigned") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-info"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Open") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-primary"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Attended") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-secondary" ></i> ${name}
      </a>
    </div>`;
  } else if (id == "Completed") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-success"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Approved") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-success"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Request") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-warning"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Waitting for Engineer") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-warning"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Closed") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-muted"></i> ${name}
      </a>
    </div>`;
  } else if (id == "Rejected") {
    return `<div class="action-label">
      <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
        <i class="fa fa-dot-circle-o text-danger"></i> ${name}
      </a>
    </div>`;
  }
};
