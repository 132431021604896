import React, { useRef } from 'react'
import { useEffect, useState, useContext } from 'react';
import StackedChart from '../../components/charts/StackedChart'
import DashboardService from './DashboardService';
import { PeriodicService } from '../periodic/PeriodicService';
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { WithoutSelectDropDown } from '../../components/WithoutSelectDropDown';
import { PieChart } from '../../components/charts/PieChart';
import $ from "jquery";
import { FilterDropdown } from '../../components/FilterDropdown';
import { GetLoginUserID, getPrivilege, Decrypt, ShortMonths,
   Generateyears, QuarterlyMonths, HalfMonths, PeriodicEquipmentColor,
   remarksAndDescriptionDownload,getFirstDayOfMonth, getYMDdate} from "../../services/CommonService";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { ReactMultiSelect, MultiValue, Option } from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { BootrapModel } from '../../components';
import { BASE_URL } from "../../env";
import { DataTableReportAjax } from '../../components/DataTableReportAjax';
import { BarChart } from '../../components/charts/Barchart';


const Obj = new DashboardService();
const periodicObj = new PeriodicService();
const animatedComponents = makeAnimated();
export default function Periodic({ TabID, TabValue }) {


  const [OpenList, setOpenList] = useState([]);
  const [ClosedList, setClosedList] = useState([]);
  const [PendingList, setPendingList] = useState([]);
  const [ApprovedList, setApprovedList] = useState([]);
  const [RejectedList, setRejectedList] = useState([]);
  const StationWiseRef = useRef()

  // const [Filter, setRejectedList] = useState([]);

  const [StationWiseData, setStationWiseData] = useState({
 DataSets : [],   
StationCodes : [],
EquipmentIDs : []
  });
  const [equipmentShortName, setequipmentShortName] = useState([]);

  const [FilterModel, setFilterModel] = useState({});
  const [IndividualChartModel, setIndividualChartModel] = useState({
    data : [],
    label : ""
  });
  const [PiedataList, setPiedataList] = useState([]);
  const { showLoading, hideLoading,handleGlobalStationName,getCookies } = useContext(AppContext);
  const [sum, setSum] = useState(0);

  useEffect(() => {


    if (TabID == TabValue) {
      
      const { StationID, Frequency } = FilterModel;
      
      let filterStationIDs  = StationID.split(",").map(Number);


      var stationList = Decrypt(getCookies(Global.COOKIES.Stations))
      let stationCode = stationList.filter((x) => {
        return filterStationIDs.includes(x.StationID)
        }).map((x) => x.StationCode).join()
      handleGlobalStationName(stationCode)



      if (FilterModel && Object.keys(FilterModel).length > 0 && StationID != "") {

        const model = ManipulateObject(FilterModel);
        setFilterModel({...model})

        showLoading();

        Obj.Dashboard_PeriodicStackChart(StationID, Frequency, model.CopyStackFromDate, model.CopyStackToDate).then((res) => {
          if (res?.data) {
            const { openList, closedList, pendingList, approvedList, rejectedList, equipmentShortName,
              stationCodes,stationWiseStatus,equipmentIDs
            
            } = res.data;
            
            let Datasets = [];
            if(stationWiseStatus?.length){
              equipmentShortName.forEach((item,key) => {
                let obj = 
                {
                  label: item,
                  data: stationWiseStatus[key],
                  backgroundColor: PeriodicEquipmentColor[key],
                }
                Datasets.push(obj)
              })
              
              
            }

            const StationWisePeriodicData = {
              DataSets : Datasets,
              StationCodes   :stationCodes   ,
              EquipmentIDs : equipmentIDs   
            }
             setStationWiseData({...StationWisePeriodicData})
            

            setOpenList([...openList]);
            setClosedList([...closedList]);
            setPendingList([...pendingList]);
            setApprovedList([...approvedList]);
            setRejectedList([...rejectedList]);

            const sumArray = [openList, closedList, pendingList, approvedList, rejectedList].reduce((acc, curr) => {
              curr.forEach((num, i) => {
                acc[i] = (acc[i] || 0) + num;
              });
              return acc;
            }, []);


            const sumEquipment = equipmentShortName.map((equip, i) => `${equip} (${sumArray[i]})`);
           

            setequipmentShortName([...sumEquipment]);

            const { pieData } = res.data;
            let sum = pieData.reduce((acc, curr) => acc + curr, 0);
            setSum(sum);
            setPiedataList({ ...pieData });


            hideLoading();
          }
          else{
            setSum(0);
            hideLoading();
            setPiedataList([]);
            setequipmentShortName([]);
            setOpenList([])
              setClosedList([])
              setPendingList([])
              setApprovedList([])
              setRejectedList([])
          }
        }).catch((err) => {
          hideLoading();
          console.log(err);
        });


        // Obj.Dashboard_PeriodicPiechart(StationID, Frequency, model.CopyStackFromDate, model.CopyStackToDate, true).then((res) => {
        //   if (res != null && res.data != null) {
  
        //   }
        // }).catch((err) => {
        //   hideLoading();

        //   console.log(err);
        // });

      }
      else{
        hideLoading();
        setPiedataList([]);
        setOpenList([])
          setClosedList([])
          setPendingList([])
          setApprovedList([])
          setRejectedList([])
      }
    }


  }, [JSON.stringify(FilterModel), TabValue])


  // StationID : 1,
  // Frequency : 1,
  // StackFromDate : new Date().toJSON().slice(0,10).replace(/-/g,'-'),
  // StackToDate :"",
  // DateRange : "",
  // Year : new Date().getFullYear()


  // useEffect(() => {

  //   if (TabID == TabValue) {
  //     const { StationID, Frequency, StackFromDate, StackToDate } = PieFilterModel;

  //     if (PieFilterModel && Object.keys(PieFilterModel).length > 0) {
  //       const model = ManipulateObject(PieFilterModel);

  //       Obj.Dashboard_PeriodicPiechart(StationID, Frequency, model.CopyStackFromDate, model.CopyStackToDate, true).then((res) => {
  //         if (res != null && res.data != null) {
  //           const { pieData } = res.data;
  //           setPiedataList({ ...pieData });
  //         }
  //       }).catch((err) => {

  //         console.log(err);
  //       });

  //     }
  //   }

  // }, [JSON.stringify(PieFilterModel), TabValue])

  function ManipulateObject(Obj1) {

    var Obj = {...Obj1};

    const year = parseInt(Obj.Year);
    const Month = parseInt(Obj.DateRange);

    if (Obj.Frequency == 1) {
      if (Obj.StackToDate != "") {
        Obj.CopyStackFromDate = Obj.StackFromDate;
        Obj.CopyStackToDate = Obj.StackToDate;

      }
      else {
        Obj.CopyStackFromDate = Obj.StackFromDate;
        Obj.CopyStackToDate = Obj.StackFromDate;
      }
    }
    else if (Obj.Frequency == 2) {
      Obj.CopyStackFromDate = new Date(year, Month, 1, 6).toJSON().slice(0, 10).replace(/-/g, '-');
      Obj.CopyStackToDate = new Date(year, Month + 1, 0, 8).toJSON().slice(0, 10).replace(/-/g, '-');
    }
    else if (Obj.Frequency == 3) {
      Obj.CopyStackFromDate = `${Obj.Year}-${Obj.DateRange}-01`;
      var nextMonth = Month + 2;
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6).toJSON().slice(0, 10).replace(/-/g, '-');
    }
    else if (Obj.Frequency == 4) {
      Obj.CopyStackFromDate = `${Obj.Year}-${Obj.DateRange}-01`;
      var nextMonth = Month + 5;
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6).toJSON().slice(0, 10).replace(/-/g, '-');
    }
    else if (Obj.Frequency == 5) {
      Obj.CopyStackFromDate = `${Obj.Year}-01-01`;
      Obj.CopyStackToDate = new Date(year, 12, 0, 6).toJSON().slice(0, 10).replace(/-/g, '-');
    }
    return Obj;
  };




  const HandleChange = (e) => {
    
    setFilterModel({ ...e });
  }




  // var kje = ["#667eea", "navy", "#6c757d", "#55ce63", "#dc3545"];
  const pidata = {
    labels: [
      'Open',
      'Pending',
      'Closed',
      'Approved',
      'Rejected',
    ],
   


    datasets: [{
      data: PiedataList,
      backgroundColor: ["#667eea", "#ffc107", "#6c757d", "#55ce63", "#dc3545"],
      hoverOffset: 4
    }]
  };


  const StackedChartData = {
    labels: equipmentShortName,
    datasets: [

      {
        label: 'Open',
        data: OpenList,
        backgroundColor: "  #667eea",
      },
      {
        label: 'Pending',
        data: PendingList,
        backgroundColor: "#ffc107",
      },
      {
        label: 'Closed',
        data: ClosedList,
        backgroundColor: "#6c757d",
      },
      {
        label: 'Approved',
        data: ApprovedList,
        backgroundColor: "#55ce63",
      },
      {
        label: 'Rejected',
        data: RejectedList,
        backgroundColor: "#dc3545",
      },

    ],
  }


  const StackedChartDataForStationWise = {
    labels: StationWiseData.StationCodes,
    EquipmentID : StationWiseData.EquipmentIDs,
    datasets: StationWiseData.DataSets ?? []
  }

  const IndividualEquipment = {
    labels: [
      'Open',
      'Pending',
      'Closed',
      'Approved',
      'Rejected',
    ],
    datasets: [{
      data: IndividualChartModel.data ,
      label : IndividualChartModel.label,
      backgroundColor: ["#667eea", "#ffc107", "#6c757d", "#55ce63", "#dc3545"],
      hoverOffset: 4
    }]
  }

  const StatckedBarStationWiseBarClick = (event,chartElements) => {
    
    
      
    
      if (chartElements.length > 0) {
        const element = chartElements[0];
        const datasetIndex = element.datasetIndex;
        const index = element.index;
  
        const label = StackedChartDataForStationWise.labels[index];
        let StationID = 0
        var stationList = Decrypt(getCookies(Global.COOKIES.Stations))
        if(stationList?.length){
          StationID = stationList.find((x) => x.StationCode == label).StationID ?? 0
        }


        const EquipmentID = StackedChartDataForStationWise.EquipmentID[datasetIndex]
        // const EquipmentName = StackedChartDataForStationWise.datasets[datasetIndex].labels

        Obj.Dashboard_PeriodicStackChart(StationID,FilterModel.Frequency, FilterModel.CopyStackFromDate, FilterModel.CopyStackToDate,EquipmentID).then((res) => {
          if (res?.data) {
            const { openList, closedList, pendingList, approvedList, rejectedList, equipmentShortName
            } = res.data;
            
            if(openList?.length){
              let data = openList.concat(pendingList,closedList,approvedList, rejectedList)

              let obj = {data : data,label : equipmentShortName[0]}
              setIndividualChartModel({...obj})
              $("#PriodicStationWiseStackedGraphModelPopupBtn").click()
            }
            

          
          }

        }).catch((err) => {
          hideLoading();
          console.log(err);
        });



        setFilterModel((pre) => ({
          ...pre,
          EquipmentID :  EquipmentID ?? 0,
          ChartStationID : StationID
        }))

      }

  };





  // const DateSelectUsing




  return (
    <>
      <div className=''>
        <div className=''>
          <EqptFilters Filtervalues={HandleChange} FromId={"FromStack"} Todate={"ToStack"} TabValue = {TabValue}   ></EqptFilters>

        </div>
        <div className="row d-flex justify-content-center" style={{minHeight : '300px', alignContent : 'center'}}>
          {sum > 0 &&
            <div className='col-md-4 card-body'>
              <PieChart data={pidata} title={"Total - "+ sum}></PieChart>
            </div>
          }
          
          <div className='col-md-8 card-body'>
            <StackedChart  data = {StackedChartData}  IsShow={equipmentShortName?.length} yLabel={"No of Jobs"} id={"PeriodicStackedChart"} ></StackedChart>
          </div>


          <div className='col-md-12 card-body'>
            <StackedChart  reff={StationWiseRef} BarClick={StatckedBarStationWiseBarClick}  data = {StackedChartDataForStationWise}  IsShow={equipmentShortName?.length} yLabel={"Equipments"} id={"PeriodicStackedChartPeriodicWise"} ></StackedChart>
          </div>

            <BootrapModel Id={"PriodicStationWiseStackedGraphModel"} ModelTitle={""} module={
                <PeriodicReport data={IndividualEquipment} />
            } /> 
            <button hidden 
            id="PriodicStationWiseStackedGraphModelPopupBtn"
            data-bs-toggle="modal"
            data-bs-target="#PriodicStationWiseStackedGraphModel"
            
            /> 
        </div>
      </div>
     
    </>
  )
}


export const EqptFilters = React.memo(({ Filtervalues, FromId, Todate,TabValue }) => {


  const [StationList, setStationList] = useState([]);
  const [DateRange, setDateRange] = useState([]);
  const [ScheduleTypeList, setScheduleTypeList] = useState([]);
  const [IsDaily, setIsDaily] = useState(true);
  const [IsYearly, setIsYearly] = useState(false);
  const [IsOther, setIsOther] = useState(false);
  const { showLoading, hideLoading, getCookies,handleGlobalStationName } = useContext(AppContext);

  const [StationSelectedList, setStationSelectedList] = useState([]);


  const date = new Date();
  const firstDay = getFirstDayOfMonth(
    date.getFullYear(),
    date.getMonth(),
  );


  const [FilterModel, setFilterModel] = useState({
    StationID: '',
    ChartStationID: '',
    Frequency: 1,
    StackFromDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    StackToDate: "",
    DateRange: "",
    Year: new Date().getFullYear()
  });

  const HandleStationChange = (e) => {
    
    var stationIds = e.map((x => {
      return x.value
    }))
    let StationLabel = e.map(x => x.label).join()
    handleGlobalStationName(StationLabel)



    setFilterModel((prevState) => ({
      ...prevState,
      StationID: stationIds.toString(),
    }));

    setStationSelectedList(e);



  }

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations))
    var dataLst = [];
    stationList.map((data) => {

      dataLst.push({ value: data.StationID, label: data.StationCode });

    });
    // stationList.map((data) => {

    //   dataLst.push({ value: data.StationID, key: data.StationCode });

    // });
    setStationList(dataLst);
    HandleStationChange(dataLst);
    // if(dataLst.length > 0){
    //   setFilterModel((prevState) => ({
    //     ...prevState,
    //     StationID: dataLst[0]?.value,
    //   }));
    // }



  };



  useEffect(() => {
    if(TabValue == 3){
    $(`#StackToDate${Todate}`).attr("max", new Date().toJSON().slice(0, 10).replace(/-/g, '-'));
    getStationList();
    GetDropdown();
    }

  }, [TabValue]);
  const RangeObj = {
    2: ShortMonths(),
    3: QuarterlyMonths(),
    4: HalfMonths(),
  }




  const HandleChange = (e) => {
    const { name, value } = e.target;

    setFilterModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    

    if (name == 'Frequency') {
      $(".RangeDate").attr('disabled', true);

      if (Number(value) != 1 && Number(value) != 5) {

        setIsOther(true);
        setIsDaily(false);
        setIsYearly(false);

        const DropDownValue = RangeObj[Number(value)];

        setFilterModel((prevState) => ({
          ...prevState,
          ["DateRange"]: DropDownValue[0].value,
        }));

        setDateRange([...DropDownValue]);
      }
      else if(Number(value) == 5){        
        setIsYearly(true);
        setIsDaily(false);
        setIsOther(false);


      }
      else {
        setIsDaily(true);
        setIsYearly(false);
        setIsOther(false);

        if (Number(value) == 1) {
          $(".RangeDate").attr('disabled', false);
        }
        setDateRange([])
      }
    }

    else if (name == "StackFromDate") {
      if (value != null && value != "") {
        $(`#StackToDate${Todate}`).attr("min", value);
      }
      else {
        $(`#StackToDate${Todate}`).val("")
      }
    }
    else if (name == "StackToDate") {
      if (value != null) {

        $(`#StackFromDate${FromId}`).attr("max", value)
      }
    }

  }

  useEffect(() => {

    Filtervalues({ ...FilterModel });

  }, [JSON.stringify(FilterModel)])




  const GetDropdown = () => {
    periodicObj.Periodic_GetDropList().then((res) => {
      var data = res.data;

      if (data != null) {

        // if (data.stationList != null && data.stationList.length > 0) {
        //   data.stationList.map((x) => x.key = x.label);
        //   setStationList([...data.stationList])
        // }
        if (data.scheduleList != null && data.scheduleList.length > 0) {
          setScheduleTypeList([...data.scheduleList]);
        }
      }
      else{
        setScheduleTypeList([]);
      }

    }).catch((err) => {
      console.log(err);

    })
  }


  return (
    <div className="row d-flex justify-content-center mt-0 ">
      {/* <div className="col-md-2 m-b-20">
        <label className="label" >
          Station Name
        </label>
        <WithoutSelectDropDown
          name={"StationID"}
          placeholder="Select Station"
          HandleChange={HandleChange}
          data={StationList}
          value={FilterModel.StationID}


        />
      </div> */}
      <div className={"col-md-3"}>
        <label className="form-check-label" htmlFor="category1">
          Station
        </label>
        <ReactMultiSelect
          options={StationList}
          isMulti
          value={StationSelectedList}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={(e) => HandleStationChange(e)}
          allowSelectAll={true}

        />
      </div>
      <div className="col-md-3">
        <label className="form-check-label" htmlFor="category1">
          Schedule Type
        </label>
        <WithoutSelectDropDown
          name={"Frequency"}
          placeholder="Select Schedule Type"
          HandleChange={HandleChange}
          data={ScheduleTypeList}
          value={FilterModel.Frequency}
        />
      </div>


      {IsOther &&
        <div className="col-md-3">
          <label className="form-check-label" htmlFor="category1">
            Select
          </label>
          <WithoutSelectDropDown
            name={"DateRange"}
            placeholder="Select Date Range"
            HandleChange={HandleChange}
            data={DateRange}
          />
        </div>
      }


      { (IsOther || IsYearly )?
        <div className="col-md-3">
          <label className="form-check-label" htmlFor="Year">
            Year
          </label>
          <FilterDropdown
            code={'Year'}
            name={"Year"}
            onChange={HandleChange}
            value={FilterModel.Year} />
          {/* <WithoutSelectDropDown
            name={"Year"}
            placeholder="Select Year"
            HandleChange={HandleChange}
            data={Generateyears(5)}
            value={FilterModel.Year}

          /> */}
        </div> : ""
      }

      {IsDaily &&
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              From Date
            </label>
            <input
              className="form-control ManualClearClass RangeDate"
              type="date"
              name='StackFromDate'
              max={FilterModel.StackToDate}
              id={`StackFromDate${FromId}`}
              value={FilterModel.StackFromDate}
              onChange={HandleChange}
            />
          </div>
        </div>
      }
      {IsDaily &&
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              To Date
            </label>
            <input
              className="form-control ManualClearClass RangeDate"
              type="date"
              name='StackToDate'
              id={`StackToDate${Todate}`}
              value={FilterModel.StackToDate}
              min={FilterModel.StackFromDate}
              onChange={HandleChange}
            />
          </div>
        </div>
      }
    </div>
  );
});

export const PeriodicReport = ({data}) => {
  
  const columns = [
    { data: "RowId", title: "Serial No" },
    { data: "StationCode", title: "Station Code" },
    { data: "FromDate", title: "Scheduled Date" },
    { data: "EquipmentShortName", title: "Equipment" },
    { data: "EquipmentCode", title: "Equipment Tag No" },
    { data: "MaintenanceTypeName", title: "Maintenance Type" },
    {
      data: "ApprovedBy",
      title: "Approved / Rejected By",
    },
    {
      data: "JobDoneBy",
      title: "Job Done By",
    },
   
    {
      data: "PWTNumber",
      title: "PTW Number",
    },
    {
      data: "PWTDate",
      title: "PTW Date",
    },
    {
      data: "ClosedDate",
      title: "Closed Date",
    },   
    {
      data: "StatusName",
      title: "Status",
    },
    {
      title: "Download Remarks",
      data: "JobScheduleID",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<button class='btn icon-btn text-center' title='Download Remarks'>
        <i class='fa fa-download m-r-5'></i>
      </button>`).on("click", function () {
        ReportGetJobDescriptionUsingJobID(rowData.JobScheduleID,rowData.EquipmentDetailID,rowData);
      });;
        $(td).html(b);
      },
    } ,
    {
      title: "Download",
      data: "download_url",
      visible: false,
      className : "hide"
    }
  ];






  const ReportGetJobDescriptionUsingJobID = (jobScheduleID = 0, EquipmentDetailID = 0,rowdata) => {
    

    periodicObj.ReportGetJobDescriptionUsingJobID(EquipmentDetailID,jobScheduleID).then((response) => {
      const {data} = response.data
      var jobDescriptionList = []
      if (data?.length > 0) {


        rowdata["Station Code"] = rowdata.StationCode
        rowdata["Equipment Short Name"] = rowdata.EquipmentShortName
        rowdata["Maintenance Type Name"] = rowdata.MaintenanceTypeName
        rowdata["Approved By"] = rowdata.ApprovedBy
        rowdata["Job Done By"] = rowdata.JobDoneBy
        rowdata["Status Name"] = rowdata.StatusName
        rowdata["Closed Date"] = rowdata.ClosedDate
        rowdata["Equipment Code"] = rowdata.EquipmentCode
        
        const res = data.map((x) => {
          var obj = { ...rowdata };
          obj["Job Description"] = x.Description
          obj["Remarks By Engineer"] = x.RemarksByEngineer
          obj["Remarks By Technician"] = x.RemarksByTechncian
          obj["Downloads"] = x.AttachementFlag == 1 ? obj.download_url : "No Attachment Found"
          obj["Scheduled Date"] = obj.FromDate
          jobDescriptionList.push(obj)
          return obj
        })
        
        remarksAndDescriptionDownload(res)  
      }
     

      // saveAs(new Blob([response.data], { type: contentType }), `${file_name}`)
    }).catch((err) => 
    {
      console.log(err)
    })
  };


  
  
  return ( 
  <>
  { data?.labels?.length && 
    <BarChart data={data} clickable={false}  id={"IndividualEquipmentModel"} showLegend yTitle={"Status"}
    
    dataLabelsShow
    > </BarChart>
  }
            </>
  )
          }
