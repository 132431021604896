import React, {
  useEffect
} from "react";
import PropTypes from "prop-types";


import { DailyCustomView } from "./CustomPages/DailyCustomView";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import { COOKIES } from "../../services/Global";
import { prototype } from "jszip";
import { ContextValues } from "./PeriodicContextProvider";
import ErrorBoundary from "../../components/ErrorBoundary";

const localizer = globalizeLocalizer(globalize);

export const CalenderDailyView = () => {
  
  useEffect(() => {
    const buttons = document.querySelectorAll("button"); // Select all buttons

    buttons.forEach((button) => {
      if (button.textContent === "Back") {
        button.style.float = "left"; // Apply your custom styles
        // You can apply any other styles as needed
      }
    });
  }, []);
  
  return (
    <div className="" >
      <Calendar
        localizer={localizer}
        defaultView={"daily"}
        views={{
          daily: DailyCustomView,
        }}
        toolbar={false}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable={true}
      />
    </div>
  );
};

CalenderDailyView.propTypes = {
  date: PropTypes.string,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalenderDailyView.range = (date, { localizer }) => {
  const start = date;
  const end = localizer.add(start, 2, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

// CalenderDailyView.navigate = (date, action, { localizer }) => {


//    const { PeriodicModel} = ContextValues();

//    const { } = PeriodicModel;



//   var d = localStorage.getItem("dailyDate");
//   var dLst = d.split("-");
//   var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
//   switch (action) {
//     case Navigate.PREVIOUS:
//       newD.setDate(newD.getDate() - 1);
//       localStorage.setItem("dailyDate", getYMDdate(newD));
//       return date;
//     // return localizer.add(newD, -1, "day");

//     case Navigate.NEXT:
//       newD.setDate(newD.getDate() + 1);
//       localStorage.setItem("dailyDate", getYMDdate(newD));
//       return date;
//     // return localizer.add(newD, 1, "day");

//     default:
//       return new Date();
//   }
// };
// CalenderDailyView.title = (date, { localizer }) => {
//   var cusDate = date;
//   if (
//     localStorage.getItem("dailyDate") == null ||
//     getYMDdate(cusDate) == getYMDdate(new Date())
//   ) {
//     localStorage.setItem("dailyDate", getYMDdate(cusDate));
//   }

//   var finalDate;
//   if (
//     date.getFullYear() == cusDate.getFullYear() &&
//     date.getMonth() == cusDate.getMonth() &&
//     date.getDate() == cusDate.getDate()
//   ) {
//     finalDate = date;
//   } else {
//     var d = localStorage.getItem("dailyDate");
//     var dLst = d.split("-");
//     var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
//     finalDate = newD;
//   }

//   return finalDate.toDateString();
// };


