import React from "react";

function ListSort({ UnOrderList, setUnOrderList, RowRender, Draggable = false,onChange }) {
  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let _UnOrderList = [...UnOrderList];

    //remove and save the dragged item content
    const draggedItemContent = _UnOrderList.splice(dragItem.current, 1)[0];

    //switch the position
    _UnOrderList.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setUnOrderList(_UnOrderList);
    onChange(_UnOrderList)
  };

  return (
    <div className="app">
      <div className="list-sort">
        {UnOrderList.map((item, index) => (
          <div
          className="row"
             draggable={Draggable}
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            {RowRender(item, index)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListSort;
