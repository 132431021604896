import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class BreakdownMaintenanceService {
  async GetBreakdownMaintenanceList(
    StationID,
    employeeID,
    BreakdownDate = "",
    ticketID = 0
  ) {
    return await axios
      .get(
        `BreakdownMaintenance/GetBreakdownMaintenanceList?stationID=${StationID}&employeeID=${employeeID}&ticketID=${ticketID}&BreakdownDate=${BreakdownDate}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async Breakdown_GetCorridorList(EmployeeID = 0, TicketID = 0) {
    return await axios
      .get(
        `BreakdownMaintenance/Breakdown_GetCorridorList?EmployeeID=${EmployeeID}&TicketID=${TicketID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async Breakdown_GetAssignedToList(EmployeeID = 0, DepartmentID, stationID) {
    return await axios
      .get(
        `BreakdownMaintenance/Breakdown_GetAssignedToList?EmployeeID=${EmployeeID}&DepartmentID=${DepartmentID}&stationID=${stationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async SaveBreakdownDetailes(spareDetailesModel) {
    return await axios
      .post(`BreakdownMaintenance/SaveBreakdownDetailes`, spareDetailesModel)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async DeleteSparePart(spareId, PartId, spareUsedID) {
    return await axios
      .post(
        `BreakdownMaintenance/DeleteSparePart?spareID=${spareId}&PartID=${PartId}&spareUsedID=${spareUsedID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async BreakdownAlarmReport(StartDate, EndDate, StationID) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownAlarmReport?StartDate=${StartDate}&&EndDate=${EndDate}&&StationID=${StationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  async BreakdownReport(StartDate, EndDate, StationID) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownReport?StartDate=${StartDate}&&EndDate=${EndDate}&&StationID=${StationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  async GetStatusList() {
    return await axios
      .get("BreakdownMaintenance/GetStatusList")
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  async Report_HistoryDropDown() {
    return await axios
      .get("BreakdownMaintenance/Report_HistoryDropDown")
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  async BreakdownMaintenance_GetManualTicket(
    StationID,
    employeeID,
    BreakdownDate = ""
  ) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetManualTicket?stationID=${StationID}&&employeeID=${employeeID}&&BreakdownDate=${BreakdownDate}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownMaintenance_GetDepartmentTicket(
    StationID,
    employeeID,
    BreakdownDate = "",
    DepartmentID,
    ISCyclicCheck = 0
  ) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetDepartmentTicket?stationID=${StationID}&&employeeID=${employeeID}&&BreakdownDate=${BreakdownDate}&&DepartmentID=${DepartmentID}&&ISCyclicCheck=${ISCyclicCheck}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownDepartment_GetDepartmentList(EmployeeID) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownDepartment_GetDepartmentList?EmployeeID=${EmployeeID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async BreakdownDepartmentReport_GetDetailesbyTicketID(TicketID) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownDepartmentReport_GetDetailesbyTicketID?TicketID=${TicketID}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  async RCAReport_GetDetailesbyTicketID(ticketId) {
    return await axios
      .get(
        `BreakdownMaintenance/GetRcaReportsByTicketId?TicketId=${ticketId}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  async Report_GetOverAllRemarkForPM(TicketID) {
    return await axios
      .get(`AuditingProcess/Report_GetOverAllRemarkForPM?TicketID=${TicketID}`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }

  async BreakdownMaintenance_GetManualBreakdownDropdownData(stationID = 0) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetManualBreakdownDropdownData?stationID=${stationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownMaintenance_GetDepartmentBreakdownDropdownData() {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetDepartmentBreakdownDropdownData`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownMaintenance_ManualSaveBreakdownDetailes(spareDetailesModel) {
    return await axios
      .post(
        `BreakdownMaintenance/BreakdownMaintenance_ManualSaveBreakdownDetailes`,
        spareDetailesModel
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async Breakdown_DepartmentManualSaveBreakdownDetailes(spareDetailesModel) {
    return await axios
      .post(
        `BreakdownMaintenance/Breakdown_DepartmentManualSaveBreakdownDetailes`,
        spareDetailesModel
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async BreakdownMaintenance_GetManualTicketUsingTicketID(TicketID, IsDeleted) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetManualTicketUsingTicketID?TicketID=${TicketID}&IsDeleted=${IsDeleted}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownMaintenance_GetDepartmentTicketUsingTicketID(
    TicketID,
    IsDeleted,
    EmployeeID = 0
  ) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetDepartmentTicketUsingTicketID?TicketID=${TicketID}&IsDeleted=${IsDeleted}&EmployeeID=${EmployeeID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async BreakdownMaintenance_GetDepartmentEmpList(TicketID, DepartmentID) {
    return await axios
      .get(
        `BreakdownMaintenance/BreakdownMaintenance_GetDepartmentEmpList?TicketID=${TicketID}&DepartmentID=${DepartmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async FileUpload(FileUpload, FileName = "") {
    return await axios
      .post(`BreakdownMaintenance/FileUpload?FileName=${FileName}`, FileUpload)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
  async GetFilesUsingTicketID(TicketID, AttachmetID, flag) {
    return await axios
      .get(
        `BreakdownMaintenance/GetFilesUsingTicketID?ticketID=${TicketID}&attachmentID=${AttachmetID}&flag=${flag}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async fileDownload(data, IsFileAttached = 0, ForShow = false) {
    return await axios.get(
      `BreakdownMaintenance/Download?AttachmentID=${data}&IsFileAttached=${IsFileAttached}&ForShow=${ForShow}`,
      { responseType: "blob" }
    );
  }
  async Report_BreakdownMaintenanceGetManualTicket(
    StartDate,
    EndDate,
    StationID
  ) {
    return await axios
      .get(
        `BreakdownMaintenance/Report_BreakdownMaintenanceGetManualTicket?StartDate=${StartDate}&&EndDate=${EndDate}&&StationID=${StationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      });
  }

  async BreakdownMaintenance_GetDropDownListForSystemTicket() {
    return await axios.get(
      `BreakdownMaintenance/BreakdownMaintenance_GetDropDownListForSystemTicket`
    );
  }

  async GetHistoryReport(dt) {
    dt = {
      length: 10,
      start: 0,
      search: "",
      fromDate: "2023-10-01",
      toDate: "2023-10-16",
      statusID: 1,
      stationID: "1,2,3",
      equipmentID: 1,
      employeeID: 0,
      ticketID: 0,
      breakdownStatus: 0,
      equipmentCode: 0,
    };

    return await axios
      .post(`BreakdownMaintenance/GetHistoryReport`, dt)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async BreakdownMaintenance_ReportDropDown(employeeID) {
    return await axios.get(
      `BreakdownMaintenance/BreakdownMaintenance_ReportDropDown?EmployeeID=${employeeID}`
    );
  }
  async BreakdownMaintenance_GetStationIdUsingTicketNo(type, ticketNo) {
    return await axios.get(
      `BreakdownMaintenance/BreakdownMaintenance_GetStationIdUsingTicketNo?type=${type}&ticketNo=${ticketNo}`
    );
  }

  // RCA report
  async addRCAReport(data) {
      return await axios
        .post(`BreakdownMaintenance/Breakdown_RcaReport`, data)
        .then((response) => {
          if (response != null && response.data != null) {
            return response.data;
          }
          return response;
        })
        .catch((err) => {
          console.error(err);
          return null;
        });
  }




}

export const DownloadForCorrectiveMaintanence = async (Id) => {
  return await axios.get(
    `BreakdownMaintenance/DownloadForCorrectiveMaintanence?TicketID=${Id}`,
    {
      responseType: "blob",
    }
  );
};

export const BreakdownDepartment_GetTicketRemarks = async (Id) => {
  return await axios.get(
    `BreakdownMaintenance/BreakdownDepartment_GetTicketRemarks?TicketID=${Id}`
  );
};

export const Breakdown_GetPredefineDWN = async (
  EquipmentTagNo,
  DepartmentID,
  BreakDownTypeID
) => {
  return await axios.get(
    `BreakdownMaintenance/Breakdown_GetPredefineDWN?EquipmentTagNo=${EquipmentTagNo}&DepartmentID=${DepartmentID}&BreakDownTypeID=${BreakDownTypeID}`
  );
};

export const BreakdownMaintenance_GetParameterListUsingEquipmentDetailID =
  async (EquipmentDetailID) => {
    return await axios.get(
      `BreakdownMaintenance/BreakdownMaintenance_GetParameterListUsingEquipmentDetailID?EquipmentDetailID=${EquipmentDetailID}`
    );
  };
export const BreakdownDepartment_GetDropdownDataUsingID = async (
  stationID,
  departmentID
) => {
  return await axios.get(
    `BreakdownMaintenance/BreakdownDepartment_GetDropdownDataUsingID?stationID=${stationID}&departmentID=${departmentID}`
  );
};
 //get RCA report 

 export const  getRCAReport=async(ticketId)=>{
  return await axios.get(`BreakdownMaintenance/GetRcaReportsByTicketId?TicketId=${ticketId}`) 
}

export const  getRCAReportAllData=async()=>{
return await axios.get(`BreakdownMaintenance/GetAllReports`) 
}

export const  DownloadForRCAReport= async(Files = "")=>{
  return await axios.get(`BreakdownMaintenance/DownloadForRCAReport?Files=${Files}`,{ responseType: "blob" }) 
  }