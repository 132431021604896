import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import $, { data } from "jquery";
import Global from "./../../services/Global";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";


const _masterDetailService = new MasterDetailService();

export const Location = ({ TabID, TabValue }) => {
  const [alllocationlist, setAllLocationList] = useState([]);
  const [locationList, setlocationList] = useState([]);

  const [Refresh, setRefresh] = useState(0);

  const { currentMasterTab, getCookies } = useContext(AppContext);
  const { showLoading, hideLoading } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    var isExists = [];
    data.SavedBy = getCookies(Global.COOKIES.EmployeeID)

    console.log(alllocationlist);
    isExists = alllocationlist.find(
      (v) =>
      (v.equipLocationName != null) &&
       v.equipLocationName.toLowerCase().trim() ==
          data.equipLocationName.toLowerCase().trim() &&
        v.equipLocID != data.equipLocID
      
    );
    if (isExists == null || isExists.length == 0) {
      _masterDetailService
        .SaveEquipmentLocation(data)
        .then((response) => {
          reset();
          showSaveSuccess();
          var count = Refresh;
          setRefresh(++count);
        })
        .catch((error) => {
          showSaveError();
        });
    } else {
      showWarningMsg("Equipment location already exists");
    }
  };

  const columnNames = [
    { title: "Location ID", visible: false },
    { title: "Equipment Location" },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),

        createdCell: (td, cellData, rowData, row, col) => {
          var b = "";
          var c = "";
          if (getPrivilege().includes("MASTEREDIT")) {
            b = $(`<button class='btn icon-btn' title='Edit'>
            <i class='fa fa-pencil m-r-5'></i>
          </button>`).on("click", function () {
            EditLocation(Number(rowData[0]));
            window.scroll(0,0);

            });
          }
  
          if (getPrivilege().includes("MASTERDELETE")) {
            c = $(`<button class='btn icon-btn'  title='delete'  >
            <i class='fa fa-trash m-l-15'></i>
          </button>`).on("click", function () {
            onDeleteClick(Number(rowData[0]));
            });
          }
  
          $(td).html(b);
          $(td).append(c);
        },
      
    },
   
  ];

  //functions
  useEffect(() => {
    // get parameter list
   
    if (TabID == TabValue) {
      console.log(TabID+ ' ' + TabValue + ' Location');
      getData();
      reset();
    }
  }, [currentMasterTab]);

  const getData = () => {
    _masterDetailService
      .GetEquipmentLocationList()
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          setAllLocationList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.equipLocID);
            row.push(v.equipLocationName);
            row.push(i);
            rowList.push(row);
          });
          setlocationList(rowList);
        } else {
        }
      })
      .catch((error) => {
      });
  };
  const onDeleteClick = (id) => {
 


    swal({
      title: "Are you sure?",
      text: "Do you want to delete the equipment location?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _masterDetailService.DeleteEquipmentLocation(id).then((response) => {
          if (response.data == 1) {
            showDeleteSuccess();
            setRefresh(Refresh+1);
          }
          else {
            showSaveError();
          }
    
        }).catch((err) =>{
          setRefresh(Refresh+1);
          console.log(err);
        });
  
      }
    });  
  };
  useEffect(() => {
    if (TabID == TabValue  && Refresh != 0) {
       getData();
    }
  }, [Refresh]);

  function EditLocation(Id) {
    const found = alllocationlist.find((obj) => {
      return obj.equipLocID === Id;
    });
    Object.keys(found).map((key, i) => setValue(key, found[key]));
  }

  return (
    <div className="mb-2">
      {getPrivilege().includes("MASTERCREATE") && (
      <form id="LocationMaster" onSubmit={handleSubmit(onSubmit)}>
        <div className="row d-flex justify-content-end">
          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipLocationName">
                Equipment Location <span className="text-danger">*</span>
              </label>
              <input type="hidden" value={register.equipLocID} />

              <input
                type="text"
                name="equipLocationName"
                maxLength="50"
                className="form-control"
                // placeholder="Equipment Location"
                id="equipLocationName"
                {...register("equipLocationName", {
                  required: "Equipment location is required",
                  validate: (value) => {
                    return (
                      !!value.trim() || "Equipment location cannot be empty"
                    );
                  },
                })}
                value={register.equipLocationName}
              />
              {errors.equipLocationName && (
                <span className="text-danger">
                  {errors.equipLocationName.message}
                </span>
              )}
            </div>
          </div>

          {getPrivilege().includes("MASTERCREATE") && (
          <div className="col-2 mt-4">
            <AddButton />
          </div>
          )}
        </div>
      </form>
      )}
      <HtmlTable
        columnList={columnNames}
        rowList={locationList}
        id={"LocationTable"}
      />
    </div>
  );
};
