import React, { useEffect, useState } from "react";
import {
  PeriodicMaintenance_AssignEmployee,
  PeriodicMaintenance_GetEmployeeWithEquipmentDetails,
} from "./PeriodicService";
import { ContextValues } from "./PeriodicContextProvider";
import $ from "jquery";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { FaRegSave } from "react-icons/fa";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
} from "../../components";
import { DeepCopy, GetLoginUserID, GetToDateAndWeek } from "../../services/CommonService";

export const PeriodicJobAssign = () => {
  const { PeriodicModel } = ContextValues();

  const initDropdownData = {
    EquipmentList: [],
    EmployeeList: [],
  };

  const [DropdownModel, setDropdownModel] = useState(initDropdownData);

  const [PeriodicAssignModel, setPeriodicAssignModel] = useState([]);

  useEffect(() => {
    setDropdownModel(initDropdownData);
    setPeriodicAssignModel([]);
    getDescriptionAssign();
  }, [PeriodicModel.RefreshID]);

  const getDescriptionAssign = async () => {

    debugger
    const {
      DepartmentID,
      EquipmentID,
      StationID,
      FromDate,
      CurrentWeek,
      CurrentView,
    } = PeriodicModel;


    


    let { ToDate } = GetToDateAndWeek(CurrentView, CurrentWeek, FromDate);
    
    if (DepartmentID && EquipmentID && StationID?.value && FromDate && ToDate) {
  
      
      await PeriodicMaintenance_GetEmployeeWithEquipmentDetails(
        DepartmentID,
        EquipmentID,
        StationID?.value,
        FromDate,
        ToDate
      )
        .then((res) => {
          if (res?.data?.data) {
            
            const { equipmentList, employeeList } = res.data.data;
            setDropdownModel((pre) => ({
              ...pre,
              EquipmentList: equipmentList,
              EmployeeList: employeeList,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const HandleChange = async (e, EquipmentDetailID) => {
    const { value } = e.target;
    
    // if(value > 0){
    const model = JSON.parse(JSON.stringify(PeriodicAssignModel));
    let IdentifiedObj = model?.find(
      (ele) => ele.EquipmentDetailID == EquipmentDetailID
    );
    if (IdentifiedObj) {
      IdentifiedObj.EmployeeID = value;
    } else {
      model.push({
        EmployeeID: value,
        EquipmentDetailID,
      });
    }
    const CopyList = DeepCopy(DropdownModel.EquipmentList);
    let finedEqu = CopyList.find((ele) => ele.EquipmentDetailID == EquipmentDetailID);
    if (finedEqu) {
      finedEqu.AttentedBy = value;
    }
    setDropdownModel((pre) => ({
      ...pre,
      EquipmentList: CopyList,
    }));
    setPeriodicAssignModel(model);
    // }
  };

  const SavePeriodicAssign = async () => {
    if (PeriodicAssignModel?.length) {
      const {
        DepartmentID,
        EquipmentID,
        StationID,
        FromDate,
        CurrentView,
        CurrentWeek,
      } = PeriodicModel;
      let { ScheduleType, ToDate, week } = GetToDateAndWeek(
        CurrentView,
        CurrentWeek,
        FromDate
      );

      const obj = {
        DepartmentID,
        EquipmentID,
        StationID : StationID?.value,
        FromDate,
        ToDate: ToDate,
        PeriodicAssignList: JSON.stringify(PeriodicAssignModel),
        WeekID: week,
        ScheduleType,
        SavedBy : GetLoginUserID(),
        Type : 1
      };

      await PeriodicMaintenance_AssignEmployee(obj)
        .then((res) => {
          if (res?.data?.isSuccess) {
            showSaveSuccess();
          } else {
            showSaveError();
          }
          setDropdownModel(initDropdownData);
          $("#PeriodicJobAssignClose").click();
        })
        .catch((err) => {
          console.log(err);
          $("#PeriodicJobAssignClose").click();
          setDropdownModel(initDropdownData);
          showSaveError();
        });

      return true;
    }
    showWarningMsg("Please select at least one.");
  };

  return (
    <>
      <div>
        {DropdownModel?.EquipmentList.map((ele) => {
          return (
            <>
              <div className="row">
                <div className="col-md-8 m-b-10 align-content-center row">
                  <div className="col-4">{ele.EquipmentCode}</div>
                  <div className="col-4">
                    <WithSelectDropDown
                      HandleChange={(e) => {
                        HandleChange(e, ele.EquipmentDetailID);
                      }}
                      value={ele.AttentedBy}
                      name={"EmployeeID"}
                      initialVal={"0"}
                      data={DropdownModel?.EmployeeList ?? []}
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <button className="btn btn-primary" onClick={SavePeriodicAssign}>
        <FaRegSave /> Save{" "}
      </button>
    </>
  );
};
