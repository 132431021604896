import { BASE_URL } from "../../env";
import AxiosService from "../../services/AxiosService";
import { Decrypt } from "../../services/CommonService";
import { COOKIES } from "../../services/Global";

const axios = new AxiosService();

export const StationLocationSaveConfig = (model) =>
  axios.post("ApplicationConfig/StationLocationSaveConfig", model);

export const StationLocationGetList = async (
  StationLocationID = 0,
  IsDeleted = 0,
  SavedBy = 0
) => {
  return await axios.get(
    `ApplicationConfig/StationLocationGetList?StationLocationID=${StationLocationID}&IsDeleted=${IsDeleted}&SavedBy=${SavedBy}`
  );
};
