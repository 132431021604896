import { Encrypt } from "../../services/CommonService";
import Axios from "../../services/AxiosService";
import { getUserDetails, GetLoginUserID } from "./../../services/CommonService";
import { COOKIES } from "../../services/Global";

const axios = new Axios();
export class PeriodicService {
  async GetEmployeeCodes(type) {
    return await axios
      .get(`User/GetEmployeCodes?type=${type}`)
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        //;
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  async GetEmployeeCodesByStation(station) {
    return await axios
      .get(`User/GetEmployeeCodesByStation?station=${station}`)
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        //;
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  async ReportGetJobDescriptionUsingJobID(equipmentDetailID, JobScheduleID) {
    return await axios
      .get(
        `PeriodicMaintenance/ReportGetJobDescriptionUsingJobID?equipmentDetailID=${equipmentDetailID}&JobScheduleID=${JobScheduleID}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }

  GetStationList() {
    return axios
      .get(`MasterConfig/StationList`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  GetStatusList(type) {
    return axios
      .get(`PeriodicMaintenance/GetStatusList?type=${type}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async GetEquipmentDetails(stationID, equipmentID) {
    return await axios
      .get(
        `PeriodicMaintenance/GetEquipmentDetails?stationID=${stationID}&equipentID=${equipmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async Report_GetJobsForPeriodic(
    fromDate,
    toDate,
    statusID,
    stationID,
    EquipmentID
  ) {
    return await axios
      .get(
        `PeriodicMaintenance/Report_GetJobsForPeriodic?fromDate=${fromDate}&toDate=${toDate}&statusID=${statusID}&stationID=${stationID}&equipmentID=${EquipmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async GetPeriodicData(type, stationID) {
    return await axios
      .get(
        `PeriodicMaintenance/GetPeriodicData?type=${type}&stationID=${stationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  // That is Equipment Detail ID
  async PeriodicGetDropList(EquipmentID) {
    return await axios
      .get(
        `PeriodicMaintenance/Periodic_GetDropList?EquipmentID=${EquipmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async GetSpareUsingjobScheduleID(jobScheduleID) {
    return await axios
      .get(
        `PeriodicMaintenance/GetSpareUsingjobScheduleID?jobScheduleID=${jobScheduleID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async JobSchedulePendingDate(month, year, currentdate, type, Station) {
    return await axios
      .get(
        `PeriodicMaintenance/JobSchedulePendingDate?Month=${month}&&Year=${year}&&CurDate=${currentdate}&&Type=${type}&&Station=${Station}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  async GetTestRunData(station, EquipmentDetailId) {
    return await axios
      .get(
        `PeriodicMaintenance/GetTestRunData?stationID=${station}&&equipmentDetailID=${EquipmentDetailId}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  async Periodic_GetDropList() {
    return await axios
      .get(`PeriodicMaintenance/PeriodicConfig_GetDropDown`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  SavePridicConfiguration(obj) {
    return axios
      .post("PeriodicMaintenance/PeriodicConfig_SavePridicConfiguration", obj)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  PeriodicConfig_GetByPeriodicID(
    PeriodicMaintanenceID = 0,
    IsDeleted = 0,
    EmployeeID = 0
  ) {
    return axios
      .get(
        `PeriodicMaintenance/PeriodicConfig_GetByPeriodicID?PeriodicMaintanenceID=${PeriodicMaintanenceID}&IsDeleted=${IsDeleted}&EmployeeID=${Number(
          EmployeeID
        )}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
//arunbala

async GetApproverList(FilterModel){
  debugger
  return axios
  .post(
    `PeriodicMaintenance/GetApproverList`,FilterModel).then(response => {
  
    return response.data;

  })
}
  async GetJobScheduleEquipment(fromdate, todate, stationID, type, week) {
    // var assingedTo =
    //   getUserDetails().RoleID == COOKIES.TechRoleID ? 0 : 0;
    return await axios
      .get(
        `PeriodicMaintenance/Periodic_GetJobEquipments?StationID=${stationID}&MaintenanceTypeID=${type}&Week=${week}&FromDate=${fromdate}&ToDate=${todate}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => null);
  }

  async BulkUpdateJobScheduleByEquipment(job) {
    return await axios
      .post("PeriodicMaintenance/BulkUpdateJobScheduleByEquipment", job)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  async Periodic_GetMaintenanceScheduleByEquipments(
    fromdate,
    todate,
    stationID,
    type,
    equipmentDetailID,
    equipmentID
  ) {
    // var assingedTo =
    //   getUserDetails().RoleID == COOKIES.TechRoleID ? 0 : 0;
    return await axios
      .get(
        `PeriodicMaintenance/Periodic_GetMaintenanceScheduleByEquipments?StationID=${stationID}&EquipmentDetailID=${equipmentDetailID}&FromDate=${fromdate}&ToDate=${todate}&MaintenanceTypeID=${type}&EquipmentID=${equipmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => null);
  }

  async AddorUpdateJobMaintenanceByID(job) {
    return await axios
      .post("PeriodicMaintenance/AddOrUpdateJobScheuldeIndividual", job)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  async BulkUpdateJobScheduleByEquipmentDetails(job) {
    return await axios
      .post("PeriodicMaintenance/BulkUpdateJobScheduleByEquipmentDetails", job)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  async Report_GetJobEquipmentList(type = 1) {
    return await axios
      .get(`PeriodicMaintenance/Report_GetJobEquipmentList?Type=${type}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async Periodic_GetEquipmentDetailsListByID(
    stationID,
    equipmentID,
    FromDate,
    ToDate,
    MaintenanceTypeID
  ) {
    return await axios
      .get(
        `PeriodicMaintenance/Periodic_GetEquipmentDetailsListByID?stationID=${stationID}&equipmentID=${equipmentID}&Fromdate=${FromDate}&ToDate=${ToDate}&MaintenanceTypeID=${MaintenanceTypeID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  async GetSampleData() {
    return await axios
      .get(`PeriodicMaintenance/GetData`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  async Periodic_GetDescriptionByID(equipmentID, MaintenanceTypeID, stationID) {
    return await axios
      .get(
        `PeriodicMaintenance/Periodic_GetJobDescriptionByID?EquipmentID=${equipmentID}&MaintenanceTypeID=${MaintenanceTypeID}&StationID=${stationID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  async download_uploaded_files(JobScheduleID = 0, EquipmentDetailID = 0) {
    return await axios.get(
      `PeriodicMaintenance/download_uploaded_files?JobScheduleID=${JobScheduleID}&EquipmentDetailID=${EquipmentDetailID}`,
      { responseType: "blob" }
    );
  }

  async Periodic_GetEquipmentDetailsUsingID(
    StationID,
    EquipmentID,
    MaintenanceTypeID,
    FromDate,
    ToDate
  ) {
    return await axios.get(
      `PeriodicMaintenance/Periodic_GetEquipmentDetailsUsingID?StationID=${StationID}&EquipmentID=${EquipmentID}&MaintenanceTypeID=${MaintenanceTypeID}&FromDate=${FromDate}&ToDate=${ToDate}`
    );
  }

  async AddorUpdateJobMaintenanceByIDForUG(job) {
    return await axios
      .post("PeriodicMaintenance/AddOrUpdateJobScheuldeIndividualForUG19", job)
      .then((res) => {
        if (res != null && res.data != null) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  async SafetyMeasurement_GetSafetyMeasures(EmployeeID) {
    return await axios.get(
      `PeriodicMaintenance/SafetyMeasurement_GetSafetyMeasures?EmployeeID=${EmployeeID}`
    );
  }

  async SafetyMeasurement_SaveStatus(EmployeeID) {
    return await axios.post(
      `PeriodicMaintenance/SafetyMeasurement_SaveStatus?empID=${EmployeeID}`
    );
  }

  async Report_Periodic_GetEquipmentDetailsUsingID(
    StationID,
    EquipmentID,
    MaintenanceTypeID,
    FromDate,
    ToDate
  ) {
    return await axios.get(
      `PeriodicMaintenance/Report_Periodic_GetEquipmentDetailsUsingID?StationID=${StationID}&EquipmentID=${EquipmentID}&MaintenanceTypeID=${MaintenanceTypeID}&FromDate=${FromDate}&ToDate=${ToDate}`
    );
  }
}

export const PeriodicConfiguration_SavePeriodicConfiguration = async (
  model
) => {
  return await axios.post(
    `PeriodicMaintenance/PeriodicConfiguration_SavePeriodicConfiguration`,
    model
  );
};

export const PeriodicConfiguration_GetDescription = async (
  PeriodicID,
  IsDeleted
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicConfiguration_GetDescription?PeriodicID=${PeriodicID}&IsDeleted=${IsDeleted}`
  );
};

export const PeriodicMaintenanceGetEquipment = async (
  stationID,
  employeeID,
  fromDate,
  toDate,
  departmentId,
  scheduleType,
  WeekId
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenanceGetEquipment?stationID=
    ${stationID}&employeeID=${employeeID}&fromDate=${fromDate}&toDate=${toDate}
    &departmentId=${departmentId}&scheduleType=${scheduleType}&WeekId=${WeekId}`
  );
};

export const PeriodicMaintenance_GetEmployeeWithEquipmentDetails = async (
  DepartmentID = 0,
  EquipmentID,
  StationID,
  FromDate = '',
  ToDate = '',
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetEmployeeWithEquipmentDetails?DepartmentID=
    ${DepartmentID}&EquipmentID=${EquipmentID}&StationID=${StationID}&FromDate=${FromDate}&ToDate=${ToDate}`
  );
};

export const PeriodicMaintenance_AssignEmployee = async (obj) => {
  return await axios.post(
    `PeriodicMaintenance/PeriodicMaintenance_AssignEmployee`,
    obj
  );
};

export const PeriodicMaintenance_GetEquipmentDetailsUsingID = async (
  PeriodicCheckListMaintenanceID,
  EquipmentDetailId,
  EmployeeID
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetEquipmentDetailsUsingID?PeriodicCheckListMaintenanceID=
    ${PeriodicCheckListMaintenanceID}&EquipmentDetailId=${EquipmentDetailId}&EmployeeID=${EmployeeID}`
  );
};

export const PeriodicMaintenance_GetEquipmentDetailForJobSave = async (
  PeriodicCheckListMaintenanceID,
  EmployeeID
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetEquipmentDetailForJobSave?PeriodicCheckListMaintenanceID=
    ${PeriodicCheckListMaintenanceID}&EmployeeID=${EmployeeID}`
  );
};

export const PeriodicMaintenance_GetDescriptionByEquipment = async (
  EquipmentID,MaintenanceTypeID,StationID,DepartmentId,weekID = -1
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetDescriptionByEquipment?EquipmentID=
    ${EquipmentID}&MaintenanceTypeID=${MaintenanceTypeID}&StationID=${StationID}
    &DepartmentId=${DepartmentId}&weekID=${weekID}`
  );
};

export const PeriodicMaintenance_GetDynamicMonths = async (
  DepartmentId,Year,StationID
) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetDynamicMonths?DepartmentId=${DepartmentId}&Year=${Year}&StationID=${StationID}`
  );
};

export const PeriodicMaintenance_SpareGetDropList = async (EquipmentDetailID, DepartmentID) => {
  return await axios
    .get(
      `PeriodicMaintenance/PeriodicMaintenance_SpareGetDropList?EquipmentDetailID=${EquipmentDetailID}&DepartmentID=${DepartmentID}`
    )
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
};


export const PeriodicMaintenance_SaveSpareData = async (obj) => {
  return await axios.post(
    `PeriodicMaintenance/PeriodicMaintenance_SaveSpareData`,
    obj
  );
};

export const PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19 =
  async (obj) => {
    return await axios.post(
      `PeriodicMaintenance/PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19`,
      obj
    );
  };

export const PeriodicConfiguration_GetDropDown = async () => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicConfiguration_GetDropDown`
  );
};


export const PeriodicMaintenance_GetSpareUsingPMCheckListID = async (PMCheckListID) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetSpareUsingPMCheckListID?PMCheckListID=${PMCheckListID}`
  );
};


export const PeriodicMaintenance_GetOverAllRemarks = async (PMChkId,EquipmentDetailID) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetOverAllRemarks?PMChkId=${PMChkId}&EquipmentDetailID=${EquipmentDetailID}`
  );
};

export const Report_PeriodicMaintenance_DropDown = async () => {
  return await axios.get(
    `PeriodicMaintenance/Report_PeriodicMaintenance_DropDown`
  );
};

export const PeriodicMaintenance_GetPendingColor = async (ScheduleTypeID, DepartmentID, StationID, FromDate, ToDate) => {
  return await axios.get(
    `PeriodicMaintenance/PeriodicMaintenance_GetPendingColor?ScheduleTypeID=${ScheduleTypeID}&DepartmentID=${DepartmentID}&StationID=${StationID}&FromDate=${FromDate}&ToDate=${ToDate}`
  );
};