import React from "react";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import { Dropdown } from "../../components/Dropdown";
import { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import {
  GetLoginUserID,
  ClearFormElement,
  getPrivilege,
} from "../../services/CommonService";
import BreakdownMaintenanceService, {
  BreakdownMaintenance_GetParameterListUsingEquipmentDetailID,
} from "./BreakdownMaintenanceService";
import {
  showDeleteSuccess,
  showSuccessMsg,
  showSaveSuccess,
  showErrorMsg,
} from "../../components/ToastMsg";
import $, { cssNumber } from "jquery";
import { useContext, useRef } from "react";
import { AppContext } from "../../services/ContextProvider";
import { useReducer } from "react";
import { ManualSpareList } from "./ManualSpareList";
import { getUserDetails } from "../../services/CommonService";
import { COOKIES } from "../../services/Global";
import { Cookies } from "react-cookie";
import Global from "../../services/Global";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ReactAutoComplete } from "../../components";
import { Filler } from "chart.js";

// getUserDetails().RoleID == COOKIES.TechRoleID

const obj = new BreakdownMaintenanceService();
export const SaveManualBreakdownMaindence = ({
  IsShow,
  TicketDetailes,
  StatusDropDown,
  StationList,
  AssignByDropDown,
  ReportToDropdown,
  ParameterList,
  RefreshforForm,
  tableRefresh,
  SelectedSpareList,
  PartDropDown,
  Sparelist,
  
}) => {
  const [TicketModel, setTicketModel] = useState({});
  const [FilterParameterList, setFilterParameterList] = useState([]);
  const { getCookies, setCookies, showLoading, hideLoading } =
    useContext(AppContext);
  const [EquipmentTagNo, setEquipmentTagNo] = useState({});
  const [ParameterID, setParameterID] = useState("");
  const [errors, seterrors] = useState({});

  const [ModelRefresh, setModelRefresh] = useReducer((x) => x + 1, 0);
  const [SelectedSpareListForManual, SetSelectedSpareListForManual] = useState(
    []
  );
  const [privilege, setpriviledge] = useState("");
  const [Equipment, setEquipment] = useState([]);
  const [AssignToDropDown, setAssignToDropDown] = useState([]);
  const [ReportDrop, setReportDrop] = useState([]);
  const [StationSpareList, setStationSpareList] = useState([]);
  const [StationPartList, setStationPartList] = useState([]);
  const [isChildFocused, setIsChildFocused] = useState(false);

  useEffect(() => {
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";
    setpriviledge(ModulePrivileges);
  }, []);

  useEffect(() => {
    seterrors({});

    $(".BreakdownManual").val(" ");
    $(".BreakdownManualDrop").val("0");
    document.getElementById("FormFile").value = null;

    setModelRefresh();

    // FilterSpareList.map((x) => {
    //   return (x.key = x.SpareName), (x.value = x.SpareID);
    // });

    // let filStationSpareList = FilterSpareList.filter(
    //   (x) => x.StationID == TicketDetailes.StationID
    // );

    // setStationSpareList(filStationSpareList ?? []);

    // setAssignToDropDown(EmployeeDropDown);
    // setReportDrop(ReportToDropdown);

    // PartDropDown.map((x) => {
    //   return (x.key = x.SparePartNo), (x.value = x.SpareDetailID);
    //   // temp1.push({ key: x.SparePartNo, value: x.SpareDetailID, ...x });
    // // });
    // let filStationPartDropDown = PartDropDown.filter(
    //   (x) => x.StationID == TicketDetailes.StationID
    // );
    // setStationPartList(filStationPartDropDown ?? []);
    
    if (TicketDetailes.ParameterID != undefined) {
      
      const { EquipmentDetailID, StationID, ParameterID } = TicketDetailes;

      getEquipmentList(StationID, EquipmentDetailID);
      getParameterList(EquipmentDetailID, ParameterID);
      // const EquipmentTag = EqiupmentTagList.find((x) => {
      //   return x.Value == TicketDetailes.EquipmentDetailID;
      // });

      // setEquipmentTagNo(EquipmentTag);

      // EquipmentChange(EquipmentTag);
      // const Parameter = ParameterList.find((x) => {
      //   return x.id == TicketDetailes.ParameterID;
      // });

      // setParameterID(Parameter?.label ?? "");

      if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
        TicketDetailes.TicketStatusID = 31;
      } else if (
        getUserDetails().RoleID === COOKIES.TechRoleID &&
        TicketDetailes.initialStatusID == 28
      ) {
        TicketDetailes.TicketStatusID = 26;
      }

      setTicketModel({ ...TicketDetailes });
      // setTicketModel((prevState) => ({
      //   ...prevState,
      //   ParameterUnit: Parameter?.UOM ?? "",
      // }));
    } else {
      setTicketModel({ ...TicketDetailes });
      setEquipmentTagNo({});
      setParameterID("");
      onchangeUOMClearName();
      $(".TicketStatus").val("25");
      setTicketModel((prevState) => ({
        ...prevState,
        ["TicketStatusID"]: 25,
        ["statusName"]: "Open",
        ["AssignedBy"]: GetLoginUserID(),
        ["ReportTo"]:
          getUserDetails().RoleID !== COOKIES.TechRoleID
            ? GetLoginUserID()
            : "0",
      }));
    }

    return () => {
      setTicketModel(null);
    };
  }, [JSON.stringify(TicketDetailes), RefreshforForm]);


  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    if (name == "AssignedTo") {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
        TicketStatusID: 28,
        AssignedBy: GetLoginUserID(),
      }));
    } else if (name == "StationID") {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
        ReportTo : COOKIES.TechRoleID == getUserDetails().RoleID ? 0 : GetLoginUserID(),
        AssignedTo : 0,
        AssignedBy : 0
      }));

      getEquipmentList(value);

      // let filStationSpareList = FilterSpareList.filter(
      //   (x) => x.StationID == value
      // );
      // setStationSpareList(filStationSpareList ?? []);

      // let filStationPartDropDown = PartDropDown.filter(
      //   (x) => x.StationID == value
      // );
      // setStationPartList(filStationPartDropDown ?? []);

      // const Assigned_By_DropDown = AssignByDropDown.filter((x) => {
      //   return (
      //     x.stationID === Number(value) && x.roleID == Global.COOKIES.TechRoleID
      //   );
      // });

      // setAssignToDropDown(Assigned_By_DropDown);

      // const Report_drop = AssignByDropDown.filter((x) => {
      //   return x.stationID === Number(value);
      // });

      // setReportDrop(Report_drop);
    } else {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const getEquipmentList = async (stationID, EquipmentDetailID) => {
    await obj
      .BreakdownMaintenance_GetManualBreakdownDropdownData(stationID)
      .then((res) => {
        if (res?.data) {
          const { equipmentList, spareList, partList,employeeList } = res.data;
          if (EquipmentDetailID) {
            let EquipmentCode = equipmentList.find(
              (ele) => ele.value == EquipmentDetailID
            );
            setEquipmentTagNo(EquipmentCode ?? {});
          } else {
            setEquipmentTagNo({});
          }

          if (employeeList?.length) {
            
            const EmpList = employeeList.filter((x) => {
              return x.roleID == Global.COOKIES.TechRoleID;
            });

            setAssignToDropDown(EmpList)
            setReportDrop(employeeList)

          } else {
            setAssignToDropDown([]);
            setReportDrop([]);
          }


          setStationSpareList(spareList);
          setStationPartList(partList);

          setEquipment(equipmentList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParameterList = (EquipmentDetailID, parameterId) => {
    BreakdownMaintenance_GetParameterListUsingEquipmentDetailID(
      EquipmentDetailID
    )
      .then((res) => {
        if (res?.data?.data?.length) {
          setFilterParameterList(res.data.data);
          if (parameterId) {
            let Parameter = res.data.data.find((ele) => ele.id == parameterId);
            if (Parameter) {
              setParameterID(Parameter.label);
              setTicketModel((prevState) => ({
                ...prevState,
                ParameterUnit: Parameter?.UOM ?? "",
              }));
            } else {
              setParameterID("");
              setTicketModel((prevState) => ({
                ...prevState,
                ParameterUnit: "",
              }));
            }
          }
        } else {
          setFilterParameterList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeParameterName = (e) => {
    setIsChildFocused(true);
    let paraName = e;
    //  paraName?.UOM
    setTicketModel((prevState) => ({
      ...prevState,
      ParameterUnit: paraName?.UOM,
    }));
  };
  const onchangeUOMClearName = () => {
    setIsChildFocused(false);
    setTicketModel((prevState) => ({
      ...prevState,
      ParameterUnit: "",
    }));
  };
  const EquipmentChange = (e) => {
    const { value } = e;

    setEquipmentTagNo(e);
    setParameterID("");
    onchangeUOMClearName();
    getParameterList(value);
  };

  function SaveDetailes(e) {
    e.preventDefault();

    const formData = new FormData();

    var TicketModels = TicketModel;

    const {
      Location,
      ProblemDescription,
      AssignedTo,
      AssignedBy,
      TicketStatusID,
      StationID,
      ReportTo,
    } = TicketModels;

    
    var ErrorMgs = errors;

    let flag = true;

    if (Location != null && Location.trim() != "") {
      ErrorMgs.Location = "";
    } else {
      ErrorMgs.Location = "Please enter location";
      flag = false;
    }

    if (ProblemDescription != null && ProblemDescription.trim() != "") {
      ErrorMgs.ProblemDescription = "";
    } else {
      ErrorMgs.ProblemDescription = "Please enter problem description";
      flag = false;
    }

    if (AssignedTo != null && AssignedTo != "") {
      ErrorMgs.AssignedTo = "";
    } else {
      ErrorMgs.AssignedTo = "Please enter assigned to";
      flag = false;
    }

    if (StationID != null && StationID != "") {
      ErrorMgs.StationID = "";
    } else {
      ErrorMgs.StationID = "Please enter station name";
      flag = false;
    }

    if (ReportTo != null && ReportTo != "" && ReportTo != "0") {
      ErrorMgs.ReportTo = "";
    } else {
      ErrorMgs.ReportTo = "Please enter report to";
      flag = false;
    }

    if (AssignedBy != null && AssignedBy != "") {
      ErrorMgs.AssignedBy = "";
    } else {
      ErrorMgs.AssignedBy = "Please enter assigned by";
      flag = false;
    }
    if (TicketStatusID != null && TicketStatusID != "") {
      ErrorMgs.TicketStatusID = "";
    } else {
      ErrorMgs.TicketStatusID = "Please select status";
      flag = false;
    }

    // if (ParameterID != null && ParameterID.length) {
    //   ErrorMgs.ParameterID = "";
    // } else {
    //   ErrorMgs.ParameterID = "Please select parameter";
    //   flag = false;
    // }
    if (EquipmentTagNo != null && EquipmentTagNo.value != undefined) {
      ErrorMgs.EquipmentTagNo = "";
    } else {
      ErrorMgs.EquipmentTagNo = "Please select equipment tag no";
      flag = false;
    }

    seterrors({ ...ErrorMgs });

    // TicketModels.assignedBy =

    if (flag) {
      var fileInput = document.getElementById("FormFile");

      if (
        TicketModels.TicketStatusID == 28 &&
        TicketModels.AssignedBy == TicketModels.AssignedTo
      ) {
        TicketModels.TicketStatusID = 26;
      }
      TicketModels.savedBy = GetLoginUserID();
      // TicketModels.ParameterID =  isNaN(ParameterID[0].id) ? 0 : ParameterID[0].id;
      TicketModels.ParameterName = ParameterID;
      TicketModels.EquipmentDetailID = EquipmentTagNo.value;
      TicketModels.Files = fileInput.files;

      const SelectedSpareList = SelectedSpareListForManual;

      $.each($("#FormFile")[0].files, function (i, file) {
        formData.append("files", file);
      });

      showLoading();

      obj
        .FileUpload(formData)
        .then((res) => {
          const WrapObject = {
            breakdownMaintenanceModel: TicketModels,
            sparesUsedMasterModels: SelectedSpareList,
            ListOfOrginalFileName: res,
          };

          obj
            .BreakdownMaintenance_ManualSaveBreakdownDetailes(WrapObject)
            .then((res) => {
    

              hideLoading();

              $(".close").click();

              showSuccessMsg("Saved successfully.");
            })
            .catch((err) => {
             
              console.log(err);
              showErrorMsg("Something went wrong, Please try after sometime");
              hideLoading();
            });
        })
        .catch((err) => {
          hideLoading();

          console.log(err);
        });
    }
  }

  const saveSpare = (value) => {
    SetSelectedSpareListForManual(value);
  };

  return (
    <div>
      {IsShow ? (
        <form
          id="ManualBreakdownDetailes"
          className="ManualBreakdownDetailes"
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={SaveDetailes}
        >
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Station Name<span className="text-danger"> *</span>{" "}
                </label>
                <Dropdown
                  data={StationList}
                  placeholder="--Select--"
                  name={"StationID"}
                  HandleChange={CommonHandleChange}
                  value={TicketModel.StationID}
                  className="BreakdownManualDrop"
                  disabled
                  initialVal={-1}
                />

                <span className="text-danger mt-1"> {errors.StationID} </span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Equipment Tag Number
                  <span className="text-danger"> *</span>
                </label>
                <ReactMultiSelect
                  isDisabled
                  options={Equipment}
                  onChange={EquipmentChange}
                  placeholder="Select"
                  // closeMenuOnSelect={false}
                  // hideSelectedOptions={false}

                  value={EquipmentTagNo}
                />
                <span className="text-danger"> {errors.EquipmentTagNo} </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Parameter Name
                  <span className="text-danger"> *</span>
                </label>
                <ReactSelect
                  options={FilterParameterList}
                  isDisabled
                  // closeMenuOnSelect={false}
                  // hideSelectedOptions={false}
                  onChange={(e) => setParameterID(e)}
                  placeholder="Select"
                  disabled
                  value={ParameterID}
                />
                <span className="text-danger"> {errors.ParameterID} </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Status
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"

                  type="text"
                  name="ProblemDescription"
                  disabled
                  value={TicketModel.statusName}
                />
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned By
                  <span className="text-danger"> *</span>
                </label>
                <Dropdown
                  disabled
                  data={ReportDrop}
                  placeholder="Select"
                  value={TicketModel.AssignedBy}
                  className="BreakdownManualDrop"
                  initialVal={-1}
                  name={"AssignedBy"}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedBy} </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned To
                  <span className="text-danger"> * </span>
                </label>
                <Dropdown
                  data={AssignToDropDown}
                  placeholder="Select"
                  value={TicketModel.AssignedTo}
                  name={"AssignedTo"}
                  className="BreakdownManualDrop"
                  disabled
                  initialVal={-1}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedTo} </span>
              </div>
            </div>
            {getUserDetails().RoleID == COOKIES.TechRoleID ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Report To
                    <span className="text-danger"> * </span>
                  </label>
                  {TicketModel.TicketID == undefined ||
                  TicketModel.TicketID == 0 ? (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportTo}
                      disabled
                      name={"ReportTo"}
                      className="BreakdownManualDrop"
                      initialVal={-1}
                      HandleChange={CommonHandleChange}
                    />
                  ) : (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportTo}
                      disabled
                      name={"ReportTo"}
                      className="BreakdownManualDrop"
                      initialVal={-1}
                      HandleChange={CommonHandleChange}
                    />
                  )}
                  <span className="text-danger"> {errors.ReportTo} </span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Problem Description
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="ProblemDescription"
                  disabled
                  value={TicketModel.ProblemDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
                <span className="text-danger">
                  {" "}
                  {errors.ProblemDescription}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Work Description
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="WorkDescription"
                  disabled
                  value={TicketModel.WorkDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Location
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  style={{ resize: "none" }}
                  type="text"
                  name="Location"
                  disabled
                  value={TicketModel.Location}
                  onChange={CommonHandleChange}
                  rows={3}
                />
                <span className="text-danger"> {errors.Location} </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Upload Document
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <input id="file" hidden />
                <input
                  className="form-control "
                  type="file"
                  id="FormFile"
                  name="FormFile"
                  multiple
                  disabled
                />
              </div>
            </div>
          </div>
          <ManualSpareList
            saveSpare={saveSpare}
            Ref={ModelRefresh}
            IsAdd={privilege.includes("BREAKDOWNSPAREADDCREATE")}
            Sparelist={Sparelist}
            PartDropDown={PartDropDown}
            SelectedSpareList={SelectedSpareList}
          >
            {" "}
          </ManualSpareList>
        </form>
      ) : (
        <form
          id="ManualBreakdownDetailes"
          className="ManualBreakdownDetailes"
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={SaveDetailes}
        >
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Station Name<span className="text-danger"> *</span>{" "}
                </label>
                <Dropdown
                  data={StationList}
                  placeholder="--Select--"
                  name={"StationID"}
                  HandleChange={CommonHandleChange}
                  value={TicketModel.StationID}
                  className="BreakdownManualDrop"
                  initialVal={0}
                />

                <span className="text-danger mt-1"> {errors.StationID} </span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Equipment Tag Number
                  <span className="text-danger"> *</span>
                </label>
                <ReactMultiSelect
                  options={Equipment}
                  onChange={EquipmentChange}
                  placeholder="Select"
                  value={EquipmentTagNo}
                />
                <span className="text-danger"> {errors.EquipmentTagNo} </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" for="empCode">
                  Parameter Name
                </label>{" "}
                <ReactAutoComplete
                  handleChange={(e, text) => {
                    setParameterID(text);
                    onchangeUOMClearName();
                  }}
                  value={ParameterID ?? ""}
                  handleSelect={(e, text) => {
                    setParameterID(e);
                    onchangeParameterName(text);
                  }}
                  name={"ParameterName"}
                  options={FilterParameterList}
                />
                <span className="text-danger mt-1"> {errors.ParameterID} </span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  UOM
                </label>
                <input
                  type="text"
                  name="ParameterUnit"
                  className="form-control ParameterUnit"
                  value={TicketModel.ParameterUnit}
                  onChange={CommonHandleChange}
                  disabled={isChildFocused}
                />
                <span className="text-danger"> {errors.ParameterID} </span>
              </div>
            </div>
            {getUserDetails().RoleID !== COOKIES.TechRoleID &&
            TicketModel.initialStatusID == 26 ? (
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                    <span className="text-danger"> *</span>
                  </label>

                  <Dropdown
                    data={StatusDropDown}
                    placeholder="--Select--"
                    value={TicketModel.TicketStatusID}
                    name={"TicketStatusID"}
                    className="BreakdownManualDrop TicketStatus"
                    HandleChange={CommonHandleChange}
                    initialVal={0}
                  />
                  <span className="text-danger"> {errors.TicketStatusID} </span>
                </div>
              </div>
            ) : getUserDetails().RoleID !== COOKIES.TechRoleID ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"

                    type="text"
                    name="ProblemDescription"
                    disabled
                    value={TicketModel.statusName}
                  />
                </div>
              </div>
            ) : getUserDetails().RoleID == COOKIES.TechRoleID &&
              (TicketModel.initialStatusID === 26 ||
                TicketModel.initialStatusID === 25 ||
                TicketModel.initialStatusID === 28 ||
                TicketModel.initialStatusID === 32) ? (
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                    <span className="text-danger"> *</span>
                  </label>

                  <Dropdown
                    data={StatusDropDown}
                    placeholder="--Select--"
                    value={TicketModel.TicketStatusID}
                    name={"TicketStatusID"}
                    className="BreakdownManualDrop TicketStatus"
                    HandleChange={CommonHandleChange}
                    initialVal={0}
                  />
                  <span className="text-danger"> {errors.TicketStatusID} </span>
                </div>
              </div>
            ) : (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-control BreakdownManual"
                    // placeholder="Please Enter Problem Description"

                    type="text"
                    name="ProblemDescription"
                    disabled
                    value={TicketModel.statusName}
                  />
                </div>
              </div>
            )}

            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned By
                  <span className="text-danger"> *</span>
                </label>
                <Dropdown
                  disabled
                  data={ReportDrop}
                  placeholder="--Select--"
                  value={TicketModel.AssignedBy}
                  className="BreakdownManualDrop"
                  initialVal={0}
                  name={"AssignedBy"}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedBy} </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Assigned To
                  <span className="text-danger"> * </span>
                </label>
                <Dropdown
                  data={AssignToDropDown}
                  placeholder="--Select--"
                  value={TicketModel.AssignedTo}
                  name={"AssignedTo"}
                  className="BreakdownManualDrop"
                  initialVal={0}
                  HandleChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.AssignedTo} </span>
              </div>
            </div>
            {getUserDetails().RoleID == COOKIES.TechRoleID ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Report To
                    <span className="text-danger"> * </span>
                  </label>
                  {TicketModel.TicketID == undefined ||
                  TicketModel.TicketID == 0 ? (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportTo}
                      name={"ReportTo"}
                      className="BreakdownManualDrop"
                      initialVal={0}
                      HandleChange={CommonHandleChange}
                    />
                  ) : (
                    <Dropdown
                      data={ReportDrop}
                      placeholder="--Select--"
                      value={TicketModel.ReportTo}
                      disabled
                      name={"ReportTo"}
                      className="BreakdownManualDrop"
                      initialVal={0}
                      HandleChange={CommonHandleChange}
                    />
                  )}
                  <span className="text-danger"> {errors.ReportTo} </span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Problem Description
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="ProblemDescription"
                  value={TicketModel.ProblemDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
                <span className="text-danger">
                  {" "}
                  {errors.ProblemDescription}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Remarks By Engineer
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="EngineerRemarks"
                  value={TicketModel.EngineerRemarks}
                  disabled={getUserDetails().RoleID == COOKIES.TechRoleID}
                  rows={3}
                  onChange={CommonHandleChange}
                />
                <span className="text-danger"> {errors.EngineerRemarks} </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Remarks By Technician
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="RemarksForTechnician"
                  value={TicketModel.RemarksForTechnician}
                  disabled={getUserDetails().RoleID !== COOKIES.TechRoleID}
                  rows={3}
                  onChange={CommonHandleChange}
                />
                <span className="text-danger">
                  {" "}
                  {errors.RemarksForTechnician}{" "}
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Work Description
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  // placeholder="Please Enter Problem Description"
                  style={{ resize: "none" }}
                  type="text"
                  name="WorkDescription"
                  value={TicketModel.WorkDescription}
                  rows={3}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Location
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  className="form-control BreakdownManual"
                  style={{ resize: "none" }}
                  type="text"
                  name="Location"
                  value={TicketModel.Location}
                  onChange={CommonHandleChange}
                  rows={3}
                />
                <span className="text-danger"> {errors.Location} </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="label" htmlFor="empCode">
                  Upload Document
                  {/* <span className='text-danger'> *</span> */}
                </label>
                <input id="file" hidden />
                <input
                  className="form-control "
                  type="file"
                  id="FormFile"
                  name="FormFile"
                  multiple
                />
              </div>
            </div>
          </div>
          <ManualSpareList
            saveSpare={saveSpare}
            Ref={ModelRefresh}
            IsAdd={privilege.includes("BREAKDOWNSPAREADDCREATE")}
            Sparelist={StationSpareList ?? []}
            PartDropDown={StationPartList ?? []}
            SelectedSpareList={SelectedSpareList}
          >
            {" "}
          </ManualSpareList>

          {TicketModel.initialStatusID != 31 &&
          // && TicketModel.initialStatusID != 32
          (TicketModel.initialStatusID != 28 ||
            getUserDetails().RoleID === COOKIES.TechRoleID) ? (
            <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
              <div className="col-md-2 ">
                <button
                  type="submit"
                  id=""
                  onSubmit={SaveDetailes}
                  className="btn add-btn secondary-btn submit-btn"
                >
                  {" "}
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      )}
    </div>
  );
};
