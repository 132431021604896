import React from "react";
import { Dropdown } from "../../components/Dropdown";
import { useEffect, useState, useContext } from "react";
import $ from "jquery";
import swal from "sweetalert";
import {
  showWarningMsg,
  showDeleteSuccess,
  showErrorMsg,
} from "../../components/ToastMsg";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import Global from "../../services/Global";
// import { PeriodicService } from "./PeriodicService";

export const ManualSpareList = ({
  IsAdd,
  PartDropDown,
  Equlist,
  saveSpare,
  SelectedSpareList,
  Sparelist,
  Ref,
}) => {
  const [errors, seterrors] = useState({});
  const [SpareLists, setSpareLists] = useState(
    SelectedSpareList != null ? SelectedSpareList : []
  );
  const [PartDropDownModel, setPartDropDownModel] = useState([]);

  const [SpareID, setSpareID] = useState([]);
  const [PartNo, setPartNo] = useState([]);
  const [priviledge, setpriviledge] = useState("");

  const { getCookies, showLoading, hideLoading } =
    useContext(AppContext);

  const obj = new BreakdownMaintenanceService();

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("0");
    $("#SpareID").val("0");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");

    seterrors({});
    setSpareID([]);

    saveSpare(SpareLists);
  }, [SpareLists, Ref, Sparelist]);

  useEffect(() => {
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";
    setpriviledge(ModulePrivileges);
  }, []);

  useEffect(() => {
    setSpareLists(SelectedSpareList);
  }, [SelectedSpareList]);

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("0");
    $("#SpareID").val("0");
    $("#EqipmentName").val("");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");

    seterrors({});

    setSpareID([]);
    setPartNo([]);
    setPartDropDownModel([]);

    seterrors([]);

  }, [Ref]);

  function AddHandleClick() {
    const spare = SpareID;
    const Part = PartNo;
    const Quantity = $("#Quantity").val();

    var err = errors;
    var ErrCheck = true;



    if (spare == null || spare.spareID == null || spare.spareID == "0") {
      err.spareID = "Spare name is required";
      ErrCheck = false;
    } else {
      err.spareID = "";
    }
    if (Part == null || Part.spareDetailID == null || Part.spareDetailID == 0) {
      err.spareDetailID = "Part no is required";
      ErrCheck = false;
    } else {
      err.spareDetailID = "";
    }
    if (Quantity == null || Quantity == "") {
      err.Quantity = "Quantity is required";
      ErrCheck = false;
    } else {
      err.Quantity = "";
    }

    seterrors({ ...err });

    if (ErrCheck) {
      if (Number(Quantity) > 0 && Number(Quantity) <= Part.quantityAvailable) {
        var SpareLst = SpareLists != null ? SpareLists : [];

        var check = SpareLst.findIndex((x) => {
          return x.spareDetailID == Number(Part.spareDetailID);
        });

        if (check == -1) {
          swal({
            title: "Are you sure",
            text: "Do you want to add this spare?",
            icon: "info",
            buttons: true,
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              const NewSpare = {
                spareName: spare.spareName,
                spareID: spare.spareID,
                partNo: Part.PartNo,
                spareDetailID: Part.spareDetailID,
                quantity: Quantity,
                reorderLimit: Part.reorderLimit,
                quantityAvailable: Part.quantityAvailable - Quantity,
                // eqipmentName: Eqipment.EqipmentName,
                // equipmentDetailID: Eqipment.EqipmentID,
              };

              SpareLst.push(NewSpare);
              setSpareLists([...SpareLst]);
            }
          });
        } else {
          showWarningMsg(`Can't add duplicate Part No`);
        }
      } else {
        showWarningMsg(`Only ${Part.quantityAvailable} is available.`);
      }
    }

  }

  useEffect(() => {
    $("#Quantity").val("");
    $("#PartNoID").val("");
    $("#SpareID").val("");
    $("#AvailQuantity").html("");
    $("#ReorderLimit").html("");

    setSpareID([]);
    setPartNo([]);
    setPartDropDownModel([]);

    seterrors([]);
  }, []);

  function PartNoHandleChange(e) {
    if (e.target.value > 0) {
      const Quantity = PartDropDown.find((x) => {
        return Number(x.SpareDetailID) == Number(e.target.value);
      });
      const temp = {
        spareDetailID: e.target.value,
        PartNo: e.target.selectedOptions[0].label,
        quantityAvailable: Quantity.QuantityAvailable,
        reorderLimit: Quantity.ReorderLimit,
      };
      let ReorderLimit = Quantity?.ReorderLimit ?? 0;

      if (Quantity != null) {
        $("#AvailQuantity").html(
          `Available Quantity is : ${Quantity.QuantityAvailable}`
        );
        $("#ReorderLimit").html(`Reorder Limit Is : ${ReorderLimit}`);
      } else {
        $("#AvailQuantity").html("");
        $("#ReorderLimit").html("");
      }
      setPartNo(temp);
    } else {
      setPartNo(null);
    }
  }
  function deleteSpare(spareId, SparedetailID, spareUsedID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this spare?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (spareUsedID > 0) {
          showLoading();
          obj
            .DeleteSparePart(spareId, SparedetailID, spareUsedID)
            .then((response) => {
              hideLoading();

              showDeleteSuccess();
              var remainSpare = SpareLists.filter((x) => {
                return x.spareDetailID != SparedetailID;
              });
              setSpareLists(remainSpare);
            })
            .catch((error) => {
              hideLoading();
              showErrorMsg("something went wrong please try after sometime.");
            });
        } else {
          var remainSpare = SpareLists.filter((x) => {
            return x.spareDetailID != SparedetailID;
          });
          showDeleteSuccess();
          setSpareLists(remainSpare);
        }
      }
    });
  }

  

  function SpareHandleChange(e) {
    const { name, value } = e.target;
    
    if (PartDropDown != null) {
      // console.log(FilteredListofSpares);
      const partDropDowns = PartDropDown.filter((x) => {
        return Number(x.SpareID) == Number(value);
      });
      const temp = {
        spareID: e.target.value,
        spareName: e.target.selectedOptions[0].label,
      };
      setSpareID(temp);
      var tempList = [];
      partDropDowns.map((x) => {
        tempList.push({ key: x.SparePartNo, value: x.SpareDetailID, ...x });
      });

      setPartDropDownModel(tempList);
      $("#AvailQuantity").html("");
      $("#ReorderLimit").html("");
      $("#PartNoID").val("");
      setPartNo([]);
    }
  }


  return (
    <div>
      {IsAdd ? (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Spare Name{" "}
              </label>
              <WithSelectDropDown
                data={Sparelist}
                placeholder="Select"
                name={"SpareID"}
                HandleChange={SpareHandleChange}
                initialVal={0}
              />

              <span className="text-danger mt-1"> {errors.spareID} </span>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Part No{" "}
              </label>
              <WithSelectDropDown
                data={PartDropDownModel}
                placeholder="Select"
                name={"PartNoID"}
                HandleChange={PartNoHandleChange}
                initialVal={0}
                className={"spareClear"}
              />

              <span className="text-danger pt-1 mt-1">
                {errors.spareDetailID}{" "}
              </span>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Quantity{" "}
              </label>
              <input
                className="form-control"
                type="number"
                pattern="/^-?\d+\.?\d*$/"
                onKeyPress="if(this.value.length==10) return false;"
                placeholder="Please Enter Quantity"
                id="Quantity"
              />
              <span
                id=""
                className="text-danger pt-1 mt-1"
                style={{ minHeight: "20px" }}
              >
                {" "}
                {errors.Quantity}{" "}
              </span>
              <br></br>
              <span
                id="AvailQuantity"
                className="text-info"
                style={{ minHeight: "20px" }}
              >
                {" "}
              </span>
              <br></br>
              <span
                id="ReorderLimit"
                className="text-info"
                style={{ minHeight: "20px" }}
              ></span>
            </div>
          </div>

          <div className="col-md-2 mt-4">
            <button
              type="button"
              id="addRow"
              onClick={AddHandleClick}
              className="btn add-btn"
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <table
        id="SpareDetailes"
        style={{ marginBottom: "3px" }}
        className="table table-striped custom-table mt-3"
      >
        <thead>
          <tr>
            <th>Spare Name</th>
            <th>Part No</th>
            <th>Quantity</th>
            {priviledge.includes("BREAKDOWNSPAREADDDELETE") && <th>Submit</th>}
          </tr>
        </thead>
        <tbody id="tbl1">
          <>
            {SpareLists != null && SpareLists.length > 0 ? (
              SpareLists.map((x) => {
                return (
                  <tr>
                    <td> {x.spareName} </td>
                    <td>{x.partNo}</td>
                    <td>{x.quantity}</td>
                    {priviledge.includes("BREAKDOWNSPAREADDDELETE") && (
                      <td>
                        <button
                          type="button"
                          className="btn icon-btn text-center"
                          onClick={() =>
                            deleteSpare(
                              x.spareID,
                              x.spareDetailID,
                              x.spareUsedID
                            )
                          }
                        >
                          <i className="fa fa-trash m-r-5"></i>
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colspan="6">
                  No Data Found
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  );
};
