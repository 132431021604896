import React from "react";
import { createContext, useState, useContext } from "react";
import { ShortMonthsForPeriodic } from "../../../services/CommonService";

export const PeriodicStartMonthContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => {},
  DropDownInitialState: {},
  PeriodicStartModel: {},
  setPeriodicStartModel: () => {},
  InitialState: {},
  ErrInitialState: {},
  ScheduleTypeOptions: () => {},
});

function PeriodicStartMonthProvider({ children }) {
  const ErrInitialState = {
    YearID: "",
    DepartmentId: "",
    Q1_StartMonth: "",
    Q1_EndMonth: "",
    Q2_EndMonth: "",
    Q3_EndMonth: "",
    Q4_EndMonth: "",
    H1_EndMonth: "",
    H2_EndMonth: "",
    Yearly_EndMonth: "",
    StationID : ""
  };

  const DropDownInitialState = {
    FilterDepartmentList: [],
    StationList: [],
    FilterStation : [],
    DepartmentList: [],
    YearList: {},
    MaintenanceTypeList: [],
    MonthList: [],
    Q1List: [],
    Q2List: [],
    Q3List: [],
    Q4List: [],
    H1_List: [],
    H2_List: [],
    PeriodicStartMonthList: [],
    ErrorObj: ErrInitialState,
  };

  const InitialState = {
    PeriodicScheduleConfigID: 0,
    YearID: {},
    StationID: [],
    DepartmentId: "",
    Q1_StartMonth: {},
    Q1_EndMonth: [],
    Q2_EndMonth: [],
    Q3_EndMonth: [],
    Q4_EndMonth: [],
    H1_EndMonth: [],
    H2_EndMonth: [],
    Yearly_EndMonth: [],
  };

  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [PeriodicStartModel, setPeriodicStartModel] = useState(InitialState);

  function ScheduleTypeOptions(monthId, isEdit = false) {
    let monthsList = [];
    let tempMonth = [...ShortMonthsForPeriodic()].concat(
      ShortMonthsForPeriodic()
    );

    for (var i = 0; i < 4; i++) {
      monthsList.push(tempMonth.slice(monthId - 1, monthId + 2));
      monthId += 3;
    }
    setDropdownModel((pre) => ({
      ...pre,
      Q1List: monthsList[0],
      Q2List: monthsList[1],
      Q3List: monthsList[2],
      Q4List: monthsList[3],
      H1_List: ShortMonthsForPeriodic().splice(0, 6),
      H2_List: ShortMonthsForPeriodic().splice(6),
    }));

    if (!isEdit) {
      setPeriodicStartModel((pre) => ({
        ...pre,
        Q1_EndMonth: InitialState.Q1_EndMonth,
        Q2_EndMonth: InitialState.Q2_EndMonth,
        Q3_EndMonth: InitialState.Q3_EndMonth,
        Q4_EndMonth: InitialState.Q4_EndMonth,
      }));
    }
  }

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
    setPeriodicStartModel,
    PeriodicStartModel,
    InitialState,
    ErrInitialState,
    ScheduleTypeOptions,
  };

  return (
    <PeriodicStartMonthContext.Provider value={contextValue}>
      {children}
    </PeriodicStartMonthContext.Provider>
  );
}

export default PeriodicStartMonthProvider;
