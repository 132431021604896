import React from "react";
import { useState, useEffect, useReducer } from "react";
import { Dropdown } from "../../components/Dropdown";
import SCADAConfigService from "./SCADAConfigService";
import {
  getPrivilege,
  GetSelect,
  GetLoginUserID,
  getUserDetails,
} from "../../services/CommonService";
import Global, { COOKIES } from "../../services/Global";
import swal from "sweetalert";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "../../components/ToastMsg";
import $ from "jquery";
export const ParentTable = ({
  columnList,
  rowList,
  tableName,
  StationDropDownData,
  StatusList,
  AlaramStatus,
  FrequencyNameList,
  Type,
}) => {
  const [isTable, setIsTable] = useState(true);
  const [TempEquimentDetailID, SetTempEquimentDetailID] = useState(0);
  const [TempEquipmentID, SetTempEquipmentID] = useState(0);
  const [RoutineUsage, SetRoutineUsage] = useState([]);
  const [ListPageRender, SetListPageRender] = useReducer((x) => x + 1, 0);

  const SCADAConfigServiceobj = new SCADAConfigService();
  var t;

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable]);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: false,
      autoWidth: false,
      // order: [[2, "asc"]],
      responsive: true,
      columnDefs: [
        {
          responsivePriority: 1,
          targets: -1,
        },
        {
          responsivePriority: 2,
          targets: -2,
        },
        { orderable: false, targets: -1 },
      ],
      order: [[2, "asc"]],
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        //Open this row

        var EquimentDetailID = $(this).closest("tr").find("td:eq(1)").html();
        var EquipmentID = $(this).closest("tr").find("td:eq(3)").html();
        SetTempEquimentDetailID(EquimentDetailID);
        SetTempEquipmentID(EquipmentID);
        SCADAConfigServiceobj.GetSCADADetailesUsingEquipmentID(
          EquipmentID,
          EquimentDetailID,
          Type
        ).then((childRows) => {
          if (
            childRows != null &&
            childRows.data != null &&
            childRows.data.sCADAChildModels != null &&
            childRows.data.sCADAChildModels.length > 0
          ) {
            var rowList = [];
            var sCADAChildModels = childRows.data.sCADAChildModels;
            var statusMastermodels = childRows.data.statusMastermodels;

            // if (statusMastermodels != null && statusMastermodels.length > 0) {
            //   var dropDown = [];
            //   statusMastermodels.map((v) => {
            //     dropDown.push({
            //       key: v.statusName,
            //       value: v.statusID,

            //     });
            //   });

            //   SetRoutineUsage(dropDown)

            // }

            if (sCADAChildModels != null && sCADAChildModels.length > 0) {
              sCADAChildModels.map((v, i) => {
                var rows = [];
                console.log(v);
                rows.push(EquipmentID);
                rows.push(EquimentDetailID);
                rows.push(v.scadaid);
                rows.push(v.parameterName);
                rows.push(v.aT30HZ);
                rows.push(v.aT30HZ);
                rows.push(v.aT50HZ);
                rows.push(v.aT50HZ);
                rows.push(v.acceptableParameterLow);
                rows.push(v.acceptableParameterLow);
                rows.push(v.acceptableParameterHigh);
                rows.push(v.acceptableParameterHigh);
                rows.push(v.parameterUnit);
                rows.push(v.routineUsage);
                rows.push(v.routineUsage);
                rows.push(v.parameterID);
                rows.push(v.frequencyName);
                rows.push(v.frequencyName);
                rows.push(v.thresholdLow);
                rows.push(v.thresholdLow);
                rows.push(v.thresholdHigh);
                rows.push(v.thresholdHigh);
                rows.push(v.alaramORTrand);
                rows.push(v.alaramORTrand);
                rows.push(v.scadaEquipmentCode);
                rows.push(v.scadaEquipmentCode);
                rows.push(i + 1);
                rows.push("");

                rowList.push(rows);
              });
            }

            row.child(ChildTable(EquimentDetailID)).show();

            //set child table
            childTable(rowList, childColumns, EquimentDetailID);
          } else {
            row.child(ChildTable(EquimentDetailID)).show();

            //set child table
            childTable([], childColumns, EquimentDetailID);
          }
        });
        // row.child(ChildTable(EquipmentID)).show();
        // //set child table
        // childTable([], childColumns, EquipmentID);

        tr.addClass("shown");
      }
    });

    return function () {
      table.destroy();
    };
  };

  const RenderListload = () => {

    //set data table
    const table = $(`#${tableName}`).DataTable({
      // data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: false,
      autoWidth: false,
      // order: [[2, "asc"]],
      responsive: true,
      columnDefs: [
        {
          responsivePriority: 1,
          targets: -1,
        },
        {
          responsivePriority: 2,
          targets: -2,
        },
        { orderable: false, targets: -1 },
      ],
      order: [[2, "asc"]],
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);
      var EquimentDetailID = $(this).closest("tr").find("td:eq(1)").html();
      var EquipmentID = $(this).closest("tr").find("td:eq(3)").html();
      SetTempEquimentDetailID(EquimentDetailID);
      SetTempEquipmentID(EquipmentID);
      SCADAConfigServiceobj.GetSCADADetailesUsingEquipmentID(
        EquipmentID,
        EquimentDetailID,
        Type
      ).then((childRows) => {
        if (
          childRows != null &&
          childRows.data != null &&
          childRows.data.sCADAChildModels != null &&
          childRows.data.sCADAChildModels.length > 0
        ) {
          var rowList = [];
          var sCADAChildModels = childRows.data.sCADAChildModels;
          if (sCADAChildModels != null && sCADAChildModels.length > 0) {
            sCADAChildModels.map((v, i) => {
              var rows = [];
              console.log(v);
              rows.push(EquipmentID);
              rows.push(EquimentDetailID);
              rows.push(v.scadaid);
              rows.push(v.parameterName);
              rows.push(v.aT30HZ);
              rows.push(v.aT30HZ);
              rows.push(v.aT50HZ);
              rows.push(v.aT50HZ);
              rows.push(v.acceptableParameterLow);
              rows.push(v.acceptableParameterLow);
              rows.push(v.acceptableParameterHigh);
              rows.push(v.acceptableParameterHigh);
              rows.push(v.parameterUnit);
              rows.push(v.routineUsage);
              rows.push(v.routineUsage);
              rows.push(v.parameterID);
              rows.push(v.frequencyName);
              rows.push(v.frequencyName);
              rows.push(v.thresholdLow);
              rows.push(v.thresholdLow);
              rows.push(v.thresholdHigh);
              rows.push(v.thresholdHigh);
              rows.push(v.alaramORTrand);
              rows.push(v.alaramORTrand);
              rows.push(v.scadaEquipmentCode);
              rows.push(v.scadaEquipmentCode);
              rows.push(i + 1);
              rows.push("");

              rowList.push(rows);
            });
          }

          row.child(ChildTable(EquimentDetailID)).show();
          childTable(rowList, childColumns, EquimentDetailID);
        } else {
          row.child(ChildTable(EquimentDetailID)).show();
          childTable([], childColumns, EquimentDetailID);
        }
      });
      // tr.addClass("shown");
    });

    return function () {
      table.destroy();
    };
  };

  const childTable = (rowList, childColumns, eqptCodeID) => {
    // set child table as data table
    t = $(`#comm_detail-${eqptCodeID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      paging: false,
      bInfo: false,
      scrollX: true,
      autoWidth: false,

      order: [[27, "desc"]],
    });

    updateValues(t, eqptCodeID);
  };

  const ChildTable = (tableIndex) => {
    return `<table id="comm_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" >
          </table>`;
  };

  var childColumns = [
    {
      title: "EquipmentID",
      className: "hide",
    },
    {
      title: "EquimentDetailID",
      className: "hide",
    },
    {
      title: "scadaid",
      className: "hide",
    },
    {
      title: "Parameter Name",
      className: "align-middle text-center",
      // createdCell: (td, cellData, rowData, row, col) => {
      //   var b = $(
      //     `<input type="text" pattern="/^-?\d+\.?\d*$/"   class="form-control" value="${cellData}"/>`
      //   );
      //   $(td).html(b);
      // },

      width: "300px",
    },

    {
      title: "aT30HZ",
      className: "hide",
    },
    {
      title: "Comm. <br/> At 30Hz",
      className: "hide align-middle text-center",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"  class="form-control" value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },

    {
      title: "At 50Hz-Hidden",
      className: "hide",
    },
    {
      title: " Comm. <br/> At 50Hz",
      className: " align-middle text-center",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "Parameter Range Low",
      className: "hide",
    },
    {
      title:
        "<span title = 'Acceptble Parameter Range Low '> Param. <br/>Range Low <span>",
      className: "align-middle text-center",

      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: " Parameter Range High",
      className: "hide",
    },
    {
      title:
        "<span title = 'Acceptble Parameter Range High '> Param. <br/>Range High <span>",
      className: "align-middle text-center",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },

    {
      title: "Units",
      className: "align-middle text-center",
    },
    {
      title: "Routine Usage",
      className: "hide",
    },
    {
      title: "Routine Usage",
      className: "align-middle text-center",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(GetSelect(StatusList, cellData)).on(
          "change",
          function (e) { }
        );
        $(td).html(b);
      },
    },
    {
      title: "parameter ID",
      className: "hide",
    },
    {
      title: "Frequency ",
      className: "hide",
    },
    {
      title: "Time Frequency",

      className: "align-middle text-center hide",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="text" onKeyPress="if(this.value.length==10) return false;" class="form-control" id='FrequencyName' value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "Threshold value Low ",

      className: "align-middle text-center hide",
    },
    {
      title: "Threshold Value low",
      width: "40px",
      //  className: "hide",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10)  return false;" class="form-control" id='ThresholdvalueLow' value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "Threshold value High",
      className: "align-middle text-center hide",
    },
    {
      title: "Threshold Value High",
      // className: "hide",
      width: "40px",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" id='ThresholdvalueHigh' value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "Alram Or Trand",
      className: "hide",
    },
    {
      title: "Alaram Or Trend",
      className: "align-middle text-center hide",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(GetSelect(AlaramStatus, cellData)).on(
          "change",
          function (e) { }
        );
        $(td).html(b);
      },
    },
    {
      title: "SCADA Equipmentcode",
      className: "align-middle text-center hide",
    },
    {
      title: "SCADA Equipmentcode",
      // className: "hide",

      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="text" title="${cellData}" style="width:auto" onKeyPress="if(this.value.length==100) return false;" class="form-control pseudo-tooltip-wrapper" id='scadaEquipmentCode' value="${cellData}"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "row ID",
      className: "hide",
    },
   
    {
      
      title: "Submit",
      className: "align-middle text-center",
      createdCell: (td, cellData, rowData, row, col) => {
        let deletebtn = "";
        let savebtn = "";
        let Fragment = $(`<s></s`);
        savebtn = $(`<button class='btn icon-btn' title='Save' id="save_Scada">
        <i class='fa fa-check' style="margin-top:10px"></i>
    </button>`).on("click", function () {
          SaveSCADADetailes(rowData, row);
        });
        deletebtn =

          $(` <button class='btn icon-btn delete_${rowData[2]}' title='Delete' id="save_Scadas">
        <i class='fa fa-trash' style="margin-top:10px;margin-left:10px;"></i>
    </button>`).on("click", function () {
            DeleteAttachmentDocument(td, cellData, rowData, row, col);
          });
        if (getPrivilege().includes("SCADAEDIT")) {
          Fragment.append(savebtn);
        }
        // if (getUserDetails().RoleID == COOKIES.OperationRoleID) {
          if (getPrivilege().includes("SCADADELETE")) {
        Fragment.append(deletebtn);
        }

        $(td).html(Fragment);
      }
      ,
    },
  ];

  const DeleteAttachmentDocument = (td, cellData, rowData, row, col) => {

    // var ee = $(this).closest("s").find(`.delete_${rowData[2]}`);
    swal({
      title: "Are You Sure?",
      text: "Do You Want to Delete this SCADA?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteSCADADetailes(td, cellData, rowData, row, col);
        // DeleteAttachment(TicketId, AttachID, Flag);
      }
    });
  };

  const DeleteSCADADetailes = (td, cellData, rowData, row, col) => {

    var ScadaDetailes = {
      SCADAID: Number(rowData[2]),
    };
    let createdBy = Number(GetLoginUserID());

    SCADAConfigServiceobj.DeleteScadabyID(ScadaDetailes.SCADAID, createdBy)
      .then((res) => {
        console.log(res.data);
        // SetListPageRender();
        // RenderListload();
        $(`.delete_${rowData[2]}`).closest("tr").remove();

        showDeleteSuccess("Deleted Sucessfully.");
      })
      .catch((err) => {
        showDeleteSuccess("Something Wrong please Try Again.");
        console.log(err);
      });
  };
  const SaveSCADADetailes = (data, row) => {
    var frequencyName = data[16].replace(" ", "");
    var ScadaDetailes = {
      EquipmentID: data[0],
      ParameterName: data[3],
      EquipmentDetailID: Number(data[1]),
      SCADAID: Number(data[2]),
      AT30Hz: Number(data[4]),
      AT50Hz: Number(data[6]),
      AcceptableParameterLow: Number(data[8]),
      AcceptableParameterHigh: Number(data[10]),
      RoutineUsage: Number(data[13]),
      ParameterID: Number(data[15]),
      frequencyName: frequencyName,
      thresholdLow: Number(data[18]),
      thresholdHigh: Number(data[20]),
      parameterUnit: data[12],
      AlaramORTrand: Number(data[22]),
      ScadaEquipmentCode: data[24],
    };

    var flag = true;
    var IsFine = true;
    if (
      ScadaDetailes.frequencyName != null &&
      ScadaDetailes.frequencyName != ""
    ) {
      var k = ScadaDetailes.frequencyName.split("-");
      if (k.length == 2) {
        var regex1 = /^[0-9]+$/;

        if (regex1.test(k[0].trim())) {
          if (
            k[1].trim().toUpperCase() == "S" ||
            k[1].trim().toUpperCase() == "H" ||
            k[1].trim().toUpperCase() == "M"
          ) {
          } else {
            flag = false;
          }
        } else {
          flag = false;
        }
      } else {
        flag = false;
      }
    }
    // if (
    //   ScadaDetailes.AcceptableParameterLow >=
    //   ScadaDetailes.AcceptableParameterHigh
    // ) {
    //   showWarningMsg(
    //     "The acceptable parameter high must be greater than the acceptable parameter low"
    //   );
    //   IsFine = false;
    // }
    const {
      AcceptableParameterLow,
      AcceptableParameterHigh,
      thresholdLow,
      thresholdHigh,
    } = ScadaDetailes;

    // if (
    //   !(
    //     AcceptableParameterLow >= thresholdLow &&
    //     AcceptableParameterHigh >= thresholdHigh
    //   )
    // ) {
    //   showWarningMsg("The threshold value must be within an acceptable range.");
    //   IsFine = false;
    // }

    if (!flag)
      showWarningMsg(
        "Frequency Must be type Following Format : Eg : 10-s ,2-h,1-m"
      );

    if (flag && IsFine) {
      $("#save_Scada").prop("disabled", true);
      // consol
      SCADAConfigServiceobj.ScadaDetailesSave(ScadaDetailes)
        .then((res) => {
          console.log(res.data);

          showSaveSuccess("Saved Sucessfully.");
          $("#save_Scada").prop("disabled", false);

          // $(`#save-${data[0]}+${data[1]}`).attr("disabled", false);
          t.cell({ row: row, column: 2 }).data(res.data);
        })
        .catch((err) => {
          showSaveSuccess("Something Wrong please Try Again.");
          $("#save_Scada").prop("disabled", false);

          // $(`#save-${data[0]}+${data[1]}`).attr("disabled", false);
          console.log(err);
        });
    }
  };

  const updateValues = (t, eqptCodeID) => {
    $(`#comm_detail-${eqptCodeID}  tbody  tr`).each(function (index, tr) {
      var row = t.row(this);
      var rowIndex = t.row(this).index();

      // for AT30HTZ
      var inputCell = $(this).closest("tr").find("td:eq(5)");
      inputCell.on("change", function (e) {
        t.cell({ row: rowIndex, column: 4 }).data(e.target.value);
      });
      // for AT50HTZ
      var inputCell2 = $(this).closest("tr").find("td:eq(7)");
      inputCell2.on("change", function (e) {
        t.cell({ row: rowIndex, column: 6 }).data(e.target.value);
      });
      // for Acc parameter low
      var inputCell3 = $(this).closest("tr").find("td:eq(9)");
      inputCell3.on("change", function (e) {
        t.cell({ row: rowIndex, column: 8 }).data(e.target.value);
        // var val = Number(e.target.value);
        // if(val != null )
        // {
        //   val = val * 0.9
        //   t.cell({ row: rowIndex, column: 19 }).data(val);
        // }
      });
      // for Acc parameter high
      var inputCell4 = $(this).closest("tr").find("td:eq(11)");
      inputCell4.on("change", function (e) {
        t.cell({ row: rowIndex, column: 10 }).data(e.target.value);
        var val = Number(e.target.value);
        if (val != null) {
          val = (val * 0.9).toFixed(2);

          $(this).closest("tr").find("td:eq(21) input").val(val);
          t.cell({ row: rowIndex, column: 20 }).data(val);
        }
      });
      //Routine Usage
      var inputCell5 = $(this).closest("tr").find("td:eq(14)");
      inputCell5.on("change", function (e) {
        t.cell({ row: rowIndex, column: 13 }).data(e.target.value);
      });

      var inputCell6 = $(this).closest("tr").find("td:eq(17)");
      inputCell6.on("keydown", function (e) {
        $(`#FrequencyName`).autocomplete({
          source: FrequencyNameList,
        });
        t.cell({ row: rowIndex, column: 16 }).data(e.target.value);
      });

      var inputCell7 = $(this).closest("tr").find("td:eq(17)");
      inputCell7.on("change", function (e) {
        t.cell({ row: rowIndex, column: 16 }).data(e.target.value);
      });

      var inputCell8 = $(this).closest("tr").find("td:eq(19)");
      inputCell8.on("change", function (e) {
        t.cell({ row: rowIndex, column: 18 }).data(e.target.value);
      });
      var inputCell9 = $(this).closest("tr").find("td:eq(21)");
      inputCell9.on("change", function (e) {
        t.cell({ row: rowIndex, column: 20 }).data(e.target.value);
      });

      var inputCell10 = $(this).closest("tr").find("td:eq(23)");
      inputCell10.on("change", function (e) {
        t.cell({ row: rowIndex, column: 22 }).data(e.target.value);
      });

      var inputCell11 = $(this).closest("tr").find("td:eq(25)");
      inputCell11.on("change", function (e) {
        t.cell({ row: rowIndex, column: 24 }).data(e.target.value);
      });
    });
  };

  return (
    <>
      {/* <Header title={"SCADA Configuration"} ></Header> */}
      <div className="table-responsive " style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col" style={{ width: "100px" }}>
            {isTable ? (
              <table
                className="table table-striped no-footer display collapse-table"
                id={tableName}
                width="100%"
              ></table>
            ) : (
              /* No of coulms and no of rows must be equal*/
              <div className="row col-12 text-center mt-2 mb-2">
                <p className="text-muted">Table not found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
