import React, { useEffect, useState, useReducer } from "react";
import { Calendar, Navigate, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CheckList from "./CheckList";
import {
  GetCurrentWeek,
  GetCurrentWeekInDate,
  YMDtoDate,
  addDays,
  getDDMMYYdate,
  getUserDetails,
  getYMDdate,
} from "../../services/CommonService";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { AppContext } from "../../services/ContextProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { showWarningMsg } from "../../components";

export default function CheckListDailyCalender() {
  const {  TypeDetails, HandleTypeDetails } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [ListPageRender, SetListPageRender] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const buttons = document.querySelectorAll("button"); // Select all buttons

      buttons.forEach((button) => {
        if (button.textContent === "Back") {
          button.style.float = "left"; // Apply your custom styles
          // You can apply any other styles as needed
        }
      });
    }, [ListPageRender]);

  function DailyCheckList({
    date,
    localizer,
    max = localizer.endOf(new Date(), "day"),
    min = localizer.startOf(GetCurrentWeekInDate(), "day"),
    scrollToTime = localizer.startOf(GetCurrentWeekInDate(), "day"),
    RenderView,
    ...props
  }) {
    return <CheckList InitialScheduleType={43}></CheckList>;
  }

  DailyCheckList.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  DailyCheckList.title = (date) => {
    return getDDMMYYdate(date);
  };

  DailyCheckList.navigate = (date, action, { localizer }) => {
    let returnDate = date;
    let IncrementNumber = 1;
    switch (action) {
      case Navigate.PREVIOUS:
        returnDate = localizer.add(date, -IncrementNumber, "day");
        break;
      case Navigate.NEXT:
        returnDate = localizer.add(date, IncrementNumber, "day");
        break;
      default:
        returnDate = new Date();
        break;
    }
    if (getYMDdate(returnDate) > getYMDdate(new Date())) {
      showWarningMsg("You won't be able to enter values in the future .");
    }
    HandleTypeDetails("CheckDate", getYMDdate(returnDate));
    return returnDate;
  };

  function WeeklyCheckList({
    date,
    localizer,
    max = localizer.endOf(new Date(), "day"),
    min = localizer.startOf(GetCurrentWeekInDate(), "day"),
    scrollToTime = localizer.startOf(GetCurrentWeekInDate(), "day"),
    RenderView,
    ...props
  }) {
    return <CheckList InitialScheduleType={44}></CheckList>;
  }

  WeeklyCheckList.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  WeeklyCheckList.title = (date) => {
    return getDDMMYYdate(date);
  };

  WeeklyCheckList.navigate = (date, action, { localizer }) => {
    let returnDate = date;
    let IncrementNumber = 7;

    switch (action) {
      case Navigate.PREVIOUS:
        if (new Date(date).getDate() == 1) {
          
          returnDate = new Date(GetCurrentWeek(addDays(date, -1)));
        } else {
          returnDate = localizer.add(date, -IncrementNumber, "day");
        }
        break;
      case Navigate.NEXT:
        if (new Date(date).getDate() == 22) {
          returnDate = new Date(GetCurrentWeek(addDays(date, 10)));
        } else {
          returnDate = localizer.add(date, IncrementNumber, "day");
        }
        //returnDate = localizer.add(date, IncrementNumber, "day");
        break;
      default:
        returnDate = new Date(GetCurrentWeek());

        break;
    }
    if (GetCurrentWeek(returnDate) > getYMDdate(new Date())) {
      showWarningMsg("You won't be able tpo enter values in the future .");
    }

    HandleTypeDetails("CheckDate", GetCurrentWeek(returnDate));

    return new Date(GetCurrentWeek(returnDate));
  };

  const localizer = momentLocalizer(moment);

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate:
        TypeDetails.ScheduleType == 43
          ? YMDtoDate(TypeDetails.CheckDate)
          : new Date(GetCurrentWeek()),
      views: {
        day: DailyCheckList,
        week: WeeklyCheckList,
      },
    }),
    []
  );

  const MyToolbar = (toolbar) => {
    const goToDate = (Type) => {
      toolbar.onNavigate(Type);
      SetListPageRender();
    };

    return (
      <>
        <div class="rbc-toolbar">
          <span class="rbc-btn-group">
            <button type="button" onClick={() => goToDate("CUSTOM_TODAY")}>
              Today
            </button>
            <button type="button" onClick={() => goToDate("PREV")}>
              Back
            </button>
            <button type="button" onClick={() => goToDate("NEXT")}>
              Next
            </button>
          </span>
          <span className="rbc-toolbar-label">
            {toolbar.view === "day"
              ? toolbar.label
              : `${moment(toolbar.date).format("DD MMMM")} - ${moment(
                  toolbar.date
                )
                  .add(6, "days")
                  .format("DD MMMM")}`}
          </span>
          <span class="rbc-btn-group">
            <button
              type="button"
              onClick={() => {
                toolbar.onView("day");
                HandleTypeDetails("ScheduleType", 43);
                HandleTypeDetails("CheckDate", getYMDdate(new Date()));
                SetListPageRender();
              }}
            >
              Day
            </button>
            <button
              type="button"
              onClick={() => {
                toolbar.onView("week");
                HandleTypeDetails("ScheduleType", 44);
                HandleTypeDetails("CheckDate", getYMDdate(GetCurrentWeek()));
                SetListPageRender();
              }}
            >
              Week
            </button>
          </span>
          <span class="rbc-btn-group"></span>

          <span class="rbc-btn-group  text-end">
            <button
              id="addRow_ers"
              onClick={() => {
                navigate("/CheckListCalenderView");
                HandleTypeDetails("ScheduleType", 43);
              }}
              className="btn btn-secondary text-end"
            >
              Go back
            </button>
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      className="card height600"
    >
      <div className="card-body">
        <Calendar
          defaultDate={defaultDate}
          date={TypeDetails.CheckDate}
          defaultView={Views[TypeDetails.ScheduleType == 43 ? "DAY" : "WEEK"]}
          events={[]}
          components={{
            toolbar: MyToolbar,
          }}
          selectable
          localizer={localizer}
          views={views}
        />
      </div>
    </div>
  );
}
