import React from "react";
import { Outlet } from "react-router";
import { useContext } from "react";
import { AppContext } from "./../services/ContextProvider";
import { ToastMsg } from "./ToastMsg";

export const WithoutNav = () => {
  const { loadingStatus } = useContext(AppContext);

  var loadingStyle = !loadingStatus ? { display: "none" } : {};

  return (
    <div>
      {/* Loader */}
      <div className="loader-div" style={loadingStyle}>
        <span className="loader"></span>
      </div>

      {/* Content */}
      <div>
        <ToastMsg />
        <Outlet />
      </div>
    </div>
  );
};
