import React, { useState, useEffect, useContext, useCallback } from "react";
import SpareService from "./SpareService";
import { AppContext } from "../../services/ContextProvider";
import {
  BootrapModel,
  ReactMultiSelect,
  Dropdown,
  HtmlTable,
  showSaveSuccess,
  showSaveError,
  ReactAutoComplete,
  ReactMultiselectTreeView,
} from "../../components";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { Link } from "react-router-dom";
import { DataTableReports } from "../../components/DataTableReports";
import Global from "../../services/Global";
import { BuildTreeUsingParentID, Decrypt, findDepartmentById, getPrivilege } from "../../services/CommonService";
import MasterDetailService from "../master/MasterDetailService";
import { Typeahead } from "react-bootstrap-typeahead";
import $ from "jquery";
import swal from "sweetalert";
import Autocomplete from "react-autocomplete";

const _spareService = new SpareService();
const _masterDetailService = new MasterDetailService();

export const SpareDetails = () => {
  const TableColumns = [
    {
      className: "dt-control",
      orderable: false,
      data: null,
      defaultContent: "",
      width: "2.06rem",
    },
    { title: "Station ID", className: "hide" },
    { title: "Station Code" },
  ];
  const SpareTableColumns = [
    {
      title: "Department Name",
      data: "SubSystem", // 2
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Station Code",
      data: "StationCode", // 2
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Equipment Description",
      data: "SystemTypeName",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "Spare Name",
      data: "SpareName",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "Part Number", //9
      data: "SparePartNo",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Spare Make", //11
      data: "SpareMake",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Recommended Quantity", // 13
      data: "SpareQuantity",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "Available Quantity", //15
      data: "QuantityAvailable",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "Reorder Limit", // 17
      data: "ReorderLimit",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "Spare Location", // 19
      data: "SpareLocation",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },

    {
      title: "UOM", // 21
      data: "MeasurementName",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Supplier Name", // 25
      data: "SupplierName",
      createdCell: (td, cellData, rowData, row, col) => {
        let NoReorderLimit = rowData.ReorderLimit;
        let NoQuantityAvailable = rowData.QuantityAvailable;
        if (NoReorderLimit > NoQuantityAvailable) {
          // Example condition, you should adjust as per your requirement
          $(td).addClass("text-danger");
        }
      },
    },
    {
      title: "Submit", // 27
      data: "SpareDetailID",
      visible:
        getPrivilege().includes("SPAREINVENTORYEDIT") ||
        getPrivilege().includes("SPAREINVENTORYCREATE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "",
          c = "";

        if (
          getPrivilege().includes("SPAREINVENTORYEDIT") ||
          getPrivilege().includes("SPAREINVENTORYCREATE")
        ) {
          b = $(`<button          
          data-bs-toggle='modal'
          data-bs-target='#SpareDetailsCreation'          
          class='btn icon-btn m-r-10' title='Save'>
        <i class='fa fa-edit' style="margin-top:10px"></i>
      </button>`).on("click", function () {
            getSpareDetails(rowData.SpareDetailID, 2);
          });
        }

        if (getPrivilege().includes("SPAREINVENTORYDELETE")) {
          c = $(`<button class='btn icon-btn' title='Delete'>
       <i class='fa fa-trash' style="margin-top:10px"></i>
     </button>`).on("click", function () {
            swal({
              title: "Are you sure?",
              text: "Do you want to delete this Spare Detail?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                getSpareDetails(rowData.SpareDetailID, 1);
              }
            });
            // DeleteSpare(rowData, row);
          });
        }

        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [spareList, setSpare] = useState([]);

  const initialFromData = {
    FactoryModel: "",
    SpareMake: "",
    SpareLocation: "",
    MeasurementName: "",
    SpareQuantity: 0,
    QuantityAvailable: 0,
    SupplierName: "",
    ReorderLimit: 0,
    StationIDs: "",
    SpareID: {},
    SparePartNo: "",
    DepartmentId: 0,
    EquipmentTypeID: 0,
    ShelfLife: "",
  };

  const [formData, setFormData] = useState(initialFromData);
  const [StationList, setStationList] = useState([]);
  const [SubsustemList, setSubsystemList] = useState([]);
  const [StationID, setStationID] = useState(0);
  const [subsystemID, setsubsystemID] = useState(0);

  const [SpareDetailsData, setSpareDetailsData] = useState([]);

  const [isReport, setIsReport] = useState(false);

  useEffect(() => {
    getStationList();
    getSubsystemList();
  }, []);

  useEffect(() => {
    if (StationID > 0) {
      getSpareDetails();
    }
  }, [StationID, subsystemID]);

  const HandleChange = (e) => {

    setStationID(e.target.value);
    var selectedOption = e.target.options[e.target.selectedIndex];

    var selectedLabel = selectedOption.text;
    handleGlobalStationName(selectedLabel ?? "");
  };
  const HandleChangee = (e) => {

    setsubsystemID(e.target.value);
    // var selectedOption = e.target.options[e.target.selectedIndex];

    // var selectedLabel = selectedOption.text;
    // handleGlobalStationName(selectedLabel ?? "");
  };

  const getStationList = async () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, key: data.StationCode });
    });
    handleGlobalStationName(dataLst[0].key);
    setStationList(dataLst);
    setStationID(dataLst[0].value);
  };
  const getSubsystemList = async () => {
    let Department = [];
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));
    const itemsWithoutChildren = DepartmentList.filter(
      (item) => !DepartmentList.some((child) => child.parentId === item.id)
    );
    itemsWithoutChildren.map((data) => {
      Department.push({ value: data.id, key: data.name, Type: data.Type });
    });
    const updatedSubsystemList = [
      { key: 'All', value: '0' },
      ...Department
    ];
    setSubsystemList(updatedSubsystemList);
    // 
    // _spareService
    //   .getSubsystemList()
    //   .then((res) => {
    //     if (res.data != null && res.data.subsystemList.length > 0) {
    //       const updatedSubsystemList = [
    //         { key: 'All', value: '0' },
    //         ...res.data.subsystemList
    //       ];
    //       setSubsystemList(updatedSubsystemList);
    //     } else {
    //       setSubsystemList([]);
    //     }
    //   });
  };
  const getSpareDetails = (spareDetailID = 0, IsDeleted = 0) => {
    _spareService
      .GetSpareDetailsByID(spareDetailID, IsDeleted, StationID, subsystemID)
      .then((res) => {

        if (res != null && res.isSuccess && res.data?.length > 0) {
          if (spareDetailID > 0 && IsDeleted == 2) setFormData(res.data[0]);
          else setSpareDetailsData(res.data);
        } else {
          setSpareDetailsData([]);
          setFormData({});
        }
      });
  };

  const ClosePopupModel = () => {
    getSpareDetails();
    setFormData({ ...initialFromData });
  };

  const closepopup = () => {
    setIsReport(false);
  };
  const Header = ({ title }) => {
    return (
      <div className="row">
        <div className="page-header mb-3 col-6">
          <div className="">
            <h3 className="page-title">{title}</h3>
          </div>
        </div>
        <div className="col-6 row justify-content-end">
          {/* {getPrivilege().includes("MASTERCREATE") && */}

          <button
            className="btn btn-primary mright hide"
            id="popupbutton"
            disabled={isReport}
            // data-bs-toggle="modal"
            // data-bs-target="#SpareHistoryDetails"
            onClick={() => setIsReport(true)}
          >
            {!isReport && (
              <span>
                <i
                  style={{ fontSize: "18px" }}
                  className="las la-file-excel"
                  title="Excel download"
                ></i>
              </span>
            )}

            <span>{!isReport ? "Excel" : "Please wait..."} </span>
          </button>

          <Link
            to={{
              pathname: "/Master",
            }}
            state={{ Type: "SPARE" }}
            className="hide"
          >
            <button
              type="button"
              id="addRow"
              // onClick={history.pushState()}
              className="btn add-btn"
            >
              <i className="fa fa-plus" title="Spare"></i> Add Spare
            </button>
          </Link>
          <div className="col-4 m-r-5">
            <WithoutSelectDropDown
              data={SubsustemList}
              value={subsystemID}
              name={"SubSystemID"}
              HandleChange={HandleChangee}
            ></WithoutSelectDropDown>
          </div>
          <div className="col-3 m-r-5">
            <WithoutSelectDropDown
              data={StationList}
              value={StationID}
              name={"StationIDDropDown"}
              HandleChange={HandleChange}
            ></WithoutSelectDropDown>
          </div>
          <button
            className="btn btn-primary col-4"
            style={{ padding: "0px", height: "35px" }}
            id="SpareDetailCreation"
            data-bs-toggle="modal"
            data-bs-target="#SpareDetailsCreation"
            onClick={() => {
              setFormData({ ...initialFromData });
            }}
          >
            <i className="fa fa-plus" title="Spare Details Add"></i>
            {"  "} Add Spare details
          </button>

          {/* } */}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header title={"Equipment Spare Details"} />

      {/* Uncomment if required report as popup */}

      {/* <BootrapModel
          Id={"SpareHistoryDetails"}
          modelSize={"modal-xl"}
          ModelTitle={"Spares"}
          module={isReport && <SpareDataModel /> }
          closepopup={closepopup}
        />  */}

      {/* Uncomment if required report as excel download alone */}

      {isReport && <SpareDataModel setIsReport={setIsReport} />}

      <BootrapModel
        Id={"SpareDetailsCreation"}
        CloseID={"SpareDetailsCreationCloseId"}
        modelSize={"modal-xl"}
        ModelTitle={"Spare Details"}
        module={<SpareDetailsCreation getByData={{ ...formData }} />}
        closepopup={ClosePopupModel}
      />
      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={SpareTableColumns}
            rowList={SpareDetailsData}
            id={"SpareDetailsTable"}
            FixedColums={{
              left: 1,
              right: 1,
            }}
            title="Spare Details"
            IsExcelNeed
            isShowPdf
            exportOptions={{
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            }}
          ></HtmlTable>
        </div>
      </div>
      {/* old StationWise Spare Creation */}
      {/* <div className="card card-body">
                  <SpareDetailsTable
                    columnList={TableColumns}
                    rowList={tableRows}
                    tableName={`Spare_Details${1}`}
                    // spare={spareList}
                  />
                </div> */}
    </div>
  );
};

const SpareDataModel = ({ setIsReport }) => {
  const [spareList, setSpareList] = useState([]);
  const { showLoading, hideLoading } = useContext(AppContext);

  const columns = [
    { title: "Serial No" },
    { title: "Equipment" },
    // { title: "Equipment Type" },
    { title: "Spare Description" },
    { title: "Spare Name" },
    { title: "Part Number" },
    { title: "Recommended Quantity" },
    { title: "Available Quantity" },
    { title: "Reorder Limit" },
    { title: "Spare Location" },
  ];

  const GetSpareHistory = () => {
    _spareService
      .SpareReportHistoryDetails()
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.RowId);
            row.push(v.EquipmentShortName);
            // row.push(v.SystemTypeName);
            row.push(v.FactoryModel);
            row.push(v.SpareName);
            row.push(v.SparePartNo);
            row.push(v.SpareQuantity);
            row.push(v.QuantityAvailable);
            row.push(v.ReorderLimit);
            row.push(v.SpareLocation);

            rowList.push(row);
          });
          setSpareList(rowList);

          setTimeout(() => {
            setIsReport(false);
          }, 1000);
        } else {
          setSpareList([]);
          setIsReport(false);
        }
      })
      .catch((error) => {
        setSpareList([]);
        setIsReport(false);
      });
  };
  useEffect(() => {
    GetSpareHistory();
  }, []);

  return (
    <DataTableReports
      columnList={columns}
      rowList={spareList}
      id={"SparesHistoryTable"}
      title={"Spare Details"}
      isAsc={true}
      isVisible={false}
    />
  );
};

const SpareDetailsCreation = ({ getByData = {} }) => {
  const InitialState = {
    FactoryModel: "",
    SpareMake: "",
    SpareLocation: "",
    MeasurementName: "",
    SpareQuantity: 0,
    QuantityAvailable: 0,
    SupplierName: "",
    ReorderLimit: 0,
    StationIDs: [],
    SpareID: {},
    SparePartNo: "",
    ShelfLife: "",
    DepartmentID: 0,
    EquipmentTypeID: 0,
    OHEDepartmentID: '',
  };

  const [formData, setFormData] = useState(InitialState);
  const [errors, setErrors] = useState({});
  const [stationList, setStationList] = useState([]);
  const [DropdownData, setDropdownData] = useState({
    StationList: [],
    SpareList: [],
    FactoryModelList: [],
    SpareMakeList: [],
    SpareLocationList: [],
    SpareUOM: [],
    DepartmentList: [],
    EquipmentList: [],
    OHEDepList: [],
  });
  const [EquipmentFilterList, SetEquipmentFilterList] = useState([]);

  const { getCookies } = useContext(AppContext);
  const [OnSelect, setOnSelect] = useState('');
  const [treeData, settreeData] = useState([]);
  const [IsDepartment, SetIsDepartment] = useState(0);

  const handleChange = (event, name) => {
    if (name) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: event,
      }));
    } else {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {

    const { DepartmentID } = formData;

    const filterlist = DropdownData.EquipmentList.filter((item) => item.DepartmentID == DepartmentID);
    SetEquipmentFilterList(filterlist);

    if (DropdownData.OHEDepList.length > 0 && DropdownData.OHEDepList.find(item => item.OHEDepartmentIDs == DepartmentID)) {
      const filterStationList = DropdownData.StationList.filter((item) => item.type == 95)
      setStationList(filterStationList);
    } else {
      const filterStationList = DropdownData.StationList.filter((item) => item.type != 95)
      setStationList(filterStationList);
    }


  }, [formData.DepartmentID])

  useEffect(() => {
    getStationList();
    getSpare();
    getDropDownList();
    getAutocompletedata();
  }, []);

  useEffect(() => {
    if (getByData != null && getByData.SpareID > 0) {
      let tempEditData = { ...getByData };
      let stationList = [];
      let obj = findDepartmentById(treeData, tempEditData?.DepartmentID);
      setOnSelect(obj?.name ?? "");
      if (getByData?.StationIDs?.length)
        stationList = getByData?.StationIDs.split(",").map((str) =>
          parseInt(str, 10)
        );
      let SpareID = DropdownData.SpareList?.find(
        (x) => x.value == getByData.SpareID
      );
      let FactoryModel = DropdownData.FactoryModelList.find(
        (x) => x.label == getByData.FactoryModel
      );
      let MeasurementName = DropdownData.SpareUOM.find(
        (x) => x.label == getByData.MeasurementName
      );
      let spareMake = DropdownData.SpareMakeList.find(
        (x) => x.label == getByData.SpareMake
      );
      let SpareLocation = DropdownData.SpareLocationList.find(
        (x) => x.label == getByData.SpareLocation
      );
      let StationIds = DropdownData.StationList?.filter((x) =>
        stationList?.includes(x.value)
      );
      tempEditData.FactoryModel = FactoryModel?.label ?? "";
      tempEditData.MeasurementName = MeasurementName?.label ?? "";
      tempEditData.SpareMake = spareMake?.label ?? "";
      tempEditData.SpareLocation = SpareLocation?.label ?? "";
      tempEditData.StationIDs = StationIds;
      tempEditData.SpareID = SpareID;

      setFormData({ ...tempEditData });
    } else {
      setFormData({ ...getByData });
    }

    setErrors({});
  }, [JSON.stringify(getByData)]);

  const getAutocompletedata = () => {
    _spareService.GetAutoCompleteData().then((res) => {
      if (res != null && res.isSuccess) {
        const { factoryModel, spareMake, spareLocation, spareUOM } = res.data;

        setDropdownData((pre) => ({
          ...pre,
          FactoryModelList: factoryModel,
          SpareMakeList: spareMake,
          SpareLocationList: spareLocation,
          SpareUOM: spareUOM,
        }));
      }
    });
  };

  const handleSubmit = (event) => {
    // console.log(formData);
    let Error = true;
    let tempError = errors;
    if (formData.StationIDs?.length) {
      tempError.StationID = "";
    } else {
      tempError.StationID = "Please select stations";
      Error = false;
    }

    // if (formData?.SpareID && formData.SpareID.value) {
    //   tempError.SpareID = "";
    // } else {
    //   tempError.SpareID = "Please select spare";
    //   Error = false;
    // }
    // if (formData?.FactoryModel?.length) {
    //   tempError.FactoryModel = "";
    // } else {
    //   tempError.FactoryModel = "Please type and select from below list";
    //   Error = false;
    // }
    if (formData?.DepartmentID > 0) {
      tempError.DepartmentID = "";
    } else {
      tempError.DepartmentID = "Please select department";
      Error = false;
    }
    if (formData?.EquipmentTypeID > 0) {
      tempError.EquipmentTypeID = "";
    } else {
      tempError.EquipmentTypeID = "Please select Equipment Type";
      Error = false;
    }
    if (formData?.SparePartNo?.length) {
      tempError.SparePartNo = "";
    } else {
      tempError.SparePartNo = "Please enter part number";
      Error = false;
    }

    if (formData?.SpareMake?.length) {
      tempError.SpareMake = "";
    } else {
      tempError.SpareMake = "Please type and select from below list";
      Error = false;
    }

    if (formData?.SpareQuantity > 0) {
      tempError.SpareQuantity = "";
    } else {
      tempError.SpareQuantity = "Please enter recommending quantity";
      Error = false;
    }

    if (formData?.QuantityAvailable > 0) {
      tempError.QuantityAvailable = "";
    } else {
      tempError.QuantityAvailable = "Please enter available  number";
      Error = false;
    }
    if (formData?.ReorderLimit > 0) {
      tempError.ReorderLimit = "";
    } else {
      tempError.ReorderLimit = "Please enter reorder limit";
      Error = false;
    }

    if (formData?.SpareLocation?.length) {
      tempError.SpareLocation = "";
    } else {
      tempError.SpareLocation = "Please type and select from below list";
      Error = false;
    }

    if (formData?.MeasurementName?.length) {
      tempError.MeasurementName = "";
    } else {
      tempError.MeasurementName = "Please type and select from below list";
      Error = false;
    }

    if (formData?.SupplierName?.length) {
      tempError.SupplierName = "";
    } else {
      tempError.SupplierName = "Please enter supplier name";
      Error = false;
    }

    setErrors({ ...tempError });

    if (Error) {
      let obj = { ...formData };

      obj.FactoryModel = formData.FactoryModel;
      obj.MeasurementName = formData.MeasurementName;
      obj.SpareLocation = formData.SpareLocation;
      obj.SpareMake = formData.SpareMake;
      // obj.S = formData.SpareMake[0].value;

      obj.SpareID = formData.SpareID.value;
      obj.StationIDs = formData.StationIDs.map((x) => x.value).join();

      _spareService
        .AddSpareDetails(obj)
        .then((res) => {
          showSaveSuccess();
          if (res.data != null && res.data.isSuccess) {
            getAutocompletedata();
            setErrors({});
            setFormData({ ...InitialState });
            setOnSelect('');
            $("#SpareDetailsCreationCloseId").click();
          }
        })
        .catch((err) => {
          showSaveError();
          setErrors({});
          setFormData(InitialState);
          $("#SpareDetailsCreationCloseId").click();
          console.log(err);
        });
    }
  };

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode, type: data.Type });
    });
    setDropdownData((pre) => ({
      ...pre,
      StationList: dataLst,
    }));
  };
  const getSpare = () => {
    _masterDetailService.GetSpare(0, false).then((res) => {
      if (res != null && res.isSuccess && res.data.length > 0) {
        var spare = [];
        res.data.map((v) => {
          spare.push({
            label: v.SpareName,
            value: v.SpareID,
          });
        });
        setDropdownData((pre) => ({
          ...pre,
          SpareList: spare,
        }));
      }
    });
  };
  const getDropDownList = () => {
    _spareService.getDropDownList().then((res) => {

      if (res.data != null && res.data.departmentList.length > 0) {
        setDropdownData((pre) => ({
          ...pre,
          DepartmentList: res.data.departmentList,
          EquipmentList: res.data.equipmentTypeList,
          OHEDepList: res.data.oheDepartmentID,
        }));
        let treeJsonData = BuildTreeUsingParentID(res.data.departmentList);
        let Data = JSON.parse(JSON.stringify(treeJsonData, null, 2));
        settreeData(Data);
      }
    });
  };
  const setSelectedDepartment = (id = 0) => {
    let obj = findDepartmentById(treeData, id);
    if (!Array.isArray(obj.children)) {
      setOnSelect(obj.name);
      setFormData((pre) => ({
        ...pre,
        DepartmentID: id,
      }));
    }
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <label className="label" for='stationCode'>
          Select Department<span className="text-danger"> *</span>{" "}
        </label>
        <ReactMultiselectTreeView
          openByDefault={false}
          data={treeData}
          width={250}
          height={100}
          onSelect={setSelectedDepartment}
          IsMultiselect={false}
        ></ReactMultiselectTreeView>
        <span className="text-danger"> {errors.DepartmentID} </span>
        {OnSelect !== '' && (
          <p>Selected Department: <span style={{ color: 'blue', fontFamily: 'Arial', fontWeight: 'bold' }}>{OnSelect}</span></p>
        )}

      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label className="label" for="empCode">
            Stations
            <span className="text-danger"> *</span>
          </label>
          <ReactMultiSelect
            isMulti
            options={stationList}
            placeholder="Select"
            name="StationIDs"
            value={formData.StationIDs ?? []}
            onChange={(e) => {
              handleChange(e, "StationIDs");
            }}
            allOption
          />
          <span className="text-danger"> {errors.StationID} </span>
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="label" for="empCode">
            Spare Name<span className="text-danger"> *</span>{" "}
          </label>
          {/* <ReactMultiSelect
            options={DropdownData.SpareList}
            placeholder="Select"
            name={"SpareID"}
            onChange={(e) => {
              handleChange(e, "SpareID");
            }}
            value={formData.SpareID ?? {}}
          /> */}

          <ReactAutoComplete
            handleChange={(e, text) => {
              handleChange(text, "SpareName");
            }}
            value={formData.SpareName}
            handleSelect={(e) => {
              handleChange(e, "SpareName");
            }}
            name={"SpareName"}
            options={DropdownData.SpareList}
          />

          <span className="text-danger mt-1"> {errors.SpareID} </span>
        </div>
      </div>
      {IsDepartment != 0 &&
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label className="label" htmlFor="empCode">
              Equipment Description
              <span className="text-danger"> *</span>
            </label>

            <ReactAutoComplete
              handleChange={(e, text) => {
                handleChange(text, "FactoryModel");
              }}
              value={formData.FactoryModel}
              handleSelect={(e) => {
                handleChange(e, "FactoryModel");
              }}
              name={"FactoryModel"}
              options={DropdownData.FactoryModelList}
            />
            <span className="text-danger mt-1"> {errors.FactoryModel} </span>
          </div>
        </div>}
      {IsDepartment == 0 &&
        <div className={"col-md-3 col-sm-6"}>
          <div className="form-group">
            <label className="label" for='stationCode'>
              Equipment Type
              <span className="text-danger"> *</span>{" "}
            </label>
            <WithSelectDropDown
              data={EquipmentFilterList}
              value={formData.EquipmentTypeID}
              name={"EquipmentTypeID"}
              HandleChange={handleChange}
            ></WithSelectDropDown>
            {/* <Dropdown
              data={EquipmentFilterList}
              placeholder="Select"
              name={"EquipmentTypeID"}
              HandleChange={handleChange}
              value={formData.EquipmentTypeID}
              className=""
            /> */}
            <span className="text-danger mt-1"> {errors.EquipmentTypeID} </span>
          </div>
        </div>}
      <div className="col-md-3 col-sm-6">
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Part No
            <span className="text-danger"> *</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="SparePartNo"
            value={formData.SparePartNo}
            onChange={handleChange}
            id="partNo"
          />
          <span className="text-danger mt-1"> {errors.SparePartNo} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Spare Make
          </label>
          <span className="text-danger"> *</span>

          <ReactAutoComplete
            handleChange={(e, text) => {
              handleChange(text, "SpareMake");
            }}
            value={formData.SpareMake}
            handleSelect={(e) => {
              handleChange(e, "SpareMake");
            }}
            name={"SpareMake"}
            options={DropdownData.SpareMakeList}
          />

          <span className="text-danger mt-1"> {errors.SpareMake} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Recommended Quantity
          </label>
          <span className="text-danger"> *</span>

          <input
            className="form-control"
            type="number"
            name="SpareQuantity"
            value={formData.SpareQuantity}
            onChange={handleChange}
          />
          <span className="text-danger mt-1"> {errors.SpareQuantity} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Available Quantity
          </label>
          <span className="text-danger"> *</span>

          <input
            className="form-control"
            type="number"
            name="QuantityAvailable"
            value={formData.QuantityAvailable}
            onChange={handleChange}
          />
          <span className="text-danger mt-1"> {errors.QuantityAvailable} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Reorder Limit
          </label>
          <span className="text-danger"> *</span>

          <input
            className="form-control"
            type="number"
            id="SpareLocation"
            name="ReorderLimit"
            value={formData.ReorderLimit}
            onChange={handleChange}
          />
          <span className="text-danger mt-1"> {errors.ReorderLimit} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Spare Location
          </label>
          <span className="text-danger"> *</span>

          <ReactAutoComplete
            handleChange={(e, text) => {
              handleChange(text, "SpareLocation");
            }}
            value={formData.SpareLocation}
            handleSelect={(e) => {
              handleChange(e, "SpareLocation");
            }}
            name={"SpareLocation"}
            options={DropdownData.SpareLocationList}
          />

          <span className="text-danger mt-1"> {errors.SpareLocation} </span>
        </div>
      </div>
      <div
        className="col-md-3 col-sm-6
"
      >
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            UOM
          </label>
          <span className="text-danger"> *</span>

          <ReactAutoComplete
            handleChange={(e, text) => {
              handleChange(text, "MeasurementName");
            }}
            value={formData.MeasurementName}
            handleSelect={(e) => {
              handleChange(e, "MeasurementName");
            }}
            name={"MeasurementName"}
            options={DropdownData.SpareUOM}
          />

          <span className="text-danger mt-1"> {errors.MeasurementName} </span>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Supplier Name
          </label>
          <span className="text-danger"> *</span>
          <input
            className="form-control"
            type="text"
            name="SupplierName"
            value={formData.SupplierName}
            onChange={handleChange}
          />
          <span className="text-danger mt-1"> {errors.SupplierName} </span>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            Shelf Life
          </label>
          <input
            className="form-control"
            type="text"
            name="ShelfLife"
            value={formData.ShelfLife}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
        <div className="col-md-2 ">
          <button
            id=""
            onClick={handleSubmit}
            className="btn add-btn secondary-btn"
          >
            {" "}
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
