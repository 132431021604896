import React from "react";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import MasterDetailService from "./../screens/master/MasterDetailService";
import { EquipmentManager } from "./../screens/master/EquipmentManager";
import {
  showDeleteError,
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "./ToastMsg";
import {
  GetLoginUserID,
  getPrivilege,
  GetSelect,
  GetToggleButton,
} from "./../services/CommonService";
import { tab } from "@testing-library/user-event/dist/tab";
import areIntervalsOverlappingWithOptions from "date-fns/fp/areIntervalsOverlappingWithOptions";

$.DataTable = require("datatables.net");
const _masterDetailService = new MasterDetailService();

export const CollapseTable = ({ columnList, rowList, id, Locations, Area }) => {
  const tableName = id;

  const [isTable, setIsTable] = useState(true);
  const [rerender, setRerender] = useState(true);

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable]);

  useEffect(() => {}, [rerender]);

  const handleRender = () => setRerender(!rerender);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: true,
      autoWidth: true,
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        var equipmentManagerID = $(this).closest("tr").find("td:eq(1)").html();
        var childColumns = [
          {
            title: "Equipment Detail ID",
            className: "hide",
            createdCell: (td, cellData, rowData, row, col) => {
              var b = $(
                `<input type="text" class="form-control" value="${cellData}"/>`
              ).on("change", function () {});
              $(td).html(b);
            },
          },
          {
            title: "Equipment Tag Number",
            createdCell: (td, cellData, rowData, row, col) => {
              var b;
              if (getPrivilege().includes("MASTEREDIT") || rowData[0] == 0) {
                b = $(
                  `<input type="text" class="form-control" value="${cellData}"/>`
                ).on("change", function () {});
              } else {
                b = $(
                  `<input type="text" class="form-control" value="${cellData}" readonly/>`
                ).on("change", function () {});
              }

              $(td).html(b);
            },
          },
          {
            title: "Model No",
            createdCell: (td, cellData, rowData, row, col) => {
              var b;
              if (getPrivilege().includes("MASTEREDIT") || rowData[0] == 0) {
                b = $(
                  `<input type="text" class="form-control" value="${cellData}"/>`
                );
              } else {
                b = $(
                  `<input type="text" class="form-control" value="${cellData}" readonly />`
                );
              }

              $(td).html(b);
            },
            className : "hide",
            
          },
          {
            title: "Equipment Location",
            createdCell: (td, cellData, rowData, row, col) => {
              var b;
              if (getPrivilege().includes("MASTEREDIT") || rowData[0] == 0) {
                b = GetSelect(Locations, cellData);
              } else {
                b = $(`<div style="pointer-events:none">
                ${GetSelect(Locations, cellData)}</div>`);
              }

              $(td).html(b);
            },
          },
          {
            title: "Equipment Area",
            createdCell: (td, cellData, rowData, row, col) => {
              var b;
              if (getPrivilege().includes("MASTEREDIT") || rowData[0] == 0) {
                b = GetSelect(Area, cellData);
              } else {
                b = $(`<div style="pointer-events:none">
                ${GetSelect(Area, cellData)}</div>`);
              }
              $(td).html(b);
            },
          },
          {
            title: "Status",
            createdCell: (td, cellData, rowData, row, col) => {
              var b;
              if (getPrivilege().includes("MASTEREDIT") || rowData[0] == 0) {
                b = GetToggleButton(row, col, cellData, equipmentManagerID);
              } else {
                b = $(`<div style="pointer-events:none">
                ${GetToggleButton(
                  row,
                  col,
                  cellData,
                  equipmentManagerID
                )}</div>`);
              }

              $(td).html(b);
            },
          },
          {
            title: "row ID",
            className: "hide",
          },
          {
            title: "Submit",
            visible:
              getPrivilege().includes("MASTERECREATE") ||
              getPrivilege().includes("MASTEREDIT") ||
              getPrivilege().includes("MASTERDELETE"),
            createdCell: (td, cellData, rowData, row, col) => {
              var b = "";
              var c = "";
              if (
                getPrivilege().includes("MASTERECREATE") ||
                getPrivilege().includes("MASTEREDIT")
              ) {
                b =
                  $(`<button class='btn icon-btn' id="${equipmentManagerID}${row}${col}" title='Save'>
              <i class='fa fa-check' style="margin-top:10px"></i>
            </button>`);
              }
              if (getPrivilege().includes("MASTERDELETE")) {
                c = $(`<button class='btn'  title='delete'  >
                <i class='fa fa-trash m-l-15'></i>
              </button>`).on("click", function () {
                  if (rowData[0] > 0) {
                    _masterDetailService
                      .GetEquipmentDetails(rowData[0], 0, 1)
                      .then((res) => {
                        if (res != null && res.isSuccess) {
                          _masterDetailService
                            .GetEquipmentDetails(0, equipmentManagerID, 0)
                            .then((childRows) => {
                              if (
                                childRows != null &&
                                childRows.data != null &&
                                childRows.data.length > 0
                              ) {
                                var rowList = [];
                                childRows.data.map((v, i) => {
                                  var rows = [];
                                  rows.push(v.EquipmentDetailID);
                                  rows.push(v.EquipmentCode);
                                  rows.push(v.ModelNo);
                                  rows.push(v.EquipmentLocationID);
                                  rows.push(v.EquipmentAreaID);
                                  rows.push(v.EquipmentStatus);
                                  rows.push(i + 1);
                                  rows.push(v.EquipmentStatus);
                                  rowList.push(rows);
                                });
                                childTable(
                                  rowList,
                                  childColumns,
                                  equipmentManagerID
                                );
                              } else {
                                childTable(
                                  [],
                                  childColumns,
                                  equipmentManagerID
                                );
                              }
                            });
                          showDeleteSuccess();
                        } else {
                          showDeleteError();
                        }
                      });
                  } else {
                  }
                });
              }

              $(td).html(b);
              $(td).append(c);
            },
          },
        ];
        _masterDetailService
          .GetEquipmentDetails(0, equipmentManagerID, 0)
          .then((childRows) => {
            if (
              childRows != null &&
              childRows.data != null &&
              childRows.data.length > 0
            ) {
              var rowList = [];
              childRows.data.map((v, i) => {
                var rows = [];
                rows.push(v.EquipmentDetailID);
                rows.push(v.EquipmentCode);
                rows.push(v.ModelNo);
                rows.push(v.EquipmentLocationID);
                rows.push(v.EquipmentAreaID);
                rows.push(v.EquipmentStatus);
                rows.push(i + 1);
                rows.push(v.EquipmentStatus);
                rowList.push(rows);
              });
              row.child(ChildTable(equipmentManagerID)).show();

              //set child table
              childTable(rowList, childColumns, equipmentManagerID);
            } else {
              row.child(ChildTable(equipmentManagerID)).show();

              //set child table
              childTable([], childColumns, equipmentManagerID);
            }
          });

        tr.addClass("shown");
      }
    });

    return function () {
      table.destroy();
    };
  };

  const childTable = (rowList, childColumns, equipmentManagerID) => {
    // set child table as data table
    var t = $(`#child-${equipmentManagerID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      paging: false,
      "bInfo": false,
      scrollX: true,
      autoWidth: true,
      order: [[6, "desc"]],
    });

    //add new row functionality
    $(`#addRow-${equipmentManagerID}`).on("click", function () {
      var rowID = t.column(0).data().length + 1;
      t.row.add([0, "", "", 0, 0, false, rowID, ""]).draw(false);
      // save icon button functionality
      saveButton(equipmentManagerID, t);
    });

    // save icon button functionality
    saveButton(equipmentManagerID, t);
  };

  const saveButton = (equipmentManagerID, t) => {
    $(`#child-${equipmentManagerID} tbody tr`).each(function () {
      var iconBtn = $(this).closest("tr").find(".icon-btn");
      var row = t.row(this);
      var rowIndex = t.row(this).index();
      iconBtn.unbind().on("click", () => {
        var inputList = [];
        var selectList = [];
        $(this)
          .find("td")
          .each(function () {
            var inputs = $(this).find("input[type=text]");
            if (inputs != null && inputs.length == 1) {
              inputList.push(inputs.val());
            }

            var toggle = $(this).find("input[type=checkbox]");
            if (toggle != null && toggle.length == 1) {
              inputList.push(toggle.is(":checked"));
            }

            var select = $(this).find("select");
            if (select != null && select.length == 1) {
              selectList.push(select.val());
            }
          });
        if (
          inputList[1].trim() != "" &&
          // inputList[2].trim() != "" &&
          Number(selectList[0]) != 0 &&
          Number(selectList[1]) != 0
        ) {
          var equipmentDetail = {
            EquipmentDetailID: Number(inputList[0]),
            EquipmentManagerID: Number(equipmentManagerID),
            EquipmentCode: inputList[1],
            ModelNo: inputList[2],
            EquipmentLocationID: Number(selectList[0]),
            EquipmentAreaID: Number(selectList[1]),
            EquipmentStatus: Boolean(inputList[3]),
            SavedBy: GetLoginUserID(),
          };
          console.log(equipmentDetail);
          var data = t.column(1).data();
          var isExists = false;
          if (
            data != null &&
            data.length > 0 &&
            equipmentDetail.EquipmentDetailID == 0
          ) {
            for (var i = 0; i < data.length; i++) {
              if (
                data[i].toLowerCase().trim() ==
                equipmentDetail.EquipmentCode.toLowerCase().trim()
              ) {
                isExists = true;
                break;
              }
            }
          }
          if (!isExists) {
            var inputCell = $(this).closest("tr").find("td:eq(0)");

            onSaveClick(equipmentDetail, t, row, rowIndex);
          } else {
            showWarningMsg("Equipment code is already exists");
          }
        } else {
          showWarningMsg("Please fill all fields");
        }
      });
    });
  };

  const onSaveClick = (data, t, row, rowIndex) => {
    _masterDetailService.AddOrUpdateEqptDetail(data).then((response) => {
      if (response != null && response.isSuccess) {
        showSaveSuccess();
        t.cell({ row: rowIndex, column: 0 }).data(
          `<input type="text" class="form-control" value="${response.data}"/>`
        );
      } else {
        showSaveError();
      }
    });
  };

  return (
    <div className="table-responsive mt-4">
      <div className="row">
        <div className="col-sm-12">
          {isTable ? (
            <table
              className="table table-striped no-footer display collapse-table"
              id={tableName}
              width="100%"
            ></table>
          ) : (
            /* No of coulms and no of rows must be equal*/
            <div className="row col-12 text-center mt-2 mb-2">
              <p className="text-muted">Table not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ChildTable = (tableIndex) => {
  if (getPrivilege().includes("MASTERCREATE")) {
    return `<div class="row d-flex justify-content-end mt-2 mb-1"><div class="col-md-2"><button type="submit" id="addRow-${tableIndex}" class="btn add-btn"><i class="fa fa-plus"></i> Row</button></div></div>
  <div class"row ">
  <table id="child-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
  </table></div>`;
  } else {
    return `<table id="child-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
    </table></div>`;
  }
};
