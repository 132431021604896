import React, { useContext, useEffect, useState } from "react";
import { ManualLogContext } from "./ContextProvider";
import { AppContext } from "../../services/ContextProvider";
import {
  Decrypt,
  GetLoginUserID,
  addDays,
  getPrivilege,
  getUserDetails,
  getYMDdate,
} from "../../services/CommonService";
import {
  Dropdown,
  HtmlTable,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
} from "../../components";
import {
  RoutineShiftCheck_SaveConfig,
  RoutineShiftCheck_DropDownDetails,
  RoutineShiftCheck_GetEquipmentDetails,
  RoutineShiftCheck_DetailsSaveConfig,
  EquipmentEnergyConsumption_GetDocumentID,
} from "./ManualLogService";
import Global, { COOKIES } from "../../services/Global";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import $, { data } from "jquery";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
export default function RoutineShiftCheck({ TabValue }) {
  const {
    RoutineShiftCheckModel,
    setRoutineShiftCheckModel,
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
    Error,
    setError,
    RoutineChecksList,
    setRoutineChecksList,
  } = useContext(ManualLogContext);
  const { handleGlobalStationName, getCookies, showLoading, hideLoading } =
    useContext(AppContext);
  const [flag, setflag] = useState(false);
  const columnNames = [
    {
      title: "RoutineShiftCheckID",
      data: "RoutineShiftCheckID",
      visible: false,
    },
  
    
    {
      title: "Equipment Name",
      data: "RoutineEquipmentName",
    },
    {
      title: "A (06:00-14:00)",
      data: "FirstShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {


        let isNotDisable = rowData.CurrentShift=="A"
        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "FirstShiftResponse",
            isNotDisable
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "FirstShiftResponse",isNotDisable
          );
        }
      },
    },
    {
      title: "B (14:00-22:00)",
      data: "SecondShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {
        let isNotDisable = rowData.CurrentShift=="B"

        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "SecondShiftResponse",isNotDisable
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "SecondShiftResponse",isNotDisable
          );
        }
      },
    },
    {
      title: "C (22:00-06:00)",
      data: "ThirdShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {
        let isNotDisable = rowData.CurrentShift=="C"

        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "ThirdShiftResponse",isNotDisable
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "ThirdShiftResponse",isNotDisable
          );
        }
      },
    },
    {
      title: "Remarks",
      data: "Remarks",
      createdCell: (td, cellData, rowData, row, col) => {
        const { checkAllApproved, checkAllRejected } = RoutineShiftCheckModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved && !checkAllRejected
            ? ""
            : "disabled";
        var b = $(
          `<textarea  class="form-control " ${IsDisable} style = "
          resize: none;
          min-width: 150px !important;"  value="${cellData}">${cellData} </textarea>`
        ).on("change", (e) => {
          e.preventDefault();
          rowData.Remarks = e.target.value;
          RoutineChecksHandleChange(rowData);
          UpdateStatus(e, rowData);
        });

        $(td).html(b);
      },
    },
    {
      title: "Status",
      data: "StatusName",
      width: "60px",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="StatusCls" > </span>`);
        span.append(GetPeriodicStatus(cellData));
        $(td).html(span);
      },
    },
  ];

  useEffect(() => {
      initialDropDown();
    return () => {
      setDropdownModel({ ...DropDownInitialState });
    };
  }, []);



  useEffect(() => {
    getEquipmentDetails();
  }, [RoutineShiftCheckModel.StationID, RoutineShiftCheckModel.CurrentDate]);

  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    //let ignoredStation = ["stc", "str", "swa"];
     let ignoredStation = [""];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    setRoutineShiftCheckModel((prevState) => ({
      ...prevState,
      StationID: FinalStationList[0]?.value ?? 0,
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");

    await RoutineShiftCheck_DropDownDetails()
      .then((res) => {
        if (res?.data?.data?.length) {
          setDropdownModel((pre) => ({
            ...pre,
            StationList: FinalStationList,
            TypeList: res.data.data,
          }));
        } else {
          setDropdownModel(DropDownInitialState);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await EquipmentEnergyConsumption_GetDocumentID()
      .then((res) => {
        if (res?.data?.data) {
          const { data } = res.data;
          let document = data.find(
            (x) =>
              x.ModuleName == "ROUTINE_MONITORING_CHECKLIST_FOR_CHILLER_SYSTEMS"
          );
          setRoutineShiftCheckModel((pre) => ({
            ...pre,
            documentNo: document.DocumentNo,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CommonHandleChange = (event) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setRoutineShiftCheckModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        let currentStation = DropdownModel.StationList.find(
          (x) => x.value == value
        );
        handleGlobalStationName(currentStation?.key ?? "");
      }
    }
  };
  const AddHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...RoutineShiftCheckModel };
    let isError = false;
    let modelErrors = Error;
    if (obj.ResponseType) {
      modelErrors.ResponseType = "";
    } else {
      modelErrors.ResponseType = "Please enter response type.";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please select station";
      isError = true;
    }
    if (obj.RoutineEquipmentName) {
      modelErrors.RoutineEquipmentName = "";
    } else {
      modelErrors.RoutineEquipmentName = "Please enter Equipment Name";
      isError = true;
    }
    setError({ ...modelErrors });

    if (!isError) {
      obj.CreatedBy = GetLoginUserID();
      RoutineShiftCheck_SaveConfig(obj)
        .then((res) => {
          if (res?.data?.isSuccess) {
            showSaveSuccess();
            getEquipmentDetails();
            setRoutineShiftCheckModel((pre) => ({
              ...pre,
              RoutineEquipmentName: "",
              ResponseType: 0,
            }));
          } else {
            showSaveError();
          }
        })
        .catch((err) => {
          console.log(err);
          showSaveError();
        });
    }
  };

  const RoutineChecksHandleChange = async (obj, Status = 64) => {
    obj.SavedBy = GetLoginUserID();
    obj.Status = Status;
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      obj.ShiftTechnicianID = GetLoginUserID();
    } else {
      obj.ShiftEngineerID = GetLoginUserID();
    }
    obj.CheckDate = RoutineShiftCheckModel.CurrentDate;
    await RoutineShiftCheck_DetailsSaveConfig(obj)
      .then((res) => {
        const { isSuccess } = res.data;
        if (isSuccess && Status != 64) {
          showSaveSuccess();
        } else if (!isSuccess) {
          showErrorMsg("Something want Wrong Please try again.");
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEquipmentDetails = async () => {
    const { CurrentDate, StationID } = RoutineShiftCheckModel;
    if (StationID > 0 && CurrentDate) {
      showLoading();
      await RoutineShiftCheck_GetEquipmentDetails(CurrentDate, StationID)
        .then((res) => {
          hideLoading();

          if (res?.data?.data?.length) {
            const { data } = res.data;
            let checkAllAttended = data.every((x) => x.Status == 129);
            
            let checkAllApproved = data.every((x) => x.Status == 65);
            let checkAllRejected = data.every((x) => x.Status == 66);
            setRoutineChecksList([...data]);
            setflag(true)
            setRoutineShiftCheckModel((pre) => ({
              ...pre,
              checkAllApproved: checkAllApproved,
              checkAllAttended: checkAllAttended,
              checkAllRejected: checkAllRejected,
            }));
          } else {
            setRoutineChecksList([]);
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };
  function DateFilter() {
    return $(
      `<label class='m-l-15' style ="display:inline-flex" > <span> Date : </span>
        <input type="date" max=${getYMDdate(
        new Date()
      )} style="height:35px" class="form-control m-l-15" value ="${RoutineShiftCheckModel.CurrentDate
      }"
        /></label>
        `
    ).on("change", function (e) {
      const { value } = e.target;
      setRoutineShiftCheckModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
  }

  function UpdateStatus(e, Data) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");

      if (StatusClsCell.length > 0) {
        Data.StatusName = "Attended";
        StatusClsCell.html(GetPeriodicStatus("Attended")); // You can format the value as needed
        SubmitBtn();
      }
    }
  }
 

  function NumberTypeResponse(td, cellData, rowData, row_1, col, objName = "",isNotDisable) {
    let selectedValues = cellData.split(",");
    let row = $('<div class="row ParentDiv justify-content-center">');
    const { checkAllApproved, checkAllRejected } = RoutineShiftCheckModel;

    for (let i = 1; i < 4; i++) {
      let shiftDiv = $('<div class="col-4">');

      if (IsAllowToEnterData() && !checkAllApproved && !checkAllRejected && isNotDisable) {
        $(shiftDiv).on("click", function (e) {
          debugger;

          $(this).toggleClass("numberDiv selectedNumberDiv");
          let selectedDiv = $(this)
            .closest(".ParentDiv")
            .find(".selectedNumberDiv");
          let selectedValues = selectedDiv
            .map((_, element) => $(element).text())
            .get();
          cellData = selectedValues.join(",");
          rowData[objName] = cellData;
          RoutineChecksHandleChange(rowData);
          UpdateStatus(e, rowData);
        });
      }

      shiftDiv.text(i);
      if (selectedValues.includes(String(i))) {
        shiftDiv.addClass("selectedNumberDiv");
      } else {
        shiftDiv.addClass("numberDiv");
      }

      row.append(shiftDiv);
    }

    $(td).html(row);
  }

  function OtherCommonType(td, cellData, rowData, row_1, col, objName = "",isNotDisable) {

    const TextType = {
      59: "ON,OFF",
      60: "OK,Not Ok",
      61: "W,NW",
      62: "Open,Close",
      63: "Local,Remote",
    };

    let ShowString = TextType[rowData.ResponseType]?.split(",") ?? [
      "ON",
      "OFF",
    ];

    let selectedValues = cellData;
    let row = $('<div class="row ParentDiv justify-content-center">');
    const { checkAllApproved, checkAllRejected } = RoutineShiftCheckModel;
    for (let i = 0; i < 2; i++) {
      let shiftDiv = $('<div class="col-4 commonRoutineChecksDiv">');

      if (IsAllowToEnterData() && !checkAllApproved && !checkAllRejected && isNotDisable) {
        $(shiftDiv).on("click", function (e) {
          $(this)
            .closest(".ParentDiv")
            .find("div")
            .removeClass("selectedOtherTypeDiv");

          $(this).toggleClass("otherTypeDiv selectedOtherTypeDiv");
          cellData = $(this).text();
          rowData[objName] = cellData;
          RoutineChecksHandleChange(rowData);
          UpdateStatus(e, rowData);
        });
      }

      shiftDiv.text(ShowString[i]);
      if (selectedValues == ShowString[i]) {
        shiftDiv.addClass("selectedOtherTypeDiv");
      } else {
        shiftDiv.addClass("otherTypeDiv");
      }

      row.append(shiftDiv);
    }

    $(td).html(row);
  }

  function IsAllowToEnterData() {
    let currentDate = new Date().toISOString().slice(0, 10);
    let CheckDate = new Date(RoutineShiftCheckModel.CurrentDate)
      .toISOString()
      .slice(0, 10);
    //return true
    return (
      getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate
    );
  }
  function ApprovedBtn() {
    let tableValues = $("#RoutineChecksTable")
      .DataTable()
      .table()
      .data()
      .toArray();

    let ApproveBtn = $(
      `<button  class="btn btn-success disabled"
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();
      if (tableValues?.length) {
        let rowData = tableValues[0];
        await RoutineChecksHandleChange(rowData, 65);
        await getEquipmentDetails();
      }
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await RoutineChecksHandleChange(rowData, 66);
        await getEquipmentDetails();
      }
    });

    let Fragment = $(`<span>`);
    if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }

    const {
      checkAllAttended,
      CurrentDate,
      checkAllApproved,
      checkAllRejected,
    } = RoutineShiftCheckModel;

    if (
      checkAllAttended &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 3))
    ) {
      ApproveBtn.removeClass("disabled");
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 3)) ||
        checkAllApproved)
    ) {
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 3)) ||
        checkAllRejected)
    ) {
      ApproveBtn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 15)) &&
      checkAllAttended
    ) {
      ApproveBtn.removeClass("disabled");
    }

    return Fragment;
  }
  function SubmitBtn() {

    let tableValues = $("#RoutineChecksTable")
      .DataTable()
      .table()
      .data()
      .toArray();
    let SendForApprovelBtn = $(
      `<button id="submitBtn" class="btn btn-primary m-l-15">Submit</button> `
    ).on("click", async function (e) {
      e.preventDefault();
      let everyapprove = tableValues.every((x) => x.StatusName == "Attended")
      if (tableValues?.length && everyapprove) {
        let rowData = tableValues[0];
        await RoutineChecksHandleChange(rowData, 129);
        await getEquipmentDetails();
      } else {
        showWarningMsg("Please completed all checklist.")
      }
    });
    const {
      checkAllAttended,
      CurrentDate,
    } = RoutineShiftCheckModel;
    let Fragment = $(`<span ></span>`);
    if (getUserDetails().RoleID === COOKIES.TechRoleID && getYMDdate(new Date()) == getYMDdate(CurrentDate)) {
      Fragment.append(SendForApprovelBtn);
    }
    if (
      getUserDetails().RoleID === COOKIES.TechRoleID &&
      getYMDdate(new Date()) == getYMDdate(CurrentDate) &&
      checkAllAttended
    ) {
      setTimeout(() => {
        $('#submitBtn').addClass("disabled");
      }, 600)
    }
    return Fragment;
  }



  return (
    <>
      {" "}
      <div className="col-md-12">
        <label
          className="label"
          for="empCode"
          style={{ float: "right", position: "relative", top: "-10px" }}
        >
          {RoutineShiftCheckModel?.documentNo &&
            `Doc Number : ${RoutineShiftCheckModel.documentNo}`}
        </label>
      </div>
      <form id="EquipmentRunningStatus">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={RoutineShiftCheckModel.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {Error.StationID} </span>
            </div>
          </div>
          {getPrivilege().includes("MANUALLOGCREATE") && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Type Of Response<span className="text-danger"> *</span>{" "}
                  </label>
                  <WithSelectDropDown
                    data={DropdownModel.TypeList}
                    placeholder="Select"
                    name={"ResponseType"}
                    HandleChange={CommonHandleChange}
                    value={RoutineShiftCheckModel.ResponseType}
                    className=""
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {Error.ResponseType}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Equipment Name <span className="text-danger"> *</span>{" "}
                  </label>{" "}
                  <textarea
                    className="form-control "
                    style={{ resize: "none" }}
                    // type="text"
                    name="RoutineEquipmentName"
                    value={RoutineShiftCheckModel.RoutineEquipmentName}
                    rows={2}
                    onChange={(e) => CommonHandleChange(e)}
                  ></textarea>
                  <span className="text-danger mt-1">
                    {" "}
                    {Error.RoutineEquipmentName}{" "}
                  </span>
                </div>
              </div>

              <div className="col-md-2 mt-4">
                <button
                  type="submit"
                  id="addRow_ers"
                  onClick={AddHandleClick}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </>
          )}
          {getPrivilege().includes("MANUALLOGDELETE11") && (
            <div className="col-md-2 mt-4">
              <button
                type="button"
                // onClick={deleteHandleClick}
                className="btn btn-danger"
              >
                <i className="fa fa-trash"></i> Delete
              </button>
            </div>
          )}

          {/* <div className="col-md-12">
            <label
              className="label"
              for="empCode"
              style={{ float: "right", position: "relative", top: "-10px" }}
            >
              {RoutineShiftCheckModel?.documentNo &&
                `Doc Number : ${RoutineShiftCheckModel.documentNo}`}
            </label>
          </div> */}
        </div>
      </form>
      <div style={{ position: "relative", top: "-30px" }}>
        <HtmlTable
          columnList={columnNames}
          ShowLengthPostponedHtml={DateFilter}
          rowList={RoutineChecksList ?? []}
          SearchPrependHtml={ApprovedBtn}
          SearchPostponedHtml={SubmitBtn}
          //SearchPostponedHtml={IsAllowToEnterData() && SubmitButton}
          id={"RoutineChecksTable"}
          FixedColums={{
            left: 1,
            right: 1,
          }}
        />
      </div>
    </>
  );
}
