import React, { useState } from "react";
import "./assets/css/font-awesome.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/dataTables.bootstrap5.min.css";
import "./assets/css/style.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/js/app.js";
import "datatables.net-bs5";
import jsZip from "jszip";
import "pdfmake";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.css";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.js";
import "datatables.net-fixedcolumns";
import "datatables.net-dt";
import "datatables.net-select-dt";
import "react-accessible-accordion/dist/fancy-example.css";
import { Dashboard } from "./screens/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Verifycation from "./screens/dashboard/EquipmentAvailability/Verifycation.js";
import { Login } from "./screens/auth/Login";
import WithNav from "./components/WithNav";
import { EmployeeGrid } from "./screens/employee/EmployeeGrid";
import { Error404 } from "./screens/error/Error_404";
import { useContext } from "react";
import { AppContext } from "./services/ContextProvider";
import { WithoutNav } from "./components/WithoutNav";
import Global, { COOKIES } from "./services/Global";
import { useEffect } from "react";
import { Beforeunload } from "react-beforeunload";
import { Decrypt, Encrypt } from "./services/CommonService";
import { MasterDetails } from "./screens/master/MasterDetails";
import { DepartmentConfigIndex } from "./screens/master/DepartmentConfigIndex.js";
import { User } from "./screens/user/User";
import { DesingAndCommission } from "./screens/commissioned/DesignAndCommission";
import { RoleList } from "./screens/roles/RoleList";

import { AddEmployee } from "./screens/employee/AddEmployee";
import { ManualSave } from "./screens/ManualUpload/ManualSave";

import { SpareDetails } from "./screens/spare/SpareDetails";
import { PeriodicMaintance } from "./screens/periodic/Periodic";
import { ParameterDetails } from "./screens/parameterConfig/ParameterDetails";
import { SCADAConfig } from "./screens/SCADA/SCADAConfig";
import { MainGrid } from "./screens/BreakDownMaintenance/MainGrid";
import DashboardRedirect from "./screens/dashboard/DashboardRedirect";
import { BreakdownReport } from "./screens/reports/BreakdownReport";
import { ReportPeriodic } from "./screens/periodic/ReportPeriodic";
import { CalenderDailyView as CalenderDailyViewDepartment } from "./screens/PeriodicMaintenance/CalenderDailyView.js";
import { CalenderDailyView } from "./screens/periodic/CalenderDailyView.js";
import { CalenderChildMonth as CalenderChildMonthDepartment } from "./screens/PeriodicMaintenance/CalenderChildMonth";
import { CalenderChildMonth } from "./screens/periodic/CalenderChildMonth";
import { CalenderChildQuaterly } from "./screens/periodic/CalenderChildQuaterly";
import { CalenderChildHalfyearly } from "./screens/periodic/CalenderChildHalfyearly";
import { CalenderChildYearly } from "./screens/periodic/CalenderChildYearly";
import { ManualParameterEntry } from "./screens/ManualParameterEntry/ManualParameterEntry";
import { ReportForManulBreakDownMaintance } from "./screens/BreakDownMaintenance/ReportForManulBreakDownMaintance";
import { Report_ManulPointEntry } from "./screens/ManualParameterEntry/Report_ManulPointEntry";
import { BreakdownAlarmReport } from "./screens/reports/BreakdownAlarmReport";
import { SpareReport } from "./screens/reports/SpareReport";
//import PeriodicConfig from "./screens/PeriodicConfig/PeriodicConfig";
import { PeriodicConfiguration } from "./screens/PeriodicConfig/index.js";
import PrivacyPolicy from "./screens/Privacy policy/PrivacyPolicy";
import TermsAndConditions from "./screens/Privacy policy/TermsAndConditions";
import { StationConfig } from "./screens/StationConfig/StationConfig";
import { HistoryReport } from "./screens/reports/Histroy_report";
import AlertConfiguration from "./screens/FileTransferAlertSettings/Alertconfiguration";
import ManualLog from "./screens/ManualLog/ManualLog";
import EquipmentAvailability from "./screens/dashboard/EquipmentAvailability/EquipmentAvailability.js";
// import Temperatureconfiguration from "./screens/temperatureConfig/Temperatureconfiguration";
import RevenueConfiguration from "./screens/temperatureConfig/RevenueConfiguration";
import CustomEquipmentPage from "./screens/periodic/CustomPages/CustomEquipmentPage";
import CheckListReport from "./screens/CheckList/CheckListReport.js";
import CheckListCalenderView from "./screens/CheckList/CheckListCalenderView.js";
import CheckListDailyCalender from "./screens/CheckList/CheckListDailyCalender.js";
import RoutineShiftCheckReport from "./screens/ManualLog/RoutineShiftCheckReport.js";
// import DailyCustomView from "./screens/periodic/CustomPages/DailyCustomView";
import { DailyElectricalPanelReport } from "./screens/reports/DailyElectricalPanelReport.js";
import { ChillerMonitoringReport } from "./screens/reports/ChillerMonitoringReport.js";
import UG_19_StationDashboard from "./screens/dashboard/UG_19_StationDashboard.js";
import CheckListCardView from "./screens/MaintenanceCheckList/MaintenanceChecksCalenderView.js";
import MaintenanceChecksDescriptionCalenderView from "./screens/MaintenanceCheckList/MaintenanceChecksDescriptionCalenderView.js";
import UG19StationPMPage from "./screens/UG19StationPeriodic/UG19StationPMPage.js";
import PerformanceEvaluationReport from "./screens/PerformanceEvaluation/PerformanceEvaluationReport.js";
import BootrapModel from "./components/BootrapModel.js";
import SafetyMeasurementModel from "./screens/Privacy policy/SafetyMeasurementModel.js";
import UG_19_PeriodicReport from "./screens/periodic/UG_19_PeriodicReport.js";
import PerformanceEvaluationLandingPage from "./screens/PerformanceEvaluation/PerformanceEvalutionLandingPage.js";
import PerformanceEvolutionCalenderWrap from "./screens/PerformanceEvaluation/PerformanceEvalutionCalenderWrap.js";

import BufferConfig from "./screens/ApplicationConfiguration/BufferDaysConfig/BufferConfig.js";
import PredefinedFailures from "./screens/ApplicationConfiguration/PredefinedFailures/PredefinedFailures.js";
import PeriodicStartMonthConfig from "./screens/ApplicationConfiguration/PeriodicStartMonthConfig/index.js";

import { DepartmentConfiguration } from "./screens/master/DepartmentConfiguration.js";
import AuditingLevelConfigIndex from "./screens/ApplicationConfiguration/AuditingLevelConfig/AuditingLevelConfigIndex.js";
import ApprovalConfig from "./screens/ApplicationConfiguration/ApprovalConfig/ApproveConfig.js";
import PeriodicMaintenanceModule from "./screens/PeriodicMaintenance/index.js";
import BreakdownDepartmentDashboard from "./screens/dashboard/BreakdownDepartmentDashboard.js";
import Depart_UG19StationPMPage from "./screens/UG19StationPeriodicForDepartmentWise/UG19StationPMPage.js";
import { BreakdownDepartmentIndex } from "./screens/BreakDownMaintenance/BreakdownDepartmentIndex.js";
import OverAllHistoryReport from "./screens/OverAllHistoryReport/OverAllHistoryReport.js";
import PeriodicDetailsView from "./screens/AuditingProcess/PeriodicDetails.js";
import PeriodicConfig from "./screens/PeriodicConfig/PeriodicConfig_Old.js";
import AuditingProcessTab from "./screens/AuditingProcess/AuditingProcessTab.js";
import CyclicCheckIndex from "./screens/CyclicCheck/CyclicCheckIndex.js";
import CyclicCheckApproverIndex from "./screens/CyclicCheckApprover/CyclicCheckApproverIndex.js";
import { CyclicCheckReport } from "./screens/reports/CyclicCheckReport.js";
import { AuditingReport } from "./screens/reports/AuditingReport.js";
import ReportPeriodicForDepart from "./screens/PeriodicMaintenance/ReportPeriodicForDepart.js";
import StationLocationConfiguration from "./screens/StationLocationConfiguration/StationLocationConfiguration.js";
import { ReportAttendance } from "./screens/Attendance/ReportAttendance.js";
import { RCAReport } from "./screens/BreakDownMaintenance/RCAReport.js";

import RCAPdfReport from "./screens/BreakDownMaintenance/RCAPdfReport.js";

import BillingSheet from "./screens/BillingSheet/BillingSheet.js";
import DailyClosingReadingCheckList from "./screens/reports/DailyClosingReadingCheckListReport.js";
import CriticalEquipmentRoomTemperatureMonitoringReport from "./screens/reports/CriticalEquipmentRoomTemperatureMonitoringReport.js";
import BillingSheetReport from "./screens/reports/BillingSheetReport.js";
import BillingSheetReportExl from "./screens/BillingSheet/BillingSheetReportExl.js";
import PeriodicJobAllocation from "./screens/PeriodicMaintenance/JobAllocationPages/PeriodicJobAllocation.js";
// import { ReportAttendanceShiftBases } from "./screens/Attendance/ReportAttendanceShiftBases.js";

window.JSZip = jsZip;
const UserNotExists = () => {
  return (
    <Routes>
      <Route element={<WithoutNav />}>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/login" name="login" />} />
      </Route>
    </Routes>
  );
};

const UserExists = () => {
  const { getCookies, CommonLogout } = useContext(AppContext);

  const [priviledge, setpriviledge] = useState("");
  const [StationList, setStationList] = useState("");
  const [IsTvsvac, setIsTvsvac] = useState(false);
  const [IsPh1, setIsPh1] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (getCookies(Global.COOKIES.Stations) == null) {
      CommonLogout();
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";

    const stations = Decrypt(getCookies(COOKIES.Stations)) ?? "";
    setStationList(stations);
    if (stations?.length) {
      const isValidStationList = stations?.every((element) =>
        [1, 2, 3].includes(element.StationID)
      );
      setIsPh1(isValidStationList);
    }

    let timeout = setTimeout(setpriviledge(ModulePrivileges), 3000);
    setIsTvsvac(getCookies(Global.COOKIES.IsTvsvac));

    return () => {
      clearInterval(timeout);
    };
  }, [getCookies(Global.COOKIES.modulePrivileage)]);

  return (
    <Routes>
      <Route element={<WithNav />}>
        {priviledge.includes("DASHBOARDVIEW") && (
          <>
            <Route exact path="/" element={<DashboardRedirect />} />
            {IsTvsvac === "true" && (
              <Route path="/dashboard" element={<Dashboard />} />
            )}
            {IsTvsvac === "false" && (
              <Route
                path="/BreakdownDepartmentDashboard"
                element={<BreakdownDepartmentDashboard />}
              />
            )}

            <Route
              path="/EquipmentAvailability"
              element={<EquipmentAvailability />}
            />
          </>
        )}

        {priviledge.includes("MASTERVIEW") && (
          <Route path="/master" element={<MasterDetails />} />
        )}
        {priviledge.includes("MASTERVIEW") && IsTvsvac === "false" && (
          <Route
            path="/DepartmentConfigIndex"
            element={<DepartmentConfigIndex />}
          />
        )}

        {priviledge.includes("ASSOCIATEVIEW") && (
          <>
            <Route path="/employee" element={<EmployeeGrid />} />

            <Route path="/add-employee" element={<AddEmployee />} />
          </>
        )}

        {priviledge.includes("USERVIEW") && (
          <Route path="/user" element={<User />} />
        )}

        {priviledge.includes("ROLESVIEW") && (
          <Route path="/roles" element={<RoleList />} />
        )}

        {priviledge.includes("REFERENCEDOCUMENTVIEW") && (
          <Route path="/ManualUpload" element={<ManualSave />} />
        )}

        {priviledge.includes("PERIODICMAINTENANCEVIEW") && (
          <>
            {IsTvsvac == "true" ? (
              <Route path="/periodic" element={<PeriodicMaintance />} />
            ) : (
              <Route
                path="/Periodic-Department"
                element={<PeriodicMaintenanceModule />}
              />
            )}

            {/* <Route path="/daily" element={<DailyCustomView />} /> */}
            <Route path="/periodics" element={<CustomEquipmentPage />} />
            <Route path="/periodic/periodics" element={<UG19StationPMPage />} />
            <Route
              path="/periodic/Depart-periodics"
              element={<Depart_UG19StationPMPage />}
            />
            <Route
              path="/Department-DailyCalender"
              element={<CalenderDailyViewDepartment />}
            />

            <Route path="/dailyCalender" element={<CalenderDailyView />} />
            <Route path="/monthCalender" element={<CalenderChildMonth />} />
            <Route
              path="/monthCalenderDepartment"
              element={<CalenderChildMonthDepartment />}
            />
            <Route
              path="/quaterlyCalender"
              element={<CalenderChildQuaterly />}
            />
            <Route
              path="/halfyearlyCalender"
              element={<CalenderChildHalfyearly />}
            />
            <Route path="/yearlyCalender" element={<CalenderChildYearly />} />
          </>
        )}
        {priviledge.includes("EQUIPMENTDESIGNVIEW") && IsTvsvac === "true" && (
          <Route path="/design-comm" element={<DesingAndCommission />} />
        )}

        {priviledge.includes("SCADAVIEW") && IsTvsvac === "true" && (
          <Route path="/Scada-Configuration" element={<SCADAConfig />} />
        )}
        {(priviledge.includes("SPAREINVENTORYVIEW") ||
          priviledge.includes("SPAREINVENTORYCREATE")) && (
            <Route path="/spare" element={<SpareDetails />} />
          )}

        {priviledge.includes("EQUIPMENTPARAMETERVIEW") && (
          <Route path="/parameterdetails" element={<ParameterDetails />} />
        )}

        {priviledge.includes("BREAKDOWNMAINTENANCEVIEW") &&
          IsTvsvac === "true" && (
            <Route path="/breakdownmaintenance" element={<MainGrid />} />
          )}

        {priviledge.includes("BREAKDOWNMAINTENANCEVIEW") &&
          IsTvsvac === "false" && (
            <Route
              path="/BreakdownDepartmentIndex"
              element={<BreakdownDepartmentIndex />}
            />
          )}
        {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" && (
          <Route path="/AuditingReport" element={<AuditingReport />} />
        )}
        {priviledge.includes("REPORTVIEW") && (
          <Route path="/breakdownreport" element={<BreakdownReport />} />
        )}
        {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" && (
          <Route path="/Periodic-Report" element={<ReportPeriodic />} />
        )}

        {priviledge.includes("REPORTVIEW") &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/UG-19-PeriodicReport"
              element={<UG_19_PeriodicReport />}
            />
          )}
        {IsTvsvac === "true" && (
          <Route
            path="/OverAllHistoryReport"
            element={<OverAllHistoryReport />}
          />
        )}
        {IsTvsvac === "false" && (
          <Route path="/CyclicCheckReport" element={<CyclicCheckReport />} />
        )}

        {priviledge.includes("MANUALPARAMETERENTRYVIEW") &&
          IsTvsvac === "true" && (
            <Route
              path="/ManualParameterEntry"
              element={<ManualParameterEntry />}
            />
          )}

        {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" && (
          <Route
            path="/manualreport"
            element={<ReportForManulBreakDownMaintance />}
          />
        )}

        {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" && (
          <Route
            path="/CyclicCheckApproverIndex"
            element={<CyclicCheckApproverIndex />}
          />
        )}
        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/ChillerMonitoringReport"
              element={<ChillerMonitoringReport />}
            />
          )}
        {priviledge.includes("REPORTVIEW") &&
           IsTvsvac === "true" &&
          // StationList.some(
          //   (element) => ![1, 2, 3].includes(element.StationID)
          // ) &&
           (
            <Route
              path="/DailyClosingReadingCheckList"
              element={<DailyClosingReadingCheckList />}
            />
          )}
        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/CriticalEquipmentRoomTemperatureMonitoringReport"
              element={<CriticalEquipmentRoomTemperatureMonitoringReport />}
            />
          )}

        {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" && (
          <Route
            path="/Report_ManulPointEntry"
            element={<Report_ManulPointEntry />}
          />
        )}
        {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" && (
          <Route
            path="/breakdownalarmreport"
            element={<BreakdownAlarmReport />}
          />
        )}
        {priviledge.includes("REPORTVIEW") && (
          <Route path="/sparereport" element={<SpareReport />} />
        )}

        {priviledge.includes("REPORTVIEW") && (
          <Route path="/MTBF-Report" element={<HistoryReport />} />
        )}

        {priviledge.includes("PERIODICCONFIGURATIONVIEW") && (
          <>
            <Route
              path="/PeriodicConfiguration"
              element={<PeriodicConfiguration />}
            />
            <Route path="/PeriodicConfig" element={<PeriodicConfig />} />
          </>
        )}

        {priviledge.includes("NONREVENUECONFIGURATIONVIEW") &&
          IsTvsvac === "true" && (
            <Route
              path="/nonrevenueconfig"
              element={<RevenueConfiguration />}
            />
          )}

        {priviledge.includes("STATIONCONFIGVIEW") && (
          <Route path="/stationconfig" element={<StationConfig />} />
        )}
        {priviledge.includes("DELAYEDMONITORCONFIGURATIONVIEW") &&
          IsTvsvac === "true" && (
            <Route
              path="/Alertconfiguration"
              element={<AlertConfiguration />}
            />
          )}

        {priviledge.includes("MANUALLOGVIEW") &&
          IsTvsvac === "true" &&
          <Route path="/manualLog" element={<ManualLog />} />}

        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && <Route path="/CheckListReport" element={<CheckListReport />} />}

        {priviledge.includes("REPORTVIEW") && IsTvsvac == "false" && (
          <Route
            path="/PeriodicMaintenanceReport"
            element={<ReportPeriodicForDepart />}
          />
        )}

        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/DailyElectricalPanelReport"
              element={<DailyElectricalPanelReport />}
            />
          )}

        <Route
          path="/BillingSheet"
          element={<BillingSheet />}
        />


        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/CheckListCalenderView"
              element={<CheckListCalenderView />}
            />
          )}
        {priviledge.includes("REPORTVIEW") && (
          <Route
            path="/CheckListDailyCalender"
            element={<CheckListDailyCalender />}
          />
        )}
        {priviledge.includes("REPORTVIEW") && IsPh1 && (
          <Route path="/ReportAttendance" element={<ReportAttendance />} />
        )}
        {priviledge.includes("REPORTVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <Route
              path="/Routine-Shift-Check-Report"
              element={<RoutineShiftCheckReport />}
            />
          )}
        <Route
          path="/UG_19_StationDashboard"
          element={<UG_19_StationDashboard />}
        />

        <Route
          path="/RCAReport"
          element={<RCAReport />}
        />
            <Route
          path="/RCAPdfReport"
          element={<RCAPdfReport />}
        />
        {/* <Route
          path="/BillingSheetReport"
          element={<BillingSheetReport/>}
        /> */}
         <Route
          path="/BillingSheetReportExl"
          element={<BillingSheetReportExl/>}
        />

        <Route path="/Verifycation" element={<Verifycation />} />
        <Route path="/CheckListCardView" element={<CheckListCardView />} />
        <Route
          path="/CheckListCardView/Description"
          element={<MaintenanceChecksDescriptionCalenderView />}
        />
        {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" && (
          <>
            <Route
              path="/PerformanceReport"
              element={<PerformanceEvaluationReport />}
            />
            <Route path="/CheckListCardView" element={<CheckListCardView />} />
            <Route
              path="/CheckListCardView/Description"
              element={<MaintenanceChecksDescriptionCalenderView />}
            />
          </>
        )}

        {priviledge.includes("PERFORMANCEEVALUATIONVIEW") &&
          IsTvsvac === "true" &&
          StationList.some(
            (element) => ![1, 2, 3].includes(element.StationID)
          ) && (
            <>
              <Route
                path="/PerformanceEvaluation"
                element={<PerformanceEvolutionCalenderWrap />}
              />
              <Route
                path="/PerformanceEvaluationCalenderView"
                element={<PerformanceEvaluationLandingPage />}
              />
            </>
          )}
        {IsTvsvac === "false" && (
          <Route
            path="PeriodicStartMonthConfig"
            element={<PeriodicStartMonthConfig />}
          />
        )}
         
        <Route path="/Buffer-Config" element={<BufferConfig />} />
        <Route path="/Predifined-Fails" element={<PredefinedFailures />} />
        <Route
          path="/AuditingLevelConfigIndex"
          element={<AuditingLevelConfigIndex />}
        />
        <Route
          path="/DepartmentConfiguration"
          element={<DepartmentConfiguration />}
        />
        <Route
          path="/StationLocationConfiguration"
          element={<StationLocationConfiguration />}
        />

        <Route path="/ApprovalConfig" element={<ApprovalConfig />} />
        <Route path="/AuditingProcess" element={<AuditingProcessTab />} />
        <Route path="/Periodic-Detail" element={<PeriodicDetailsView />} />
        <Route path="/Periodic-Job-Allocation" element={<PeriodicJobAllocation />} />

        <Route
          path="*"
          element={
            <Error404
              page={` ${IsTvsvac === "false"
                ? "/BreakdownDepartmentDashboard"
                : "/Dashboard"
                }  `}
              name="Home"
            />
          }
        />
        {IsTvsvac === "false" && (
          <Route path="/CyclicCheckIndex" element={<CyclicCheckIndex />} />
        )}
      </Route>
      <Route element={<WithoutNav />}>
        <Route path="/login" element={<Login />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/" element={<Login />} />
        <Route path="*" element={<Error404 page="/" name="Home" />} />
        <Route path="/Error404" element={<Error404 page="/" name="Home" />} />
      </Route>
    </Routes>
  );
};

function App() {
  const { getCookies, GlobalCorridor, showLoading, hideLoading } =
    useContext(AppContext);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 10000);

  // },[])

  useEffect(() => {
    let newColor =
      GlobalCorridor == 2 ? Global.C2_GREEN_LINE : Global.C1_BLUE_COLOR;
    document.documentElement.style.setProperty(
      "--main-color",
      newColor.main_color
    );
    document.documentElement.style.setProperty(
      "--main-color-hover",
      newColor.main_color_hover
    );
    document.documentElement.style.setProperty(
      "--sidebar-back-color",
      newColor.sidebar_back_color
    );
    document.documentElement.style.setProperty(
      "--notification-color",
      newColor.notification_color
    );
    document.documentElement.style.setProperty(
      "--header-linear-color",
      newColor.header_linear_color
    );
    document.documentElement.style.setProperty(
      "--body-background-color",
      newColor.body_background_color
    );
  }, [GlobalCorridor]);

  useEffect(() => {
    //store Jwt token on local storage

    localStorage.setItem(
      Global.COOKIES.Jwt,
      Encrypt(getCookies(Global.COOKIES.Jwt))
    );
    localStorage.setItem(
      Global.COOKIES.UserName,
      Encrypt(getCookies(Global.COOKIES.UserName))
    );

    localStorage.setItem(
      Global.COOKIES.EmployeeID,
      Encrypt(getCookies(Global.COOKIES.EmployeeID))
    );
  }, [getCookies(Global.COOKIES.Jwt)]);

  return (
    <Beforeunload
      onBeforeunload={() => {
        //clear local storage when closing tab
        if (window) {
          localStorage.removeItem(COOKIES.CalendarMonth);
        }
      }}
    >
      <Router>
        {getCookies(Global.COOKIES.Jwt) != null &&
          getCookies(Global.COOKIES.Jwt) != "" ? (
          <>
            <UserExists />
            <BootrapModel
              module={<SafetyMeasurementModel />}
              Id={"SafetyMesurementModel"}
              CloseHide
              CloseID={"CloseSafetyMesurementModel"}
              modelSize={"modal-xl"}
            />
          </>
        ) : (
          <UserNotExists />
        )}
      </Router>
    </Beforeunload>
  );
}

export default App;
