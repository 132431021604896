import AxiosService from "../../services/AxiosService";

const axios = new AxiosService();

export const PeriodicStartMonth_GetMaintenanceType = () => {
  return axios.get("ApplicationConfig/PeriodicStartMonth_GetMaintenanceType");
};

export const PeriodicStartMonth_SavePeriodicStartMonth = (model) => {
  return axios.post(
    "ApplicationConfig/PeriodicStartMonth_SavePeriodicStartMonth",
    model
  );
};

export const PeriodicStartMonth_GetPeriodicStartMonthList = (
  PeriodicScheduleConfigID = 0,
  IsDeleted = 0
) => {
  return axios.get(
    `ApplicationConfig/PeriodicStartMonth_GetPeriodicStartMonthList?PeriodicScheduleConfigID=${PeriodicScheduleConfigID}&IsDeleted=${IsDeleted}`
  );
};
