import React from "react";
import PropTypes from "prop-types";


import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";

import { DailyCustomView } from "./CustomPages/DailyCustomView";

const localizer = globalizeLocalizer(globalize);

export const CalenderChildMonth = () => {

  return (
    <div className=" mt-3">
      <Calendar
        localizer={localizer}
        defaultView={"daily"}
        views={{
          daily: DailyCustomView,
        }}
        toolbar={false}
        style={{ height: 800 }}
        selectable={true}
      />
    </div>
  );
};

CalenderChildMonth.propTypes = {
//  date: localStorage.getItem("monthDate"),
date: PropTypes.string,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};


