import React, { useCallback, useEffect, useState } from "react";
import TreeTable from "../../components/TreeTable/TreeTable";
import { BootrapModel, ReactMultiSelect } from "../../components";
import BillingSheetSaveDetail from "./BillingSheetSaveDetail";
import BillingSheetServices from "./BillingSheetServices";
import $ from "jquery";
import { toast } from "react-toastify";
import { FilterDropdown } from "../../components/FilterDropdown";
import { getYMDdate } from "../../services/CommonService";
import { toDate } from "date-fns";
import { Link } from "react-router-dom";

export default function BillingSheet() {
  const _billServc = new BillingSheetServices();
  const [BillingSheetList, setBillingSheetList] = useState([]);
  const [Year, setYear] = useState(new Date().getFullYear());
  const [Quarter, setQuarter] = useState("Nov-Jan(Q1)");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [TotalAmount, setTotalAmount] = useState();
  const [isHandle, setIsHandle] = useState(false);
  useEffect(() => {
    ParentDataFunction();
  }, [dateRange]);

  const columns = [
    {
      className: "dt-control",
      orderable: true,
      data: "",
      defaultContent: "",
      createdCell: function (td, cellData, rowData) {
        const hideIcon = rowData.EquipmentShortName === "OTHERS";

        if (hideIcon) {
          $(td).css("visibility", "hidden");
        }
      },
    },
    { title: "CheckListId", data: "CheckListId", visible: false },
    { title: "Checklist Number", data: "CheckListNumber" },
    { title: "Checklist Description", data: "CheckListDescription" },
    {
      title: "Total No's",
      data: "TotalNo",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        }
      },
    },
    {
      title: "Total Completed Checklist",
      data: "TotalCompletedChecklist",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        }
      },
    },
    {
      title: "Max Marks Weightage (%)",
      data: "MaxMarksWeightage",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        const inputElement = $(
          `<input type="number" class="form-control" name ="MaxMarksWeightage" value="${cellData.toFixed(
            2
          )}" data-id="${rowData.CheckListId}" data-field="MaxMarksWeightage"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.MaxMarksWeightage = newValue;

            handleInputChange(rowData);
          }
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Marks Obtained",
      data: "MarksObtained",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        if (cellData <= 0) {
          const newValue = rowData.TotalCompletedChecklist || 0;
          const totalNos = rowData.TotalNo || 0;
          const maxMarksWeightage = parseFloat(rowData.MaxMarksWeightage) || 0;

          if (totalNos > 0) {
            rowData.MarksObtained = (newValue / totalNos) * maxMarksWeightage;
          } else {
            rowData.MarksObtained = 0;
          }
        }

        const inputElement = $(
          `<input type="number" class="form-control" name="MarksObtained" value="${rowData.MarksObtained.toFixed(
            2
          )}" data-id="${rowData.CheckListId}" data-field="MarksObtained"/>`
        ).on("change", function (e) {
          e.preventDefault();
          let updatedValue = parseFloat(e.target.value);

          rowData.MarksObtained = updatedValue;
          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },

    {
      title: "Amount",
      data: "Amount",
      createdCell: function (td, cellData, rowData) {
        const inputElement = $(
          `<input type="number" class="form-control" name="Amount" value="${cellData}" data-id="${rowData.CheckListId}" data-field="Amount"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.Amount = newValue;

            handleInputChange(rowData);
          }
        });

        $(td).html(inputElement);
      },
    },
    {
      title: "Max Amount (Rs)",
      data: "MaxAmount",
      createdCell: function (td, cellData, rowData) {
        const inputElement = $(
          `<input type="number" class="form-control" name="MaxAmount" value="${cellData}" data-id="${rowData.CheckListId}" data-field="MaxAmount"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.MaxAmount = newValue;

            handleInputChange(rowData);
          }
        });

        $(td).html(inputElement);
      },
    },
    {
      title: "Rate as per Contract (Rs)",
      data: "RateAsPerContract",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        const inputElement = $(
          `<input type="number" class="form-control" name="RateAsPerContract" value="${cellData}" data-id="${rowData.CheckListId}" data-field="RateAsPerContract"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.MaxMarksWeightage = newValue;

            handleInputChange(rowData);
          }
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Remarks of Vendor-L1",
      data: "VendorRemarks",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        const inputElement = $(
          `<textarea  type="text" class="form-control" name ="VendorRemarks" data-id="${rowData.CheckListId}" data-field="VendorRemarks">${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = e.target.value;

          rowData.VendorRemarks = newValue;

          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Remarks of CMRL-L2",
      data: "CMRLRemarks",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";

        const inputElement = $(
          `<textarea  type="text" class="form-control" name="CMRLRemarks" data-id="${rowData.CheckListId}" data-field="CMRLRemarks">${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = e.target.value;

          rowData.CMRLRemarks = newValue;

          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
  ];

  const ChildDataColumns = [
    { title: "CheckListId", data: "CheckListId", visible: false },
    { title: "Station Name", data: "StationCode" },
    {
      title: "",
      data: "StatusName",
      createdCell: function (td, cellData, rowData) {
        const content =
          cellData === "Approved"
            ? '<span style="background-color: green; color: white; padding: 3px 6px; border-radius: 4px;">Completed</span>'
            : '<span style="background-color: red; color: white; padding: 3px 6px; border-radius: 4px;">InComplete</span>';
        $(td).html(content);
      },
    },
  ];

  const ParentDataFunction = async () => {
    try {
      var FromDate = dateRange.fromDate || `${Year}-11-01`;
      var ToDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;

      const parentData = await _billServc.BillingSheet_GetMaintenanceDetails(
        FromDate,
        ToDate
      );
      console.log(parentData?.data?.data);
      const updatedParentData = await Promise.all(
        parentData?.data?.data.map(async (parentItem) => {
          const childData = await ChildDataFunction({
            CheckListId: parentItem.CheckListId,
          });
          const totalRow = childData?.length;
          const approvedCount = childData.filter(
            (item) => item.StatusName === "Approved"
          ).length;

          return {
            ...parentItem,
            TotalNo: totalRow,
            TotalCompletedChecklist: approvedCount,
          };
        })
      );
      const totalAmount = updatedParentData.reduce((sum, item) => {
        if (item.EquipmentShortName === "OTHERS") {
          return (sum -= item.Amount);
        }
        return (sum += item.Amount);
      }, 0);

      setTotalAmount(totalAmount);

      setBillingSheetList(updatedParentData);
    } catch (error) {
      console.log(error);
    }
  };

  const ChildDataFunction = async ({ CheckListId }) => {
    try {
      var FromDate = dateRange.fromDate || `${Year}-11-01`;
      var ToDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;

      const response = await _billServc.BillingSheet_GetJobApprovedStationList(
        CheckListId,
        FromDate,
        ToDate
      );
      return response?.data?.data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleInputChange = async (obj) => {
    try {
      obj.fromDate = dateRange.fromDate || `${Year}-11-01`;
      obj.toDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;
      const SaveDetails = await _billServc.BillingSheet_SaveMaintenanceDetails(
        obj
      );
      if (SaveDetails.data > 0) {
        ParentDataFunction();
        //toast.success("Saved Successfully");
      }
    } catch (error) {
      toast.error("Save Failed");
    }
  };
  const handleQuarterChange = (event) => {
    const selectedQuarter = event.target.value;
    setQuarter(selectedQuarter);
    updateDateRange(selectedQuarter, Year);
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    updateDateRange(Quarter, selectedYear);
  };

  const updateDateRange = (quarter, year) => {
    let fromDate = "";
    let toDate = "";

    switch (quarter) {
      case "Nov-Jan(Q1)":
        fromDate = `${year}-11-01`;
        toDate = `${parseInt(year) + 1}-01-31`;
        break;
      case "Feb-Apr(Q2)":
        fromDate = `${year}-02-01`;
        toDate = `${year}-04-30`;
        break;
      case "May-Jul(Q3)":
        fromDate = `${year}-05-01`;
        toDate = `${year}-07-31`;
        break;
      case "Aug-Oct(Q4)":
        fromDate = `${year}-08-01`;
        toDate = `${year}-10-31`;
        break;
      default:
        break;
    }

    setDateRange({ fromDate, toDate });
  };

  return (
    <div>
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-6">
            <h3 className="page-title">Billing Sheet</h3>
          </div>
          <div className="col-6" style={{width :"11%"}}>
            <Link to="/BillingSheetReportExl">
              {" "}
              <button className="btn btn-secondary">Go to Report</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <label className="form-check-label">Select</label>
          <div>
            <select
              onChange={handleQuarterChange}
              value={Quarter}
              className="form-select"
            >
              <option>Nov-Jan(Q1)</option>
              <option>Feb-Apr(Q2)</option>
              <option>May-Jul(Q3)</option>
              <option>Aug-Oct(Q4)</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-check-label">Year</label>
          <FilterDropdown
            code={"Year"}
            name={"Year"}
            onChange={(e) => handleYearChange(e.target.value)}
            value={Year}
          />
        </div>
        <div className="col-md-3">
          <label className="form-check-label">Total Amount</label>
          <input
            type="number"
            className="form-control"
            value={TotalAmount}
            disabled
          />
        </div>
        <div className="col-3">
          <button
            type="button"
            className="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target="#AddBillingSheetCheckList"
            style={{ marginTop: "20px" }}
          >
            <i className="fa fa-plus"></i> Add
          </button>
        </div>
      </div>
      <TreeTable
        columnList={columns}
        rowList={BillingSheetList}
        id={"BillingTable"}
        title={"Billing Sheet"}
        childColumns={ChildDataColumns}
        ChildApiFunction={ChildDataFunction}
        isAsc
      />
      <BootrapModel
        Id={"AddBillingSheetCheckList"}
        CloseID="CloseBillingSheetCheckList"
        ModelTitle={`BillingSheet CheckList Creation`}
        modelSize={"modal-xl"}
        closepopup={ParentDataFunction}
        module={<BillingSheetSaveDetail BillingSheetList={BillingSheetList} />}
      />
    </div>
  );
}
