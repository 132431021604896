import Axios from "../../../services/AxiosService";

const axios = new Axios();

const BufferConfig_GetDropDown = async () => {
  return await axios.get(`ApplicationConfig/BufferConfig_GetDropDown`);
};
const BufferConfig_AddOrUpdate = async (data) => {
  return await axios.post("ApplicationConfig/BufferConfig_AddOrUpdate", data);
};
const BufferConfig_GetBufferConfigList = async () => {
  return await axios.get(`ApplicationConfig/BufferConfig_GetBufferConfigList`);
};
const BufferConfig_DeleteConfig = async (BufferConfigID) => {
  return await axios.post(
    `ApplicationConfig/BufferConfig_DeleteConfig?BufferConfigID=${BufferConfigID}`
  );
};
const BufferDays_GetByDepartmentID = async (DepartmentID) => {
  return await axios.get(
    `ApplicationConfig/BufferDays_GetByDepartmentID?DepartmentID=${DepartmentID}`
  );
};

export {
  BufferConfig_GetDropDown,
  BufferConfig_AddOrUpdate,
  BufferConfig_GetBufferConfigList,
  BufferConfig_DeleteConfig,
  BufferDays_GetByDepartmentID,
};
