import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class AuthService {
  Login(data) {

    return axios
      .post("Auth/Login", data)
      .then((response) => response.data)
      .catch((err) => console.warn(err));
  }

  GetStationandPriviledgeByID(userName, userID){
    return axios
      .get(`Auth/GetStationandPriviledgeByID?userID=${userID}&userName=${userName}`)
      .then((response) => response.data)
      .catch((err) => console.warn(err))
  }
}
