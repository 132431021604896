import React from "react";
import MasterDetailService from "../master/MasterDetailService";
import BreakdownMaintenanceService from "./BreakdownMaintenanceService";
import { HtmlTable } from "../../components/HtmlTable";
import { useState, useEffect, useContext } from "react";
import { DataTableReports } from "../../components/DataTableReports";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import $, { error } from "jquery";

import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";
const obj = new BreakdownMaintenanceService();
const MDS = new MasterDetailService();
const animatedComponents = makeAnimated();

export const ReportForManulBreakDownMaintance = () => {
  const { showLoading, hideLoading, getCookies,handleGlobalStationName } = useContext(AppContext);
  const columnNamesForManual = [
    // { title: "Ticket ID", visible: false },
    { title: "Serial No", data: "RowId" },
    { title: "Station Code", data: "StationCode" },

    {
      title: "Ticket No",
      data: "TicketNo",
    },

    { title: "Equipment Tag No", data: "EquipmentCode" },
    { title: "Parameter", data: "ParameterName" },
    { title: "Assigned By", data: "AssignedByName" },
    { title: "Assigned To", data: "AssignedToName" },
    { title: "Problem Description", data: "ProblemDescription" },
    { title: "Work Description", data: "WorkDescription" },
    { title: "Remarks by Engineer", data: "EngineerRemarks" },
    { title: "Remarks by Technician", data: "RemarksForTechnician" },
    { title: "Location", data: "Location" },
    { title: "Created Date", data: "CreatedDate" },
    { title: "Work Completion Date", data: "ClosedDate" },

    {
      title: " Status",
      data: "StatusName",
    },
  ];

  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [data, setData] = useState([]);
  const [FromDate, SetfromDate] = useState("");
  const [ToDate, SetToDate] = useState("");

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map(x => x.label).join()
    handleGlobalStationName(StationLabel)
  };

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);

    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };

  useEffect(() => {
    getStationList();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    SetfromDate(firstDay.toISOString().split("T")[0]);
    SetToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    // var stationIds = StationID.map((x) => {
    //   return x.value;
    // });

    if (FromDate != null) {
      $("#ToDate").attr("min", FromDate);
    }

    if (ToDate != null) {
      $("#FromDate").attr("max", ToDate);
    }

    // getData(stationIds);

    return () => {
      setData([]);
    };
  }, [StationID, FromDate, ToDate]);

  function getData(stationIds) {
    if (
      stationIds != null &&
      stationIds != "" &&
      FromDate != "" &&
      ToDate != ""
    ) {
      showLoading();
      obj
        .Report_BreakdownMaintenanceGetManualTicket(
          FromDate,
          ToDate,
          stationIds
        )
        .then((response) => {
          var BreakList = [];
          if (response.data.length > 0) {
            var breakdownMaintenance = response.data;

            breakdownMaintenance.map((v, i) => {
              var row = [];
              // row.push(v.TicketID);
              row.push(i + 1);
              row.push(v.StationCode);
              row.push(v.TicketNo);
              row.push(v.EquipmentCode);
              row.push(v.ParameterName);
              row.push(v.AssignedByName);
              row.push(v.AssignedToName);
              row.push(v.ProblemDescription);
              row.push(v.WorkDescription);
              row.push(v.Location);
              row.push(v.CreatedDate);
              row.push(v.ClosedDate);
              row.push(v.StatusName);
              row.push("");
              BreakList.push(row);
            });

            setData([...BreakList]);
            hideLoading();
          } else {
            hideLoading();
          }
        })
        .catch((Err) => {
          console.log(Err);
          hideLoading();
        });
    } else {
      setData([]);
      hideLoading();
    }
  }

  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col">
            <h3 className="page-title">Manual Ticket Report</h3>
          </div>
          <div className="col-2">
            <label className="col-form-label">From Date</label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              defaultValue={FromDate}
              onChange={(e) => {
                SetfromDate(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <label className="col-form-label">To Date</label>

            <input
              type="date"
              className="form-control"
              id="ToDate"
              defaultValue={ToDate}
              onChange={(e) => {
                SetToDate(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <label className="col-form-label">Stations</label>

            <ReactMultiSelect
              options={StationList}
              isMulti
              value={StationID}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => HandleChange(e)}
              allowSelectAll={true}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <div className="">
          <DataTableReportAjax
            columnList={columnNamesForManual}
            StationID={StationID ? StationID.map((x) => x.value).join() : ""}
            FromDate={FromDate}
            ToDate={ToDate}
            url={`${BASE_URL}BreakdownMaintenance/Report_BreakdownMaintenanceGetManualTicket`}
            id={"ManualBreakdownReport"}
            title={"Manual Ticket Report"}
            isAsc={true}
            pageLength={10}
            landscape
          />
        </div>
      </div>
    </>
  );
};
