import React from "react";
import { TabBar } from "../../components/TabBar";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { EquipmentManager } from "./EquipmentManager";
import { Parameter } from "./Parameter";
import { Spare } from "./Spare";
import { Station } from "./Station";
import { Equipment } from "./Equipment";
import { DepartmentTypeConfig } from "./DepartmentTypeConfig";
import { Location } from "./Location";
import { Area } from "./Area";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { getUserDetails } from "../../services/CommonService";
import { DepartmentConfiguration } from "./DepartmentConfiguration";

const Header = ({ title }) => {
  return (
    <div className="page-header mb-3">
      <div className="row">
        <div className="col-4">
          <h3 className="page-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export const DepartmentConfigIndex = () => {
  const { handleMasterTab } = useContext(AppContext);

  const [TabInitialVal, setTabInitialVal] = useState(0);
  const location = useLocation();
  const TabHeader = [
    "Department Configuration",
    "Department Type Configuration",
  ];

  const TabBody = [
    <DepartmentConfiguration TabID={0} TabValue={TabInitialVal} />,
    <DepartmentTypeConfig TabID={1} TabValue={TabInitialVal} />,
  ];

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;

      setTabInitialVal(0);
    }
  }, []);

  const onTabChange = (e) => {
    setTabInitialVal(e - 1);
  };

  return (
    <>
      <Header title={"Configuration Details"} />
      <div className="card">
        <TabBar
          header={getUserDetails().RoleID === COOKIES.OperationRoleID
            ? TabHeader
            // : TabHeader
            : TabHeader.slice(0, 1)}
          body={getUserDetails().RoleID === COOKIES.OperationRoleID
            ? TabBody
            // : TabBody
            : TabBody.slice(0, 1)}
          InitialValue={TabInitialVal}
          onTabChange={onTabChange}
        />
      </div>
    </>
  );
};
