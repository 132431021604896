import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class ParameterService {


    GetEquipmentParamterByID(EquipmentID) {
    return axios.get(`Parameter/GetEquipmentParamterByID?EquipmentID=${EquipmentID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  GetStatusData() {
    return axios.get(`Parameter/GetParameterStatusData`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }
  AddParameterDetails(data) {

    return axios.post("Parameter/AddEquipmentParameterDetails", data)
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
  }
  DeleteParameterConfiguration(parameterConfigID) {
    return axios.get(`Parameter/DeleteParameterConfiguration?parameterConfigID=${parameterConfigID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  



}