
import React, { useEffect, useMemo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-big-calendar'
import { useNavigate } from 'react-router';
import { getYMDdate, getUserDetails,Week5_render, determineWeekColor } from '../../services/CommonService';
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { TabBar } from '../../components/TabBar';
import { PeriodicWeekData, checkDate, checkMonth } from './PeriodicWeekData';
import { PeriodicService } from "./PeriodicService";
import { PeriodicSetQuerterMOnthDate } from "../../services/CommonService";



const HalfYearlyMonths = [
  {
    Quater: "1st Half-Yearly",
    Month: 5,
  },

  {
    Quater: "2nd Half-Yearly",
    Month: 11,
  },
];

const MonthList = [5, 11];


const _periodicServices = new PeriodicService();

export default function CalendarHalfyearlyView({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}) {

  const { getCookies,showLoading,hideLoading } = useContext(AppContext);

  const [JobPendingDate, setJobPendingDate] = useState([]);

  useEffect(() => {
    var curMonth = date.getMonth() + 1;
    var curYear = date.getFullYear();
    var finalDate;
    if (MonthList.includes(curMonth)) {
      finalDate = date;
    } else {
      while (true) {
        if (curMonth < 12) {
          curMonth = curMonth + 1;
          if (MonthList.includes(curMonth)) {
            finalDate = new Date(curYear, curMonth - 1, 1);
            break;
          }
        } else if (curMonth == 12) {
          curMonth = 1;
          curYear = curYear + 1;
        }
      }
    }
    // var finalMonth = finalDate.getMonth() + 1;



    // var list =[];
    //   list.push(Months[finalMonth-2]);
    // list.push(Months[finalMonth-1]);
    // list.push(Months[finalMonth]);

    // setHeader([...list]);
  }, [date])





  const currRange = useMemo(
    () => {

      var Mydate = PeriodicSetQuerterMOnthDate(date, 4);
      return CalendarHalfyearlyView.range(Mydate, { localizer })
    },
    [date, localizer]
  );

  const navigate = useNavigate();
  const navigateTo = (i, currdate) => {
    var newdate = date;
    if (currdate == '22') {
      if (localStorage.getItem("halfyearlyMonth") == null) {
        localStorage.setItem("halfyearlyMonth", getYMDdate(new Date(newdate.getFullYear(), newdate.getMonth() + 1, 22)))
        localStorage.setItem("halfyearlyWeek", i)

      }
      else {
        var weekdate = new Date(localStorage.getItem("halfyearlyMonth"));
        localStorage.setItem("halfyearlyMonth", getYMDdate(new Date(newdate.getFullYear(), newdate.getMonth(), 22)))
        localStorage.setItem("halfyearlyWeek", i)
      }
      navigate('/halfyearlyCalender', { state: { week: i, date: getYMDdate(localStorage.getItem("halfyearlyMonth")) } });

    }
    else {
      var currneDate = PeriodicSetQuerterMOnthDate(date, 4);
      localStorage.setItem("halfyearlyMonth", 
            getYMDdate(getYMDdate(new Date(`${currneDate.getFullYear()}-${currneDate.getMonth()+1}-${'01'}`))))
      localStorage.setItem("halfyearlyWeek", i)
      navigate('/halfyearlyCalender', { state: { week: i, date: getYMDdate(currneDate) } });
    }

  }


  var week5 = PeriodicWeekData(currRange[0]);

  useEffect(() => {
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      navigateTo(1, new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    }

  }, [])

  useEffect(() => {
    
    var currentMonth, currentYear, todayDate;
    var curDate = date;
    var d;
    
    if (curDate != null) {
      d = new Date(curDate);
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());

    } else {
      d = new Date();
      localStorage.setItem("halfyearlyMonth", new Date());
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    }
    showLoading()
    _periodicServices.JobSchedulePendingDate(currentMonth, currentYear, todayDate, 4, parseInt(getCookies(COOKIES.PeriodicStationID)))
      .then((res) => {
        hideLoading()
        if (res != null && res.data != null && res.data != undefined) {
          setJobPendingDate([...res.data])
          console.log(res.data)
        }
      }
      ).catch((err) =>{
        console.log(err)
        hideLoading()
      });

  }, [date, localStorage.getItem("halfyearlyMonth"), getCookies(COOKIES.PeriodicStationID)]);


  var week1 = currRange.slice(0, 7);
  var week2 = currRange.slice(7, 14);
  var week3 = currRange.slice(14, 21);
  var week4 = currRange.slice(21, 28);

  var weekLst = [week1, week2, week3, week4];


  var isWeek;
  var isCurrentMonth;
  let its_future_week5 = false
  let pendingcolor_5 = false
  let checkdaterange_5 = false
  let isCurrentMonth_5 = false
  let isCurrentWeek = false
  let Week5IsNoJob = false



  return (
    <>

      {
        weekLst.map((v, i) => {
          var color = v.map(c => { return c.getDate() });
          var dates = v.map(c => { return getYMDdate(c) });
          var isPending = v.map(c => { return JobPendingDate.some((x) => x.JobDate == getYMDdate(c))});

          let CheckNoJob = v.map(c => { return JobPendingDate.some((x) => x.JobDate == getYMDdate(c) && x.CheckAnyJob)});


          var pendingcolor = isPending.includes(true);
          let IsNoJob = CheckNoJob.every(x => x == false);

          var checkdaterange = dates.some(checkDate);

          isCurrentMonth = dates.some(checkMonth);
          let j = 0;
          if (color.includes(new Date().getDate())) {
            j = i + 1
            if (j == 4) {
              isWeek = true
            }
          }
          let its_future_week = false
          if (v){
            its_future_week = v[0].getTime() > new Date().getTime()
          }
          if (i + 1 == 4){
            its_future_week5 = its_future_week
            pendingcolor_5 = pendingcolor
            checkdaterange_5 = checkdaterange
            isCurrentMonth_5 = isCurrentMonth
            isCurrentWeek = (i + 1) == j
            Week5IsNoJob = IsNoJob
          }
          

          return <div className='row calender'>
            <div className={'col card CalenderCard_Week ' +  determineWeekColor(i, j, isCurrentMonth, pendingcolor, IsNoJob, its_future_week) }>{`Week ${i + 1}`}</div>
            {
              v.map(c => {
                let shortMonth = c.toLocaleString("default", {
                  month: "short",
                });
                let date = c.getDate();
                let its_future = new Date().getTime() < c.getTime();
                return <div className={'col card CalenderCard_Week_Date ' + determineWeekColor(i, j, isCurrentMonth, pendingcolor, IsNoJob, its_future_week)} onClick={() => navigateTo(i + 1, c.toDateString())}>
                  {(date < 10 ? '0' + date : date)}
                </div>
              })
            }
          </div>
        })
      }
      {   
        <>
          {     
          Week5_render(week5,isCurrentMonth_5,pendingcolor_5,checkdaterange_5,its_future_week5,isCurrentWeek,navigateTo,Week5IsNoJob)
          }
        </>}
    </>
  )
}

CalendarHalfyearlyView.propTypes = {
  date: localStorage.getItem("halfyearlyMonth"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

CalendarHalfyearlyView.range = (date, { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(start, new Date(date.getFullYear(), date.getMonth(), 0).getDate(), 'day')

  let current = start
  const range = []

  while (localizer.lte(current, end, 'day')) {
    range.push(current)
    current = localizer.add(current, 1, 'day')
  }

  return range
}

CalendarHalfyearlyView.navigate = (date, action, { localizer }) => {
  
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -6, "month");

    case Navigate.NEXT:
      return localizer.add(date, 6, "month");

    default:
      return date;
  }
}

CalendarHalfyearlyView.title = (date, { localizer }) => {
  var curMonth = date.getMonth() + 1;
  var curYear = date.getFullYear();
  var finalDate;
  if (MonthList.includes(curMonth)) {
    finalDate = date;
  } else {
    while (true) {
      if (curMonth < 12) {
        curMonth = curMonth + 1;
        if (MonthList.includes(curMonth)) {
          finalDate = new Date(curYear, curMonth - 1, 1);
          break;
        }
      } else if (curMonth == 12) {
        curMonth = 1;
        curYear = curYear + 1;
      }
    }
  }
  var finalMonth = finalDate.getMonth() + 1;
  var quater = HalfYearlyMonths.find((d) => d.Month == finalMonth);
  var finalMonthString = finalDate.toLocaleString("default", {
    month: "short",
  });
  var finalYear = finalDate.getFullYear();
  var titleString = `${quater.Quater} (${finalMonthString}-${finalYear})`;
  return titleString;

}

