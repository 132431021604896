import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";

import $ from "jquery";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import {
  Decrypt,

} from "../../services/CommonService";
import {
  showWarningMsg,
  showSaveSuccess,
  Dropdown,
  ReactMultiSelect,
  HtmlTable,
  showSaveError,
  showErrorMsg,
  BootrapModel,
  showSuccessMsg,
} from "../../components";
import { MaintenanceChecksForChecks, MaintenanceChecksReportData } from "../dashboard/TempData";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import { PageHeader } from "../../components/PageHeader";
import Global from "../../services/Global";

const _breakdownService = new BreakdownMaintenanceService();
export default function MaintenanceChecks({  }) {
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
    DescriptionList: [],
    FilterEmployeeList: [],
    TechnicianList: [],
    EngineerList: [],
  });
  const initialCheckList = {
    EquipmentID: {},
    StationID: "",
    Description: "",
    ShiftTechnicianID: "",
    ShiftEngineerID: "",
    OrderNo: 0,
    IsShowSubmitBtn: false,
    CheckAllAttended: false,
    CheckAllApproved: false,
    CheckAllRejected: false,
  };

  const [CheckListModel, setCheckListModel] = useState(initialCheckList);

  const [FileData, setFileData] = useState([]);
  const [CheckListScheduleType, setCheckListScheduleType] = useState(43);

  const [errors, setErrors] = useState({
    EquipmentID: "",
    StationID: "",
    Description: "",
  });

  const {
    getCookies,
    TypeDetails,
    HandleTypeDetails,
    handleGlobalStationName,
    showLoading,
    hideLoading,
  } = useContext(AppContext);

  useEffect(() => {
    initialDropDown();

    return () => {
      setDropdownModel({});
    };
  }, []);

  const columnList = [
    {
      title: "Description",
      data: "description",
    },
    {
      title: "Action",
      data: "action",
    },
    {
      title: "Actual",
      data: "actual",
      createdCell: function (td, cellData, rowData, row, col) {
        var inputElement = "";

          inputElement = $(
            `<input type="text"  class="form-control_copy Equipment_" style="width:60px" value="${cellData}"/>`
          );
        
        inputElement.on("change", function (e) {
          e.preventDefault();
          const { value } = e.target;
          rowData.actual= value;
        });

        $(td).html(inputElement);
      },
    },
    {
      title: "Status",
      data: "Status",
    },

    {
      title: "Remarks",
      data: "remarks",
      createdCell: function (td, cellData, rowData, row, col) {
        var inputElement = "";

          inputElement = $(
            `<textarea type="text"  class="form-control_copy Equipment_" style="resize:none" value="${cellData}"/>`
          );
        
        inputElement.on("change", function (e) {
          e.preventDefault();
          const { value } = e.target;
          rowData.actual= value;
        });

        $(td).html(inputElement);
      },
    },
  ];




  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async (ScheduleType) => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });


    setDropdownModel((preState) => ({
      ...preState,
      StationList: FinalStationList,
    }));
  };


  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      event.preventDefault();

      const { name, value } = event.target;
      setCheckListModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (name == "StationID") {
        const EquipmentList = DropdownModel.FilterEquipmentList?.filter((x) => {
          return x.StationID == value;
        });

        HandleTypeDetails(name, value);
        setDropdownModel((pre) => ({
          ...pre,
          EquipmentList: EquipmentList,
        }));

        setCheckListModel((pre) => ({
          ...pre,
          EquipmentID: EquipmentList[0],
        }));
        let selectedStation = DropdownModel.StationList.find(
          (x) => x.value == value
        );

        handleGlobalStationName(selectedStation?.key ?? "");
      }
    } else {
      setCheckListModel((pre) => ({
        ...pre,
        [name]: event,
      }));
    }
  };

  function SubmitButton() {
    return $(
      `<button  class="btn btn-primary m-l-15"
        >  Submit</button>  `
    ).on("click", function (e) {
      e.preventDefault();
    });
  }


  function PTWNumbers() {
    let PwtOpen = $(
      `<label class='m-l-15' style ="display:inline-flex" > 
        <input type="number"style="height:35px" class="form-control m-l-15"
        placeholder = "PTW No (Open):"
        /></label>
        `
    ).on("change", function (e) {

    });
    let PwtClose = $(
      `<label class='m-l-15' style ="display:inline-flex" > <span> PTW No (Close): </span>
        <input type="number"style="height:35px" class="form-control m-l-15"
        /></label>
        `
    ).on("change", function (e) {

    });
    let SpanTag = $(`<span>`)
    SpanTag.append(PwtOpen)
    SpanTag.append(PwtClose)

return SpanTag;

  }






  return (
    <div>
      {/* <div style={{ position: "absolute", top: "65px", right: "33px" }}>
        {CheckListModel?.EquipmentID?.DocumentNo &&
          `Doc Number :${CheckListModel?.EquipmentID?.DocumentNo}`}
      </div> */}
      <div className="row">
        <PageHeader
          title={`Maintenance CheckList - (Monthly) `}
        >
          {" "}
        </PageHeader>
      </div>
      <form id="MaintenanceCheckList">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={TypeDetails.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment
                <span className="text-danger"> *</span>
              </label>
              <ReactMultiSelect
                options={DropdownModel.EquipmentList}
                placeholder="Select"
                value={CheckListModel.EquipmentID}
                onChange={(e) => {
                  CommonHandleChange(e, "EquipmentID");
                }}
              />
              <span className="text-danger"> {errors.EquipmentID} </span>
            </div>
          </div>
       
        </div>
      </form>

      {columnList?.length ? (
        <>
          <div style={{ marginTop: "-25px" }}>
            <HtmlTable
              FixedColums={{
                left: 2,
                right: 2,
              }}
              OrderTargetList={Array.from(
                { length: columnList.length - 6 },
                (_, i) => i + 2
              )}
              IsASC
              rowList={MaintenanceChecksForChecks}
              columnList={columnList}
              SearchPostponedHtml={
                  SubmitButton
              }
              SearchPrependHtml={PTWNumbers}
              id={`MaintenanceCheckListTable`}
            />
          </div>
          <div className="row"></div>
        </>
      ) : (
        <div className="row col-12 text-center  mb-2">
          <p className="text-muted"></p>
        </div>
      )}

      
    </div>
  );
}
