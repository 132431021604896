import React from "react";
import { useState, useContext, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { PeriodicService } from "../PeriodicService";
import { AppContext } from "../../../services/ContextProvider";
import { COOKIES } from "../../../services/Global";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  showSaveSuccess,
  showSaveError,
  showWarningMsg,
} from "../../../components/ToastMsg";
import $ from "jquery";
import { useEffect } from "react";
import BreakdownMaintenanceService from "../../BreakDownMaintenance/BreakdownMaintenanceService";
import { Dropdown } from "../../../components/Dropdown";
import {
  getUserDetails,
  GetLoginUserID,
} from "../../../services/CommonService";
import { SpareList } from "../SpareList";

const _periodicService = new PeriodicService();
const _breakdownService = new BreakdownMaintenanceService();
const CustomEquipmentPage = () => {
  const location = useLocation();
  const { equipmentID, jobScheduleID, maintenanceTypeID, fromdate, enddate } =
    location.state;
  const [EquipmentID, setEquipmentID] = useState(equipmentID);
  const [JobScheduleID, setJobScheduleID] = useState(null);
  const [rows, setRows] = useState([]);
  const [equipmentDetailsList, setEquipmentDetailsList] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [equipmentDetailID, setEquipmentDetailID] = useState(0);
  const [PWTNumber, setPWTNumber] = useState("");
  const [PWTDate, setPWTDate] = useState("");
  const { getCookies, calRefresh, handleCalRefresh, showLoading, hideLoading } =
    useContext(AppContext);
  const [statusList, setStatusList] = useState(null);
  const [spareList, setSpareList] = useState([]);
  const [spareListAdded, setSpareListAdded] = useState([]);
  const [RefreshforForm, setRefreshforForm] = useReducer((x) => x + 1, 0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDetails, setselectedDetails] = useState([]);
  const [selectedRowsSpecific, setSelectedrowsSpecific] = useState([]);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileSelect = (event) => {
    setSelectedFiles([...event.target.files]);
  };
  const [Loading, setLoading] = useState(true);

  useEffect(() => {

    const checkIsUG19 = ![1, 2, 3].includes(
      parseInt(getCookies(COOKIES.PeriodicStationID))
    );
    if (checkIsUG19) {
      navigate("/periodic/periodics", {
        state: { ...location.state, ...{ StationID: parseInt(getCookies(COOKIES.PeriodicStationID)) } },
      });
    }
  }, []);

  useEffect(() => {
    // setSpareList([]);
    if (
      (localStorage.getItem("JobScheduleID") == null &&
        JobScheduleID == null) ||
      JobScheduleID == 0
    ) {
      localStorage.setItem("JobScheduleID", jobScheduleID);
      setJobScheduleID(jobScheduleID);
    }
    // GetSpareData();
    getStatusList();
    getEquipmentDetails();
  }, []);
  useEffect(() => {
    setSelectedFiles([]);
    setSelectedRows([]);
    // setSpareList([]);
    setScheduleData([]);
    // getEquipmentDetails();
    // GetSpareData(localStorage.getItem("JobScheduleID"));
  }, [
    EquipmentID,
    maintenanceTypeID,
    fromdate,
    enddate,
    localStorage.getItem("JobScheduleID"),
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const GetSpareData = () => {
    let ID = localStorage.getItem("JobScheduleID");
    _periodicService
      .GetSpareUsingjobScheduleID(ID)
      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          setSpareList([...res.data]);
        }
        // else {
        //     setSpareList([]);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEquipmentDetails = () => {
    showLoading();

    _periodicService
      .Periodic_GetEquipmentDetailsListByID(
        parseInt(getCookies(COOKIES.PeriodicStationID)),
        EquipmentID,
        fromdate,
        enddate,
        maintenanceTypeID
      )
      .then((res) => {
        if (res != null && res.data != null) {
          if (res.data != null && res.data.length > 0) {
            var dataLst = [];
            var EquipForSpare = [];
            res.data.map((v) => {
              var data = [];
              data.push("");
              data.push(v.EquipmentDetailID);
              data.push(v.EquipmentCode);
              // data.push(v.MaintenanceCount);
              data.push(v.JobStatus);
              // data.push(v.Totalcount);
              data.push(v.StatusName);
              data.push(v.JobStatusFlag);
              data.push(v.PWTNumber);
              data.push(v.PWTDate);
              dataLst.push(data);
              EquipForSpare.push({
                key: v.EquipmentCode,
                value: v.EquipmentDetailID,
              });
            });
            setRows(dataLst);
            setEquipmentDetailsList(EquipForSpare);
            hideLoading();
          } else {
            hideLoading();
            setRows([]);
            setEquipmentDetailsList([]);
          }
          hideLoading();
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const getStatusList = () => {
    var statustype = getUserDetails().RoleID == COOKIES.TechRoleID ? 2 : 1;

    _periodicService.GetStatusList(statustype).then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        var status = [];
        res.data.map((v) => {
          status.push({ key: v.StatusName, value: v.StatusID });
        });
        setStatusList(status);
      }
    });
  };

  const SetLoading = (e) => {
    setLoading(e);
  };

  const saveData = (data) => {
    _periodicService
      .AddorUpdateJobMaintenanceByID(data)
      .then((data) => {
        hideLoading();
        if (data != null && data.data != 0) {
          showSaveSuccess();
          getEquipmentDetails();
          setJobScheduleID(data.data);
          localStorage.setItem("JobScheduleID", data.data);
          getMaintenanceDataById(equipmentDetailID);

          setLoading(false);
        } else {
          showSaveError();
          setLoading(false);
        }
      })
      .catch((err) => {
        hideLoading();
        setLoading(false);
      });
  };

  const EditData = (maintenanceScheduleID) => {
    setLoading(true);
    const formData = new FormData();
    const filteredData = scheduleData.filter(
      (item) => item.MaintenanceScheduleID == maintenanceScheduleID
    );

    // $.each($("#uploadFile_"+maintenanceScheduleID)[0].files, function (i, file) {
    //     formData.append('files', file);
    // });

    selectedFiles.length > 0 &&
      selectedFiles.map((v, i) => {
        formData.append("files", v);
      });

    var list = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      MaintenanceTypeID: maintenanceTypeID,
      JobScheduleID: localStorage.getItem("JobScheduleID"),
      FromDate: fromdate,
      ToDate: enddate,
      EquipmentID: EquipmentID,
      EquipmentDetailID: filteredData[0]?.EquipmentDetailID,
      MaintenanceScheduleID: maintenanceScheduleID,
      AssignedBy:
        getUserDetails().RoleID != COOKIES.TechRoleID ? GetLoginUserID() : 0,
      AssignedTo:
        getUserDetails().RoleID == COOKIES.TechRoleID ? GetLoginUserID() : 0,
      JobStatus: parseInt(filteredData[0]?.Status),
      RemarksForEngg: filteredData[0]?.RemarksByEngineer,
      RemarksForTech: filteredData[0]?.RemarksByTechncian,
      PWTNumber: PWTNumber,
      PWTDate: PWTDate,
      IsSpare: 0,
      SavedBy: GetLoginUserID(),
    };
    _breakdownService
      .FileUpload(formData)
      .then((res) => {
        const finalData = {
          JobScheduleForPeriodic: list,
          ListOfOrginalFileName: res,
        };
        saveData(finalData);
        // $("#uploadFile_"+maintenanceScheduleID).val = ''
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (equipmentDetailID != 0) {
      setScheduleData([]);
      getMaintenanceDataById(equipmentDetailID);
    }
  }, [equipmentDetailID]);

  const savespareData = () => {
    var job = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      MaintenanceTypeID: maintenanceTypeID,
      JobScheduleID: localStorage.getItem("JobScheduleID"),
      FromDate: fromdate,
      ToDate: enddate,
      EquipmentID: EquipmentID,
      IsSpare: 1,
      SavedBy: GetLoginUserID(),
    };
    const data = {
      JobScheduleForPeriodic: job,
      sparesUsedMasterModel: spareList,
    };
    _periodicService
      .AddorUpdateJobMaintenanceByID(data)
      .then((data) => {
        hideLoading();
        if (data != null && data.data != 0) {
          showSaveSuccess();
          navigate(-1);
          setJobScheduleID(data.data);
          localStorage.setItem("JobScheduleID", data.data);
          setRefreshforForm();
          // GetSpareData();
          // getMaintenanceDataById(equipmentDetailID);
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
      });
  };

  const saveSpare = (list) => {
    setSpareList(list);
  };

  const getMaintenanceDataById = (id) => {
    try {
      SetLoading(true);
      _periodicService
        .Periodic_GetMaintenanceScheduleByEquipments(
          fromdate,
          enddate,
          parseInt(getCookies(COOKIES.PeriodicStationID)),
          maintenanceTypeID,
          id,
          EquipmentID
        )
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            setScheduleData([]);
            var rows = [];
            res.data.map((v) => {
              let buttonDisabled = false;
              let disabled = false;
              let Status = v.JobStatus;
              if (
                getUserDetails().RoleID != COOKIES.TechRoleID &&
                (Status == null || Status == 3 || Status == 4)
              ) {
                Status = Status == null ? 3 : Status;
                disabled = true;
                buttonDisabled = false;
              } else if (
                getUserDetails().RoleID != COOKIES.TechRoleID &&
                Status == 1
              ) {
                Status = Status == null ? 3 : Status;
                disabled = true;
                buttonDisabled = true;
              } else if (
                getUserDetails().RoleID == COOKIES.TechRoleID &&
                (Status == null || Status == 1 || Status == 6 || Status == 2)
              ) {
                Status = 2;
                disabled = true;
                buttonDisabled = false;
              } else if (
                getUserDetails().RoleID == COOKIES.TechRoleID &&
                Status == 4
              ) {
                Status = 2;
                disabled = true;
                buttonDisabled = false;
              } else if (
                getUserDetails().RoleID != COOKIES.TechRoleID &&
                Status == 2
              ) {
                Status = 3;
                disabled = false;
                buttonDisabled = false;
              } else {
                Status = 2;
                disabled = true;
                buttonDisabled = true;
              }

              rows.push({
                ...v,
                file: [],
                Status: Status,
                disabled: disabled,
                buttonDisabled: buttonDisabled,
              });
            });

            setScheduleData([...rows]);
            SetLoading(false);
          } else {
            setScheduleData([]);
            SetLoading(false);
          }
        });
    } catch (err) {
      setScheduleData([]);
      SetLoading(false);
      console.log(err);
    }
  };

  const HandleChange = (e, id) => {
    e.preventDefault();
    const { name, value, files } = e.target;
    const updatedArray = scheduleData.map((item) => {
      if (item.MaintenanceScheduleID == id) {
        if (name == "file") {
          return { ...item, [name]: files[0] };
        } else {
          return { ...item, [name]: value };
        }
      }
      return item;
    });

    setScheduleData([...updatedArray]);
  };

  const HandleCheckbox = (e, id, PWTNumber, PWTDate) => {
    // e.preventDefault();
    if (e.target.checked == true) {
      let temp = selectedRows;
      temp.push({
        EquipmentDetailID: id,
        PWTNumber: PWTNumber,
        PWTDate: PWTDate,
      });
      setSelectedRows([...temp]);
    } else {
      let temp = selectedRows;
      temp = temp.filter((x) => {
        return x.EquipmentDetailID != id;
      });
      // if (index > -1) {
      //   temp.splice(index, 1);
      // }
      setSelectedRows([...temp]);
    }
  };

  const updateJobStatus = (status) => {
    const list = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      EquipmentID: EquipmentID,
      MaintenanceTypeID: maintenanceTypeID,
      JobScheduleID: localStorage.getItem("JobScheduleID"),
      FromDate: fromdate,
      ToDate: enddate,
      AssignedBy:
        getUserDetails().RoleID != COOKIES.TechRoleID ? GetLoginUserID() : 0,
      AssignedTo:
        getUserDetails().RoleID == COOKIES.TechRoleID ? GetLoginUserID() : 0,
      JobStatus: status,
      equipmentWithPWT: selectedRows,
      Savedby: GetLoginUserID(),
    };

    // var job = {
    //     jobScheduleForPeriodic: list,

    // };

    showLoading();
    _periodicService
      .BulkUpdateJobScheduleByEquipmentDetails(list)
      .then((res) => {
        hideLoading();
        if (res != null && res.data != 0) {
          showSaveSuccess();
          setSelectedRows([]);
          localStorage.setItem("JobScheduleID", res.data);
          setJobScheduleID(res.data);
          getEquipmentDetails();
          getMaintenanceDataById(equipmentDetailID);
          $(".closestatus").prop("checked", false);
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };
  return rows.length > 0 ? (
    <>
      <div className="row d-flex justify-content-between">
        <div className="col-sm-3 mb-2 text-start">
          <button
            className="btn btn-success"
            style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <div className="col-sm-3 mb-2 text-end">
          {getUserDetails().RoleID == COOKIES.TechRoleID ? (
            <button
              class="btn btn-success"
              title="Close selected jobs"
              disabled={
                selectedRows.length > 0 || selectedRowsSpecific.length > 0
                  ? false
                  : true
              }
              onClick={(e) => updateJobStatus(2)}
            >
              <span>Close</span>
            </button>
          ) : (
            <button
              class="btn btn-success"
              title="Approve seleted jobs"
              disabled={
                selectedRows.length > 0 || selectedRowsSpecific.length > 0
                  ? false
                  : true
              }
              onClick={(e) => updateJobStatus(3)}
              style={{ marginRight: "10px" }}
            >
              <span>Approve</span>
            </button>
          )}
          {getUserDetails().RoleID != COOKIES.TechRoleID && (
            <button
              class="btn btn-danger"
              title="Reject seleted jobs"
              disabled={
                selectedRows.length > 0 || selectedRowsSpecific.length > 0
                  ? false
                  : true
              }
              onClick={(e) => updateJobStatus(4)}
            >
              <span>Reject</span>
            </button>
          )}
        </div>
      </div>
      <div className="card card-body">
        <Accordion allowZeroExpanded={true}>
          {rows?.map((item, key) => {
            let is_checked =
              selectedRows.findIndex((x) => x.EquipmentDetailID == item[1]) !==
              -1;

            return (
              <>
                <AccordionItem key={key} uuid={key}>
                  <div style={{ position: "relative" }}>
                    <AccordionItemHeading
                      onClick={() => {
                        setEquipmentDetailID(item[1]);
                        setPWTNumber(item[6]);
                        setPWTDate(item[7]);
                      }}
                    >
                      <AccordionItemButton>{item[2]}</AccordionItemButton>
                    </AccordionItemHeading>
                    <div className="text-end d-flex accordianStyle closestatus">
                      {getUserDetails().RoleID == COOKIES.TechRoleID ? (
                        <div className="periodic_status">
                          {item[5] == 1 || item[5] == 2
                            ? `${item[4]}`
                            : item[5] == 3
                              ? "Open / Closed"
                              : "Open"}
                        </div>
                      ) : (
                        <div className="periodic_status">
                          {item[5] == 1 || item[5] == 2
                            ? `${item[4]}`
                            : item[5] == 3
                              ? "Approved / Rejected"
                              : "Open"}
                        </div>
                      )}
                      {getUserDetails().RoleID == COOKIES.TechRoleID ? (
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          checked={is_checked}
                          disabled={
                            (item[5] == 2 && item[3] != 4) || item[5] == 4
                              ? true
                              : item[3] == 4 || item[3] == 1 || item[5] == 3
                                ? false
                                : true
                          }
                          onChange={(e) =>
                            HandleCheckbox(e, item[1], item[6], item[7])
                          }
                        />
                      ) : (
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          checked={is_checked}
                          disabled={item[5] == 1 ? true : false}
                          onChange={(e) =>
                            HandleCheckbox(e, item[1], item[6], item[7])
                          }
                        />
                      )}
                    </div>
                  </div>

                  <AccordionItemPanel>
                    {!Loading ? (
                      <div
                        className="card card-body table-responsive"
                        style={{ overflow: "auto" }}
                      >
                        <div className="row">
                          <div className="col-md-4 col-sm-6">
                            <div class="input-block mb-3 row">
                              <label class="col-lg-4 col-form-label">
                                {" "}
                                PTW Number
                              </label>

                              <div class="col-lg-8">
                                <input
                                  className="form-control "
                                  type="text"
                                  value={PWTNumber}
                                  onChange={(e) => {
                                    setPWTNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div class="input-block mb-3 row">
                              <label class="col-lg-4 col-form-label">
                                {" "}
                                PTW Date
                              </label>

                              <div class="col-lg-8">
                                <input
                                  className="form-control "
                                  type="date"
                                  defaultValue={PWTDate ? PWTDate : ""}
                                  onChange={(e) => {
                                    setPWTDate(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <table
                          id={`example-${item[1]}`}
                          className="table table-striped no-footer display"
                        >
                          <thead>
                            <tr>
                              <th style={{ display: "none" }}>
                                MaintenanceScheduleID
                              </th>
                              <th>Description</th>
                              <th>Remarks By Engineer</th>
                              <th>Remarks By Technician</th>
                              {getUserDetails().RoleID == COOKIES.TechRoleID ? (
                                <>
                                  <th>Current Status</th>
                                  <th style={{ display: "none" }}>
                                    Job Status
                                  </th>
                                </>
                              ) : (
                                <th>Job Status</th>
                              )}

                              <th>File</th>
                              <th>Submit</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {scheduleData != null &&
                                scheduleData.length > 0 ? (
                                scheduleData.map((data) => {
                                  return (
                                    <tr>
                                      <td style={{ display: "none" }}>
                                        {data?.MaintenanceScheduleID}
                                      </td>
                                      <td style={{ width: "25%" }}>
                                        {data.Description}
                                      </td>
                                      <td>
                                        <textarea
                                          maxLength={200}
                                          disabled={
                                            getUserDetails().RoleID ==
                                            COOKIES.TechRoleID
                                          }
                                          style={{ width: "220px" }}
                                          className="form-control"
                                          onChange={(e) =>
                                            HandleChange(
                                              e,
                                              data?.MaintenanceScheduleID
                                            )
                                          }
                                          defaultValue={
                                            data?.RemarksByEngineer != null
                                              ? data?.RemarksByEngineer
                                              : ""
                                          }
                                          name="RemarksByEngineer"
                                        />
                                      </td>
                                      <td>
                                        <textarea
                                          maxLength={200}
                                          disabled={
                                            getUserDetails().RoleID !=
                                            COOKIES.TechRoleID
                                          }
                                          style={{ width: "220px" }}
                                          className="form-control"
                                          onChange={(e) =>
                                            HandleChange(
                                              e,
                                              data?.MaintenanceScheduleID
                                            )
                                          }
                                          defaultValue={
                                            data?.RemarksByTechncian != null
                                              ? data?.RemarksByTechncian
                                              : ""
                                          }
                                          name="RemarksByTechncian"
                                        />
                                      </td>
                                      {getUserDetails().RoleID ==
                                        COOKIES.TechRoleID ? (
                                        <>
                                          <td>
                                            <p className="mt-2">
                                              {data.JobStatusName != null
                                                ? data.JobStatusName
                                                : "Open"}
                                            </p>
                                          </td>
                                          <td style={{ display: "none" }}>
                                            <select
                                              className={`form-select`}
                                              name={"Status"}
                                              value={
                                                data?.Status != null
                                                  ? data?.Status
                                                  : 1
                                              }
                                              id={"JobStatus"}
                                              onChange={(e) =>
                                                HandleChange(
                                                  e,
                                                  data?.MaintenanceScheduleID
                                                )
                                              }
                                              disabled={data.disabled}
                                            >
                                              <option disabled selected>
                                                {"--Select Status--"}
                                              </option>
                                              {statusList != null &&
                                                statusList.length > 0 ? (
                                                statusList.map((v, i) => {
                                                  return (
                                                    <option
                                                      key={i}
                                                      value={v.value}
                                                    >
                                                      {v.key}
                                                    </option>
                                                  );
                                                })
                                              ) : (
                                                <></>
                                              )}
                                            </select>
                                          </td>
                                        </>
                                      ) : (
                                        <td style={{ width: "20%" }}>
                                          <select
                                            className={`form-select`}
                                            name={"Status"}
                                            value={
                                              data?.Status != null
                                                ? data?.Status
                                                : 1
                                            }
                                            id={"JobStatus"}
                                            onChange={(e) =>
                                              HandleChange(
                                                e,
                                                data?.MaintenanceScheduleID
                                              )
                                            }
                                            disabled={data.disabled}
                                          >
                                            <option disabled selected>
                                              {"--Select Status--"}
                                            </option>
                                            {statusList != null &&
                                              statusList.length > 0 ? (
                                              statusList.map((v, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={v.value}
                                                  >
                                                    {v.key}
                                                  </option>
                                                );
                                              })
                                            ) : (
                                              <></>
                                            )}
                                          </select>
                                        </td>
                                      )}
                                      {/* <td>
                                        <p className="mt-2">
                                          {data.JobStatusName != null
                                            ? data.JobStatusName
                                            : "Open"}
                                        </p>
                                      </td> */}

                                      <td>
                                        <input
                                          type={"file"}
                                          multiple={true}
                                          className="form-control-file fileupload"
                                          id={
                                            "uploadFile_" +
                                            data?.MaintenanceScheduleID
                                          }
                                          onChange={handleFileSelect}
                                          name="file"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            EditData(
                                              data?.MaintenanceScheduleID
                                            )
                                          }
                                          className="btn icon-btn"
                                          disabled={data.buttonDisabled}
                                        >
                                          <i
                                            class="fa fa-check"
                                            style={{ marginTop: "10px" }}
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-center" colspan="6">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div
                          className="text-center"
                          style={{ margin: "10rem" }}
                        >
                          <div className="loader-Wrapper">
                            <div className="Customloader-div">
                              <span className="loader"></span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </>
            );
          })}
        </Accordion>
      </div>

      <div className="card card-body">
        {/* <h4 className='text-center'> Used Spare</h4> */}
        <SpareList
          IsAdd={getUserDetails().RoleID == COOKIES.TechRoleID}
          Ref={RefreshforForm}
          Equlist={equipmentDetailsList}
          saveSpare={saveSpare}
          SelectedSpareList={spareList}
        ></SpareList>
        <br></br>
        <div className="row d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary col-md-2 col-sm-2"
            onClick={savespareData}
          >
            Save
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="card card-body text-center"> No Equipment found</div>
  );
};

export default CustomEquipmentPage;
