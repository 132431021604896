import React, { useState, useEffect } from "react";
import $ from "jquery";
import { HtmlTable, PageHeaderTitle, showWarningMsg } from "../../components";
import {
  AlertConfig_SaveAlertConfiguration,
  AlertConfig_GetById,
} from "./AlertconfigurationService";
import { GetLoginUserID, validateEmail } from "../../services/CommonService";
import { showSaveSuccess, showErrorMsg } from "../../components";
import swal from "sweetalert";

/**
 * AlertConfiguration Component
 *
 * This component is used for managing alert configurations related to delayed transfers.
 */
function AlertConfiguration() {
  // ... (state declarations and other stuff)
  const initialState = {
    DelayId: 0,
    DelayCount: 0,
    AlertRecipient: "",
    CreatedBy: GetLoginUserID(),
  };
  const initialError = {
    DelayCount: "",
    AlertRecipient: "",
  };

  const [monitorModel, setMonitorModel] = useState(initialState);
  const [monitorErrorModel, setMonitorErrorModel] = useState(initialError);
  const [monitorListModel, setMonitorListModel] = useState([]);
  const columnNames = [
    { title: "delayId", visible: false },
    { title: "Serial No" },
    { title: "Delay Count (In days)" },
    { title: "Delay Alert Recipient" },
    {
      title: "Submit ",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        //   if (getPrivilege().includes("BREAKDOWNMAINTENANCEEDIT")) {
        b = $(`<button class='btn icon-btn text-center' title='Edit'>
                    <i class='fa fa-pencil m-r-5'></i>
                  </button>`).on("click", function () {
          editAlertConfiguration(rowData[0]);
        });
        // }
        var d = "";
        d = $(`<button class='btn icon-btn text-center'  title='Delete'  >
             <i class='fa fa-trash m-r-5'></i>
                   </button>`).on("click", function () {
          swal({
            title: "Are you sure?",
            text: "Do you want to delete this configuration?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              const modelList = await alertConfigurationGetById(rowData[0], 1);
              if (modelList?.length) {
                const table_data = FetchDetails(modelList);

                setMonitorListModel([...table_data]);
              } else {
                setMonitorListModel([]);
              }
            }
          });
        });
        $(td).html(b);
        $(td).append(d);
      },
    },
  ];

  /**
   * FetchDetails
   *
   * This function processes the list of configurations and prepares them for rendering in the table.
   *
   * @param {Array} configList - The list of configuration data.
   */

  const FetchDetails = (configList) => {
    if (configList) {
      let rowList = [];
      let serial_no = configList.length;
      configList.map((x) => {
        let row = [];
        row.push(x.DelayId);
        row.push(serial_no);
        row.push(x.DelayCount);
        row.push(x.AlertRecipient);
        row.push("");
        rowList.push(row);
        serial_no--;
      });
      setMonitorListModel([...rowList]);
    }
  };

  /**
   * useEffect hook
   *
   * This hook runs when the component mounts. It fetches the initial list of alert configurations.
   */
  useEffect(() => {
    alertConfigurationGetById();

    return () => {
      setMonitorListModel([]);
    };
  }, []);

  /**
   * editAlertConfiguration
   *
   * Fetches the configuration details of a specific ID and updates the monitorModel state.
   *
   * @param {Number} id - The ID of the configuration to be fetched.
   */
  const editAlertConfiguration = async (id) => {
    const editModel = await alertConfigurationGetById(id);
    if (editModel?.length) {
      setMonitorModel(editModel[0]);
    } else {
      setMonitorModel(initialState);
    }
  };

  /**
   * inputOnChange
   *
   * Event handler for form input changes. Updates the monitorModel state with new values.
   *
   * @param {Event} e - The event object.
   */
  const inputOnChange = (e) => {
    const { name, value } = e?.target;
    setMonitorModel((PreviousState) => ({
      ...PreviousState,
      [name]: value,
    }));
  };

  /**
   * alertConfigurationGetById
   *
   * Fetches a configuration by its ID or gets all configurations if no ID is provided.
   * Updates the state with fetched configurations.
   *
   * @param {Number} id - (Optional) The ID of the configuration to be fetched.
   * @param {Number} isDeleted - (Optional) Filter flag for fetching configurations.
   * @returns {Array|null} - Returns the list of configurations or null in case of an error.
   */
  const alertConfigurationGetById = async (id = 0, isDeleted = 0) => {
    return await AlertConfig_GetById(id, isDeleted)
      .then((res) => {
        if (res?.data) {
          if (id === 0) FetchDetails(res.data.data);
          else {
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        showErrorMsg();
        console.log(err);
        return null;
      });
  };

  /**
   * alertConfigurationGetById
   *
   * Fetches a configuration by its ID or gets all configurations if no ID is provided.
   * Updates the state with fetched configurations.
   *
   * @param {Number} id - (Optional) The ID of the configuration to be fetched.
   * @param {Number} isDeleted - (Optional) Filter flag for fetching configurations.
   * @returns {Array|null} - Returns the list of configurations or null in case of an error.
   */
  const saveAlertSaveConfiguration = async (e) => {
    e.preventDefault();

    let error = true;
    if (!monitorModel.DelayCount) {
      setMonitorErrorModel((pre) => ({
        ...pre,
        DelayCount: "Please Enter Valid Number.",
      }));
      error = false;
    } else {
      setMonitorErrorModel((pre) => ({
        ...pre,
        DelayCount: "",
      }));
    }
    let AlertRecipient = monitorModel.AlertRecipient;
    const emails = AlertRecipient.split(",").map((email) => email.trim());
    let emailValid = emails.every(validateEmail);
    if (AlertRecipient?.length && emailValid) {
      setMonitorErrorModel((pre) => ({
        ...pre,
        AlertRecipient: "",
      }));
    } else {
      setMonitorErrorModel((pre) => ({
        ...pre,
        AlertRecipient: "Please Enter Valid Email Address.",
      }));
      error = false;
    }

    const isAlreadyExists = monitorListModel.findIndex((x) => {
      return (
        x[2] == monitorModel.DelayCount &&
        x[3] == monitorModel.AlertRecipient.trim() &&
        monitorModel.DelayId != x[0]
      );
    });

    if (isAlreadyExists == -1) {
    } else {
      error = false;
      showWarningMsg(`This email is already associated with this same count`);
    }

    if (error) {
      await AlertConfig_SaveAlertConfiguration(monitorModel)
        .then((res) => {
          setMonitorModel({ ...initialState });
          alertConfigurationGetById();
          showSaveSuccess();
        })
        .catch((err) => {
          showErrorMsg();
          console.log(err);
        });
    }
  };

  // ... (rendered JSX)
  return (
    <div>
      {/* <PageHeaderTitle title={"File Transfer Monitor Configuration"}></PageHeaderTitle> */}
      <div class="page-header ">
        <div class="row justify-content-between">
          <div class="col-6">
            <h3 class="page-title">File Transfer Monitor Configuration</h3>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <label className="label" htmlFor="delayCount">
                        Maximum Delay Count (In Days)
                      </label>
                      <input
                        className="form-control "
                        type="number"
                        name="DelayCount"
                        id="DelayCount"
                        value={monitorModel.DelayCount}
                        onChange={inputOnChange}
                      />
                      <span className="text-danger">
                        {monitorErrorModel.DelayCount}
                      </span>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <label className="label" htmlFor="alertRecipient">
                          Delay Alert Recipient
                        </label>
                        <input
                          className="form-control "
                          type="text"
                          name="AlertRecipient"
                          id="AlertRecipient"
                          placeholder="Enter emails separated by commas..."
                          value={monitorModel.AlertRecipient}
                          onChange={inputOnChange}
                        />
                        <span className="text-danger">
                          {monitorErrorModel.AlertRecipient}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-2 mt-4">
                      <button
                        type="submit"
                        id="addRow"
                        onClick={saveAlertSaveConfiguration}
                        className="btn add-btn"
                      >
                        <i className="fa fa-plus"></i> Add
                      </button>
                    </div>
                  </div>
                </form>
                <HtmlTable
                  columnList={columnNames}
                  rowList={monitorListModel}
                  id={"alertConfigTable"}
                  // orderby={"asc"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertConfiguration;
