import React, { useState, useContext } from "react";
import { Dropdown } from "../../components/Dropdown";
import $ from "jquery";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import { HtmlTable } from "../../components/HtmlTable";
import PageHeaderTitle from "../../components/PageHeaderTitle";
import { PeriodicService } from "../periodic/PeriodicService";
import { useEffect } from "react";
import makeAnimated from "react-select/animated";
import {
  showSuccessMsg,
  showErrorMsg,
  showWarningMsg,
  showSaveSuccess,
} from "../../components/ToastMsg";
import { TabBar } from "../../components/TabBar";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";

export default function PeriodicConfig() {
  const animatedComponents = makeAnimated();

  const obj = new PeriodicService();
  const { getCookies } = useContext(AppContext);
  const [PeriodicModel, setPeriodicModel] = useState({});
  const [PeriodicListModel, setPeriodicListModel] = useState([]);
  const [PeriodicListforDailyModel, setPeriodicListforDailyModel] = useState(
    []
  );
  const [PeriodicListforMonthModel, setPeriodicListforMonthModel] = useState(
    []
  );
  const [PeriodicListforQuarterlyModel, setPeriodicListforQuarterlyModel] =
    useState([]);
  const [PeriodicListforHalfModel, setPeriodicListforHalfModel] = useState([]);
  const [PeriodicListforYearlyModel, setPeriodicListforYearlyModel] = useState(
    []
  );
  const [errors, seterrors] = useState([]);
  const [Equipmentlist, setEquipmentlist] = useState([]);
  const [StationList, setStationList] = useState([]);
  const [FilterdStationList, setFilterdStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [FilteredEquipmentShortName, setFilteredEquipmentShortName] = useState(
    []
  );
  const [EquipmentShortName, setEquipmentShortName] = useState({});
  const [WeekList, setWeekList] = useState([]);

  const [ScheduleTypeList, setScheduleTypeList] = useState([]);

  const columnNames = [
    { title: "PeriodicMaintenanceID", visible: false },
    { title: "MaintenanceScheduleID", visible: false },
    { title: "EquipmentID", visible: false },
    { title: "ScheduleTypeID", visible: false },
    { title: "Station Code" },
    { title: "Equipment" },
    { title: "Schedule Type" },
    { title: "Week" },
    { title: "Description" },
    { title: "Component" },
    {
      title: "Submit  ",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "",
          d = "";

        if (getPrivilege().includes("PERIODICCONFIGURATIONEDIT")) {
          b = $(`<button class='btn icon-btn text-center' title='Edit'>
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
            PeriodicConfig_GetByPeriodicID(rowData[1], true, 0);
            window.scrollTo(0, 0);

            $(".Weekclass").attr("disabled", false);
          });
        }
        if (getPrivilege().includes("PERIODICCONFIGURATIONDELETE")) {
          d = $(`<button class='btn icon-btn text-center'  title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
              </button>`).on("click", function () {
            Swal(rowData[1]);
          });
        }

        $(td).html(b);
        $(td).append(d);
      },
    },
  ];

  const columnNamesForDaily = [
    { title: "PeriodicMaintenanceID", visible: false },
    { title: "MaintenanceScheduleID", visible: false },
    { title: "EquipmentID", visible: false },
    { title: "ScheduleTypeID", visible: false },
    { title: "Station Code" },
    { title: "Equipment" },
    { title: "Schedule Type" },
    { title: "Week", visible: false, className: "hide" },
    { title: "Description" },
    { title: "Component" },
    {
      title: "Submit  ",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "",
          d = "";

        if (getPrivilege().includes("PERIODICCONFIGURATIONEDIT")) {
          b = $(`<button class='btn icon-btn text-center' title='Edit'>
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
            PeriodicConfig_GetByPeriodicID(rowData[1], true, 0, true);
            window.scrollTo(0, 0);
            $(".Weekclass").attr("disabled", true);
          });
        }
        if (getPrivilege().includes("PERIODICCONFIGURATIONDELETE")) {
          d = $(`<button class='btn icon-btn text-center'  title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
              </button>`).on("click", function () {
            Swal(rowData[1]);
          });
        }

        $(td).html(b);
        $(td).append(d);
      },
    },
  ];

  function Swal(PeriodicMaintenanceID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this configuration?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PeriodicConfig_GetByPeriodicID(PeriodicMaintenanceID, false, 1);
      }
    });
  }

  const StationhandleChange = (e) => {
    if (e != null && e.length > 0) {
      const values = e.map((x) => {
        return x.value;
      });

      var temp = FilteredEquipmentShortName.filter((x) => {
        var station = x.StationID.split(",").map((iNum) => parseInt(iNum, 10));

        var filarray = station.map((y) => {
          return values.includes(y);
        });

        return filarray.includes(true);
      });

      if (values.includes(EquipmentShortName.value)) {
      }

      if (EquipmentShortName.StationID !== undefined) {
        var station = EquipmentShortName.StationID.split(",").map((iNum) =>
          parseInt(iNum, 10)
        );

        var filarray = station.map((y) => {
          return values.includes(y);
        });
        if (filarray.includes(true)) {
        } else {
          setEquipmentShortName({});
        }
      }

      setEquipmentlist(temp);
    } else {
      setEquipmentlist([]);
      setEquipmentShortName([]);
    }
    SetStationID(e);
  };

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;

    if (name == "ScheduleType") {
      if (value == "1") {
        $(".Weekclass").attr("disabled", true);

        setPeriodicModel((prevState) => ({
          ...prevState,
          [name]: value,
          ["Weeks"]: -1,
        }));
      } else {
        setPeriodicModel((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        $(".Weekclass").prop("disabled", false);
      }
    } else {
      setPeriodicModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const AddHandleClick = () => {
    var pri_model = PeriodicModel;

    const { Weeks, Description, ScheduleType, Component } = pri_model;

    var ErrorMgs = errors;

    let flag = true;

    if (Weeks != null && Weeks != "") {
      ErrorMgs.Weeks = "";
    } else {
      ErrorMgs.Weeks = "Please select week";
      flag = false;
    }

    if (Description != null && Description.trim() != "") {
      ErrorMgs.Description = "";
    } else {
      ErrorMgs.Description = "Please enter description";
      flag = false;
    }

    if (Component?.trim().length) {
      ErrorMgs.Component = "";
    } else {
      ErrorMgs.Component = "Please enter Components";
      flag = false;
    }

    if (StationID != null && StationID.length > 0) {
      ErrorMgs.StationID = "";
    } else {
      ErrorMgs.StationID = "Please select station name";
      flag = false;
    }

    if (ScheduleType != null && ScheduleType != "") {
      ErrorMgs.ScheduleType = "";
    } else {
      ErrorMgs.ScheduleType = "Please select schedule type";
      flag = false;
    }

    if (EquipmentShortName != null && EquipmentShortName.value !== undefined) {
      ErrorMgs.EquipmentShortName = "";
    } else {
      ErrorMgs.EquipmentShortName = "Please select equipment";
      flag = false;
    }

    seterrors({ ...ErrorMgs });

    if (flag) {
      var StationIDS = StationID.map((x) => {
        return x.value;
      });

      // var EquipmentShortNames = EquipmentShortName.map((x) => {
      //   return x.value
      // })

      pri_model.StationIDs = StationIDS.toString();
      pri_model.EquipmentIDs = EquipmentShortName.value;

      obj
        .SavePridicConfiguration(pri_model)
        .then((res) => {
          if (res.data == "Eqiupment") {
            showWarningMsg(
              "Some eqiupment already configured with same description "
            );
          } else {
            showSaveSuccess();
            setPeriodicModel({});
            SetStationID([]);
            setEquipmentShortName([]);
            $(".PeriodicClearClass").val(" ");
            $(".PeriodicClearClassforDrop").val("-1");
            PeriodicConfig_GetByPeriodicID(0, false, 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    GetDropdown();
    PeriodicConfig_GetByPeriodicID(0, false, 0);
  }, []);

  const GetDropdown = () => {
    
    obj
      .Periodic_GetDropList()
      .then((res) => {
        var data = res.data;
        

        if (data != null) {
          var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
          var dataLst = [];

          stationList.map((data) => {
            dataLst.push({ value: data.StationID, label: data.StationCode });
          });
          setStationList(dataLst);
          setFilterdStationList(dataLst);

          if (data.stationList != null && data.stationList.length > 0) {
            // setStationList([...data.stationList])
            setFilterdStationList([...data.stationList]);
          }

          if (data.equipmentList != null && data.equipmentList.length > 0)
            setFilteredEquipmentShortName([...data.equipmentList]);
          if (data.scheduleList != null && data.scheduleList.length > 0)
            setScheduleTypeList([...data.scheduleList]);
          if (data.weekList != null && data.weekList.length > 0)
            setWeekList([...data.weekList]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PeriodicConfig_GetByPeriodicID = (
    MaintenanceID,
    SingleOrList,
    IsDeleted,
    IsDaily
  ) => {
    var employeeID = GetLoginUserID();

    obj
      .PeriodicConfig_GetByPeriodicID(
        MaintenanceID,
        IsDeleted,
        Number(employeeID)
      )
      .then((res) => {
        const data = res.data;
        if (data != null) {
          if (!SingleOrList) {
            var templist = [];
            data.map((v) => {
              var row = [];
              row.push(v.PeriodicID);
              row.push(v.MaintenanceScheduleID);
              row.push(v.EquipmentID);
              row.push(v.ScheduleType);
              row.push(v.StationCode);
              row.push(v.EquipmentShortName);
              row.push(v.MaintenanceTypeName);
              row.push(v.WeekType);
              row.push(v.Description);
              row.push(v.Component);
              row.push("");

              templist.push(row);
            });

            var daily = templist.filter((x) => x[3] == 1);
            var Monthly = templist.filter((x) => x[3] == 2);
            var Quarterly = templist.filter((x) => x[3] == 3);
            var Half = templist.filter((x) => x[3] == 4);
            var yearly = templist.filter((x) => x[3] == 5);

            setPeriodicListforDailyModel([...daily]);
            setPeriodicListforMonthModel([...Monthly]);
            setPeriodicListforQuarterlyModel([...Quarterly]);
            setPeriodicListforHalfModel([...Half]);
            setPeriodicListforYearlyModel([...yearly]);

            setPeriodicListModel([...templist]);
          } else {
            if (IsDaily) data[0].Weeks = -1;

            setPeriodicModel({ ...data[0] });

            var singleValue = data[0];
            var stationiDs = [];
            if (singleValue.StationID != null) {
              var tempstationList = [...FilterdStationList];
              stationiDs = singleValue.StationID.split(",").map((iNum) =>
                parseInt(iNum, 10)
              );

              console.log(tempstationList);
              console.log(stationiDs);

              console.log(StationList);

              stationiDs = tempstationList.filter((x) => {
                return stationiDs.includes(x.value);
              });
            }

            const Equip = FilteredEquipmentShortName.find((x) => {
              return x.value == singleValue.EquipmentID;
            });

            StationhandleChange(stationiDs);

            setEquipmentShortName({ ...Equip });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TabHeader = ["Daily", "Monthly", "Quarterly", "Half-yearly", "Yearly"];

  const FixedColums = {
    left: 1,
    right: 1,
  };

  const TabBody = [
    <HtmlTable
      columnList={columnNamesForDaily}
      rowList={PeriodicListforDailyModel}
      FixedColums={FixedColums}
      id={"PeriodicConfigTable1"}
    />,
    <HtmlTable
      columnList={columnNames}
      rowList={PeriodicListforMonthModel}
      FixedColums={FixedColums}
      id={"PeriodicConfigTable2"}
    />,
    <HtmlTable
      columnList={columnNames}
      rowList={PeriodicListforQuarterlyModel}
      FixedColums={FixedColums}
      id={"PeriodicConfigTable3"}
    />,
    <HtmlTable
      columnList={columnNames}
      rowList={PeriodicListforHalfModel}
      FixedColums={FixedColums}
      id={"PeriodicConfigTable4"}
    />,
    <HtmlTable
      columnList={columnNames}
      rowList={PeriodicListforYearlyModel}
      FixedColums={FixedColums}
      id={"PeriodicConfigTable5"}
    />,
  ];

  return (
    <div>
      <PageHeaderTitle title={"Periodic Maintenance Schedule Configuration"} />
      <div className="card">
        <div className="card-body">
          {(getPrivilege().includes("PERIODICCONFIGURATIONCREATE") ||
            getPrivilege().includes("PERIODICCONFIGURATIONEDIT")) && (
              <form id="PeriodicConfig">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Station Name<span className="text-danger"> *</span>{" "}
                      </label>
                      <ReactMultiSelect
                        options={StationList}
                        isMulti
                        allowSelectAll
                        value={StationID}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={(e) => StationhandleChange(e)}
                      />

                      <span className="text-danger mt-1">
                        {" "}
                        {errors.StationID}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Equipment
                        <span className="text-danger"> *</span>
                      </label>

                      <ReactMultiSelect
                        options={Equipmentlist}
                        // isMulti
                        value={EquipmentShortName}
                        // closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={(e) => {
                          setEquipmentShortName(e);
                        }}
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.EquipmentShortName}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Schedule Type
                        <span className="text-danger"> *</span>
                      </label>
                      <Dropdown
                        data={ScheduleTypeList}
                        HandleChange={CommonHandleChange}
                        placeholder="Select"
                        value={PeriodicModel.ScheduleType}
                        name="ScheduleType"
                        initialVal={-1}
                        className={"PeriodicClearClassforDrop"}
                      />
                      <span className="text-danger"> {errors.ScheduleType} </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Week
                        <span className="text-danger"> *</span>
                      </label>
                      <Dropdown
                        data={WeekList}
                        HandleChange={CommonHandleChange}
                        placeholder="Select"
                        value={PeriodicModel.Weeks}
                        name="Weeks"
                        initialVal={-1}
                        className={"PeriodicClearClassforDrop Weekclass"}
                      />
                      <span className="text-danger"> {errors.Weeks} </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Description
                        <span className="text-danger"> *</span>
                        {/* <span className="text-danger"> *</span>{" "} */}
                      </label>
                      <textarea
                        className="form-control PeriodicClearClass"
                        style={{ resize: "none" }}
                        type="text"
                        name="Description"
                        value={PeriodicModel.Description}
                        onChange={CommonHandleChange}
                        rows={3}
                      />
                      <span className="text-danger"> {errors.Description} </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Component
                        <span className="text-danger"> *</span>
                        {/* <span className="text-danger"> *</span>{" "} */}
                      </label>
                      <textarea
                        className="form-control PeriodicClearClass"
                        style={{ resize: "none" }}
                        type="text"
                        name="Component"
                        value={PeriodicModel.Component}
                        onChange={CommonHandleChange}
                        rows={3}
                      />
                      <span className="text-danger"> {errors.Component} </span>
                    </div>
                  </div>

                  <div className="col-md-2 mt-4">
                    <button
                      type="button"
                      id="addRow"
                      onClick={AddHandleClick}
                      className="btn add-btn"
                    >
                      <i className="fa fa-plus"></i> Add
                    </button>
                  </div>
                </div>
              </form>
            )}

          <TabBar header={TabHeader} body={TabBody}>
            {" "}
          </TabBar>
        </div>
      </div>
    </div>
  );
}
