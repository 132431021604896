import React from "react";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidMount()
    {
        this.setState({ hasError: false });
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {  
        if(prevProps.Flag != this.props.Flag )
        this.setState({ hasError: false })
    
    }

    componentDidCatch(error, info) {
        
        console.log(error)
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {

            // You can render any custom fallback UI
            return <p style={{ color: "red" }}>{this.props.ErrorMessage}</p>;
        }
        return this.props.Child;
    }
}
