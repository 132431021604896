import React from "react";
import CyclicCheckApproverList from "./CyclicCheckApproverList";
import { CyclicCheckApproverProvider } from "./CyclicCheckApproverContext";

function CyclicCheckApproverIndex() {
  return (
    <CyclicCheckApproverProvider>
      <CyclicCheckApproverList />
    </CyclicCheckApproverProvider>
  );
}

export default CyclicCheckApproverIndex;
