import React from "react";

export const DropdownWithOtherName = ({
  data,
  placeholder,
  name,
  value,
  reg,
  initialVal,
  disabled,
  HandleChange,
  className
}) => {
  return (
    <select
      className={`form-select ${className}`}
      name={name}
      value={value}
      ref={reg}
      id={name}
      disabled={disabled}
      onChange={HandleChange}
      
    >
      <option disabled selected value={initialVal != null ? initialVal : ""}>
        {placeholder}
      </option>
      {data != null && data.length > 0 ? (
        data.map((v, i) => {
          return (
            <option key={i} value={v.values}>
              {v.label}
            </option>
          );
        })
      ) : (
        <></>
      )}
    </select>
  );
};
