import React from "react";
import Autocomplete from "react-autocomplete";

export const ReactAutoComplete = ({
  options,
  name,
  Id,
  handleChange,
  handleSelect,
  value = "",
}) => {
  function RenderFilter(state, val) {
    return (
      state.label.toLowerCase().indexOf(val.toString().toLowerCase()) !== -1
    );
  }
  return (
    <div className="ReactAutoComplete">
      <Autocomplete
        getItemValue={(item) => item.label}
        items={options}
        renderItem={(item, isHighlighted) => (
          <div
            key={item.id}
            style={{
              background: isHighlighted ? "lightgray" : "white",
              padding: "10px",
            }}
          >
            {item.label}
          </div>
        )}
        inputProps={{
          className: "form-control",
          id: Id,
          name: name,
        }}
        shouldItemRender={RenderFilter}
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
      ></Autocomplete>
    </div>
  );
};
