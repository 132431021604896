import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class MasterDetailService {
  GetEquipmentManagerDetails(equipmentManagerID, isDelete, employeeID = 0,StationID=0,DepartmentID=0) {
    return axios
      .get(
        `EquipmentManager/GetEquipmentManagerDetails?equipmentManagerID=${equipmentManagerID}&&IsDelete=${isDelete != 0
        }&&employeeID=${employeeID}&StationID=${StationID}&DepartmentID=${DepartmentID}`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  AddOrUpdateManager(data) {
    debugger
    return axios
      .post("EquipmentManager/AddOrUpdateEqptManager", data)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetParameter(parameterID, isDelete) {
    return axios
      .get(
        `EquipmentManager/GetParameters?parameterID=${parameterID}&isDelete=${isDelete}`
      )
      .then((response) => {
        //;
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          console.log(jsonData);
          return jsonData;
        }
        return null;
      })
      .catch((err) => {
        //;
        return null;
      });
  }

  async GetSpare(spareID, isDelete) {
    return await axios
      .get(`EquipmentManager/GetSpares?spareID=${spareID}&isDelete=${isDelete}`)
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
  }

  AddParameter(parameter) {
    return axios
      .post("EquipmentManager/AddOrUpdateParameter", parameter)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  AddSpare(spare) {
    return axios
      .post("EquipmentManager/AddOrUpdateSpare", spare)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetEquipmentDetails(detailID, managerID, isDelete) {
    return axios
      .get(
        `EquipmentManager/GetEquipmentDetails?detailID=${detailID}&managerID=${managerID}&IsDelete=${isDelete != 0
        }`
      )
      .then((response) => {
        if (response != null && response.data != null) {
          var jsonData = response.data;
          if (jsonData.data != null || jsonData.data != "")
            jsonData.data = JSON.parse(jsonData.data);
          return jsonData;
        }
        return null;
      })
      .catch((err) => null);
  }

  AddOrUpdateEqptDetail(eqptDetails) {
    return axios
      .post("EquipmentManager/AddOrUpdateEqptDetail", eqptDetails)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  GetEqptLocations() {
    return axios
      .get("MasterConfig/GetEquipmentLocationList")
      .then((response) => response)
      .catch((err) => null);
  }

  GetEqptAreaList() {
    return axios
      .get("MasterConfig/GetEquipmentAreaList ")
      .then((response) => response)
      .catch((err) => null);
  }

  GetStationList() {
    return axios
      .get("MasterConfig/StationList")
      .then((response) => response)
      .catch((err) => null);
  }

  GetEquipmentList(Id) {
    debugger
    return axios
      .get(
        `MasterConfig/Config_GetEquipmentForEquipmentManager?EmployeeID=${Id}`)
      .then((response) => response)
      .catch((err) => null);
  }

  SaveStation(data) {
    return axios.post("MasterConfig/SaveStationMaster", data);
  }

  DeleteStation(Id) {
    return axios.get(`MasterConfig/DeleteStation?StationId=${Id}`);
  }

  GetEquipmentLocationList() {
    return axios.get("MasterConfig/GetEquipmentLocationList");
  }

  MasterConfig_GetDepartmentList() {
    return axios.get("MasterConfig/MasterConfig_GetDepartmentList");
  }
  MasterConfig_GetDepartmentListForEquipmentManager() {
    return axios.get("MasterConfig/MasterConfig_GetDepartmentListForEquipmentManager");
  }

  SaveEquipmentLocation(data) {
    return axios.post("MasterConfig/SaveEquipmentLocation", data);
  }

  DeleteEquipmentLocation(Id) {
    return axios.get(`MasterConfig/DeleteEquipmentLocation?EquipLocID=${Id}`);
  }

  GetEquipmentAreaList() {
    return axios.get("MasterConfig/GetEquipmentAreaList");
  }
  SaveEquipmentArea(data) {
    return axios.post("MasterConfig/SaveEquipmentArea", data);
  }

  DeleteEquipmentArea(Id) {
    return axios.get(`MasterConfig/DeleteEquipmentArea?AreaID=${Id}`);
  }

  GetEquipmentMaster(StationIds = "", employeeID) {
    return axios.get(
      `MasterConfig/GetEquipmentMaster?stationIds=${StationIds}&&employeeID=${employeeID}`
    );
  }

  DepartmentType_Update(DepartmentID = 0, Type = 0, ModifiedBy = 0) {
    return axios.get(
      `MasterConfig/DepartmentType_Update?DepartmentID=${DepartmentID}&&Type=${Type}&&ModifiedBy=${ModifiedBy}`
    );
  }
  SaveEquipmentMaster(data) {
    return axios.post("MasterConfig/SaveEquipmentMaster", data);
  }
  GetSystemType() {
    return axios.get("MasterConfig/GetSystemType");
  }
  DeleteEquipment(Id) {
    return axios.get(`MasterConfig/DeleteEquipment?EquipmentID=${Id}`);
  }
  async MasterConfigTempretureConfigurationSave(model) {
    return await axios.post(
      `MasterConfig/MasterConfigTempretureConfigurationSave`,
      model
    );
  }
  MasterConfigGetTempretureConfiguration() {
    return axios.get(`MasterConfig/MasterConfigGetTempretureConfiguration`);
  }

  GetStationColorList(EmployeeID) {
    return axios
      .get(`MasterConfig/GetStationsColorList?EmployeeId=${EmployeeID}`)
      .then((response) => {
        if (response != null && response.data != null) {
          return response.data;
        }
      })
      .catch((err) => console.log(err));
  }

  SaveStationColor(stationID, employeeID, color) {
    console.log(stationID, employeeID, color.substring(1));
    color = color.substring(1);
    return axios.get(
      `MasterConfig/StationColorMapping?StationID=${stationID}&EmployeeID=${employeeID}&color=${color}`
    );
  }
}

export async function MasterConfig_GetDepartmentList() {
  return await axios.get(`MasterConfig/MasterConfig_GetDepartmentList`);
}

export function MasterConfig_SaveDepartments(DepartList) {
  let headers = {
    'Content-Type': 'text/plain' // Adjust content type if needed
  }
  return axios.post(`MasterConfig/MasterConfig_SaveDepartments`, DepartList, headers);
}

export async function DepTypeConfig_Getlist() {
  return await axios.get(`MasterConfig/DepTypeConfig_Getlist`);
}