import Axios from "../../services/AxiosService";
const axios = new Axios();

const CheckList_DropDownDetails = async (ScheduleType) => {
  return await axios.get(
    `CheckList/CheckList_DropDownDetails?ScheduleType=${ScheduleType}`
  );
};

const CheckList_SaveCheckListDetails = async (data) => {
  return await axios.post("CheckList/CheckList_SaveCheckListDetails", data);
};
const CheckList_GetDescriptionByID = async (
  StationID,
  EquipmentID,
  ScheduleType,
  CheckDate
) => {
  return await axios.get(
    `CheckList/CheckList_GetDescriptionByID?StationID=${StationID}&EquipmentID=${EquipmentID}&ScheduleType=${ScheduleType}&CheckDate=${CheckDate}`
  );
};

const CheckList_ReportGetByID = async (
  StationID,
  EquipmentID,
  ScheduleType,
  FromDate,
  ToDate
) => {
  return await axios.get(
    `CheckList/CheckList_ReportGetByID?StationID=${StationID}&EquipmentID=${EquipmentID}&ScheduleType=${ScheduleType}&FromDate=${FromDate}&ToDate=${ToDate}`
  );
};
const CheckList_ReportDropDownDetails = async () => {
  return await axios.get(`CheckList/CheckList_ReportDropDownDetails`);
};

const CheckList_SaveScheduleCheckListDetails = async (data) => {
  return await axios.post(
    "CheckList/CheckList_SaveScheduleCheckListDetails",
    data
  );
};
const CheckList_GetStatusUsingID = async (
  StationID,
  ScheduleType,
  StartDate,
  EndDate
) => {
  return await axios.get(`CheckList/CheckList_GetStatusUsingID?StationID=${StationID}
  &ScheduleType=${ScheduleType}&StartDate=${StartDate}&EndDate=${EndDate}`);
};
export {
  CheckList_DropDownDetails,
  CheckList_ReportGetByID,
  CheckList_SaveCheckListDetails,
  CheckList_GetDescriptionByID,
  CheckList_SaveScheduleCheckListDetails,
  CheckList_ReportDropDownDetails,
  CheckList_GetStatusUsingID,
};
