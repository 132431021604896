import React, { useContext, useEffect, useState } from "react";
import { ReactTreeView, Dropdown } from "../../components";
import $ from "jquery";
import { CyclicCheckApproverContext } from "./CyclicCheckApproverContext";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import {
  findDepartmentById,
  GetLoginUserID,
} from "../../services/CommonService";
import makeAnimated from "react-select/animated";
import { CyclicCheck_GetListByCyclicCheckId } from "./CyclicCheckApproverServices";
import { showSaveSuccess, showSaveError, showErrorMsg } from "../../components";
import { Cycliccheck_ApproveProcess } from "./CyclicCheckApproverServices";
import { AppContext } from "../../services/ContextProvider";

function CyclicCheckApproverCreate(props) {
  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);
  const animatedComponents = makeAnimated();
  const { CyclicCheckId, Render, setGridRender } = props;

  const { DropdownModel, setDropdownModel } = useContext(
    CyclicCheckApproverContext
  );
  const { DepartmentList, YearList } = DropdownModel;
  const initialEquipmentState = {
    CyclicCheckId: 0,
    WorkDescription: "",
    DepartmentID: 0,
    DepartmentName: "",
    StationID: [],
    AuditingLevel: 0,
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
    RoleIds: "",
    ApprovedRemark: ""
  };
  const initialerrorEquipmentState = {
    DepartmentID: "",
    AuditingLevel: "",
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
    ApprovedRemark : ""
  };
  const [AuditingModel, setAuditingModel] = useState(initialEquipmentState);
  const [CyclicCheckModel, setCyclicCheckModel] = useState(
    initialEquipmentState
  );

  const [error, setformError] = useState(initialerrorEquipmentState);

  useEffect(() => {
    setCyclicCheckModel(initialEquipmentState);
    CyclicCheckGetListByCyclicCheckId();
  }, [Render]);

  const CyclicCheckGetListByCyclicCheckId = async () => {
    if (
      CyclicCheckId == 0 ||
      CyclicCheckId == null ||
      CyclicCheckId == "" ||
      CyclicCheckId == undefined
    ) {
      setAuditingModel(initialEquipmentState);
      onSelect();
    }
    showLoading();
    await CyclicCheck_GetListByCyclicCheckId(CyclicCheckId).then((res) => {
      if (res.data.data.cyclicCheckDetailsList != "") {
        setCyclicCheckModel(...res.data.data.cyclicCheckDetailsList);

        let dataLst = [];
        var dataList = [];

        res.data.data.stationList.map((data) => {
          if (
            data.Type == res.data.data.cyclicCheckDetailsList[0].DepartmentType
          ) {
            dataLst.push({
              value: data.value,
              label: data.label,
              key: data.label,
            });
          }
        });
        res.data.data.equipmentList.map((data) => {
          if (
            data.SystemTypeID ==
            res.data.data.cyclicCheckDetailsList[0].SystemTypeID
          ) {
            dataList.push({
              value: data.value,
              label: data.label,
              key: data.label,
            });
          }
        });
        const { StationID, SystemTypeID, EquipmentID, SubModuleID } = res.data.data.cyclicCheckDetailsList[0]
        const filteredStationID = res.data.data.stationList.filter(item =>
          StationID?.split(',').map(id => id.trim()).includes(item.value.toString())
        );

        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          StationID: filteredStationID,
          EquipmentList: dataList,
          StationList: dataLst,
        }));
      }
      const dataMappings = [
        { dataKey: "locationList", stateKey: "LocationList" },
        { dataKey: "rolesList", stateKey: "RolesList" },
        { dataKey: "stationList", stateKey: "StationFilterList" },
        { dataKey: "corridorList", stateKey: "CorridorList" },
        { dataKey: "cyclicCheckList", stateKey: "CyclicCheckList" },
        { dataKey: "equipmentList", stateKey: "EquipmentFilterList" },
        { dataKey: "criticalList", stateKey: "CriticalList" },
        { dataKey: "equipmentSystemList", stateKey: "EquipmentSystemList" },
        { dataKey: "weekList", stateKey: "WeekList" },
      ];

      if (res != null) {
        dataMappings.forEach((mapping) => {
          const { dataKey, stateKey } = mapping;
          const data = res.data.data[dataKey];
          if (data != null && data.length > 0) {
            setCyclicCheckModel((prevState) => ({
              ...prevState,
              [stateKey]: data,
            }));
          }
        });
        hideLoading();
      } else {
        // Handle the case when res is null
      }
    });
  };

  const onSelect = (e = 0, name = "") => {
    if (name != "") {
      let obj = findDepartmentById(DepartmentList, e);
      if (!Array.isArray(obj.children)) {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          DepartmentID: obj.id,
          DepartmentName: obj.name,
          DepartmentType: obj.Type,
        }));

        if (
          CyclicCheckModel.CorridorId != "" &&
          CyclicCheckModel.CorridorId != null
        ) {
          let dataLst = [];
          CyclicCheckModel.StationFilterList.map((data) => {
            if (
              data.Type == obj.Type &&
              data.CorridorId == CyclicCheckModel.CorridorId
            ) {
              dataLst.push({ value: data.value, label: data.label });
            }

            // setCyclicCheckModel((PreviousState) => ({
            //   ...PreviousState,
            //   StationList: dataLst,
            //   StationID: [],
            // }));
          });
        }
      }
    }
  };

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    if (
      name == "StationID" ||
      name == "LocationID" ||
      name == "EquipmentID" ||
      name == "RoleId" ||
      name == "SubModuleID" ||
      name == "CriticalTypeID" ||
      name == "CorridorId"
    ) {
      setCyclicCheckModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (
        name == "CorridorId" &&
        CyclicCheckModel.DepartmentType != "" &&
        CyclicCheckModel.DepartmentType != null
      ) {
        // alert();
        let dataLst = [];
        CyclicCheckModel.StationFilterList.map((data) => {
          if (data.Type == CyclicCheckModel.DepartmentType) {
            dataLst.push({
              value: data.value,
              label: data.label,
              key: data.label,
            });
          }

          setCyclicCheckModel((PreviousState) => ({
            ...PreviousState,
            StationList: dataLst,
            StationID: [],
          }));
        });
      }
    } else if (name == "SystemTypeID") {
      var dataList = [];
      CyclicCheckModel.EquipmentFilterList.map((data) => {
        if (data.SystemTypeID == value) {
          dataList.push({
            value: data.value,
            label: data.label,
            key: data.label,
          });
        }
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          [name]: value,
          EquipmentList: dataList,
        }));
      });
      // EquipmentFilterList
    } else {
      setCyclicCheckModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
    }
  };

  const onReject = (data) => {
    data.preventDefault();

    var myerror = error;
    var flag = true;
    if (
      CyclicCheckModel.ApprovedRemark == null ||
      CyclicCheckModel.ApprovedRemark == "" ||
      CyclicCheckModel.ApprovedRemark == 0
    ) {
      myerror.ApprovedRemark = "Remark is Required";
      flag = false;
    } else {
      myerror.DepartmentID = "";
    }

    setformError({ ...myerror });
    if (flag) {
      var USserID = GetLoginUserID();
      CyclicCheckModel.CreatedBy = USserID;
      CyclicCheckModel.Status = 120;
      CyclicCheckModel.StationID = 0;

      CycliccheckApproveProcess(CyclicCheckModel);
    }
  };

  const onSubmit = (data) => {

    data.preventDefault();
    var myerror = error;
    var flag = true;
    if (
      CyclicCheckModel.ApprovedRemark == null ||
      CyclicCheckModel.ApprovedRemark == "" ||
      CyclicCheckModel.ApprovedRemark == 0
    ) {
      myerror.ApprovedRemark = "Remark is Required";
      flag = false;
    } else {
      myerror.DepartmentID = "";
    }
    setformError({ ...myerror });
    if (flag) {
      var USserID = GetLoginUserID();
      CyclicCheckModel.CreatedBy = USserID;
      CyclicCheckModel.Status = 119;
      CyclicCheckModel.StationID = 0;

      CycliccheckApproveProcess(CyclicCheckModel);
    }
  };

  const CycliccheckApproveProcess = async (CyclicCheckModel) => {
    await Cycliccheck_ApproveProcess(CyclicCheckModel)
      .then((res) => {
        // hideLoading();
        if (res.isSuccess) {
          showSaveSuccess();
          $("#AuditingLevelConfigurationClose").trigger("click");
        } else {
          showErrorMsg();
        }
        setGridRender();
      })
      .catch((err) => {
        // hideLoading();
        console.log(err);
        showSaveError();
      });
  };
  $('#AuditingLevelConfig').click(function () {
    setformError({})
  });

  return (
    <>
      {" "}
      <form id="AddOrEditEmployee" onSubmit={onSubmit}>
        <div className="row">
          <input
            type="text"
            className="form-control hide"
            placeholder="CyclicCheckId"
            value={CyclicCheckModel.CyclicCheckId}
            onChange={(e) => CommonHandleChange(e, "CyclicCheckId")}
          />

          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="label">
                Department Name<span className="text-danger"> *</span>{" "}
              </label>
              <br />
              <ReactTreeView
                data={DepartmentList}
                onSelect={onSelect}
                openByDefault={true}
                isSearchNeed={false}
                width={300}

              />
              {CyclicCheckModel.DepartmentName != "" && (
                <span className="text-black mt-1 mr-1">
                  {" "}
                  Selected Department is{" "}
                  <span style={{ color: "blue !important" }}>
                    {CyclicCheckModel.DepartmentName}{" "}
                  </span>
                </span>
              )}

              <span className="text-danger mt-1"> {error.DepartmentName} </span>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="row">
              {" "}
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Corridor <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.CorridorList}
                    value={CyclicCheckModel.CorridorId}
                    name={"CorridorId"}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    disabled={true}
                  />

                  <span className="text-danger mt-1"> {error.CorridorId} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Station/Section <span className="text-danger"> *</span>{" "}
                  </label>

                  {/* <Dropdown
                    data={CyclicCheckModel.StationList}
                    value={CyclicCheckModel.StationID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"StationID"}
                    disabled={true}
                  /> */}
                  <ReactMultiSelect
                    options={CyclicCheckModel.StationList}
                    isMulti
                    allowSelectAll
                    value={CyclicCheckModel.StationID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      CommonHandleChange(e, "StationID");
                    }}
                    disabled={true}
                  />


                  <span className="text-danger mt-1"> {error.StationID} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Location <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.LocationList}
                    value={CyclicCheckModel.LocationID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"LocationID"}
                    disabled={true}
                  //   onChange={(e) => CommonHandleChange(e, "LocationID")}
                  />
                  <span className="text-danger mt-1"> {error.LocationID} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Equipment Type <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.EquipmentSystemList}
                    value={CyclicCheckModel.SystemTypeID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"SystemTypeID"}
                    disabled={true}
                  //  onChange={(e) => CommonHandleChange(e, "SystemTypeID")}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.SystemTypeID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Equipment <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.EquipmentList}
                    value={CyclicCheckModel.EquipmentID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"EquipmentID"}
                    disabled={true}
                  //  onChange={(e) => CommonHandleChange(e, "EquipmentID")}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.EquipmentID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Approver <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.RolesList}
                    value={CyclicCheckModel.RoleId}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"RoleId"}
                    disabled={true}
                  //    onChange={(e) => CommonHandleChange(e, "RoleId")}
                  />
                  <span className="text-danger mt-1"> {error.RoleId} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Cyclic Check <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.CyclicCheckList}
                    value={CyclicCheckModel.SubModuleID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"SubModuleID"}
                    disabled={true}
                  //   onChange={(e) => CommonHandleChange(e, "SubModuleID")}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.SubModuleID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Week <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.WeekList}
                    value={CyclicCheckModel.Weeks}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"Weeks"}
                    disabled={true}
                  //   onChange={(e) => CommonHandleChange(e, "SubModuleID")}
                  />
                  <span className="text-danger mt-1"> {error.Weeks} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Remarks <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks"
                    value={CyclicCheckModel.Remarks}
                    rows={3}
                    onChange={CommonHandleChange}
                    disabled={true}
                  //    onChange={(e) => CommonHandleChange(e, "Remarks")}
                  />
                  <span className="text-danger mt-1"> {error.Remarks} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Work Description <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="WorkDescription"
                    value={CyclicCheckModel.WorkDescription}
                    rows={3}
                    onChange={CommonHandleChange}
                    disabled={true}
                  //    onChange={(e) => CommonHandleChange(e, "WorkDescription")}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.WorkDescription}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    {CyclicCheckModel.SubModuleID == 5 ||
                      CyclicCheckModel.SubModuleID == 6
                      ? "Problem Description"
                      : "Component"}
                    <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="Component"
                    value={CyclicCheckModel.Component}
                    rows={3}
                    onChange={CommonHandleChange}
                    disabled={true}
                  //    onChange={(e) => CommonHandleChange(e, "WorkDescription")}
                  />
                  <span className="text-danger mt-1"> {error.Component} </span>
                </div>
              </div>
              <div className="col-md-12 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Remarks <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="ApprovedRemark"
                    value={CyclicCheckModel.ApprovedRemark}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.ApprovedRemark}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-8 col-sm-6"></div>
              <div className="col-md-12 ">
                <div className="btn-list pull-right">
                  <button className="btn btn-success m-md-1" onClick={onSubmit}>
                    Approve
                  </button>
                  <button className="btn btn-danger" onClick={onReject}>
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default CyclicCheckApproverCreate;
