import AxiosService from "../../services/AxiosService";

const axios = new AxiosService();
export const Report_OverAllHistoryReport = (StationID , EquipmentID ,Year) => {
  return axios.get(
    `PeriodicMaintenance/Report_OverAllHistoryReport?StationID=${StationID}&EquipmentID=${EquipmentID}&Year=${Year}`
  );
};

export const Report_OverAllHistoryDropDown = () => {
  return axios.get(`PeriodicMaintenance/Report_OverAllHistoryDropDown`);
};
