import React, { useState, useContext, useReducer, useEffect } from "react";

import { PeriodicService } from "./PeriodicService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import $ from "jquery";

const _periodicService = new PeriodicService();

export default function ({ equipmentID, maintenanceTypeID }) {
  const { getCookies, setCookies, handleCal, showLoading, hideLoading } =
    useContext(AppContext);
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [data, setRows] = useState([]);
  //   const [Loading, setLoading] = useState(true);

  //   const SetLoading = (e) => {
  //     setLoading(e);
  //   };

  const getDescriptions = () => {
    if (equipmentID != null && maintenanceTypeID != null) {
    }
    _periodicService
      .Periodic_GetDescriptionByID(
        equipmentID,
        maintenanceTypeID,
        parseInt(getCookies(COOKIES.PeriodicStationID))
      )
      .then((res) => {
      
        if (res != null && res.data != null && res.data.length > 0) {
          var dataLst = [];
          res.data.map((v) => {
            dataLst.push(v.Description);
          });
          setRows(dataLst);

        } else {
          setRows([]);
        }
      });
  };
  useEffect(() => {
    getDescriptions();
  }, [equipmentID, maintenanceTypeID]);

  return (
  <div>
    
    <div className="card" >
        <ol>
        {data.map((v) => (       
            <div className="card-body">
                <li className="">{v}</li>
            </div>             
        ))}

        </ol>

    </div>
  </div>
  );
}
