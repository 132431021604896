import React from "react";
import { TabBar, PageHeaderTitle } from "../../components";
import { useState, useEffect } from "react";
import {
  EquipmentRunningStatus,
  EnergyConsumptionLog,
  StationTemperatureMonitorLog,
  EquipmentTemperatureMonitorLog,
} from "./index";
import DailyElectricalPanel from "./DailyElectricalPanel";
import RoutineShiftCheck from "./RoutineShiftCheck";
import ContextProvider from "./ContextProvider";
import ChillerMonitoring from "./ChillerMonitoring";
import { DefaultAppContextValues } from "../../services/ContextProvider";

export default function ManualLog() {
  const TabHeader = [
    // "Equipment Running Status",
    // "Station Temperature Monitoring",
    "Chiller Monitoring",
    "Daily Electrical Panel",
    "Critical Equipment Room Temperature Monitoring",
    "Daily Closing Reading CheckList",
    "Routine Shift Checks",
  ];

  const [TabInitialVal, setTabInitialVal] = useState(0);

  const { GetLoginStationList } = DefaultAppContextValues();
  const IsPh1 = GetLoginStationList().map((ele) => ele.label).every((ele) => ["stc", "str", "swa"].includes(ele.toLocaleLowerCase()));

  const TabBody = [
    // <EquipmentRunningStatus TabID={0} TabValue={TabInitialVal} />,
    // <StationTemperatureMonitorLog TabID={2} TabValue={TabInitialVal} />,
    // Daily Electrical Panel
    <ChillerMonitoring TabID={0} TabValue={TabInitialVal} />,
    <DailyElectricalPanel TabID={1} TabValue={TabInitialVal} />,
    <EquipmentTemperatureMonitorLog TabID={2} TabValue={TabInitialVal} />,
    <EnergyConsumptionLog TabID={3} TabValue={TabInitialVal} />,
    <RoutineShiftCheck TabID={4} TabValue={TabInitialVal} />,
  ];

  /**
   * Handle tab change event.
   *
   * @param {number} e - The selected tab index.
   */
  const onTabChange = (e) => {
    setTabInitialVal(e - 1);
  };

  return (
    <div className="card">
      <>
        {/* <PageHeaderTitle title={"Manual Log"} /> */}
        <ContextProvider>
          <TabBar
            header={IsPh1 ? TabHeader.slice(2, 5) : TabHeader}
            body={IsPh1 ? TabBody.slice(2, 5) : TabBody}
            InitialValue={TabInitialVal}
            onTabChange={onTabChange}
          />
        </ContextProvider>
      </>
    </div>
  );
}
