import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";
import { AddButton } from "../../components/Common";
import { PageHeader } from "./../../components/PageHeader";
import { HtmlTable } from "./../../components/HtmlTable";
import $ from "jquery";
import { UserService } from "./UserService";
import {
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
} from "./../../components/ToastMsg";
import { AddUser } from "./AddUser";
import {
  Decrypt,
  getPrivilege,
  GetStatus,
  GetToggleButton,
} from "../../services/CommonService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import MasterDetailService from "../master/MasterDetailService";
import Global from "../../services/Global";
const animatedComponents = makeAnimated();

const _userService = new UserService();

export const User = () => {
  //User table columns
  const columnNames = [
    { title: "User ID", className: "hide" },
    {
      title: "Station Code",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(`<p class='Eclispedot' title=${cellData}>${cellData}</p>`);
      },
    },
    { title: "User Name", visible: false },
    { title: "Employee ID", visible: false },
    { title: "Employee Code" },
    { title: "Employee Name" },
    { title: "User Name" },
    { title: "Role" },
    {
      title: "Status",
      visible: getPrivilege().includes("USERDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var c = $(GetToggleButton(row, col, cellData, rowData[0])).on(
          "click",
          function () {
            var status;

            var toggle = $(this).find("input[type=checkbox]");
            if (toggle != null && toggle.length == 1) {
              status = toggle.is(":checked");
            }
            if (Boolean(cellData) != status) {
              ChangeStatus(rowData[0]);
            }
          }
        );
        $(td).html(c);
      },
    },
    {
      title: "Submit",
      visible: getPrivilege().includes("USEREDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(`<button class='btn icon-btn' title='Edit'>
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
          onEditClick(rowData[0]);
        });
        $(td).html(b);
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [DBRoles, setDBRoles] = useState([]);
  const [empCodes, setEmpCodes] = useState([]);
  const [user, setUser] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const modalCloseRef = useRef();
  const modalOpenRef = useRef();
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [privillege, setPrivillege] = useState("");
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [departmentID, SetdepartmentID] = useState([]);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    var modulePrivillge = getCookies(COOKIES.modulePrivileage);
    setPrivillege(modulePrivillge);
  }, []);

  useEffect(() => {
    getUsers(0);
    getRoles();
    getEmployeeCodes(1);
  }, []);

  useEffect(() => {
    if (user != null) {
      modalOpenRef.current.click();
    }
  }, [user]);

  useEffect(() => {
    if (isAdd) {
      modalOpenRef.current.click();
    }
  }, [isAdd]);

  useEffect(() => {
    getStationList();
    getDepartmentList();
  }, []);

  const getStationList = () => {
    var stations = Decrypt(getCookies(Global.COOKIES.Stations));
    var stationsdropdown = [];

    stations.map((data) => {
      stationsdropdown.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(stationsdropdown);
    HandleChange(stationsdropdown);
    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange(dataLst);
    //   }
    // });
  };
  const getDepartmentList = () => {
    var DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments))
    var depdropdown = [];
    const itemsWithoutChildren = DepartmentList.filter(
      (item) => !DepartmentList.some((child) => child.parentId === item.id)
    );

    itemsWithoutChildren.map((data) => {
      depdropdown.push({ value: data.id, label: data.name });
    });

    // DepartmentList.map((data) => {

    //   depdropdown.push({ value: data.id, label: data.name });

    // });
    setDepartmentList(depdropdown);
    DepartmentHandleChange(depdropdown);
  }

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var depIds = departmentID.map((x => {
      return x.value
    }))
    getUsers(0, stationIds.toString(), depIds.toString());
  }, [Refresh, StationID, departmentID]);

  const getUsers = (userID, stationIDs = 0, depIDs) => {
    if (stationIDs != null && stationIDs != "") {
      const IsTvsvac = getCookies(Global.COOKIES.IsTvsvac)
      const tvsVac = IsTvsvac === "true" ? 1 : 0;

      showLoading();
      _userService
        .GetUsers(userID, stationIDs, tvsVac, depIDs)
        .then((response) => {
          if (response == null) {
            // showErrorMsg("Failed to load records");
          } else if (response.length > 0) {
            var rows = [];
            response.map((v) => {
              var row = [];
              row.push(v.userID);
              row.push(v.stationCode);
              row.push(v.username);
              row.push(v.employeeID);
              row.push(v.employeeCode);
              row.push(`${v.firstName} ${v.lastName}`);
              row.push(v.username);
              row.push(v.roleName);
              row.push(v.isActive);
              row.push("");
              rows.push(row);
            });
            setRows(rows);
          } else {
            setRows([]);
          }
          hideLoading();
        })
        .catch((err) => {
          hideLoading();
        });
    } else {
      setRows([]);
      hideLoading();
    }
  };

  const getRoles = () => {
    const IsTvsvac = getCookies(Global.COOKIES.IsTvsvac)
    const tvsVac = IsTvsvac === "true" ? 1 : 0;
    _userService.GetUserRoleList(tvsVac).then((res) => {
      if (res != null && res.length > 0) {
        
        var roleList = [];
        var Dblist = [];
        tvsVac == 0 ?
          res.map((v, i) => {
            roleList.push({
              key: v.roleName + " - " + v.prefixName,
              value: v.roleID,
              index: i + 1,
            });
          }) : res.map((v, i) => {
            roleList.push({
              key: v.roleName,
              value: v.roleID,
              index: i + 1,
            });
          })
        res.map((v, i) => {
          Dblist.push({
            dbName: v.dbName,
            value: v.roleID,
            index: i + 1,
          });
        })
        setRoles(roleList);
        setDBRoles(Dblist);
      }
    });
  };
  const ChangeStatus = (id) => {
    _userService.DeleteUser(id).then((response) => {
      if (response.data == 1) {
        // showSaveSuccess();
      } else {
        showSaveError();
      }
    });
  };

  const getEmployeeCodes = (type) => {
    _userService.GetEmployeeCodes(type).then((res) => {
      if (res != null && res.data.length > 0) {
        var codeList = [];
        console.log(res.data);
        res.data.map((v) => {
          codeList.push({
            key: v.EmployeeCode,
            value: v.EmployeeID,
            empName: `${v.FirstName} ${v.LastName}`,
          });
        });
        setEmpCodes(codeList);
      }
    });
  };

  const saveUser = (data) => {
    var isExists = [];
    var userNameExists = [];
    if (data.UserID == 0) {
      isExists = rows.find((v) => Number(v[2]) == data.EmployeeID);

      userNameExists = rows.find(
        (v) => v[1].toLowerCase().trim() == data.Username.toLowerCase().trim()
      );
    }

    if (isExists != null && isExists.length > 0) {
      showErrorMsg("User already exists");
    } else if (userNameExists != null && userNameExists.length > 0) {
      showErrorMsg("Username already exists");
    } else {
      showLoading();
      _userService.AddorUpdateUser(data).then((response) => {
        setRefresh();
        getEmployeeCodes(1);
        hideLoading();

        if (
          response != null &&
          response.isSuccess &&
          parseInt(response.data) == 1
        ) {
          showSaveSuccess();
          getUsers(0);
          setUser(null);
          modalCloseRef.current.click();
        } else if (response != null && !response.isSuccess) {
          // getUsers(0);
          showErrorMsg(response.message);
          modalCloseRef.current.click();
        } else {
          showSaveError();
          modalCloseRef.current.click();
          return 0;
        }
      });
    }
  };

  const onEditClick = (uID) => {
    _userService.GetUsers(uID).then((res) => {
      if (res != null) {
        setIsAdd(false);
        setUser(res[0]);
      }
    });
  };

  const onOpenModel = () => {
    if (user != null) {
      setUser(null);
      setIsAdd(true);
    } else {
      modalOpenRef.current.click();
    }
  };

  const clearUser = () => {
    setUser(null);
  };

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
  };
  const DepartmentHandleChange = (e) => {
    SetdepartmentID(e);
  }

  return (
    <>
      {/*Header */}
      <div className="page-header mb-1">
        <div className="row">
          <div className="justify-content-start col-lg-4 col-md-4 col-sm-4">
            <h3 className="page-title">User List</h3>
          </div>
          <div className="col-lg-8 col-sm-8 col-md-8 ">
            {/*Add button */}
            <div className="row justify-content-end">
              <div className={windowWidth > 1000 ? 'col-5' : 'col-5'} >
                <ReactMultiSelect
                  options={DepartmentList}
                  isMulti
                  value={departmentID}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={(e) => DepartmentHandleChange(e)}
                  allowSelectAll={true}
                />
              </div>
              <div className={windowWidth > 1000 ? "col-4" : "col-4"}>
                <ReactMultiSelect
                  options={StationList}
                  isMulti
                  value={StationID}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={(e) => HandleChange(e)}
                  allowSelectAll={true}
                />
              </div>

              {privillege.includes("USERCREATE") && (
                <div className={windowWidth > 1000 ? "col-2" : "col-2"}>
                  <button
                    type="submit"
                    className="btn add-btn"
                    onClick={onOpenModel}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add user form */}
      <AddUser
        roles={roles}
        empCodes={empCodes}
        onSubmit={saveUser}
        data={user}
        clearUser={clearUser}
        Refresh={Refresh}
        DBRoles={DBRoles}
      />

      {/* Open add modal */}
      <input
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#add-user"
        hidden
        ref={modalOpenRef}
      />
      {/* Close add modal */}
      <input
        type="button"
        data-bs-dismiss="modal"
        data-bs-target="#add-user"
        data-bs-backdrop="static"
        hidden
        ref={modalCloseRef}
      />

      {/*User table */}
      <div className="card card-body mt-3">
        <div className="row">
          <div className="col">
            <HtmlTable
              columnList={columnNames}
              rowList={rows}
              id={"user-table"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
