import Axios from "../../services/AxiosService";
const axios = new Axios();

/**
 * Retrieve dropdown details for equipment running configuration.
 *
 * @returns {Promise} A promise that resolves with the dropdown details.
 */
const EquipmentRunning_DropDownDetails = async () => {
  return await axios.get("ManualLog/EquipmentRunning_DropDownDetails");
};


/**
 * Save equipment running configuration.
 *
 * @param {Object} obj - The equipment running configuration object to be saved.
 * @returns {Promise} A promise that resolves with the result of the save operation.
 */
const EquipmentRunning_SaveConfig = async (obj) => {
  return await axios.post("ManualLog/EquipmentRunning_SaveConfig", obj);
};


/**
 * Get equipment details for a specific running status date.
 *
 * @param {string} runningStatusDate - The date for which equipment details are requested.
 * @returns {Promise} A promise that resolves with the equipment details.
 */
const EquipmentRunning_GetEquipmentDetails = async (runningStatusDate = "") => {
  return await axios.get(
    `ManualLog/EquipmentRunning_GetEquipmentDetails?runningStatusDate=${runningStatusDate}`
  );
};


const EquipmentRunning_DetailsSaveConfig = async (obj) => {
  return await axios.post(`ManualLog/EquipmentRunning_DetailsSaveConfig`, obj);
};

/**
 * Mirror equipment running data from the previous day.
 *
 * @param {string} SavedBy - The user responsible for the data mirroring.
 * @returns {Promise} A promise that resolves with the result of the data mirroring operation.
 */
const EquipmentRunning_MirrorYesterDayData = async (SavedBy) => {
    return await axios.get(`ManualLog/EquipmentRunning_MirrorYesterDayData?savedBy=${SavedBy}`, );
  };


/**
 * Save equipment running configuration.
 *
 * @param {Object} obj - The equipment running configuration object to be saved.
 * @returns {Promise} A promise that resolves with the result of the save operation.
 */
const EquipmentEnergyConsumption_SaveConfig = async (obj) => {
  return await axios.post("ManualLog/EquipmentEnergyConsumption_SaveConfig", obj);
};


/**
 * Get equipment details for a specific today date.
 *
 * @returns {Promise} A promise that resolves with the equipment details.
 */
const EquipmentEnergyConsumption_GetEquipmentDetails = async (CurrentDate,StationID) => {
  return await axios.get(
    `ManualLog/EquipmentEnergyConsumption_GetEquipmentDetails?CurrentDate=${CurrentDate}&StationID=${StationID}`
  );
};


const EquipmentEnergyConsumption_DetailsSaveConfig = async (obj) => {
  return await axios.post(`ManualLog/EquipmentEnergyConsumption_DetailsSaveConfig`, obj);
};

const TemperatureMonitor_SaveConfig = async (obj) => {
  return await axios.post("ManualLog/TemperatureMonitor_SaveConfig", obj);
};

const TemperatureMonitor_DropDownDetails = async () => {
  return await axios.get("ManualLog/TemperatureMonitor_DropDownDetails");
};
const TemperatureMonitor_GetRoomDetails = async () => {
  return await axios.get("ManualLog/TemperatureMonitor_GetRoomDetails");
};

const TemperatureMonitor_DetailsSaveConfig = async (obj) => {
  return await axios.post(`ManualLog/TemperatureMonitor_DetailsSaveConfig`, obj);
};
const EquipmentTemperatureMonitor_SaveConfig = async (obj) => {
  return await axios.post("ManualLog/EquipmentTemperatureMonitor_SaveConfig", obj);
};
const EquipmentTemperatureMonitor_GetRoomDetails = async (StationID,CurrentDate) => {
  return await axios.get(`ManualLog/EquipmentTemperatureMonitor_GetRoomDetails?StationID=${StationID}&CurrentDate=${CurrentDate}`);
};
const EquipmentTemperatureMonitor_DetailsSaveConfig = async (obj) => {
  return await axios.post(`ManualLog/EquipmentTemperatureMonitor_DetailsSaveConfig`, obj);
};

const RoutineShiftCheck_DropDownDetails = async (StationID,CurrentDate) => {
  return await axios.get(`ManualLog/RoutineShiftCheck_DropDownDetails`);
};
const RoutineShiftCheck_SaveConfig = async (obj) => {
  return await axios.post(`ManualLog/RoutineShiftCheck_SaveConfig`, obj);
};
const RoutineShiftCheck_GetEquipmentDetails = async (CurrentDate,StationID) => {
  return await axios.get(
    `ManualLog/RoutineShiftCheck_GetEquipmentDetails?CurrentDate=${CurrentDate}&StationID=${StationID}`
  );
};
const RoutineShiftCheck_DetailsSaveConfig = async (obj) => {
  return await axios.post(`ManualLog/RoutineShiftCheck_DetailsSaveConfig`, obj);
};
const RoutineShiftCheck_ReportGetByID = async (StationID,FromDate,ToDate) => {
  return await axios.get(`ManualLog/RoutineShiftCheck_ReportGetByID?StationID=${StationID}&&FromDate=${FromDate}&ToDate=${ToDate}`);
};
const EquipmentEnergyConsumption_GetDocumentID = async () => {
  return await axios.get(`ManualLog/EquipmentEnergyConsumption_GetDocumentID`);
};


export {
  EquipmentRunning_DropDownDetails,
  EquipmentRunning_SaveConfig,
  EquipmentRunning_GetEquipmentDetails,
  EquipmentRunning_DetailsSaveConfig,
  EquipmentRunning_MirrorYesterDayData,
  EquipmentEnergyConsumption_SaveConfig,
  EquipmentEnergyConsumption_GetEquipmentDetails,
  EquipmentEnergyConsumption_DetailsSaveConfig,
  TemperatureMonitor_SaveConfig,
  TemperatureMonitor_DropDownDetails,
  TemperatureMonitor_GetRoomDetails,
  TemperatureMonitor_DetailsSaveConfig,
  EquipmentTemperatureMonitor_SaveConfig,
  EquipmentTemperatureMonitor_GetRoomDetails,
  EquipmentTemperatureMonitor_DetailsSaveConfig,
  RoutineShiftCheck_DropDownDetails,
  RoutineShiftCheck_SaveConfig,
  RoutineShiftCheck_GetEquipmentDetails,
  RoutineShiftCheck_DetailsSaveConfig,
  RoutineShiftCheck_ReportGetByID,
  EquipmentEnergyConsumption_GetDocumentID
};
