import React, { useContext } from "react";
import {
  ReactMultiSelect,
  Option,
  MultiValue,
  ReactTreeView,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
} from "../../../components";
import makeAnimated from "react-select/animated";
import { PeriodicStartMonthContext } from "./ContextProvider";
import {
  GetLoginUserID,
  ShortMonthsForPeriodic,
  findDepartmentById,
} from "../../../services/CommonService";
import { FaRegSave } from "react-icons/fa";
import { PeriodicStartMonth_SavePeriodicStartMonth } from "../ApplicationService";
import $ from "jquery";

export const PeriodicStartMonthSave = () => {
  const animatedComponents = makeAnimated();
  const {
    DropdownModel,
    setDropdownModel,
    PeriodicStartModel,
    InitialState,

    setPeriodicStartModel,
    ScheduleTypeOptions,
  } = useContext(PeriodicStartMonthContext);
  const {
    DepartmentList,
    YearList,
    Q1MonthList,
    Q1List,
    Q2List,
    Q3List,
    Q4List,
    H1_List,
    H2_List,
    ErrorObj,
    StationList,
    MaintenanceTypeList,
    FilterStation,
    PeriodicStartMonthList,
    
  } = DropdownModel;
  const {
    YearID,
    DepartmentId,
    Q1_StartMonth,
    Q1_EndMonth,
    Q2_EndMonth,
    Q3_EndMonth,
    Q4_EndMonth,
    H1_EndMonth,
    H2_EndMonth,
    Yearly_EndMonth,
    StationID,
    PeriodicScheduleConfigID,
  } = PeriodicStartModel;

  const onSelect = (id = 0, node) => {
    if (DepartmentList?.length) {
      let obj = findDepartmentById(DepartmentList, id);
      if (!Array.isArray(obj.children)) {
        let DepartList = JSON.parse(JSON.stringify(DepartmentList));
        DepartList[0].name = obj.name;
        DepartList[0].id = "Selected_" + obj.id;
        setDropdownModel((pre) => ({
          ...pre,
          DepartmentList: DepartList,
        }));
        node?.tree?.closeAll();
         let FilStation =  FilterStation?.filter((ele) => ele.Type == obj.Type);

         setDropdownModel((pre) => ({
          ...pre,
          StationList : FilStation
         }))

         setPeriodicStartModel((pre) => ({
          ...pre,
          StationID : []
         }))


        HandleChange("DepartmentId", obj.id);
      }
    }
  };

  const HandleChange = (name, value) => {
    
    if (name && value) {
      setPeriodicStartModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }



    if (name == "Q1_StartMonth") {
      let monthId = value.value;
      ScheduleTypeOptions(monthId);
    }
  };

  const SavePeriodicStartMonth = () => {
    
    const model = {
      PeriodicScheduleConfigID,
      YearID: YearID.value,
      DepartmentId,
      Q1_StartMonth: Q1_StartMonth.value,
      Q1_EndMonth: Q1_EndMonth,
      Q2_EndMonth: Q2_EndMonth,
      Q3_EndMonth: Q3_EndMonth,
      Q4_EndMonth: Q4_EndMonth,
      H1_EndMonth: H1_EndMonth,
      H2_EndMonth: H2_EndMonth,
      Yearly_EndMonth: Yearly_EndMonth,
      StationID,
    };
    
    let ErrorObj = validateModel(model);

    if (!ErrorObj) {
      setDropdownModel((pre) => ({
        ...pre,
        ErrorObj: {},
      }));
      if (PeriodicStartMonthList?.length > 0) {
        
        let selectedStation = StationID?.map((ele) => parseInt(ele.value));
        let CheckAlreadyExists = PeriodicStartMonthList.find((ele) => {
          const ConfigList = ele.StationIDs?.split(",")?.map((ele) =>
            parseInt(ele)
          );
          let IsAlreadyConfigured = false;
          if (ConfigList?.length) {
            IsAlreadyConfigured = ConfigList.some((ele) =>
              selectedStation.includes(ele)
            );
          }
          return (
            ele.PeriodicScheduleConfigID != PeriodicScheduleConfigID &&
            ele.DepartmentId == DepartmentId &&
            ele.YearID == YearID.value &&
            IsAlreadyConfigured
          );
        });
        if (CheckAlreadyExists) {
          showWarningMsg("This department already configured with same year or same station.");
          return false;
        }
      }
      let DateConfig = [];
      
      Object.keys(model).forEach((ele) => {
        let ScheduleType =
          MaintenanceTypeList.find((obj) => obj.StatusName == ele)?.StatusID ??
          0;
        if (
          ele != "DepartmentId" &&
          ele != "YearID" &&
          ele != "Q1_StartMonth" &&
          ele != "PeriodicScheduleConfigID" &&
          ele != "StationID"
        ) {
          model[ele].forEach((element) => {
            DateConfig.push({
              MonthValue: element.value,
              ScheduleType,
            });
          });
        } else if (ele == "Q1_StartMonth") {
          DateConfig.push({
            MonthValue: model[ele],
            ScheduleType,
          });
        }
      });


      let ManipulatedModel = {
        PeriodicScheduleConfigID,
        DepartmentId,
        StationID: StationID?.map((ele) => ele.value).join(","),
        YearID: model.YearID,
        MonthScheduleList: JSON.stringify(DateConfig),
        SavedBy: GetLoginUserID(),
      };

        PeriodicStartMonth_SavePeriodicStartMonth(ManipulatedModel)
          .then((res) => {
            $("#PeriodicStartMonthCOnfigClose").click();
            if (res.data.isSuccess) {
              showSaveSuccess();
            } else {
              console.log(res.data.data);
              showSaveError();
            }
          })
          .catch((err) => {
            $("#PeriodicStartMonthCOnfigClose").click();
            console.log(err);
            showSaveError();
          });
      
    } else {
      setDropdownModel((pre) => ({
        ...pre,
        ErrorObj,
      }));
    }
  };

  function validateModel(model) {
    const errors = {};

    const fieldNames = {
      Q1_EndMonth: "Q1 End Month",
      Q2_EndMonth: "Q2 End Month",
      Q3_EndMonth: "Q3 End Month",
      Q4_EndMonth: "Q4 End Month",
      H1_EndMonth: "H1 End Month",
      H2_EndMonth: "H2 End Month",
      Yearly_EndMonth: "Yearly End Month",
      StationID: "Stations",
    };

    if (!model.YearID || typeof model.YearID !== "number") {
      errors.YearID = "Year is required.";
    }

    if (!model.DepartmentId || typeof model.DepartmentId !== "number") {
      errors.DepartmentId = "Department is required.";
    }
    if (!model.Q1_StartMonth || typeof model.Q1_StartMonth !== "number") {
      errors.Q1_StartMonth = "Q1 Start Month is required.";
    }

    Object.entries(fieldNames).forEach(([field, displayName]) => {
      if (
        !model[field] ||
        !Array.isArray(model[field]) ||
        model[field].length === 0
      ) {
        errors[field] = `${displayName} is required.`;
      }
    });
    // Check if any errors were found
    const hasErrors = Object.keys(errors).length > 0;
    return hasErrors ? errors : null;
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label className="label">
                Department Name<span className="text-danger"> *</span>{" "}
              </label>
              <ReactTreeView
                data={DepartmentList}
                onSelect={onSelect}
                openByDefault={false}
                isSearchNeed={false}
                width={300}
              />
              <span className="text-danger mt-1">
                {" "}
                {ErrorObj.DepartmentId}{" "}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Stations <span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                options={StationList}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti
                allowSelectAll
                components={{ Option, MultiValue, animatedComponents }}
                placeholder={"Select Station"}
                onChange={(value) => HandleChange("StationID", value)}
                value={StationID}
              />
              <span className="text-danger mt-1"> {ErrorObj.StationID} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Year <span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                options={YearList}
                placeholder={"Select Year"}
                onChange={(value) => HandleChange("YearID", value)}
                value={YearID}
              />
              <span className="text-danger mt-1"> {ErrorObj.YearID} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Q1-Start <span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                options={Q1MonthList}
                placeholder={"Schedule Type"}
                onChange={(value) => HandleChange("Q1_StartMonth", value)}
                value={Q1_StartMonth}
              />
              <span className="text-danger mt-1">
                {" "}
                {ErrorObj.Q1_StartMonth}{" "}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Q1 Job Month<span className="text-danger"> *</span>{" "}
              </label>

              <ReactMultiSelect
                options={Q1List}
                isMulti
                value={Q1_EndMonth}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                placeholder={"Q1 End Month"}
                onChange={(value) => HandleChange("Q1_EndMonth", value)}
                key={1}
              />

              <span className="text-danger mt-1"> {ErrorObj.Q1_EndMonth} </span>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Q2 Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={Q2List}
                placeholder={"Q2 Start Month"}
                onChange={(value) => HandleChange("Q2_EndMonth", value)}
                value={Q2_EndMonth}
                // allOption={}
                isMulti
              />
              <span className="text-danger mt-1"> {ErrorObj.Q2_EndMonth} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Q3 Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={Q3List}
                placeholder={"Q3 Start Month"}
                onChange={(value) => HandleChange("Q3_EndMonth", value)}
                value={Q3_EndMonth}
                isMulti
              />
              <span className="text-danger mt-1"> {ErrorObj.Q3_EndMonth} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Q4 Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={Q4List}
                placeholder={"Q4 Start Month"}
                onChange={(value) => HandleChange("Q4_EndMonth", value)}
                value={Q4_EndMonth}
                isMulti
              />
              <span className="text-danger mt-1"> {ErrorObj.Q4_EndMonth} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                H1 Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={H1_List}
                placeholder={"H1 Start Month"}
                onChange={(value) => HandleChange("H1_EndMonth", value)}
                value={H1_EndMonth}
                isMulti
              />
              <span className="text-danger mt-1"> {ErrorObj.H1_EndMonth} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                H2 Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={H2_List}
                placeholder={"H2 Start Month"}
                onChange={(value) => HandleChange("H2_EndMonth", value)}
                value={H2_EndMonth}
                isMulti
              />
              <span className="text-danger mt-1"> {ErrorObj.H2_EndMonth} </span>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label className="label">
                Yearly Job Month<span className="text-danger"> *</span>{" "}
              </label>
              <ReactMultiSelect
                components={{ Option, MultiValue, animatedComponents }}
                options={Q1MonthList}
                placeholder={"Yearly Start Month"}
                onChange={(value) => HandleChange("Yearly_EndMonth", value)}
                value={Yearly_EndMonth}
                isMulti
              />
              <span className="text-danger mt-1">
                {" "}
                {ErrorObj.Yearly_EndMonth}{" "}
              </span>
            </div>
          </div>
        </div>
        <button className="btn btn-primary float-end" onClick={SavePeriodicStartMonth}>
          <FaRegSave /> Save
        </button>
      </div>
    </>
  );
};
