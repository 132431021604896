import React, { useState, useEffect, useContext } from "react";
import {Decrypt, MyCOLORS } from "../../services/CommonService";
import { ChartWithCardForLive } from "./widgets/ChartWithCardForLive";
import { ReactMultiSelect, MultiValue, Option } from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import MasterDetailService from "../master/MasterDetailService";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import DashboardService from "./DashboardService";
import { showWarningMsg } from "../../components/ToastMsg";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery"
import Global from "../../services/Global";

import { IconButton } from "./Dashboard";

const DS = new DashboardService();


const animatedComponents = makeAnimated();






const EqptGraph = ({ DashBoardData, ChangeFormet, FilteredParameter }) => {




  return (
    <>


      <div className="row">
        <div className="col-md-12">
          <div className="row">


            {DashBoardData != null && DashBoardData.length > 0 ?

              DashBoardData.map((x, key) => {

                var units = x.parameterName.split(":");
                var unit = units.length > 0 ? units[1] : ""; 
                return (
                  <>

                    <ChartWithCardForLive
                    key={x.parameterName}
                      title={x.parameterName}
                      data={x}
                      unit={unit}
                      yTitle={"Sensor Value"}
                      // charttitle={"Live Data"}
                      xTitle={"Time"}
                      parameterID={x.parameterID}
                      Alow={x.acceptableParameterLow}
                      AHigh={x.acceptableParameterHigh}
                      Thigh={x.thresholdValueHigh}
                      Tlow={x.thresholdValueLow}
                      ChangeFormet={ChangeFormet}
                      id={`LiveChart${key}`}
                      FilteredParameter={FilteredParameter}
                    />



                  </>
                )
              })

              :
              <div className="text-center " style={{margin:"10rem 0px"}}>

                Sorry No data Available
              </div>
            }

          </div>
        </div>
      </div>


    </>
  );
};

const   EqptFilters = ({ SetDashBoardData, Flag, setFilterValue, SetLoading, TabID, TabValue }) => {


  const [StationID, SetStationID] = useState(0);
  const [StationList, setStationList] = useState([]);

  const [EqiupmentID, SetEqiupmentID] = useState(0);
  const [EqiupmentList, seEqiupmentList] = useState([]);
  const [FilterEqiupmentList, setFilterEqiupmentList] = useState([]);

  const [EquipmentTagNo, setEquipmentTagNo] = useState([]);
  const [EquipmentTagNoList, setEquipmentTagNoList] = useState([]);
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const { showLoading, hideLoading, getCookies,handleGlobalStationName,GlobalCorridor  } = useContext(AppContext);
  const [DashboardColors, setDashboardColors] = useState([]);






  useEffect(() => {
    // getStationList();
    
    if(TabID == TabValue){
      getEquipmentList();
    }
    

  }, [TabValue,GlobalCorridor])


  useEffect(() => {

   if(TabID == TabValue){
    getEquipmentTagNumbers();
    SetDashBoardData([]);
    setDashboardColors([]);
   }
   

  }, [EqiupmentID, StationID, TabValue])







  const getEquipmentList = () => {

    SetLoading(true);
    DS.Dashboard_GetEquipmentList().then((response) => {

      if (response != null && response.data.length > 0) {

        var dataLst = [];
        const EquipmentList = response.data[1];

        EquipmentList.map((v) => {
          dataLst.push({ value: v.equipmentID, key: v.equipmentShortName, ...v });
        });



        if (dataLst != null && dataLst.length > 0) {
          // seEqiupmentList(dataLst)
          setFilterEqiupmentList(dataLst);

          // SetEqiupmentID(dataLst[0].value);
        }
        /* Get station from Cookies */
        
        var stationList = Decrypt(getCookies(Global.COOKIES.Stations))
        var dataLst1 = [];
            
        stationList.map((data) => {
          
          dataLst1.push({value: data.StationID, key: data.StationCode});
    
        });

        // setStationList(dataLst1);
        // const stationList = response.data[0];

        // var dataLst1 = [];
        // stationList.map((v) => {
        //   dataLst1.push({ value: v.StationID, key: v.StationCode, ...v });
        // });
        if (dataLst1 != null && dataLst1.length > 0) {

          SetStationID(dataLst1[0].value)
          
          handleGlobalStationName(dataLst1[0].key)
          setStationList(dataLst1);
          var initialStationId = dataLst1[0].value;

          var FileterArray = dataLst.filter((x) => {
            return x.StationID == initialStationId
          })


          seEqiupmentList([...FileterArray]);
          FileterArray.length > 0 ? SetEqiupmentID(FileterArray[0].value) : SetEqiupmentID(0);

        }


      }
      else {
        seEqiupmentList([])
        SetEqiupmentID(0);
        setFilterEqiupmentList([]);
      }

    });
  };



  const getEquipmentTagNumbers = () => {


    if (EqiupmentID > 0) {

      SetLoading(true);
      DS.Dashboard_GetEquipmentTagNo(EqiupmentID, StationID).then((response) => {

        if (response.data != null && response.data.length > 0) {

          var dataLst = [];
          response.data.map((v) => {
            dataLst.push({ value: v.EquipmentDetailID, label: v.EquipmentCode, Unit: v.Unit });
          });
          if (dataLst != null && dataLst.length > 0) {
            setEquipmentTagNoList(dataLst);
            var Eqipmentlist = [];
            Eqipmentlist.push(dataLst[0]);
            GetDashboardColor(dataLst);
            setEquipmentTagNo([...Eqipmentlist])
            var TagNos = Eqipmentlist.map((x => {
              return x.value
            }))
            GetparametersUsingEquipmentTagNo(TagNos.toString());
            
          }
          // setEquipmentTagNo(dataLst[0]);
          // SetLoading(false);
        }
        else {
          setEquipmentTagNoList([])
          setEquipmentTagNo(0);
          SetLoading(false);
        }

      }).catch((err) => {
        SetLoading(false);
        hideLoading();
        console.log(err);
      });
    }
  };

  const FilterEquipmentUsingStationID = (stationID) => {


    var FileterArray = FilterEqiupmentList.filter((x) => {
      return x.StationID == stationID;
    });
    return FileterArray
  };



  const StationHandleChange = (e) => {

    SetStationID(e.target.value);
    var selectedOption = e.target.options[e.target.selectedIndex];
    var selectedLabel = selectedOption.text;
    handleGlobalStationName(selectedLabel ?? "");

    setEquipmentTagNo(null);
    const { value } = e.target;

    var FileterArray = FilterEquipmentUsingStationID(value);
    seEqiupmentList([...FileterArray]);
    FileterArray.length > 0 ? SetEqiupmentID(FileterArray[0].value) : SetEqiupmentID(0);
    $("#EqiupmentIDDropDown").val(FileterArray[0].value);
  }

  const EquipmentHandleChange = (e) => {



    SetEqiupmentID(e.target.value);
    setEquipmentTagNo(null);
  }


  const HandleChange = (e) => {

    if (e.length > 0) {

      if (EquipmentTagNo != null && EquipmentTagNo.length == 0) {
        setEquipmentTagNo(e);
        var TagNos = e.map((x => {
          return x.value
        }))
        GetparametersUsingEquipmentTagNo(TagNos.toString());

      }
      else if (EquipmentTagNo != null && EquipmentTagNo.length > 0) {

        var temp = e.filter((x) => {

          return x.Unit != EquipmentTagNo[0].Unit
        })
   

        var Check = e.findIndex((x) => {
          return x.Unit == null
        })

        if ((temp.length == 0 && Check == -1)) {
          setEquipmentTagNo(e);

          var TagNos = e.map((x => {
            return x.value
          }))


          GetparametersUsingEquipmentTagNo(TagNos.toString());
        }

        else {
          if(EqiupmentID == 19){
            showWarningMsg("Please choose same type of equipment tag number")
          }
          else{
            showWarningMsg("Please choose same unit (KW) equipment tag number")
          }
         
        }


      }
      else {
        setEquipmentTagNo(e);
        var TagNos = e.map((x => {
          return x.value
        }))


        GetparametersUsingEquipmentTagNo(TagNos.toString());

      }
    }
    else {
      setEquipmentTagNo(e);
      GetparametersUsingEquipmentTagNo("");
    }

  }

  const GetDashboardColor = (equipmentDetails) => {

  
    var dashboardColor = [];
    if(equipmentDetails.length > 0){
      equipmentDetails.map((v, i) => {
        dashboardColor.push({"equipmentDetailID" : v.value, "color": MyCOLORS[i]});
      })
    }
    setDashboardColors([...dashboardColor])
    // localStorage.setItem("dashboardColor", JSON.stringify(dashboardColor));
    
  }

 


   const GetparametersUsingEquipmentTagNo = async (TagNumbers, Flag = 1) => {
    
    if (TagNumbers != "") {
      SetLoading(true);
      await DS.Dashboard_GetparametersUsingEquipmentTagNo(TagNumbers, FromDate, ToDate, Flag).then((res) => {
       
        var DashData = [];


        if (res != null && res.data != null && res.data.length > 0) {


          setFilterValue({ FromDate: FromDate, ToDate: ToDate, TagNos: TagNumbers })

          res.data.map((x) => {

            var rowData = [];
            var rowDataForDay = [];



            x.spareCharts.map((v, i) => {

              x.thresholdValueLow = v.thresholdValueLow;
              x.thresholdValueHigh = v.thresholdValueHigh;
              x.acceptableParameterHigh = v.acceptableParameterHigh;
              x.acceptableParameterLow = v.acceptableParameterLow;
              x.equipmentDetailID = v.equipmentDetailID;

              var backgroundcolors = [];
              var currentcolor = ''

              if(DashboardColors.length > 0){
  
                 backgroundcolors = DashboardColors.filter(item => item?.equipmentDetailID == v.equipmentDetailID);
  
                if(backgroundcolors.length > 0){
                  currentcolor = backgroundcolors[0]?.color
                }
                else{
                  currentcolor = ''
                }
              }
             
             
              return (
                rowData.push(
                  {
                    label: (v.commisionValue !== 'NA' && v.commisionValue != 0) ? v.equipmentCode + ' (C.V :' + v.commisionValue + ")" : v.equipmentCode,
                    equipmentDetailID: v.equipmentDetailID,
                    data: v.dashboardDataForLiveTrendsHour,
                    borderWidth: 1,
                    backgroundColor: (currentcolor && currentcolor != '') ? currentcolor : MyCOLORS[i],
                    borderColor:(currentcolor && currentcolor != '') ? currentcolor : MyCOLORS[i],

                    tension: 0.1,
                  
                    // spanGaps: true
                    // pointStyle: "line",
                    // pointRadius: 5,
                    // pointHoverRadius: 8,
                  }
                ),
                rowDataForDay.push(
                  {
                    label: (v.commisionValue !== 'NA' && v.commisionValue != 0) ? v.equipmentCode + ' (C.V :' + v.commisionValue + ")" : v.equipmentCode,
                    equipmentDetailID: v.equipmentDetailID,
                    data: [],
                    borderWidth: 1,
                    backgroundColor: (currentcolor && currentcolor != '') ? currentcolor : MyCOLORS[i],
                    borderColor: (currentcolor && currentcolor != '' ? currentcolor : MyCOLORS[i]),

                    tension: 0.1,
                    pointStyle: "circle",
                   
                    // spanGaps: true

                    // pointRadius: 5,
                    // pointHoverRadius: 8,
                  }
                )
              )
            });


            DashData.push(
              {
                labels: x.dateLables,
                datasets: rowData,
                parameterName: x.parameterName,
                parameterID: x.parameterID,
                rowDataForDay: rowDataForDay,
                rowDataForHour: rowData,
                commisionValue: x.commisionValue,
                thresholdValueHigh: x.thresholdValueHigh,
                thresholdValueLow: x.thresholdValueLow,
                acceptableParameterLow: x.acceptableParameterLow,
                acceptableParameterHigh: x.acceptableParameterHigh
              })

          })

       

        }
        else{
          if(res != null){
            SetLoading(false);
          }
        }


        SetDashBoardData([...DashData]);

        // SetLoading(false);



      }).catch((err) => {
        SetLoading(false);
        console.log(err);
      })

    }
    else {
      SetLoading(false);
      SetDashBoardData([]);
    }

    SetLoading(false);

  }


  useEffect(() => {
    
    var TagNos = EquipmentTagNo.map((x => {
      return x.value
    }))
    GetparametersUsingEquipmentTagNo(TagNos.toString(), Flag);

  }, [Flag])



  useEffect(() => {

    var TagNos = EquipmentTagNo.map((x => {
      return x.value
    }))
    GetparametersUsingEquipmentTagNo(TagNos.toString());


  }, [FromDate, ToDate])


  useEffect(() => {
    if (FromDate != null && FromDate != "") {

      $("#ToDate").attr("min", FromDate);
      $("#ToDate").attr("disabled", false);

    }
    else {
      $("#ToDate").attr("disabled", true);
      setToDate("");
    }

    if (ToDate != null) {

      $("#FromDate").attr("max", ToDate)


    }

    var TagNos = EquipmentTagNo.map((x => {
      return x.value
    }))


  }, [FromDate, ToDate])








  return (
    <div className="row d-flex justify-content-center mt-0 mb-2">



      <div className='col-md-2' style={{ float: "right" }}>
        <label className="form-check-label" htmlFor="category1">
          Stations
        </label>
        <WithoutSelectDropDown data={StationList} value={StationID} name={"StationIDDropDown"} HandleChange={StationHandleChange}   ></WithoutSelectDropDown>
      </div>

      <div className='col-md-2' style={{ float: "right" }}>
        <label className="form-check-label" htmlFor="category1">
          Equipment
        </label>
        <WithoutSelectDropDown data={EqiupmentList} name={"EqiupmentIDDropDown"} HandleChange={EquipmentHandleChange}   ></WithoutSelectDropDown>
      </div>


      <div className="col-md-3">
        <label className="form-check-label" htmlFor="category1">
          Equipment Tag Numbers
        </label>
        <ReactMultiSelect
          options={EquipmentTagNoList}
          isMulti
          value={EquipmentTagNo}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={(e) => HandleChange(e)}

        />
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            From Date
          </label>
          <input
            className="form-control ManualClearClass"
            type="date"
            name='FromDate'
            id="FromDate"
            value={FromDate}


            onChange={(e) => {
              setFromDate(e.target.value)

            }}

          />


        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <label className="label" htmlFor="empCode">
            To Date
          </label>
          <input
            className="form-control ManualClearClass"
            type="date"
            name='ToDate'
            id="ToDate"
            value={ToDate}

            onChange={(e) => { setToDate(e.target.value) }}

          />


        </div>
      </div>
      <div className="col-md-1 text-center">

<div className="form-group">

 <IconButton TabValue={3}/>



</div>

</div>
    </div>
  );
};

export const EquipmentLiveTrends = ({TabID, TabValue}) => {


  const [DashBoardData, SetDashBoardData] = useState([]);
  const [FilteredParameter, setFilteredParameter] = useState({});
  const [Flag, SetFlag] = useState(1);
  const [Loading, setLoading] = useState(true);

  const SpareChage = (value) => {
    SetDashBoardData([...value])
  }

  const ChangeFormet = (value) => {

    SetFlag(value)

  }
  const setFilterValue = (e) => {
    setFilteredParameter({ ...e })
  }
  const SetLoading = (e) => {
    
     setLoading(e);
  }

  return (
    <>
      {/* Tiles */}
      {/* <EquipmentTiles /> */}

      {/* Equipment Filter */}
      <EqptFilters SetDashBoardData={SpareChage} Flag={Flag} setFilterValue={setFilterValue} SetLoading={SetLoading} TabID={TabID} TabValue={TabValue} />

      {/* Line Chart */}
      {
        !Loading ?

          <EqptGraph DashBoardData={DashBoardData} ChangeFormet={ChangeFormet} FilteredParameter={FilteredParameter} />

          : < >
            <div className="text-center" style={{margin:"10rem"}}>           
                <div className="loader-Wrapper">
              <div className="Customloader-div" >
                <span className="loader"></span>
              </div>
            </div>
            </div>

          </>
      }
    </>
  );
};

