import { useEffect, useState, useContext } from "react";
import { PageHeader } from "../../components/PageHeader";
import {
    PerformanceEvaluation_DropDownDetails, PerformanceEvaluation_SaveDescriptionDetails,
    PerformanceEvaluation_GetDescriptionByID, PerformanceEvaluation_SaveScheduleCheckListDetails,
} from "./PerformanceEvaluationService";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import { getCurrentScheduleDate, getScheduleType, makeUnique } from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import $ from "jquery";
import {
    showWarningMsg,
    showSaveSuccess,
    Dropdown,
    ReactMultiSelect,
    HtmlTable,
    showSaveError,
    showErrorMsg,
    BootrapModel,
    showSuccessMsg,
} from "../../components";
import {
    getPrivilege,
    Decrypt,
    getYMDdate,
    GetLoginUserID,
    getUserDetails,
    addDays,
} from "../../services/CommonService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import moment from "moment";


export default function PerformanceEvaluation() {
    const initialEquipmentState = {
        PerfomanceID: [],
        EquipmentID: {},
        StationID: "",
        DescriptionName: '',
        Unit: '',
        OrderNo: 0,
        IsDeleted: false,
        CheckAllAttended: false,
        CurrentDate: "",
        IsShowSubmitBtn: false,
        checkAllApproved: false,
        checkAllRejected: false,
    };
    const { getCookies, showLoading, hideLoading, handleGlobalStationName, PerformanceDropdownList, setPerformanceDropdownList } =
        useContext(AppContext);

    const { StationID, StartDate } = PerformanceDropdownList;
    const _breakdownService = new BreakdownMaintenanceService();
    const [PerformanceModel, setPerformanceModel] = useState(initialEquipmentState);
    const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
    const [StationList, setStationList] = useState([]);
    const [RowList, setRowList] = useState([]);
    const [FileData, setFileData] = useState([]);
    const [columnList, setColumnList] = useState([]);
    const [errors, setErrors] = useState({
        EquipmentID: "",
        StationID: "",
        DescriptionName: "",
        OrderNo: "",
    });

    useEffect(() => {
        initDropDown()
    }, []);

    useEffect(() => {

        if (StartDate) {


            setPerformanceModel((pre) => ({
                ...pre,
                CurrentDate: StartDate
            }))
        }
    }, [StartDate])


    useEffect(() => {
        initialEquipmentCode();
    }, [PerformanceModel.StationID]);
    useEffect(() => {
        GetDescriptionByID();
    }, [
        PerformanceModel.EquipmentID,
        PerformanceModel.StationID,
        PerformanceModel.CurrentDate,
    ]);
    function SubmitButton() {
        return $(
            `<button  class="btn btn-primary m-l-15"
            >  Submit</button>  `
        ).on("click", function (e) {
            e.preventDefault();
            Submit_SavePerfomanceEvaluation(72);
        });
    }



    function ApprovedBtn() {
        let ApproveBtn = $(
            `<button  class="btn btn-success disabled"
              >  Approve </button>  `
        ).on("click", function (e) {
            e.preventDefault();

            Submit_SavePerfomanceEvaluation(73);
        });
        let RejectBTn = $(
            `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
              >  Reject </button>  `
        ).on("click", function (e) {
            e.preventDefault();
            Submit_SavePerfomanceEvaluation(74);
        });

        let Fragment = $(`<span></span>`);
        let currentCheckListDate = moment(PerformanceModel.CurrentDate).add(3, "M");

        let ForMaintenanceDays = addDays(currentCheckListDate, 3);
        let ForEngineerDays = addDays(currentCheckListDate, 15);

        const { CheckAllAttended, CheckAllRejected, CheckAllApproved } =
            PerformanceModel;

        if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
            Fragment.append(ApproveBtn);
            Fragment.append(RejectBTn);
        }
        if (
            getUserDetails().RoleID == COOKIES.MaintainerRolID &&
            getYMDdate(ForMaintenanceDays) >= getYMDdate(new Date()) &&
            !CheckAllAttended &&
            !CheckAllApproved &&
            !CheckAllRejected
        ) {
            ApproveBtn.removeClass("disabled");
            RejectBTn.removeClass("disabled");
        }

        if (
            getPrivilege().includes("CHECKLISTAPPROVAL") &&
            getUserDetails().RoleID !== COOKIES.MaintainerRolID &&
            !CheckAllAttended &&
            !CheckAllApproved
        ) {
            ApproveBtn.removeClass("disabled");
        }

        if (
            getPrivilege().includes("CHECKLISTAPPROVAL") &&
            getUserDetails().RoleID !== COOKIES.MaintainerRolID &&
            getYMDdate(ForEngineerDays) >= getYMDdate(new Date()) &&
            !CheckAllAttended &&
            (getYMDdate(new Date()) > getYMDdate(ForMaintenanceDays) ||
                CheckAllApproved)
        ) {
            RejectBTn.removeClass("disabled");
        }

        return Fragment;
    }
    const GetDescriptionByID = async () => {
        var CheckDate = getYMDdate(new Date());

        let ScheduleType = getScheduleType(new Date(PerformanceModel.CurrentDate));
        if (
            PerformanceModel.StationID &&
            PerformanceModel.EquipmentID?.Value &&
            PerformanceModel.CurrentDate
        ) {
            await PerformanceEvaluation_GetDescriptionByID(
                PerformanceModel.StationID,
                PerformanceModel.EquipmentID.Value ?? 0,
                PerformanceModel.CurrentDate,
                ScheduleType
            )
                .then((res) => {
                    if (res?.data?.data) {
                        let columnsList = [];
                        columnsList.push({
                            title: " S.No",
                            data: "RowID",
                            width: "5px",
                            className: "S_no_Class",
                            // visible: false
                        });
                        columnsList.push({
                            title: `<p style = 'width : 150px'> Description of Work<p/>`,
                            data: "DescriptionName",
                            width: "150px ",
                        });
                        columnsList.push({
                            title: `<p style = 'width : 50px'> UNIT<p/>`,
                            data: "UNIT",
                            width: "18px ",
                        });
                        const { descriptionList, equipmentList } =
                            res.data.data;

                        if (descriptionList?.length) {
                            let uniqueList = makeUnique(equipmentList, [
                                "EquipmentCode",
                                "EquipmentDetailID",
                            ]);
                            let IsAllowToEnter = IsAllowToEnterData();

                            if (uniqueList && uniqueList[0].EquipmentCode && uniqueList[0].EquipmentCode.includes('CT')) {

                                const { EquipmentDetailID, EquipmentCode } = uniqueList[0];

                                columnsList.push({
                                    title: `<p > Rated <p/>`,
                                    data: `Rated_${EquipmentDetailID}`,
                                    createdCell: function (td, cellData, rowData, row, col) {
                                        var inputRatedElement = "";
                                        let RatedValue = rowData[`Rated_${EquipmentDetailID}`]
                                        debugger
                                        IsAllowToEnter ?
                                            inputRatedElement = $(
                                                `<input type="number"  class="form-control_copy " style="width:100px;margin-right:5px" value="${RatedValue}"/>`
                                            ) : inputRatedElement = $(
                                                `<input type="number" disabled  class="form-control_copy " style="width:100px;margin-right:5px" value="${RatedValue}"/>`
                                            )
                                        inputRatedElement.on("change", function (e) {
                                            e.preventDefault();
                                            const { value } = e.target;
                                            rowData[`Rated_${EquipmentDetailID}`] = value;
                                        });

                                        $(td).html(inputRatedElement);
                                    },
                                })
                            }
                            uniqueList && uniqueList[0].EquipmentCode && uniqueList[0].EquipmentCode.includes('AHU') ?
                                uniqueList?.forEach((element, _) => {

                                    const { EquipmentDetailID, EquipmentCode } = element;
                                    let div = $(`<span><span/>`);
                                    let para = $(
                                        `<p style ="width : 180px;text-align: center;" >${EquipmentCode}</p>`
                                    );
                                    let valuePara = $('<p style="margin-right: 5px;">Rated<span style="margin-right: 40px;"></span>Actual</p>');

                                    div.append(para);
                                    div.append(valuePara);
                                    columnsList.push({
                                        title: div.html(),
                                        data: `Actual_${EquipmentDetailID}`,
                                        createdCell: function (td, cellData, rowData, row, col) {
                                            var inputRatedElement = "";
                                            var inputActualElement = '';
                                            let Actualvalue = rowData[`Actual_${EquipmentDetailID}`]
                                            let RatedValue = rowData[`Rated_${EquipmentDetailID}`]
                                            IsAllowToEnter ?
                                                inputRatedElement = $(
                                                    `<input type="number"  class="form-control_copy " style="width:50px;margin-right:5px" value="${RatedValue}"/>`
                                                ) : inputRatedElement = $(
                                                    `<input type="number" disabled class="form-control_copy " style="width:50px !important;margin-right:5px" value="${RatedValue}"/>`
                                                )
                                            IsAllowToEnter ?
                                                inputActualElement = $(
                                                    `<input type="number"  class="form-control_copy" style="width:50px" value="${Actualvalue}"/>`
                                                ) : inputActualElement = $(
                                                    `<input type="number" disabled class="form-control_copy" style="width:50px" value="${Actualvalue}"/>`
                                                )
                                            inputRatedElement.on("change", function (e) {
                                                e.preventDefault();
                                                const { value } = e.target;
                                                rowData[`Rated_${EquipmentDetailID}`] = value;
                                            });
                                            inputActualElement.on("change", function (e) {
                                                e.preventDefault();
                                                const { value } = e.target;
                                                rowData[`Actual_${EquipmentDetailID}`] = value;
                                            });

                                            $(td).html(inputRatedElement);
                                            $(td).append(inputActualElement);
                                        },
                                    });
                                }) :
                                uniqueList?.forEach((element, _) => {

                                    const { EquipmentDetailID, EquipmentCode } = element;
                                    let div = $(`<span><span/>`);
                                    let para = $(
                                        `<p style ="width : 180px;text-align: center;" >${EquipmentCode}</p>`
                                    );

                                    div.append(para);
                                    columnsList.push({
                                        title: div.html(),
                                        data: `Actual_${EquipmentDetailID}`,
                                        createdCell: function (td, cellData, rowData, row, col) {
                                            var inputActualElement = "";
                                            let ActualValue = rowData[`Actual_${EquipmentDetailID}`]
                                            IsAllowToEnter ?
                                                inputActualElement = $(
                                                    `<input type="text"  class="form-control_copy " style="width:100px;margin-right:5px" value="${ActualValue}"/>`
                                                ) : inputActualElement = $(
                                                    `<input type="text" disabled class="form-control_copy " style="width:100px;margin-right:5px" value="${ActualValue}"/>`
                                                )
                                            inputActualElement.on("change", function (e) {
                                                e.preventDefault();
                                                const { value } = e.target;
                                                rowData[`Actual_${EquipmentDetailID}`] = value;
                                            });


                                            $(td).html(inputActualElement);
                                        },
                                    });
                                });
                            columnsList.push({
                                title: "Remarks",
                                data: "RemarksByTechnician",
                                width: "60px",
                                createdCell: function (td, cellData, rowData, row, col) {
                                    var inputElement = "";

                                    inputElement = $(
                                        `<textarea type="text" class="form-control_copy"  style="width:130px;resize:none" >${cellData}</textarea>`
                                    ).on("change", function (e) {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        rowData.RemarksByTechnician = value;
                                    });

                                    // else{
                                    //   inputElement = $(
                                    //     `<input type="text" class="form-control" disabled  style="width:60px" value="${cellData}"/>`
                                    //   )
                                    // }

                                    $(td).html(inputElement);
                                },
                            });
                            columnsList.push({
                                title: "Status",
                                data: "Status",
                                width: "60px",
                                createdCell: function (td, cellData, rowData, row, col) {
                                    $(td).html(GetPeriodicStatus(cellData));
                                },
                            });
                            columnsList.push({
                                title: "Upload",
                                data: "Upload",
                                width: "40px",

                                createdCell: function (td, cellData, rowData, row, col) {
                                    let normalDiv = $('<div class="file-upload-div "> </div>');
                                    let UploadIcon = $(
                                        '<i class="fa fa-upload col-6" aria-hidden="true"></i>'
                                    ).on("click", function (e) {
                                        var parentElement = $(e.target).closest(".file-upload-div");
                                        parentElement.find(".file-input").click();
                                    });
                                    var inputElement = $(`
                            <input type="file" class="form-control hide file-input"  multiple value="${cellData}"/>`).on(
                                        "change",
                                        async function (e) {
                                            e.preventDefault();

                                            const formData = new FormData();
                                            $.each(e.target.files, function (i, file) {
                                                formData.append("files", file);
                                            });
                                            if (e.target?.files.length > 0) {
                                                let returnValue = await fileUpload(formData);
                                                if (returnValue) {
                                                    rowData.Upload = JSON.stringify(returnValue);
                                                }
                                            } else {
                                                rowData.Upload = "";
                                            }
                                        }
                                    );
                                    let IsDownloadEnable = rowData.DownloadFlag == 1;
                                    let DownloadIcon = "";
                                    if (IsDownloadEnable) {
                                        DownloadIcon =
                                            $(`<button class='btn icon-btn text-center'data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
                                      <i class='fa fa-download m-r-5'></i>
                                      </button>`).on("click", function () {
                                                DownloadFiles(rowData.PerformanceMaintenceID, 0, 6);
                                            });
                                    } else {
                                        DownloadIcon =
                                            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
                                    <i class='fa fa-download m-r-5'></i>
                                    </button>`);
                                    }
                                    if (IsAllowToEnter) {
                                        normalDiv.append(UploadIcon);
                                    }
                                    normalDiv.append(inputElement);
                                    normalDiv.append(DownloadIcon);
                                    $(td).html(normalDiv);
                                },
                            });
                            descriptionList.forEach((element) => {
                                let filList = equipmentList.filter(
                                    (x) => x.PerformanceEvaluationID == element.PerformanceEvaluationID
                                );
                                filList.forEach((y) => {
                                    let column_name = y.EquipmentDetailID.toString();
                                    // element[column_name] = y.EquipmentDetailID;
                                    element[`Actual_${column_name}`] = y.ActualValue;
                                    element[`Rated_${column_name}`] = y.RatedValue;
                                });
                            });


                            let CheckDay =
                                getYMDdate(new Date());
                            let IsShowSubmitBtn = false;
                            let checkCurrentRange = CheckDate == CheckDay;
                            let CheckAllAttended = false,
                                CheckAllApproved = false;
                            let checkAnyRejected = descriptionList.some(
                                (x) => x.Status == "Rejected"
                            );

                            CheckAllAttended = descriptionList.some((x) => x.Status == 'Open');
                            CheckAllApproved = descriptionList.some((x) => x.Status == "Approved");
                            if (CheckAllAttended) {
                                IsShowSubmitBtn = true;
                            }
                            if (checkAnyRejected) {
                                IsShowSubmitBtn = true;
                            }

                            setPerformanceModel((pre) => ({
                                ...pre,
                                IsShowSubmitBtn: IsShowSubmitBtn,
                                CheckAllAttended: CheckAllAttended,
                                CheckAllApproved: CheckAllApproved,
                                CheckAllRejected: checkAnyRejected,
                            }));

                            let FinalRowObj = descriptionList.sort(
                                (a, b) => a.RowID - b.RowID
                            );



                            setColumnList([...columnsList]);
                            setRowList([...FinalRowObj]);
                        } else {
                            setColumnList([]);
                            setRowList([]);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setColumnList([]);
            setRowList([]);
        }
    };
    function Submit_SavePerfomanceEvaluation(Status) {
        let tableData = $("#PerfomanceEvaluationTable").DataTable().rows().data().toArray();
        if (tableData.length) {
            let FinalObj = [];
            let manipulateData = JSON.parse(JSON.stringify(tableData));
            let parentObj = [
                "RowID",
                "DescriptionName",
                "RemarksByTechnician",
                "Upload",
                "PerformanceEvaluationID",
                "DescriptionID",
                "PerformanceMaintenceID",
                "DownloadFlag",
                "ShiftEngineerID",
                "ShiftTechnicianID",
                "StatusName",
                "UOM",
                "Status",
            ];

            let EquipmentList = Object.keys(manipulateData[0]);
            let EquipmentData = EquipmentList.filter((ele) => {
                return !parentObj.includes(ele);
            });
            // console.log(manipulateData);
            if (EquipmentData?.length) {
                manipulateData.forEach((parentEle) => {
                    let obj = [];

                    EquipmentData.forEach((ele) => {
                        if (ele.includes('Rated_')) {
                            const ratedProperty = ele.replace('Rated_', '');

                            obj.push({
                                EquipmentDetailID: ratedProperty,
                                RatedValueData: parentEle[ele],
                            });
                        } else if (ele.includes('Actual_')) {
                            const ratedProperty = ele.replace('Actual_', '');

                            obj.push({
                                EquipmentDetailID: ratedProperty,
                                ActualValueData: parentEle[ele],
                            });
                        }
                        delete parentEle[ele];
                    });

                    // const filteredRatedData = obj.filter(item => item.RatedValueData || item.ActualValueData);
                    const filteredActualData = obj.filter(item => item.ActualValueData);
                    let IsDataAvailable = obj.some((check) => check.ActualValueData || check.RatedValueData);
                    if (!IsDataAvailable) {
                        IsDataAvailable = parentEle.UOM?.length > 0;
                        if (!IsDataAvailable) {
                            IsDataAvailable = parentEle.RemarksByTechnician?.length > 0;
                        }
                        if (!IsDataAvailable) {
                            IsDataAvailable = parentEle.Upload?.length > 0;
                        }
                    }
                    parentEle.RatedValueList = JSON.stringify(obj);
                    parentEle.ActualvalueList = JSON.stringify(filteredActualData);
                    parentEle.SavedBy = GetLoginUserID();
                    parentEle.CurrentDate = getYMDdate(PerformanceModel.CurrentDate)
                    parentEle.ScheduleType = getScheduleType(new Date(PerformanceModel.CurrentDate));

                    parentEle.Status = Status;

                    if (Status == 72) {
                        parentEle.ShiftTechnicianID = GetLoginUserID();
                    } else {
                        parentEle.ShiftEngineerID = GetLoginUserID();
                    }
                    if (IsDataAvailable || parentEle.PerformanceMaintenceID > 0) {
                        FinalObj.push(parentEle);
                    }

                });

                SavePerformanceEvaluation(FinalObj);
            }
        }
    }

    async function SavePerformanceEvaluation(data) {
        if (data?.length) {
            showLoading();
            await PerformanceEvaluation_SaveScheduleCheckListDetails(data)
                .then((res) => {
                    hideLoading();
                    if (res.data.isSuccess) {
                        showSaveSuccess();
                    } else {
                        showErrorMsg();
                    }
                    GetDescriptionByID();
                })
                .catch((err) => {
                    hideLoading();
                    console.log(err);
                    showSaveError();
                });
        } else {
            showWarningMsg("Please enter at least one value to submit!");
        }
    }
    const AddHandleClick = async (e) => {
        e.preventDefault();

        let obj = { ...PerformanceModel };
        let isError = false;
        let modelErrors = errors;
        if (obj.EquipmentID?.Value) {
            modelErrors.EquipmentID = "";
        } else {
            modelErrors.EquipmentID = "Please Select Equipment.";
            isError = true;
        }
        if (obj.StationID) {
            modelErrors.StationID = "";
        } else {
            modelErrors.StationID = "Please Select Station.";
            isError = true;
        }

        if (obj.DescriptionName?.length) {
            modelErrors.Description = "";
        } else {
            modelErrors.Description = "Please Select Description.";
            isError = true;
        }
        if (obj.OrderNo != 0) {
            modelErrors.OrderNo = "";
        } else {
            modelErrors.OrderNo = "Please fill OredrNo.";
            isError = true;
        }
        setErrors({ ...modelErrors });
        if (!isError) {
            obj.EquipmentID = obj.EquipmentID.Value ?? 0;
            obj.SavedBy = GetLoginUserID();
            obj.SavedDate = new Date()

            PerformanceEvaluation_SaveDescriptionDetails(obj)
                .then((res) => {

                    const { data } = res?.data;
                    if (data == "-1" || data == null) {
                        showSaveError();
                    } else {
                        showSaveSuccess();
                        setPerformanceModel((pre) => ({
                            ...pre,
                            DescriptionName: "",
                            Unit: '',
                        }));
                    }
                    GetDescriptionByID();
                })
                .catch((err) => {
                    console.log(err);
                    setPerformanceModel((pre) => ({
                        ...pre,
                        DescriptionName: "",
                        Unit: '',
                    }));
                });
        }
    };

    const fileUpload = async (formData) => {
        showLoading();
        return await _breakdownService
            .FileUpload(formData)
            .then((res) => {
                hideLoading();
                showSuccessMsg("Files Uploaded SuccessFully.");
                return res;
            })
            .catch((err) => {
                hideLoading();
                showErrorMsg("Something Went Wrong! Please Try Again.");

                console.log(err);
                return 0;
            });
    };
    const DownloadFiles = (ticketID, AttachmetID, Flag) => {
        _breakdownService
            .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
            .then((res) => {
                setFileData(res.data);
            })
            .catch((err) => {
                setFileData([]);
                console.log(err);
            });
    };
    function IsAllowToEnterData() {
        return getUserDetails().RoleID == COOKIES.TechRoleID && StartDate <= getYMDdate();
    }

    const initDropDown = async () => {
        const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
        let FinalStationList = [];
        let ignoredStation = ["stc", "str", "swa"];
        let filterStation = userStationsList.filter((x) => {
            return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
        });
        FinalStationList = filterStation.map((data) => {
            return { value: data.StationID, key: data.StationCode };
        });

        setStationList([...FinalStationList]);
        let initialStationID = FinalStationList[0]?.value ?? 0;
        if (StationID > 0) {
            initialStationID = StationID;
        }

        let initialStartDate = getYMDdate(getCurrentScheduleDate());
        if (StartDate != "") {
            initialStartDate = getYMDdate(StartDate);
        }

        setPerformanceModel((pre) => ({
            ...pre,
            StationID: initialStationID,
            CurrentDate: initialStartDate
        }));
        handleGlobalStationName(FinalStationList[0]?.key ?? "");


    }
    const initialEquipmentCode = async () => {
        if (PerformanceModel.StationID > 0) {
            await PerformanceEvaluation_DropDownDetails(PerformanceModel.StationID).then((res) => {
                setEquipmentTagNumberList(res.data.data)
                setPerformanceModel((pre) => ({
                    ...pre,
                    EquipmentID: res.data.data[0],
                }));
            })
        }
    }
    const CommonHandleChange = (event, name) => {
        if (Object.hasOwn(event, "target")) {
            const { name, value } = event.target;

            setPerformanceModel((PreviousState) => ({
                ...PreviousState,
                [name]: value,
            }));
            if (name == "StationID") {
                let Station = StationList.find((x) => x.value == value);

                setPerformanceDropdownList((pre) => ({
                    ...pre,
                    StationID: value
                }))
                handleGlobalStationName(Station?.key ?? "");
            }
        } else {
            setPerformanceModel((PreviousState) => ({
                ...PreviousState,
                [name]: event,
            }));
        }
    };

    return (
        <div>
            <div style={{ position: "absolute", top: "65px", right: "33px" }}>
                {/* {`Document No`} */}
                {PerformanceModel?.EquipmentID?.DocumentNo &&
                    `Doc Number :${PerformanceModel?.EquipmentID?.DocumentNo}`}
            </div>
            <div className="row">
                <PageHeader
                    title={`Performance Evaluation`}
                >
                    {" "}
                </PageHeader>
            </div>
            <form id="CheckList">
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <label className="label" for="empCode">
                                Station Name<span className="text-danger"> *</span>{" "}
                            </label>
                            <Dropdown
                                data={StationList}
                                placeholder="Select"
                                name={"StationID"}
                                HandleChange={CommonHandleChange}
                                value={PerformanceModel.StationID}
                                className=""
                            />
                            <span className="text-danger mt-1"> {errors.StationID} </span>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="form-group">
                            <label className="label" for="empCode">
                                Equipment
                                <span className="text-danger"> *</span>
                            </label>
                            <ReactMultiSelect
                                options={EquipmentTagNumberList}
                                placeholder="Select"
                                value={PerformanceModel.EquipmentID}
                                onChange={(e) => {
                                    CommonHandleChange(e, "EquipmentID");
                                }}
                            />
                            <span className="text-danger"> {errors.EquipmentID} </span>
                        </div>
                    </div>

                    {getPrivilege().includes("PERFORMANCEEVALUATIONCREATE") && (
                        <>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="label" for="empCode">
                                        Description <span className="text-danger"> *</span>{" "}
                                    </label>{" "}
                                    <textarea
                                        className="form-control "
                                        style={{ resize: "none" }}
                                        // type="text"
                                        name="DescriptionName"
                                        value={PerformanceModel.DescriptionName}
                                        rows={2}
                                        onChange={(e) => CommonHandleChange(e)}
                                    >
                                    </textarea>
                                    <span className="text-danger mt-1">
                                        {" "}
                                        {errors.Description}{" "}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <div className="form-group">
                                    <label className="label" for="empCode">
                                        Unit
                                    </label>{" "}
                                    <input
                                        className="form-control "
                                        style={{ resize: "none" }}
                                        type="text"
                                        name="Unit"
                                        value={PerformanceModel.Unit}
                                        rows={2}
                                        onChange={(e) => CommonHandleChange(e)}
                                    >
                                    </input>
                                    {/* <span className="text-danger mt-1">
                                        {" "}
                                        {errors.Unit}{" "}
                                    </span> */}
                                </div>
                            </div>

                            <div className="col-md-1">
                                <div className="form-group">
                                    <label className="label" for="empCode">
                                        Order No <span className="text-danger"> *</span>{" "}
                                    </label>{" "}
                                    <input
                                        className="form-control "
                                        type="number"
                                        name="OrderNo"
                                        value={PerformanceModel.OrderNo}
                                        rows={2}
                                        onChange={(e) => CommonHandleChange(e)}
                                    />
                                    <span className="text-danger mt-1"> {errors.OrderNo} </span>
                                </div>
                            </div>

                            <div className="col-md-2 mt-4 alert-dismissible">
                                <button
                                    type="submit"
                                    id="addRow_ers"
                                    onClick={AddHandleClick}
                                    className="btn add-btn"
                                >
                                    <i className="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </>
                    )}


                </div>
            </form>
            {columnList?.length ? (
                <>
                    <div style={{ marginTop: "-25px" }}>
                        <HtmlTable
                            FixedColums={{
                                left: 3,
                                right: 2,
                            }}
                            OrderTargetList={Array.from(
                                { length: columnList.length - 6 },
                                (_, i) => i + 2
                            )}
                            IsASC
                            rowList={RowList}
                            columnList={columnList}
                            // ShowLengthPostponedHtml={DateFilter}
                            SearchPrependHtml={ApprovedBtn}
                            SearchPostponedHtml={
                                IsAllowToEnterData() && PerformanceModel.IsShowSubmitBtn
                                    ? SubmitButton
                                    : ""
                            }
                            // addedPlugin={addedPlugin}
                            id={`PerfomanceEvaluationTable`}
                        />
                    </div>
                    <div className="row"></div>
                </>
            ) : (
                <div className="row col-12 text-center  mb-2">
                    <p className="text-muted"></p>
                </div>
            )}
            <BootrapModel
                Id={"DownLoadFiles"}
                CloseID="DownLoadFilesClose"
                ModelTitle={"Download Attachments"}
                modelSize={"modal-xl"}
                module={
                    <DownloadModel
                        Flag={5}
                        DeleteAttachment={DownloadFiles}
                        FileDatas={FileData}
                    // DownLoadRefresh={DownLoadRefresh}
                    ></DownloadModel>
                }
            >
                {" "}
            </BootrapModel>
        </div>
    );
}