import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../env";
import { Decrypt } from "../../services/CommonService";
import { COOKIES } from "../../services/Global";

export const PM_AllApiFunction = createApi({
  reducerPath: "PERIODIC_MAINTENANCE_SERVICE",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    headers: {
      Authorization: "Bearer " + Decrypt(localStorage.getItem(COOKIES.Jwt)),
    },
  }),
  endpoints: (builder) => ({
    GetEquipmentForJobAllocation: builder.mutation({
      query: ({ StationID, EmployeeID, FromDate, ToDate, DepartmentID,Search,Start,Length }) => ({
        url: `PeriodicMaintenance/PeriodicMaintenanceGetEquipmentForJobAllocation?StationID=${StationID}&EmployeeID=${EmployeeID}&FromDate=${FromDate}&ToDate=${ToDate}
        &DepartmentId=${DepartmentID}&Search=${Search}&Start=${Start}&Length=${Length}`,
        method: "get",
      }),
    }),
  }),
});

export const { useGetEquipmentForJobAllocationMutation } = PM_AllApiFunction;
