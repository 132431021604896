import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import { getYMDdate, addDays } from "../../services/CommonService";
import { PeriodicService } from "./PeriodicService";
import { PeriodicWeekData, checkDate, checkMonth } from "./PeriodicWeekData";
import CommonWeekCalender from "./CommonWeekCalender";


export default function CalendarMonthView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const currRange = useMemo(
    () => CalendarMonthView.range(date, { localizer }),
    [date, localizer]
  );
  const [week, setWeek] = useState(0);

  useEffect(() => {
    var date = new Date();
    setWeek(getWeekOfMonth(date));
  }, [week]);

  //get week number of date
  function getWeekOfMonth(date) {
    let adjustedDate = date.getDate() + date.getDay();
    let prefixes = ["0", "1", "2", "3", "4", "5"];
    return parseInt(prefixes[0 | (adjustedDate / 7)]) + 1;
  }

  var week1 = currRange.slice(0, 7);
  var week2 = currRange.slice(7, 14);
  var week3 = currRange.slice(14, 21);
  var week4 = currRange.slice(21, 28);

  var week5 = PeriodicWeekData(currRange[0]);

  var weekLst = [week1, week2, week3, week4, week5];

  return (
   <CommonWeekCalender weekLst={weekLst} ></CommonWeekCalender>
  );
}

CalendarMonthView.propTypes = {
  date: localStorage.getItem("monthDate"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalendarMonthView.range = (date, { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CalendarMonthView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "month");

    case Navigate.NEXT:
      return localizer.add(date, 1, "month");

    default:
      return new Date();
  }
};

CalendarMonthView.title = (date, { localizer }) => {
  localStorage.setItem("monthDate", getYMDdate(date));

  const month = date.toLocaleString("default", { month: "long" });
  var titleString = `${month}-${date.getFullYear()}`;
  return titleString;
  // const [start, ...rest] = CalendarMonthView.range(date, { localizer })
  // return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat')
};


