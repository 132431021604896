import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  BootrapModel,
  HtmlTable,
  ReactMultiSelect,
  WebCam,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
  showWarningMsg,
} from "../../components";
import { TbCameraSelfie } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19,
  PeriodicMaintenance_GetOverAllRemarks,
  PeriodicMaintenance_SaveSpareData,
} from "../PeriodicMaintenance/PeriodicService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";
import {
  DeepCopy,
  GetLoginUserID,
  GetSelect,
  getL0RoleID,
  getUserDetails,
  makeUnique,
} from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { useNavigate } from "react-router";
import { SpareList } from "../PeriodicMaintenance/SpareList";
import { PeriodicMaintenance_GetEquipmentDetailsUsingID } from "../PeriodicMaintenance/PeriodicService";
import { IoMdCheckmark } from "react-icons/io";
import { RiArrowGoBackLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import swal from "sweetalert";

const _breakdownService = new BreakdownMaintenanceService();
function Depart_UG19StationPMPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showLoading, hideLoading, PeriodicModel, setPeriodicModel } =
    useContext(AppContext);

  const {
    PeriodicCheckListMaintenanceID,
    FromDate,
    enddate,
    EquipmentDetails,
    DepartmentID,
  } = location.state;

  const {
    PWTDate,
    PWTNumber,
    ApprovalRemarks,
    EquipmentDetailId,
    OverAllRemarksList,
    AllClosed,
  } = PeriodicModel;

  const [UGSColumnList, setUGSColumnList] = useState([]);
  const [UGSRowList, setUGSRowList] = useState([]);

  const [spareList, setSpareList] = useState([]);

  const [TestNo, setTestNo] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setPeriodicModel({
      PeriodicCheckListMaintenanceID,
      DepartmentID,
      FromDate: FromDate,
      EndDate: enddate,
      PWTDate: "",
      EquipmentDetailId: EquipmentDetails[0] ?? {},
      isBreakDown: EquipmentDetails[0]?.EquipAvailStatus ?? 0,
      Location: "",
      PWTNumber: "",
    });
    localStorage.setItem("FilePaths", "");
    localStorage.setItem("isAlreadyUploaded", "");
    localStorage.setItem("IsJobAssigner", "");
    localStorage.setItem("AllClosed", "");
    return () => {
      setUGSColumnList([]);
      setUGSRowList([]);
      localStorage.setItem("FilePaths", "");
      localStorage.setItem("isAlreadyUploaded", "");
      localStorage.setItem("IsJobAssigner", "");
      localStorage.setItem("AllClosed", "");
    };
  }, []);

  useEffect(() => {
    GetDescriptionByID();
  }, [PeriodicCheckListMaintenanceID, EquipmentDetailId?.value]);

  const CommonHandleChange = async (event, CusName) => {
    if (CusName) {
      if (CusName == "EquipmentDetailId") {
        setPeriodicModel({
          [CusName]: event,
          isBreakDown: event.EquipAvailStatus,
        });
      }
    } else {
      const { name, value } = event.target;
      if (name == "FileObj") {
        event.preventDefault();
        const formData = new FormData();
        $.each(event.target.files, function (i, file) {
          formData.append("files", file);
        });
        if (event.target?.files.length > 0) {
          let returnValue = await fileUpload(formData);
          if (returnValue) {
            let FilePaths = localStorage.getItem("FilePaths");
            if (FilePaths) {
              FilePaths = JSON.parse(FilePaths);
            } else FilePaths = [];
            // let FilePaths = CopyFileObj.FilePaths;

            if (!Array.isArray(FilePaths)) {
              FilePaths = [];
            }

            returnValue.forEach((ele) => (ele.Type = "UPLOAD"));
            FilePaths = FilePaths.concat(returnValue);
            localStorage.setItem("FilePaths", JSON.stringify(FilePaths));
          }
        }
      } else {
        if (name == "ApprovalRemarks") {
          $(".approveRemarks").removeClass("border-danger");
        }
        setPeriodicModel({ [name]: value });
      }
    }
  };

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  const GetDescriptionByID = () => {
    showLoading();
    if (PeriodicCheckListMaintenanceID && EquipmentDetailId?.value) {
      PeriodicMaintenance_GetEquipmentDetailsUsingID(
        PeriodicCheckListMaintenanceID,
        EquipmentDetailId?.value,
        GetLoginUserID()
      )
        .then((res) => {
          hideLoading();
          convertColum(res);
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };

  const SaveJobDetailsForUG = async (data) => {
    showLoading();

    return await PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19(
      data
    )
      .then((res) => {
        hideLoading();
        if (res?.data?.isSuccess) {
          if (data.isBreakDown == "115" || data.isBreakDown == "117") {
            let Equipment = EquipmentDetails.find(
              (ele) => (ele.value = EquipmentDetailId?.value)
            );
            let EquipmentCode = "";
            if (Equipment) {
              EquipmentCode = Equipment?.key ?? "";
            }
            showWarningMsg(
              `This equipment ${EquipmentCode} is currently not running. Please check again later`
            );
          } else {
            if (getL0RoleID() == getUserDetails().RoleID) {
              const { PeriodicDetailID, IsAllClosed } = data;
              if (IsAllClosed) {
                GetDescriptionByID();
                showSaveSuccess();
                return true;
              }
              if (data.Status == 2) {
                let tableData = $("#PeriodicUGStation")
                  .DataTable()
                  .rows()
                  .data()
                  .toArray();
                let isAnyOpen = tableData.some(
                  (ele) => ele.StatusName == "Closed"
                );
                if (!isAnyOpen) {
                  GetDescriptionByID();
                } else {
                  let obj = tableData.find(
                    (ele) => ele.PeriodicDetailID == PeriodicDetailID
                  );
                  obj.Status = 2;
                  return true;
                }
              }
            } else {
              GetDescriptionByID();
              showSaveSuccess();
            }
          }
        } else {
          showErrorMsg();
        }
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg();
        console.log(err);
      });
  };

  function convertColum(res) {
    if (res?.data?.data?.length) {
      let columnsList = [];
      const data = res.data.data;
      let InpEnable = getL0RoleID() == getUserDetails().RoleID;
      let ApproveBtnShow = true;
      const IsJobAssigner = data[0].isJobAssigner == 1;
      if (IsJobAssigner) {
        ApproveBtnShow = !(data[0].StatusName == "Approved");
      }

      let AllClosed = data.some((ele) => ele.IsAllClosed);

      localStorage.setItem("AllClosed", AllClosed);
      localStorage.setItem("IsJobAssigner", ApproveBtnShow);
      let DescriptionName = "Cell Sl.No";

      if (data[0]?.ConfigType == "Config_Type_1") {
        DescriptionName = "Work Description";
      }

      columnsList.push({
        title: "PeriodicScheduleID",
        data: "PeriodicScheduleID",
        visible: false,
        className: "false",
      });

      columnsList.push({
        title: `<p style ='width:150px;'> Component<p/>`,
        data: "ComponentName",
        width: "150px",
        className: `${data[0]?.ConfigType == "Config_Type_1" ? "" : "hide"}`,
        visible: data[0]?.ConfigType == "Config_Type_1",
        createdCell: function (td, cellData, rowData, row, col) {
          let Div = $(`<p>`);
          let tag = $(`<p>${cellData}</p>`);
          let Critical = $(`<small class ='criticalTag'>Critical</small>`);
          let CyclicCheck = $(
            `<small class ='criticalTag' style="background : #a89eff">Cyclic Check</small>`
          );

          let { IsCritical, IsCyclicCheck } = rowData;
          if (IsCritical) {
            $(Div).append(Critical);
          }
          if (IsCyclicCheck) {
            $(Div).append(CyclicCheck);
          }
          $(Div).append(tag);
          $(td).html(Div);
        },
      });
      columnsList.push({
        title: `<p style = 'width : 150px'>${DescriptionName}<p/>`,
        data: "DescriptionName",
        width: "150px ",
      });
      let uniqueList = makeUnique(data, ["EquipmentCode", "EquipmentDetailID"]);

      let EquipForSpare = [];
      uniqueList?.forEach((element, _) => {
        const { EquipmentCode, EquipmentDetailID } = element;

        EquipForSpare.push({
          key: EquipmentCode,
          value: EquipmentDetailID,
        });
      });

      let EquipmentDetailID = uniqueList[0].EquipmentDetailID;
      let Columns = [];
      //
      if (data[0]?.ConfigType == "Config_Type_1") {
        Columns = Config_Column_1(EquipmentDetailID);
      } else {
        Columns = Config_Column(data[0]?.ConfigType);
      }

      Columns.forEach((ele) => columnsList.push(ele));

      columnsList.push({
        title: "<p  style=`width:150px`>Lo Remarks </p>",
        data: "Remarks",
        createdCell: function (td, cellData, rowData, row, col) {
          let Remarks = $(
            `<textarea  class="form-control" style="width:150px"  ${
              InpEnable ? "" : "disabled"
            }>${cellData}</textarea>`
          );

          Remarks.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[`Remarks`] = value;
          });
          $(td).html(Remarks);
        },
      });
      columnsList.push({
        title: "Status",
        data: "StatusName",
        width: "60px",
        createdCell: function (td, cellData, rowData, row, col) {
          let Status = cellData;
          if (getL0RoleID() !== getUserDetails().RoleID) {
            if (rowData.InitialApproved == null) {
              Status = "Closed";
            } else {
              Status = rowData.InitialApproved ? "Approved" : "Rejected";
            }
          }
          let span = $(`<span class="StatusCls" > </span>`);
          span.append(GetPeriodicStatus(Status));

          $(td).html(span);
        },
      });

      columnsList.push({
        title: "Submit",
        data: "PeriodicCheckListMaintenanceID",
        width: "60px",
        visible: getL0RoleID() == getUserDetails().RoleID,
        className: `${getL0RoleID() == getUserDetails().RoleID ? "" : "hide"}`,

        createdCell: function (td, cellData, rowData, row, col) {
          let WarpDiv = $("<div>");
          let SubmitEnable = !(localStorage.getItem("AllClosed") == "true");
          // const { InitialApproved } = rowData;

          // if (
          //   getL0RoleID() == getUserDetails().RoleID &&
          //   (rowData.Status < 2 || rowData.IsShow) &&
          //   (InitialApproved == null || InitialApproved == 0)
          // ) {
          //   SubmitEnable = true;
          // }
          //SubmitEnable = true;
          let SubmitBtn = $(`<button type="button" class='btn icon-btn' ${
            SubmitEnable ? "" : "disabled"
          } title='Save'>
                <i class='fa fa-check' style="margin-top:10px"  ></i>
                </button>`);

          if (SubmitEnable) {
            SubmitBtn.on("click", async (e) => {
              let result = await handleSubmit(rowData);
              if (result) {
                UpdateStatus(e, rowData);
              }
            });
          }

          WarpDiv.append(SubmitBtn);

          $(td).html(WarpDiv);
        },
      });

      setUGSColumnList([...columnsList]);
      setUGSRowList([...data]);

      setPeriodicModel({
        PWTDate: data[0]?.PWTDate,
        PWTNumber: data[0]?.PWTNumber,
        ApprovalRemarks: data[0]?.ApprovalRemarks,
      });

      $(".PWTDate").val(data[0]?.PWTDate);
      $(".PWTNumber").val(data[0]?.PWTNumber);
    } else {
      setUGSRowList([]);
    }
  }

  const Config_Column_1 = () => {
    let InpEnable = getL0RoleID() == getUserDetails().RoleID;
    let div = $(`<div style ="width : 300px">`);
    let span = $(`<span style ="width : 300px ;display: flex;" >`);
    let ActualRatingHeading = $(
      '<p class= "row" ><span class ="col"> Status</span><span  class ="col">Actual Value</span></p>'
    );

    div.append(span);
    div.append(ActualRatingHeading);

    let Columns = [
      {
        title: "Rated Value",
        data: "RatedValue",
        width: "50px ",
      },
      {
        title: "<p style='width:100px'>UOM</p>",
        data: "UOM",
        width: "60px",
      },
      {
        title: div.html(),
        data: `EquipmentStatus`,
        // width: "100px",
        createdCell: function (td, cellData, rowData, row, col) {
          let WrapDiv = $("<div class='btn-group' style='width:200px'>");

          let ActualValueInp = "";
          let StatusValueInp = "";
          const ActualValue = rowData[`Actual`];
          const EquipmentStatus = rowData[`EquipmentStatus`] ?? "";
          const { MaxValue, MinValue } = rowData;
          let ActualValBColor = "";

          if (
            MaxValue > 0 &&
            MinValue > 0 &&
            rowData.InitialApproved !== null
          ) {
            ActualValBColor =
              MaxValue >= ActualValue && MinValue <= ActualValue
                ? ""
                : "badge-danger";
          }

          if (rowData.IsHasOption) {
            StatusValueInp = $(
              GetSelect(
                [
                  {
                    key: rowData.StatusOption1?.trim(),
                    value: rowData.StatusOption1?.trim(),
                  },
                  {
                    key: rowData.StatusOption2?.trim(),
                    value: rowData.StatusOption2?.trim(),
                  },
                ],
                EquipmentStatus?.trim(),
                !InpEnable,
                "m-r-5",
                "-"
              )
            );
          } else {
            StatusValueInp = $(
              `<input type="text"  class="form-control " ${
                InpEnable ? "" : "disabled"
              } value="${EquipmentStatus}"/>`
            );
          }
          StatusValueInp.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[`EquipmentStatus`] = value;
          });

          ActualValueInp = $(
            `<input type="number" style="margin-left: 5px;"  class="form-control ${ActualValBColor}" ${
              InpEnable ? "" : "disabled"
            } value="${ActualValue}"/>`
          );

          ActualValueInp.on("change", function (e) {
            e.preventDefault();

            const { value } = e.target;
            if (MaxValue > 0 && MinValue > 0) {
              if (MaxValue >= value && MinValue <= value) {
                $(e.target).removeClass("badge-danger");
              } else {
                $(e.target).addClass("badge-danger");
              }
            }
            rowData[`Actual`] = value;
          });

          WrapDiv.append(StatusValueInp);
          WrapDiv.append(ActualValueInp);

          $(td).html(WrapDiv);
        },
      },
    ];
    return Columns;
  };

  const Config_Column = (columnType) => {
    let InpEnable = getL0RoleID() == getUserDetails().RoleID;

    // Define column object lists based on the column type
    let ColumnObjList = [];
    if (columnType === "Config_Type_3") {
      ColumnObjList = [
        {
          name: "InitialNoLoadCondition",
          DisplayName: "Initial No Load Condition",
        },
        { name: "Time_1", DisplayName: "1 hrs" },
        { name: "Time_2", DisplayName: "2 hrs" },
        { name: "Time_3", DisplayName: "3 hrs" },
        { name: "Time_4", DisplayName: "4 hrs" },
        { name: "Time_5", DisplayName: "5 hrs" },
        {
          name: "FinalNoLoadCondition",
          DisplayName: "Final No Load Condition ",
        },
        { name: "BoostVoltage", DisplayName: "Boost Voltage" },
        { name: "SpecificGravity", DisplayName: "Specific Gravity" },
      ];
    } else if (columnType === "Config_Type_2") {
      ColumnObjList = [
        {
          name: "Details",
          DisplayName: "Details",
          disableCondition: (rowData) => {
            const { DescriptionName = "" } = rowData;
            let tempDescriptionName = DescriptionName.trim().toUpperCase();
            if (
              tempDescriptionName == "TIME" ||
              tempDescriptionName == "CURRENT" ||
              tempDescriptionName == "TOTALVOLTAGE"
            ) {
              return false;
            } else return true;
          },
        },
        {
          name: "CellVoltageFloatMode",
          DisplayName: "Cell Voltage Float Mode",
        },
        {
          name: "CellVoltageDischargeMode",
          DisplayName: "Cell Voltage Discharge Mode",
        },
        { name: "SpecificGravity", DisplayName: "Specific Gravity" },
      ];

    }

    let Columns = [];

    ColumnObjList.forEach((ele) => {
      const {
        name,
        DisplayName,
        disableCondition = () => {
          return true;
        },
      } = ele;

      Columns.push({
        title: `<span style="width:120px;display:block;">${DisplayName}</span>`,
        data: name,
        width: "150px",
        createdCell: function (td, cellData, rowData, row, col) {
          let WrapDiv = $("<div class='btn-group' style='width:120px'>");
          let Input = "";
          let CellValue = rowData[name];
          let isNotDisabled = disableCondition(rowData);

          Input = $(
            `<input type="text" style="margin-left: 5px;" class="form-control" ${
              InpEnable && isNotDisabled ? "" : "disabled"
            } value="${CellValue}"/>`
          );

          Input.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[name] = value;
          });

          WrapDiv.append(Input);
          $(td).html(WrapDiv);
        },
      });
    });

    return Columns;
  };

  function UpdateStatus(e, Data) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");

      if (StatusClsCell.length > 0) {
        Data.StatusName = "Closed";

        StatusClsCell.html(GetPeriodicStatus("Closed")); // You can format the value as needed
      }
    }
  }

  const handleSubmit = async (rowData, status, IsAllClosed = false) => {
    const {
      PeriodicDetailID,
      PeriodicCheckListMaintenanceID,
      EquipmentStatus,
      Actual,
      CurrentStatgeID,
      Remarks,
      AllJobs = "",
      Details,
      CellVoltageFloatMode,
      CellVoltageDischargeMode,
      SpecificGravity,
      InitialNoLoadCondition,
      Time_1,
      Time_2,
      Time_3,
      Time_4,
      Time_5,
      BoostVoltage,
      FinalNoLoadCondition,
    } = rowData;

    const obj = {
      PeriodicDetailID,
      PeriodicCheckListMaintenanceID,
      EquipmentStatus,
      Actual,
      isBreakDown: $("input.isBreakDown:checked").val(),
      SavedBy: GetLoginUserID(),
      Status: 2,
      PWTDate: $(".PWTDate").val(),
      PWTNumber: $(".PWTNumber").val(),
      CurrentStatgeID: CurrentStatgeID ?? 0,
      IsApproved: status,
      Remarks,
      ApprovalRemarks,
      Type: getL0RoleID() == getUserDetails().RoleID ? 1 : 2,
      IsAllClosed,
      AllJobs,
      Details,
      CellVoltageFloatMode,
      CellVoltageDischargeMode,
      SpecificGravity,
      InitialNoLoadCondition,
      Time_1,
      Time_2,
      Time_3,
      Time_4,
      Time_5,
      BoostVoltage,
      FinalNoLoadCondition,
    };
    let FilePaths = localStorage.getItem("FilePaths");
    let isAlreadyUploaded = localStorage.getItem("isAlreadyUploaded");

    if (FilePaths) {
      FilePaths = JSON.parse(FilePaths);
    } else FilePaths = [];

    const isL0Role = getL0RoleID() === getUserDetails().RoleID;
    let tableData = $("#PeriodicUGStation").DataTable().rows().data().toArray();
    let isAnyOpen = tableData.some((ele) => ele.StatusName == "Closed");
    const hasSelfie = FilePaths.some((ele) => ele.Type === "SELFIE");

    if (!(obj.isBreakDown == 116)) {
      obj.FileObj = JSON.stringify([]);
      SaveJobDetailsForUG(obj);

      return false;
    }

    if (isL0Role) {
      if (!obj.PWTDate && !obj.PWTNumber) {
        showWarningMsg("Please fill all mandatory fields");
        $(".PWTDate").addClass("border-danger");
        $(".PWTNumber").addClass("border-danger");
        setTimeout(function () {
          $(".PWTDate").removeClass("border-danger");
          $(".PWTNumber").removeClass("border-danger");
        }, 1000);
        window.scrollTo(0, 0);
        return false;
      }
    }

    if (isL0Role) {
      if (hasSelfie || isAnyOpen) {
        if (isAlreadyUploaded) {
          obj.FileObj = JSON.stringify([]);
          return await SaveJobDetailsForUG(obj);
        } else {
          obj.FileObj = JSON.stringify(FilePaths);
          localStorage.setItem("isAlreadyUploaded", 1);
          setPeriodicModel((pre) => ({
            ...pre,
            isAlreadyUploaded: true,
          }));
          return await SaveJobDetailsForUG(obj);
        }
      } else {
        showWarningMsg(
          "Please take a selfie. Then you can continue your work!"
        );
        window.scrollTo(0, 0);
        $(".TakeSelfie").addClass("btn-danger");

        setTimeout(function () {
          $(".TakeSelfie").removeClass("btn-danger");
        }, 1000);
      }
    } else {
      if (!isAlreadyUploaded) {
        obj.FileObj = JSON.stringify(FilePaths);
        SaveJobDetailsForUG(obj);
      }
    }
  };

  const getOverAllRemarks = () => {
    PeriodicMaintenance_GetOverAllRemarks(
      PeriodicCheckListMaintenanceID,
      EquipmentDetailId?.value
    )
      .then((res) => {
        if (res?.data?.data) {
          setPeriodicModel({ OverAllRemarksList: res.data.data });
        } else {
          setPeriodicModel({ OverAllRemarksList: [] });
        }
      })
      .catch((err) => console.log(err));
  };

  const saveSpare = (list) => {
    setSpareList(list);
  };
  const SaveSpareData = () => {
    var obj = {
      PeriodicCheckListMaintenanceID:
        PeriodicModel.PeriodicCheckListMaintenanceID,
      SavedBy: GetLoginUserID(),
      SpareList: JSON.stringify(spareList),
    };

    PeriodicMaintenance_SaveSpareData(obj)
      .then((res) => {
        hideLoading();
        if (res?.data?.isSuccess) {
          showSaveSuccess();
          navigate(-1);
        } else {
          console.log(res.data);
          showSaveError();
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };

  const updateJobStatus = (status) => {
    if (status == 0 && !ApprovalRemarks?.trim()) {
      showWarningMsg("Remarks is Required");
      $(".approveRemarks").addClass("border-danger");
      return false;
    }
    let tableData = $("#PeriodicUGStation").DataTable().rows().data().toArray();
    if (status == 3) {
      let isAnyOpen = tableData.every((ele) => ele.StatusName == "Closed");
      if (!isAnyOpen) {
        showWarningMsg("Please close all jobs.");
        return false;
      }
    }
    let statusName = "";

    if (status == 1) statusName = "approve";
    else if (status == 2) statusName = "reject";
    else statusName = "save";

    if (tableData?.length) {
      swal({
        title: "Are you sure?",
        text: `Do you want to ${statusName} this job`,
        icon: `warning`,
        buttons: true,
        dangerMode: status == 0,
      }).then((willDelete) => {
        if (willDelete) {
          handleSubmit(tableData[0], status, status == 3);
        }
      });
    }
  };

  const getCameraImagePath = (objString = "") => {
    if (objString) {
      let imageObj = JSON.parse(objString);
      let FilePaths = localStorage.getItem("FilePaths");
      if (FilePaths) {
        FilePaths = JSON.parse(FilePaths);
      } else FilePaths = [];
      let CopyFileObj = DeepCopy(FilePaths);
      if (!Array.isArray(CopyFileObj)) {
        CopyFileObj = [];
      }
      imageObj[0].Type = "SELFIE";
      CopyFileObj = CopyFileObj.concat(imageObj);

      localStorage.setItem("FilePaths", JSON.stringify(CopyFileObj));
      $("#CloseSelfiePeriodicModel").click();
    }
  };

  // const CameraModel = React.memo(WebCam,[]);
  // remarks and OverallClose
  const RemarksBtn = () => {
    let span = $("<span>");
    let btn = $(
      `<button class='btn btn-primary m-l-5'  data-bs-toggle="modal" data-bs-target="#OverAllRemarksComponent" title='Show OverAll Remarks' >`
    );
    let RemarksIcon = $(`<i class="fa fa-comments-o" aria-hidden="true"></i>`);

    let SaveBtn = $(`<button class='btn btn-primary m-l-5'  title='Save' >`);
    let SaveBtnIcon = $(`<i class="fa fa-floppy-o" aria-hidden="true"></i>`);
    SaveBtn.append(SaveBtnIcon);

    SaveBtn.on("click", () => {
      CloseAllJobs();
    });

    btn.on("click", (e) => {
      e.preventDefault();
      getOverAllRemarks();
    });

    btn.append(RemarksIcon);
    span.append(btn);
    if (
      getUserDetails().RoleID == getL0RoleID() &&
      !(localStorage.getItem("AllClosed") == "true")
    ) {
      span.append(SaveBtn);
    }
    return span;
  };

  const CloseAllJobs = () => {
    let tableData = $("#PeriodicUGStation").DataTable().rows().data().toArray();
    debugger
    let AllJobs = [];
    tableData.forEach((ele) => {
      const {
        PeriodicDetailID,
        EquipmentStatus,
        Actual,
        Remarks,
        Details,
        CellVoltageFloatMode,
        CellVoltageDischargeMode,
        SpecificGravity,
        InitialNoLoadCondition,
        Time_1,
        Time_2,
        Time_3,
        Time_4,
        Time_5,
        BoostVoltage,
        FinalNoLoadCondition,
      } = ele;
      AllJobs.push({
        PeriodicDetailID,
        EquipmentStatus,
        Actual,
        Remarks,
        Details,
        CellVoltageFloatMode,
        CellVoltageDischargeMode,
        SpecificGravity,
        InitialNoLoadCondition,
        Time_1,
        Time_2,
        Time_3,
        Time_4,
        Time_5,
        BoostVoltage,
        FinalNoLoadCondition,
      });
    });
    const FinalObj = tableData[0];
    FinalObj.AllJobs = JSON.stringify(AllJobs);
    let WarningMsg = "";
    if (AllJobs?.length) {
      WarningMsg = AllJobs.some((ele) => !ele.Actual || !ele.EquipmentStatus)
        ? "Some checklist values are missing.\nDo you want to close the job?"
        : "Do you want to close this job";
    }

    swal({
      title: "Are you sure?",
      text: WarningMsg,
      icon: `warning`,
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        handleSubmit(FinalObj, 3, true);
      }
    });
  };

  const OverAllRemarksComponent = () => {
    return (
      <>
        <div class="chats">
          {OverAllRemarksList?.length
            ? OverAllRemarksList.map((ele) => {
                const { DescriptionName } = ele;
                let manipulatedDesc = DescriptionName?.length
                  ? `${DescriptionName} - `
                  : "";

                return (
                  <>
                    <div class="chat-line">
                      <span class="chat-date">
                        {ele.EmployeeName} - {ele.StatusName}
                      </span>
                    </div>
                    <div class="chat chat-left">
                      <div class="chat-body">
                        <div class="chat-bubble">
                          <div class="chat-content">
                            <p className="text-black text-sm">
                              {ele.Remarks?.length > 0
                                ? manipulatedDesc + ele.Remarks
                                : `${manipulatedDesc}`}{" "}
                            </p>

                            <span
                              class="chat-time pull-right text-small"
                              style={{ fontSize: "8px" }}
                            >
                              {ele.FormattedDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-sm-3 mb-2 text-start">
              <button
                className="btn btn-success"
                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
                onClick={() => {
                  navigate(-1);
                  localStorage.setItem("NNR", 1);
                }}
              >
                <RiArrowGoBackLine /> Go Back
              </button>
            </div>
            <div className="col-sm-6 mb-2 text-end">
              {getUserDetails().RoleID !== getL0RoleID() &&
                localStorage.getItem("IsJobAssigner") == "true" && (
                  <button
                    className="btn btn-success m-l-5"
                    title="Approve seleted jobs"
                    onClick={(e) => updateJobStatus(1)}
                  >
                    <span>
                      <IoMdCheckmark /> Approve
                    </span>
                  </button>
                )}
              {getUserDetails().RoleID !== getL0RoleID() &&
                localStorage.getItem("IsJobAssigner") == "true" && (
                  <button
                    className="btn btn-danger m-l-5"
                    title="Reject seleted jobs"
                    onClick={() => updateJobStatus(0)}
                  >
                    <span>
                      <IoClose /> Reject
                    </span>
                  </button>
                )}

              <button
                data-bs-toggle="modal"
                data-bs-target="#SelfiePeriodicModel"
                className="btn Camera-btn btn-secondary TakeSelfie m-l-5"
                title="Take Selfie"
              >
                <span>
                  {" "}
                  <TbCameraSelfie /> Take Selfie
                </span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PTW Number<span className="text-danger"> *</span>
                </label>{" "}
                <input
                  className="form-control PWTNumber"
                  type="text"
                  name="PWTNumber"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  value={PWTNumber}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PTW Date <span className="text-danger"> *</span>
                </label>{" "}
                <input
                  className="form-control PWTDate"
                  type="date"
                  name="PWTDate"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  onChange={CommonHandleChange}
                  value={PWTDate}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  Equipment Code{" "}
                </label>{" "}
                <ReactMultiSelect
                  options={EquipmentDetails ?? []}
                  name="EquipmentDetailId"
                  value={EquipmentDetailId}
                  onChange={(e) => CommonHandleChange(e, "EquipmentDetailId")}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Remarks
                </label>{" "}
                <textarea
                  className="form-control OverAllRemarks approveRemarks"
                  name="ApprovalRemarks"
                  style={{ resize: "none" }}
                  rows={2}
                  disabled={getUserDetails().RoleID == getL0RoleID()}
                  onChange={CommonHandleChange}
                  // value={}
                >
                  {" "}
                </textarea>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="label" for="empCode">
                  Upload{" "}
                </label>{" "}
                <input
                  type="file"
                  className="form-control"
                  name="FileObj"
                  multiple
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group ">
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input isBreakDown"
                    type="radio"
                    name="isBreakDown"
                    id="inlineRadio1"
                    value="117"
                    checked={PeriodicModel.isBreakDown == 117}
                    disabled={getUserDetails().RoleID !== getL0RoleID()}
                    onChange={CommonHandleChange}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Under Breakdown
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input isBreakDown"
                    type="radio"
                    name="isBreakDown"
                    id="inlineRadio2"
                    checked={PeriodicModel.isBreakDown == 116}
                    disabled={getUserDetails().RoleID !== getL0RoleID()}
                    value="116"
                    onChange={CommonHandleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Running
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input isBreakDown"
                    type="radio"
                    name="isBreakDown"
                    id="inlineRadio2"
                    checked={PeriodicModel.isBreakDown == 115}
                    disabled={getUserDetails().RoleID !== getL0RoleID()}
                    value="115"
                    onChange={CommonHandleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Under Maintenance
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "-25px" }}>
            <HtmlTable
              // stateSave={false}
              IsASC
              FixedColums={{
                left: 1,
                right: 2,
              }}
              OrderTargetList={Array.from(
                { length: UGSColumnList.length - 6 },
                (_, i) => i + 4
              )}
              rowList={UGSRowList}
              columnList={UGSColumnList}
              id={`PeriodicUGStation`}
              SearchPostponedHtml={RemarksBtn}
              // addedPlugin={addedPlugin}
              tableReRender={TestNo}
              ordering={false}
              orderby={0}
            />
          </div>
          <div className="card card-body">
            {/* <h4 className='text-center'> Used Spare</h4> */}
            <SpareList
              IsAdd={getUserDetails().RoleID == getL0RoleID()}
              Equlist={EquipmentDetails}
              saveSpare={saveSpare}
              SelectedSpareList={spareList}
            ></SpareList>
            <br></br>
            {getUserDetails().RoleID == getL0RoleID() && (
              <div className="row d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary col-md-2 col-sm-2"
                  onClick={SaveSpareData}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <BootrapModel
            module={<WebCam HandleChange={getCameraImagePath} />}
            ModelTitle={"Selfie"}
            modelSize={"modal-lg"}
            CloseID={"CloseSelfiePeriodicModel"}
            Id={"SelfiePeriodicModel"}
          ></BootrapModel>

          <BootrapModel
            module={<OverAllRemarksComponent />}
            ModelTitle={"Over All Remarks"}
            modelSize={"modal-lg"}
            CloseID={"CloseOverAllRemarksComponent"}
            Id={"OverAllRemarksComponent"}
          ></BootrapModel>
        </div>
      </div>
    </>
  );
}

export default Depart_UG19StationPMPage;
