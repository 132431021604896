import React from 'react'
import PeriodicContextProvider from './PeriodicContextProvider'
import { PeriodicMaintance } from './Periodic'

export default function PeriodicMaintenanceModule() {
  return (
    <>
    <PeriodicMaintance /> 

    </>
  )
}
