import React, { useContext, useEffect, useState } from "react";
import Global from "../../services/Global";
import {
  EquipmentEnergyConsumption_GetDocumentID,
  RoutineShiftCheck_ReportGetByID,
} from "./ManualLogService";
import {
  CheckListHtmlTable,
  Dropdown,
  PageHeaderTitle,
} from "../../components";
import {
  Decrypt,
  RoutineChecksReportOtherCellColor,
  getYMDdate,
} from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";

export default function RoutineShiftCheckReport() {
  const initialCheckList = {
    StationID: "",
    FromDate: getYMDdate(),
    ToDate: "",
    StationName: "",
  };
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
  });
  const { getCookies } = useContext(AppContext);

  useEffect(() => {
    initialDropDown();
    return () => {
      setDropdownModel(initialCheckList);
    };
  }, []);

  const [RoutineChecksModel, setRoutineChecksModel] =
    useState(initialCheckList);
  const [RoutineChecksListModel, setRoutineChecksListModel] = useState([]);

  const columnNames = [
    {
      title: "S.No",
      data: "SNO",
    },
    {
      title: "Equipment Name",
      data: "RoutineEquipmentName",
    },
    {
      title: "A (06:00-14:00)",
      data: "FirstShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {
        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "FirstShiftResponse"
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "FirstShiftResponse"
          );
        }
      },
    },
    {
      title: "B (14:00-22:00)",
      data: "SecondShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {
        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "SecondShiftResponse"
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "SecondShiftResponse"
          );
        }
      },
    },
    {
      title: "C (22:00-06:00)",
      data: "ThirdShiftResponse",
      createdCell: (td, cellData, rowData, row, col) => {
        if (rowData.ResponseType == 58) {
          NumberTypeResponse(
            td,
            cellData,
            rowData,
            row,
            col,
            "ThirdShiftResponse"
          );
        } else {
          OtherCommonType(
            td,
            cellData,
            rowData,
            row,
            col,
            "ThirdShiftResponse"
          );
        }
      },
    },
    {
      title: "Remarks",
      data: "Remarks",
    },
    {
      title: "Response Type",
      data: "ResponseType",
      width: "60px",
      visible: false,
    },
  ];

  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;
      setRoutineChecksModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (name == "StationID") {
        let SelectedStation = DropdownModel?.StationList?.find(
          (x) => x.value == value
        );

        setRoutineChecksModel((pre) => ({
          ...pre,
          StationName: SelectedStation.key,
        }));
      }
    }
  };

  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = [];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    setDropdownModel((preState) => ({
      ...preState,
      StationList: FinalStationList,
    }));
    setRoutineChecksModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0].value,
      StationName: FinalStationList[0].key,
    }));
  };

  function NumberTypeResponse(td, cellData, rowData, row_1, col, objName = "") {
    let selectedValues = cellData.split(",");
    let row = $('<div class="row ParentDiv justify-content-center">');

    for (let i = 1; i < 4; i++) {
      let shiftDiv = $('<div class="col-4">');
      shiftDiv.text(i);
      if (selectedValues.includes(String(i))) {
        shiftDiv.addClass("selectedNumberDiv");
      } else {
        shiftDiv.addClass("numberDiv");
      }
      row.append(shiftDiv);
    }

    $(td).html(row);
  }

  function OtherCommonType(td, cellData, rowData, row_1, col, objName = "") {
    const TextType = {
      59: "ON,OFF",
      60: "OK,Not Ok",
      61: "W,NW",
      62: "Open,Close",
      63: "Local,Remote",
    };

    let ShowString = TextType[rowData.ResponseType]?.split(",") ?? [
      "ON",
      "OFF",
    ];

    let selectedValues = cellData;
    let row = $('<div class="row ParentDiv justify-content-center">');
    for (let i = 0; i < 2; i++) {
      let shiftDiv = $('<div class="col-4 commonRoutineChecksDiv">');
      shiftDiv.text(ShowString[i]);
      if (selectedValues == ShowString[i]) {
        shiftDiv.addClass("selectedOtherTypeDiv");
      } else {
        shiftDiv.addClass("otherTypeDiv");
      }

      row.append(shiftDiv);
    }

    $(td).html(row);
  }

  useEffect(() => {
    getReportUsingID();

    return () => {
      setRoutineChecksListModel([]);
    };
  }, [JSON.stringify(RoutineChecksModel)]);

  const getReportUsingID = () => {
    const { StationID, FromDate, ToDate } = RoutineChecksModel;
    if (StationID > 0 && FromDate) {
      RoutineShiftCheck_ReportGetByID(StationID, FromDate, ToDate)
        .then((res) => {
          if (res?.data?.data) {
            setRoutineChecksListModel(res.data.data);
          } else {
            setRoutineChecksListModel([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PDfCustomization = (doc) => {
    let tableBody = doc.content[1].table.body;
    tableBody.forEach((element, _index) => {
      if (_index > 0) {
        for (let i = 1; i < 4; i++) {
          let body = RoutineChecksReportOtherCellColor(element, i);
          element[i].table = {
            body: [body],
            layout: {
              // Set padding for cells
              padding: [5, 5, 5, 5],
            },
          };
        }
      }
    });

    let Temptable = doc.content[1].table;
    if (Temptable.body.length > 0) {
      for (var i = 0; i < Temptable.body.length; i++) {
        Temptable.body[i].pop();
      }
    }
    doc.content[1].table.widths = [50, 130, 90, 90, 90, 130];

    let footerColumns = {
      columns: [
        {
          text: "\nA = AUTO   M = MANUAL  W = WORKING NW = NOT WORKING ",
          bold: false,
        },
        // {
        //   text: "\n",
        // },
        {
          text: "\nSignature of In Charge:",
          alignment: "center",
        },
      ],
    };
    doc.content.push(footerColumns);
    let TechnicianRow = [];
    let EngineerRow = [];

    TechnicianRow.push({
      text: "Name of the Shift Technician",
      //   colSpan: ,
    });
    EngineerRow.push({
      text: "Name of the Shift Approver/Engineer",
      //   colSpan: 2,
    });

    let ShiftTechnician = RoutineChecksListModel?.[0]?.ShiftTechnician;
    let ShiftEngineer = RoutineChecksListModel?.[0]?.ShiftEngineer;

    let headerRow = [...doc.content[1].table.body[0]];
    headerRow = headerRow.slice(1);
    headerRow?.forEach(() => {
      TechnicianRow.push({ text: ShiftTechnician });
      EngineerRow.push({ text: ShiftEngineer });
    });

    doc.content[1].table.body.push(TechnicianRow);
    doc.content[1].table.body.push(EngineerRow);
  };

  return (
    <div>
      <PageHeaderTitle title={`Routine Shift Checks`}> </PageHeaderTitle>
      <form id="ReportCheckList">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={RoutineChecksModel.StationID}
                className=""
              />
            </div>
          </div>
          <div className="col-2">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              name="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              value={RoutineChecksModel.FromDate}
              onChange={(e) => {
                CommonHandleChange(e);
              }}
              max={RoutineChecksModel.ToDate}
            />
          </div>
        </div>
      </form>
      <CheckListHtmlTable
        FixedColums={{
          left: 2,
          right: 1,
        }}
        IsASC
        IsShowPDF
        title="Routine Shift Checks"
        rowList={RoutineChecksListModel}
        columnList={columnNames}
        id={`ReportRoutineCheckListTable`}
        PDfCustomization={PDfCustomization}
        StationName={RoutineChecksModel.StationName ?? ""}
        fromDate={RoutineChecksModel.FromDate ?? ""}
        pdfHeading="Routine Shift Checklist"
        DocumentNo={"UG/VAC&TVS/CAMC/002"}
        CurrentDate={RoutineChecksModel.FromDate ?? ""}
      // documentNO = {RoutineChecksModel ?? "" }
      />
    </div>
  );
}
