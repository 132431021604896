import React, { useContext, useEffect, useState } from "react";
import { ReactTreeView, Dropdown } from "../../../components";
import $ from "jquery";

import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../../components/MultiSelect/ReactMultiSelect";
import { AuditContext } from "./AuditContextProvider";
import {
  findDepartmentById,
  GetLoginUserID,
} from "../../../services/CommonService";
import {
  AuditingLevelConfig_GetListByID,
  AuditingLevelConfig_GetSubModuleListByDepartmentID,
  AuditingLevelConfigMaster_CreateUpdate,
  AuditingLevelConfig_GetAuditingListByTaskTypeID,
} from "./AuditingLevelServices";
import makeAnimated from "react-select/animated";

import { COOKIES } from "../../../services/Global";
import {
  showSaveSuccess,
  showSaveError,
  showErrorMsg,
} from "../../../components";
// export const AuditingLevelConfigCreate = (prop) => {

function AuditingLevelConfigCreate(props) {
  const animatedComponents = makeAnimated();
  const { AuditingLevelConfigID, Render, setGridRender } = props;

  const { DropdownModel, setDropdownModel } = useContext(AuditContext);
  const [AuditingLevelList, setAuditingLevelList] = useState([]);
  const [TaskTypeList, setTaskTypeList] = useState([]);
  const { DepartmentList, YearList } = DropdownModel;
  const initialEquipmentState = {
    AuditingLevelConfigID: 0,
    DepartmentID: 0,
    DepartmentName: "",
    AuditingLevel: 0,
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
    RoleIds: "",
  };
  const initialerrorEquipmentState = {
    DepartmentID: "",
    AuditingLevel: "",
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
  };
  const [AuditingModel, setAuditingModel] = useState(initialEquipmentState);

  const [error, setformError] = useState(initialerrorEquipmentState);

  useEffect(() => {
    AuditingLevelConfig_GetList();
  }, [Render]);

  const AuditingLevelConfig_GetList = async () => {
    if (
      AuditingLevelConfigID == 0 ||
      AuditingLevelConfigID == null ||
      AuditingLevelConfigID == "" ||
      AuditingLevelConfigID == undefined
    ) {
      setAuditingModel(initialEquipmentState);
      onSelect();
    }
    await AuditingLevelConfig_GetListByID(AuditingLevelConfigID).then((res) => {
      if (
        res != null &&
        res.data.data.auditingLevelConfigList != null &&
        res.data.data.auditingLevelConfigList.length > 0
      ) {
        const auditingLevelConfigList =
          res.data.data.auditingLevelConfigList[0];
        setAuditingModel(auditingLevelConfigList);

        onSelect(res.data.data.auditingLevelConfigList[0]?.DepartmentID);
      }
      if (
        res != null &&
        res.data.data.prefixNamelist != null &&
        res.data.data.prefixNamelist.length > 0
      ) {
        setAuditingLevelList([...res.data.data.prefixNamelist]);
      }
      
      if (
        res != null &&
        res.data.data.taskTypeList != null &&
        res.data.data.taskTypeList.length > 0
      ) {
        setTaskTypeList([...res.data.data.taskTypeList]);
      }

      if (
        res != null &&
        res.data.data.selectedTaskTypeList != null &&
        res.data.data.selectedTaskTypeList.length > 0
      ) {
        setAuditingModel((PreviousState) => ({
          ...PreviousState,
          TaskType: res.data.data.selectedTaskTypeList[0],
        }));
      }

      if (
        res != null &&
        res.data.data.selectedPrefixNamelist != null &&
        res.data.data.selectedPrefixNamelist.length > 0
      ) {
        setAuditingModel((PreviousState) => ({
          ...PreviousState,
          AuditingLevel: res.data.data.selectedPrefixNamelist,
        }));
      } else {
        //   setGridModel([]);
      }
    });
  };
  const GetAuditingListByTaskTypeID = async (TaskTypeID) => {
    await AuditingLevelConfig_GetAuditingListByTaskTypeID(
      TaskTypeID,
      AuditingModel?.DepartmentID
    ).then((res) => {
      if (
        res != null &&
        res.data.data.prefixNamelist != null &&
        res.data.data.prefixNamelist.length > 0
      ) {
        setAuditingLevelList([...res.data.data.prefixNamelist]);
      } else {
        setAuditingLevelList([]);
      }
    });
  };

  const GetTaskListByDepartmentID = async (DepartmentID) => {
    await AuditingLevelConfig_GetSubModuleListByDepartmentID(DepartmentID).then(
      (res) => {
        
        if (
          res != null &&
          res.data.data.taskTypeList != null &&
          res.data.data.taskTypeList.length > 0
        ) {
          setTaskTypeList([...res.data.data.taskTypeList]);
        } else {
          setTaskTypeList([]);
        }
      }
    );
  };

  const onSelect = (id = 0, node) => {
    if (DepartmentList?.length) {
      let obj = findDepartmentById(DepartmentList, id);
      // if (!Array.isArray(obj.children)) {
      if (obj) {
        const DepartList = [...DepartmentList];
        // DepartList[0].name = obj.name;
        // DepartList[0].id = "Selected_" + obj.id;
        setDropdownModel((pre) => ({
          ...pre,
          DepartmentList: DepartList,
        }));
        // node?.tree?.closeAll();

        setAuditingModel((PreviousState) => ({
          ...PreviousState,

          DepartmentID: obj.id,
          DepartmentName: obj.name,
          TaskType: "",
          AuditingLevel: "",
        }));

        GetTaskListByDepartmentID(obj.id);
      }
    }
  };

  const CommonHandleChange = (event, name) => {
    if (name == "TaskType") {
      setAuditingModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
        AuditingLevel: "",
      }));

      GetAuditingListByTaskTypeID(event?.value);
    } else if (name == "AuditingLevel") {
      var RoleIds = event.map((x) => {
        return x.value;
      });

      setAuditingModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
        RoleIds: RoleIds,
      }));
    } else {
      setAuditingModel((PreviousState) => ({
        ...PreviousState,
        [name]: event.target.value,
      }));
    }
  };

  const onSubmit = (data) => {
    data.preventDefault();

    console.log(AuditingModel);
    var myerror = error;
    var flag = true;
    if (
      AuditingModel.DepartmentID == null ||
      AuditingModel.DepartmentID == "" ||
      AuditingModel.DepartmentID == 0
    ) {
      myerror.DepartmentID = "Department is Required";
      flag = false;
    } else {
      myerror.DepartmentID = "";
    }
    if (AuditingModel.Frequency == null || AuditingModel.Frequency == "") {
      myerror.Frequency = "Frequency is Required";
      flag = false;
    } else {
      myerror.Frequency = "";
    }
    if (AuditingModel.TaskType == null || AuditingModel.TaskType == "") {
      myerror.TaskType = "Task Type is Required";
      flag = false;
    } else {
      myerror.TaskType = "";
    }
    if (
      AuditingModel.PercentageofJob == null ||
      AuditingModel.PercentageofJob == ""
    ) {
      myerror.PercentageofJob = "Percentage of Jobs is Required";
      flag = false;
    } else {
      myerror.PercentageofJob = "";
    }
    if (
      AuditingModel.AuditingLevel == null ||
      AuditingModel.AuditingLevel == ""
    ) {
      myerror.AuditingLevel = "Auditing Level is Required";
      flag = false;
    } else {
      myerror.AuditingLevel = "";
    }

    setformError({ ...myerror });

    if (flag) {
      var USserID = GetLoginUserID();
      var RoleIds = AuditingModel.AuditingLevel.map((x) => {
        return x.value;
      });

      var AuditingLevelConfigModel = [
        {
          AuditingLevelConfigID: AuditingModel.AuditingLevelConfigID, // AuditingLevelConfigID is not defined in your example
          TypeTypeID: Number(AuditingModel.TaskType.value),
          DepartmentID: Number(AuditingModel.DepartmentID),
          JobSendDuration: Number(AuditingModel.Frequency),
          JobPercentageVisable: Number(AuditingModel.PercentageofJob),
          RoleIds: Array.isArray(RoleIds) ? RoleIds.join(",") : "", // Convert array to comma-separated string
          CreatedBy: USserID,
        },
      ];
      AuditingLevelConfigMasterCreateUpdate(AuditingLevelConfigModel[0]);
      setGridRender();
    }
  };

  const AuditingLevelConfigMasterCreateUpdate = async (
    AuditingLevelConfigModel
  ) => {
    await AuditingLevelConfigMaster_CreateUpdate(AuditingLevelConfigModel)
      .then((res) => {
        // hideLoading();
        if (res.isSuccess) {
          showSaveSuccess();
          $("#AuditingLevelConfigurationClose").trigger("click");
        } else {
          showErrorMsg();
        }
      })
      .catch((err) => {
        // hideLoading();
        console.log(err);
        showSaveError();
      });
  };

  return (
    <>
      {" "}
      <form id="AddOrEditEmployee" onSubmit={onSubmit}>
        <div className="row">
          <input
            type="text"
            className="form-control hide"
            placeholder="AuditingLevelConfigID"
            value={AuditingModel.AuditingLevelConfigID}
            onChange={(e) => CommonHandleChange(e, "AuditingLevelConfigID")}
          />

          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="label">
                Department Name<span className="text-danger"> *</span>{" "}
              </label>
              <br />
              <ReactTreeView
                data={DepartmentList}
                onSelect={onSelect}
                openByDefault={true}
                isSearchNeed={false}
                width={300}
              />
              {AuditingModel.DepartmentName != "" && (
                <span className="text-black mt-1 mr-1">
                  {" "}
                  Selected Department is {AuditingModel.DepartmentName}{" "}
                </span>
              )}

              <span className="text-danger mt-1"> {error.DepartmentID} </span>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="row">
              {" "}
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Task Type <span className="text-danger"> *</span>{" "}
                  </label>

                  <ReactMultiSelect
                    options={TaskTypeList}
                    // isMulti
                    // allowSelectAll
                    value={AuditingModel.TaskType}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    // components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => CommonHandleChange(e, "TaskType")}
                  />
                  <span className="text-danger mt-1"> {error.TaskType} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Frequency in (Days) <span className="text-danger"> *</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Frequency in (Days)"
                    value={AuditingModel.Frequency}
                    onChange={(e) => CommonHandleChange(e, "Frequency")}
                    maxLength={"5   "}
                    onKeyDown={(e) => {
                      // Allow: backspace, delete, tab, escape, enter
                      if (
                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                        // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
                        (e.keyCode === 65 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+A
                        (e.keyCode === 67 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+C
                        (e.keyCode === 86 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+V
                        (e.keyCode === 88 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+X
                        // Allow: home, end, left, right
                        (e.keyCode >= 35 && e.keyCode <= 39)
                      ) {
                        // Let it happen, don't do anything
                        return;
                      }
                      // Ensure that it is a number and stop the keypress
                      if (
                        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                        (e.keyCode < 96 || e.keyCode > 105)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) => {
                      // Repeating the same logic as onKeyDown
                      if (
                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                        (e.keyCode === 65 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+A
                        (e.keyCode === 67 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+C
                        (e.keyCode === 86 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+V
                        (e.keyCode === 88 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+X
                        (e.keyCode >= 35 && e.keyCode <= 39)
                      ) {
                        return;
                      }
                      if (
                        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                        (e.keyCode < 96 || e.keyCode > 105)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <span className="text-danger mt-1"> {error.Frequency} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Percentage of Jobs <span className="text-danger"> *</span>{" "}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Percentage of Job"
                    value={AuditingModel.PercentageofJob}
                    onChange={(e) => CommonHandleChange(e, "PercentageofJob")}
                    maxLength={"3"}
                    onKeyDown={(e) => {
                      // Allow: backspace, delete, tab, escape, enter
                      if (
                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                        // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
                        (e.keyCode === 65 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+A
                        (e.keyCode === 67 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+C
                        (e.keyCode === 86 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+V
                        (e.keyCode === 88 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+X
                        // Allow: home, end, left, right
                        (e.keyCode >= 35 && e.keyCode <= 39)
                      ) {
                        // Let it happen, don't do anything
                        return;
                      }
                      // Ensure that it is a number and stop the keypress
                      if (
                        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                        (e.keyCode < 96 || e.keyCode > 105)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) => {
                      // Repeating the same logic as onKeyDown
                      if (
                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                        (e.keyCode === 65 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+A
                        (e.keyCode === 67 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+C
                        (e.keyCode === 86 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+V
                        (e.keyCode === 88 &&
                          (e.ctrlKey === true || e.metaKey === true)) || // Ctrl+X
                        (e.keyCode >= 35 && e.keyCode <= 39)
                      ) {
                        return;
                      }
                      if (
                        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                        (e.keyCode < 96 || e.keyCode > 105)
                      ) {
                        e.preventDefault();
                      }
                      // Limit input to less than 100
                      const value = parseInt(e.target.value + e.key, 10);
                      if (value >= 100) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <span className="text-danger mt-1">
                    {" "}
                    {error.PercentageofJob}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Auditing Level <span className="text-danger"> *</span>{" "}
                  </label>

                  <ReactMultiSelect
                    options={AuditingLevelList}
                    isMulti
                    allowSelectAll
                    value={AuditingModel.AuditingLevel}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => CommonHandleChange(e, "AuditingLevel")}
                  />

                  <span className="text-danger mt-1">
                    {" "}
                    {error.AuditingLevel}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-10 col-sm-6"></div>
              <div className="col-md-2 ">
                <button
                  className="btn btn-primary mt-4 pull-right"
                  onClick={onSubmit}
                >
                  <i className="fa fa-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default AuditingLevelConfigCreate;
