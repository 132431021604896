import React from "react";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import { CollapseTable } from "../../components/CollapseTable";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { Dropdown, Select } from "../../components/Dropdown";

import {
  showDeleteError,
  showSaveError,
  showSaveSuccess,
} from "../../components/ToastMsg";
import {
  showWarningMsg,
  showErrorMsg,
  showDeleteSuccess,
} from "./../../components/ToastMsg";
import $ from "jquery";
import Global from "../../services/Global";

import {
  GetLoginUserID,
  getPrivilege,
  Decrypt,
  DeepCopy,
} from "../../services/CommonService";
import { ReactMultiSelect } from "../../components";

const _masterDetailService = new MasterDetailService();

const AddForm = ({ onSubmit, editData, currentMasterTab }) => {
  const [stationErr, setStationErr] = useState(false);
  const [equipmentErr, setEquipmentErr] = useState(false);
  const [departmentErr,setDepartmentErr]=useState(false)
  const [stationList, setStationList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const { Set, getCookies } = useContext(AppContext);
  const [department,setDepartmnet] = useState([])
  const [equipmentId,setEquipmentId] = useState()
  const [equipentName,SetEquipentName] = useState()
  useEffect(() => {
    getStationList();
    getEquipmentList();
    stationErr && setStationErr(false);
    equipmentErr && setEquipmentErr(false);
    departmentErr && setDepartmentErr (false);
    clearForm();
    GetDepartmentList()
  }, [currentMasterTab]);
  
  useEffect(() => {
    if (editData != null) {
      $("[name=stationCode]").val(`${editData.StationID}`);

      setEquipmentId(editData.EquipmentID)
     console.log(editData.EquipmentID,'edfcedwfcedrfc')
      // $("[name=equipmentName1]").val(`${editData.EquipmentID}`);
      $("[name=DepartmentID]").val(`${editData.DepartmentID}`)
    }
  }, [editData]);

  const clearForm = () => {
    $("[name=stationCode]").val("0");
    // $("[name=equipmentName1]").val("0");
    setEquipmentId(0)
    $("[name=DepartmentID]").val("0")
  };

  // get Station list
  const getStationList = () => {
    _masterDetailService.GetStationList().then((response) => {
      console.log(response);
      if (response != null && response.data.length > 0) {
        var dataLst = [];
        response.data.map((v) => {
          dataLst.push({ key: v.stationCode, value: v.stationID });
        });
        setStationList(dataLst);
      }
    });
  };
  //GetDepartmentList
  const GetDepartmentList = () => {
    _masterDetailService.MasterConfig_GetDepartmentListForEquipmentManager().then((response) => {
    
      if (response?.data?.data?.length) {
        const {data}=response.data
        var dataLst = [];
        const itemsWithoutChildren = data.filter(
          (item) => !data.some((child) => child.ParentId === item.DepartmentID)
        );
   
        itemsWithoutChildren.map((v) => {
          dataLst.push({ key: v.ConcatenatedDepartments, value: v.DepartmentID });
        });
        setDepartmnet(dataLst);
      }
    });
  };

  //get equipment list
  const getEquipmentList = () => {
    _masterDetailService.GetEquipmentList(getCookies(Global.COOKIES.EmployeeID)).then((response) => {
      console.log(response.data)
      if (response?.data?.data?.length) {
        const {data}=response.data
        var dataLst = data != null && data.length>0 &&
        data.map((v) => {
          // dataLst.push({ key: v.EquipmentName, value: v.EquipmentID });
          return {
            label: v.EquipmentName,
            value: v.EquipmentID,
          };
          
        });
        setEquipmentList(dataLst);
      }
    });
  };

  //validate form
  const validate = (e) => {
    e.preventDefault();
    
    var stationCode = e.target.stationCode.value;
    var equipmentName = e.target.equipmentName1.value;
    var departmentId = e.target.DepartmentID.value;
    var statonError = false;
    var equipmentError = false;
    var departmentErr=false

    if (Number(stationCode) == 0) {
      statonError = true;
      setStationErr(true);
    } else {
      statonError = false;
      setStationErr(false);
    }

    if (Number(equipmentName) == 0) {
      equipmentError = true;
      setEquipmentErr(true);
    } else {
      equipmentError = false;
      setEquipmentErr(false);
    }

    if(Number(departmentId)==0){
      departmentErr=true
      setDepartmentErr(true)
    }
    else{
      departmentErr=false
      setDepartmentErr(false)
    }

    if (!statonError && !equipmentError && !departmentErr) {
      var data = {
        EquipmentManagerID: Number(e.target.equipmentManagerID.value),
        EquipmentID: Number(equipmentName),
        StationID: Number(stationCode),
        DepartmentId:Number(departmentId),
        SavedBy: GetLoginUserID(),
      };
      onSubmit(data, clearForm);
    }
  };
  const HandleEquipmentNameChange = (e) => {
    setEquipmentId(e);
  }

  return (
    <div className="mb-3">
       {getPrivilege().includes("MASTERCREATE") &&
      <form onSubmit={validate}>
        <div className="row d-flex justify-content-end">
          {/* Equipment Manager ID*/}
          <input
            type="text"
            className="form-control"
            placeholder="Parameter ID"
            name="equipmentManagerID"
            value={editData != null ? editData.EquipmentManagerID : 0}
            hidden
          />
          {/* Station Code*/}
          <div className="col-3">
            <label className="col-form-label">
              Station Code <span className="text-danger">*</span>
            </label>
            <Dropdown
              placeholder={"--Select--"}
              name={"stationCode"}
              data={stationList}
              initialVal={0}
            />
            {stationErr ? (
              <p className="text-danger mt-1">Station code is required</p>
            ) : (
              <></>
            )}
          </div>
          {/* Equipment Name*/}
          <div className="col-3">
            <label className="col-form-label">
              Equipment Name <span className="text-danger">*</span>
            </label>
            <ReactMultiSelect
              placeholder={"--Select--"}
              name={"equipmentName1"}
              options={equipmentList}
              initialVal={0}
              onChange={HandleEquipmentNameChange}
              value={equipmentId}
            />

            {equipmentErr ? (
              <p className="text-danger mt-1">Equipment name is required</p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-3">
          <label className="col-form-label">
              Department <span className="text-danger">*</span>
            </label>
          <Dropdown
            data={department}

           // value={FilterModel.DepartmentID}
           placeholder={"--Select--"}
            name={"DepartmentID"}
            initialVal={0}
          />
           {departmentErr ? (
              <p className="text-danger mt-1">Department name is required</p>
            ) : (
              <></>
            )}
        </div>
          {getPrivilege().includes("MASTERCREATE") &&
          <div className="col-2" style={{ marginTop: "2.5rem" }}>
            <AddButton />
          </div>
         } 
        </div>
      </form>
}
    </div>
  );
};

export const EquipmentManager = ({ TabID, TabValue }) => {
  //variables
  const { showLoading, hideLoading,GetLoginStationBaseOnDep,GetLoginStationTypes,handleGlobalStationName } = useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [epqtLocations, setEqptLocations] = useState(null);
  const [epqtArea, setEqptArea] = useState(null);
  const { currentMasterTab, getCookies } = useContext(AppContext);

  const [DropdownModel,setDropdownModel] = useState([])
  const [FilterModel,setFilterModel] = useState({
    StationID: {},
    DepartmentID : 0,
    StationTypeID : 0
  })

  //Equipment Managet table columns
  const TableColumns = [
    {
      className: "dt-control",
      orderable: false,
      data: null,
      defaultContent: "",
    },
    { title: "Equipment Manager ID", className: "hide" },
    { title: "Station ID", className: "hide" },
    { title: "Station Code" },
    { title: "Equipment Type" },
    { title: "Equipment ID", className: "hide" },
    { title: "Equipment Name" },
    { title: "Equipment Short Name" },

    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        if (getPrivilege().includes("MASTEREDIT")) {
          b = $(`<button class='btn icon-btn' title='Edit'>
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
            onEditClick(rowData[1]);
            window.scroll(0, 0);
          });
        }
        var c = "";
        if (getPrivilege().includes("MASTERDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
          <i class='fa fa-trash m-l-15'></i>
        </button>`).on("click", function () {
            onDeleteClick(rowData[1]);
          });
        }

        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  useEffect(() => {
    if (TabID == TabValue) {
      getEqptLocations();
      getEqptArea();
    }
  }, [currentMasterTab]);

  useEffect(() => {
    
    getStationList()
  },[])


  useEffect(() => {
    getTableData(0, 0);
  },[FilterModel.StationID,FilterModel.DepartmentID,FilterModel?.StationTypeID])



  //functions
  const getTableData = (equipMngrID, isDelete) => {
    // showLoading();
    if(FilterModel?.StationID?.value && FilterModel.DepartmentID){
    _masterDetailService
      .GetEquipmentManagerDetails(
        equipMngrID,
        isDelete,
        getCookies(Global.COOKIES.EmployeeID),
        FilterModel?.StationID?.value,
        FilterModel.DepartmentID,
      )
      .then((response) => {
        // hideLoading();
        if (
          response != null &&
          response.isSuccess &&
          response.data != null &&
          response.data.length > 0
        ) {
          var rowList = [];

          var stationList = Decrypt(getCookies(Global.COOKIES.Stations));

          let stationIds = stationList.map((x) => x.StationID);

          response.data.map((v, i) => {
            if (stationIds.includes(v.StationID)) {
              var row = [];
              row.push("");
              row.push(v.EquipmentManagerID);
              row.push(v.StationID);
              row.push(v.StationCode);
              row.push(v.SystemTypeName);
              row.push(v.EquipmentID);
              row.push(v.EquipmentName);
              row.push(v.EquipmentShortName);
              row.push(v.DepartmentID);
              row.push("");
              rowList.push(row);
            }
          });
          setTableRows(rowList);
        }
      });
    }else{
      setTableRows([])
    }
  };

  const onSubmit = (data, clearForm) => {
    debugger
    //check already exists
    var isExists = [];
    if (data.EquipmentManagerID == 0) {
      isExists = tableRows.find(
        (v) =>
          Number(v[2]) == data.StationID && Number(v[5]) == data.EquipmentID
      );
    } else {
      isExists = tableRows.find(
        (v) =>
          Number(v[2]) == data.StationID &&
          Number(v[5]) == data.EquipmentID &&
          Number(v[1]) != data.EquipmentManagerID &&
          Number(v[7]) == data.DepartmentId
      );
    }

    if (isExists == null || isExists.length == 0) {
      showLoading();
      var data = {
        EquipmentManagerID: data.EquipmentManagerID,
        EquipmentID: data.EquipmentID,
        StationID: data.StationID,
        DeptId: data.DepartmentId,
        SavedBy: GetLoginUserID(),
      };

      _masterDetailService.AddOrUpdateManager(data).then((response) => {
        hideLoading();
        if (response == null || response == 0) {
          showSaveError();
        } else if (!response.isSuccess) {
          showSaveError();
        } else {
          getTableData(0, 0);
          clearForm();
          showSaveSuccess();
        }
      });
    } else {
      showWarningMsg("Equipment name already exists for the selected station");
    }
  };

  const onDeleteClick = (id) => {
    _masterDetailService
      .GetEquipmentManagerDetails(
        id,
        true,
        getCookies(Global.COOKIES.EmployeeID)
       
      )
      .then((res) => {
        if (res != null && res.isSuccess) {
          showDeleteSuccess();
          getTableData(0, 0);
        } else {
          showDeleteError();
        }
      });
  };

  const onEditClick = (id) => {
    _masterDetailService
      .GetEquipmentManagerDetails(id, 0, getCookies(Global.COOKIES.EmployeeID))
      .then((response) => {
        if (
          response != null &&
          response.isSuccess &&
          response.data != null &&
          response.data.length > 0
        ) {
          debugger
          const data =DeepCopy(response.data[0])
          data.EquipmentID = {
            label :response.data[0].EquipmentName,
            value : response.data[0].EquipmentID
          }
          setEditData(data);
        } else {
          showErrorMsg("Failed to fetch record");
        }
      });
  };

  const getEqptLocations = () => {
    _masterDetailService.GetEqptLocations().then((response) => {
      if (response != null && response.data.length > 0) {
        var locatios = [];
        response.data.map((v) => {
          locatios.push({ key: v.equipLocationName, value: v.equipLocID });
        });
        setEqptLocations(locatios);
      }
    });
  };

  const getEqptArea = () => {
    _masterDetailService.GetEqptAreaList().then((response) => {
      if (response != null && response.data.length > 0) {
        var locatios = [];
        response.data.map((v) => {
          locatios.push({ key: v.areaName, value: v.areaID });
        });
        setEqptArea(locatios);
      }
    });
  };

  
  const getStationList = () => {
    let stationList = GetLoginStationBaseOnDep();
    let stationTypes = GetLoginStationTypes();
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));

    let Stations = [];
    let Types = [];
    let Department = [];

    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({
          key: data.label,
          ...data,
        });
      });

      stationTypes.map((data) => {
        Types.push({
          key: data.label,
          ...data,
        });
      });

    }

    if (DepartmentList?.length) {
      const itemsWithoutChildren = DepartmentList.filter(
        (item) => !DepartmentList.some((child) => child.parentId === item.id)
      );

      itemsWithoutChildren.map((data) => {
        Department.push({ value: data.id, key: data.name, Type: data.Type });
      });
      let FilStations = [];

        FilStations = Stations.filter((ele) => {
          return  ele.CorridorID == Types[0].value && ele.DepartmentID == Department[0].value
        })

      setDropdownModel((pre) => ({
        ...pre,
        StationList: FilStations,
        FilterStationList: Stations,
        StationTypes: Types,
        DepartmentList: Department,
        FilterDepartmentList: Department,
      }));

      setFilterModel((pre) => ({
        ...pre,
        DepartmentID:Department?.[0].value ?? 0,
        StationTypeID:Types?.[0].value ?? 0,
        StationID:FilStations?.[0] ?? {},
      }));
      handleGlobalStationName(FilStations?.[0]?.label ?? "");
    }
  };

  const HandleChange = (e, cusName) => {
    if (!cusName) {
      const { value, name } = e.target;
      setFilterModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      cusName = name;
    } else {
      setFilterModel((pre) => ({
        ...pre,
        [cusName]: e,
      }));
    }

    if (cusName == "StationID") {
      handleGlobalStationName(e.label ?? "");
    }

    if (["StationTypeID", "DepartmentID"].includes(cusName)) {
      const { value } = e.target;

      const StationTypeID =
        cusName == "StationTypeID" ? value : FilterModel.StationTypeID;
      const DepartmentID =
        cusName == "DepartmentID" ? value : FilterModel.DepartmentID;

      // const fineDept = DropdownModel?.DepartmentList?.find(
      //   (ele) => ele.value == DepartmentID
      // );
      const finedType = DropdownModel?.StationTypes?.find(
        (ele) => ele.value == StationTypeID
      );

      if (DepartmentID && finedType) {
        const FilStations = DropdownModel?.FilterStationList.filter(
          (ele) =>
            ele.CorridorID == finedType.value && ele.DepartmentID == DepartmentID
        );

        setDropdownModel((pre) => ({
          ...pre,
          StationList: FilStations,
        }));

        setFilterModel((pre) => ({
          ...pre,
          StationID: FilStations?.[0] ?? {},
        }));

        handleGlobalStationName(FilStations?.[0]?.label ?? "");
      } else {
        setDropdownModel((pre) => ({
          ...pre,
          StationList: [],
        }));

        setFilterModel((pre) => ({
          ...pre,
          StationID: {},
        }));
        handleGlobalStationName("");
      }
    }
  };



  return (
    <div>
     {getPrivilege().includes("MASTERCREATE") && (
        <AddForm
          onSubmit={onSubmit}
          editData={editData}
          currentMasterTab={currentMasterTab}
        />
    )}

      <div className="row">
        <div className="col-3">
          <Dropdown
            data={DropdownModel?.DepartmentList ?? []}
            value={FilterModel.DepartmentID}
            placeholder={"Department"}
            name={"DepartmentID"}
            HandleChange={HandleChange}
          >
            {" "}
          </Dropdown>
        </div>
        <div className="col-2">
          <Dropdown
            data={DropdownModel?.StationTypes ?? []}
            value={FilterModel.StationTypeID}
            name={"StationTypeID"}
            placeholder={"Station Types"}
            HandleChange={HandleChange}
          >
            {" "}
          </Dropdown>
        </div>
        <div className="col-2">
          <ReactMultiSelect
            options={DropdownModel?.StationList ?? []}
            value={FilterModel.StationID}
            name={"StationID"}
            placeholder={"Stations"}
            onChange={(e) => HandleChange(e, "StationID")}
          >
            {" "}
          </ReactMultiSelect>
        </div>
      </div>

      {epqtLocations != null &&
      epqtArea != null &&
      epqtLocations.length > 0 &&
      epqtArea.length > 0 ? (
        <CollapseTable
          columnList={TableColumns}
          rowList={tableRows}
          id={"EquipmentManagerTbl"}
          Locations={epqtLocations}
          Area={epqtArea}
        />
      ) : (epqtLocations != null && epqtLocations.length == 0) ||
        (epqtArea != null && epqtArea.length == 0) ? (
        <p className="text-center text-danger m-5">
          Station code or Equipment name is not found
        </p>
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};
