import Axios from "../../services/AxiosService";

const axios = new Axios();
const AuditingProcess_GetGridData = async (RoleID,DepartmentID) => {
    return await axios.get(
        `AuditingProcess/AuditingProcess_GetGridData?RoleID=${RoleID}&DepartmentID=${DepartmentID}`
    );
}
const AuditingProcess_Getdropdown = async () => {
    return await axios.get(
        `AuditingProcess/AuditingProcess_Getdropdown`
    );
}
const AuditingProcess_GetDetailsByID = async (
    Type, ID
) => {
    return await axios.get(
        `AuditingProcess/AuditingProcess_GetDetailsByID?Type=${Type}&ID=${ID}`
    );
};
const AuditingProcess_GetPeriodicDetailsByID = async (
    PeriodicCheckListMaintenanceID,
    EquipmentDetailId,
) => {
    return await axios.get(
        `AuditingProcess/AuditingProcess_GetPeriodicDetailsByID?PeriodicCheckListMaintenanceID=
      ${PeriodicCheckListMaintenanceID}&EquipmentDetailID=${EquipmentDetailId}`
    );
};
const AuditingProcess_ApproveReject = async (data) => {
    return await axios.post(
        `AuditingProcess/AuditingProcess_ApproveReject`, data
    );
};
export {
    AuditingProcess_GetGridData,
    AuditingProcess_GetDetailsByID,
    AuditingProcess_GetPeriodicDetailsByID,
    AuditingProcess_ApproveReject,
    AuditingProcess_Getdropdown,
}