import React from "react";
import Logo from "../../assets/img/cmrl-logo.png";
import Sterlingwilsonlogo from "../../assets/img/Sterlingwilsonlogo.png";
import "../../assets/css/style.css";
import { useState, useContext, useEffect } from "react";
import {
  BuildTreeUsingParentID,
  COLORS,
  GetNumberOfDaysInSec,
  makeUnique,
} from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import LoginService from "./AuthService";
import { useForm } from "react-hook-form";
import { showErrorMsg } from "../../components/ToastMsg";
import Session from "../../services/Session";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Global, { Roles } from "../../services/Global";
import {
  Encrypt,
  Decrypt,
  FlutterDec,
  FlutterEnc,
} from "../../services/CommonService";
import queryString from "query-string";

export const Login = () => {
  //variables

  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userIconColor, setuserIconColor] = useState(COLORS.grey);
  const [passwdIconColor, setPasswdIconColor] = useState(COLORS.grey);

  const location = useLocation();

  const {
    showLoading,
    hideLoading,
    setCookies,
    tempModulePrivileage,
    getCookies,
    GlobalCorridor,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const _loginService = new LoginService();
  const _session = new Session();

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);

    if (parsed != null && parsed.MadJApGPTN0DBn4qk98KEg != undefined) {
      parsed.MadJApGPTN0DBn4qk98KEg = parsed.MadJApGPTN0DBn4qk98KEg.slice(
        2
      ).replace(" ", "+");
      parsed.NLVfA4iBU9wDBn4qk98KEg = parsed.NLVfA4iBU9wDBn4qk98KEg.slice(
        2
      ).replace(" ", "+");

      var username = FlutterEnc(parsed.MadJApGPTN0DBn4qk98KEg);
      var Password = FlutterEnc(parsed.NLVfA4iBU9wDBn4qk98KEg);

      onSubmit({ username: username, Password: Password });
    }
  }, []);

  const onSubmit = async (data) => {
    showLoading();
    debugger
    await _loginService.Login(data).then((response) => {
      hideLoading();
      debugger
      let IsTvfvac = "";

      if (response == 0 || response == null) {
        showErrorMsg("Login Failed");
      } else if (!response.response.isSuccess) {
        showErrorMsg("Login Failed");
      } else {
        //if rememeber me is enable, cookie expires on after 30 days, otherwise expires on 1 day
        var noOfDays = rememberMe ? 30 : 7;

        //set expiry date for auth cookie
        var cookieExpiryOn = GetNumberOfDaysInSec(noOfDays);
        setCookies(Global.COOKIES.Jwt, response.token, cookieExpiryOn);
        setCookies(Global.COOKIES.UserName, response.user.userName, cookieExpiryOn);
        setCookies(Global.COOKIES.Password, data.password, cookieExpiryOn);
        setCookies(Global.COOKIES.modulePrivileage, response.modulePrivileage, cookieExpiryOn);

        setCookies(Global.COOKIES.UserGoodID, response.user.id, cookieExpiryOn);
        // setCookies(Global.COOKIES.DepartmentID, 19);

        if (
          response.employeeDetails != null &&
          response.employeeDetails.length > 0
        ) {
          setCookies(
            Global.COOKIES.EmployeeID,
            response.employeeDetails[0].EmployeeID
          );
          localStorage.setItem(
            Global.COOKIES.EmployeeID,
            Encrypt(response.employeeDetails[0].EmployeeID)
          );
          setCookies(Global.COOKIES.UserDetails, response.employeeDetails[0]);
          localStorage.setItem(
            Global.COOKIES.UserDetails,
            Encrypt(response.employeeDetails[0])
          );
        }

        if (response?.employeeStation?.length) {

          const FilterStation = makeUnique(response?.employeeStation, ["StationID", "CorridorID", "Type", "IsSection", "OrderNo"]);
          setCookies(
            Global.COOKIES.GLOBAL_STATION,
            Encrypt(FilterStation)
          );

          setCookies(
            Global.COOKIES.DepartmentStations,
            Encrypt(response?.employeeStation)
          );


          if (response.employeeDepartment != null) {
            // let treeJsonData = BuildTreeUsingParentID(response.employeeDepartment);
            let Data = JSON.parse(JSON.stringify(response.employeeDepartment));

            IsTvfvac =
              response.employeeDepartment.filter((dep) =>
                dep.name.includes("E&M")
              ).length > 0;

            if (IsTvfvac) {
              let corridorID = response.employeeStation[0].CorridorType;

              setCookies(Global.COOKIES.corridorID, corridorID);
              let filterList = response.employeeStation.filter((x) => {
                return x.CorridorType == corridorID;
              });

              let CorridorList = response.employeeStation.map((x) => {
                return x.CorridorType;
              });

              CorridorList = [...new Set(CorridorList)];
              if (CorridorList.length > 1) {
                setCookies(Global.COOKIES.CorridorChangePrivilege, 1);
              } else {
                setCookies(Global.COOKIES.CorridorChangePrivilege, 0);
              }

              setCookies(Global.COOKIES.Stations, Encrypt([...filterList]));

            } else {
              setCookies(Global.COOKIES.Stations, Encrypt([...FilterStation]));
            }



            setCookies(Global.COOKIES.IsTvsvac, IsTvfvac);

            setCookies(Global.COOKIES.Departments, Encrypt(Data));
          }
          localStorage.setItem(
            Roles.LO_RoleId,
            Encrypt(response.l0RoleID[0]?.Id)
          );
          setCookies(Roles.LO_RoleId, Encrypt(response.l0RoleID[0]?.Id));
          setCookies(Global.COOKIES.Role, response.role[0]?.Role, cookieExpiryOn);
          setCookies(Global.COOKIES.UserRoleID, response.role[0]?.RoleID);

          setCookies(
            Global.COOKIES.PeriodicStationID,
            response.employeeStation[0].StationID
          );
        }

        localStorage.setItem("UserName", Encrypt(response.user.userName));
        localStorage.setItem("ID", 0);
        // navigate("/dashboard");

        localStorage.setItem(
          Global.COOKIES.Privilege,
          Encrypt(response.modulePrivileage)
        );
        localStorage.setItem(Global.COOKIES.IsAuth, 1);
        navigate(
          `${IsTvfvac ? "/dashboard" : "/BreakdownDepartmentDashboard"}`,
          { state: { id: 1 } }
        );
      }
    });
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                <div className="logo">
                  <a
                    href="https://chennaimetrorail.org/"
                    target={"_blank"}
                    className="logo"
                  >
                    <img
                      src={Logo}
                      width="50"
                      height="auto"
                    // alt=""
                    // className="contrast"
                    />
                  </a>
                  <a
                    href="https://sterlingandwilson.com/"
                    target={"_blank"}
                    className="logo"
                  >
                    <img
                      src={Sterlingwilsonlogo}
                      width="150"
                      height="auto"
                      // alt=""
                      // className="contrast"
                      // id="sterlingLogo"
                      style={{ marginLeft: "10px" }}
                    />
                  </a>
                  {/* <img src={Logo} alt="Chennai Metro Rail Limited" /> */}

                  <h1>Comprehensive Maintenance Management System</h1>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* Username */}
                  <div className="form-group ">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i
                          className="fa fa-user-o"
                          style={{ fontWeight: "600", color: userIconColor }}
                        ></i>
                      </span>
                      <input
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onFocus={(e) => setuserIconColor(COLORS.blue)}
                        onBlur={(e) => setuserIconColor(COLORS.grey)}
                        {...register("username", { required: true })}
                      />
                    </div>
                    {errors.username && (
                      <p className="text-danger mt-1">Username is required</p>
                    )}
                  </div>

                  {/* Password */}
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i
                          className="fa fa-unlock-alt login-icon"
                          style={{ fontWeight: "600", color: passwdIconColor }}
                        ></i>
                      </span>
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onFocus={(e) => setPasswdIconColor(COLORS.blue)}
                        onBlur={(e) => setPasswdIconColor(COLORS.grey)}
                        {...register("password", { required: true })}
                      />
                      <span class="input-group-text">
                        <a
                          className="login-icon eye-icon"
                          href="#"
                          onClick={(e) => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword ? "fa fa-eye" : "fa fa-eye-slash	"
                            }
                          ></i>
                        </a>
                      </span>
                    </div>
                    {errors.password && (
                      <p className="text-danger mt-1">Password is required</p>
                    )}
                  </div>

                  {/* Remember Me */}
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input BudgetType"
                      type="checkbox"
                      value="1"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(!rememberMe)}
                    />
                    <a href="#" class="text-muted" for="category1">
                      Remember me
                    </a>
                  </div>
                  {/* Login */}
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                  {/* Forgot Password */}
                  {/* <div class="text-center">
                    <a class="text-muted" href="#" id="forgot-password">
                      Forgot Password?
                    </a>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
