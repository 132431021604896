import { AiFillFolder, AiFillFile } from "react-icons/ai";
import { MdArrowRight, MdArrowDropDown, MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FcDepartment } from "react-icons/fc";
import { PiBuildingsLight } from "react-icons/pi";

const Node = ({
  node,
  style,
  dragHandle,
  tree,
  Rename,
  RenameSave,
  DeleteNode,
  onSelect,
  CrudISNeed = false,
}) => {
  const CustomIcon = node.data.icon;
  const iconColor = node.data.iconColor;
  const { isOpen } = node;

  const HandleClick = (e) => {
    e.preventDefault();

    if (!node.data.isEditing) {
      onSelect(node.data.id, node);
      isOpen ? node.close() : node.open();
    }
  };

  // console.log(node, tree);
  return (
    <div
      className={`node-container ${
        node.state.isSelected ? "isSelected" : ""
      }  `}
      style={style}
      ref={dragHandle}
      onClick={HandleClick}
      onDoubleClick={() => Rename(node)}
    >
      <div className={`node-content`}>
        {node.isLeaf ? (
          <>
            <span className="arrow"></span>
            <span className="file-folder-icon">
              {CustomIcon ? (
                <CustomIcon color={iconColor ? iconColor : "#6bc7f6"} />
              ) : (
                <PiBuildingsLight color="#6bc7f6" />
              )}
            </span>
          </>
        ) : (
          <>
            <span className="arrow">
              {isOpen ? <MdArrowDropDown /> : <MdArrowRight />}
            </span>
            <span className="file-folder-icon">
              {CustomIcon ? (
                <CustomIcon color={iconColor ? iconColor : "#f6cf60"} />
              ) : (
                <FcDepartment color="#f6cf60" />
              )}
            </span>
          </>
        )}
        <span className="">
          {node.data.isEditing ? (
            <input
              type="text"
              className="flow-control"
              defaultValue={node.data.name}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={() => node.reset()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  RenameSave(node.data.id, e.currentTarget.value);
                }
              }}
            />
            // <input type="text" />
          ) : (
            <span>{node.data.name}</span>
          )}
        </span>
      </div>
      {CrudISNeed && (
        <div className="file-actions">
          <div className="folderFileActions">
            <button
              onClick={(e) => {
                Rename(node);
                e.preventDefault();
              }}
              title="Rename..."
            >
              <MdEdit color="#00000" />
            </button>
            <button
              onClick={(e) => {
                DeleteNode(node.data.id);
                e.preventDefault();
              }}
              title="Delete"
            >
              <RxCross2 color="#00000" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Node;
