import React from "react";
import { TabBar } from "../../components/TabBar";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "./../../services/ContextProvider";
import { BreakdownDepartmentList } from "./BreakdownDepartmentList";
import { BreakdownDepartmentCyclicList } from "./BreakdownDepartmentCyclicList";

const Header = ({ title }) => {
  return (
    <div className="page-header mb-3">
      <div className="row">
        <div className="col-4">
          <h3 className="page-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export const BreakdownDepartmentIndex = () => {
  // const { handleMasterTab } = useContext(AppContext);

  const [TabInitialVal, setTabInitialVal] = useState(0);
  const location = useLocation();
  const TabHeader = [
    "Breakdown Maintenance",
    "Breakdown Maintenance Cyclic check",
  ];

  const TabBody = [
    <BreakdownDepartmentList TabID={0} TabValue={TabInitialVal} />,
    <BreakdownDepartmentCyclicList TabID={1} TabValue={TabInitialVal} />,
  ];

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;

      setTabInitialVal(0);
    }
  }, []);

  const onTabChange = (e) => {
    setTabInitialVal(e - 1);
  };

  return (
    <>
      <div className="card">
        <TabBar
          header={TabHeader}
          body={TabBody}
          InitialValue={TabInitialVal}
          onTabChange={onTabChange}
        />
      </div>
    </>
  );
};
