import Axios from "../../services/AxiosService";

const axios = new Axios();

const PerformanceEvaluation_DropDownDetails = async (StationID) => {
  return await axios.get(
    `PerformanceEvaluation/PerformanceEvaluation_DropDownDetails?StationID=${StationID}`
  );
};
const PerformanceEvaluation_SaveDescriptionDetails = async (data) => {
  return await axios.post(
    "PerformanceEvaluation/PerformanceEvaluation_SaveDescriptionDetails",
    data
  );
};
const PerformanceEvaluation_GetDescriptionByID = async (
  StationID,
  EquipmentID,
  CurrentDate,
  ScheduleType
) => {
  return await axios.get(
    `PerformanceEvaluation/PerformanceEvaluation_GetDescriptionByID?StationID=${StationID}&EquipmentID=${EquipmentID}&CurrentDate=${CurrentDate}&ScheduleType=${ScheduleType}`
  );
};
const PerformanceEvaluation_SaveScheduleCheckListDetails = async (data) => {
  return await axios.post(
    "PerformanceEvaluation/PerformanceEvaluation_SaveScheduleCheckListDetails",
    data
  );
};
const PerformanceEvaluation_ReportGetByID = async (
  StationID,
  EquipmentID,
  FromDate,
  ToDate,
  YearID,
  QuarterID
) => {
  return await axios.get(
    `PerformanceEvaluation/PerformanceEvaluation_ReportGetByID?StationID=
    ${StationID}&EquipmentID=${EquipmentID}&FromDate=${FromDate}&ToDate=${ToDate}&YearID=${YearID}&QuarterID=${QuarterID}`
  );
};

const PerformanceEvaluation_GetStatusUsingID = async (StationID, Year) => {
  return await axios.get(
    `PerformanceEvaluation/PerformanceEvaluation_GetStatusUsingID?StationID=${StationID}&Year=${Year}`
  );
};
export {
  PerformanceEvaluation_DropDownDetails,
  PerformanceEvaluation_SaveDescriptionDetails,
  PerformanceEvaluation_GetDescriptionByID,
  PerformanceEvaluation_SaveScheduleCheckListDetails,
  PerformanceEvaluation_ReportGetByID,
  PerformanceEvaluation_GetStatusUsingID,
};
