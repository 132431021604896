import { Encrypt } from "../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class ManualUpDownloadService {


  UploadMaintenanceManual(data) {
    console.log(data)

    return axios.post("MaintenanceManual/UploadMaintenanceManual", data)

  }

  GetManualList() {


    return axios.get("MaintenanceManual/GetManualList");

  }




  DownloadMaintenanceManual(ManualID, setProgress) {


    return axios.get(`MaintenanceManual/DownloadMaintenanceManual?ManualID=${ManualID}`, { responseType: 'arraybuffer', onDownloadProgress: (progressEvent) => {
      setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    } })
  }
  DeleteRefrenceDocument(DocumentID) {

    return axios.get(`MaintenanceManual/DeleteRefrenceDocument?DocumentID=${DocumentID}`)
      
  }



}