import React, { useState, useEffect, useReducer, useContext } from "react";
import { Dropdown } from "../../components/Dropdown";
import $ from "jquery";
import {
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "../../components/ToastMsg";
import BreakdownMaintenanceService from "./BreakdownMaintenanceService";
import { GetLoginUserID } from "../../services/CommonService";
import swal from "sweetalert";
import { AppContext } from "../../services/ContextProvider";
import { showSuccessMsg } from "./../../components/ToastMsg";
import Global from "../../services/Global";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import { getUserDetails } from "../../services/CommonService";
import { COOKIES } from "../../services/Global";
import { DropdownWithOtherName } from "../../components/DropdownWithOtherName";

const obj = new BreakdownMaintenanceService();
export const SaveBreakdownMaintenance = ({
  TicketDetailes,
  EmployeeDropDown,
  StatusDropDown,
  Sparelist,
  PartDropDown,
  SelectedSpareList,
  tableRefresh,
  RefreshforForm,
  AssignByDropDown,
  IsView,
  SubmitButton,
}) => {
  const [errors, seterrors] = useState({});
  const [SpareLists, setSpareLists] = useState([]);
  const [SpareListsforDropdown, setSpareListsforDropdown] = useState([]);
  const [TicketModel, setTicketModel] = useState([]);
  const [PartDropDownModel, setPartDropDownModel] = useState([]);

  const [SpareID, setSpareID] = useState([]);
  const [PartNo, setPartNo] = useState([]);
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const { getCookies, setCookies, showLoading, hideLoading } =
    useContext(AppContext);
  const [priviledge, setpriviledge] = useState("");
  const [UserDetailes, setUserDetailes] = useState({});

  useEffect(() => {
    
    $(".SystemBreakdown").val("");
    $("#Quantity1").val("");
    $("#PartNoID").val("");
    $("#SpareID").val("");
    $("#AvailQuantity1").html("");
    $("#ReorderLimit1").html("");

    document.getElementById("FormFile1").value = null;

    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";
    setpriviledge(ModulePrivileges);

    if (
      getUserDetails().RoleID !== COOKIES.TechRoleID &&
      TicketDetailes.initialStatusID == 20
    ) {
      setTicketModel({ ...TicketDetailes });

      setTicketModel((prevState) => ({
        ...prevState,
        ["ticketStatusID"]: 29,
        
      }));
    } else if (
      getUserDetails().RoleID !== COOKIES.TechRoleID &&
      TicketDetailes.initialStatusID == 18
    ) {
      setTicketModel({ ...TicketDetailes });

      setTicketModel((prevState) => ({
        ...prevState,
        ["assignedBy"]: GetLoginUserID(),
        assignedTo : 0
      }));
    } else if (
      getUserDetails().RoleID === COOKIES.TechRoleID &&
      TicketDetailes.initialStatusID == 21
    ) {
      setTicketModel({ ...TicketDetailes });

      setTicketModel((prevState) => ({
        ...prevState,
        ["ticketStatusID"]: 20,
      }));
    } else {
      setTicketModel(TicketDetailes);
    }
    
    const filteredspare = Sparelist.filter(
      (x) => x.stationID == TicketDetailes.stationID
    );
    setSpareListsforDropdown(filteredspare);
    setSpareLists(SelectedSpareList);
    setSpareID([]);
    setPartNo([]);
    setPartDropDownModel([]);

    seterrors([]);

    var user = getUserDetails();
    if (user != null) {
      setUserDetailes(user);
    }
    // document.getElementById('FormFile1').value = null;
  }, [TicketDetailes, RefreshforForm]);

  function SpareHandleChange(e) {
    const { name, value } = e.target;

    setTicketModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (PartDropDown != null) {
      const partDropDowns = PartDropDown.filter((x) => {
        return Number(x.spareID) == Number(e.target.value);
      });
      const temp = {
        spareID: e.target.value,
        spareName: e.target.selectedOptions[0].label,
      };
      setSpareID(temp);

      setPartDropDownModel(partDropDowns);

      if (partDropDowns != null && partDropDowns.length > 0) {
        var Quantity = PartDropDown.find((x) => {
          return x.values == Number(partDropDowns[0].values);
        });
        if (Quantity != null) {
          const temp = {
            spareDetailID: Quantity.values,
            PartNo: Quantity.label,
            quantityAvailable: Quantity.quantityAvailable,
            reorderLimit: Quantity.reorderLimit,
          };

          $("#AvailQuantity1").html(
            `Available Quantity is : ${Quantity.quantityAvailable}`
          );
          $("#ReorderLimit1").html(
            `Reorder Limit Is : ${Quantity.reorderLimit}`
          );
          $("#PartNoID").val(Quantity.values);

          setPartNo(temp);
        } else {
          $("#AvailQuantity1").html("");
          $("#ReorderLimit1").html("");
          setPartNo([]);
        }
      } else {
        $("#AvailQuantity1").html("");
        $("#ReorderLimit1").html("");
        setPartNo([]);
      }
    }
  }
  function PartNoHandleChange(e) {
    const Quantity = PartDropDown.find((x) => {
      return x.values == e.target.value;
    });
    const temp = {
      spareDetailID: e.target.value,
      PartNo: e.target.selectedOptions[0].label,
      quantityAvailable: Quantity.quantityAvailable,
      reorderLimit: Quantity.reorderLimit,
    };

    if (Quantity != null) {
      $("#AvailQuantity1").html(
        `Available Quantity is : ${Quantity.quantityAvailable}`
      );
      $("#ReorderLimit1").html(`Reorder Limit Is : ${Quantity.reorderLimit}`);
    } else {
      $("#AvailQuantity1").html("");
      $("#ReorderLimit1").html("");
    }
    setPartNo(temp);
  }

  function AddHandleClick() {
    const spare = SpareID;
    const Part = PartNo;
    const Quantity = $("#Quantity1").val();

    if (spare != null && Part != null) {
      var err = errors;
      var ErrCheck = true;

      if (spare.spareID == null || spare.spareID == "0") {
        err.spareID = "Spare name is required";
        ErrCheck = false;
      } else {
        err.spareID = "";
      }
      if (Part.spareDetailID == null || Part.spareDetailID == "0") {
        err.spareDetailID = "Part no is required";
        ErrCheck = false;
      } else {
        err.spareDetailID = "";
      }
      if (Quantity == null || Quantity == "") {
        err.Quantity = "Quantity is required";
        ErrCheck = false;
      } else {
        err.Quantity = "";
      }

      seterrors({ ...err });

      if (ErrCheck) {
        if (
          Number(Quantity) != 0 &&
          Number(Quantity) <= Part.quantityAvailable
        ) {
          var SpareLst = SpareLists;

          var check = SpareLst.findIndex((x) => {
            return x.spareDetailID == Number(Part.spareDetailID);
          });

          if (check == -1) {
            swal({
              title: "Are you sure",
              text: "Do you want to add this spare?",
              icon: "info",
              buttons: true,
              dangerMode: false,
            }).then((willDelete) => {
              if (willDelete) {
                const NewSpare = {
                  spareName: spare.spareName,
                  spareID: spare.spareID,
                  partNo: Part.PartNo,
                  spareDetailID: Part.spareDetailID,
                  quantity: Quantity,
                  reorderLimit: Part.reorderLimit,
                  quantityAvailable: Part.quantityAvailable - Quantity,
                };

                SpareLst.push(NewSpare);
                setSpareLists([...SpareLst]);
              }
            });
          } else {
            showWarningMsg(`Can't add duplicate Part No`);
          }
        } else {
          if (Number(Quantity) == 0) {
            showWarningMsg(`Please add atleast one quantity`);
          } else {
            showWarningMsg(`Only ${Part.quantityAvailable} is available.`);
          }
        }
      }
    } else {
      const errr = {
        Quantity: "Quantity is required",
        spareDetailID: "Part No is required",
        spareID: "Spare name is required",
      };
      seterrors(errr);
    }
  }

  const CommonHandleChange = (e) => {

    ;
    const { name, value } = e.target;
    if (name == "assignedTo") {
      const assignby = GetLoginUserID();
      if(value == assignby) {
        setTicketModel((prevState) => ({
          ...prevState,
          [name]: value,
          ticketStatusID: 20,
          assignedBy: GetLoginUserID(),
        })) }
        else
        {
           setTicketModel((prevState) => ({
          ...prevState,
          [name]: value,
          ticketStatusID: 21,
          assignedBy: GetLoginUserID(),
        }));
      }
    } else {
      setTicketModel((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // $("#BreakdownMaintenceModelClose").on("click", function () {
  //   setRefresh();
  // });

  function SaveDetailes() {
    var TicketModels = TicketModel;

    // TicketModels.assignedBy =
    TicketModels.savedBy = GetLoginUserID();
    delete TicketModels.savedDate 

    showLoading();

    const formData = new FormData();

    $.each($("#FormFile1")[0].files, function (i, file) {
      formData.append("files", file);
    });

    obj.FileUpload(formData).then((res) => {
      const FinalModel = {
        breakdownMaintenanceModel: TicketModels,
        sparesUsedMasterModels: SpareLists,
        ListOfOrginalFileName: res,
        Type: SubmitButton ? "SYSTEM" : "ANALOG",
      };
      ;
      obj
        .SaveBreakdownDetailes(FinalModel)
        .then((res) => {
          setRefresh();

          hideLoading();
          setTicketModel((pre) => ({
            ...pre,
            assignedTo : 0
          }))
          $(".close").click();

          showSuccessMsg("Saved successfully.");
        })
        .catch((err) => {
          console.log(err);
          showErrorMsg("Something went wrong, Please try after sometime");
          hideLoading();
        });
    });
  }

  // useEffect(() => {
  //   tableRefresh(Refresh);
  // }, [Refresh]);

  function deleteSpare(spareId, SparedetailID, spareUsedID) {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this spare?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (spareUsedID > 0) {
          showLoading();
          obj
            .DeleteSparePart(spareId, SparedetailID, spareUsedID)
            .then((response) => {
              hideLoading();

              showDeleteSuccess();
              var remainSpare = SpareLists.filter((x) => {
                return x.spareDetailID != SparedetailID;
              });
              setSpareLists(remainSpare);
            })
            .catch((error) => {
              hideLoading();
              showErrorMsg("something went wrong please try after sometime.");
            });
        } else {
          var remainSpare = SpareLists.filter((x) => {
            return x.spareDetailID != SparedetailID;
          });
          showDeleteSuccess();
          setSpareLists(remainSpare);
        }
      }
    });
  }

  return (
    <>
      {IsView ? (
        <div>
          <form id="BreakdownDetailes">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Equipment Tag Number
                  </label>
                  <input
                    className="form-control SystemBreakdown"
                    type="text"
                    disabled
                    value={TicketModel.equipmentCode}
                  />
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                  </label>

                  {getUserDetails().RoleID !== COOKIES.TechRoleID &&
                    TicketModel.initialStatusID == 20 ? (
                    <Dropdown
                      data={StatusDropDown}
                      placeholder="Select"
                      value={TicketModel.ticketStatusID}
                      name={"ticketStatusID"}
                      className="form-control SystemBreakdown"
                      HandleChange={CommonHandleChange}
                    />
                  ) : getUserDetails().RoleID !== COOKIES.TechRoleID ? (
                    <input
                      className="form-control SystemBreakdown"
                      type="text"
                      disabled
                      value={TicketModel.statusName}
                    />
                  ) : getUserDetails().RoleID == COOKIES.TechRoleID &&
                    (TicketModel.initialStatusID == 21 ||
                      TicketModel.initialStatusID == 20 ||
                      TicketModel.initialStatusID == 18) ? (
                    <Dropdown
                      data={StatusDropDown}
                      placeholder="Select"
                      value={TicketModel.ticketStatusID}
                      name={"ticketStatusID"}
                      HandleChange={CommonHandleChange}
                    />
                  ) : (
                    <input
                      className="form-control SystemBreakdown"
                      type="text"
                      disabled
                      value={TicketModel.statusName}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned By
                  </label>
                  <Dropdown
                    data={AssignByDropDown}
                    placeholder={"-- Select -- "}
                    value={TicketModel.assignedBy}
                    disabled
                    name={"assignedBy"}
                    initialVal={0}
                    HandleChange={CommonHandleChange}
                  />
                </div>
              </div>

              {/* {priviledge.includes("BREAKDOWNMAINTENANCECREATE") && */}
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned To
                  </label>
                  <Dropdown
                    data={EmployeeDropDown}
                    placeholder={"-- Select--"}
                    value={TicketModel.assignedTo}
                    name={"assignedTo"}
                    initialVal={0}
                    HandleChange={CommonHandleChange}
                  />
                </div>
              </div>
              {/* } */}

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Problem Description
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="problemDescription"
                    value={TicketModel.problemDescription}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Work Description
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please Enter Work Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="workDescription"
                    value={TicketModel.workDescription}
                    rows={3}
                    onChange={CommonHandleChange}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks By Engineer
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please enter remarks"
                    style={{ resize: "none" }}
                    type="text"
                    name="remarks"
                    disabled={UserDetailes.RoleID == Global.COOKIES.TechRoleID}
                    value={TicketModel.remarks}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks By Technician
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please enter remarks"
                    style={{ resize: "none" }}
                    type="text"
                    name="remarksForTechnician"
                    disabled={
                      UserDetailes.RoleID == Global.COOKIES.TechRoleID
                        ? false
                        : true
                    }
                    value={TicketModel.remarksForTechnician}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Upload Document
                    {/* <span className='text-danger'> *</span> */}
                  </label>
                  <input id="file" hidden />
                  <input
                    className="form-control "
                    type="file"
                    id="FormFile1"
                    name="FormFile1"
                    multiple
                  />
                </div>
              </div>

              {priviledge.includes("BREAKDOWNSPAREADDCREATE") && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Spare Name <span className="text-danger"> *</span>{" "}
                      </label>
                      <DropdownWithOtherName
                        data={SpareListsforDropdown}
                        placeholder="Select"
                        name={"SpareID"}
                        className="SystemBreakdown"
                        HandleChange={SpareHandleChange}
                      />

                      <span className="text-danger mt-1">
                        {" "}
                        {errors.spareID}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Part No <span className="text-danger"> *</span>{" "}
                      </label>
                      <DropdownWithOtherName
                        data={PartDropDownModel}
                        placeholder="Select"
                        name={"PartNoID"}
                        className="SystemBreakdown"
                        HandleChange={PartNoHandleChange}
                      />

                      <span className="text-danger pt-1 mt-1">
                        {errors.spareDetailID}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="label" htmlFor="empCode">
                        Quantity<span className="text-danger"> *</span>{" "}
                      </label>
                      <input
                        className="form-control SystemBreakdown"
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==10) return false;"
                        placeholder="Please Enter Quantity"
                        id="Quantity1"
                      />
                      <span
                        id=""
                        className="text-danger pt-1 mt-1"
                        style={{ minHeight: "20px" }}
                      >
                        {" "}
                        {errors.Quantity}{" "}
                      </span>
                      <br></br>
                      <span
                        id="AvailQuantity1"
                        className="text-info"
                        style={{ minHeight: "20px" }}
                      >
                        {" "}
                      </span>
                      <br></br>
                      <span
                        id="ReorderLimit1"
                        className="text-info"
                        style={{ minHeight: "20px" }}
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-2 mt-4">
                    <button
                      type="button"
                      id="addRow"
                      onClick={AddHandleClick}
                      className="btn add-btn"
                    >
                      <i className="fa fa-plus"></i> Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>

          <table
            id="SpareDetailes"
            style={{ marginBottom: "3px" }}
            className="table table-striped custom-table mt-3"
          >
            <thead>
              <tr>
                <th>Spare Name</th>
                <th>Part No</th>
                <th>Quantity</th>
                {priviledge.includes("BREAKDOWNSPAREADDDELETE") && (
                  <th>Submit</th>
                )}
              </tr>
            </thead>
            <tbody id="tbl1">
              <>
                {SpareLists != null && SpareLists.length > 0 ? (
                  SpareLists.map((x) => {
                    return (
                      <tr>
                        <td> {x.spareName} </td>
                        <td>{x.partNo}</td>
                        <td>{x.quantity}</td>
                        <td>
                          {priviledge.includes("BREAKDOWNSPAREADDDELETE") && (
                            <button
                              className="btn icon-btn text-center"
                              onClick={() =>
                                deleteSpare(
                                  x.spareID,
                                  x.spareDetailID,
                                  x.spareUsedID
                                )
                              }
                            >
                              <i className="fa fa-trash m-r-5"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colspan="6">
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </table>

          {TicketModel.initialStatusID !== 29 &&
            TicketModel.initialStatusID !== 30 &&
            (getUserDetails().RoleID === COOKIES.TechRoleID ||
              TicketModel.initialStatusID !== 21) ? (
            <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
              <div className="col-md-2 ">
                <button
                  type="submit"
                  id=""
                  onClick={SaveDetailes}
                  className="btn add-btn secondary-btn"
                >
                  {" "}
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="row d-flex justify-content-center mt-3 mb-1 primary  ">
              <div className="col-md-2 ">
                <button
                  type="submit"
                  id=""
                  onClick={ SaveDetailes }
                  className="btn add-btn secondary-btn"
                >
                  {" "}
                  Save
                </button>
              </div>
            </div> */}
        </div>
      ) : (
        <div>
          <form id="BreakdownDetailes">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Equipment Tag Number
                  </label>
                  <input
                    className="form-control SystemBreakdown"
                    type="text"
                    disabled
                    value={TicketModel.equipmentCode}
                  />
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Status
                  </label>
                  {/* 
                  {getUserDetails().RoleID !== COOKIES.TechRoleID && TicketModel.initialStatusID == 20 ?

                    <Dropdown
                      data={StatusDropDown}
                      placeholder="Select"
                      value={TicketModel.ticketStatusID}
                      name={"ticketStatusID"}
                      className="form-control SystemBreakdown"
                      HandleChange={CommonHandleChange}
                    disabled

                    /> :
                    getUserDetails().RoleID == COOKIES.EnggRoleID ? */}
                  <input
                    className="form-control SystemBreakdown"
                    type="text"
                    disabled
                    value={TicketModel.statusName}
                  />
                  {/* : getUserDetails().RoleID == COOKIES.TechRoleID && (TicketModel.initialStatusID == 21 || TicketModel.initialStatusID == 20 || TicketModel.initialStatusID == 18) ?
                        <Dropdown
                          data={StatusDropDown}
                          placeholder="Select"
                          value={TicketModel.ticketStatusID}
                          name={"ticketStatusID"}
                          disabled
                          HandleChange={CommonHandleChange}
                        /> :
                        <input
                          className="form-control SystemBreakdown"

                          type="text"

                          disabled

                          value={TicketModel.statusName}

                        /> */}

                  {/* } */}
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned By
                  </label>
                  <WithoutSelectDropDown
                    data={AssignByDropDown}
                    placeholder="Select"
                    value={TicketModel.assignedBy}
                    disabled
                    name={"assignedBy"}
                    HandleChange={CommonHandleChange}
                  />
                </div>
              </div>

              {/* {priviledge.includes("BREAKDOWNMAINTENANCECREATE") && */}
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Assigned To
                  </label>
                  <Dropdown
                    data={EmployeeDropDown}
                    placeholder={"-- Select--"}
                    value={TicketModel.assignedTo}
                    name={"assignedTo"}
                    disabled
                    initialVal={0}
                    HandleChange={CommonHandleChange}
                  />
                </div>
              </div>
              {/* } */}

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Problem Description
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please Enter Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="problemDescription"
                    value={TicketModel.problemDescription}
                    rows={3}
                    disabled
                    onChange={CommonHandleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Work Description
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please Work Problem Description"
                    style={{ resize: "none" }}
                    type="text"
                    name="workDescription"
                    value={TicketModel.workDescription}
                    rows={3}
                    disabled
                    onChange={CommonHandleChange}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks By Engineer
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please enter remarks"
                    style={{ resize: "none" }}
                    type="text"
                    name="remarks"
                    disabled
                    value={TicketModel.remarks}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Remarks By Technician
                  </label>
                  <textarea
                    className="form-control SystemBreakdown"
                    placeholder="Please enter remarks"
                    style={{ resize: "none" }}
                    type="text"
                    name="remarksForTechnician"
                    disabled={
                      UserDetailes.RoleID == Global.COOKIES.TechRoleID
                        ? false
                        : true
                    }
                    value={TicketModel.remarksForTechnician}
                    onChange={CommonHandleChange}
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="label" htmlFor="empCode">
                    Upload Document
                    {/* <span className='text-danger'> *</span> */}
                  </label>
                  <input id="file" hidden />
                  <input
                    className="form-control "
                    type="file"
                    id="FormFile1"
                    name="FormFile1"
                    multiple
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>

          <table
            id="SpareDetailes"
            style={{ marginBottom: "3px" }}
            className="table table-striped custom-table mt-3"
          >
            <thead>
              <tr>
                <th>Spare Name</th>
                <th>Part No</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody id="tbl1">
              <>
                {SpareLists != null && SpareLists.length > 0 ? (
                  SpareLists.map((x) => {
                    return (
                      <tr>
                        <td> {x.spareName} </td>
                        <td>{x.partNo}</td>
                        <td>{x.quantity}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colspan="6">
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
