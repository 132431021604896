import React from "react";
import { Link } from "react-router-dom";
// import "../assets/css/customOrderList.css"

const CustomOrderList = () => {
  return (
    <div id="Maintenance_CheckCard"> 
    <Link to={"/CheckListCardView/Description"} >
    <div className="row"  >
      <div className="col-6">
        <i class="fa fa-dot-circle-o   me-2" style={{color:"var(--dashboard-open-color)"}}></i>Open{" "}
        <span class="float-end">166</span>
      </div>
      <div className="col-6">
        <i class="fa fa-dot-circle-o  text-warning me-2"></i>Pending{" "}
        <span class="float-end">115</span>
      </div>
      <div className="col-6">
        <i class="fa fa-dot-circle-o me-2" style={{color:"var(--dashboard-attended-color)"}}></i>Attended{" "}
        <span class="float-end">31</span>
      </div>
      <div className="col-6">
        <i class="fa fa-dot-circle-o   me-2" style={{color:"var(--dashboard-approved-color)"}}></i>Approved{" "}
        <span class="float-end">47</span>
      </div>
      <div className="col-6">
        <i class="fa fa-dot-circle-o me-2" style={{color:"var(--dashboard-rejected-color)"}}></i>Rejected{" "}
        <span class="float-end">5</span>
      </div>
    </div>
    </Link>
    </div>
  );
};

export { CustomOrderList };
