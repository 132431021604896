import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const ReactFileViewer = ({ fileBlob, fileName }) => {
  return (
    <div>
      {fileBlob && fileName ? (
        <>
          <button
            data-bs-toggle="modal"
            data-bs-target="#DownLoadFiles"
            className="btn btn-secondary"
          >
            {" "}
            Go Back
          </button>
          <div className="viewer">
            <div
              style={{
                background: "#f8f9fa", // Light gray background for the container
                padding: "20px", // Padding around the document viewer
                borderRadius: "8px", // Rounded corners for a polished look
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                maxWidth: "800px", // Max width for the document viewer
                margin: "0 auto", // Center align the container
              }}
            >
              <DocViewer
                style={{
                  background: "white", // White background for the document viewer
                  border: "1px solid #ddd", // Light border around the document
                  borderRadius: "4px", // Slight rounding of the corners
                  padding: "10px", // Padding inside the document viewer
                }}
                documents={[
                  {
                    uri: URL.createObjectURL(fileBlob),
                    fileName: fileName,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
              />
            </div>
          </div>
        </>
      ) : (
        "File not found"
      )}
    </div>
  );
};

export { ReactFileViewer };
