import Axios from "../../../services/AxiosService";

const axios = new Axios();

const PrefinedFails_GetDropDown = async () => {
    return await axios.get(
        `ApplicationConfig/PrefinedFails_GetDropDown`
    );
};
const PrefinedFails_AddOrUpdate = async (data) => {
    return await axios.post(
        "ApplicationConfig/PrefinedFails_AddOrUpdate",
        data
    );
};
const PrefinedFails_GetDescriptionList = async () => {
    return await axios.get(
        `ApplicationConfig/PrefinedFails_GetDescriptionList`
    );
};
const PredefinedFails_DeleteDescription = async (PredefineFailuresID) => {
    return await axios.post(
        `ApplicationConfig/PredefinedFails_DeleteDescription?PredefineFailuresID=${PredefineFailuresID}`
    );
};
export {
    PrefinedFails_GetDropDown,
    PrefinedFails_AddOrUpdate,
    PrefinedFails_GetDescriptionList,
    PredefinedFails_DeleteDescription,
}