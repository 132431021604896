import React, { useContext } from "react";
import { TabBar } from "../../components/TabBar";
import { PageHeaderTitle } from "../../components";
import { useState, useEffect } from "react";
import AuditingProcess from "./AuditingProcess";
import { AppContext } from "../../services/ContextProvider";
import { Decrypt } from "../../services/CommonService";
import { COOKIES } from "../../services/Global";
import { Dropdown } from "../../components";

export default function AuditingProcessTab() {
  const TabHeader = ["System Audit", "Manual Audit"];

  const [TabInitialVal, setTabInitialVal] = useState(0);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [DepartmentID, setDepartmentID] = useState([]);
  const TabBody = [
    <AuditingProcess TabID={0} DepartmentID={DepartmentID} TabValue={TabInitialVal} />,
    <AuditingProcess TabID={1} DepartmentID={DepartmentID} TabValue={TabInitialVal} />,
  ];

  const {getCookies} = useContext(AppContext)

  /**
   * Handle tab change event.
   *
   * @param {number} e - The selected tab index.
   */
  const onTabChange = (e) => {
    setTabInitialVal(e - 1);
  };

  useEffect(() => {
    let tempDepartmentList = Decrypt(getCookies(COOKIES.Departments));

    if (tempDepartmentList?.length) {
      let Department = [];
      const itemsWithoutChildren = tempDepartmentList.filter(
        (item) =>
          !tempDepartmentList.some((child) => child.parentId === item.id)
      );

      itemsWithoutChildren.map((data) => {
        Department.push({ value: data.id, key: data.name, Type: data.Type });
      });

      setDepartmentList(Department);
      setDepartmentID(Department[0].value);
    }

    return () => {};
  }, []);

  return (
    <div>
      <div className="row justify-content-between"> 
        <div className="col-5"><h3> Auditing Process </h3></div>
      <div className="col-3 mb-1">
        <Dropdown
          data={DepartmentList ?? []}
          value={DepartmentID ?? 0}
          placeholder={"Department"}
          HandleChange={(e) => {
            setDepartmentID(e.target.value);
          }}
        >
          {" "}
        </Dropdown>
      </div>
      </div>

      <div className="card">
        <>
          <TabBar
            header={TabHeader}
            body={TabBody}
            InitialValue={TabInitialVal}
            onTabChange={onTabChange}
          />
        </>
      </div>
    </div>
  );
}
