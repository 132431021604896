import React, { useContext, useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { DataTableReports } from "../../components/DataTableReports";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";

import makeAnimated from "react-select/animated";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import MasterDetailService from "../master/MasterDetailService";
import {
  getDDMMYYdate,
  getYMDdate,
  convert24hrto12hr,
} from "../../services/CommonService";
import Global from "../../services/Global";
import { Decrypt } from "../../services/CommonService";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
const _breakdownService = new BreakdownMaintenanceService();
const animatedComponents = makeAnimated();
const MDS = new MasterDetailService();

const columnNames = [
  { title: "Serial No", data: "RowId" },
  { title: "Station Code", data: "StationCode" },
  { title: "Breakdown Date", data: "BreakdownDate" },
  { title: "Breakdown Time", data: "BreakdownTime" },
  { title: "Equipment", data: "EquipmentShortName" },
  { title: "Equipment Tag No", data: "EquipmentCode" },
  { title: "Description", data: "Description" },
  // { title: "Parameter", data: "ParameterName" },
  // { title: "UOM", data: "ParameterUnit" },
];
export const BreakdownAlarmReport = () => {
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [fromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [StatusDropDown, setStatusDropDown] = useState([]);

  const [statusId, setStatusId] = useState(null);
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);

  const [faultList, setFaultList] = useState([]);
  const [orginalList, setOrginalList] = useState([]);
  const [data, setData] = useState([]);
  const [stationIdss, setStataionIds] = useState("");
  const [isTable, setIsTable] = useState(false);

  const removeFaultList = (e) => {
    setFaultList(
      faultList.filter((faultItem) => statusId !== faultItem.statusId)
    );
  };

  useEffect(() => {
    getStationList();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    setFromDate(firstDay.toISOString().split("T")[0]);
    setToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);

    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange([dataLst[0]]);
    //   }
    // });
  };

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
  };

  const onFromDateChange = (e) => {
    // console.log(e.target.value)
    // setOrginalList(null);
    setFromDate(e.target.value);
  };
  const onToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const onclear = () => {
    $("#FromDate").val(null);
    $("#ToDate").val(null);
    setFromDate("");
    setToDate("");
  };

  const GetFault = (stationIds) => {
    showLoading();
    try {
      if (
        stationIds != null &&
        stationIds != "" &&
        fromDate != "" &&
        ToDate != ""
      ) {
        _breakdownService
          .BreakdownAlarmReport(fromDate, ToDate, stationIds)
          .then((response) => {
            if (
              response != null &&
              response.data != null &&
              response.data.length > 0
            ) {
              console.log(response.data);
              var rowList = [];
              response.data.map((v, i) => {
                var row = [];
                row.push(i + 1);
                row.push(v.StationCode);
                row.push(v.BreakdownDate);
                row.push(v.BreakdownTime);
                row.push(v.EquipmentCode);
                row.push(v.ParameterName);
                row.push(v.ParameterUnit);

                rowList.push(row);
              });
              setOrginalList([...rowList]);
            } else {
              setOrginalList([]);
            }
            hideLoading();
          })
          .catch((error) => {
            hideLoading();
            console.log(error);
          });
      } else {
        hideLoading();
        setOrginalList([]);
      }
    } catch (err) {
      hideLoading();
      console.log(err);
    }
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");
    setStataionIds(ID);

    if (ID != "") {
      setIsTable(true);
    }

    // GetFault(ID);
  }, [fromDate, ToDate, StationID]);
  return (
    <div>
      <Header
        title={"Breakdown Alarm Report"}
        removeFaultList={removeFaultList}
        stationchange={HandleChange}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        stationList={StationList}
        stationIDs={StationID}
        fromDate={fromDate}
        ToDate={ToDate}
        StatusDropDown={StatusDropDown}
        onclear={onclear}
      />
      <div className="card card-body">
        {isTable ? (
          <DataTableReportAjax
            columnList={columnNames}
            // rowList={orginalList}
            id={"AlarmTable"}
            title={"Breakdown Alarm Details"}
            isAsc={true}
            FromDate={fromDate}
            ToDate={ToDate}
            url={`${BASE_URL}BreakdownMaintenance/BreakdownAlarmReport`}
            StationID={stationIdss ? stationIdss : "0"}
          />
        ) : (
          <>
            <div className="text-center" style={{ margin: "10rem" }}>
              <div className="loader-Wrapper">
                <div className="Customloader-div">
                  <span className="loader"></span>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <DataTableReports columnList={columnNames} rowList={orginalList} id={'AlarmTable'} title={"Breakdown Alarm Details"} isAsc={true} /> */}
      </div>
    </div>
  );
};

const Header = ({
  title,
  onFromDateChange,
  stationList,
  stationIDs,
  stationchange,
  onToDateChange,
  fromDate,
  ToDate,
  StatusDropDown,
  HandleChange,
  onclear,
}) => {
  useEffect(() => {
    $(function () {
      if (fromDate != null) {
        $("#ToDate").attr("min", fromDate);
      }
      if (ToDate != null) {
        $("#FromDate").attr("max", ToDate);
      } else {
        $("#FromDate").val(null);
        $("#ToDate").val(null);
      }
    });
  }, [fromDate, ToDate]);

  return (
    <div className="page-header mb-lg-2">
      <div className="row justify-content-end">
        <div className="col-3">
          <h3 className="page-title">{title}</h3>
        </div>
        <div className="col-2"></div>
        <div className="col-2">
          <label className="label" for="">
            From Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            id="FromDate"
            pattern="\d{4}-\d{2}-\d{2}"
            defaultValue={fromDate}
            onChange={(e) => {
              onFromDateChange(e);
            }}
          />
        </div>
        <div className="col-2">
          <label className="label" for="">
            To Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            id="ToDate"
            defaultValue={ToDate}
            onChange={(e) => {
              onToDateChange(e);
            }}
          />
        </div>
        <div className="col-3">
          <label className="label">Stations</label>

          <ReactMultiSelect
            options={stationList}
            isMulti
            value={stationIDs}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => stationchange(e)}
            allowSelectAll={true}
          />
        </div>
      </div>
    </div>
  );
};
