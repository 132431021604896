import Axios from "../../services/AxiosService";
const axios = new Axios();

const DailyElectricalPanel_GetDailyElectricalPanelDetailes = async (
  StationID,
  EquipmentDetailID,
  DailyElectricalPanelMonitoringDate
) => {
  return await axios.get(
    `DailyElectricalPanel/DailyElectricalPanel_GetDailyElectricalPanelDetailes?StationID=${StationID}&EquipmentDetailID=${EquipmentDetailID}&DailyElectricalPanelMonitoringDate=${DailyElectricalPanelMonitoringDate}`
    // `DailyElectricalPanel/DailyElectricalPanel_GetDailyElectricalPanelDetailes`
  );
};

const DailyElectricalPanelMonitor_GetEquipmentCode = async (
  StationID,
  EquipmentDetailID,
  DailyElectricalPanelMonitoringDate
) => {
  return await axios.get(
    `DailyElectricalPanel/DailyElectricalPanelMonitor_GetEquipmentCode?StationID=${StationID}`
  );
};

const DailyElectricalPanel_SaveDailyElectricalPanel = async (obj) => {
  return await axios.post(
    `DailyElectricalPanel/DailyElectricalPanel_SaveDailyElectricalPanel`,
    obj
  );
};

const DailyElectricalPanel_ApprovelRejectRequest = async (obj) => {
  return await axios.post(
    `DailyElectricalPanel/DailyElectricalPanel_ApprovelRejectRequest`,
    obj
  );
};
const ListDailyElectricalPanel_GetReportList = async (
  DailyElectricalPanelDate,
  StationID
) => {
  return await axios.get(
    `DailyElectricalPanel/ListDailyElectricalPanel_GetReportList?DailyElectricalPanelDate=${DailyElectricalPanelDate}&StationID=${StationID}`
  );
};

const ChillerMonitoring_GetEquipmentCode = async (StationID) => {
  return await axios.get(
    `ManualLog/ChillerMonitoring_GetEquipmentCode?StationID=${StationID}`
  );
};
const ChillerMonitoring_GetChillerMonitoringData = async (
  StationID,
  EquipmentDetailID,
  ChillerMonitoringDate
) => {
  return await axios.get(
    `ManualLog/ChillerMonitoring_GetChillerMonitoringData?StationID=${StationID}&EquipmentDetailID=${EquipmentDetailID}&ChillerMonitoringDate=${ChillerMonitoringDate}`
  );
};
const ChillerMonitoring_GetChillerMonitoringReportData = async (
  StationID,
  EquipmentDetailID,
  ChillerMonitoringDate
) => {
  return await axios.get(
    `ManualLog/ChillerMonitoring_GetChillerMonitoringReportData?StationID=${StationID}&EquipmentDetailID=${EquipmentDetailID}&ChillerMonitoringDate=${ChillerMonitoringDate}`
  );
};

const ChillerMonitoring_SaveChillerMonitoring = async (obj) => {
  return await axios.post(
    `ManualLog/ChillerMonitoring_SaveChillerMonitoring`,
    obj
  );
};

const ChillerMonitoring_ApprovelRejectRequest = async (obj) => {
  return await axios.post(
    `ManualLog/ChillerMonitoring_ApprovelRejectRequest`,
    obj
  );
};

export {
  DailyElectricalPanel_GetDailyElectricalPanelDetailes,
  ChillerMonitoring_GetChillerMonitoringData,
  ChillerMonitoring_GetChillerMonitoringReportData,
  DailyElectricalPanelMonitor_GetEquipmentCode,
  DailyElectricalPanel_SaveDailyElectricalPanel,
  DailyElectricalPanel_ApprovelRejectRequest,
  ListDailyElectricalPanel_GetReportList,
  ChillerMonitoring_GetEquipmentCode,
  ChillerMonitoring_SaveChillerMonitoring,
  ChillerMonitoring_ApprovelRejectRequest,
};
