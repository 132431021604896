import React, { useState, useContext, useReducer, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { PeriodicService } from "./PeriodicService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { HtmlTable } from "../../components/HtmlTable";
import $ from "jquery";

const _periodicService = new PeriodicService();

const columnNames = [
  {
    title: "Parameter Name",
  },
  {
    title: "Threshold High Value",
    width: "200px",
    // createdCell: (td, cellData, rowData, row, col) => {
    //   var b = $(`<p class='' title='Edit'>
    //      ${rowData[1]}
    //     </p>`)

    //   $(td).html(b);

    // },
  },
  { title: "Latest Sensor Value", width: "200px" },
];
export default function ({ equipmentID }) {
  const { getCookies, setCookies, handleCal, showLoading, hideLoading } =
    useContext(AppContext);
  const [testrundata, settestrundata] = useState([]);
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [data, setRows] = useState([]);
  const [Loading, setLoading] = useState(true);

  const SetLoading = (e) => {
    setLoading(e);
  };

  const getEquipmentDetails = () => {
    setLoading(true);
    _periodicService
      .GetEquipmentDetails(
        parseInt(getCookies(COOKIES.PeriodicStationID)),
        equipmentID
      )
      .then((res) => {
        if (res != null && res.data != null) {
          if (
            res.data.equipmentDetails != null &&
            res.data.equipmentDetails.length > 0
          ) {
            var dataLst = [];
            var EquipForSpare = [];
            res.data.equipmentDetails.map((v) => {
              var data = [];
              data.push("");
              data.push(v.EquipmentDetailID);
              data.push(v.EquipmentCode);
              dataLst.push(data);
              EquipForSpare.push({
                key: v.EquipmentCode,
                value: v.EquipmentDetailID,
              });
            });
            setRows(dataLst);
            setLoading(false);
          } else {
            setRows([]);
            setLoading(false);
          }
        }
      });
  };
  useEffect(() => {
    getEquipmentDetails();
  }, [equipmentID]);

  const getTestRun = (EquipmentDetailID) => {
    settestrundata([]);
    ;

    var stationId = localStorage.getItem(COOKIES.PeriodicStationID)
      ? localStorage.getItem(COOKIES.PeriodicStationID)
      : 1;
    // setLoading(true);
    _periodicService
      .GetTestRunData(stationId, EquipmentDetailID)
      .then((res) => {
        var testrun = [];
        if (res != null && res != undefined && res?.data[0].length > 0) {
          res.data[0].map((v, i) => {
            var data = [];
            data.push(v.ParameterName);
            data.push(v.ThresholdHigh);
            data.push(v.SensorValue);
            testrun.push(data);
          });
          settestrundata(testrun);
          // setLoading(false);
        } else {
          settestrundata([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {!Loading ? (
        <Accordion allowZeroExpanded={true}>
          {data?.map((item, key) => {
            return (
              <AccordionItem key={key} uuid={key}>
                <AccordionItemHeading onClick={() => getTestRun(item[1])}>
                  <AccordionItemButton>{item[2]}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {
                    testrundata != null && testrundata.length > 0 ? (
                      <HtmlTable
                        columnList={columnNames}
                        rowList={testrundata}
                        id={`Testrun${key}`}
                      />
                    ) : (
                      <>
                        <div
                          className="text-center"
                          style={{ margin: "10rem" }}
                        >
                          <div className="loader-Wrapper">
                            <div className="Customloader-div">
                              <span className="loader"></span>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    // <HtmlTable
                    //   columnList={columnNames}
                    //   rowList={testrundata}
                    //   id={`Testrun${key}`}
                    // />
                  }
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <>
          <div className="text-center" style={{ margin: "10rem" }}>
            <div className="loader-Wrapper">
              <div className="Customloader-div">
                <span className="loader"></span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
