import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import $ from "jquery";
import {
  getYMDdate,
  getUserDetails,
  GetLoginUserID,
} from "./../../services/CommonService";
import { useNavigate } from "react-router-dom";

import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import { COOKIES } from "../../services/Global";
import { useLocation } from "react-router-dom";
import { MonthCustomView } from "./CustomPages/MonthCustomView";

const localizer = globalizeLocalizer(globalize);

export const CalenderChildMonth = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className=" mt-3">
      <Calendar
        localizer={localizer}
        defaultView={"daily"}
        views={{
          daily: MonthCustomView,
        }}
        toolbar={false}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        selectable={true}
      />
    </div>
  );
};

CalenderChildMonth.propTypes = {
//  date: localStorage.getItem("monthDate"),
date: PropTypes.string,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalenderChildMonth.range = (date, { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CalenderChildMonth.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "month");

    case Navigate.NEXT:
      return localizer.add(date, 1, "month");

    default:
      return date;
  }
};
CalenderChildMonth.title = (date, { localizer }) => {
  const month = date.toLocaleString("default", { month: "long" });
  var titleString = `${month}-${date.getFullYear()}`;
  return titleString;
};
