import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import {
  getYMDdate,
  getUserDetails,
  Week5_render,
  determineWeekColor,
} from "../../services/CommonService";
import { PeriodicService } from "./PeriodicService";
import { PeriodicWeekData, checkDate, checkMonth } from "./PeriodicWeekData";
import { ContextValues } from "../PeriodicMaintenance/PeriodicContextProvider";

const _periodicServices = new PeriodicService();

export default function CalendarMonthView({
  
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {

  
  const currRange = useMemo(
    () => CalendarMonthView.range(date, { localizer }),
    [date, localizer]
  );
  const navigate = useNavigate();
  const [events, setEvents] = [];
  const [week, setWeek] = useState(0);
  const { getCookies, showLoading, hideLoading } = useContext(AppContext);
  const [JobPendingDate, setJobPendingDate] = useState([]);

  const { setPeriodicModel,PeriodicModel } = ContextValues();

  useEffect(() => {
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      navigateTo(1, new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    }

  }, [])

  const navigateTo = (i, date) => {
    
    if (date == "22") {
      if (localStorage.getItem("monthDate") == null) {
        localStorage.setItem(
          "monthDate",
          getYMDdate(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 22)
          )
        );
        localStorage.setItem("monthWeek", i);
      } else {
        var weekdate = new Date(localStorage.getItem("monthDate"));
        localStorage.setItem(
          "monthDate",
          getYMDdate(new Date(weekdate.getFullYear(), weekdate.getMonth(), 22))
        );
        localStorage.setItem("monthWeek", i);
      }
      navigate("/MonthCalender", {
        state: { week: i, date: getYMDdate(localStorage.getItem("monthDate")) },
      });
    } else {
      localStorage.setItem("monthDate", getYMDdate(date));
      localStorage.setItem("monthWeek", i);
      navigate("/MonthCalender", {
        state: { week: i, date: getYMDdate(date) },
      });
    }
  };

  useEffect(() => {
    var date = new Date();
    setPeriodicModel((pre) => ({
      ...pre,
      CurrentWeek : getWeekOfMonth(date)
    }))
    //setWeek(getWeekOfMonth(date));
  }, [PeriodicModel?.PeriodicModel]);

  useEffect(() => {
    var currentMonth, currentYear, todayDate;
    var curDate = localStorage.getItem("monthDate");
    var d;
    if (curDate != null) {
      d = new Date(curDate);
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    } else {
      d = new Date();
      localStorage.setItem("monthDate", new Date());
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    }
    showLoading();

    _periodicServices
      .JobSchedulePendingDate(
        currentMonth,
        currentYear,
        todayDate,
        2,
        parseInt(getCookies(COOKIES.PeriodicStationID))
      )
      .then((res) => {
        hideLoading();

        if (res != null && res.data != null && res.data != undefined) {
          setJobPendingDate([...res.data]);
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  }, [
    localStorage.getItem("monthDate"),
    date,
    getCookies(COOKIES.PeriodicStationID),
  ]);

  //get week number of date
  function getWeekOfMonth(date) {
    let adjustedDate = date.getDate() + date.getDay();
    let prefixes = ["0", "1", "2", "3", "4", "5"];
    return parseInt(prefixes[0 | (adjustedDate / 7)]) + 1;
  }

  var week1 = currRange.slice(0, 7);
  var week2 = currRange.slice(7, 14);
  var week3 = currRange.slice(14, 21);
  var week4 = currRange.slice(21, 28);

  var week5 = PeriodicWeekData(currRange[0]);

  var weekLst = [week1, week2, week3, week4];

  var isWeek;
  var isCurrentMonth;
  let its_future_week5 = false;
  let pendingcolor_5 = false;
  let checkdaterange_5 = false;
  let isCurrentMonth_5 = false;
  let isCurrentWeek = false;
  let Week5IsNoJob = false;

  return (
    <>
      {weekLst.map((v, i) => {
        var color = v.map((c) => {
          return c.getDate();
        });
        var dates = v.map((c) => {
          return getYMDdate(c);
        });

        var isPending = v.map((c) => {
          return JobPendingDate.some((x) => x.JobDate == getYMDdate(c));
        });
        let CheckNoJob = v.map((c) => {
          return JobPendingDate.some(
            (x) => x.JobDate == getYMDdate(c) && x.CheckAnyJob
          );
        });
        var pendingcolor = isPending.includes(true);
        let IsNoJob = CheckNoJob.every((x) => x == false);
        var checkdaterange = dates.some(checkDate);
        isCurrentMonth = dates.some(checkMonth);

        let j = 0;
        if (color.includes(new Date().getDate())) {
          j = i + 1;
          if (j == 4) {
            isWeek = true;
          }
        }

        let its_future_week = false;
        if (v) {
          its_future_week = v[0].getTime() > new Date().getTime();
        }
        if (i + 1 == 4) {
          its_future_week5 = its_future_week;
          pendingcolor_5 = pendingcolor;
          checkdaterange_5 = checkdaterange;
          isCurrentMonth_5 = isCurrentMonth;
          isCurrentWeek = i + 1 == j;
          Week5IsNoJob = IsNoJob;
        }

        // let its_future_week = Math.ceil(new Date().getDate() / 7) < i + 1 ;
        return (
          <div className={"row calender"}>
            <div
              className={
                "col card CalenderCard_Week " +
                determineWeekColor(
                  i,
                  j,
                  isCurrentMonth,
                  pendingcolor,
                  IsNoJob,
                  its_future_week
                )
              }
            >{`Week ${i + 1}`}</div>
            {v.map((c) => {
              let shortMonth = c.toLocaleString("default", {
                month: "short",
              });
              let date = c.getDate();
              return (
                <div
                  className={
                    "col card CalenderCard_Week_Date " +
                    determineWeekColor(
                      i,
                      j,
                      isCurrentMonth,
                      pendingcolor,
                      IsNoJob,
                      its_future_week
                    )
                  }
                  onClick={() => navigateTo(i + 1, c.toDateString())}
                >
                  {date < 10 ? "0" + date : date}
                </div>
              );
            })}
          </div>
        );
      })}
      {
        <>
          {Week5_render(
            week5,
            isCurrentMonth_5,
            pendingcolor_5,
            checkdaterange_5,
            its_future_week5,
            isCurrentWeek,
            navigateTo,
            Week5IsNoJob
          )}
        </>
      }
    </>
  );
}

CalendarMonthView.propTypes = {
  date: localStorage.getItem("monthDate"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalendarMonthView.range = (date, { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CalendarMonthView.navigate = (date, action, { localizer }) => {
  
  if (localStorage.getItem("monthDate") == null) {
    localStorage.setItem("monthDate", getYMDdate(date));
  }
  var d = localStorage.getItem("monthDate");

  var dLst = d.split("-");
  var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
  switch (action) {
    case Navigate.PREVIOUS:
      newD.setMonth(newD.getMonth() - 1);

      localStorage.setItem("monthDate", getYMDdate(newD));
      return newD;

    case Navigate.NEXT:
      newD.setMonth(newD.getMonth() + 1);
      localStorage.setItem("monthDate", getYMDdate(newD));
      return newD;
    // return localizer.add(newD, 1, "day");

    default:
      return new Date();
  }
  // switch (action) {
  //   case Navigate.PREVIOUS:

  //     return localizer.add(newD, -1, 'month')

  //   case Navigate.NEXT:
  //     return localizer.add(newD, 1, 'month')

  //   default:
  //     return date
  // }
};

CalendarMonthView.title = (date, { localizer }) => {
  localStorage.setItem("monthDate", getYMDdate(date));
  const month = date.toLocaleString("default", { month: "long" });
  var titleString = `${month}-${date.getFullYear()}`;
  return titleString;
};
