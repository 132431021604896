import { useEffect, useState, useContext } from "react";
import { getYMDdate, GetLoginUserID } from "../../../services/CommonService";
import { data, event } from "jquery";
import { Dropdown } from "../../../components";
import { AppContext } from "../../../services/ContextProvider";
// import Global, { COOKIES } from "../../services/Global";
import PageHeaderTitle from "../../../components/PageHeaderTitle";
import {
  BufferConfig_GetDropDown,
  BufferConfig_AddOrUpdate,
  BufferConfig_GetBufferConfigList,
  BufferConfig_DeleteConfig,
  BufferDays_GetByDepartmentID,
} from "./BufferconfigServie";
import { HtmlTable } from "../../../components/HtmlTable";
import {
  showSuccessMsg,
  showSaveError,
  showDeleteSuccess,
  showSaveSuccess,
} from "../../../components/ToastMsg";
import swal from "sweetalert";
import $ from "jquery";

export default function BufferConfig() {
  const initState = {
    DepartmentList: [],
    TaskTypeList: [],
    BufferTypeList: [],
    BufferConfigID: "0",
    departmentID: "",
    SystemID: "",
    tasktypeID: "",
    BuffertypeID: "",
    BufferPeriod: "",
  };
  const {
    showLoading,
    hideLoading,
    getCookies,
    handleGlobalStationName,
    PerformanceDropdownList,
    setPerformanceDropdownList,
  } = useContext(AppContext);
  // const { StationID, StartDate } = PerformanceDropdownList;
  const [configList, setconfigList] = useState([]);
  const [dropdownList, setdropDownList] = useState({
    DepartmentList: [],
    TaskTypeList: [],
    BufferTypeList: [],
  });
  const [ConfigModel, setConfigModel] = useState(initState);
  const [errors, setErrors] = useState({
    departmentID: "",
    SystemID: "",
    tasktypeID: "",
    BuffertypeID: "",
    BufferPeriod: "",
  });

  useEffect(() => {
    initDropdown();
    getConfigList();
  }, []);

  const initDropdown = async () => {
    await BufferConfig_GetDropDown().then((res) => {
      const { subDepartmentList } = res.data.data;
      let Department = [];
      const itemsWithoutChildren = subDepartmentList.filter(
        (item) =>
          !subDepartmentList.some((child) => child.parentId === item.value)
      );
      itemsWithoutChildren.map((data) => {
        Department.push({
          value: data.value,
          label: data.label,
          key: data.key,
        });
      });

      Department = Department.filter((ele) => ele.label != "TVS & VAC");
      setConfigModel((pre) => ({
        ...pre,
        DepartmentList: res.data.data.departmentList,
        SystemList: Department,
        TaskTypeList: res.data.data.taskTypeList,
        BufferTypeList: res.data.data.bufferTypeList,
      }));
    });
  };

  const getConfigList = async () => {
    await BufferConfig_GetBufferConfigList().then((res) => {

      if (
        res != null &&
        res.data.data.configList != null &&
        res.data.data.configList.length > 0
      ) {
        setconfigList([...res.data.data.configList]);
      } else {
        setconfigList([]);
      }
    });
  };

  const GetDepartmentByDepartmentID = async (id) => {
    await BufferDays_GetByDepartmentID(id).then((res) => {
      if (
        res != null &&
        res.data.data.departmentList != null &&
        res.data.data.departmentList.length > 0
      ) {
        setConfigModel((pre) => ({
          ...pre,
          SystemList: res.data.data.departmentList,
        }));
      } else {
        setConfigModel((pre) => ({
          ...pre,
          SystemList: [],
        }));
      }
    });
  };

  const CommonHandleChange = (e) => {
    const { name, value } = e.target;
    setConfigModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == "departmentID") {
      GetDepartmentByDepartmentID(value);
    }
  };
  const columnNames = [
    { title: "SystemID", visible: false, data: "SystemID" },
    { title: "BufferTypeID", visible: false, data: "BufferTypeID" },
    { title: "DepartmentID", visible: false, data: "DepartmentID" },
    { title: "BufferTypeID", visible: false, data: "BufferTypeID" },
    { title: "Department", data: "DepartmentName" },
    { title: "Sub Department", data: "SystemName" },
    { title: "Task Type", data: "SubModuleDescription" },
    { title: "Buffer Type", data: "StatusName" },
    { title: "Buffer Period", data: "BufferPeriod" },
    {
      title: "Submit",
      data: "BufferDaysConfigID",
      // visible:
      //     getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
      //     getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONEDIT")) {
        b =
          $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#timebutton" >
                  <i class='fa fa-pencil m-r-5'></i>
                </button>`).on("click", function () {
            EditBufferConfig(rowData);
          });
        // }
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
        c = $(`<button class='btn icon-btn'  title='delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function () {
          onDeleteClick(rowData);
        });
        // }
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];
  const EditBufferConfig = (rowData) => {
    setConfigModel((pre) => ({
      ...pre,
      departmentID: rowData.DepartmentID,
      SystemID: rowData.SystemID,
      tasktypeID: rowData.TaskTypeID,
      BuffertypeID: rowData.BufferTypeID,
      BufferPeriod: rowData.BufferPeriod,
      BufferConfigID: rowData.BufferDaysConfigID,
    }));
  };
  const AddOrUpdateConfiguration = async (e) => {
    e.preventDefault();

    let model = ConfigModel;
    let modelErrors = errors;
    let flag = true;
    const { departmentID, SystemID, tasktypeID, BuffertypeID, BufferPeriod } =
      model;

    if (departmentID != null && departmentID != "") {
      modelErrors.departmentID = "";
    } else {
      modelErrors.departmentID = "Please select department";
      flag = false;
    }
    if (SystemID != null && SystemID != "") {
      modelErrors.SystemID = "";
    } else {
      modelErrors.SystemID = "Please select system";
      flag = false;
    }
    if (tasktypeID != null && tasktypeID != "") {
      modelErrors.tasktypeID = "";
    } else {
      modelErrors.tasktypeID = "Please select task type";
      flag = false;
    }
    if (BuffertypeID != null && BuffertypeID != "") {
      modelErrors.BuffertypeID = "";
    } else {
      modelErrors.BuffertypeID = "Please select buffer type";
      flag = false;
    }
    if (BufferPeriod != null && BufferPeriod != "") {
      modelErrors.BufferPeriod = "";
    } else {
      modelErrors.BufferPeriod = "Please enter buffer period";
      flag = false;
    }
    setErrors({ ...modelErrors });

    if (flag) {
      model.SavedBy = GetLoginUserID();
      model.SavedDate = getYMDdate(new Date());
      model.SubModuleID = tasktypeID;
      BufferConfig_AddOrUpdate(model)
        .then((res) => {
          if (res.data.data > 0) {
            showSaveSuccess();
            setconfigList([]);
            setConfigModel((pre) => ({
              ...pre,
              BufferConfigID: "0",
              departmentID: "",
              SystemID: "",
              tasktypeID: "",
              BuffertypeID: "",
              BufferPeriod: "",
            }));
            getConfigList();
          }
          // showSaveSuccess();
          // setconfigList([]);
          // setConfigModel((pre) => ({
          //   ...pre,
          //   BufferConfigID: "",
          //   departmentID: "",
          //   SystemID: "",
          //   tasktypeID: "",
          //   BuffertypeID: "",
          //   BufferPeriod: "",
          // }));
          // getConfigList();
        })
        .catch((err) => {
          console.log(err);
          setConfigModel((pre) => ({
            ...pre,
            departmentID: "",
            SystemID: "",
            BuffertypeID: "",
            BufferPeriod: "",
            tasktypeID: "",
          }));
        });
    }
  };
  const onDeleteClick = (rowData) => {
    let id = rowData.BufferDaysConfigID;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        BufferConfig_DeleteConfig(id)
          .then((response) => {
            if (response.data.data == 1) {
              showDeleteSuccess();
              getConfigList();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  return (
    <div>
      <PageHeaderTitle title={"Buffer Days Configuration"} />
      <div className="card">
        <div className="card-body">
          <form id="bufferconfig">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Department<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={ConfigModel.DepartmentList}
                    placeholder="Select"
                    name={"departmentID"}
                    HandleChange={CommonHandleChange}
                    value={ConfigModel.departmentID}
                    className=""
                  />
                  <span className="text-danger"> {errors.departmentID} </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Sub Department<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={ConfigModel.SystemList}
                    placeholder="Select"
                    name={"SystemID"}
                    HandleChange={CommonHandleChange}
                    value={ConfigModel.SystemID}
                    className=""
                  />
                  <span className="text-danger"> {errors.SystemID} </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Task Type<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={ConfigModel.TaskTypeList}
                    placeholder="Select"
                    name={"tasktypeID"}
                    HandleChange={CommonHandleChange}
                    value={ConfigModel.tasktypeID}
                    className=""
                  />
                  <span className="text-danger"> {errors.tasktypeID} </span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Buffer Type<span className="text-danger"> *</span>{" "}
                  </label>
                  <Dropdown
                    data={ConfigModel.BufferTypeList}
                    placeholder="Select"
                    name={"BuffertypeID"}
                    HandleChange={CommonHandleChange}
                    value={ConfigModel.BuffertypeID}
                    className=""
                  />
                  <span className="text-danger"> {errors.BuffertypeID} </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Buffer Period<span className="text-danger"> *</span>{" "}
                  </label>
                  <input
                    className="form-control "
                    type="number"
                    name="BufferPeriod"
                    id="DelayCount"
                    value={ConfigModel.BufferPeriod}
                    onChange={CommonHandleChange}
                  />
                  <span className="text-danger"> {errors.BufferPeriod} </span>
                </div>
              </div>
              <div className="col-md-2 mt-4 alert-dismissible">
                <button
                  type="submit"
                  id="addRow_ers"
                  onClick={AddOrUpdateConfiguration}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
          </form>

          <HtmlTable
            columnList={columnNames}
            rowList={configList}
            id={"ConfigureList"}
          />
        </div>
      </div>
    </div>
  );
}
