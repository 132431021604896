import Axios from "../../services/AxiosService";

const axios = new Axios();
const CyclicCheck_GetList = async (EmployeeID = 0) => {
  return await axios.get(
    `CyclicCheck/CyclicCheck_GetList?EmployeeID=${EmployeeID}`
  );
};
const CyclicCheck_DeleteByID = async (EmployeeID = 0) => {
  return await axios.get(
    `CyclicCheck/CyclicCheck_GetList?EmployeeID=${EmployeeID}`
  );
};
const CyclicCheck_GetListByCyclicCheckId = async (CyclicCheckId = 0) => {
  return await axios.get(
    `CyclicCheck/CyclicCheck_GetListByCyclicCheckId?CyclicCheckId=${CyclicCheckId}`
  );
};
const CyclicCheck_DeleteCyclicCheckId = async (
  CyclicCheckId = 0,
  ModifiedBy = 0
) => {
  return await axios.get(
    `CyclicCheck/CyclicCheck_DeleteCyclicCheckId?CyclicCheckId=${CyclicCheckId}&&ModifiedBy=${ModifiedBy}`
  );
};
const CyclicCheck_CreateUpdate = async (CyclicCheckModel) => {
  return await axios
    .post(`CyclicCheck/CyclicCheck_CreateUpdate`, CyclicCheckModel)
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};
export {
  CyclicCheck_GetList,
  CyclicCheck_DeleteByID,
  CyclicCheck_GetListByCyclicCheckId,
  CyclicCheck_CreateUpdate,
  CyclicCheck_DeleteCyclicCheckId,
};
