import React, { useRef, useState, useReducer } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale
} from "chart.js";
import DashboardService from "../../screens/dashboard/DashboardService";
import { DataTableReports } from "../DataTableReports";
import BootrapModel from "../BootrapModel";
import { Line, getElementsAtEvent, getElementAtEvent } from "react-chartjs-2";
import { COLORS } from "../../services/CommonService";
import zoomPlugin from "chartjs-plugin-zoom";
import $ from 'jquery';
import { useEffect } from "react";
import "chartjs-adapter-moment"
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import ErrorBoundary from "../../components/ErrorBoundary";
import subMinutes from "date-fns/subMinutes";
import { makeUnique,dynamicsort } from "../../services/CommonService";
import { addHours, parse, subDays, subHours, subMonths, subYears } from "date-fns";
const obj = new DashboardService();


ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    annotationPlugin);



export const LineChartForLive = ({ Tlow,Alow,AHigh,Thigh,id, parameterName,unit, parameterID, data, yTitle, min, max, title, year, month, reporttitle, download, mode, Flag, xTitle, chartMax, chartMin }) => {






    const ref = useRef();
    


    const [ModelRefresh, setModelRefresh] = useReducer(x => x + 1, 0);


    




    const printElementAtEvent = (element) => {
        if (!element.length) return;

        const { datasetIndex, index } = element[0];

        return data.labels[index];

    };

    const onClick = (event) => {
        const { current } = ref;

        if (!current) {
            return;
        }
        var sparename = printElementAtEvent(getElementsAtEvent(current, event));

    };



    const GetScdadataUsingMaxTime = () => {

        
        const { current } = ref;

        // console.log(Math.max.apply(null, current.data.datasets.map(function(d) { return d.data.max(); })));
        const { datasets } = current.data;
        
        var EquipmentCode = [];
        datasets.map((x, i) => EquipmentCode.push(x.equipmentDetailID));
        
        let testDate = new Date(chartMin); 
        testDate = addHours(testDate,6)
        console.log(testDate)
         obj.GetScdadataUsingMaxTime(testDate, parameterID, EquipmentCode.toString(), Flag).then(async (res) => {

            // const chart = ref.current.chartInstance;

            
            if(Flag == 1)
            {
                var Date_Time = new Date(chartMin);
        
                chartMin = subHours(Date_Time, 5).toJSON();
            }
            else if (Flag == 2)
            {
                chartMin = subDays(Date_Time, 5).toJSON();
            }
            else if (Flag == 3)
            {
                chartMin = subMonths(Date_Time, 5).toJSON();
            }
            // console.log(chartMin + "---> Before condition")
            if (res != null && res.data != undefined && res.data != null &&  res.data.length > 0) {


                var result = res.data;
                var chartMins = new Date(new Date(chartMin));

                if(Flag == 1)
                chartMin = subHours(chartMins, 5).toJSON();
                else if (Flag == 2)
                {
                chartMin = subDays(chartMins, 1).toJSON();
                }
                else if (Flag == 3)
                {
                chartMin = subMonths(chartMins, 5).toJSON();
                }
                // console.log(chartMin + "---> After condition")

                result.map(async (x) => {
                


                    

                    const findoutINdex = datasets.findIndex((y) => {


                        return y.equipmentDetailID == x.equipmentDetailID
                    });



                    const datas = datasets[findoutINdex].data;




                    var arr3 = [...datas, ...x.data];
                    var UniqueArray = [];
                    // if(Flag == 1)
                    // {
                    //      UniqueArray = makeUnique(arr3, ["sensorID"]);

                    // }
                    // else{
                        UniqueArray = makeUnique(arr3, ["x","y"]);
                    // }
                    
                    
                   UniqueArray = UniqueArray.sort(dynamicsort("x","desc"));


                    current.data.datasets[findoutINdex].data = await UniqueArray;
                    
       
                 current.update("none")
                    // findoutINdex.
                })


            }
         

            //= arr3; 



            //  array.map(item => item.)
            //  . filter((value, index, self) => self. indexOf(value) === index)


        }).catch((err) => {
            
            console.log(err);
        })

    }






    function LineChart({ data }) {
        return (
            <Line
            height = {180}
                id={id}
                data={data}
            
                

                options={{
                    // parsing: {
                    //     xAxisKey: 'label',
                    //     yAxisKey: 'data',

                    // },
                    responsive: true,

                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                        axis: "xy"
                    },
                  
                    plugins: {
                        datalabels: {
                            display: false
                        },
                        zoom: {
                            
                            limits: {
                                y: {min: (AHigh != null && AHigh > 0) ? ((AHigh-AHigh*0.01)-(AHigh)) : -1 , max: null},
                              
                              },
                            pan: {
                                enabled: true,
                                // onPanStart({ chart, point }) {
                                //     const area = chart.chartArea;
                                // },
                               
                                onPanComplete(context) {

                                     GetScdadataUsingMaxTime();
                                },
                                mode: "xy",
                            },

                        },
                  
                        legend: {

                            title: {
                                display: true,
                                text: title,

                                font: {
                                    size: 14,
                                    weight: 'bold',

                                },
                            },
                            labels:{
                 
                                font:{
                                    weight : "800",
                                    style : "normal",


                                },
                                padding : 3,
                                textAlign:"center",
                                boxHeight : 10,
                                // boxWidth : 12

                            },
                            display: true,
                            position: "top",
                        },

                        tooltip: {
                            mode: "nearest",
                            axis: "x",
                            callbacks: {
                                label(x)
                                {
                                     return  x.dataset.label +" : "+ x.formattedValue + unit
                                                                    
                                },
                                 title (y)
                                 {
                                    if(Flag !== 1)
                                    {
                                        
                                        var label = y[0]?.label.split(",");
                                         return label[0] + ',' + label[1];
                                        // if (Flag == 2) {
                                        //     var label = y[0]?.label.split(",");
                                        //     return label[0] + ',' + label[1];
                                        // }
                                        // else if (Flag == 3){
                                        //     var label = y[0]?.label.split(",");
                                        //     var labelmonth = y[0]?.label.split(",")[0]?.split(' ')
                                        //     return labelmonth[0] + ',' + label[1];
                                        // }
                                        // else if (Flag == 4){
                                        //     var label = y[0]?.label.split(",");
                                        //     return label[1];
                                        // }
                                        // else{
                                        //     var label = y[0]?.label.split(",");
                                        //     return label[0] + ',' + label[1];
                                        // }
                                    }
                                    else
                                    {
                                        
                                        
                                       return y[0]?.label
                                    }
                                 }
                                
                            }
                        },
                        animations: false,
                        parsing: false,
                        normalized: true,
                        annotation: {

                            annotations: {

                                  line1:AHigh != "" && AHigh > 0 && {
                                    type: 'line',
                                    yMin: AHigh,
                                    yMax: AHigh,
                                    // label : "fdijiu",
                                    borderColor: COLORS.red,
                                    borderWidth: 2,
                                  } ,
                                line2: Alow != "" && Alow > 0 ? {
                                    type: 'line',
                                    yMin: Alow,
                                    yMax: Alow,
                                    borderColor: COLORS.red,
                                    borderWidth: 2,
                                } : null,
                                line3: Tlow != "" && Tlow > 0 && Tlow > 0 ? {
                                    type: 'line',
                                    yMin: Tlow,
                                    yMax: Tlow,
                                    borderColor: COLORS.orange,
                                    borderWidth: 2,
                                } : null,
                                line4: Thigh != "" && Thigh > 0 ? {
                                    type: 'line',
                                    yMin: Thigh,
                                    yMax: Thigh,
                                    borderColor: COLORS.orange,
                                    borderWidth: 2,
                                } : null,

                            }
                        }

                    },

                    scales: {

                        x: {
                            type: "time",
                            time: {
                              unit: Flag == 1 ? "hour" : Flag == 2 ? "day" : Flag == 3 ? "month" : Flag == 4 ? "year" :  "hour",
                                // stepSize: 1,
                                //  unit : "hour",
                                // displayFormats: { hour: 'mm:ss' }
                                // displayFormats:""
                             
                            },

                            min: chartMin,
                            max: chartMax,

                            display: true,
                            grid: {
                                color: "rgba(0, 0, 0, 0.3)"
                            },
                            ticks: {
                                source: "auto",
                                maxRotation: 0,
                                autoSkip: true,

                                maxTicksLimit: 8,

                                includeBounds: true,
                                major: {
                                    enabled: true
                                }

                            },
                            title: {
                                display: true,
                                text: xTitle,
                                color: "#333",
                                font: {
                                    size: 15,
                                    weight: "600",
                                    lineHeight: 1.2,
                                },
                                padding: { top: 0, left: 10, right: 10, bottom: 5 },
                            },

                        },
                        
                        y: {
                            
                             beginAtZero: true,
                                //  max : (AHigh != null && AHigh > 0) ? ((parseFloat(AHigh) * 0.1)+ parseFloat(AHigh) ) : (50*0.1)+ 50,
                             suggestedMax : (AHigh != null && AHigh > 0) ? ((parseFloat(AHigh) * 0.1)+ parseFloat(AHigh) ) : (50*0.1)+ 50,
                            //  suggestedMin:0,
                             min: 0,
                            
                             ticks: {
                                callback: function(value, index, values) {
                                    if(value > 0){
                                        return value.toFixed(1);
                                    }
                                    else{
                                        
                                        var vals = 0
                                        // if(AHigh != null && AHigh > 0 && Alow != null && Alow > 0){
                                        //     let sum = AHigh + Alow
                                        //     let average = sum / 2
                                        //     return average.toFixed(1)
                                        // }
                                        return vals.toFixed(1);
                                    }
                                }
                                
                              },
                            
                            title: {

                                display: true,
                                text: yTitle,
                                color: "#333",
                                font: {
                                    size: 15,
                                    weight: "600",
                                    lineHeight: 1.2,
                                },
                                padding: { top: 0, left: 10, right: 10, bottom: 5 },
                            },

                        },
                    },
                }}
                ref={ref}



                onClick={download ? onClick : null}



            />
        )

    }




    return (
        <div>

            <ErrorBoundary Child={<LineChart data={data} />} Flag={Flag} ErrorMessage = {"This data was not cumulated because it's large gap between data."} >

            </ErrorBoundary>

        </div>
    );
};



