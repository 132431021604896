import { Dropdown } from "../../components/Dropdown";
import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import { showWarningMsg } from "./../../components/ToastMsg";
import { GetLoginUserID } from "../../services/CommonService";
import { getUserDetails } from "./../../services/CommonService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";

export const AddUser = ({
  roles,
  empCodes,
  onSubmit,
  data,
  clearUser,
  Refresh,
  DBRoles,
}) => {
  const [UserName, setUserName] = useState("");
  const [UserModel, setUserModel] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setConfShowPassword] = useState(false);
  const { getCookies } = useContext(AppContext);
  const [errors, setErrors] = useState({
    empCodeErr: false,
    usernameErr: false,
    passwordErr: false,
    passwordCharErr: false,
    confPasswordErr: false,
    confPasswordCharErr: false,
    roleErr: false,
  });

  useEffect(() => {
    setConfShowPassword(false);
    setShowPassword(false);

    if (data != null) {
      $("[name=employeeID]").val(data.employeeID);
      $("[name=employeeCodeName]").val(
        `${data.employeeCode} - ${data.firstName} ${data.lastName}`
      );
      $("#IsActive").prop("checked", data.isActive);
      var roleName = roles.find(
        (v) => v.value.toLowerCase().trim() == data.roleID.toLowerCase().trim()
      );
      var roleID = roleName != null ? roleName.index : 0;
      setUserName(data.username);
      setUserModel((prevState) => ({
        ...prevState,
        UserName: data.username,
        password: data.password,
        confPassword: data.password,
      }));
      $("[name=userRole]").val(roleID);
      // // $("[name=username]").val(data.username);
      // $("[name=password]").val(data.password);
      // $("[name=confPassword]").val(data.password);
    } else {
      $("[name=employeeCode]").val("0");
      $("[name=userRole]").val("0");
      $("#IsActive").prop("checked", true);
      $("[name=username]").val("");
      $("[name=password]").val("");
      $("[name=confPassword]").val("");
    }
  }, [data]);

  const validate = (e) => {
    e.preventDefault();
    var userID = Number(e.target.userID.value);
    var empCode =
      userID == 0
        ? Number(e.target.employeeCode.value)
        : Number(e.target.employeeID.value);
    var username = e.target.username.value;
    var password = e.target.password.value;
    var confPassword = e.target.confPassword.value;
    var role = e.target.userRole.value;
    // var isActive = e.target.IsActive.checked;
    var reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    var error = errors;
    var isSuccess = true;

    if (empCode == 0) {
      error.empCodeErr = true;
      isSuccess = false;
    } else {
      error.empCodeErr = false;
    }

    if (username.trim() == "") {
      error.usernameErr = true;
      isSuccess = false;
    } else {
      error.usernameErr = false;
    }

    if (password.trim() == "") {
      error.passwordErr = true;
      isSuccess = false;
    } else {
      error.passwordErr = false;
    }

    if (error.passwordErr == false && !password.trim().match(reg)) {
      error.passwordCharErr = true;
      isSuccess = false;
    } else {
      error.passwordCharErr = false;
      isSuccess = true;
    }

    if (confPassword.trim() == "") {
      error.confPasswordErr = true;
      isSuccess = false;
    } else {
      error.confPasswordErr = false;
    }

    if (role == 0) {
      error.roleErr = true;
      isSuccess = false;
    } else {
      error.roleErr = false;
    }

    setErrors({ ...error });

    if (isSuccess) {
      if (password.trim() == confPassword.trim()) {
        const IsTvsvac = getCookies(COOKIES.IsTvsvac)
        // var roleExists = roles.find((v) => v.index == Number(role));
        var roleExists = DBRoles.find((v) => v.index == Number(role));
        // var roleExistsKey = IsTvsvac === "true" ? roleExists.key : roleExists.key.slice(0, -5);
        var roleExistsKey = roleExists.dbName;
        var roleID = roleExists != null ? roleExists.value : `${roleExists}`;
        var user = {
          UserID: userID,
          UserGoodID: getCookies(COOKIES.UserGoodID),
          EmployeeID: empCode,
          Username: username,
          Password: password,
          RoleID: roleID,
          RoleName: roleExistsKey,
          // IsActive: isActive,
          SavedBy: GetLoginUserID(),
          OldPassword: data != null ? data.password : "",
          IsPasswordChanged: data != null && data.password != password,
        };
        
        console.log(user);
        onSubmit(user);
      } else {
        showWarningMsg("Password and Confirm Password does not match");
      }
    }
  };

  useEffect(() => {

    reset();
  }, [Refresh]);

  const reset = () => {
    $("[name=employeeCode]").val("0");
    $("[name=userRole]").val("0");
    $("[name=username]").val("");
    $("[name=password]").val("");
    $("[name=confPassword]").val("");
    $("#IsActive").prop("checked", false);
    setErrors({});
  };
  // const handleKeyPress = (event) => {
  //   
  //   if (event.key === " ") {
  //     event.preventDefault();
  //   }
  // };

  const handleChange = (event) => {

    const { value, name } = event.target;
    // setUserName(value.replace(/\s/g, ""));
    setUserModel((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s/g, ""),
    }));
  };
  return (
    <div
      id="add-user"
      className="modal custom-modal fade"
      role="dialog"
      aria-modal="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {data == null ? "Add User" : "Edit User"}
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                reset();
                clearUser();
                // window.location.reload();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={validate}>
              <input
                type="text"
                className="form-control"
                name="userID"
                hidden
                value={data != null ? data.userID : 0}
              />
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">
                      Employee Code<span className="text-danger mt-1">*</span>
                    </label>
                    <input type="text" hidden name="employeeID" />
                    {data == null ? (
                      <select
                        className="form-select"
                        name="employeeCode"
                        id="employeeCode"
                      >
                        <option selected disabled value="0">
                          --Select--
                        </option>
                        {empCodes != null && empCodes.length > 0 ? (
                          empCodes.map((v, i) => {
                            return (
                              <option key={i} value={v.value}>
                                {v.key}
                              </option>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </select>
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        name="employeeCodeName"
                        disabled
                      />
                    )}

                    {errors.empCodeErr && (
                      <p className="text-danger mt-1">
                        Employee code is required
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">
                      Username <span className="text-danger mt-1">*</span>
                    </label>
                    {/* <input
                      className="form-control"
                      type="text"
                      name="username"
                      defaultValue={data != null ? data.username : ""}
                      disabled={data != null}
                    /> */}
                    <input
                      className="form-control"
                      type="text"
                      name="username"
                      value={UserModel.UserName}
                      disabled={data != null}
                      // onKeyPress={handleKeyPress}
                      onChange={handleChange}
                    />
                    {errors.usernameErr && (
                      <p className="text-danger mt-1">Username is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">
                      Password <span className="text-danger mt-1">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        // value={password}
                        // onKeyPress={handleKeyPresspassword}
                        // onChange={handleChangepassword}
                        value={UserModel.password}
                        // onKeyPress={handleKeyPress}
                        onChange={handleChange}
                      // defaultValue={data != null ? data.password : ""}
                      />
                      <span className="input-group-text">
                        <a
                          className="login-icon eye-icon"
                          href="#"
                          onClick={(e) => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword ? "fa fa-eye" : "fa fa-eye-slash	"
                            }
                          ></i>
                        </a>
                      </span>
                    </div>
                    {errors.passwordErr && (
                      <p className="text-danger mt-1">Password is required</p>
                    )}
                    {errors.passwordCharErr && (
                      <p className="text-danger mt-1">
                        Minimum eight characters, at least one uppercase letter,
                        one lowercase letter, one number and one special
                        character:
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">
                      Confirm Password
                      <span className="text-danger mt-1">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        name="confPassword"
                        type={showConfPassword ? "text" : "password"}
                        className="form-control"
                        // defaultValue={data != null ? data.password : ""}
                        value={UserModel.confPassword}
                        // onKeyPress={handleKeyPress}
                        onChange={handleChange}
                      />
                      <span className="input-group-text">
                        <a
                          className="login-icon eye-icon"
                          href="#"
                          onClick={(e) =>
                            setConfShowPassword(!showConfPassword)
                          }
                        >
                          <i
                            className={
                              showConfPassword ? "fa fa-eye" : "fa fa-eye-slash	"
                            }
                          ></i>
                        </a>
                      </span>
                    </div>
                    {errors.confPasswordErr && (
                      <p className="text-danger mt-1">
                        Confirm password is required
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">
                      Role <span className="text-danger mt-1">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="userRole"
                      id="userRole"
                    >
                      <option selected disabled value="0">
                        --Select--
                      </option>
                      {roles != null && roles.length > 0 ? (
                        roles.map((v, i) => {
                          return (
                            <option key={i} value={v.index}>
                              {v.key}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    {errors.roleErr && (
                      <p className="text-danger mt-1">Role is required</p>
                    )}
                  </div>
                </div>
              </div>
              {/* 
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="col-form-label">Is Active</label>
                    <div className="custom-control form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="IsActive"
                        defaultChecked={data != null ? data.isActive : true}
                        name="IsActive"
                      />
                      <label
                        className="form-check-label"
                        for="customSwitch2"
                      ></label>
                    </div>
                  </div>
                  <div class="onoffswitch text-align-start">
                    <input type="checkbox" id="IsActive"
                      defaultChecked={data != null ? data.isActive : true}
                      name="IsActive" class="onoffswitch-checkbox" />
                    <label class="onoffswitch-label" for="IsActive">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-primary submit-btn" id="addUserBtn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
