import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { randomArray } from "../../services/CommonService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const Donet = ({ data, title, clickable, onDoubleClick }) => {
  return (
    <Doughnut
      height={100}
      width={100}
      data={data}
      options={{
        onClick: clickable,
        plugins: {
          title: {
            display: title ? true : false,
            text: title,
          },
          
          legend: {
            labels: {
              boxWidth: 10,
            },
            position: "left",
          },
          tooltip: {
            // Add tooltip here
            callbacks: {
              title: function (context) {
                if (context) {
                  
                  return context[0].dataset.label;
                }
              },
            },
          },
          datalabels: {
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            // borderColor: "white",
            textAlign: "center",
            borderRadius: 25,
            borderWidth: 2,
            color: "black",
            anchor: "center",
            align: "center",
            display: function (context) {
              var dataset = context.dataset;
              var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              return value; //> count * 1.5;
            },
            font: {
              weight: "bold",
            },
            padding: 4,

            formatter: Math.round,
            labels: {},
          },
        },
        title: {
          display: true, // Set to true to display the title
          text: "Your Chart Title Here", // Title text
        },
      }}
      onDoubleClickCapture={onDoubleClick}
    ></Doughnut>
  );
};
