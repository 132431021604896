import React, { useEffect, useContext, useState } from "react";
import MasterDetailService from "../master/MasterDetailService";
import { AppContext } from "./../../services/ContextProvider";
import ParameterDetailsTable from "./ParameterDetailsTable";
import DesignAndCommService from "../commissioned/DesingAndCommService";
import ParameterService from "./ParameterService";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { Decrypt, GetLoginUserID } from "../../services/CommonService";
import Global from "../../services/Global";
import { PageHeader } from "./../../components/PageHeader";

const _masterDetailService = new MasterDetailService();
const _designAndCommService = new DesignAndCommService();
const _parameterService = new ParameterService();
export const ParameterDetails = () => {
  const animatedComponents = makeAnimated();
  const [allequipList, setAllEquipList] = useState([]);
  const [equipmentList, setequipmentList] = useState([]);
  const { showLoading, hideLoading, handleGlobalStationName, getCookies } =
    useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [parameterList, setParameters] = useState(null);
  const [statuslist, setStatuslist] = useState(null);
  const [SystemTypeList, setSystemTypeList] = useState([]);
  const [StationID, SetStationID] = useState(0);
  const [StationIDs, SetStationIDs] = useState("");

  const [StationList, setStationList] = useState([]);

  const TableColumns = [
    {
      className: "dt-control",
      orderable: false,
      data: null,
      defaultContent: "",
      width: "2.06rem",
    },
    { title: "Equipment ID", className: "hide" },

    { title: "Equipment Short Name" },
    { title: "Equipment System Name" },
  ];
  const getData = () => {
    showLoading();

    if (StationIDs != "") {
      _masterDetailService
        .GetEquipmentMaster(StationIDs, GetLoginUserID())
        .then((response) => {
          hideLoading();
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            const unique = response.data
              .map((item) => item.systemTypeName)
              .filter((value, index, self) => self.indexOf(value) === index);
            setSystemTypeList(unique);

            //   setAllEquipList(response.data);
            var rowList = [];
            response.data.map((v, i) => {
              var row = [];
              row.push("");
              row.push(v.equipmentID);
              row.push(v.equipmentShortName);
              row.push(v.systemTypeName);
              rowList.push(row);
            });
            setTableRows(rowList);
          } else {
            hideLoading();
          }
        })
        .catch((error) => {
          hideLoading();
        });
    } else {
      hideLoading();
      setSystemTypeList([]);
      setTableRows([]);
    }
  };
  const getStatus = () => {
    _parameterService.GetStatusData().then((res) => {
      console.log(res);
      if (res != null && res.isSuccess && res.data.length > 0) {
        var status = [];
        res.data.map((v) => {
          status.push({
            key: v.statusName,
            value: v.statusID,
          });
        });

        setStatuslist(status);
      }
    });
  };
  const getParameter = () => {
    _designAndCommService.GetParameters().then((res) => {
      if (res != null && res.isSuccess && res.data.length > 0) {
        var parameters = [];
        res.data.map((v) => {
          parameters.push({
            key: v.ParameterName,
            value: v.ParameterID,
            unit: v.ParameterUnit,
          });
        });

        setParameters(parameters);
      }
    });
  };
  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    HandleChange([dataLst[0]]);
  };

  const HandleChange = (e) => {
    SetStationID(e);
    let StationLabel = e.map((x) => x.label).join();
    var stationIds = e.map((x) => {
      return x.value;
    });
    handleGlobalStationName(StationLabel);
    SetStationIDs(stationIds.toString());
  };

  useEffect(() => {
    getParameter();
    getStatus();
    getStationList();
  }, []);

  useEffect(() => {
    getData();
    return () => {
      setSystemTypeList([]);
      setTableRows([]);
    };
  }, [StationIDs]);

  const Header = ({ title }) => {
    return (
      <>
      <div className="row">
        <div className="col-md-7">
      <h3 className="page-title ">{title}</h3>
      </div>
        <div className="col-5" style={{ float: "right" }}>
          <ReactMultiSelect
            options={StationList}
            isMulti
            value={StationID}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={(e) => HandleChange(e)}
            allowSelectAll={true}
          />
        </div>
       
        </div>
      </>
    );
  };
  return (
    <div>
      <Header title={"Equipment Parameter"} />
      <div className="mt-3 card">
        <div className="card-body">
          <Accordion allowZeroExpanded={true} className="">
            {SystemTypeList.map((item, key) => {
              let TableRow = [];
              TableRow = tableRows.filter((x) => {
                return x[3] == item;
              });

              return (
                <AccordionItem key={key} uuid={key}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {parameterList != null &&
                      parameterList.length > 0 &&
                      statuslist != null &&
                      statuslist.length > 0 ? (
                      <ParameterDetailsTable
                        columnList={TableColumns}
                        rowList={TableRow}
                        tableName={`Parameter_Details${key}`}
                        parameters={parameterList}
                        status={statuslist}
                      />
                    ) : parameterList == null ? (
                      <p className="text-center m-5">Loading...</p>
                    ) : (
                      <p className="text-center text-danger m-5">
                        Equipment is not found
                      </p>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};
