import React, { useEffect } from "react";
import { PeriodicColor } from "../../services/CommonService";
import "../../App.css";

export const HoverModal = ({ hoverModalData }) => {
  var equipments = [];

  const COLORS = {
    1: "bg-danger",
    2: "bg-success",
    3: "periodic-orange",
    4: "bg-primary",
    6: "bg-warning",
    8: "bg-danger",
    10: "bg-secondary",
  };

  return (
    <div
      id="hover-periodic"
      className="modal custom-modal fade"
      role="dialog"
      aria-modal="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            {hoverModalData != null &&
              hoverModalData.periodicMaintenance != null &&
              hoverModalData.periodicMaintenance.length > 0 &&
              hoverModalData.periodicMaintenance.map((v) => {
                var bgStyle = {
                  borderRadius: "4px",
                  color: "white",
                };
                var bgClass;
                var value = hoverModalData.jobList.find(
                  (c) => c.MaintenanceScheduleID == v.maintenanceScheduleID
                );

                if (value != null) {
                  bgClass = "row mb-1 " + COLORS[`${value.JobStatus}`];
                } else {
                  bgClass = "row mb-1 " + COLORS[`${1}`];
                }
                return (
                  <div className={bgClass} style={bgStyle}>
                    <div className="col text-start">{v.description}</div>
                    <div className="col-2 text-end">
                      {v.maintenanceTypeName.charAt(0) +
                        v.maintenanceTypeName.slice(1).toLowerCase()}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
