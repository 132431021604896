import { Encrypt } from "../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class ManualParameterEntryService {


    ManualPointEntry_SaveManualPoint(data) {


    return axios.post("ManualPointEntry/ManualPointEntry_SaveManualPoint", data).catch((err) =>  err);

  }
  ManualPointEntry_GetDropdownDate() {


    return axios.get("ManualPointEntry/ManualPointEntry_GetDropdownDate").catch((err) =>  err);

  }
  ManualPointEntry_GetManualPointDetailesUsingID( ManualParameterID, ManuallyType, IsDeleted, employeeID) {


    return axios.get(`ManualPointEntry/ManualPointEntry_GetManualPointDetailesUsingID?ManualParameterID=${ManualParameterID}&ManuallyType=${ManuallyType}&IsDeleted=${IsDeleted}&EmployeeID=${Number(employeeID)}` ).catch((err) =>  err);

  }
  Report_ManualPointEntry(StartDate, EndDate, StationID){
    
    return axios.get(`ManualPointEntry/Report_ManualPointEntry?StartDate=${StartDate}&&EndDate=${EndDate}&&StationID=${StationID}`)
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
  }




}