import React, { useEffect, useState } from "react";
import { Calendar, Navigate, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  GetCurrentWeek,
  GetCurrentWeekInDate,
  YMDtoDate,
  getDDMMYYdate,
  getYMDdate,
} from "../../services/CommonService";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { Headings, StatusCount } from "./TempData";
import MaintenanceChecks from "./MaintenanceChecks";

export default function MaintenanceChecksDescriptionCalenderView() {
  const navigate = useNavigate();

  function MonthlyCheckList({
    date,
    localizer,
    max = localizer.endOf(new Date(), "day"),
    min = localizer.startOf(GetCurrentWeekInDate(), "day"),
    scrollToTime = localizer.startOf(GetCurrentWeekInDate(), "day"),
    RenderView,
    ...props
  }) {
    return <MaintenanceChecks />;
  }

  MonthlyCheckList.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  MonthlyCheckList.title = (date) => {
    return getDDMMYYdate(date);
  };

  MonthlyCheckList.navigate = (date, action, { localizer }) => {
    let returnDate = date;
    let IncrementNumber = 12;
    switch (action) {
      case Navigate.PREVIOUS:
        returnDate = localizer.add(date, -IncrementNumber, "month");
        break;
      case Navigate.NEXT:
        returnDate = localizer.add(date, IncrementNumber, "month");
        break;
      default:
        returnDate = new Date();
        break;
    }
    return returnDate;
  };

  const localizer = momentLocalizer(moment);

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: {
        day: MonthlyCheckList,
      },
    }),
    []
  );

  const MyToolbar = (toolbar) => {
    const goToDate = (Type) => {
      toolbar.onNavigate(Type);
    };

    return (
      <>
        <div class="rbc-toolbar">
          <span class="rbc-btn-group">
            <button type="button" onClick={() => goToDate("CUSTOM_TODAY")}>
              Today
            </button>
            <button type="button" onClick={() => goToDate("PREV")}>
              Back
            </button>
            <button type="button" onClick={() => goToDate("NEXT")}>
              Next
            </button>
          </span>
          <span className="rbc-toolbar-label">
            {toolbar.view === "day"
              ? toolbar.label
              : `${moment(toolbar.date).format("MMMM")} - ${moment(toolbar.date)
                  .add(3, "months")
                  .format("MMMM")}`}
          </span>
          <span class="rbc-btn-group">
            <button
              type="button"
              onClick={() => {
                toolbar.onView("day");
                // HandleTypeDetails("ScheduleType", 43);
                // HandleTypeDetails("CheckDate", getYMDdate(new Date()));
              }}
            >
              Monthly
            </button>
            <button
              type="button"
              onClick={() => {
                toolbar.onView("week");
                // HandleTypeDetails("ScheduleType", 44);
                // HandleTypeDetails("CheckDate", getYMDdate(GetCurrentWeek()));
              }}
            >
              Quarterly
            </button>
            <button
              type="button"
              onClick={() => {
                toolbar.onView("week");
                // HandleTypeDetails("ScheduleType", 44);
                // HandleTypeDetails("CheckDate", getYMDdate(GetCurrentWeek()));
              }}
            >
              Half Yearly
            </button>
            <button
              type="button"
              onClick={() => {
                toolbar.onView("week");
                // HandleTypeDetails("ScheduleType", 44);
                // HandleTypeDetails("CheckDate", getYMDdate(GetCurrentWeek()));
              }}
            >
              Yearly
            </button>
          </span>
          <span class="rbc-btn-group"></span>
        </div>
      </>
    );
  };

  return (
    <div className="height600">
      <Calendar
        defaultDate={defaultDate}
        // date={TypeDetails.CheckDate}
        defaultView={Views.DAY}
        events={[]}
        components={{
          toolbar: MyToolbar,
        }}
        selectable
        localizer={localizer}
        views={views}
      />
    </div>
  );
}
