import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import RoleService, { Role_SaveOrderList } from "./RoleService";
import { AddRole } from "./AddRole";
import $ from "jquery";
import { COOKIES } from "../../services/Global";
import {
  getUserDetails,
  getPrivilege,
  GetLoginUserID,
} from "../../services/CommonService";
import ListSort from "../../components/ListSort";
import Global from "../../services/Global";
const _roleService = new RoleService();

export const RoleList = () => {
  const { clearErrors, reset } = useForm({
    mode: "onChange",
  });

  const [roleID, setroleID] = useState("");
  const [roleName, SetRoleName] = useState(null);
  const [PreRoleName, setPreRoleName] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [allRoles, setAllRole] = useState([]);
  const [Refresh, setRefresh] = useState(0);

  const [OrderRoleList, setOrderRoleList] = useState([]);

  const { showLoading, hideLoading, getCookies } = useContext(AppContext);

  const columnNames = [
    { title: "Role ID", visible: false },
    { title: "Role Name" },
    {
      title: "Submit",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        if (getPrivilege().includes("ROLESEDIT")) {
          b =
            $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#addrole" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
              EditRole(rowData[0], rowData[1]);
            });
        }
        $(td).html(b);
      },
    },
  ];

  const AddNewRole = () => {
    setroleID(null);
    SetRoleName(null);
    setPreRoleName(null);
  };

  const closepopup = () => {
    setroleID("");
    SetRoleName(null);
  };

  function EditRole(Id, Name) {
    const found = allRoles.find((obj) => {
      return obj.roleID === Id;
    });

    setroleID(found.roleID);
    SetRoleName(found.roleName);
    setPreRoleName(found.prefixName);
  }

  const handleRefresh = () => {
    var count = Refresh;
    setRefresh(++count);
  };

  useEffect(() => {
    getRoleList();
  }, [Refresh]);

  const getRoleList = () => {
    const IsTvsvac = getCookies(Global.COOKIES.IsTvsvac)
    const tvsVac = IsTvsvac === "true" ? 1 : 0;
    _roleService
      .GetRoleList(getUserDetails().RoleID ? getUserDetails().RoleID : "", tvsVac)
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          
          setAllRole(response.data);
          setOrderRoleList(response.data);
          // var rowList = [];
          // // response.data.map((v, i) => {
          // //   var row = [];
          // //   row.push(v.roleID);
          // //   row.push(v.roleName);
          // //   row.push("");
          // //   rowList.push(row);
          // // });
          // setRoleList(rowList);
        }
      })
      .catch((error) => { });
  };

  const Header = ({ title }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-4">
            <h3 className="page-title">{title}</h3>
          </div>

          {getPrivilege().includes("ROLESCREATE") && (
            <div className="col-3">
              <button
                type="submit"
                className="btn add-btn mb-2"
                id="rolesbutton"
                onClick={() => AddNewRole()}
                data-bs-toggle="modal"
                data-bs-target="#addrole"
              >
                <i className="fa fa-plus"></i> Add Role
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const RowRender = (item, index) => {
    const { roleName, roleID, prefixName } = item;
    let oddOrEven = index % 2 == 0 ? "even" : "odd";
    return (
      <>
        <div className={`col-6 ${oddOrEven}`}>
          <div className="DateInput_input__focused row cursor-grap">
            <div className="p-3 col">
              <div>{prefixName}</div>
            </div>
            <div className="p-3 col">
              <div>{roleName}</div>
            </div>
            <div className="p-3 col ">
              <div
                className="float-end cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#addrole"
                onClick={() => EditRole(roleID, roleName)}
              >
                <i class="fa fa-pencil m-r-5" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };







  const SaveOrder = (OrderRoleList) => {
    if (OrderRoleList?.length) {
      let OrderRole = [];
      OrderRoleList.forEach((ele, index) => {
        const { roleID } = ele;
        OrderRole.push({
          RoleID: roleID,
          OrderLevel: index + 1,
          SavedBy: GetLoginUserID(),
        });
      });
      showLoading();
      if (OrderRole?.length) {
        Role_SaveOrderList({ RoleList: JSON.stringify(OrderRole) })
          .then((res) => {
            hideLoading();
            if (res?.data?.isSuccess) {
              showSaveSuccess();
              getRoleList();
            }
          })
          .catch((err) => {
            showSaveError();
            hideLoading();
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <Header title={"Role Details"} />

      {/* <button id="rolesbutton" onClick={() => AddNewRole()} data-bs-toggle="modal" data-bs-target="#addrole"  ><i >Add Role</i></button> */}
      <AddRole
        roleID={roleID}
        roleName={roleName}
        PrefixRoleName={PreRoleName}
        handleRefresh={() => handleRefresh()}
        roleList={allRoles}

        closepopup={() => closepopup()}
      />

      <div className="card card-body">
        {/* {getPrivilege().includes("ROLESCREATE") && (

        <div className="row">
          <div className="col-6">
            <button className="btn btn-primary" onClick={SaveOrder}>
              Save
            </button>
          </div>
        </div>
)} */}
        <div className="row">
          <div className="col-12">
            <div className={`col-6`}>
              <div className="row">
                <div className="p-3 col">
                  <div style={{ font: "bold" }}>User Level</div>
                </div>
                <div className="p-3 col">
                  <div style={{ font: "bold" }}>Role Name</div>
                </div>
                <div className="p-3 col ">
                  <div className="float-end cursor-pointer" style={{ font: "bold" }}>Action</div>
                </div>
              </div>
            </div>
            <ListSort
              Draggable={false}
              UnOrderList={OrderRoleList}
              
              setUnOrderList={setOrderRoleList}
              RowRender={RowRender}
              onChange={SaveOrder}
            />
          </div>
        </div>
        {/* <HtmlTable columnList={columnNames} rowList={roleList} id={'RoleTable'} /> */}
      </div>
    </>
  );
};
