import Axios from "../../../services/AxiosService";
const axios = new Axios();

const AuditingLevelConfig_GetList = async () => {
  return await axios.get(`ApplicationConfig/AuditingLevelConfig_GetList`);
};

const AuditingLevelConfig_GetListByID = async (AuditingLevelConfigID) => {
  return await axios.get(
    `ApplicationConfig/AuditingLevelConfig_GetListByID?AuditingLevelConfigID=${AuditingLevelConfigID}`
  );
};

const AuditingLevelConfig_GetSubModuleListByDepartmentID = async (
  DepartmentID
) => {
  return await axios.get(
    `ApplicationConfig/AuditingLevelConfig_GetSubModuleListByDepartmentID?DepartmentID=${DepartmentID}`
  );
};

const AuditingLevelConfig_GetAuditingListByTaskTypeID = async (
  TaskTypeID,
  DepartmentID
) => {
  return await axios.get(
    `ApplicationConfig/AuditingLevelConfig_GetAuditingListByTaskTypeID?TaskTypeID=${TaskTypeID}&&DepartmentID=${DepartmentID}`
  );
};
const AuditingLevelConfigMaster_CreateUpdate = async (
  AuditingLevelConfigModel
) => {
  return await axios
    .post(
      `ApplicationConfig/AuditingLevelConfigMaster_CreateUpdate`,
      AuditingLevelConfigModel
    )
    .then((response) => {
      if (response != null && response.data != null) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

const AuditingLevelConfig_DeleteByID = async (
  AuditingLevelConfigID,
  USserID
) => {
  return await axios.get(
    `ApplicationConfig/AuditingLevelConfig_DeleteByID?AuditingLevelConfigID=${AuditingLevelConfigID}&&CreatedBy=${USserID}`
  );
};
export {
  AuditingLevelConfig_GetList,
  AuditingLevelConfig_GetListByID,
  AuditingLevelConfigMaster_CreateUpdate,
  AuditingLevelConfig_DeleteByID,
  AuditingLevelConfig_GetSubModuleListByDepartmentID,
  AuditingLevelConfig_GetAuditingListByTaskTypeID,
};
