import React from "react";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

export const MultiSelect = ({ data, selectedData }) => {
  return (
    <div className="cus-multi-select">
      <DropdownMultiselect options={data} selected={selectedData} />
    </div>
  );
};
