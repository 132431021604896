const StatusCount  = [
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
        OpenCount : 0,
        AttendedCount : 2,
        PendingCount : 20,
        ApprovalCount : 12,
        RejectedCount : 2,
    },
    {
      OpenCount : 0,
      AttendedCount : 2,
      PendingCount : 20,
      ApprovalCount : 12,
      RejectedCount : 2,
  },
  {
    OpenCount : 0,
    AttendedCount : 2,
    PendingCount : 20,
    ApprovalCount : 12,
    RejectedCount : 2,
},
{
  OpenCount : 0,
  AttendedCount : 2,
  PendingCount : 20,
  ApprovalCount : 12,
  RejectedCount : 2,
},
]

const Headings = [
    {
      title: "January",
    },
    {
      title: "February",
  
    },
    {
      title: "March",
  
    },
    {
      title: "April",
    },
    {
      title: "May",
  
    },
    {
      title: "June",
  
    },
    {
      title: "July",
    },
    {
      title: "August",
  
    },
    {
      title: "September",
    },
    {
      title: "October",
    },
    {
      title: "November",
    },
    {
      title: "December",
    },
  ];

  
const WeeklyHeadings = [
  {
    title: "January - March",
  },
  {
    title: "April - June",

  },
  {
    title: "July - September",

  },
  {
    title: "October - December",
  },
  
];


   
export {StatusCount,Headings,WeeklyHeadings};