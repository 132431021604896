import React from "react";
import {
  Dropdown,
  HtmlTable,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
} from "../../components";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  YMDtoDate,
  addDays,
} from "../../services/CommonService";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  TemperatureMonitor_DropDownDetails,
  EquipmentTemperatureMonitor_SaveConfig,
  EquipmentTemperatureMonitor_GetRoomDetails,
  EquipmentTemperatureMonitor_DetailsSaveConfig,
  EquipmentEnergyConsumption_GetDocumentID,
} from "./ManualLogService";
import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";

export default function EquipmentTemperatureMonitorLog({ TabValue }) {
  const initialEquipmentState = {
    RoomID: [],
    StationID: "",
    IsDeleted: false,
    checkAllAttended: false,
    CurrentDate: getYMDdate(new Date()),
    checkAllApproved: false,
    checkAllRejected: false,
    documentNo: "",
  };
  const [StationList, setStationList] = useState([]);
  const [errors, setErrors] = useState({
    RoomID: "",
    StationID: "",
  });
  const [RoomList, setRoomList] = useState([]);
  const [TemperatureMonitorDetailList, setTemperatureMonitorDetailList] =
    useState([]);
  const [flag, setflag] = useState(false)
  const [RoomModel, setRoomModel] = useState(initialEquipmentState);

  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);

  /**
   * Column Names for the Temperature Monitor Detail   Table.
   */
  const columnNames = [
    { title: "Station Code", data: "StationCode" },
    {
      title: "EquipmentTemperatureMonitorID",
      data: "EquipmentTemperatureMonitorID",
      visible: false,
    },
    { title: "Room Name", data: "RoomName" },
    {
      title: "00.00 \n (DBT)",
      data: "Time_1",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_1");
      },
    },
    {
      title: "03:00 \n (DBT)",
      data: "Time_2",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_2");
      },
    },
    {
      title: "03:00 \n (WBT)",
      data: "Time_3",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_3");
      },
    },
    {
      title: "06.00 \n (DBT)",
      data: "Time_4",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_4");
      },
    },
    {
      title: "09:00 \n (DBT)",
      data: "Time_5",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_5");
      },
    },
    {
      title: "12:00 \n (DBT)",
      data: "Time_6",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_6");
      },
    },
    {
      title: "12.00\n(WBT)",
      data: "Time_7",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_7");
      },
    },
    {
      title: "15:00\n(DBT)",
      data: "Time_8",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_8");
      },
    },
    {
      title: `18:00\n(WBT)`,
      data: "Time_9",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_9");
      },
    },
    {
      title: `21.00\n(DBT)`,
      data: "Time_10",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_10");
      },
    },
    {
      title: `21:00\n(WBT)`,
      data: "Time_11",
      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_11");
      },
    },
    {
      title: `24:00\n(DBT)`,
      data: "Time_12",

      createdCell: function (td, cellData, rowData, row, col) {
        createdCommonCell(td, cellData, rowData, row, col, "Time_12");
      },
    },
    {
      title: `UOM\n(Deg C)`,
      data: "UOM",

      createdCell: function (td, cellData, rowData, row, col) {
        let inputElement = "";

        if (
          !IsAllowToEnterData() ||
          rowData.Status == 49 ||
          rowData.Status == 50 || rowData.Status == 128
        ) {
          inputElement = $(
            `<input type="text" disabled class="form-control" value="${cellData}"/>`
          );
        } else {
          inputElement = $(
            `<input type="text" class="form-control" value="${cellData}"/>`
          ).on("change", function (e) {
            e.preventDefault();
            let newValue = e.target.value;
            rowData.UOM = newValue;
            TemperatureMonitorHandleChange(rowData);
            UpdateStatus(e, rowData);
          });
        }

        $(td).html(inputElement);
      },
    },
    {
      title: `Acceptable Range \n(DBT)`,
      data: "AcceptableRangeDBT",

      createdCell: function (td, cellData, rowData, row, col) {
        AcceptableInput(td, cellData, rowData, row, col, "AcceptableRangeDBT");
      },
    },
    {
      title: `Acceptable Range \n(WBT)`,
      data: "AcceptableRangeWBT",

      createdCell: function (td, cellData, rowData, row, col) {
        AcceptableInput(td, cellData, rowData, row, col, "AcceptableRangeWBT");
      },
    },
    {
      title: "Remarks",
      data: "Remarks",
      createdCell: (td, cellData, rowData, row, col) => {
        let b = "";
        if (
          !IsAllowToEnterData() ||
          rowData.Status == 49 ||
          rowData.Status == 50 || rowData.Status == 128
        ) {
          b = $(
            `<textarea  class="form-control" disabled style = "
          resize: none;
          min-width: 150px !important;" value="${cellData}">${cellData} </textarea>`
          );
        } else if (
          getUserDetails().RoleID !== COOKIES.TechRoleID ||
          IsAllowToEnterData()
        ) {
          b = $(
            `<textarea  class="form-control " style = "
            resize: none;
            min-width: 150px !important;" value="${cellData}">${cellData} </textarea>`
          ).on("change", (e) => {
            e.preventDefault();
            rowData.Remarks = e.target.value;
            TemperatureMonitorHandleChange(rowData);

            UpdateStatus(e, rowData);
          });
        }

        $(td).html(b);
      },
    },
    {
      title: "Status",
      data: "StatusName",
      width: "60px",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="StatusCls" > </span>`);
        span.append(GetPeriodicStatus(cellData));
        $(td).html(span);
      },
    },
  ];

  const createdCommonCell = (td, cellData, rowData, row, col, ObjectName) => {
    let inputElement = "";
    if (!IsAllowToEnterData() || rowData.Status == 49 || rowData.Status == 50 || rowData.Status == 128) {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    } else {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      ).on("change", function (e) {
        e.preventDefault();

        let newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          rowData[ObjectName] = newValue;
          TemperatureMonitorHandleChange(rowData);
          UpdateStatus(e, rowData);
        }
      });
    }

    $(td).html(inputElement);
  };

  function AcceptableInput(td, cellData, rowData, row, col, ObjectName) {
    let inputElement = "";
    if (
      ((rowData.Status == null || cellData == 0) && rowData.Status != 128 &&
        getUserDetails().RoleID == COOKIES.TechRoleID &&
        getYMDdate(RoomModel.CurrentDate) == getYMDdate(new Date())) ||
      (getUserDetails().RoleID == COOKIES.EnggRoleID && rowData.Status !== null)
    ) {
      inputElement = $(
        `<input type="number" class="form-control" value="${cellData}"/>`
      );
    } else {
      inputElement = $(
        `<input type="number" disabled class="form-control" value="${cellData}"/>`
      );
    }

    inputElement.on("change", function (e) {
      e.preventDefault();

      let newValue = parseFloat(e.target.value);
      if (!isNaN(newValue)) {
        rowData[ObjectName] = newValue;
        TemperatureMonitorHandleChange(rowData);
        UpdateStatus(e, rowData);

        if (getUserDetails().RoleID == COOKIES.TechRoleID) {
          $(e.target).prop("disabled", true);
        }
      }
    });
    $(td).html(inputElement);
  }

  useEffect(() => {
    // if (TabValue == 1) {
    //   initialDropDown();
    //   getRoomDetails();
    // }
    initialDropDown();
    getRoomDetails();
    return () => {
      setStationList([]);
      setRoomList([]);
    };
  }, []);

  useEffect(() => {
    if (TabValue == 0) {
      getRoomDetails();
    }

    return () => {
      setTemperatureMonitorDetailList([]);
    };
  }, [TabValue, RoomModel.StationID, RoomModel.CurrentDate]);

  /**
   * Handle change event for common inputs.
   *
   * @param {Event} event - The input change event.
   */
  const CommonHandleChange = (event) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        let Station = StationList.find((x) => x.value == value);

        handleGlobalStationName(Station?.key ?? "");
      }
    } else {
      setRoomModel((PreviousState) => ({
        ...PreviousState,
        RoomID: event,
      }));
    }
  };

  /**
   * Event handler for adding a new equipment running configuration.
   *
   * @param {Event} e - The click event.
   */
  const AddHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...RoomModel };
    let isError = false;
    let modelErrors = errors;
    if (obj.RoomID?.length > 0) {
      modelErrors.RoomID = "";
    } else {
      modelErrors.RoomID = "Please Select Room";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please Select Station";
      isError = true;
    }
    setErrors({ ...modelErrors });

    if (!isError) {
      obj.RoomID?.map((x) => {
        if (isNaN(x.id)) {
          x.id = "0";
        }
      });
      obj.RoomID = JSON.stringify(obj.RoomID);
      obj.CreatedBy = GetLoginUserID();
      SaveEquipmentMonitorChecks(obj);
    }
  };

  function SaveEquipmentMonitorChecks(obj) {
    EquipmentTemperatureMonitor_SaveConfig(obj)
      .then((res) => {
        const { data } = res?.data;
        if (data == "-1") {
          showWarningMsg(
            "This equipment tag number is already associated with this station"
          );
        } else {
          showSaveSuccess();
          getRoomDetails();
          initialDropDown();
          setRoomModel({ ...initialEquipmentState });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function SubmitBtn() {

    let tableValues = $("#EquipmentTemperatureMonitorLog")
      .DataTable()
      .table()
      .data()
      .toArray();
    let SendForApprovelBtn = $(
      `<button id="sbtn" class="btn btn-primary m-l-15" >  Submit </button> `
    ).on("click", async function (e) {
      e.preventDefault();
      let everyapprove = tableValues.every((x) => x.StatusName == "Attended")
      if (tableValues?.length && everyapprove) {
        let rowData = tableValues[0];
        await TemperatureMonitorHandleChange(rowData, 128);
        await getRoomDetails();
      } else {
        showWarningMsg("Please completed all checklist.")
      }
    });
    const {
      checkAllAttended,
      CurrentDate,
    } = RoomModel;
    let Fragment = $(`<span ></span>`);
    if (getUserDetails().RoleID === COOKIES.TechRoleID && getYMDdate(new Date()) == getYMDdate(CurrentDate)) {
      Fragment.append(SendForApprovelBtn);
    }

    if (
      getUserDetails().RoleID === COOKIES.TechRoleID &&
      getYMDdate(new Date()) == getYMDdate(CurrentDate) &&
      checkAllAttended
    ) {
      console.log("entered")
      setTimeout(() => {
        $('#sbtn').addClass("disabled");
      }, 600)
    }
    return Fragment;
  }

  function ApprovedBtn() {
    let tableValues = $("#EquipmentTemperatureMonitorLog")
      .DataTable()
      .table()
      .data()
      .toArray();

    let ApproveBtn = $(
      `<button  class="btn btn-success disabled "
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await TemperatureMonitorHandleChange(rowData, 49);
        await getRoomDetails();
      }
      //  Submit_SaveCheckListDetails(46);
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await TemperatureMonitorHandleChange(rowData, 50);
        await getRoomDetails();
      }
    });
    let Fragment = $(`<span>`);
    if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }

    const {
      checkAllAttended,
      CurrentDate,
      checkAllApproved,
      checkAllRejected,
    } = RoomModel;

    if (
      checkAllAttended &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 3))
    ) {
      ApproveBtn.removeClass("disabled");
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 3)) ||
        checkAllApproved)
    ) {
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 3)) ||
        checkAllRejected)
    ) {
      ApproveBtn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 15)) &&
      checkAllAttended
    ) {
      ApproveBtn.removeClass("disabled");
    }

    return Fragment;
  }

  function IsAllowToEnterData() {
    let currentDate = new Date().toISOString().slice(0, 10);
    let CheckDate = new Date(RoomModel.CurrentDate).toISOString().slice(0, 10);
    //return true
    return (
      getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate
    );
  }

  const deleteHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...RoomModel };
    let isError = false;
    let modelErrors = errors;
    if (obj.RoomID) {
      modelErrors.RoomID = "";
    } else {
      modelErrors.RoomID = "Please Select Room";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please Select Station";
      isError = true;
    }
    setErrors({ ...modelErrors });

    if (!isError) {
      swal({
        title: "Are you sure?",
        text: "Do you want to delete this configuration?",
        icon: "error",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          obj.RoomID?.map((x) => {
            if (isNaN(x.id)) {
              x.id = "0";
            }
          });
          obj.RoomID = JSON.stringify(obj.RoomID);
          obj.CreatedBy = GetLoginUserID();
          obj.IsDeleted = true;
          SaveEquipmentMonitorChecks(obj);
        }
      });
    }
  };

  function UpdateStatus(e, Data) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");

      if (StatusClsCell.length > 0) {
        Data.StatusName = "Attended";
        StatusClsCell.html(GetPeriodicStatus("Attended")); // You can format the value as needed
        SubmitBtn();
      }
    }
  }

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    //let ignoredStation = ["stc", "str", "swa"];
    let ignoredStation = [""];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    await TemperatureMonitor_DropDownDetails()
      .then((res) => {
        if (res?.data) {
          const { data } = res.data;
          if (data?.length) {
            setRoomList([...data]);
          } else {
            setRoomList([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await EquipmentEnergyConsumption_GetDocumentID()
      .then((res) => {
        if (res?.data?.data) {
          const { data } = res.data;
          let document = data.find(
            (x) =>
              x.ModuleName == "CRITICAL_EQUIPMENT_ROOM_TEMPERATURE_CHECKLIST"
          );
          setRoomModel((pre) => ({
            ...pre,
            documentNo: document.DocumentNo,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setStationList([...FinalStationList]);
    setRoomModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  /**
   * Fetch equipment details for a specific Energy Consumption date.
   */
  const getRoomDetails = async () => {
    if (RoomModel.StationID > 0) {
      showLoading();
      await EquipmentTemperatureMonitor_GetRoomDetails(
        RoomModel.StationID,
        RoomModel.CurrentDate
      )
        .then((res) => {
          hideLoading();
          if (res?.data) {
            const { data } = res.data;
            console.log(data);
            if (data?.length) {
              let checkAllAttended = data.every((x) => x.Status == 128);
              let checkAllApproved = data.every((x) => x.Status == 49);
              let checkAllRejected = data.every((x) => x.Status == 50);
              setflag(true)
              setRoomModel((pre) => ({
                ...pre,
                checkAllAttended: checkAllAttended,
                checkAllApproved: checkAllApproved,
                checkAllRejected: checkAllRejected,
              }));

              setTemperatureMonitorDetailList([...data]);
            } else {
              setTemperatureMonitorDetailList([]);
            }
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  /**
   * Handle changes in equipment energy Consumption and save the configuration.
   *
   * @param {Object} obj - The equipment energy Consumption  configuration object.
   */
  const TemperatureMonitorHandleChange = async (obj, status = 48) => {
    obj.SavedBy = GetLoginUserID();
    obj.Status = status;
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      // obj.Status = 48;
      obj.ShiftTechnicianID = obj.SavedBy;
    } else {
      obj.ShiftEngineerID = obj.SavedBy;
    }
    obj.CurrentDate = getYMDdate(RoomModel.CurrentDate);
    await EquipmentTemperatureMonitor_DetailsSaveConfig(obj)
      .then((res) => {
        const { isSuccess } = res.data;
        if (isSuccess && (status == 128 || status == 49 || status == 50)) {
          showSaveSuccess();
        } else if (!isSuccess) {
          showErrorMsg("Something went wrong. Please try again.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Renders the form for adding a new Energy Consumption Log configuration.
   *
   * @returns {HTMLElement} The form element for adding new configurations.
   */
  function Form() {
    return (
      <form id="EquipmentRunningStatusName">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={RoomModel.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>
          {getPrivilege().includes("MANUALLOGCREATE") && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Room Name <span className="text-danger"> *</span>{" "}
                  </label>{" "}
                  <Typeahead
                    name="RoomID"
                    id="RoomID"
                    options={RoomList}
                    allowNew
                    multiple
                    caseSensitive
                    onChange={CommonHandleChange}
                    selected={RoomModel.RoomID}
                  />
                  <span className="text-danger mt-1"> {errors.RoomID} </span>
                </div>
              </div>

              <div className="col-md-2 mt-4">
                <button
                  type="submit"
                  id="addRow_ers"
                  onClick={AddHandleClick}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </>
          )}
          {/* {getPrivilege().includes("MANUALLOGDELETE") && (
            <div className="col-md-2 mt-4">
              <button
                type="button"
                onClick={deleteHandleClick}
                className="btn btn-danger"
              >
                <i className="fa fa-trash"></i> Delete
              </button>
            </div>
          )} */}
        </div>
      </form>
    );
  }

  function DateFilter() {
    return $(
      `<label class='m-l-15' style ="display:inline-flex" > <span> Date : </span>
        <input type="date" id="CERTMDateID" max=${getYMDdate(
        new Date()
      )} style="height:35px" class="form-control m-l-15" value ="${RoomModel.CurrentDate
      }"
        /></label>
        `
    ).on("change", function (e) {
      const { value } = e.target;
      setRoomModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
  }

  return (
    <div>
      <div className="col-md-12">
        <label
          className="label"
          for="empCode"
          style={{ float: "right", position: "relative", top: "-15px" }}
        >
          {RoomModel?.documentNo && `Doc Number : ${RoomModel.documentNo}`}
        </label>
      </div>
      <Form></Form>
      {/* <div
        style={{
          position: "absolute",
          right: "18px",
        }}
      >
        {RoomModel?.documentNo && `Doc Number : ${RoomModel.documentNo}`}
      </div> */}
      <div className="">
        <HtmlTable
          columnList={columnNames}
          rowList={TemperatureMonitorDetailList ?? []}
          id={"EquipmentTemperatureMonitorLog"}
          FixedColums={{
            left: 2,
            right: 1,
          }}
          // IsASC
          // orderby={0}
          ShowLengthPostponedHtml={DateFilter}
          SearchPostponedHtml={SubmitBtn}
          SearchPrependHtml={ApprovedBtn}
        />
      </div>
    </div>
  );
}
