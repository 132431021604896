import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../services/ContextProvider';
import { COOKIES } from '../../services/Global';

function DashboardRedirect() {
    const navigate = useNavigate();

    const {getCookies} = useContext(AppContext)


useEffect(()=> {
    localStorage.setItem("ID", 0);
    const IsTvsvac =  getCookies(COOKIES.IsTvsvac);
    let Navigate = IsTvsvac === "true" ? "/dashboard" : "/BreakdownDepartmentDashboard";
    navigate(Navigate);
},[])
  return (
    <div></div>
  )
}

export default DashboardRedirect