import { useEffect, useState, useContext } from "react";
import EmployeeService from "../../screens/employee/EmployeeService";
import { Dropdown, ReactMultiselectTreeView } from "../../components";
import {
  BuildTreeUsingParentID,
  findDepartmentById,
  propagateSelectionRecursively,
} from "../../services/CommonService";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import makeAnimated from "react-select/animated";
import { AccordionItemState } from "react-accessible-accordion";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";


const DepartmentFilter = ({
  HandleChange = () => { },
  isSmall = true,
  EditDropDown,
}) => {
  const {
    getCookies
  } = useContext(AppContext);
  const animatedComponents = makeAnimated();
  const initState = {
    DepartmentList: [],
    CorridorList: [],
    StationTypeList: [],
    LocationypeList: [],
    SectionTypeList: [],
    OheDepList: [],
  };
  const _empServc = new EmployeeService();
  const [DropdownModel, setDropdownModel] = useState(initState);
  const [treeData, settreeData] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [stationID, SetStationID] = useState(0);
  const [isDisabled, setisDisabled] = useState(true);
  const [DropdownList, setDropdownList] = useState({
    DepartmentID: 0,
    DepartmentIDs: "",
    CorridorIdS: "",
    CorridorID: "",
    StationTypeID: "",
    LocationTypeID: "",
    LocationNo: "",
    TypeIDs: "",
    flag: 0
  });

  useEffect(() => {
    setDropdownList((prevState) => ({
      DepartmentID: 0,
      DepartmentIDs: "",
      CorridorIdS: "",
      CorridorID: "",
      StationTypeID: "",
      LocationTypeID: "",
      LocationNo: "",
      TypeIDs: "",
      flag: 0
    }));
    initDropdown();
  }, [EditDropDown.EmployeeID]);

  useEffect(() => {
    if (EditDropDown.TempStationTypeID != "" //&& (DropdownList.flag == 0 || EditDropDown.flag == 1)
    ) {

      // const corridorIdList = DropdownModel.CorridorList.filter(
      //   (item) =>
      //     item.value == EditDropDown.CorridorIdS
      // );

      EditDropDown.flag = 0;
      const corridorIDsArray = EditDropDown?.CorridorIdS?.split(',').map(id => id.trim()) ?? [];

      const corridorIdList = DropdownModel.CorridorList.filter(item =>
        corridorIDsArray?.includes(item.value.toString())
      );


      const departmentIDsArray = EditDropDown.DepartmentIDs?.split(',').map(id => id.trim()) ?? [];

      const filteredDepartments = DropdownModel.DepartmentList.filter(item =>
        departmentIDsArray.includes(item.id.toString())
      );


      const TypeIDs = filteredDepartments.map(item => item.Type).join(',');
      const typeidss = TypeIDs?.split(",").map((typeID) =>
        typeID.trim()
      );


      const corridorIds = corridorIdList.map(
        (corridor) => corridor.value
      );

      const sectionList = DropdownModel.StationTypeList.filter(
        (item) =>
          typeidss.includes(item?.Type?.toString()) &&
          corridorIds.includes(item?.CorridorId)
      );
      if (stationList.length == 0) {
        setStationList(sectionList);
      }

      if (DropdownModel?.StationTypeList?.length) {
        let Stations = EditDropDown.TempStationTypeID?.split(",").map((ele) => parseInt(ele, 10));
        let selectedStation = DropdownModel?.StationTypeList.filter((ele) => {
          return Stations.includes(ele.value)
        })

        setDropdownList((pre) => ({
          ...pre,
          TypeIDs: TypeIDs,
          CorridorID: corridorIdList,
          StationTypeID: selectedStation,
          flag: 1
        }))

        HandleChange("CorridorID", corridorIdList);
        HandleChange("StationTypeID", selectedStation);


      }




      setDropdownList((prevState) => ({
        ...prevState,
        DepartmentIDs: EditDropDown.DepartmentIDs,
        TypeIDs: DropdownList.TypeIDs,
        // CorridorID: EditDropDown.CorridorId,
        // StationTypeID: EditDropDown.StationTypeID,
        LocationTypeID: EditDropDown.LocationTypeID,
        LocationNo: EditDropDown.LocationNo,
      }));
    }
    if (treeData != null && EditDropDown.DepartmentIDs) {
      SetStationID(
        EditDropDown.DepartmentIDs.includes(DropdownModel.oheDepartmentID)
          ? 1
          : 0
      );

      const TemptreeDate = JSON.parse(JSON.stringify(treeData));
      const DepId = EditDropDown.DepartmentIDs?.split(",");
      DepId?.forEach((ele) => {
        let obj = findDepartmentById(TemptreeDate, parseInt(ele));
        if (obj) obj.IsSelected = true;
      });
      // propagateSelectionRecursively(obj, true);
      settreeData(TemptreeDate);
    }
  }, [JSON.stringify(EditDropDown)]);

  useEffect(() => {
    if (DropdownModel.StationTypeList.length > 0) {
      const { DepartmentIDs, CorridorID } = DropdownList;
      const filteredExceptOheDepList = DropdownModel.DepartmentList.filter(
        (item) =>
          !DropdownModel.OheDepList.some(
            (oheItem) => oheItem.OHEDepartmentIDs === item.id
          )
      );

      if (DropdownList.TypeIDs == "" && DropdownList.DepartmentIDs != '') {

        const departmentIDsArray = EditDropDown.DepartmentIDs?.split(',').map(id => id.trim()) ?? [];

        const filteredDepartments = DropdownModel.DepartmentList.filter(item =>
          departmentIDsArray.includes(item.id.toString())
        );
        const TypeIDs = filteredDepartments.map(item => item.Type).join(',');

        if (CorridorID != "") {
          const typeidss = TypeIDs?.split(",").map((typeID) =>
            typeID.trim()
          );
          const corridorIds = DropdownList.CorridorID.map(
            (corridor) => corridor.value
          );

          const sectionList = DropdownModel.StationTypeList.filter(
            (item) =>
              typeidss.includes(item?.Type?.toString()) &&
              corridorIds.includes(item?.CorridorId)
          );

          setStationList(sectionList);

          const typeIDsArray = DropdownList?.TypeIDs?.split(",").map((typeID) =>
            typeID.trim()
          );

        }
      }
      else {
        if (CorridorID != "") {
          const typeidss = DropdownList.TypeIDs?.split(",").map((typeID) =>
            typeID.trim()
          );
          const corridorIds = DropdownList.CorridorID.map(
            (corridor) => corridor.value
          );

          const sectionList = DropdownModel.StationTypeList.filter(
            (item) =>
              typeidss.includes(item?.Type?.toString()) &&
              corridorIds.includes(item?.CorridorId)
          );
          console.log(DropdownList.DepartmentIDs);

          setStationList(sectionList);

          const typeIDsArray = DropdownList?.TypeIDs?.split(",").map((typeID) =>
            typeID.trim()
          );

        }
      }


    }
  }, [DropdownList.z, DropdownList.CorridorID]);

  const initDropdown = async () => {
    await _empServc.Employee_GetCommonDropdown().then((res) => {
      setDropdownModel((pre) => ({
        ...pre,
        DepartmentList: res.data.data.departmentList,
        CorridorList: res.data.data.corridorList,
        StationTypeList: res.data.data.stationTypeList,
        LocationypeList: res.data.data.locationTypeList,
        oheDepartmentID: res.data.data.oheDepartmentID?.[0]?.DepartmentID,
        OheDepList: res.data.data.oheDepartmentList,
      }));
      if (res.data.data.departmentList.length != 0) {
        let treeJsonData = BuildTreeUsingParentID(res.data.data.departmentList);
        let Data = JSON.parse(JSON.stringify(treeJsonData, null, 2));

        settreeData(Data);
      }
    });
  };
  const CommonHandleChange = (e) => {
    const { name, value, label } = e.target;
    setDropdownList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    HandleChange(name, value);
  };
  // const setSelectedDepartment = (id = 0) => {
  //
  //     let obj = findDepartmentById(treeData, id);
  //     setOnSelect(obj.name);
  //     setDropdownList((pre) => ({
  //         ...pre,
  //         DepartmentID: id,
  //     }));
  //     if (obj.name == 'OHE') {
  //         SetStationID(1);
  //     } else {
  //         SetStationID(0);
  //     }
  //     HandleChange('DepartmentID', id)

  // };

  const handleCheckedNodesChange = (node, IsChecked) => {
    const { id } = node;
    const isTvsVac = getCookies(Global.COOKIES.IsTvsvac);
    console.log(isTvsVac);
    const TemptreeDate = JSON.parse(JSON.stringify(treeData));
    debugger;
    const isTvsVacBoolean = (isTvsVac === "false");
    const filteredNodes = isTvsVacBoolean
      ? TemptreeDate.filter(node => node.name !== 'E&M' && node.name !== 'TVS VAC')
      : TemptreeDate;
    console.log(isTvsVacBoolean);
    console.log(filteredNodes, 'First');
    if (filteredNodes?.length) {
      let FinedObj = findDepartmentById(filteredNodes, id);
      FinedObj.IsSelected = IsChecked;
      const selctedTreedata = FinedObj;

      propagateSelectionRecursively(selctedTreedata, IsChecked);
      settreeData(filteredNodes);
    }

    //submit method
    function collectSelectedIdsRecursively(nodes, selectedIds, typeIDs) {
      nodes.forEach((node) => {
        if (node.IsSelected) {
          selectedIds.push(node.id);
          typeIDs.push(node.Type);
        }
        if (node.children) {
          collectSelectedIdsRecursively(node.children, selectedIds, typeIDs);
        }
      });
      console.log(selectedIds);
      setDropdownList((prevState) => ({
        ...prevState,
        StationTypeID: []
      }));


    }
    // Usage:
    const selectedIds = [];
    const typeIDs = [];
    collectSelectedIdsRecursively(TemptreeDate, selectedIds, typeIDs);
    const Ids = selectedIds.join(",");
    const typeIdsSelected = typeIDs.join(",");
    setDropdownList((prevState) => ({
      ...prevState,
      DepartmentIDs: Ids,
      TypeIDs: typeIdsSelected,
    }));
    HandleChange("DepartmentIDs", Ids);
    SetStationID(Ids.includes(DropdownModel.oheDepartmentID) ? 1 : 0);

    const { DepartmentIDs, CorridorID } = DropdownList;
    if (CorridorID != "") {
      const typeidss = typeIdsSelected
        .split(",")
        .map((typeID) => typeID.trim());
      const corridorIds = DropdownList.CorridorID.map(
        (corridor) => corridor.value
      );

      const sectionList = DropdownModel.StationTypeList.filter(
        (item) =>
          typeidss.includes(item.Type.toString()) &&
          corridorIds.includes(item.CorridorId)
      );
      console.log()

      setStationList(sectionList);
    }
    const typeIDsArray = typeIdsSelected
      ?.split(",")
      .map((typeID) => typeID.trim());

    setisDisabled(!typeIDsArray.includes("95"));
    if (!typeIDsArray.includes("95")) {
      setDropdownList((pre) => ({
        ...pre,
        LocationTypeID: "",
        LocationNo: "",
      }));
    }
    //submit method
  };



  return (
    <div>
      <form id="filterDepartment">
        <div className="row">
          <div className="col-md-4">
            <label className="label" for="stationCode">
              Select Department<span className="text-danger"> *</span>{" "}
            </label>
            <ReactMultiselectTreeView
              openByDefault={false}
              isSearchNeed={false}
              data={treeData}
              width={250}
              height={132}
              IsMultiselect={true}
              onCheckedNodesChange={handleCheckedNodesChange}
            ></ReactMultiselectTreeView>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Corridor Type<span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={DropdownModel.CorridorList}
                    isMulti
                    allowSelectAll
                    value={DropdownList.CorridorID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      setDropdownList((prevState) => ({
                        ...prevState,
                        CorridorID: e,
                      }));
                      setDropdownList((prevState) => ({
                        ...prevState,
                        StationTypeID: []
                      }));


                      HandleChange("CorridorID", e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Station/Section/Depo/AFC
                    <span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={stationList}
                    isMulti
                    allowSelectAll
                    value={DropdownList.StationTypeID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      setDropdownList((prevState) => ({
                        ...prevState,
                        StationTypeID: e,
                      }));

                      HandleChange("StationID", e);
                    }}
                  />
                </div>
              </div>
              {/* {stationID == 1 && (( */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Location Type
                    {/* <span className="text-danger"> *</span>{" "} */}
                  </label>
                  <Dropdown
                    data={DropdownModel.LocationypeList}
                    placeholder="Select"
                    name={"LocationTypeID"}
                    HandleChange={CommonHandleChange}
                    disabled={isDisabled}
                    value={DropdownList.LocationTypeID}
                    className=""
                  />
                </div>
              </div>
              {/* ))} */}
              {/* {stationID == 1 && (( */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label" for="stationCode">
                    Location Number
                    {/* <span className="text-danger"> *</span>{" "} */}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={DropdownList.LocationNo}
                    name="LocationNo"
                    disabled={isDisabled}
                    onChange={CommonHandleChange}
                  />
                  {/* <span className="text-danger"> {errors.DepartmentID} </span> */}
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default DepartmentFilter;
