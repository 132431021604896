import React from "react";
export const FilterDropdown = (props) => {
  const { code, onChange, value, name } = props;

  var data = [];
  const hidePlaceholder =
    code == "Today" || code == "Station" || code == "Eqpt";

  switch (code) {
    case "Year":
      var minOffset = 0, maxOffset = 10; // Change to whatever you want
      var Year = (new Date()).getFullYear();
      const startYear = 2022;
      const endYear = new Date().getFullYear();

      for (let year = endYear; year >= startYear; year--) {
        data.push({ value: year, text: year });
      }
      // for (var i = minOffset; i <= maxOffset; i++) {
      //   var year = Year - i;
      //   data.push({ value: year, text: year });
      // }

      break;
    case "Month":
      data = [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ];
      break;
    case "Week":
      data = [];
    case "Today":
      data = ["Today", "Last 7 days", "Last 15 days", "Last 30 days"];
      break;
    case "Station":
      data = [{ text: "STC", value: 1 }, { text: "STR", value: 2 }];
      break;
    case "Eqpt":
      data = ["STC-TVF-001", "STC-TVF-002", "STC-TVF-003", "STC-TVF-004"];
      break;
  }

  return (
    <div>
      <select
        key={name}
        className="form-select"
        tabIndex="-1"
        name={name ? name : ''}
        aria-hidden="true"
        onChange={onChange}
        value={value ? value : ''}
      >
        {/* Hide placeholder if code is Today or Station or Equipment*/}
        {!hidePlaceholder ? (
          <option selected disabled>
            {code}
          </option>
        ) : (
          <></>
        )}
        {data != null && data.length > 0 ? (
          data.map((v, i) => {
            return <option key={v.value} value={v.value}>{v.text}</option>;
          })
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};
