import React from "react";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import DesignAndCommService from "./DesingAndCommService";
import {
  GetSelect,
  getPrivilege,
  GetLoginUserID,
} from "./../../services/CommonService";
import {
  showSaveError,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  showDeleteSuccess,
} from "./../../components/ToastMsg";
import swal from "sweetalert";

$.DataTable = require("datatables.net");

const _designAndCommService = new DesignAndCommService();

export const CommisionedDetailsTable = ({
  columnList,
  rowList,
  tableName,
  parameters,
}) => {
  const [isTable, setIsTable] = useState(true);
  var t;

  let trueorfalse = false;
  var childColumns = [
    {
      title: "Eqpt ID",
      className: "hide",
    },
    {
      title: "Commissioned Detail ID",
      className: "hide",
    },
    {
      title: "Commisioned Parameter ID",
      className: "hide",
    },
    {
      title: "Equipment Parameter",
      createdCell: (td, cellData, rowData, row, col) => {
        var b;
        //  = $(GetSelect(parameters, cellData, true)).on("change", function (e) {
        //   //   var t = $(`#${tableName}`).DataTable();
        //   //   console.log(t.cell(td));
        // });

        if (
          getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
          rowData[0] == 0
        ) {
          b = $(GetSelect(parameters, cellData)).on("change", function (e) { });
        } else {
          b = $(GetSelect(parameters, cellData, true)).on(
            "change",
            function (e) { }
          );
        }
        $(td).html(b);
      },
    },
    {
      title: "At 30Hz-Hidden",
      className: "hide",
    },
    {
      title: "At 30Hz",

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        //  = $(
        //   `<input  pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        // );
        if (
          getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
          rowData[0] == 0
        ) {
          b = $(
            `<input type="text" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;"  class="form-control" value="${cellData}"/>`
          );
        } else {
          b = $(
            `<input type="text" readonly pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;"  class="form-control" value="${cellData}"/>`
          );
        }

        // b = $(
        //   `<input type="number" readonly pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;"  class="form-control" value="${cellData}"/>`
        // );

        $(td).html(b);
      },
      //  className: trueorfalse ? "" : "hide",
    },
    {
      title: "At 50Hz-Hidden",
      className: "hide",
    },
    {
      title: "At 50Hz",
      createdCell: (td, cellData, rowData, row, col) => {
        var b;
        // = $(
        //   `<input type="number" readonly pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==10) return false;" class="form-control" value="${cellData}"/>`
        // );
        if (
          getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
          rowData[0] == 0
        ) {
          b = $(
            `<input type="text" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;"  class="form-control" value="${cellData}"/>`
          );
        } else {
          b = $(
            `<input type="text" readonly pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==50) return false;"  class="form-control" value="${cellData}"/>`
          );
        }

        $(td).html(b);
      },
    },
    {
      title: "Units of Measurement",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = $(
          `<input type="text" class="form-control" value="${cellData}" readonly style="background-color:#E0E0E0	"/>`
        );
        $(td).html(b);
      },
    },
    {
      title: "SystemTypeID",
      className: "hide",
    },
    {
      title: "row ID",
      className: "hide",
    },
    {
      title: "Submit",
      // className: "hide",
      visible:
        getPrivilege().includes("EQUIPMENTDESIGNEDIT") ||
        getPrivilege().includes("EQUIPMENTDESIGNCREATE") ||
        getPrivilege().includes("EQUIPMENTDESIGNDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";

        if (
          getPrivilege().includes("EQUIPMENTDESIGNEDIT") ||
          getPrivilege().includes("EQUIPMENTDESIGNCREATE")
        ) {
          var b = $(`<button class='btn icon-btn' title='Save'>
        <i class='fa fa-check' style="margin-top:10px"></i>
      </button>`).on("click", function () {
            saveEqptDesingVal(rowData, row, 1);
          });
        }
        if (getPrivilege().includes("EQUIPMENTDESIGNDELETE")) {
          c = $(`<button class='btn'  title='delete'  >
                <i class='fa fa-trash m-l-15'></i>
              </button>`).on("click", function () {
            DeleteParameter(rowData, row, 0);
          });
        }
        $(td).html(b);
        $(td).append(c);
      },
    },
    {
      title: "Equipment ID",
      className: "hide",
    },
  ];

  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
  }, [rowList, isTable]);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: false,
      autoWidth: false,
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        var eqptCodeID = $(this).closest("tr").find("td:eq(1)").html();

        _designAndCommService
          .GetCommissionedDetailsParameterByID(eqptCodeID)
          .then((childRows) => {
            if (
              childRows != null &&
              childRows.data != null &&
              childRows.data.length > 0
            ) {
              var rowList = [];
              childRows.data.map((v, i) => {
                var rows = [];
                rows.push(eqptCodeID);
                rows.push(v.equipCommDetID);
                rows.push(v.parameterID);
                rows.push(v.parameterID);
                rows.push(v.aT30Hz);
                rows.push(v.aT30Hz);
                rows.push(v.aT50Hz);
                rows.push(v.aT50Hz);

                if (v.parameterID > 0) {
                  var findUnits = parameters.find(
                    (e) => e.value == v.parameterID
                  );
                  findUnits != null ? rows.push(findUnits.unit) : rows.push("");
                }
                rows.push(v.systemTypeID);

                rows.push(i + 1);
                rows.push("");
                rows.push(v.equipmentID);
                rowList.push(rows);
              });
              row.child(ChildTable(eqptCodeID)).show();

              //set child table
              childTable(rowList, childColumns, eqptCodeID);
            } else {
              row.child(ChildTable(eqptCodeID)).show();

              //set child table
              childTable([], childColumns, eqptCodeID);
            }
          });
        // row.child(ChildTable(eqptCodeID)).show();
        // //set child table
        // childTable([], childColumns, eqptCodeID);
        tr.addClass("shown");
      }
    });

    return function () {
      table.destroy();
    };
  };

  const childTable = (rowList, childColumns, eqptCodeID) => {
    const temp = rowList[0];

    // if (temp[12] == 10) {
    //   childColumns[5].visible = true;

    // }
    // else {
    //   childColumns[5].visible = false;
    // }

    t = $(`#comm_detail-${eqptCodeID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      paging: false,
      bInfo: false,
      scrollX: false,
      autoWidth: true,
      order: [[10, "desc"]],
    });

    //add new row functionality
    $(`#addRow-${eqptCodeID}`).on("click", function () {
      childColumns[5].visible = true;
      var rowID = t.column(0).data().length + 1;
      t.row
        .add([eqptCodeID, 0, 0, "", "", "", "", "", "", "", rowID, "", 0])
        .draw(false);

      //update row values
      updateValues(t, eqptCodeID);
    });

    updateValues(t, eqptCodeID);
  };

  const updateValues = (t, eqptCodeID) => {
    $(`#comm_detail-${eqptCodeID}  tbody  tr`).each(function (index, tr) {
      var row = t.row(this);
      var rowIndex = t.row(this).index();

      //on Paramter change
      var selectCell = $(this).closest("tr").find("td:eq(3)");
      selectCell.on("change", function (e) {
        //update parameter value
        t.cell({ row: rowIndex, column: 2 }).data(Number(e.target.value));

        //update paramter unit
        var parameterID = Number(e.target.value);
        if (parameterID > 0) {
          var paramName = parameters.find((v) => v.value == parameterID);
          if (paramName != null) {
            t.cell({ row: rowIndex, column: 8 }).data(paramName.unit);
          }
        }
      });

      //on 30Hz value change
      var inputCell = $(this).closest("tr").find("td:eq(5)");
      inputCell.on("change", function (e) {
        t.cell({ row: rowIndex, column: 4 }).data(e.target.value);
      });
      var inputCell2 = $(this).closest("tr").find("td:eq(7)");
      inputCell2.on("change", function (e) {
        t.cell({ row: rowIndex, column: 6 }).data(e.target.value);
      });
    });
  };

  const saveEqptDesingVal = (data, row, status) => {
    if (
      data != null &&
      data.length > 0 &&
      data[0] != "" &&
      data[2] > 0 &&
      (data[4] != "" || data[6] != "")
      // parseFloat(data[4]) != NaN &&
      // parseFloat(data[6]) != NaN
    ) {
      var commDetails = {
        EquipCommDetID: data[1],
        EquipmentDetailsID: Number(data[0]),
        ParameterID: data[2],
        AT30Hz: data[4],
        AT50Hz: data[6],
        SavedBy: GetLoginUserID(),
        IsActive: status,
      };

      _designAndCommService.AddOrUpdateCommissioned(commDetails).then((res) => {
        if (
          res != null &&
          res.isSuccess &&
          res.data != "Parameter already exists"
        ) {
          showSaveSuccess();
          t.cell({ row: row, column: 1 }).data(res.data);
        } else if (res.isSuccess && res.data == "Parameter already exists") {
          showWarningMsg(res.data);
        } else {
          showSaveError();
        }
      });
    } else {
      showWarningMsg("Please fill all fields");
    }
  };

  const DeleteParameter = (data, row, status) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this parameter?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (
          data != null &&
          data.length > 0 &&
          data[0] != "" &&
          data[2] > 0 &&
          (data[4] != "" || data[6] != "")
        ) {
          var commDetails = {
            EquipCommDetID: data[1],
            EquipmentDetailsID: Number(data[0]),
            ParameterID: data[2],
            AT30Hz: data[4],
            AT50Hz: data[6],
            SavedBy: GetLoginUserID(),
            IsActive: status,
          };

          _designAndCommService
            .AddOrUpdateCommissioned(commDetails)
            .then((res) => {
              if (
                res != null &&
                res.isSuccess &&
                res.data != "Parameter already exists"
              ) {
                showDeleteSuccess();
                t.cell({ row: row, column: 1 }).data(res.data);

                var eqptCodeID = Number(data[0]);

                const getData = async () => {
                  _designAndCommService
                    .GetCommissionedDetailsParameterByID(eqptCodeID)
                    .then((childRows) => {
                      if (
                        childRows != null &&
                        childRows.data != null &&
                        childRows.data.length > 0
                      ) {
                        var rowList = [];
                        childRows.data.map((v, i) => {
                          var rows = [];
                          rows.push(eqptCodeID);
                          rows.push(v.equipCommDetID);
                          rows.push(v.parameterID);
                          rows.push(v.parameterID);
                          rows.push(v.aT30Hz);
                          rows.push(v.aT30Hz);
                          rows.push(v.aT50Hz);
                          rows.push(v.aT50Hz);

                          if (v.parameterID > 0) {
                            var findUnits = parameters.find(
                              (e) => e.value == v.parameterID
                            );
                            findUnits != null
                              ? rows.push(findUnits.unit)
                              : rows.push("");
                          }
                          rows.push(v.systemTypeID);

                          rows.push(i + 1);
                          rows.push("");
                          rows.push(v.equipmentID);
                          rowList.push(rows);
                        });
                        // row.child(ChildTable(eqptCodeID)).show();

                        //set child table
                        childTable(rowList, childColumns, eqptCodeID);
                      } else {
                        // row.child(ChildTable(eqptCodeID)).show();

                        //set child table
                        childTable([], childColumns, eqptCodeID);
                      }
                    });
                };
                getData();
              } else if (
                res.isSuccess &&
                res.data == "Parameter already exists"
              ) {
                showWarningMsg(res.data);
              } else {
                showSaveError();
              }
            });
        } else {
          showWarningMsg("Please fill all fields");
        }
      }
    });
  };

  return (
    <div className="table-responsive">
      <div className="row">
        <div className="col-sm-12">
          {isTable ? (
            <table
              className="table table-striped no-footer display collapse-table"
              id={tableName}
              width="100%"
            ></table>
          ) : (
            /* No of coulms and no of rows must be equal*/
            <div className="row col-12 text-center mt-2 mb-2">
              <p className="text-muted">Table not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ChildTable = (tableIndex) => {
  if (getPrivilege().includes("EQUIPMENTDESIGNCREATE")) {
    return `
  
    <div class="row d-flex justify-content-end"><div class="col-md-2" style=" position: relative; top: -43px;"><button type="submit" id="addRow-${tableIndex}" class="btn add-btn btn-sm"><i class="fa fa-plus"></i> Add</button></div></div>
  <div class"row "  style="margin-top: -43px;">
    <table id="comm_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
    </table></div>`;
  } else {
    return `
    <div class"row " style="margin-top: -43px;">
      <table id="comm_detail-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width:100%">
      </table></div>`;
  }
};
