import React, { useContext } from "react";
import { AppContext } from "./../services/ContextProvider";

const TabHeader = ({ header, onTabChange,InitialValue = 0 }) => {

  return (
    <div className="card p-2 m-0">
      <ul className="nav nav-tabs nav-tabs-solid nav-justified">
        {header != null && header.length > 0 ? (
          header.map((v, i) => {
            if (i == 0 && InitialValue == 0) {
              var id = "#solid-justified-tab" + (i + 1);
              var tabid = "tabID" + (i + 1);
              return (
                <li className="nav-item" key={i}>
                  <a
                  id = {tabid}
                    className="nav-link active"
                    // data-bs-target={id}
                    // data-bs-toggle="tab"
                    onClick={() => 
                      {
                      typeof onTabChange == "function" ? 
                        onTabChange(i) : (function() { return false; }())
                      }
                      }
                        
                  >
                    {v}
                  </a>
                </li>
              );
            }else if(InitialValue == i)
            {
              var id = "#solid-justified-tab" + (i + 1);
              var tabid = "tabID" + (i + 1);
              return (
                <li className="nav-item" key={i}>
                  <a
                  id = {tabid}
                    className="nav-link active"
                    // data-bs-target={id}
                    // data-bs-toggle="tab"
                    onClick={() => 
                      {
                      typeof onTabChange == "function" ? 
                        onTabChange( i) : (function() { return false; }());
                      }
                      }
                        
                  >
                    {v}
                  </a>
                </li>
              );
            }
            
            
            
            
            else {
              var id = "#solid-justified-tab" + (i + 1);
              var tabid = "tabID" + (i + 1);

              return (
                <li className="nav-item" key={i}>
                  <a
                  id = {tabid}
                    className="nav-link"
                    data-bs-target={id}
                    data-bs-toggle="tab"
                    onClick={() => 
                      {
                      typeof onTabChange == "function" ? 
                        onTabChange( i) : (function() { return false; }());
                      }
                      }
                  >
                    {v}
                  </a>
                </li>
              );
            }
          })
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};

const TabBody = ({ body, currentTab,InitialValue = 0 }) => {
  return (
    <div className="card mt-2" >
      <div className="card-body">
        <div className="tab-content">
          {body != null && body.length > 0 ? (
            body.map((v, i) => {
              var id = "solid-justified-tab" + (i + 1);
              if (i == 0 && InitialValue == 0) {
                return (
                  <div key={i} className="tab-pane show active" id={id}>
                    {v}
                  </div>
                );
              }
              else if(InitialValue == i)
              {
                return (
                  <div key={i} className="tab-pane show active" id={id}>
                    {v}
                  </div>
                );
              }
              else{
              return (
                <div key={i} className="tab-pane show" id={id}>
                  {v}
                </div>
              );
              }
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export const TabBar = ({ header, body,onTabChange,InitialValue }) => {
  return (
    <>
      <TabHeader header={header} onTabChange = {onTabChange} InitialValue = {InitialValue}  />
      <TabBody body={body} InitialValue = {InitialValue} />
    </>
  );
};
