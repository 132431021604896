import { Encrypt } from "./../../services/CommonService";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class EmployeeService {

  GetEmployeeList(stationID, departmentID) {

    return axios.get(`Employee/GetEmployeeList?stationID=${stationID}&departmentID=${departmentID}`)

  }
  GetEmployeeByID(empID) {

    return axios.get(`Employee/GetEmployeeByID?empID=${empID}`).then(r => r.data).catch(e => console.log(e));

  }
  SaveEmployeeMaster(data) {

    return axios.post("Employee/SaveEmployeeMaster", data)
  }
  DeleteEmployee(empID) {

    return axios.get(`Employee/DeleteEmployee?empID=${empID}`)

  }
  EmployeeDeleteEmployee(empID) {

    return axios.get(`Employee/EmployeeDeleteEmployee?empID=${empID}`)

  }

  UpdatePicPath(id, path) {


    return axios.post(`Employee/UpdatePicPath?empid=${id}&path=${path}`).then(r => r.data).catch(e => console.log(e));
  }

  EmpcodeAlreadyExists(empCode) {

    return axios.get(`Employee/EmpEmpcodeAlreadyExists?empCode=${empCode}`)

  }

  EmpEmailIDAlreadyExists(email) {

    return axios.get(`Employee/EmpEmailIDAlreadyExists?email=${email}`)

  }
  GetSystemType() {
    return axios.get("Employee/GetDesignationList");
  }
  Employee_GetVendorList() {
    return axios.get("Employee/Employee_GetVendorList");
  }
  GetDeptList() {
    return axios.get("Employee/GetDeptList");
  }

  Employee_GetCommonDropdown() {
    return axios.get("Employee/Employee_GetCommonDropdown");
  }
}