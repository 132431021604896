import React from "react";
import { createContext, useState, useContext } from "react";

export const StationLocationContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => {},
  DropDownInitialState: {},
  ConfigModel: {},
  setConfigModel: () => {},
  InitialState: {},
  setConfigList: () => {},
  ConfigList: [],
});

function StationLocationContextProvider({ children }) {
  const DropDownInitialState = {
    StationList: []
  };

  const InitialState = {
    StationLocationID : 0,
    StationID: {},
    GeoLocationName: "",
    Latitude: "",
    Longitude : "",
    OffsetLatitude: "",
    OffsetLongitude : "",
    Range: 0,
  };


  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [ConfigModel, setConfigModel] = useState(InitialState);

  const [ConfigList, setConfigList] = useState([]);

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
    ConfigModel,
    setConfigModel,
    InitialState,
    ConfigList,
    setConfigList
  };

  return (
    <StationLocationContext.Provider value={contextValue}>
      {children}
    </StationLocationContext.Provider>
  );
}

export function StationLocationContextValues() {
  return useContext(StationLocationContext);;
}

export default StationLocationContextProvider;
