import React, { useRef, useState, useContext, useEffect } from "react";
import { openstreetmapApiCall } from "./CameraService";
import { AppContext } from "../../services/ContextProvider";
import { showErrorMsg, showSuccessMsg } from "..";
import { COOKIES } from "../../services/Global";
import { saveAs } from "file-saver";
import BreakdownMaintenanceService from "../../screens/BreakDownMaintenance/BreakdownMaintenanceService";
import moment from "moment";
import { FaCamera } from "react-icons/fa";
import { IoCameraReverse } from "react-icons/io5";
import { MdCamera } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { StationLocationGetList } from "../../screens/StationLocationConfiguration/StationLocationService";
import { checkCurrentLocationWithinRange } from "./LocationTracker";

const CameraComponent = ({
  HandleChange = () => {},
  ClearImage = "",
  AddOnName = "",
}) => {
  const { hideLoading, showLoading, getCookies } = useContext(AppContext);
  const _breakdownService = new BreakdownMaintenanceService();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState("");
  const [blob, setblob] = useState({});
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [showVideo, setShowVideo] = useState(true);
  const [filename, setfilename] = useState("");
  const [WatchID, setWatchID] = useState("");
  const [StationLocationList, setStationLocationList] = useState([]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };


useEffect(() => {
  getStationLocation();
}, []);

  const getStationLocation = async () => {
    await StationLocationGetList().then((res) => {
      if(res?.data?.data.length){
        setStationLocationList(res.data.data)
      }else{
        setStationLocationList([])
      }
    }).catch((err) => console.log(err))
  }



  useEffect(() => {
    setWatchID("");
    setfilename("");
    setfilename("");
    setShowVideo(true);
    setShowVideo(true);
    setCapturedImage("");
  }, [ClearImage]);

  const askForLocationPermission = async () => {
    let WatchID = await navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude : CurrentLat, longitude : CurrentLong } = position.coords;
        let displayName = `Latitude : ${CurrentLat}, Longitude : ${CurrentLong}`
        StationLocationList.forEach((ele) => {

          const { GeoLocationName, Latitude, Longitude, OffsetLatitude, OffsetLongitude, Range,IsStation, StationCode} = ele;
           if(checkCurrentLocationWithinRange(CurrentLat,CurrentLong,Latitude,Longitude,OffsetLatitude,OffsetLongitude,IsStation,Range)){
            displayName = GeoLocationName
            return;
           }
        })
        setAddress(displayName);
        startCamera();
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
    setWatchID(WatchID);
  };

  const retake = () => {
    setCapturedImage("");
    setShowVideo(true);
    askForLocationPermission();
  };
  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Add timestampkpokpo

    const timestamp = new Date().toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    context.font = "16px Arial";
    context.fillStyle = "white"; // Color of the text
    context.fillText(timestamp, 10, canvas.height - 60);
    context.fillText(address, 10, canvas.height - 30);
    context.fillText(address2, 10, canvas.height - 10);
    // Convert image to base64
    const imageDataURL = canvas.toDataURL("image/png");
    const blob = dataURLtoBlob(imageDataURL);
    setblob(blob);
    setCapturedImage(imageDataURL);
    stopCamera();
    StopTracking();

    setShowVideo(false);
  };
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    const filename =
      AddOnName +
      getCookies(COOKIES.UserName) +
      "_" +
      moment().format("DD_MM_YYYY") +
      ".png";
    setfilename(filename);
    // saveAs(new Blob([arrayBuffer], { type: mimeString }), `${filename}`)

    return new Blob([arrayBuffer], {
      type: mimeString,
      name: filename,
      FileName: filename,
    });
  }
  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("files", blob);
    let returnValue = await fileUpload(formData);
    if (returnValue) {
      const data = JSON.stringify(returnValue);
      HandleChange(data);
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    videoRef.current.srcObject = null;
  };

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData, filename)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  const StopTracking = () => {
    navigator.geolocation.clearWatch(WatchID);
  };

  return (
    <div className="row">
      <br />
      {showVideo ? (
        <div className="col-md-12 mb-2">
          <video
            ref={videoRef}
            autoPlay
            style={{
              maxWidth: "100%",
            }}
          ></video>
        </div>
      ) : (
        <div className="col-md-12 mb-2">
          <img
            src={capturedImage}
            alt="Captured"
            style={{ maxWidth: "100%", maxHeight: "40vh" }}
          />
        </div>
      )}
      <div className="row">
        <div className="col-md-12 mt-2 row">
          {/* <div class="btn-list"> */}
          <div className="col-1">
            <button
              type="button"
              className="btn btn-info Camera-btn"
              onClick={askForLocationPermission}
              title="Start Capture"

              // style={{ position: "relative", right: "25%" }}
            >
              <FaCamera />
            </button>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={captureImage}
              title="Capture Image"
            >
              <MdCamera />
            </button>
          </div>
          {!showVideo && (
            <div className="col-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={submit}
                title="Save"
                value="Submit"
              >
                <FaCheck />
              </button>
            </div>
          )}
          {!showVideo && (
            <div className="col-1">
              <button
                type="button"
                className="btn btn-warning"
                onClick={retake}
                title="Retake"
              >
                <IoCameraReverse />
              </button>
            </div>
          )}
        </div>
      </div>
      <br />
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
    </div>
  );
};

export { CameraComponent };
