import React, { useContext, useState } from "react";
import { AppContext } from "../../services/ContextProvider";
import { PeriodicService } from "../periodic/PeriodicService";
import { GetLoginUserID } from "../../services/CommonService";
import $ from "jquery";

const _periodicService = new PeriodicService();
export default function SafetyMeasurementModel() {
  const { SafetyMeasurementModel } = useContext(AppContext);
  const [IsAccept, setIsAccept] = useState(false);

  const SaveDailySafetyCheck = () => {
    let EmployeeID = GetLoginUserID();
    _periodicService
      .SafetyMeasurement_SaveStatus(EmployeeID)
      .then((res) => {
        $("#SafetyMesurementModelBtn").click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="h3 text-center">
        {SafetyMeasurementModel?.Heading ?? ""}
      </div>
      <hr></hr>
      <div className="h4 text-center">
        {SafetyMeasurementModel?.SubHeading ?? ""}
      </div>
      <ol>
        {SafetyMeasurementModel?.SafetyMeasures?.split(",").map(
          (ele, index) => {
            return <li key={index}>{ele}</li>;
          }
        )}
      </ol>
      <div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="AcceptCheckbox"
            onChange={(ele) => setIsAccept(ele.target.checked)}
          />
          <label
            className="form-check-label"
            htmlFor="AcceptCheckbox"
            style={{ cursor: "pointer" }}
          >
            I will read and understand the above safety rules and precautions.
          </label>
        </div>
        <div>
          <button
            disabled={!IsAccept}
            onClick={SaveDailySafetyCheck}
            className={`btn btn-primary ${!IsAccept ? "disabled" : ""}`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
