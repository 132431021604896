import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router";
import {
  CheckList_ReportGetByID,
  CheckList_ReportDropDownDetails
} from "./CheckListService";
import { Decrypt, DeepCopy, getYMDdate, tableLayout } from "../../services/CommonService";
import Global from "../../services/Global";
import {
  Dropdown,
  ReactMultiSelect,
  CheckListHtmlTable,
} from "../../components";
import { makeUnique } from "../../services/CommonService";
import { PageHeader } from "../../components/PageHeader";
import moment from "moment";

// import { logo } from "../screens/Image";
// Done by Vineeth
import { logo } from "../Image";

export default function CheckListReport() {
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
  });
  const todayDate = new Date();
  const initialCheckList = {
    EquipmentID: {},
    StationID: "",
    ScheduleType: 43,
    FromDate: getYMDdate(
      new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
    ),
    ToDate: getYMDdate(new Date()),
    StationName: "",
    EquipmentName: "",
  };

  const [CheckListModel, setCheckListModel] = useState(initialCheckList);
  // Done by Vineeth
  const { EquipmentName, FromDate, ToDate, StationName, DocumentNo } =
    CheckListModel;

  const [columnList, setColumnList] = useState([]);
  const [RowList, setRowList] = useState([]);
  const [repotTitle, setRepotTitle] = useState([]);

  const location = useLocation();
  const { getCookies, showLoading, hideLoading } = useContext(AppContext);

  useEffect(() => {
    initialDropDown(43);
    return () => {
      setDropdownModel({});
    };
  }, [location.pathname]);

  useEffect(() => {
    GetDescriptionByID();
  }, [JSON.stringify(CheckListModel)]);

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    await CheckList_ReportDropDownDetails()
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList } = res.data.data;
          let EquipmentList =
            equipmentList?.filter(
              (x) =>
                x.StationID == FinalStationList[0].value &&
                x.IsCheckListDaily == 43
            ) ?? [];


          setCheckListModel((pre) => ({
            ...pre,
            ScheduleType: 43,
            StationID: FinalStationList[0].value,
            EquipmentID: EquipmentList[0] ?? {},
            EquipmentName: EquipmentList[0]?.label ?? "",
            StationName: FinalStationList[0]?.key ?? "",
          }));

          if (equipmentList?.length) {
            setDropdownModel((preState) => ({
              ...preState,
              StationList: FinalStationList,
              EquipmentList: EquipmentList,
              FilterEquipmentList: equipmentList ?? [],
            }));
          }
        } else {
          setDropdownModel({});
        }
      })
      .catch((err) => {
        console.log(err);
        setDropdownModel({});
      });
  };

  const GetDescriptionByID = async () => {
    if (
      CheckListModel.StationID &&
      CheckListModel.EquipmentID?.Value &&
      CheckListModel.FromDate
    ) {
      await CheckList_ReportGetByID(
        CheckListModel.StationID,
        CheckListModel.EquipmentID.Value ?? 0,
        CheckListModel.ScheduleType,
        CheckListModel.FromDate,
        CheckListModel.ToDate
      )
        .then((res) => {
          if (res?.data?.data) {

            let columnsList = [];
            columnsList.push({
              title: " S.No",
              data: "RowID",
              width: "10px",
              // ClassName : "hide",
              // visible: false
            });
            columnsList.push({
              title: `<p style = 'width : 150px'> Description of Work<p/>`,
              data: "Description",
              width: "150px ",
            });

            const { descriptionList, equipmentList } = res.data.data;

            if (descriptionList?.length) {
              let uniqueList = makeUnique(equipmentList, [
                "EquipmentCode",
                "EquipmentDetailID",
              ]);

              uniqueList?.forEach((element, index) => {
                columnsList.push({
                  title: element.EquipmentCode,
                  data: element.EquipmentDetailID.toString(),
                  width: "60px",
                });
              });
              columnsList.push({
                title: "UOM",
                data: "UOM",
              })

              columnsList.push({
                title: "Remarks",
                data: "Remarks",
                width: "60px",
              });
              columnsList.push({
                title: "CheckDate",
                data: "CheckDate",
                visible: false,
              });
              descriptionList.forEach((element) => {
                let filList = equipmentList.filter(
                  (x) => x.CheckListID == element.CheckListID
                );
                filList.forEach((y) => {
                  let column_name = y.EquipmentDetailID.toString();
                  element[column_name] = y.CheckListData;
                });
              });

              let FinalRowObj = descriptionList.sort(
                (a, b) => a.RowID - b.RowID
              );

              setColumnList([...columnsList]);
              setRowList([...FinalRowObj]);
            } else {
              setColumnList([]);
              setRowList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setColumnList([]);
      setRowList([]);
    }
  };

  const CommonHandleChange = (event, name) => {
    debugger;
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;
      setCheckListModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (name == "ScheduleType") {
        let ScheduleName =
          value == 43 ? "IsCheckListDaily" : "IsCheckListWeekly";

        const EquipmentList = DropdownModel.FilterEquipmentList?.filter((x) => {
          return (
            x.StationID == CheckListModel.StationID && x[ScheduleName] == value
          );
        });

        setDropdownModel((pre) => ({
          ...pre,
          EquipmentList: EquipmentList,
        }));

        setCheckListModel((pre) => ({
          ...pre,
          EquipmentID: EquipmentList[0] ?? {},
          EquipmentName: EquipmentList?.[0].label ?? "",
        }));
      }
      if (name == "StationID") {
        let SelectedStation = DropdownModel?.StationList?.find(
          (x) => x.value == value
        );

        setCheckListModel((pre) => ({
          ...pre,
          StationName: SelectedStation.key,
        }));
      }
    } else {
      setCheckListModel((pre) => ({
        ...pre,
        [name]: event,
        EquipmentName: event.label,
      }));
    }
  };

  // Done By Vineeth Start


  const PDfCustomization = (doc) => {
    let array = JSON.parse(JSON.stringify(doc.content[1].table.body));
    let header = DeepCopy(doc.content[1].table.body[0]);
    debugger;
    let isWidth = false;
    if (header.length >= 10) {
      isWidth = true;
    }

    array = array.slice(1);
    let listsByDate = {};
    // Group items by date
    array.forEach(function (item) {
      let dateCheck = item[item.length - 1].text; // Assuming index 8 contains the dateCheck text field
      if (!listsByDate[dateCheck]) {
        listsByDate[dateCheck] = [DeepCopy(header)]; // Create a new list if it doesn't exist
      }
      listsByDate[dateCheck].push(item);
    });

    // Convert the object of lists into an array
    let mainList = Object.keys(listsByDate).map(function (dateCheck) {
      return {
        date: dateCheck,
        // items: listsByDate[dateCheck],
        items: listsByDate[dateCheck].map(function (item) {
          return item.slice(0, -1); // Remove the last index from each item array
        }),
      };
    });

    console.log("mainList", mainList);

    let addDateandStation = (date) => {
      return {
        columns: [
          {
            text: `Station Name: ${StationName}`,
            alignment: "left",
            margin: [0, 0, 0, 6],
          },
          {
            text: `Date: ${CheckListModel.ScheduleType == 43
              ? moment(date).format("DD-MM-YYYY")
              : `${moment(CheckListModel.FromDate).format("DD-MM-YYYY")} - ${moment(CheckListModel.ToDate).format("DD-MM-YYYY")}`}`,
            alignment: "right",
            margin: [0, 0, 0, 6],
          },
        ],
      };
    };

    let addStartHeader = () => [
      {
        columns: [
          {
            table: {
              widths: isWidth ? [440, 440, 250] : [200, 200, 150],
              layout: {
                vLineWidth: 0.5,
                hLineWidth: 0.5,
              },

              body: [
                [
                  {
                    text: `Doc no: ${CheckListModel?.EquipmentID?.DocumentNo} \n Rev: 01`,
                    alignment: "left",
                    margin: [0, 20, 0, 0],
                  },
                  {
                    text: `${CheckListModel.ScheduleType == 43 ? 'Daily' : 'Weekly'} checks for ${EquipmentName ?? ""}`,
                    // rowSpan: 2,
                    alignment: "center",
                    margin: [0, 23, 0, 0],
                  },
                  {
                    image: logo,
                    // rowSpan: 2,
                    alignment: "center",
                    width: 50,
                    margin: [0, 5, 0, 5],
                  },
                ],
              ],
            },
            margin: [18, 10, 0, 0],
          },
        ],
      },
    ];

    const table = DeepCopy(doc.content[1]);
    let n = 0;

    doc.header = function (page, pages) { };
    for (let i = 1, j = 0; i < mainList.length * 3; i = i + 3, j++) {
      let TechnicianRow = [];
      let EngineerRow = [];

      let copiedTable = DeepCopy(table);
      copiedTable.headerRows = 1;
      // doc.content[i] = addStartHeader();
      doc.content[i] = addDateandStation(formatDate(mainList[j].date));
      doc.header = addStartHeader();
      copiedTable.layout = tableLayout();
      copiedTable.table.body = mainList[j].items;
      doc.content.push(copiedTable);

      // let tableLength = headerArray.length;
      copiedTable.table.body.forEach((ele, index) => {
        if (index != 0) ele[0].text = index;
      });

      console.log(copiedTable.table.body);
      // copiedTable.body = [headerArray, ...copiedTable.table.body];
      let headerArray = copiedTable.table.body[0];
      TechnicianRow.push({
        text: "Name of the Shift Technician",
        colSpan: 2,
      });

      EngineerRow.push({
        text: "Name of the Shift Approver/Engineer",
        colSpan: 2,
      });

      let ShiftTechnician = DeepCopy(RowList?.[0]?.ShiftTechnician);
      let ShiftEngineer = DeepCopy(RowList?.[0]?.ShiftEngineer);

      headerArray.forEach((ele, index) => {
        if (index !== 0) {
          TechnicianRow.push({
            text: ShiftTechnician,
            colSpan: headerArray.length - 2,
            alignment: "center",
          });
          EngineerRow.push({
            text: ShiftEngineer,
            colSpan: headerArray.length - 2,
            alignment: "center",
          });
        }
      });

      //doc.content[1].table.body.push(mergedRows);
      copiedTable.table.body.push(TechnicianRow);
      copiedTable.table.body.push(EngineerRow);

      if (j !== mainList.length - 1) {
        doc.content.push({ text: "", pageBreak: "before" });
      }


      console.log("doc.content", doc.content);
      // doc.content
    }

    console.log("finallog", doc.content);
  };
  function formatDate(datetimeString) {
    return datetimeString.split("T")[0];
  }
  // Done By Vineeth End
  const fromMonth = new Date(FromDate)
    .toLocaleString("default", { month: "short" })
    .toLowerCase();
  const toMonth = new Date(ToDate)
    .toLocaleString("default", { month: "short" })
    .toLowerCase();
  const fromDateFormatted = `${FromDate.split("-")[2]}_${fromMonth} `;
  const toDateFormatted = `${ToDate.split("-")[2]}_${toMonth} `;
  const fromDate = `${EquipmentName}_${fromDateFormatted} -${toDateFormatted} `;
  return (
    <div>
      <PageHeader title={`Operation Checks Report`}> </PageHeader>
      <form id="ReportCheckList">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={CheckListModel.StationID}
                className=""
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                ScheduleType{" "}
              </label>
              <Dropdown
                data={[
                  { key: "Daily", value: 43 },
                  { key: "Weekly", value: 44 },
                ]}
                placeholder="Select"
                name={"ScheduleType"}
                HandleChange={CommonHandleChange}
                value={CheckListModel.ScheduleType}
                className=""
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment
              </label>
              <ReactMultiSelect
                options={DropdownModel.EquipmentList}
                placeholder="Select"
                value={CheckListModel.EquipmentID}
                onChange={(e) => {
                  CommonHandleChange(e, "EquipmentID");
                }}
              />
            </div>
          </div>

          <div className="col-2">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              name="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              value={CheckListModel.FromDate}
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
          <div className="col-2">
            <label className="label" for="">
              To Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              value={CheckListModel.ToDate}
              id="ToDate"
              name="ToDate"
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
        </div>
      </form>

      {columnList?.length ? (
        <>
          <div style={{ marginTop: "-25px" }}>
            <CheckListHtmlTable

              IsASC
              IsShowPDF
              // IsExcelNeed
              title="Operation Checks"
              rowList={RowList}
              columnList={columnList}
              id={`ReportCheckListTable`}
              StationName={CheckListModel.StationName ?? ""}
              EquipmentName={CheckListModel.EquipmentName ?? ""}
              pdfHeading={`${CheckListModel.ScheduleType} Checks for ${EquipmentName}`}
              PDfCustomization={PDfCustomization}
              DocumentNo={CheckListModel?.EquipmentID?.DocumentNo ?? ""}
              fromDate={fromDate}
            // orderby={columnList.length - 1}
            />
          </div>
        </>
      ) : (
        <div className="row col-12 text-center  mb-2">
          <p className="text-muted"></p>
        </div>
      )}
    </div>
  );
}
