import React, { useState, useContext } from "react";
import $ from "jquery";
import {
  Decrypt,
  DeepCopy,
  ShortMonths,
  generateQuarters,
  tableLayout,
} from "../../services/CommonService";
import { PageHeaderTitle, ReactMultiSelect } from "../../components";
import { FilterDropdown } from "../../components/FilterDropdown";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import { PeriodicService, Report_PeriodicMaintenance_DropDown } from "./PeriodicService";
import { useEffect } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import moment from "moment";
import { logo } from "../Image";
import { DTAjaxForDynamicColumns } from "../../components/DTAjaxForDynamicColumns";
function ReportPeriodicForDepart() {
  const [FilterModel, setFilterModel] = useState({
    DepartmentID: {},
    FromDate: "",
    ToDate: "",
    Frequency: "",
    Stations: {},
    ScheduleType: { label: "Daily", value: 1 },
    DateRange: [],
    Year: new Date().getFullYear(),
    PeriodicStartMonth: 1,
    EquipmentID: {},
    StationTypeID: {},
  });

  const [ConfigType, setConfigType] = useState("");
  const [ApproverList,setApproverList] = useState([])
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    FilterStationList: [],
    DepartmentList: [],
    DateRange: [],
    StartMonthConfigList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
    stationTypes: [],
  });

  const {
    getCookies,
    handleGlobalStationName,
    GetLoginStationTypes,
    GetLoginStationList,
  } = useContext(AppContext);

  const PeriodicServiceobj = new PeriodicService();

  const columns = [
    { data: "RowId", title: "Serial No" },
    { data: "StationCode", title: "Station Code" },
    { data: "EquipmentShortName", title: "Equipment" },
    {
      data: "EquipmentCode",
      title: `<p style="width: 150px !important;"> Equipment Tag No</p>`,
    },
    { data: "DescriptionName", title: "Description Name" },
    { data: "UOM", title: "UOM" },
    {
      title: "Reference Value",
      data: "RatedValue",
    },
    {
      data: "MaxValue",
      title: "Max Value",
    },

    {
      data: "MinValue",
      title: "Min Value",
    },
    {
      title: "Actual Value",
      data: "Actual",
    },
    {
      title: "Probable Reply",
      data: "EquipmentStatus",
    },

    {
      title: "Details",
      data: "Details",
    },
    {
      title: "Cell Voltage\nFloat Mode",
      data: "CellVoltageFloatMode",
    },

    {
      data: "CellVoltageDischargeMode",
      title: "Cell Voltage Discharge Mode",
    },
    {
      title: "Specific Gravity",
      data: "SpecificGravity",
    },
    {
      data: "InitialNoLoadCondition",
      title: "Initial No Load Condition",
    },
    { data: "Time_1", title: "1 hrs" },
    { data: "Time_2", title: "2 hrs" },
    { data: "Time_3", title: "3 hrs" },
    { data: "Time_4", title: "4 hrs" },
    { data: "Time_5", title: "5 hrs" },
    {
      data: "FinalNoLoadCondition",
      title: "Final No Load Condition ",
    },
    { data: "BoostVoltage", title: "Boost Voltage" },
    {
      title: "Remarks",
      data: "Remarks",
    },
    {
      data: "StatusName",
      title: "Status",
    },
    {
      data: "PWTNumber",
      title: "PTW Number",
      // className : "hide"
    },
    {
      data: "PWTDate",
      title: "PTW Date",
      // className : "hide"
    },
    {
      data: "AuditRemarks",
      title: "Audit Remarks",
      className: "hide",
    },
  ];

  const QuarterlyMonths = () => {
    let QuarterMonth = generateQuarters(FilterModel.PeriodicStartMonth ?? 1);
    let ShortMonthsList = ShortMonths();
    let QuarterList = [];
    if (QuarterMonth?.length) {
      QuarterMonth.forEach((ele) => {
        let startMonth = ele[0];
        let endMonth = ele[ele.length - 1];

        let Start = ShortMonthsList.find(
          (month) => month.value == startMonth - 1
        );
        let End = ShortMonthsList.find((month) => month.value == endMonth - 1);

        let finalLabel = `${Start.key} - ${End.key}`;
        let finalValue = Start.value;

        QuarterList.push({ key: finalLabel, value: finalValue });
      });
    }
    return QuarterList;
  };

  const HalfMonths = () => {
    return [
      { value: 5, key: "May - Oct" },
      { value: 11, key: "Nov - Apr" },
    ];
  };

  useEffect(() => {
    InitialDropDown();
    return () => {
      setDropdownModel((pre) => ({
        ...pre,
        StartMonthConfigList: [],
      }));
    };
  }, []);

  useEffect(() => {
    const { Stations, DepartmentID } = FilterModel;

    if (Stations?.value && DepartmentID?.value) {
      let EquipmentList = DropdownModel?.FilterEquipmentList.filter(
        (ele) =>
          ele.DepartmentID == DepartmentID.value &&
          ele.StationID == Stations.value
      );
      setDropdownModel((pre) => ({
        ...pre,
        EquipmentList: EquipmentList ?? [],
      }));

      setFilterModel((pre) => ({
        ...pre,
        EquipmentID: EquipmentList?.[0] ?? {},
      }));
    }
  }, [FilterModel?.DepartmentID?.value, FilterModel?.Stations?.value]);



  useEffect(() => {
    fetchApproverList()
  },[FilterModel])

  const InitialDropDown = async () => {
    let stationList = GetLoginStationList();
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));
    let stationTypes = GetLoginStationTypes();

    let Department = [];
    let Types = [];

    DepartmentDropDownValues();

    let Stations = [];
    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({
          key: data.label,
          ...data,
        });
      });
      handleGlobalStationName(Stations[0].key);
    }
    stationTypes.map((data) => {
      Types.push({
        key: data.label,
        ...data,
      });
    });
    let FilStations = Stations.filter(
      (ele) =>
        ele.CorridorID == Types[0].value && ele.Type == Department[0].Type
    );

    await Report_PeriodicMaintenance_DropDown()
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList, configMonth } = res.data.data;
          let EquipmentList = [];
          if (stationList?.length) {
            const StationID = Stations[0].value;

            const DepartmentID = Department[0].value;
            EquipmentList = equipmentList.filter(
              (ele) =>
                ele.StationID == StationID && ele.DepartmentID == DepartmentID
            );
          }

          setFilterModel((pre) => ({
            ...pre,
            EquipmentID: EquipmentList?.[0] ?? {},
            Stations: FilStations?.[0] ?? {},
            DepartmentID: Department[0],
            StationTypeID: Types?.[0].value ?? 0,
          }));
          console.log(Types?.[0].value ?? 0);

          setDropdownModel((pre) => ({
            ...pre,
            StartMonthConfigList: configMonth,
            EquipmentList,
            FilterEquipmentList: equipmentList,
            StationList: FilStations,
            DepartmentList: Department,
            stationTypes: Types,
            FilterStationList: Stations,
          }));
        }
      })
      .catch((err) => console.log(err));

    function DepartmentDropDownValues() {
      if (DepartmentList?.length) {
        const itemsWithoutChildren = DepartmentList.filter(
          (item) => !DepartmentList.some((child) => child.parentId === item.id)
        );
        itemsWithoutChildren.map((data) => {
          Department.push({
            value: data.id,
            label: data.name,
            Type: data.Type,
          });
        });

        Department = Department.filter((ele) => ele.label != "TVS & VAC");
      }
    }
  };

  const RangeObj = {
    2: ShortMonths(),
    3: QuarterlyMonths(),
    4: HalfMonths(),
  };

  const CommonHandleChange = (event, CustomName) => {
    if (CustomName) {
      setFilterModel((pre) => ({
        ...pre,
        [CustomName]: event,
      }));

      if (CustomName == "ScheduleType") {
        $(".RangeDate").attr("disabled", true);
        const { value } = event;
        if (Number(value) != 1 && Number(value) != 5) {
          const DropDownValue = RangeObj[Number(value)];
          setFilterModel((prevState) => ({
            ...prevState,
            ["DateRange"]: DropDownValue[0].value,
          }));

          setDropdownModel((pre) => ({
            ...pre,
            DateRange: DropDownValue,
          }));
        }




      } else if (["StationTypeID", "DepartmentID"].includes(CustomName)) {
        let StartMonth = DropdownModel?.StartMonthConfigList?.find(
          (ele) =>
            ele.DepartmentId == event.value && FilterModel.Year == ele.YearID
        );
        // if (StartMonth) {
        //   setFilterModel((pre) => ({
        //     ...pre,
        //     PeriodicStartMonth: StartMonth.MonthValue,
        //   }));
        // }
        const { value } = CustomName == "StationTypeID" ? event.target : event;
        const StationTypeID =
          CustomName == "StationTypeID" ? value : FilterModel.StationTypeID;
        const DepartmentID =
          CustomName == "DepartmentID"
            ? value
            : FilterModel?.DepartmentID?.value;

        const fineDept = DropdownModel?.DepartmentList?.find(
          (ele) => ele.value == DepartmentID
        );
        const finedType = DropdownModel?.stationTypes?.find(
          (ele) => ele.value == StationTypeID
        );

        if (fineDept && finedType) {
          const FilStations = DropdownModel?.FilterStationList.filter(
            (ele) =>
              ele.CorridorID == finedType.value && ele.Type == fineDept.Type
          );
          console.log(DropdownModel?.FilterStationList);
          console.log(fineDept);

          setDropdownModel((pre) => ({
            ...pre,
            StationList: FilStations,
          }));

          setFilterModel((pre) => ({
            ...pre,
            Stations: FilStations?.[0] ?? {},
            PeriodicStartMonth: StartMonth?.MonthValue,
            StationTypeID:
              CustomName == "StationTypeID" ? value : StationTypeID,
            // DepartmentID: CustomName == "Department" ? value : DepartmentID,
          }));

          handleGlobalStationName(FilStations?.[0]?.label ?? "");
        } else {
          setDropdownModel((pre) => ({
            ...pre,
            StationList: [],
          }));

          setFilterModel((pre) => ({
            ...pre,
            Stations: {},
          }));
          handleGlobalStationName("");
        }
      }
    } else {
      const { name, value } = event.target;
      setFilterModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  let addStartHeader = () => {
    return {
      columns: [
        {
          image: logo,
          width: 50,
          height: 50,
          alignment: "left",
          margin: [10, 0, 0, 10], // Adjust the margin as needed
        },

        {
          text: "CHENNAI METRO RAIL LIMITED",
          alignment: "center",
          margin: [0, 20, 0, 0], // Adjust the margin as needed
          fontSize: 14, // Adjust font size as needed
          bold: true, // Make the text bold
        },

        {
          text: "", // Empty text to fill the space on the right
          width: 60, // Set width to match the logo column
        },
      ],
      margin: [0, 0, 0, 1000], // Margin for the entire header section
      columnGap: 10, // Space between columns
    };
  };

  function ManipulateObject(Obj1) {
    var Obj = { ...Obj1 };

    let year = parseInt(Obj.Year);
    let Month = parseInt(Obj.DateRange);

    if (Obj.Frequency == 1) {
      if (Obj.StackToDate != "") {
        Obj.CopyStackFromDate = Obj.FromDate;
        Obj.CopyStackToDate = Obj.ToDate;
      } else {
        Obj.CopyStackFromDate = Obj.StackFromDate;
        Obj.CopyStackToDate = Obj.StackFromDate;
      }
    } else if (Obj.Frequency == 2) {
      Obj.CopyStackFromDate = new Date(year, Month, 1, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      Obj.CopyStackToDate = new Date(year, Month + 1, 0, 8)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 3) {
      var nextMonth = Month + 3;
      if (FilterModel.DateRange?.[0]?.value == Month && nextMonth >= 13) {
        year = year - 1;
      }
      Obj.CopyStackFromDate = new Date(year, Month, 1, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 4) {
      Obj.CopyStackFromDate = `${Obj.Year}-${Obj.DateRange}-01`;
      var nextMonth = Month + 5;
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 5) {
      Obj.CopyStackFromDate = `${Obj.Year}-01-01`;
      Obj.CopyStackToDate = new Date(year, 12, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    }
    return Obj;
  }

  const { CopyStackFromDate, CopyStackToDate } = ManipulateObject({
    FromDate: FilterModel.FromDate,
    ToDate: FilterModel.ToDate,
    Frequency: FilterModel.ScheduleType.value ?? 1,
    Year: FilterModel.Year,
    DateRange: FilterModel.DateRange,
  });

  let addDateandStation = (EqCode = "") => {
    return {
      columns: [
        {
          text: `Department Name: ${FilterModel?.DepartmentID?.label}`,
          alignment: "left",
          margin: [0, 0, 0, 6],
          // width: 'auto',
        },
        {
          text: `Station Name: ${FilterModel?.Stations?.label}`,
          alignment: "center",
          margin: [0, 0, 0, 6],
          //width: 'auto',
        },
        {
          text: "Equipment Code:" + EqCode,
          alignment: "right",
          margin: [0, 0, 0, 6],
          // width: 'auto',
        },
      ],

      columnGap: 10,
      widths: "auto",
    };
  };

  let addPwtNumber = (PtWno = "", PtWDate = "") => {
    return {
      columns: [
        {
          text: "PTW Number: " + PtWno,
          alignment: "left",
          margin: [0, 0, 0, 6],
          // width: 'auto',
        },
        {
          text: "PTW Date: " + PtWDate,
          alignment: "center",
          margin: [0, 0, 0, 6],
          // width: 'auto',
        },
        {
          text: "Date:" + moment().format("DD-MM-YYYY"),
          alignment: "right",
          margin: [0, 0, 0, 6],
          // width: 'auto',
        },
      ],
      columnGap: 10,
      widths: "auto",
    };
  };

  const PDFCustomization =async (doc) => {
    debugger
    let array = JSON.parse(JSON.stringify(doc.content[1].table.body));
    let header = DeepCopy(doc.content[1].table.body[0]);
    array = array.slice(1);

    let listsByDate = {};
    // Group items by date
    array.forEach(function (item) {
      let dateCheck = item[3].text; // Assuming index 8 contains the dateCheck text field
      if (!listsByDate[dateCheck]) {
        listsByDate[dateCheck] = [DeepCopy(header)]; // Create a new list if it doesn't exist
      }
      listsByDate[dateCheck].push(item);
    });

    // Convert the object of lists into an array
    let mainList = Object.keys(listsByDate).map(function (dateCheck) {
      return {
        date: dateCheck,
        // items: listsByDate[dateCheck],
        items: listsByDate[dateCheck].map(function (item) {
          return item; //.slice(0, -1); // Remove the last index from each item array
        }),
      };
    });

    doc.content[0] = "";
    const table = DeepCopy(doc.content[1]);
    doc.header = addStartHeader();
    for (let i = 1, j = 0; i < mainList.length * 5; i = i + 5, j++) {
      let TechnicianRow = [];
      let EngineerRow = [];

      let copiedTable = DeepCopy(table);
      copiedTable.headerRows = 1;

      doc.content[i - 1] = "\n\n";
      let tableLen = mainList[j].items?.[1].length;
      let EqCode = mainList[j].items?.[1]?.[3].text ?? "";
      let PWTNumber = mainList[j].items?.[1]?.[tableLen - 3].text ?? "";
      let PWTDate = mainList[j].items?.[1]?.[tableLen - 2].text ?? "";

      mainList[j].items = mainList[j].items.map(
        (ele) => (ele = ele.slice(0, -3))
      );
      mainList[j].items.forEach((ele) => ele.splice(1, 3));

      if (ConfigType == "Config_Type_2") {
        copiedTable.table.widths = [40, 70, 70, 70, 70, 70, 90];

        mainList[j].items.forEach((ele) => {
          let deletedIndex = 0;

          ele.forEach((element, index) => {
            if (
              element &&
              (element?.text?.toUpperCase() === "TIME" ||
                element?.text?.toUpperCase() === "CURRENT")
            ) {
              element.colSpan = 2;
              deletedIndex = index + 1;
              ele[index + 1] = "";
              deletedIndex++;
              return;
            }
          });

          if (deletedIndex == 2) {
            return;
          }
        });
      }
      if (ConfigType == "Config_Type_3") {
        copiedTable.table.widths = [
          "auto",
          "auto",
          "auto",
          30,
          30,
          30,
          30,
          30,
          "auto",
          "auto",
          "auto",
        ];
      }

      doc.content[i] = addDateandStation(EqCode);
      doc.content[i + 1] = addPwtNumber(PWTNumber, PWTDate);

      copiedTable.layout = tableLayout();
      copiedTable.table.body = mainList[j].items;

      // copiedTable.table.widths = ['auto', '*']
      doc.content.push(copiedTable);

      // let tableLength = headerArray.length;
      copiedTable.table.body.forEach((ele, index) => {
        if (index != 0) ele[0].text = index;
      });
     
      let ApproverSignList = {
        table: {
          body: [
            // Header row
            [
              {
                text: `L0 Name: ${
                  ApproverList.find((item) => item.OrderLevel === 8)?.EmployeeName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `L1 Name: ${
                  ApproverList.find((item) => item.OrderLevel === 7)?.EmployeeName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `L2 Name: ${
                  ApproverList.find((item) => item.OrderLevel === 6)?.EmployeeName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `L3 Name: ${
                  ApproverList.find((item) => item.OrderLevel === 5)?.EmployeeName ?? ""
                }`,
                colSpan: 1,
              },
            ],
            // Designation row
            [
              {
                text: `Designation: ${
                  ApproverList.find((item) => item.OrderLevel === 8)?.Designation ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Designation: ${
                  ApproverList.find((item) => item.OrderLevel === 7)?.Designation ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Designation: ${
                  ApproverList.find((item) => item.OrderLevel === 6)?.Designation ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Designation: ${
                  ApproverList.find((item) => item.OrderLevel === 5)?.Designation ?? ""
                }`,
                colSpan: 1,
              },
            ],
            // VendorName row
            [
              {
                text: `Vendor Name: ${
                  ApproverList.find((item) => item.OrderLevel === 8)?.VendorName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Vendor Name: ${
                  ApproverList.find((item) => item.OrderLevel === 7)?.VendorName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Vendor Name: ${
                  ApproverList.find((item) => item.OrderLevel === 6)?.VendorName ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Vendor Name: ${
                  ApproverList.find((item) => item.OrderLevel === 5)?.VendorName ?? ""
                }`,
                colSpan: 1,
              },
            ],
            // Approved Date row
            [
              {
                text: `Approved Date: ${
                  ApproverList.find((item) => item.OrderLevel === 8)?.ModifiedDate ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Approved Date: ${
                  ApproverList.find((item) => item.OrderLevel === 7)?.ModifiedDate ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Approved Date: ${
                  ApproverList.find((item) => item.OrderLevel === 6)?.ModifiedDate ?? ""
                }`,
                colSpan: 1,
              },
              {
                text: `Approved Date: ${
                  ApproverList.find((item) => item.OrderLevel === 5)?.ModifiedDate ?? ""
                }`,
                colSpan: 1,
              },
            ],
          ],
          
          widths: [100, 100, 100, 100], // Define your widths here
        },
        layout: tableLayout(),
      };

      doc.content.push(ApproverSignList);



      if (j !== mainList.length - 1) {
        doc.content.push({ text: "", pageBreak: "after" });
      }
    }
  };

  const fetchApproverList = async () => {
    const FilterObj = {
      EquipmentID: FilterModel.EquipmentID?.value,
      StationID: FilterModel.Stations.value,
      DepartmentID: FilterModel?.DepartmentID?.value,
      ScheduleType: FilterModel.ScheduleType?.value,
      FromDate: CopyStackFromDate,
      ToDate: CopyStackToDate,
    };
    try {
      return await PeriodicServiceobj.GetApproverList(FilterObj).then((result) => {
              setApproverList(result.data)
      });
    } catch (error) {
      console.error(error);
    }
  };



  const AjaxReturnDataModification = async (table, json = []) => {
    
    let ConfigType = "Config_Type_1";
    if (json?.length > 0) {
      ConfigType = json[0].ConfigType;
    }
    setConfigType(ConfigType);
    let HiddenColumns = {
      Config_Type_1: [
        "Details",
        "CellVoltageFloatMode",
        "CellVoltageDischargeMode",
        "SpecificGravity",
        "InitialNoLoadCondition",
        "Time_1",
        "Time_2",
        "Time_3",
        "Time_4",
        "Time_5",
        "FinalNoLoadCondition",
        "BoostVoltage",
      ],
      Config_Type_2: [
        "UOM",
        "RatedValue",
        "MaxValue",
        "MinValue",
        "Actual",
        "EquipmentStatus",
        "InitialNoLoadCondition",
        "Time_1",
        "Time_2",
        "Time_3",
        "Time_4",
        "Time_5",
        "FinalNoLoadCondition",
        "BoostVoltage",
      ],
      Config_Type_3: [
        "Details",
        "CellVoltageFloatMode",
        "CellVoltageDischargeMode",
        "SpecificGravity",
        "UOM",
        "RatedValue",
        "MaxValue",
        "MinValue",
        "Actual",
        "EquipmentStatus",
      ],
    };

    let HidColumns = HiddenColumns[ConfigType];

    let TotalColumList = columns.length - 1;
    Array.from({ length: TotalColumList }, (_, index) => {
      table.column(index).visible(true);
    });

    if (ConfigType !== "Config_Type_1") {
      $(table.column(4).header()).text("Cell Sl.No");
    } else {
      $(table.column(4).header()).text("Description Name");
    }

    HidColumns.forEach((ele) => {
      let index = columns.findIndex((element) => element.data == ele);
      if (index > 0) {
        table.column(index).visible(false);
      }
    });
  };




  return (
    <div>
      <PageHeaderTitle title={"Periodic Maintenance Report"}></PageHeaderTitle>
      <div className="row col-12">
        {" "}
        <div className="col-3">
          <label className="form-check-label">Department</label>
          <ReactMultiSelect
            options={DropdownModel.DepartmentList}
            placeholder={"Department"}
            onChange={(e) => CommonHandleChange(e, "DepartmentID")}
            value={FilterModel.DepartmentID}
          ></ReactMultiSelect>
        </div>
        <div className="col-3">
          <label className="form-check-label">Corridor</label>

          <WithoutSelectDropDown
            name={"StationTypeID"}
            placeholder="Select Station Type"
            HandleChange={(e) => CommonHandleChange(e, "StationTypeID")}
            data={DropdownModel.stationTypes}
          />
        </div>
        <div className="col-3">
          <label className="form-check-label">Stations</label>
          <ReactMultiSelect
            options={DropdownModel.StationList}
            placeholder={"Stations"}
            onChange={(e) => CommonHandleChange(e, "Stations")}
            value={FilterModel.Stations}
          ></ReactMultiSelect>
        </div>
        <div className="col-3">
          <label className="form-check-label">Equipment </label>
          <ReactMultiSelect
            options={DropdownModel.EquipmentList}
            placeholder={"Equipment"}
            onChange={(e) => CommonHandleChange(e, "EquipmentID")}
            value={FilterModel.EquipmentID}
          ></ReactMultiSelect>
        </div>
      </div>
      <div className="row col-12 mt-2">
        <div className="col-3 ">
          <label className="form-check-label">Schedule Type</label>
          <ReactMultiSelect
            options={[
              { label: "Daily", value: 1 },
              { label: "Monthly", value: 2 },
              { label: "Quarterly", value: 3 },
              { label: "Half Yearly", value: 4 },
              { label: "Yearly", value: 5 },
            ]}
            onChange={(e) => CommonHandleChange(e, "ScheduleType")}
            value={FilterModel.ScheduleType ?? {}}
          ></ReactMultiSelect>
        </div>
        {FilterModel?.ScheduleType.value !== 1 && (
          <>
            <div className="col-3 ">
              <label className="form-check-label" htmlFor="Year">
                Year
              </label>
              <FilterDropdown
                code={"Year"}
                name={"Year"}
                onChange={(e) => CommonHandleChange(e)}
                value={FilterModel.Year}
              />
            </div>
            <div className="col-md-3">
              <label className="form-check-label" htmlFor="category1">
                Select
              </label>
              <WithoutSelectDropDown
                name={"DateRange"}
                disabled={FilterModel?.ScheduleType.value == 5}
                
                placeholder="Select Date Range"
                HandleChange={CommonHandleChange}
                data={DropdownModel.DateRange}
              />
            </div>
          </>
        )}
        {FilterModel?.ScheduleType.value == 1 && (
          <>
            <div className="col-3">
              <label className="label" for="">
                From Date{" "}
              </label>

              <input
                type="date"
                className="form-control"
                id="FromDate"
                name="FromDate"
                pattern="\d{4}-\d{2}-\d{2}"
                value={FilterModel.FromDate}
                onChange={(e) => {
                  CommonHandleChange(e);
                }}
              />
            </div>
            <div className="col-3">
              <label className="label" for="">
                To Date{" "}
              </label>

              <input
                type="date"
                className="form-control"
                value={FilterModel.ToDate}
                id="ToDate"
                name="ToDate"
                onChange={(e) => {
                  CommonHandleChange(e);
                }}
              />
            </div>
          </>
        )}
      </div>
      <DTAjaxForDynamicColumns

        FromDate={CopyStackFromDate}
        ToDate={CopyStackToDate}

        title={"PM"}
        FilterObj={{
          EquipmentID: FilterModel.EquipmentID?.value,
          StationID: FilterModel.Stations.value,
          DepartmentID: FilterModel?.DepartmentID?.value,
          ScheduleType: FilterModel.ScheduleType?.value,
        }}
        columnList={columns}
        url={`${BASE_URL}PeriodicMaintenance/Report_PeriodicMaintenance_ForDepartment`}
        id={"PM_ReportForDepart"}
        PDFCustomization={(doc) => {PDFCustomization(doc)}}
        exportOptions={{
          columns:
            ConfigType == "Config_Type_1"
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 23, 25, 26, 27]
              : ConfigType == "Config_Type_2"
              ? [0, 1, 2, 3, 4, 11, 12, 13, 14, 23, 25, 26, 27]
              : [0, 1, 2, 3, 4, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27],
        }}
     //   ManipulateData={ManipulateData}
        // ExcelCustomization={ExcelCustomization}
        manipulateColumns={AjaxReturnDataModification}
      ></DTAjaxForDynamicColumns>
    </div>
  );
}

export default ReportPeriodicForDepart;
