import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import $ from "jquery";
import { HtmlTable } from "../../../components/HtmlTable";
import {
  GetPeriodicStatus,
  getPrivilege,
  selectalldatatable,
} from "../../../services/CommonService";
import { PeriodicService } from "../PeriodicService";
import { AppContext } from "./../../../services/ContextProvider";
import { COOKIES } from "../../../services/Global";
import { DownloadModel } from "../../BreakDownMaintenance/DownloadModel";
import BootrapModel from "../../../components/BootrapModel";
import { PeriodicSetQuerterMOnthDate } from "../../../services/CommonService";
import TestRun from "../TestRun";
import swal from "sweetalert";


import {
  getYMDdate,
  getUserDetails,
  GetLoginUserID,
} from "../../../services/CommonService";
import {
  showSaveSuccess,
  showSaveError,
  showWarningMsg,
  formd,
} from "./../../../components/ToastMsg";
import { Link } from "react-router-dom";
//   import { BulkAssignPeriodic } from "./bulkAssignPeriodic";
import BreakdownMaintenanceService from "../../BreakDownMaintenance/BreakdownMaintenanceService";
import { useNavigate } from "react-router-dom";
import DescriptionList from "../DescriptionList";

const _periodicServices = new PeriodicService();
const _breakdown = new BreakdownMaintenanceService();

const HalfYearlyMonths = [
  {
    Quater: "1st Half-Yearly",
    Month: 5,
  },

  {
    Quater: "2nd Half-Yearly",
    Month: 11,
  },
];

const MonthList = [5, 11];

export const HalfyearlyCustomView = ({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) => {
  const currRange = useMemo(() => {
    var Mydate = PeriodicSetQuerterMOnthDate(date, 4);

    return HalfyearlyCustomView.range(Mydate, { localizer });
  }, [date, localizer]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsSpecific, setSelectedrowsSpecific] = useState([]);
  const [removeRows, setRemoveRows] = useState([]);
  const [selectedNotAssignJobs, setSelectedNotAssignJobs] = useState([]);
  const [Flags, setFlag] = useState(3);
  const [FileDatas, setFileDatas] = useState([]);
  const [DownLoadRefresh, setDownLoadRefresh] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const [equipmentID, setEquipmentID] = useState(0);


  useEffect(() => {
    $("#selectallhalfyearly").on("change", function (e) {
      var data = selectalldatatable("periodic-halfyearly", e.target.checked);
      setSelectedRows(data);
    });
  });

  const AddorEditEquipment = (data) => {
    var week = parseInt(localStorage.getItem("halfyearlyWeek"));
    var currentDate = new Date(localStorage.getItem("halfyearlyMonth"));
    var fromDate;
    var endDate;
    if (week == 1) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 7);
    } else if (week == 2) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 8);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 14);
    } else if (week == 3) {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 21);
    } else {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        22
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 28);
    }
    localStorage.setItem("JobScheduleID", data[2] != null ? data[2] : 0);

    navigate("/periodics", {
      state: {
        equipmentID: data[1],
        jobScheduleID: data[2] != null ? data[2] : 0,
        fromdate: getYMDdate(fromDate),
        enddate: getYMDdate(endDate),
        maintenanceTypeID: data[3],
        week: week,
      },
    });
  };

  const columnNames = [
    {
      title:
        "<input type='checkbox' id='selectallhalfyearly' class='form-check-input'/>",
      // title: "<button type='button' id='selectall' class='btn'>Select </button>",
      createdCell: (td, cellData, rowData, row, col) => {
        var a = rowData[8].split("|")[1];

        if (
          a == isstatusCheckbox ||
          (getUserDetails().RoleID == COOKIES.TechRoleID &&
            (a == "Pending" || a == "Rejected"))
        ) {
          var b = $(
            "<input type='checkbox'  class='checks form-check-input' />"
          ).on("change", function (e) {
            if (e.target.checked) {
              let temp = selectedRows;

              var index = temp.findIndex(
                (item) => item?.EquipmentID == rowData[1]
              );

              temp.push({
                EquipmentID: rowData[1],
                JobScheduleID: rowData[2] != null ? rowData[2] : 0,
              });
              setSelectedRows([...temp]);
            } else {
              let temp = selectedRows;
              var index = temp.findIndex(
                (item) => item.EquipmentID == rowData[1]
              );
              if (index != -1) {
                temp.splice(index, 1);
                setSelectedRows([...temp]);
              }
            }
            $(e).attr("checked", e.target.checked);
          });
          $(td).html(b);
        } else {
          // var b = $("<input type='checkbox' class='form-check-input' disabled/>")
          var b = "";

          $(td).html(b);
        }
      },
    },
    { title: "EquipmentID", className: "hide" },
    { title: "JobScheduleID", className: "hide" },
    { title: "MaintenanceTypeID", className: "hide" },
    { title: "Station" },
    { title: "Equipment" },
    {
      title: "Approved / Rejected By",
    },
    {
      title: "Done By",
    },
    {
      title: "Status",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = GetPeriodicStatus(cellData);
        $(td).html(b);
      },
    },
    {
      title: "PeriodicJobID",
      className: "hide",
    },
    {
      title: "TestRun",
      className: "hide",
    },
    {
      title: "Submit",
      visible: getPrivilege().includes("PERIODICMAINTENANCEEDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        var a = rowData[8].split("|")[0];
        var b;
        if (getUserDetails().RoleID == COOKIES.TechRoleID && a == 3) {
          b = $(`<button class='btn icon-btn' disabled title='Edit'>
        <i class='fa fa-pencil m-r-5'></i>
      </button>`).on("click", function () {
            return AddorEditEquipment(rowData);
          });
        } else if (
          getUserDetails().RoleID != COOKIES.TechRoleID &&
          (a == 1 || a == 6) &&
          rowData[7] == "-"
        ) {
          b = $(`<button class='btn icon-btn' disabled title='Edit'>
      <i class='fa fa-pencil m-r-5'></i>
    </button>`).on("click", function () {
            return AddorEditEquipment(rowData);
          });
        } else {
          b = $(`<button class='btn icon-btn' title='Edit'>
        <i class='fa fa-pencil m-r-5'></i>
      </button>`).on("click", function () {
            return AddorEditEquipment(rowData);
          });
        }

        var c;
        if (rowData[2] == rowData[9]) {
          c =
            $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              // SetIsView(true)
              DownloadFiles(rowData[2], 0, 3);
              setFlag(3);
            });
        } else {
          c =
            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              // SetIsView(true)
              // DownloadFiles(rowData[2], 0, 3);
              setFlag(3);
            });
        }
        var d;
        if (rowData[10] == 1) {
          d =
            $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#TestRun" title='Test run'  >
              <i class=" fa fa-solid fa-wifi  m-r-5"></i>
              </button>`).on("click", function () {
              setEquipmentID(rowData[1]);
            });
        } else {
          d = "";
        }
        var e = "";
        if (rowData[1] != null) {
          e =
            $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#Description" title='Job Description'  >
                  <i class=" fa fa-solid fa-calendar-check-o m-r-5"></i>
                  </button>`).on("click", function () {
              setEquipmentID(rowData[1]);
            });
        } else {
          e = "";
        }

        $(td).html(b);
        $(td).append(c);
        $(td).append(d);
        $(td).append(e);
      },
    },
    {
      title: "Order",
      className: "hide",
    },
  ];

  const [rowList, setRowList] = useState(null);

  const { getCookies, showLoading, hideLoading } = useContext(AppContext);

  const editModalRedf = useRef();
  const modalCloseRef = useRef();
  const [title, setTitle] = useState("");

  const [isstatusCheckbox, setisStatusCheckbox] = useState("");

  useEffect(
    () => {
      setTitle(GetTitle());
      {
        getUserDetails().RoleID == COOKIES.TechRoleID
          ? setisStatusCheckbox("Open")
          : setisStatusCheckbox("Closed");
      }
      getJobs();
    },
    [
      // date,
      getCookies(COOKIES.PeriodicStationID),
      localStorage.getItem("halfyearlyMonth"),
    ],
    title
  );

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    _breakdown
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        setFileDatas(res.data);
        setDownLoadRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getJobs = () => {
    var week = parseInt(localStorage.getItem("halfyearlyWeek"));
    var currentDate = new Date(localStorage.getItem("halfyearlyMonth"));
    var fromDate;
    var endDate;
    if (week == 1) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 7);
    } else if (week == 2) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 8);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 14);
    } else if (week == 3) {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 21);
    } else {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        22
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 28);
    }
    var stationID = getCookies(COOKIES.PeriodicStationID);

    if (new Date(fromDate).getFullYear() < 2022) {
      setRowList([]);
    } else {
      try {
        showLoading();
        _periodicServices
          .GetJobScheduleEquipment(
            getYMDdate(fromDate),
            getYMDdate(endDate),
            parseInt(stationID),
            4,
            week
          )
          .then((res) => {
            if (res != null && res.data != null && res.data.length > 0) {
              ;
              var rows = [];
              res.data.map((v) => {
                var row = [];

                row.push("");
                row.push(v.EquipmentID);
                row.push(v.JobScheduleID);
                row.push(v.MaintenanceTypeID);
                row.push(v.StationCode);
                row.push(v.EquipmentShortName);
                row.push(
                  v.ApprovedBy != undefined &&
                    v.ApprovedBy != null &&
                    v.ApprovedBy != ""
                    ? v.ApprovedBy
                    : "-"
                );
                row.push(
                  v.JobDoneBy != undefined &&
                    v.JobDoneBy != null &&
                    v.JobDoneBy != ""
                    ? v.JobDoneBy
                    : "-"
                );
                row.push(`${v.JobStatus}|${v.StatusName}`);
                row.push(v.PeriodicJobID);
                row.push(v.Testrun);
                row.push("");
                row.push(v.JobStatus);

                if (stationID > 3) {

                  if (
                    getUserDetails().RoleID == COOKIES.TechRoleID &&
                    (v.JobStatus == 2 || v.JobStatus == 3)
                  ) {
                  } else if (
                    getUserDetails().RoleID != COOKIES.TechRoleID &&
                    v.JobStatus == 3
                  ) {
                  } else {
                    rows.push(row);
                  }
                } else {
                  if (
                    getUserDetails().RoleID == COOKIES.TechRoleID &&
                    (v.JobStatus == 2 || v.JobStatus == 3)
                  ) {

                  } else if (
                    (getUserDetails().RoleID == COOKIES.MaintainerRolID &&
                      v.JobStatus == 3) ||
                    v.JobStatus == 4
                  ) {

                  } else {
                    rows.push(row);
                  }
                }
                // rows.push(row);
              });
              clearSelectedRows();
              setRowList(rows);
              hideLoading();
            } else {
              hideLoading();
              setRowList([]);
            }
          });
      } catch (err) {
        hideLoading();
        console.log(err);
      }
    }
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
  };
  const confirmSelection = (status) => {
    if (status) {
      let statusName = "";

      if (status == 3) statusName = "approve";
      else if (status == 4) statusName = "reject";
      else statusName = "save";
      swal({
        title: "Are you sure?",
        text: `Do you want to ${statusName} this job`,
        icon: `warning`,
        buttons: true,
        dangerMode: status == 0,
      }).then((willDelete) => {
        if (willDelete) {
          updateJobStatus(status);
        }
      });
    }
  }

  const updateJobStatus = (type) => {
    ;
    var week = localStorage.getItem("halfyearlyWeek");
    var currentDate = new Date(localStorage.getItem("halfyearlyMonth"));
    var fromDate;
    var endDate;
    if (week == 1) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 7);
    } else if (week == 2) {
      fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 8);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 14);
    } else if (week == 3) {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 21);
    } else {
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        22
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 28);
    }
    const list = {
      StationID: parseInt(getCookies(COOKIES.PeriodicStationID)),
      MaintenanceTypeID: 4,
      FromDate: getYMDdate(fromDate),
      ToDate: getYMDdate(endDate),
      AssignedBy:
        getUserDetails().RoleID != COOKIES.TechRoleID ? GetLoginUserID() : 0,
      AssignedTo:
        getUserDetails().RoleID == COOKIES.TechRoleID ? GetLoginUserID() : 0,
      JobStatus: type,
      Savedby: GetLoginUserID(),
    };

    var job = {
      jobScheduleForPeriodic: list,
      EquipmentTypeModel: selectedRows,
    };

    showLoading();
    _periodicServices
      .BulkUpdateJobScheduleByEquipment(job)
      .then((res) => {
        hideLoading();
        console.log(res.data);
        if (res != null && res.data != 0) {
          showSaveSuccess();
          setSelectedRows([]);
          getJobs();
        } else {
          showSaveError();
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };

  const Toolbar = ({ selectedRows, selectedRowsSpecific }) => {
    return (
      <>
        <div className="row d-flex justify-content-between mt-2">
          <div className="col-3  rbc-toolbar d-flex justify-content-start">
            <span class="rbc-btn-group">
              <button type="button" onClick={() => onNavigate("PREV")}>
                Back
              </button>
              <button type="button" onClick={() => onNavigate("TODAY")}>
                Today
              </button>
              <button type="button" onClick={() => onNavigate("NEXT")}>
                Next
              </button>
            </span>
          </div>
          <div className="col-3 text-start">{title}</div>
          <div className="col-sm-3">
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              {getUserDetails().RoleID == COOKIES.TechRoleID ? (
                <li>
                  <button
                    class="btn btn-success"
                    title="Close selected jobs"
                    disabled={selectedRows.length > 0 ? false : true}
                    onClick={(e) => confirmSelection(2)}
                  >
                    <span>Close</span>
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    class="btn btn-success"
                    title="Approve seleted jobs"
                    disabled={selectedRows.length > 0 ? false : true}
                    onClick={(e) => confirmSelection(3)}
                    style={{ marginRight: "10px" }}
                  >
                    <span>Approve</span>
                    {/* <i
                              class="fa fa-check-circle text-success m-r-5"
                              style={{ fontSize: "30px" }}
                            ></i> */}
                  </button>
                </li>
              )}
              {getUserDetails().RoleID != COOKIES.TechRoleID && (
                <li>
                  <button
                    class="btn btn-danger"
                    title="Reject seleted jobs"
                    disabled={selectedRows.length > 0 ? false : true}
                    onClick={(e) => confirmSelection(4)}
                  >
                    <span>Reject</span>
                    {/* <i
                            class="fa fa-times-circle text-danger m-r-5"
                            style={{ fontSize: "30px" }}
                          ></i> */}
                  </button>
                </li>
              )}
            </ul>
          </div>

          <div className="col-sm-1 text-end" style={{ paddingLeft: "10px" }}>
            <Link
              to="/periodic"
              state={{
                view:
                  getUserDetails().RoleID != COOKIES.TechRoleID
                    ? "halfYearly"
                    : "month",
              }}
            >
              {" "}
              <button
                type="button"
                className="btn add-btn"
                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              >
                Go Back
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  };

  const GetTitle = () => {
    if (localStorage.getItem("halfyearlyMonth") == null) {
      localStorage.setItem("halfyearlyMonth", getYMDdate(new Date()));
    }
    if (localStorage.getItem("halfyearlyWeek") == null) {
      localStorage.setItem("halfyearlyWeek", "1");
    }

    var d = new Date(localStorage.getItem("halfyearlyMonth"));
    var e = localStorage.getItem("halfyearlyWeek");
    if (e == "4") {
      e = "4 & 5";
    }
    var curMonth = d.getMonth() + 1;
    var curYear = d.getFullYear();
    // var curMonth = date.getMonth() + 1;
    // var curYear = date.getFullYear();
    var finalDate;
    if (MonthList.includes(curMonth)) {
      finalDate = new Date(localStorage.getItem("halfyearlyMonth"));
    } else {
      while (true) {
        if (curMonth < 12) {
          curMonth = curMonth + 1;
          if (MonthList.includes(curMonth)) {
            finalDate = new Date(curYear, curMonth - 1, 1);
            break;
          }
        } else if (curMonth == 12) {
          curMonth = 1;
          curYear = curYear + 1;
        }
      }
    }
    var finalMonth = finalDate.getMonth() + 1;
    var quater = HalfYearlyMonths.find((d) => d.Month == finalMonth);
    var finalMonthString = finalDate.toLocaleString("default", {
      month: "short",
    });
    var finalYear = finalDate.getFullYear();
    var titleString = `${quater?.Quater
      } (${finalMonthString}-${finalYear}) (Week - ${e ? e : "1 - 4"})`;
    return titleString;
  };
  const onNavigate = (type) => {
    if (localStorage.getItem("halfyearlyMonth") == null) {
      localStorage.setItem("halfyearlyMonth", getYMDdate(new Date()));
    }
    if (localStorage.getItem("halfyearlyWeek") == null) {
      localStorage.setItem("halfyearlyWeek", "1");
    }
    var d = localStorage.getItem("halfyearlyMonth");
    var e = localStorage.getItem("halfyearlyWeek");
    var dLst = d.split("-");
    var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
    switch (type) {
      case Navigate.PREVIOUS:
        if (e == 1) {
          localStorage.setItem("halfyearlyWeek", 4);
          var datePrev = new Date(newD.getFullYear(), newD.getMonth() - 5, 0);
          newD = datePrev;
        } else {
          localStorage.setItem("halfyearlyWeek", parseInt(e) - 1);
          newD.setDate(newD.getDate() - 7);
        }

        localStorage.setItem("halfyearlyMonth", getYMDdate(newD));
        setTitle(GetTitle());
        return newD;
      // return localizer.add(date, -6, "month");

      case Navigate.NEXT:
        if (e == 4) {
          localStorage.setItem("halfyearlyWeek", 1);
          var dateNext = new Date(newD.getFullYear(), newD.getMonth() + 6, 1);
          newD = dateNext;
        } else {
          localStorage.setItem("halfyearlyWeek", parseInt(e) + 1);
          newD.setDate(newD.getDate() + 7);
        }
        localStorage.setItem("halfyearlyMonth", getYMDdate(newD));
        setTitle(GetTitle());
        return newD;
      // return localizer.add(date, 6, "month");
      case Navigate.TODAY:
        localStorage.setItem(
          "halfyearlyMonth",
          getYMDdate(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          )
        );
        localStorage.setItem("halfyearlyWeek", 1);
        setTitle(GetTitle());
        return new Date();
      default:
        return new Date();
    }
  };

  return (
    <div className="mt-3">
      <BootrapModel
        Id={"TestRun"}
        CloseID="TestRunclose"
        ModelTitle={`Test Run`}
        modelSize={"modal-xl"}
        module={<TestRun equipmentID={equipmentID}></TestRun>}
      >
        {" "}
      </BootrapModel>

      <BootrapModel
        Id={"Description"}
        CloseID="Descriptionclose"
        ModelTitle={`Job Description`}
        modelSize={"modal-md"}
        module={
          <DescriptionList
            equipmentID={equipmentID}
            maintenanceTypeID={4}
          ></DescriptionList>
        }
      >
        {" "}
      </BootrapModel>
      <Toolbar
        selectedRows={selectedRows}
        selectedRowsSpecific={selectedRowsSpecific}
      />
      {rowList != null && (
        <>
          <HtmlTable
            columnList={columnNames}
            rowList={rowList}
            id={"periodic-halfyearly"}
          //   orderby={14}
          />
          <BootrapModel
            Id={"DownLoadFiles"}
            CloseID="DownLoadFilesClose"
            ModelTitle={`Periodic Maintenance - Download Files`}
            modelSize={"modal-xl"}
            module={
              <DownloadModel
                Flag={Flags}
                DeleteAttachment={DownloadFiles}
                FileDatas={FileDatas}
                DownLoadRefresh={DownLoadRefresh}
              ></DownloadModel>
            }
          >
            {" "}
          </BootrapModel>
        </>
      )}
    </div>
  );
};

HalfyearlyCustomView.propTypes = {
  date: localStorage.getItem("halfyearlyMonth"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

HalfyearlyCustomView.range = (date, { localizer }) => {
  const start = date;
  const end = start;

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

HalfyearlyCustomView.navigate = (date, action, { localizer }) => {
  if (localStorage.getItem("halfyearlyMonth") == null) {
    localStorage.setItem("halfyearlyMonth", getYMDdate(new Date()));
  }
  if (localStorage.getItem("halfyearlyWeek") == null) {
    localStorage.setItem("halfyearlyWeek", "1");
  }
  var d = localStorage.getItem("halfyearlyMonth");
  var e = localStorage.getItem("halfyearlyWeek");
  var dLst = d.split("-");
  var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
  switch (action) {
    case Navigate.PREVIOUS:
      if (e == 1) {
        localStorage.setItem("halfyearlyWeek", 4);
        var datePrev = new Date(newD.getFullYear(), newD.getMonth() - 6, 0);
        newD = datePrev;
      } else {
        localStorage.setItem("halfyearlyWeek", parseInt(e) - 1);
        newD.setDate(newD.getDate() - 7);
      }

      localStorage.setItem("halfyearlyMonth", getYMDdate(newD));
      return newD;
    // return localizer.add(date, -6, "month");

    case Navigate.NEXT:
      if (e == 4) {
        localStorage.setItem("halfyearlyWeek", 1);
        var dateNext = new Date(newD.getFullYear(), newD.getMonth() + 6, 1);
        newD = dateNext;
      } else {
        localStorage.setItem("halfyearlyWeek", parseInt(e) + 1);
        newD.setDate(newD.getDate() + 7);
      }
      localStorage.setItem("halfyearlyMonth", getYMDdate(newD));
      return newD;
    // return localizer.add(date, 6, "month");

    default:
      return new Date();
  }
};

HalfyearlyCustomView.title = (date, { localizer }) => {
  if (localStorage.getItem("halfyearlyMonth") == null) {
    localStorage.setItem("halfyearlyMonth", getYMDdate(new Date()));
  }
  if (localStorage.getItem("halfyearlyWeek") == null) {
    localStorage.setItem("halfyearlyWeek", "1");
  }

  var d = new Date(localStorage.getItem("halfyearlyMonth"));
  var e = localStorage.getItem("halfyearlyWeek");
  var curMonth = d.getMonth() + 1;
  var curYear = d.getFullYear();
  // var curMonth = date.getMonth() + 1;
  // var curYear = date.getFullYear();
  var finalDate;
  if (MonthList.includes(curMonth)) {
    finalDate = date;
  } else {
    while (true) {
      if (curMonth < 12) {
        curMonth = curMonth + 1;
        if (MonthList.includes(curMonth)) {
          finalDate = new Date(curYear, curMonth - 1, 1);
          break;
        }
      } else if (curMonth == 12) {
        curMonth = 1;
        curYear = curYear + 1;
      }
    }
  }
  var finalMonth = finalDate.getMonth() + 1;
  var quater = HalfYearlyMonths.find((d) => d.Month == finalMonth);
  var finalMonthString = finalDate.toLocaleString("default", {
    month: "short",
  });
  var finalYear = finalDate.getFullYear();
  var titleString = `${quater?.Quater
    } (${finalMonthString}-${finalYear}) (Week - ${e ? e : "1 - 4"} )`;
  return titleString;
};
