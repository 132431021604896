import React from "react";
export const PageHeader = ({ title, subtitle }) => {
  return (
    <div className="page-header mb-1">
      <div className="row">
        <div className="col-4">
          <h3 className="page-title">{title}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">{subtitle}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
