import { getCSSVariables, randomArray } from "../../services/CommonService";
import underGround from "../../assets/img/underGround.png";
import attachmentimage from "../../assets/img/attachmentimage.png";
let variableNames = [
  "--dashboard-open-color",
  "--dashboard-attended-color",
  "--dashboard-approved-color",
  "--dashboard-rejected-color",
];
const StatusColor = getCSSVariables(variableNames);

// Equipment Wise
const AllDepartmentData = {
  labels: ["Lift","Escalator"],
  datasets: [
    {
      label: "Open",
      data: [4, 8],
      backgroundColor: "#93a7fd",

      borderWidth: 1,
    },
    {
      label: "Overdue",
      data: [2, 2].sort((a, b) => b - a),
      backgroundColor: "rgb(255, 159, 64)",

      borderWidth: 1,
    },
    {
      label: "Completed",
      data: [38, 3],
      backgroundColor: "#83d0e1",

      borderWidth: 1,
    },
    {
      label: "Approved",
      data: [36, 3],
      backgroundColor: "#87f093",

      borderWidth: 1,
    },
    {
      label: "Rejected",
      data: [2, 1],
      backgroundColor: "#f27d89",

      borderWidth: 1,
    },
  ],
};
// Equipment Wise
const AllEquipmentWiseData = {
  labels: ["E&M", "Telecom", "plumbing", "AFC"],
  datasets: [
    {
      label: "Running",
      data: [11760, 13720, 4116, 1176],
      backgroundColor: "#87f093",

      borderWidth: 1,
    },
    {
      label: "Under Maintenance",
      data: [190, 234, 80, 24].sort((a, b) => b - a),
      backgroundColor: "rgb(255, 159, 64)",

      borderWidth: 1,
    },
    {
      label: "Breakdown",
      data: [38, 43, 15, 6],
      backgroundColor: "#f27d89",

      borderWidth: 1,
    },
  ],
};

const AllEquipmentAvaWiseData = {
  labels: "SSA, SCR, STE, SGM, STL, SLI, SGE, SHC, SMA, SWA, STC, STR".split(
    ","
  ),
  // ["#ff6384", "#ff9f40", "#82cd47"],
  datasets: [
    {
      label: "Running",

      data: [2, 4, 2, 3, 3, 5, 3, 5, 4, 3, 4, 3],

      backgroundColor: "#87f093",
      borderWidth: 1,
    },

    {
      label: "Under Maintenance",
      data: [1, 5, 7, 7, 2, 2, 6].sort((a, b) => b - a),
      backgroundColor: "#ff9f40",

      borderWidth: 1,
    },
    {
      label: "Breakdown",
      data: [1].sort((a, b) => b - a),

      backgroundColor: "#f27d89",
      borderWidth: 1,
    },

    // Breakdown", "Under Maintenance
  ],
};

const AllStationWiseData = {
  labels: "SSA, SCR, SLI, SGM, STL, SGE, SHC, SMA, STC, STR".split(","),
  datasets: [
    {
      label: "Open",
      // data: [1],
      data: [2, 1, 1],
      backgroundColor: "#93a7fd",

      borderWidth: 1,
    },
    {
      label: "Overdue",
      data: [1, 0, 2, 1, 0, 1, 0].sort((a, b) => b - a),
      backgroundColor: "rgb(255, 159, 64)",

      borderWidth: 1,
    },
    {
      label: "Assigned",
      data: [6, 5, 5, 2, 1, 2],
      backgroundColor: "#83d0e1",

      borderWidth: 1,
    },
    {
      label: "Approved",
      data: [0, 0, 0, 1, 0, 0].sort((a, b) => b - a),
      backgroundColor: "#87f093",

      borderWidth: 1,
    },
    {
      label: "Rejected",
      data: [1, 1, 0, 0, 0, 5].sort((a, b) => b - a),
      backgroundColor: "#5184ff",

      borderWidth: 1,
    },
    {
      label: "Attended",
      data: [5, 4, 1, 0, 0, 0],
      backgroundColor: "#5184ff",

      borderWidth: 1,
    },
  ],
};

const MaintenanceChecksReportData = [
  {
    description:
      "MCC, Controller components, Track surface and Antistatic roller.",
    action: "Cleaning",
    actual: " ",
    LastMaintainedate: "03-02-2024",
    Schduledate: "25-02-2024",
    LiftName: "SCR-L-01",
    remarks: "",
    StationCode: `<p style='color:blue;'>
    <img style = 'height: 20px;width: 26px;' src =${underGround}   />
    SCR</p>`,
    Status: "Open",
    PendingDuration: "20-02-2024 - 28-03-2024",
    Option: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  {
    description:
      "Pit (Top & Bottom), Steps, Floor plate, Handrail, Inlet at Drive unit (Motor) & Controller Vent, (with help of cleaning agency).",
    action: "Cleaning",
    actual: " ",
    LastMaintainedate: "02-02-2024",
    Schduledate: "15-02-2024",
    LiftName: "SSA-L-02",

    remarks: "",
    StationCode: `<p style='color:blue;'>
    <img style = 'height: 20px;width: 26px;' src =${underGround}   />
    SSA</p>`,
    Status: "Open",
    PendingDuration: "20-02-2024 - 28-03-2024",
    Option: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  {
    description:
      "Lubrication : Drive Chain, Handrail drive chain, and Step Chain.",
    action: "lubrication",
    LastMaintainedate: "02-02-2024",
    Schduledate: "20-02-2024",
    actual: "",
    remarks: "",
    LiftName: "SSA-L-03",

    StationCode: `<p style='color:blue;'>
    <img style = 'height: 20px;width: 26px;' src =${underGround}   />
    SSA</p>`,
    Status: "Open",

    PendingDuration: "20-02-2024 - 28-03-2024",
    Option: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  {
    description: "Greasing : Main drive shaft, Bearing of handrail drive shaft",
    action: "Greasing",
    actual: "",
    remarks: "",
    LiftName: "SLI-L-01",
    LastMaintainedate: "31-01-2024",
    Schduledate: "18-02-2024",
    StationCode: `<p style='color:blue;'>
    <img style = 'height: 20px;width: 26px;' src =${underGround}   />
    SLI</p>`,
    Status: "Open",
    PendingDuration: "20-02-2024 - 28-03-2024",
    Option: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  // {
  //   description:
  //     "The gap between the step and skirting 4 mm at either side (The sum of gap measured at both side at two directly opposite points shall not exceed 7mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR-E-05",
  //   LastMaintainedate: "31-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description:
  //     "Combing plate Teeth condition, (without one comb plate two adjacent broken teeth)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LastMaintainedate: "35-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-12",
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description:
  //     "The mesh depth of combs in to the grooves (It should be 5mm +1mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LastMaintainedate: "25-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR -L-01",

  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description: "Steps Teeth condition, (without any broken teeth)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR -L-02",
  //   LastMaintainedate: "25-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description: "Step Nylon Pad condition",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LastMaintainedate: "23-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-03",

  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description:
  //     "Steps chain Tension set compression spring length Tension set compression spring length (180 mm to 200mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LastMaintainedate: "23-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-04",

  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description: "Step chain link condition",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LastMaintainedate: "23-01-2023",
  //   StationCode: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-05",

  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description: "Sag of the handrail drive chain (10 mm to 15mm)",
  //   action: "Inspection",
  //   actual: "",
  //   LiftName : "SCR-E-01",
  //   remarks: "",
  //   StationCode:" <p style='color:blue'> SCR</p>",
  //   Status: "Overdue",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description:
  //     "Check Drive Unit any Oil leakage, Check Drive Unit noise if any",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode:" <p style='color:blue'> SCR</p>",
  //   Status: "Overdue",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
];

const MaintenanceChecksForChecks = [
  {
    description:
      "MCC, Controller components, Track surface and Antistatic roller.",
    action: "Cleaning",
    actual: " ",
    LiftName: "SSA-E-01",
    remarks: "",
    StationCode: `<p style='color:blue;'>
      <img style = 'height: 20px;width: 26px;' src =${underGround}   />
      SSA</p>`,
    // Action: `<p style='color:blue;'>
    //   <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for site pictures"  />
    // </p>`,
    Status: "Open",
    PendingDuration: "20-02-2024 - 28-03-2024",
    LastMaintenanceDate: "10-01-2024",
    LastDateofBreakdown: "25-02-2024",
    Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  // {
  //   description:
  //     "Pit (Top & Bottom), Steps, Floor plate, Handrail, Inlet at Drive unit (Motor) & Controller Vent, (with help of cleaning agency).",
  //   action: "Cleaning",
  //   actual: " ",
  //   LiftName: "SSA-E-02",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${underGround}   />
  //     SSA</p>`,
  //   Action: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for MTBF & History Card"  />
  //   </p>`,
  //   Status: "Attended",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "20-02-2024",
  //   LastDateofBreakdown: "22-02-2024",
  //   // Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  // },
  // {
  //   description: "Step Nylon Pad condition.",
  //   action: "Inspection",
  //   actual: " ",
  //   LiftName: "SSA-E-03",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${underGround}   />
  //     SSA</p>`,
  //   Action: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for MTBF & History Card"  />
  //   </p>`,
  //   Status: "Attended",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "21-02-2024",
  //   LastDateofBreakdown: "22-02-2024",
  //   // Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  // },
  // {
  //   description: "Steps Teeth condition, (without any broken teeth)",
  //   action: "Cleaning",
  //   actual: " ",
  //   LiftName: "SSA-E-04",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${underGround}   />
  //     SSA</p>`,
  //   Action: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for MTBF & History Card"  />
  //   </p>`,
  //   Status: "Attended",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "14-02-2024",
  //   LastDateofBreakdown: "14-02-2024",
  //   // Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  // },
  // {
  //   description:
  //     "Greasing : Main drive shaft, Bearing of handrail drive shaft.",
  //   action: "Cleaning",
  //   actual: " ",
  //   LiftName: "SSA-E-05",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${underGround}   />
  //     SSA</p>`,
  //   Action: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for MTBF & History Card"  />
  //   </p>`,
  //   Status: "Attended",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "12-02-2024",
  //   LastDateofBreakdown: "12-02-2024",
  //   // Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  // },
  // {
  //   description:
  //     "Lubrication : Drive Chain, Handrail drive chain, and Step Chain.",
  //   action: "Cleaning",
  //   actual: " ",
  //   LiftName: "SSA-E-06",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${underGround}   />
  //     SSA</p>`,
  //   Action: `<p style='color:blue;'>
  //     <img style = 'height: 20px;width: 26px;' src =${attachmentimage} title="Download for MTBF & History Card"  />
  //   </p>`,
  //   Status: "Attended",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "11-02-2024",
  //   LastDateofBreakdown: "11-02-2024",
  //   // Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  // },
  // {
  //   description:
  //     "Pit (Top & Bottom), Steps, Floor plate, Handrail, Inlet at Drive unit (Motor) & Controller Vent, (with help of cleaning agency).",
  //   action: "Cleaning",
  //   actual: " ",
  //   LiftName: "SCR-E-06",

  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "22-02-2024",
  // },
  // {
  //   description:
  //     "Lubrication : Drive Chain, Handrail drive chain, and Step Chain.",
  //   action: "lubrication",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR-E-08",

  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "20-02-2024",
  // },
  // {
  //   description: "Greasing : Main drive shaft, Bearing of handrail drive shaft",
  //   action: "Greasing",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR-E-11",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "11-02-2024",
  // },
  // {
  //   description:
  //     "The gap between the step and skirting 4 mm at either side (The sum of gap measured at both side at two directly opposite points shall not exceed 7mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR-E-05",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "12-02-2024",
  // },
  // {
  //   description:
  //     "Combing plate Teeth condition, (without one comb plate two adjacent broken teeth)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   LiftName: "SCR-E-12",
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "12-02-2024",
  // },
  // {
  //   description:
  //     "The mesh depth of combs in to the grooves (It should be 5mm +1mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   LiftName: "SCR -L-01",

  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "15-02-2024",
  // },
  // {
  //   description: "Steps Teeth condition, (without any broken teeth)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   LiftName: "SCR -L-02",

  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  //   LastMaintenanceDate: "25-02-2024",
  // },
  // {
  //   description: "Step Nylon Pad condition",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-03",
  //   LastMaintenanceDate: "",
  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description:
  //     "Steps chain Tension set compression spring length Tension set compression spring length (180 mm to 200mm)",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-04",
  //   LastMaintenanceDate: "",

  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
  // {
  //   description: "Step chain link condition",
  //   action: "Inspection",
  //   actual: "",
  //   remarks: "",
  //   StationCode: `<p style='color:blue;'>
  //     <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //     SCR</p>`,
  //   Status: "Open",
  //   LiftName: "SCR -L-05",
  //   LastMaintenanceDate: "",

  //   PendingDuration: "20-02-2024 - 28-03-2024",
  // },
];

const AllStationList = [
  { StationType: "UG", label: "SSA", value: 23 },
  { StationType: "UG", label: "SCR", value: 24 },
  { StationType: "UG", label: "STE", value: 25 },
  { StationType: "UG", label: "SGM", value: 26 },
  { StationType: "UG", label: "STL", value: 27 },
  { StationType: "UG", label: "SLI", value: 28 },
  { StationType: "UG", label: "SGE", value: 30 },
  { StationType: "UG", label: "SCC", value: 31 },
  { StationType: "UG", label: "SHC", value: 34 },
  { StationType: "UG", label: "SMA", value: 38 },
  { StationType: "UG", label: "SWA", value: 39 },
  { StationType: "UG", label: "STC", value: 40 },
  { StationType: "UG", label: "STR", value: 41 },
];

const StackedDataForBDEquipmentWise = {
  labels: [""],
  // EquipmentID: [2, 10, 12, 21],
  datasets: [
    {
      label: "Open",
      data: 1,
      backgroundColor: StatusColor.dashboard_open_color,
    },
    {
      label: "Assigned",
      data: [2],
      backgroundColor: StatusColor.dashboard_attended_color,
    },
    {
      label: "Overdue",
      data: [1],
      backgroundColor: "#ff9f40",
    },
    {
      label: "Attended",
      data: [6, 10],
      backgroundColor: "#5184ff",
    },
    {
      label: "Approved",
      data: [5, 0],
      backgroundColor: StatusColor.dashboard_approved_color,
    },
    {
      label: "Rejected",
      data: [1, 5],
      backgroundColor: StatusColor.dashboard_rejected_color,
    },
  ],
};

const StackedDataForBDStationWise = {
  labels: "SSA, SCR, STE, SGM, STL, SGE, SHC, SMA, STC, STR".split(","),
  // EquipmentID: [2, 10, 12, 21],
  datasets: [
    // {
    //   label: "Overdue",
    //   data: [1].sort((a, b) => a - b),
    //   backgroundColor: "#ffc107",
    // },
    {
      label: "Open",
      data: [1],
      backgroundColor: StatusColor.dashboard_open_color,
    },
    {
      label: "Assigned",
      data: [1],
      backgroundColor: StatusColor.dashboard_attended_color,
    },
    {
      label: "Attended",
      data: [0, 1],
      backgroundColor: StatusColor.dashboard_approved_color,
    },
    {
      label: "Rejected",
      data: [0, 0, 0, 1, 0, 0, 1],
      backgroundColor: StatusColor.dashboard_rejected_color,
    },
  ],
};
const EquipmentAvailabilitydata = {
  labels: ["Running", "Under Maintenance", "Breakdown"],

  datasets: [
    {
      label: "E&M",
      data: [41, 2, 1],
      backgroundColor: ["#79f387", "#ff9f40", "#f78490"],
      borderWidth: 1,
    },
  ],
};
const BreakdownVerifydata = {
  labels: ["verified", "Non verified"],

  datasets: [
    {
      label: "E&M",
      data: [15, 7],
      backgroundColor: ["#79f387", "#f78490"],
      borderWidth: 1,
    },
  ],
};

const MachineAvaChecksReportData = [
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 20px;width: 26px;' src =${underGround}   />
    SSA</p>`,
    LiftName: "SSA-L-04",
    LastMaintainedate: "23-02-2024",
    LastDateofBreakdown: "25-02-2024",
    Remarks: "Electrical Short Circuit",
    Status: `Breakdown`,
    Action: ` <i class="fa fa-download" title="Download for MTBF & History Card"></i>`,
  },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-02",
  //   LastMaintainedate: "11-02-2023",
  //   Remarks: "Electrical Issues",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-03",
  //   LastMaintainedate: "21-01-2023",
  //   Remarks: "Over heat",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-0",
  //   LastMaintainedate: "19-01-2023",
  //   Remarks: "Over heat",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-01",
  //   LastMaintainedate: "11-01-2023",
  //   Remarks: "Over heat",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-01",
  //   LastMaintainedate: "10-01-2023",
  //   Remarks: "Electrical Issues",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-01",
  //   LastMaintainedate: "05-01-2023",
  //   Remarks: "Electrical Issues",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-01",
  //   LastMaintainedate: "04-01-2023",
  //   Remarks: "Electrical Issues",
  //   Status: "Breakdown",
  // },
  // {
  //   StationName: `<p style='color:blue;'>
  //   <img style = 'height: 40px;width: 46px;' src =${underGround}   />
  //   SCR</p>`,
  //   LiftName: "SCR-E-01",
  //   LastMaintainedate: "03-01-2023",
  //   Remarks: "Over heat",
  //   Status: "Breakdown",
  // },
];
const BreakdownVerifyReportData = [
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SCC</p>`,
    LiftName: "SCR-E-01",
    LastMaintainedate: "23-02-2023",
    Status: "Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SCR</p>`,
    LiftName: "SCR-E-02",
    LastMaintainedate: "23-02-2023",
    Status: "Not Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SEG</p>`,
    LiftName: "SEG-E-03",
    LastMaintainedate: "23-02-2023",
    Status: "Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SGE</p>`,
    LiftName: "SGE-E-0",
    LastMaintainedate: "23-02-2023",
    Status: "Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SGM</p>`,
    LiftName: "SGM-E-01",
    LastMaintainedate: "23-02-2023",
    Status: "Not Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SHC</p>`,
    LiftName: "SHC-E-01",
    LastMaintainedate: "23-02-2023",
    Status: "Not Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SLI</p>`,
    LiftName: "SLI-E-01",
    LastMaintainedate: "23-02-2023",
    Status: "Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SMA</p>`,
    LiftName: "SMA-E-01",
    LastMaintainedate: "23-02-2023",
    Status: "Verified",
  },
  {
    StationName: `<p style='color:blue;'>
    <img style = 'height: 30px;width: 36px;' src =${underGround}   />
    SSA</p>`,
    LiftName: "SSA-E-01",
    LastMaintainedate: "23-02-2023",
    Remarks: "Over heat",
    Status: "Verified",
  },
];

export {
  BreakdownVerifyReportData,
  AllDepartmentData,
  AllStationWiseData,
  AllEquipmentAvaWiseData,
  MaintenanceChecksReportData,
  MaintenanceChecksForChecks,
  AllStationList,
  StackedDataForBDEquipmentWise,
  StackedDataForBDStationWise,
  EquipmentAvailabilitydata,
  MachineAvaChecksReportData,
  BreakdownVerifydata,
  AllEquipmentWiseData,
};
