import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router";
import { AppContext } from "./../services/ContextProvider";
import { ToastMsg } from "./ToastMsg";

export default () => {
  const { loadingStatus } = useContext(AppContext);

  var loadingStyle = !loadingStatus ? { display: "none" } : {};
  const [isVisible, setisVisible] = useState(false);
  
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    window.onscroll = () =>{
      if(window.pageYOffset === 0) {
        setisVisible(false)
      }
      else{
        setisVisible(true)
      }
    }

    return () => (window.onscroll = null);
  }, []);
  return (
    <>
      {/* Loader */}
      <div className="loader-Wrapper">
        <div className="loader-div" style={loadingStyle}>
          <span className="loader"></span>
        </div>
      </div>

      <Navbar />
      <div className="page-wrapper bodyContent">
        <div className="content container-fluid">
          <ToastMsg />
          <Outlet />
          {isVisible && (
            <div className="scrollTop">
              <button className="btn btn-primary" onClick={() => scrollToTop()} title="scroll to top">
                <span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA10lEQVR4nOXNrQ5BARjG8UPTBNLZZJs5SWLD3IDkBiQ3ILkByQWwCW6AYrpCEE6RTjCJEWw+ivI3wWy8Z5yPN5h/fvb8DOOvA0xgBiyAlBZiAWuebYBc2EgROPHeGSiFheSBI+5dgHJQJPMBeXTfZP0iccDh+1ZAwg80wnsTP5DtA7I9Qy9oBLgKx4dAxy7YVoAcDWgpQHMNaCpAYw1oKEADDagnQB0NqC1ALQ2oKUANDaguQDUNqCpAFQ2oIECWBpQWIFMDSgpQTAOKAl1gB+yBfujIT3UDCxh520Ud9GkAAAAASUVORK5CYII=" /></span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
