import { BootrapModel } from "../../../components";
import AddApproveConfig from "./AddApproveConfig";
import { useEffect, useState } from "react";
import {
  ApproveConfig_DeleteDescription,
  ApproveConfig_GetDescription,
} from "./ApprovalConfigService";
import $ from "jquery";
import { HtmlTable } from "../../../components/HtmlTable";
import VerificationDays from "./VerificationDays";
import swal from "sweetalert";
import { showSaveError, showDeleteSuccess } from "../../../components/ToastMsg";

export default function ApprovalConfig() {
  const [GridModel, setGridModel] = useState([]);
  const [DropdownModel, setDropdownModel] = useState([]);
  const [approvelDays, setapprovelDays] = useState([]);
  const [filterModel, setFilterModel] = useState({
    ConfigurationWorkFlowID: "0",
    ConfigApproveID: "0",
    departmentID: "",
    TaskTypeID: "",
    verifierID: "",
    approverID: "",
    TimeDurationL1: "",
    TimeDurationL2: "",
    TimeDurationL3: "",
    TimeDurationL4: "",
    TimeDurationL5: "",
    IsJobAssign: 1,
  });
  useEffect(() => {
    initGrid();
  }, []);
  const handleRefresh = () => {
    initGrid();
    setFilterModel((pre) => ({
      ...pre,
      ConfigurationWorkFlowID: "0",
      ConfigApproveID: "0",
      departmentID: "",
      TaskTypeID: "",
      verifierID: "",
      approverID: "",
      TimeDurationL1: "",
      TimeDurationL2: "",
      TimeDurationL3: "",
      TimeDurationL4: "",
      TimeDurationL5: "",
      IsJobAssign: 1,
    }));
  };

  const initGrid = async () => {
    await ApproveConfig_GetDescription().then((res) => {
      if (
        res != null &&
        res.data.data.descriptionList != null &&
        res.data.data.descriptionList.length > 0
      ) {
        const { descriptionList } = res.data.data;
        if (descriptionList.length > 0) {
          const mappedDropdownModel = descriptionList.map((item) => ({
            label: `${item.userlevel}`,
            value: `${item.value}`,
          }));
          setDropdownModel(mappedDropdownModel);
          const combinedData = {};

          descriptionList.forEach((entry) => {
            const {
              ModuleName,
              userlevel,
              StatusName,
              ApprovalTimeDuration,
              value,
              TaskTypeID,
              DepartmentName,
              ConfigurationWorkFlowID,
              ConfigApproveID,
              DepartmentID,
              IsJobAssign,
            } = entry;
            if (!combinedData[DepartmentName + ModuleName]) {
              combinedData[DepartmentName + ModuleName] = {
                VERIFIER: [],
                APPROVER: [],
                VERIFIERIDS: [],
                APPROVERIDS: [],
                VerifierDuration: {},
                ApproverDuration: {},
                value,
                TaskTypeID,
                DepartmentName,
                ConfigurationWorkFlowID,
                ConfigApproveID,
                DepartmentID,
                IsJobAssign,
                ModuleName,
              };
            }
            if (StatusName === "VERIFIER") {
              combinedData[DepartmentName + ModuleName].VERIFIER.push(
                userlevel
              );
              combinedData[DepartmentName + ModuleName].VERIFIERIDS.push(value);
              combinedData[DepartmentName + ModuleName].VerifierDuration[
                `TimeDuration${userlevel}`
              ] = ApprovalTimeDuration;
            } else if (StatusName === "APPROVER") {
              combinedData[DepartmentName + ModuleName].APPROVER.push(
                userlevel
              );
              combinedData[DepartmentName + ModuleName].APPROVERIDS.push(value);
              combinedData[DepartmentName + ModuleName].ApproverDuration[
                `TimeDuration${userlevel}`
              ] = ApprovalTimeDuration;
            }
          });
          const resultList = [];
          Object.keys(combinedData).forEach((ModuleName) => {
            const rowData = {
              ModuleName: combinedData[ModuleName].ModuleName,
              Verifier: combinedData[ModuleName].VERIFIER.join(", "),
              Approver: combinedData[ModuleName].APPROVER.join(", "),
              VerifierIds: combinedData[ModuleName].VERIFIERIDS,
              ApproverIds: combinedData[ModuleName].APPROVERIDS,
              TaskTypeID: combinedData[ModuleName].TaskTypeID,
              value: combinedData[ModuleName].value,
              DepartmentName: combinedData[ModuleName].DepartmentName,
              ConfigurationWorkFlowID:
                combinedData[ModuleName].ConfigurationWorkFlowID,
              ConfigApproveID: combinedData[ModuleName].ConfigApproveID,
              DepartmentID: combinedData[ModuleName].DepartmentID,
              IsJobAssign: combinedData[ModuleName].IsJobAssign,
            };
            Object.keys(combinedData[ModuleName].VerifierDuration).forEach(
              (level) => {
                rowData[level] =
                  combinedData[ModuleName].VerifierDuration[level];
              }
            );
            Object.keys(combinedData[ModuleName].ApproverDuration).forEach(
              (level) => {
                rowData[level] =
                  combinedData[ModuleName].ApproverDuration[level];
              }
            );
            resultList.push(rowData);
          });

          setGridModel([...resultList]);
        }
      } else {
        setGridModel([]);
      }
    });
  };
  const columnNames = [
    { title: "value", visible: false, data: "value" },
    { title: "TaskTypeID", visible: false, data: "TaskTypeID" },
    { title: "Department", data: "DepartmentName" },
    { title: "Task Type", data: "ModuleName" },
    { title: "Verifier", data: "Verifier" },
    { title: "Approver", data: "Approver" },
    // { title: "Approval Days (L1)", data: "TimeDurationL1" },
    // { title: "Approval Days (L2)", data: "TimeDurationL2" },
    // { title: "Approval Days (L3)", data: "TimeDurationL3" },
    // { title: "Approval Days (L4)", data: "TimeDurationL4" },
    // { title: "Approval Days (L5)", data: "TimeDurationL4" },

    {
      title: "Submit",
      data: "ConfigurationWorkFlowID",
      // visible:
      //     getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
      //     getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var eye = "";
        var b = "";
        var c = "";
        eye =
          $(`<button class='btn icon-btn' title='View Approver Days'  data-bs-toggle="modal" data-bs-target="#Days" >
                  <i class='fa fa-eye m-r-5'></i>
                </button>`).on("click", function () {
            getApproveData(rowData);
          });
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONEDIT")) {
        b =
          $(`<button class='btn icon-btn' title='Edit'  data-bs-toggle="modal" data-bs-target="#ApprovalConfig" >
                  <i class='fa fa-pencil m-l-15'></i>
                </button>`).on("click", function () {
            EditmodelList(rowData);
          });
        // }
        // if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
        c = $(`<button class='btn icon-btn'  title='Delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function () {
          onDeleteClick(rowData);
        });

        // }
        $(td).html(eye);
        $(td).append(b);
        $(td).append(c);
      },
    },
  ];
  const getApproveData = (rowData) => {
    const timeDurationList = Object.keys(rowData)
      .filter((key) => key.includes("TimeDuration"))
      .map((key) => ({ name: key, value: rowData[key] }));
    setapprovelDays(timeDurationList);
  };
  const onDeleteClick = (rowData) => {
    let id = rowData.ConfigurationWorkFlowID;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApproveConfig_DeleteDescription(id)
          .then((response) => {
            if (response.data.data == 1) {
              showDeleteSuccess();
              initGrid();
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const EditmodelList = (rowData) => {
    const { ApproverIds, VerifierIds } = rowData;
    const uniqueDropdownModel = Array.from(
      new Set(DropdownModel.map((item) => item.value))
    );
    const uniqueDropdownModelArray = uniqueDropdownModel.map((value) => {
      return {
        label: DropdownModel.find((item) => item.value === value).label,
        value: value,
      };
    });
    const selectedApproverIds = uniqueDropdownModelArray.filter((item) =>
      ApproverIds.includes(item.value)
    );
    const selectedVerifierIds = uniqueDropdownModelArray.filter((item) =>
      VerifierIds.includes(item.value)
    );
    setFilterModel((pre) => ({
      ...pre,
      ConfigurationWorkFlowID: rowData.ConfigurationWorkFlowID,
      ConfigApproveID: rowData.ConfigApproveID,
      departmentID: rowData.DepartmentID,
      TaskTypeID: rowData.TaskTypeID,
      verifierID: selectedVerifierIds,
      approverID: selectedApproverIds,
      IsJobAssign: rowData.IsJobAssign,
      TimeDurationL1: rowData.TimeDurationL1,
      TimeDurationL2: rowData.TimeDurationL2,
      TimeDurationL3: rowData.TimeDurationL3,
      TimeDurationL4: rowData.TimeDurationL4,
      TimeDurationL5: rowData.TimeDurationL5,
      IsJobAssign: rowData.IsJobAssign,
    }));
  };
  const Header = ({ title }) => {
    return (
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-md-6 ">
            <h3 className="page-title">{title}</h3>
          </div>
          {/* {getPrivilege().includes("NONREVENUECONFIGURATIONCREATE") && ( */}
          <div className="col-md-2 ">
            <button
              type="submit"
              className="btn add-btn mb-2"
              id="timeconfigbutton"
              // onClick={() => AddNewConfig()}
              data-bs-toggle="modal"
              data-bs-target="#ApprovalConfig"
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  };
  const handle = (name, value) => {
    console.log(value);
  };

  return (
    <div>
      <Header title={"Approval Process Configuration"} />
      <BootrapModel
        Id={"ApprovalConfig"}
        modelSize={"modal-xl"}
        ModelTitle={"Add / Edit Approval Process Configuration"}
        module={
          <AddApproveConfig
            editModel={filterModel}
            handleRefresh={handleRefresh}
          />
        }
        closepopup={handleRefresh}
      />
      <BootrapModel
        Id={"Days"}
        modelSize={"modal-xl"}
        ModelTitle={" Approval Days"}
        module={
          <VerificationDays
            approvelDays={approvelDays}
            handleRefresh={handleRefresh}
          />
        }
        closepopup={handleRefresh}
      />

      <div className="card">
        <div className="card-body">
          <HtmlTable
            columnList={columnNames}
            rowList={GridModel}
            id={"ConfigureList"}
          />
        </div>
      </div>
    </div>
  );
}
