import Axios from "../../services/AxiosService";

const axios = new Axios();
const Attendance_ReportShift = async (stationID, isTvsVac, fromDate, toDate) => {
    return await axios.get(
        `Attendance/Attendance_ReportShift?stationID=${stationID}&isTvsVac=${isTvsVac}&fromDate=${fromDate}&toDate=${toDate}`
    );
};
const Attendance_ReportDetails = async (stationID, isTvsVac, fromDate, toDate) => {
    return await axios.get(
        `Attendance/Attendance_ReportDetails?stationID=${stationID}&isTvsVac=${isTvsVac}&fromDate=${fromDate}&toDate=${toDate}`
    );
};

export {
    Attendance_ReportShift,
    Attendance_ReportDetails
    
}