import React, { useContext } from "react";
import { Calendar, Navigate, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  getCurrentScheduleDate,
  getDDMMYYdate,
  getYMDdate,

} from "../../services/CommonService";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { AppContext } from "../../services/ContextProvider";
import PerformanceEvaluation from "./PerformanceEvaluation";

export default function PerformanceEvolutionCalenderWrap() {

  const navigate = useNavigate();
  const {setPerformanceDropdownList,PerformanceDropdownList} = useContext(AppContext);
  const {StartDate}  =PerformanceDropdownList;


  function PerformanceEvaluationCalenderView({

    ...props
  }) {
    return <PerformanceEvaluation></PerformanceEvaluation>;
  }

  PerformanceEvaluationCalenderView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  PerformanceEvaluationCalenderView.title = (date) => {
    return getDDMMYYdate(date);
  };

  PerformanceEvaluationCalenderView.navigate = (date, action, { localizer }) => {
    let returnDate = date;
    let IncrementNumber = 3;
    switch (action) {
      case Navigate.PREVIOUS:
        returnDate = localizer.add(date, -IncrementNumber, "month");
        break;
      case Navigate.NEXT:
        returnDate = localizer.add(date, IncrementNumber, "month");
        break;
      default:
        returnDate = getCurrentScheduleDate();
        break;
    }
    
    setPerformanceDropdownList((pre) => ({
      ...pre,
     //  CurrentYear : new Date(returnDate).getFullYear(),
      StartDate : getYMDdate(returnDate)
    }))
    return returnDate;
  };

  const localizer = momentLocalizer(moment);
  
  console.log(getCurrentScheduleDate(new Date(StartDate)))
  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: StartDate?.length > 0 ? getCurrentScheduleDate(new Date(StartDate)) : getCurrentScheduleDate(),
      views: {
        day: PerformanceEvaluationCalenderView,
      },
    }),
    []
  );

  const MyToolbar = (toolbar) => {
    const goToDate = (Type) => {
      toolbar.onNavigate(Type);
    };

    return (
      <>
        <div class="rbc-toolbar">
          <span class="rbc-btn-group">
            
            <button type="button" onClick={() => goToDate("PREV")}>
              Back
            </button>
            <button type="button" onClick={() => goToDate("CUSTOM_TODAY")}>
              Today
            </button>
            <button type="button" onClick={() => goToDate("NEXT")}>
              Next
            </button>
          </span>
          <span className="rbc-toolbar-label">
            {`(${moment(toolbar.date).format("MMMM")} - ${moment(toolbar.date).add(2, 'months').format("MMMM") })-${moment(toolbar.date).format("YYYY")} `}
          </span>
          <span class="rbc-btn-group  text-end">
            <button
              id="addRow_ers"
              onClick={() => {
                navigate("/PerformanceEvaluationCalenderView");
              }}
              className="btn btn-secondary text-end"
            >
              Go back
            </button>
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      className="card height600"
      style={{ position: "relative", top: "-10px" }}
    >
      <div className="card-body">
        <Calendar
          defaultDate={defaultDate}
          defaultView={"day"}
          events={[]}
          components={{
            toolbar: MyToolbar,
          }}
          selectable
          localizer={localizer}
          views={views}
        />
      </div>
    </div>
  );
}
