import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { AppContextProvider } from "./services/ContextProvider";
import PeriodicContextProvider from "./screens/PeriodicMaintenance/PeriodicContextProvider";
import PeriodicConfigurationProvider from "./screens/PeriodicConfig/ContextProvider";
import StationLocationContextProvider from "./screens/StationLocationConfiguration/StationLocationContext";
import { Provider } from "react-redux";
import {store} from "./ReduxStore/store"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(


  <Provider store={store} > 
  <CookiesProvider>
    <PeriodicContextProvider>
      <PeriodicConfigurationProvider>
        <StationLocationContextProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </StationLocationContextProvider>
      </PeriodicConfigurationProvider>
    </PeriodicContextProvider>
  </CookiesProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
